/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesChina',[], function () {
    "use strict";

    var first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
	
	["Aiguo"]	,
			["An"]	,
			["Angúo"]	,
			["Bai"]	,
			["Bingwen"]	,
			["Bo"]	,
			["Bohai"]	,
			["Bojing"]	,
			["Bolin"]	,
			["Boqin"]	,
			["Changpu"]	,
			["Chanming"]	,
			["Chao"]	,
			["Chaoxiang"]	,
			["Chen"]	,
			["Cheng"]	,
			["Chenglei"]	,
			["Cheung"]	,
			["Chi"]	,
			["Chongan"]	,
			["Chongkun"]	,
			["Chonglin"]	,
			["Chuanli"]	,
			["Chung"]	,		
			["Délì"]	,
			["Déshì"]	,
			["Déwei"]	,
			["Da"]	,
			["Dai"]	,
			["Delun"]	,
			["Deming"]	,
			["Dingbang"]	,
			["Dingxiang"]	,
			["Dong"]	,
			["Donghai"]	,
			["Duyi"]	,
			["Enlai"]	,
			["Fa"]	,
			["Fai"]	,
			["Fang"]	,
			["Feng"]	,
			["Fengge"]	,
			["Fu"]	,
			["Fuhua"]	,
			["Gan"]	,
			["Gang"]	,
			["Geming"]	,
			["Gen"]	,
			["Genghis"]	,
			["Guang"]	,
			["Guangli"]	,
			["Gui"]	,
			["Guiren"]	,
			["Guoliang"]	,
			["Guotin"]	,
			["Guowei"]	,
			["Guozhi"]	,
			["Hai"]	,
			["He"]	,
			["Heng"]	,
			["Ho"]	,
			["Hong"]	,
			["Honghui"]	,
			["Hongqi"]	,
			["Hop"]	,
			["Huan"]	,
			["Huang Fu"]	,
			["Hui"]	,
			["Hulin"]	,
			["Hung"]	,
			["Huojin"]	,
			["Jaw-long"]	,
			["Jian"]	,
			["Jiang"]	,
			["Jianguo"]	,
			["Jianjun"]	,
			["Jianyu"]	,
			["Jin"]	,
			["Jing"]	,
			["Jingguo"]	,
			["Jinhai"]	,
			["Jinjing"]	,
			["Jun"]	,
			["Junjie"]	,
			["Kang"]	,
			["Keung"]	,
			["Kong"]	,
			["Lì"]	,
			["Lei"]	,
			["Li"]	,
			["Liang"]	,
			["Liang"]	,
			["Liko"]	,
			["Ling"]	,
			["Liu"]	,
			["Liwei"]	,
			["Lok"]	,
			["Longwei"]	,
			["Manchu"]	,
			["Ming-húa"]	,
			["Mingli"]	,
			["Ming-tun"]	,
			["Minsheng"]	,
			["Minzhe"]	,
			["Nianzu"]	,
			["On"]	,
			["Park"]	,
			["Peng"]	,
			["Pengfei"]	,
			["Ping"]	,
			["Qi"]	,
			["Qianfan"]	,
			["Qiang"]	,
			["Qingshan"]	,
			["Qingsheng"]	,
			["Qiquiang"]	,
			["Qiu"]	,
			["Quan"]	,
			["Quon"]	,
			["Renshu"]	,
			["Rong"]	,
			["Ru"]	,
			["Shàoqiáng"]	,
			["Shìlín"]	,
			["Shan"]	,
			["Shanyuan"]	,
			["Shen"]	,
			["Shen"]	,
			["Shi"]	,
			["Shing"]	,
			["Shining"]	,
			["Shirong"]	,
			["Shoi-ming"]	,
			["Shoushan"]	,
			["Shunyuan"]	,
			["Shu-sai-chong"]	,
			["Siyu"]	,
			["Sueh-yén"]	,
			["Sying"]	,
			["Tao"]	,
			["Tengfei"]	,
			["Tingzhe"]	,
			["Tsun-chùng"]	,
			["Tung"]	,
			["Wang"]	,
			["Wei"]	,
			["Weimin"]	,
			["Weisheng"]	,
			["Weiyuan"]	,
			["Weizhe"]	,
			["Wencheng"]	,
			["Wenyan"]	,
			["Wing"]	,
			["Wuzhou"]	,
			["Xiang"]	,
			["Xianliang"]	,
			["Xiaobo"]	,
			["Xiaodan"]	,
			["Xiaojian"]	,
			["Xiaosi"]	,
			["Xiaowen"]	,
			["Xiasheng"]	,
			["Xin"]	,
			["Xing"]	,
			["Xiu"]	,
			["Xue"]	,
			["Xueqin"]	,
			["Xueyou"]	,
			["Yang"]	,
			["Yanlin"]	,
			["Yaochuan"]	,
			["Yaoting"]	,
			["Yaozu"]	,
			["Ye"]	,
			["Yi"]	,
			["Yingjie"]	,
			["Yingpei"]	,
			["Yong"]	,
			["Yongliang"]	,
			["Yongnian"]	,
			["Yongrui"]	,
			["Yongzheng"]	,
			["You"]	,
			["Yuanjun"]	,
			["Yunxu"]	,
			["Yusheng"]	,
			["Zedong"]	,
			["Zemin"]	,
			["Zenguang"]	,
			["Zhìxin"]	,
			["Zhìyuan"]	,
			["Zhen"]	,
			["Zhengzhong"]	,
			["Zhensheng"]	,
			["Zhihuán"]	,
			["Zhiqiang"]	,
			["Zhong"]	,
			["Zian"]	,
			["Zihao"]	,
			["Zixin"]	

    ];

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
			
		["Li"]	,
		["Wang"]	,
		["Zhang"]	,
		["Liú"]	,
		["Chén"]	,
		["Yáng"]	,
		["Zhào"]	,
		["Huáng"]	,
		["Zhōu"]	,
		["Wú"]	,
		["Xú"]	,
		["Sūn"]	,
		["Hú"]	,
		["Zhū"]	,
		["Gao"]	,
		["Lín"]	,
		["Hé"]	,
		["Guō"]	,
		["Mǎ"]	,
		["Luó"]	,
		["Liáng"]	,
		["Sòng"]	,
		["Zhèng"]	,
		["Xiè"]	,
		["Hán"]	,
		["Táng"]	,
		["Féng"]	,
		["Yú"]	,
		["Dǒng"]	,
		["Xiao"]	,
		["Chéng"]	,
		["Cáo"]	,
		["Yuán"]	,
		["Dèng"]	,
		["Xǔ"]	,
		["Fù"]	,
		["Shěn"]	,
		["Zēng"]	,
		["Péng"]	,
		["Lǚ"]	,
		["Sū"]	,
		["Lú"]	,
		["Jiǎng"]	,
		["Cài"]	,
		["Jiǎ"]	,
		["Dīng"]	,
		["Wèi"]	,
		["Xuē"]	,
		["Yè"]	,
		["Yán"]	,
		["Yú"]	,
		["Pan"]	,
		["Dù"]	,
		["Dài"]	,
		["Xià"]	,
		["Zhōng"]	,
		["Wang"]	,
		["Tián"]	,
		["Rén"]	,
		["Jiang"]	,
		["Fàn"]	,
		["Fang"]	,
		["Shí"]	,
		["Yáo"]	,
		["Tán"]	,
		["Shèng"]	,
		["Zōu"]	,
		["Xióng"]	,
		["Jīn"]	,
		["Lù"]	,
		["Hǎo"]	,
		["Kǒng"]	,
		["Bái"]	,
		["Cuī"]	,
		["Kang"]	,
		["Máo"]	,
		["Qiū"]	,
		["Qín"]	,
		["Jiang"]	,
		["Shǐ"]	,
		["Gù"]	,
		["Hóu"]	,
		["Shào"]	,
		["Mèng"]	,
		["Lóng"]	,
		["Wàn"]	,
		["Duàn"]	,
		["Zhang"]	,
		["Qián"]	,
		["Tang"]	,
		["Yǐn"]	,
		["Lí"]	,
		["Yì"]	,
		["Cháng"]	,
		["Wǔ"]	,
		["Qiáo"]	,
		["Hè"]	,
		["Lài"]	,
		["Gōng"]	,
		["Wén"]	

	

    ];

    return {
        first: first,
        last: last
    };
});
