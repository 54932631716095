define('data/namesBulgaria',[], function () { "use strict";

		var female, first, last;

		first = [
			["Aleksandar"]	,
			["Andon"]	,
			["Andrei"]	,
			["Andrey"]	,
			["Apostol"]	,
			["Asen"]	,
			["Atanas"]	,
			["Bogdan"]	,
			["Bogomil"]	,
			["Bojidar"]	,
			["Borislav"]	,
			["Bozhidar"]	,
			["Branimir"]	,
			["Chavdar"]	,
			["Damyan"]	,
			["Danail"]	,
			["Desislav"]	,
			["Dimitar"]	,
			["Filip"]	,
			["Gavrail"]	,
			["Gavril"]	,
			["Georgi"]	,
			["Hristo"]	,
			["Ilia"]	,
			["Ilija"]	,
			["Iliya"]	,
			["Iordan"]	,
			["Ivailo"]	,
			["Ivan"]	,
			["Ivaylo"]	,
			["Kaloyan"]	,
			["Karliman"]	,
			["Kiril"]	,
			["Krastio"]	,
			["Lazar"]	,
			["Luben"]	,
			["Lyuben"]	,
			["Lyubomir"]	,
			["Lyudmil"]	,
			["Momchil"]	,
			["Nikifor"]	,
			["Nikola"]	,
			["Ognian"]	,
			["Ognyan"]	,
			["Penko"]	,
			["Petar"]	,
			["Plamen"]	,
			["Radko"]	,
			["Samuil"]	,
			["Sava"]	,
			["Spas"]	,
			["Stanimir"]	,
			["Stoyan"]	,
			["Timotei"]	,
			["Todor"]	,
			["Toma"]	,
			["Tsvetan"]	,
			["Vasil"]	,
			["Yan"]	,
			["Yanko"]	,
			["Yoan"]	,
			["Yordan"]	,
			["Yosif"]	,
			["Zahari"]	,
			["Zhivko"]	

		];

		female = [

				["Aleksandra"]	,
				["Aleksandrina"]	,
				["Bilyana"]	,
				["Bisera"]	,
				["Bogdana"]	,
				["Bogna"]	,
				["Borislava"]	,
				["Boyana"]	,
				["Dana"]	,
				["Desislava"]	,
				["Dessislava"]	,
				["Donka"]	,
				["Elisaveta"]	,
				["Emiliya"]	,
				["Evdokiya"]	,
				["Filipa"]	,
				["Gergana"]	,
				["Hristina"]	,
				["Iskra"]	,
				["Iva"]	,
				["Ivana"]	,
				["Ivanka"]	,
				["Ivet"]	,
				["Lala"]	,
				["Lidiya"]	,
				["Lilyana"]	,
				["Lyudmila"]	,
				["Maya"]	,
				["Nadejda"]	,
				["Nevena"]	,
				["Nikolina"]	,
				["Penka"]	,
				["Rada"]	,
				["Radka"]	,
				["Raina"]	,
				["Raya"]	,
				["Rayna"]	,
				["Rosa"]	,
				["Rositsa"]	,
				["Silva"]	,
				["Silviya"]	,
				["Snezhana"]	,
				["Stoyanka"]	,
				["Tereza"]	,
				["Todorka"]	,
				["Tsveta"]	,
				["Tsvetanka"]	,
				["Vasilka"]	,
				["Violeta"]	,
				["Yana"]	,
				["Yoana"]	,
				["Yordanka"]	,
				["Zhivka"]	


		];

		last = [
				["Ivanov"]	,
				["Petrov"]	,
				["Georgiev"]	,
				["Dimitrov"]	,
				["Stoyanov"]	,
				["Andreev"]	,
				["Mihaylov"]	,
				["Nikolov"]	,
				["Vassilev"]	,
				["Todorov"]	

		];

	return {
		first: first,
		female: female,
		last: last
	};
});
