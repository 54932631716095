/**
 * @name data.champions
 * @namespace Name, Role, Role, Attack, Defense, Ability, Difficulty, Tracking Number (hid)
 */
define('data/champions2',[], function () {
    "use strict";

    var champion;

// http://leagueoflegends.wikia.com/wiki/List_of_champions
    // Name, Role, Role, Attack, Defense, Ability, Difficulty, Tracking Number (hid)
    champion = [
	/*
	["Aatrox","Fighter","Tank",	8	,	4	,	3	,	4	,	1	],
	["Ahri","Mage","Assassin",	3	,	4	,	8	,	5	,	2	],
	["Akali","Assassin","N/A",	5	,	3	,	8	,	7	,	3	],
	["Alistar","Tank","Support",	6	,	9	,	5	,	7	,	4	],
	["Amumu","Tank","Mage",	2	,	6	,	8	,	3	,	5	],
	["Anivia","Mage","Support",	1	,	4	,	10	,	10	,	6	],
	["Annie","Mage","N/A",	2	,	3	,	10	,	6	,	7	],
	["Ashe","Marksman","Support",	7	,	3	,	2	,	4	,	8	],
	["Azir","Mage","Marksman",	6	,	3	,	8	,	9	,	9	],
	["Bard","Support","Mage",	4	,	4	,	5	,	9	,	10	],
	["Blitzcrank","Tank","Fighter",	4	,	8	,	5	,	4	,	11	],
	["Brand","Mage","N/A",	2	,	2	,	9	,	4	,	12	],
	["Braum","Support","Tank",	3	,	9	,	4	,	3	,	13	],
	["Caitlyn","Marksman","N/A",	8	,	2	,	2	,	6	,	14	],
	["Cassiopeia","Mage","N/A",	2	,	3	,	9	,	10	,	15	],
	["Cho'Gath","Tank","Mage",	3	,	7	,	7	,	5	,	16	],
	["Corki","Marksman","N/A",	8	,	3	,	6	,	6	,	17	],
	["Darius","Fighter","Tank",	9	,	5	,	1	,	2	,	18	],
	["Diana","Fighter","Mage",	7	,	6	,	8	,	4	,	19	],
	["Dr. Mundo","Fighter","Tank",	5	,	7	,	6	,	5	,	20	],
	["Draven","Marksman","N/A",	9	,	3	,	1	,	8	,	21	],
	["Ekko","Assassin","Fighter",	5	,	3	,	7	,	8	,	22	],
	["Elise","Mage","Fighter",	6	,	5	,	7	,	9	,	23	],
	["Evelynn","Assassin","Mage",	4	,	2	,	7	,	10	,	24	],
	["Ezreal","Marksman","Mage",	7	,	2	,	6	,	7	,	25	],
	["Fiddlesticks","Mage","Support",	2	,	3	,	9	,	9	,	26	],
	["Fiora","Fighter","Assassin",	10	,	4	,	2	,	3	,	27	],
	["Fizz","Assassin","Fighter",	6	,	4	,	7	,	6	,	28	],
	["Galio","Tank","Mage",	3	,	7	,	6	,	3	,	29	],
	["Gangplank","Fighter","",	7	,	6	,	4	,	9	,	30	],
	["Garen","Fighter","Tank",	7	,	7	,	1	,	5	,	31	],
	["Gnar","Fighter","Tank",	6	,	5	,	5	,	8	,	32	],
	["Gragas","Fighter","Mage",	4	,	7	,	6	,	5	,	33	],
	["Graves","Marksman","N/A",	8	,	5	,	3	,	3	,	34	],
	["Hecarim","Fighter","Tank",	8	,	6	,	4	,	6	,	35	],
	["Heimerdinger","Mage","Support",	2	,	6	,	8	,	8	,	36	],
	["Irelia","Fighter","Assassin",	7	,	4	,	5	,	5	,	37	],
	["Janna","Support","Mage",	3	,	5	,	7	,	7	,	38	],
	["Jarvan IV","Tank","Fighter",	6	,	8	,	3	,	5	,	39	],
	["Jax","Fighter","Assassin",	7	,	5	,	7	,	5	,	40	],
	["Jayce","Fighter","Marksman",	8	,	4	,	3	,	7	,	41	],
	["Jinx","Marksman","N/A",	9	,	2	,	4	,	6	,	42	],
	["Kalista","Marksman","N/A",	8	,	2	,	4	,	7	,	43	],
	["Karma","Mage","Support",	1	,	7	,	8	,	5	,	44	],
	["Karthus","Mage","N/A",	2	,	2	,	10	,	7	,	45	],
	["Kassadin","Assassin","Mage",	3	,	5	,	8	,	8	,	46	],
	["Katarina","Assassin","Mage",	4	,	3	,	9	,	8	,	47	],
	["Kayle","Fighter","Support",	6	,	6	,	7	,	7	,	48	],
	["Kennen","Mage","Marksman",	6	,	4	,	7	,	4	,	49	],
	["Kha'Zix","Assassin","Fighter",	9	,	4	,	3	,	6	,	50	],
	["Kogmaw","Marksman","Mage",	8	,	2	,	5	,	6	,	51	],
	["LeBlanc","Assassin","Mage",	1	,	4	,	10	,	9	,	52	],
	["Lee Sin","Fighter","Assassin",	8	,	5	,	3	,	6	,	53	],
	["Leona","Tank","Support",	4	,	8	,	3	,	4	,	54	],
	["Lissandra","Mage","N/A",	2	,	5	,	8	,	6	,	55	],
	["Lucian","Marksman","N/A",	8	,	5	,	3	,	6	,	56	],
	["Lulu","Support","Mage",	4	,	5	,	7	,	5	,	57	],
	["Lux","Mage","Support",	2	,	4	,	9	,	5	,	58	],
	["Malphite","Tank","Fighter",	5	,	9	,	7	,	2	,	59	],
	["Malzahar","Mage","Assassin",	2	,	2	,	9	,	6	,	60	],
	["Maokai","Tank","Mage",	3	,	8	,	6	,	3	,	61	],
	["Master Yi","Assassin","Fighter",	10	,	4	,	2	,	4	,	62	],
	["Miss Fortune","Marksman","N/A",	8	,	2	,	5	,	1	,	63	],
	["Mordekaiser","Fighter","Mage",	4	,	6	,	7	,	7	,	64	],
	["Morgana","Mage","Support",	1	,	6	,	8	,	1	,	65	],
	["Nami","Support","Mage",	4	,	3	,	7	,	5	,	66	],
	["Nasus","Fighter","Tank",	7	,	5	,	6	,	6	,	67	],
	["Nautilus","Tank","Fighter",	4	,	6	,	6	,	6	,	68	],
	["Nidalee","Assassin","Fighter",	5	,	4	,	7	,	8	,	69	],
	["Nocturne","Assassin","Fighter",	9	,	5	,	2	,	4	,	70	],
	["Nunu","Support","Fighter",	4	,	6	,	7	,	4	,	71	],
	["Olaf","Fighter","Tank",	9	,	5	,	3	,	3	,	72	],
	["Orianna","Mage","Support",	4	,	3	,	9	,	7	,	73	],
	["Pantheon","Fighter","Assassin",	9	,	4	,	3	,	4	,	74	],
	["Poppy","Fighter","Assassin",	6	,	6	,	5	,	7	,	75	],
	["Quinn","Marksman","Fighter",	9	,	4	,	2	,	5	,	76	],
	["Rammus","Tank","Fighter",	4	,	10	,	5	,	5	,	77	],
	["Rek'Sai","Fighter","N/A",	8	,	5	,	2	,	3	,	78	],
	["Renekton","Fighter","Tank",	8	,	5	,	2	,	3	,	79	],
	["Rengar","Assassin","Fighter",	7	,	4	,	2	,	8	,	80	],
	["Riven","Fighter","Assassin",	8	,	5	,	1	,	8	,	81	],
	["Rumble","Fighter","Mage",	3	,	6	,	8	,	10	,	82	],
	["Ryze","Mage","Fighter",	2	,	2	,	10	,	7	,	83	],
	["Sejuani","Tank","Fighter",	5	,	7	,	6	,	4	,	84	],
	["Shaco","Assassin","N/A",	8	,	4	,	6	,	9	,	85	],
	["Shen","Tank","Fighter",	3	,	9	,	3	,	4	,	86	],
	["Shyvana","Fighter","Tank",	8	,	6	,	3	,	4	,	87	],
	["Singed","Tank","Fighter",	4	,	8	,	7	,	5	,	88	],
	["Sion","Tank","Fighter",	5	,	9	,	3	,	5	,	89	],
	["Sivir","Marksman","N/A",	9	,	3	,	1	,	4	,	90	],
	["Skarner","Fighter","Tank",	7	,	6	,	5	,	5	,	91	],
	["Sona","Support","Mage",	5	,	2	,	8	,	4	,	92	],
	["Soraka","Support","Mage",	2	,	5	,	7	,	3	,	93	],
	["Swain","Mage","Fighter",	2	,	6	,	9	,	8	,	94	],
	["Syndra","Mage","Support",	2	,	3	,	9	,	8	,	95	],
	["Tahm Kench","Support","Tank",	2	,	6	,	4	,	3	,	96	],
	["Talon","Assassin","Fighter",	9	,	3	,	1	,	7	,	97	],
	["Taric","Support","Fighter",	4	,	8	,	5	,	5	,	98	],
	["Teemo","Marksman","Assassin",	5	,	3	,	7	,	6	,	99	],
	["Thresh","Support","Fighter",	5	,	6	,	6	,	7	,	100	],
	["Tristana","Marksman","Assassin",	9	,	3	,	5	,	4	,	101	],
	["Trundle","Fighter","Tank",	7	,	6	,	2	,	5	,	102	],
	["Tryndamere","Fighter","Assassin",	10	,	5	,	2	,	5	,	103	],
	["Twisted Fate","Mage","N/A",	6	,	2	,	6	,	9	,	104	],
	["Twitch","Marksman","Assassin",	9	,	2	,	3	,	6	,	105	],
	["Udyr","Fighter","Tank",	8	,	7	,	4	,	7	,	106	],
	["Urgot","Marksman","Fighter",	8	,	5	,	3	,	8	,	107	],
	["Varus","Marksman","Mage",	7	,	3	,	4	,	2	,	108	],
	["Vayne","Marksman","Assassin",	10	,	1	,	1	,	8	,	109	],
	["Veigar","Mage","N/A",	2	,	2	,	10	,	7	,	110	],
	["Vel'Koz","Mage","N/A",	2	,	2	,	10	,	8	,	111	],
	["Vi","Fighter","Assassin",	8	,	5	,	3	,	4	,	112	],
	["Viktor","Mage","N/A",	2	,	4	,	10	,	9	,	113	],
	["Vladimir","Mage","Tank",	2	,	6	,	8	,	7	,	114	],
	["Volibear","Fighter","Tank",	7	,	7	,	4	,	3	,	115	],
	["Warwick","Fighter","Tank",	7	,	4	,	4	,	3	,	116	],
	["Wukong","Fighter","Tank",	8	,	5	,	2	,	3	,	117	],
	["Xerath","Mage","Assassin",	1	,	3	,	10	,	8	,	118	],
	["Xin Zhao","Fighter","Assassin",	8	,	6	,	3	,	2	,	119	],
	["Yasuo","Fighter","Assassin",	8	,	4	,	4	,	10	,	120	],
	["Yorick","Fighter","Mage",	6	,	6	,	6	,	4	,	121	],
	["Zac","Tank","Fighter",	3	,	7	,	7	,	8	,	122	],
	["Zed","Assassin","Fighter",	9	,	2	,	1	,	7	,	123	],
	["Ziggs","Mage","N/A",	2	,	4	,	9	,	4	,	124	],
	["Zilean","Support","Mage",	2	,	5	,	8	,	6	,	125	],
	["Zyra","Mage","Support",	4	,	3	,	8	,	7	,	126	]		*/

  ["Aatrox","Diver","Diver",6	,	6	,	6	,	6	,	1	],
  ["Ahri","Burst","Burst",9	,	3	,	6	,	9	,	2	],
  ["Akali","Assassin","Assassin",9	,	3	,	3	,	9	,	3	],
  ["Alistar","Vanguard","Vanguard",3	,	9	,	9	,	3	,	4	],
  ["Amumu","Vanguard","Vanguard",6	,	9	,	9	,	3	,	5	],
  ["Anivia","Battlemage","Battlemage",9	,	3	,	9	,	0	,	6	],
  ["Annie","Burst","Burst",9	,	3	,	9	,	0	,	7	],
  ["Aphelios","Marksman","Marksman",6	,	0	,	9	,	0	,	8	],
  ["Ashe","Marksman","Marksman",9	,	0	,	6	,	6	,	9	],
  ["Aurelion Sol","Battlemage","Battlemage",9	,	3	,	6	,	6	,	10	],
  ["Azir","Specialist","Specialist",3	,	3	,	9	,	6	,	11	],
  ["Bard","Catcher","Catcher",3	,	6	,	9	,	3	,	12	],
  ["Blitzcrank","Catcher","Catcher",9	,	0	,	6	,	0	,	13	],
  ["Brand","Burst","Burst",3	,	6	,	9	,	3	,	14	],
  ["Braum","Warden","Warden",9	,	0	,	6	,	6	,	15	],
  ["Caitlyn","Marksman","Marksman",9	,	6	,	6	,	9	,	16	],
  ["Camille","Diver","Diver",9	,	3	,	9	,	3	,	17	],
  ["Cassiopeia","Battlemage","Battlemage",6	,	9	,	6	,	0	,	18	],
  ["Cho'Gath","Specialist","Specialist",9	,	0	,	3	,	6	,	19	],
  ["Corki","Marksman","Marksman",9	,	6	,	6	,	0	,	20	],
  ["Darius","Juggernaut","Juggernaut",9	,	6	,	6	,	6	,	21	],
  ["Diana","Diver","Diver",6	,	9	,	3	,	0	,	22	],
  ["Dr. Mundo","Juggernaut","Juggernaut",9	,	0	,	3	,	6	,	23	],
  ["Draven","Marksman","Marksman",9	,	6	,	6	,	9	,	24	],
  ["Ekko","Assassin","Assassin",6	,	6	,	6	,	6	,	25	],
  ["Elise","Diver","Diver",6	,	6	,	3	,	6	,	26	],
  ["Evelynn","Assassin","Assassin",9	,	0	,	0	,	9	,	27	],
  ["Ezreal","Marksman","Marksman",9	,	3	,	9	,	3	,	28	],
  ["Fiddlesticks","Specialist","Specialist",9	,	6	,	6	,	6	,	29	],
  ["Fiora","Skirmisher","Skirmisher",9	,	3	,	6	,	9	,	30	],
  ["Fizz","Assassin","Assassin",6	,	9	,	9	,	6	,	31	],
  ["Galio","Warden","Warden",9	,	3	,	3	,	3	,	32	],
  ["Gangplank","Specialist","Specialist",6	,	9	,	3	,	3	,	33	],
  ["Garen","Juggernaut","Juggernaut",6	,	3	,	3	,	6	,	34	],
  ["Gnar","Specialist","Specialist",6	,	9	,	9	,	6	,	35	],
  ["Gragas","Vanguard","Vanguard",9	,	6	,	3	,	6	,	36	],
  ["Graves","Specialist","Specialist",6	,	6	,	6	,	6	,	37	],
  ["Hecarim","Diver","Diver",9	,	0	,	6	,	0	,	38	],
  ["Heimerdinger","Specialist","Specialist",9	,	6	,	3	,	0	,	39	],
  ["Illaoi","Juggernaut","Juggernaut",9	,	6	,	6	,	6	,	40	],
  ["Irelia","Diver","Diver",3	,	3	,	9	,	6	,	41	],
  ["Ivern","Catcher","Catcher",3	,	3	,	9	,	3	,	42	],
  ["Janna","Enchanter","Enchanter",6	,	6	,	6	,	6	,	43	],
  ["Jarvan IV","Diver","Diver",9	,	6	,	6	,	6	,	44	],
  ["Jax","Skirmisher","Skirmisher",9	,	0	,	3	,	6	,	45	],
  ["Jayce","Artillery","Artillery",9	,	0	,	6	,	0	,	46	],
  ["Jhin","Marksman","Marksman",9	,	0	,	6	,	3	,	47	],
  ["Jinx","Marksman","Marksman",9	,	0	,	3	,	9	,	48	],
  ["Kai'Sa","Marksman","Marksman",6	,	3	,	6	,	3	,	49	],
  ["Kalista","Marksman","Marksman",9	,	0	,	3	,	0	,	50	],
  ["Karma","Enchanter","Enchanter",9	,	6	,	3	,	9	,	51	],
  ["Karthus","Battlemage","Battlemage",9	,	0	,	0	,	9	,	52	],
  ["Kassadin","Assassin","Assassin",9	,	3	,	3	,	3	,	53	],
  ["Katarina","Assassin","Assassin",6	,	3	,	3	,	6	,	54	],
  ["Kayle","Specialist","Specialist",9	,	3	,	9	,	3	,	55	],
  ["Kayn","Skirmisher","Skirmisher",9	,	3	,	3	,	6	,	56	],
  ["Kennen","Specialist","Specialist",9	,	3	,	6	,	9	,	57	],
  ["Kha'Zix","Assassin","Assassin",9	,	6	,	3	,	9	,	58	],
  ["Kindred","Marksman","Marksman",9	,	0	,	3	,	0	,	59	],
  ["Kled","Diver","Diver",9	,	0	,	6	,	9	,	60	],
  ["Kog'Maw","Marksman","Marksman",9	,	6	,	6	,	9	,	61	],
  ["LeBlanc","Burst","Burst",3	,	9	,	9	,	3	,	62	],
  ["Lee Sin","Diver","Diver",9	,	3	,	9	,	6	,	63	],
  ["Leona","Vanguard","Vanguard",9	,	0	,	0	,	9	,	64	],
  ["Lillia","Skirmisher","Skirmisher",6	,	3	,	6	,	3	,	65	],
  ["Lissandra","Burst","Burst",9	,	3	,	6	,	0	,	66	],
  ["Lucian","Marksman","Marksman",3	,	9	,	9	,	3	,	67	],
  ["Lulu","Enchanter","Enchanter",9	,	3	,	9	,	0	,	68	],
  ["Lux","Burst","Burst",3	,	9	,	9	,	3	,	69	],
  ["Malphite","Vanguard","Vanguard",9	,	3	,	0	,	6	,	70	],
  ["Malzahar","Battlemage","Battlemage",9	,	0	,	3	,	3	,	71	],
  ["Maokai","Vanguard","Vanguard",9	,	6	,	0	,	0	,	72	],
  ["Master Yi","Skirmisher","Skirmisher",6	,	3	,	9	,	0	,	73	],
  ["Miss Fortune","Marksman","Marksman",3	,	3	,	9	,	3	,	74	],
  ["Mordekaiser","Juggernaut","Juggernaut",6	,	9	,	6	,	0	,	75	],
  ["Morgana","Catcher","Catcher",3	,	9	,	9	,	3	,	76	],
  ["Nami","Enchanter","Enchanter",9	,	3	,	0	,	9	,	77	],
  ["Nasus","Juggernaut","Juggernaut",9	,	3	,	6	,	6	,	78	],
  ["Nautilus","Vanguard","Vanguard",3	,	9	,	6	,	3	,	79	],
  ["Neeko","Burst","Burst",6	,	6	,	6	,	3	,	80	],
  ["Nidalee","Specialist","Specialist",6	,	3	,	6	,	3	,	81	],
  ["Nocturne","Assassin","Assassin",3	,	9	,	9	,	3	,	82	],
  ["Nunu & Willump","Warden","Warden",9	,	6	,	6	,	6	,	83	],
  ["Olaf","Diver","Diver",6	,	9	,	9	,	6	,	84	],
  ["Orianna","Burst","Burst",9	,	0	,	6	,	9	,	85	],
  ["Ornn","Vanguard","Vanguard",3	,	6	,	9	,	9	,	86	],
  ["Pantheon","Diver","Diver",6	,	9	,	9	,	6	,	87	],
  ["Poppy","Warden","Warden",6	,	6	,	6	,	6	,	88	],
  ["Pyke","Assassin","Assassin",6	,	6	,	6	,	6	,	89	],
  ["Qiyana","Assasin","Assasin",9	,	3	,	6	,	6	,	90	],
  ["Quinn","Specialist","Specialist",9	,	6	,	6	,	9	,	91	],
  ["Rakan","Catcher","Catcher",9	,	6	,	6	,	3	,	92	],
  ["Rammus","Vanguard","Vanguard",9	,	3	,	6	,	6	,	93	],
  ["Rek'Sai","Diver","Diver",6	,	6	,	9	,	6	,	94	],
  ["Renekton","Diver","Diver",9	,	3	,	6	,	6	,	95	],
  ["Rengar","Diver","Diver",6	,	9	,	6	,	6	,	96	],
  ["Riven","Skirmisher","Skirmisher",6	,	6	,	0	,	6	,	97	],
  ["Rumble","Battlemage","Battlemage",6	,	9	,	6	,	6	,	98	],
  ["Ryze","Battlemage","Battlemage",6	,	9	,	9	,	3	,	99	],
  ["Samira","Marksman","Marksman",9	,	3	,	0	,	3	,	100	],
  ["Sejuani","Vanguard","Vanguard",3	,	9	,	9	,	3	,	101	],
  ["Senna","Marksman","Marksman",6	,	3	,	6	,	3	,	102	],
  ["Seraphine","Burst","Burst",3	,	3	,	6	,	3	,	103	],
  ["Sett","Juggernaut","Juggernaut",6	,	6	,	6	,	0	,	104	],
  ["Shaco","Assassin","Assassin",9	,	0	,	6	,	0	,	105	],
  ["Shen","Warden","Warden",6	,	9	,	6	,	3	,	106	],
  ["Shyvana","Juggernaut","Juggernaut",9	,	0	,	6	,	3	,	107	],
  ["Singed","Specialist","Specialist",9	,	0	,	3	,	6	,	108	],
  ["Sion","Vanguard","Vanguard",3	,	6	,	6	,	0	,	109	],
  ["Sivir","Marksman","Marksman",9	,	0	,	6	,	3	,	110	],
  ["Skarner","Diver","Diver",3	,	6	,	9	,	3	,	111	],
  ["Sona","Enchanter","Enchanter",9	,	0	,	6	,	6	,	112	],
  ["Soraka","Enchanter","Enchanter",6	,	9	,	3	,	3	,	113	],
  ["Swain","Battlemage","Battlemage",9	,	6	,	3	,	6	,	114	],
  ["Sylas","Burst","Burst",9	,	0	,	6	,	6	,	115	],
  ["Syndra","Burst","Burst",9	,	0	,	3	,	6	,	116	],
  ["Tahm Kench","Warden","Warden",6	,	9	,	6	,	6	,	117	],
  ["Taliyah","Battlemage","Battlemage",6	,	6	,	6	,	3	,	118	],
  ["Talon","Assassin","Assassin",9	,	0	,	6	,	0	,	119	],
  ["Taric","Enchanter","Enchanter",9	,	0	,	6	,	6	,	120	],
  ["Teemo","Specialist","Specialist",9	,	0	,	9	,	0	,	121	],
  ["Thresh","Catcher","Catcher",9	,	0	,	6	,	0	,	122	],
  ["Tristana","Marksman","Marksman",6	,	6	,	9	,	6	,	123	],
  ["Trundle","Juggernaut","Juggernaut",9	,	3	,	6	,	0	,	124	],
  ["Tryndamere","Skirmisher","Skirmisher",9	,	6	,	3	,	3	,	125	],
  ["Twisted Fate","Burst","Burst",6	,	9	,	6	,	6	,	126	],
  ["Twitch","Marksman","Marksman",6	,	6	,	6	,	3	,	127	],
  ["Udyr","Juggernaut","Juggernaut",6	,	6	,	6	,	6	,	128	],
  ["Urgot","Juggernaut","Juggernaut",9	,	6	,	9	,	3	,	129	],
  ["Varus","Marksman","Marksman",9	,	0	,	6	,	0	,	130	],
  ["Vayne","Marksman","Marksman",6	,	6	,	6	,	6	,	131	],
  ["Veigar","Burst","Burst",9	,	3	,	6	,	9	,	132	],
  ["Vel'Koz","Artillery","Artillery",6	,	6	,	6	,	0	,	133	],
  ["Vi","Diver","Diver",6	,	9	,	9	,	6	,	134	],
  ["Viktor","Battlemage","Battlemage",9	,	0	,	3	,	9	,	135	],
  ["Vladimir","Battlemage","Battlemage",9	,	0	,	6	,	6	,	136	],
  ["Volibear","Juggernaut","Juggernaut",6	,	3	,	6	,	6	,	137	],
  ["Warwick","Diver","Diver",9	,	3	,	6	,	6	,	138	],
  ["Wukong","Diver","Diver",9	,	0	,	9	,	0	,	139	],
  ["Xayah","Marksman","Marksman",9	,	3	,	3	,	9	,	140	],
  ["Xerath","Artillery","Artillery",6	,	3	,	9	,	9	,	141	],
  ["Xin Zhao","Diver","Diver",9	,	3	,	9	,	3	,	142	],
  ["Yasuo","Skirmisher","Skirmisher",6	,	6	,	3	,	9	,	143	],
  ["Yone","Assassin","Assassin",3	,	3	,	3	,	9	,	144	],
  ["Yorick","Juggernaut","Juggernaut",9	,	3	,	6	,	6	,	145	],
  ["Yuumi","Enchanter","Enchanter",6	,	3	,	6	,	3	,	146	],
  ["Zac","Vanguard","Vanguard",9	,	3	,	6	,	3	,	147	],
  ["Zed","Assassin","Assassin",6	,	6	,	6	,	6	,	148	],
  ["Ziggs","Artillery","Artillery",9	,	3	,	6	,	6	,	149	],
  ["Zilean","Specialist","Specialist",9	,	3	,	6	,	9	,	150	],
  ["Zoe","Burst","Burst",9	,	6	,	3	,	9	,	151	],
  ["Zyra","Catcher","Catcher",9	,	3	,	9	,	3	,	152	],


    ];

    return {
        champion: champion
    };
});

