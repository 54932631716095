/**
 * @name data.playerID
 * @namespace Name, Role, Role, Attack, Defense, Ability, Difficulty, Tracking Number (hid)
 */
define('data/userIDs',[], function () {
    "use strict";

    var prefix,base,suffix,number;

// http://leagueoflegends.wikia.com/wiki/List_of_champions
    // Name, Role, Role, Attack, Defense, Ability, Difficulty, Tracking Number (hid)
	prefix = [	
		["Happy"],
		["Hungry"],
		["Angry"],
		["Cookie"],
		["Red"],
		["Blue"],
		["Pink"],
		["Beautiful"],
		["Elegant"],
		["Long"],
		["Dark"],
		["Orange"],
		["Green"],
		["Yellow"],
		["Purple"],
		["Gray"],
		["Black"],
		["White"],
		["Careful"],
		["Dead"],
		["Easy"],
		["Powerful"],
		["Rich"],
		["Wrong"],
		["Defeated"],
		["Fierce"],
		["Grumpy"],
		["Scary"],
		["Fiery"],
		["Brave"],
		["Proud"],
		["Zealous"],
		["Chubby"],
		["Candy"],
		["Square"],
		["Flat"],
		["Colossal"],
		["Great"],
		["Huge"],
		["Large"],
		["Little"],
		["Mammoth"],
		["Massive"],
		["Miniature"],
		["Petite"],
		["Puny"],
		["Teeny"],
		["Faint"],
		["Deafening"],
		["Hissing"],
		["Thundering"],
		["Noisy"],
		["Ancient"],
		["Early"],
		["x"],
		["Fast"],
		["Modern"],
		["Rapid"],
		["Swift"],
		["Young"],
		["Fresh"],
		["Hot"],
		["Icy"],
		["Juicy"],
		["Rainy"],
		["Sticky"],
		["Wet"],
		["Wooden"],
		["Broken"],
		["Cool"],
		["Damaged"],
		["Dirty"],
		["Fluffy"],
		["Empty"],
		["Heavy"],
		["Light"],
		["Abrupt"],
		["Absurd"],
		["Acidic"],
		["Automatic"],
		["Awesome"],
		["Bad"],
		["Bent"],
		["Cynical"],
		["Demonic"],
		["Angelic"],
		["Dab"],
		["Drunk"],
		["Fuzzy"],
		["Gooey"],
		["Gutsy"],
		["Hurt"],
		["Imaginary"],
		["Jazzy"],
		["Teenaged"],
		["Lavish"],
		["Living"],
		["Magical"],
		["Mute"],
		["Manditory"],
		["New"],
		["obnoxious"],
		["Offbeat"],
		["Perfect"],
		["Premium"],
		["Rabid"],
		["Rugged"],
		["Rare"],
		["Real"],
		["Regular"],
		["Royal"],
		["Wild"],
		["Sharp"],
		["Shiny"],
		["Shocking"],
		["Sick"],
		["Simple"],
		["Mr."],
		["Sneaky"],
		["Spooky"],
		["Super"],
		["Swanky"],
		["Taboo"],
		["Tame"],
		["Tranquil"],
		["Trashy"],
		["Unique"],
		["Violent"],
		["Dr."],
		["Mental"],
		["Feral"],
		["Businesss"],
		["Legal"],
		["King"],
		["Pure"],
		["Honerable"],
		["President"],
		["Sargent"],
		["General"],
		["Captain"],
		["Private"],
		["Prince"],
		["Count"],
		["Lord"],
		["Tzar"],
		["Emperor"],
		["Sir"],
		["Professor"],
		["Agent"],
		["Soul"],
		["Polar"],
		["Arc"],
		["Artic"],
		["Spotted"],
		["Mocking"],
		["Atomic"],
		["Mountain"],
		["Jungle"],
		["Bald"],
		["Kung Fu"],
		["Double"],
		["Sexy"],
		["Bloody"],
		["Best"],
		["Flashy"],
		["Failed"],
		["Glass"],
		["Transparent"],
		["Hairy"],
		["Nuclear"],
		["Evil"],
		["Psycho"],
		["Universal"],
		["Glorious"],
		["Funky"],
		["Road"],
		["Mega"],
		["Ultra"],
		["Anon"],
		["The"],
		["War"],
		["Immortal"],
		["Mighty"],
		["Cannibal"],
		["Uncle"],
		["Killer"],
		["Mutant"],
		["Smooth"],
		["Nerdy"],
		["Disco"],
		["Arcane"],
		["Molten"],
		["Phantom"],
		["North"],
		["South"],
		["East"],
		["West"],
		["Gummy"],
		["L33t"],
		["Fake"],
		["Risky"],
		["Rural"],
		["Dank"],
		["Danger"],
		["Half"],
		["Mystery"],
		["Mayor"],
		["CEO"],
		["Chief"],
		["Vegan"],
		["Sad"],
		["Mad"],
		["Genius"],
		["B1G"],
		["Rito"],
		["Human"],
		["Potential"],
		["Native"],
		["Slow"],
		["Crazy"],
		["Fatal"],
		["Astral"],
		["Xtreme"],
		["Sudden"],
		["Organic"],
		["Naked"],
		["Retired"],
		["Midnight"],
		["Missing"],
		["Awkward"],
		["e"],
		["Team"],
		["i"],
		["Fallen"],
		["Reckless"],
		["Forgiven"],
		["Blind"],
		["Retail"],
		["Señorita"],
		["Abraham"],
		["Skrafräken"],
		["Stated"],
		["Seaworthy"],
		["River"],
		["SEAL"],
		["Stomper"],
		["Equal"],
		["2by2"],
		["Squish"],
		["Swiggity"],
		["Faith"],
		["Generic"],
		["Electric"],
		["Strange"],
		["Berserk"],
		["Elite"],
		["Elfin"],
		["Devilish"],
		["Gaudy"],
		["Jaded"],
		["Jittery"],
		["Meek"],
		["Macabre"],
		["Merciful"],
		["Prickly"],
		["Sassy"],
		["Shrill"],
		["Testy"],
		["Venomous"],
		["Wacky"],
		["Warlike"],
		["Wary"],
		["Wry"],
		["Cold"],
		["Ruthless"],
		["Savage"],
		["Spry"],
		["Frosty"],
		["Wandering"],
		["Steam"],
		["Hearty"],
		["Liquid"],
		["Shattered"],
		["Invisible"],
		["Grand"],
		["Tidal"],
		["Loose"],
		["Sinister"],
		["Heartof"],
		["Radiant"],
		["Space"],
		["Mecha"],
		["Twisted"],
		["Eternal"],
		["Exiled"],
		["Eyeof"],
		["Undead"],
		["Battle"],
		["Crystal"],
		["Tr0ll"],
		["Spirit"],
		["Spiritof"],
		["Arrowof"],
		["Crimson"],
		["Unforgiven"],
		["Secret"],
		["Chrono"],
		["Riseofthe"],
		["Deific"],
		["Unfading"],
		["Undying"],
		["Deathless"]
    ];

	
    base = [
	
		["Material"],
		["Velcirptr"],
		["Wolf"],
		["Crab"],
		["Dragoon"],
		["Pheonix"],
		["Eagle"],
		["Lion"],
		["Cat"],
		["Canine"],
		["Dog"],
		["Otter"],
		["Squirrel"],
		["Snake"],
		["Bison"],
		["Aligator"],
		["Racer"],
		["Rat"],
		["Baboon"],
		["Badger"],
		["Mongoose"],
		["Bear"],
		["Monkey"],
		["Vulture"],
		["Owl"],
		["Raven"],
		["Robot"],
		["Cyborg"],
		["Tiger"],
		["Apple"],
		["Mantis"],
		["Tobbagin"],
		["Duck"],
		["Kangaroo"],
		["Zebra"],
		["Wombat"],
		["Cougar"],
		["Dolphin"],
		["Goat"],
		["Mountain"],
		["Hyena"],
		["Pikachu"],
		["Lava"],
		["Dwarf"],
		["Ocean"],
		["Man"],
		["People"],
		["History"],
		["Spartan"],
		["Meat"],
		["Law"],
		["Love"],
		["Science"],
		["Teacher"],
		["Night"],
		["Disease"],
		["Soup"],
		["Student"],
		["Lake"],
		["Debt"],
		["Attitude"],
		["Beer"],
		["Piano"],
		["Potato"],
		["Salad"],
		["Pizza"],
		["Day"],
		["Life"],
		["Year"],
		["Card"],
		["Star"],
		["Shot"],
		["Challenge"],
		["Adult"],
		["Bacon"],
		["Bread"],
		["Unit"],
		["Spider"],
		["Pain"],
		["Grass"],
		["Heat"],
		["Liver"],
		["Cash"],
		["Astronaut"],
		["Marrow"],
		["Pork"],
		["Beef"],
		["Penguin"],
		["Remote"],
		["Bomb"],
		["Beetle"],
		["Cannon"],
		["Glue"],
		["Cemetary"],
		["Scythe"],
		["Sword"],
		["Mace"],
		["Gun"],
		["Kitten"],
		["Chef"],
		["Pirate"],
		["Revolution"],
		["Ninja"],
		["Viking"],
		["Rouge"],
		["Thief"],
		["Fighter"],
		["Mage"],
		["Wizard"],
		["Psychic"],
		["Paladin"],
		["Dragoon"],
		["Sniper"],
		["Engineer"],
		["Medic"],
		["Spy"],
		["Scout"],
		["Assassin"],
		["Destroyer"],
		["Phenom"],
		["Monster"],
		["Sayian"],
		["Pants"],
		["Warrior"],
		["Beserker"],
		["Machine"],
		["Skunk"],
		["Waterboy"],
		["Mayhem"],
		["Pteradactyl"],
		["Crow"],
		["Rampage"],
		["Punk"],
		["Mouse"],
		["Zombie"],
		["Bone"],
		["Outlaw"],
		["Shark"],
		["Killer"],
		["Fury"],
		["Thug"],
		["Enemy"],
		["Child"],
		["Swag"],
		["Kid"],
		["Money"],
		["Force"],
		["Tank"],
		["Garbage"],
		["Alarm"],
		["Bite"],
		["Cloud"],
		["Bottle"],
		["Candle"],
		["Puke"],
		["Soldier"],
		["Knight"],
		["Morning"],
		["Barbarian"],
		["Samurai"],
		["Hero"],
		["Villian"],
		["Necromancer"],
		["Druid"],
		["Gambler"],
		["Shadow"],
		["Priest"],
		["Witch"],
		["Alchemist"],
		["Entertainer"],
		["Maniac"],
		["Mime"],
		["Rider"],
		["Illusionalist"],
		["Mystic"],
		["Monk"],
		["Oracle"],
		["Ranger"],
		["Scavenger"],
		["Dancer"],
		["Knife"],
		["Summoner"],
		["Sk8r"],
		["Ghost"],
		["Virus"],
		["Sloth"],
		["Door"],
		["Party"],
		["Walrus"],
		["Stone"],
		["Squad"],
		["Crowd"],
		["Egg"],
		["Energy"],
		["Limb"],
		["Lamb"],
		["Country"],
		["County"],
		["Company"],
		["Question"],
		["Policy"],
		["Effect"],
		["Show"],
		["Risk"],
		["Juror"],
		["Color"],
		["Pressure"],
		["Attack"],
		["Dream"],
		["Crisis"],
		["Prison"],
		["Fuel"],
		["Horse"],
		["Island"],
		["Zone"],
		["Pleasure"],
		["Fun"],
		["Mass"],
		["Tale"],
		["Joke"],
		["Boss"],
		["Minion"],
		["Stop"],
		["Twr"],
		["Cake"],
		["Pollution"],
		["Criminal"],
		["Baby"],
		["Bat"],
		["Chaos"],
		["Cook"],
		["Trap"],
		["Clown"],
		["Quest"],
		["Rocket"],
		["Rabbit"],
		["Mentor"],
		["Hurricane"],
		["Jail"],
		["Praise"],
		["Musket"],
		["Wolverine"],
		["Wound"],
		["Moose"],
		["H4X0R"],
		["K9"],
		["UFO"],
		["Donut"],
		["Ambush"],
		["Squid"],
		["Gaming"],
		["Shipwreck"],
		["Surfboard"],
		["Judge"],
		["Jury"],
		["Executioner"],
		["Ironside"],
		["Mobster"],
		["Gangster"],
		["Chas3"],
		["Lopez"],
		["Goose"],
		["Champion"],
		["Battler"],
		["Heroine"],
		["Warlord"],
		["Militant"],
		["Scrapper"],
		["Wildcat"],
		["Merc"],
		["Leader"],
		["Sappling"],
		["Tree"],
		["Hobbit"],
		["Blade"],
		["Fist"],
		["Minotaur"],
		["Centaur"],
		["Fossil"],
		["Wanderer"],
		["Golem"],
		["Vengence"],
		["Sheriff"],
		["Bomber"],
		["Boy"],
		["Tooth"],
		["Duelist"],
		["Trickster"],
		["Spear"],
		["One"],
		["Void"],
		["Prophet"],
		["Hunter"],
		["Bounty"],
		["Fate"],
		["Nightmare"],
		["Berserker"],
		["Artisan"],
		["Valor"],
		["Butcher"],
		["Menace"],
		["Wrath"],
		["Lust"],
		["Gluttony"],
		["Greed"],
		["Envy"],
		["Pride"],
		["Jester"],
		["Warden"],
		["Troll"],
		["Bouncer"],
		["Reaper"],
		["Roar"],
		["Grave"],
		["Expert"],
		["Frenzy"],
		["Respite"],
		["Deathstalker"],
		["Rhino"],
		["Mamba"],
		["Buffalo"],
		["Snail"],
		["Frog"],
		["Boomslang"],
		["Leopard"],
		["Viper"],
		["Crocodile"],
		["Mosquito"],
		["Pitbull"],
		["Jaguar"],
		["Vampire"],
		["Gorilla"],
		["Rattlesnake"],
		["Cobra"],
		["Weasel"],
		["Serval"],
		["Scorpion"],
		["Raccoon"],
		["Pug"],
		["Ox"],
		["Hound"],
		["Hornet"],
		["Cockroach"],
		["Ape"],
		["Cossack"],
		["Jagar"],
		["Mortal"]
    ];
	
    suffix = [
			
		["s",1],
		["x",1.5],
		["z",2.5],
		["phobic",2.52],
		["MVP",2.54],
		["Bot",2.94],
		["Jr.",2.96],
		["Express",2.98],
		["Esq.",3],
		["OP",3.2],
		["Sr.",3.22],
		["TheGreat",3.24],
		["TheCruel",3.26],
		["TheHoly",3.28],
		["TheIron",3.3],
		["TheJust",3.32],
		["TheLame",3.34],
		["A",3.36],
		["B",3.38],
		["C",3.4],
		["D",3.42],
		["E",3.44],
		["F",3.46],
		["G",3.48],
		["H",3.5],
		["I",3.52],
		["J",3.54],
		["K",3.56],
		["L",3.58],
		["M",3.6],
		["N",3.62],
		["O",3.64],
		["P",3.66],
		["Q",3.7],
		["R",3.72],
		["S",3.74],
		["T",3.76],
		["U",3.78],
		["V",3.82],
		["W",3.84],
		["X",3.88],
		["Y",3.9],
		["Z",3.94],
		["Club",3.96],
		["Mafia",3.98],
		["Embrace",4],
		["ofDoom",4.02],
		["ofWar",4.04],
		["ofEvil",4.06]

    ];	
	
    number = [
	
		["0",0.03],
		["1",0.53],
		["2",0.78],
		["3",0.88],
		["4",0.93],
		["5",0.98],
		["6",1.01],
		["7",1.04],
		["8",1.07],
		["9",1.1],
		["10",1.13],
		["11",1.16],
		["12",1.19],
		["13",1.22],
		["14",1.25],
		["15",1.28],
		["16",1.31],
		["17",1.34],
		["18",1.37],
		["19",1.4],
		["20",1.43],
		["21",1.46],
		["22",1.49],
		["23",1.52],
		["24",1.55],
		["25",1.58],
		["26",1.61],
		["27",1.64],
		["28",1.67],
		["29",1.7],
		["30",1.73],
		["31",1.76],
		["32",1.79],
		["33",1.82],
		["34",1.85],
		["35",1.88],
		["36",1.91],
		["37",1.94],
		["38",1.97],
		["39",2],
		["40",2.03],
		["41",2.06],
		["42",2.09],
		["43",2.12],
		["44",2.15],
		["45",2.18],
		["46",2.21],
		["47",2.24],
		["48",2.27],
		["49",2.3],
		["50",2.33],
		["51",2.36],
		["52",2.39],
		["53",2.42],
		["54",2.45],
		["55",2.48],
		["56",2.51],
		["57",2.54],
		["58",2.57],
		["59",2.6],
		["60",2.63],
		["61",2.66],
		["62",2.69],
		["63",2.72],
		["64",2.75],
		["65",2.78],
		["66",2.81],
		["67",2.84],
		["68",2.87],
		["69",2.9],
		["70",2.93],
		["71",2.96],
		["72",2.99],
		["73",3.02],
		["74",3.05],
		["75",3.08],
		["76",3.11],
		["77",3.14],
		["78",3.17],
		["79",3.2],
		["80",3.23],
		["81",3.26],
		["82",3.29],
		["83",3.32],
		["84",3.35],
		["85",3.38],
		["86",3.41],
		["87",3.44],
		["88",3.47],
		["89",3.5],
		["90",3.53],
		["91",3.56],
		["92",3.59],
		["93",3.62],
		["94",3.65],
		["95",3.68],
		["96",3.71],
		["97",3.74],
		["98",3.77],
		["99",3.8],
		["100",3.83],
		["666",3.86]
    ];	
	
	
    return {
		prefix: prefix,
		base: base,
		suffix: suffix,
		number: number
    };
});
