define('data/namesScotland',[], function () { "use strict";

		var female, first, last;

		first = [
			["Jack",	540],
			["James",	414],
			["Lewis",	373],
			["Oliver",	362],
			["Logan",	328],
			["Daniel",	322],
			["Noah",	305],
			["Charlie",	296],
			["Lucas",	292],
			["Alexander",	285],
			["Mason",	263],
			["Finlay",	258],
			["Max",	256],
			["Adam",	253],
			["Harry",	251],
			["Harris",	250],
			["Aaron",	247],
			["Ethan",	241],
			["Cameron",	237],
			["Jacob",	231],
			["Callum",	229],
			["Leo",	217],
			["Alfie",	215],
			["Archie",	214],
			["Riley",	209],
			["Thomas",	207],
			["Rory",	204],
			["Nathan",	202],
			["Joshua",	200],
			["Matthew",	199],
			["Jamie",	197],
			["Oscar",	197],
			["Ryan",	189],
			["Luke",	184],
			["William",	165],
			["Dylan",	164],
			["Liam",	158],
			["Andrew",	149],
			["Samuel",	148]
		];

		female = [

			["Emily",	539],
			["Sophie",	514],
			["Olivia",	446],
			["Isla",	401],
			["Jessica",	392],
			["Ava",	349],
			["Amelia",	340],
			["Lucy",	338],
			["Lily",	282],
			["Ella",	256],
			["Sophia",	256],
			["Ellie",	254],
			["Gracie",	244],
			["Freya",	235],
			["Millie",	233],
			["Chloe",	228],
			["Emma",	216],
			["Mia",	213],
			["Eilidh",	207],
			["Anna",	200],
			["Charlotte",	199],
			["Ruby",	194],
			["Eva",	192],
			["Holly",	185],
			["Layla",	178],
			["Hannah",	174],
			["Evie",	160],
			["Katie",	159],
			["Orla",	152],
			["Poppy",	147]


		];

		last = [
			["Smith",	4291],
			["Brown",	3030],
			["Wilson",	2876],
			["Campbell",	2657],
			["Stewart",	2626],
			["Thomson",	2616],
			["Robertson",	2536],
			["Anderson",	2297],
			["Macdonald",	1844],
			["Scott",	1839],
			["Reid",	1771],
			["Murray",	1690],
			["Taylor",	1595],
			["Clark",	1574],
			["Ross",	1481],
			["Watson",	1390],
			["Morrison",	1388],
			["Paterson",	1382],
			["Young",	1373],
			["Mitchell",	1367],
			["Walker",	1352],
			["Fraser",	1319],
			["Miller",	1300],
			["Mcdonald",	1244],
			["Gray",	1211],
			["Henderson",	1210],
			["Hamilton",	1184],
			["Johnston",	1182],
			["Duncan",	1177],
			["Graham",	1176],
			["Ferguson",	1162],
			["Kerr",	1146],
			["Davidson",	1145],
			["Bell",	1135],
			["Cameron",	1100],
			["Kelly",	1092],
			["Martin",	1066],
			["Hunter",	1065],
			["Allan",	1038],
			["Mackenzie",	1026],
			["Grant",	1019],
			["Simpson",	989],
			["Mackay",	953]

		];

	return {
		first: first,
		female: female,
		last: last
	};
});
