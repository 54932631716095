/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesItaly',[], function () {
    "use strict";

    var first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
			 ["Francesco"],
			["Helgi"],
			["Alessandro"],
			["Andrea"],
			["Lorenzo"],
			["Mattia"],
			["Matteo"],
			["Gabriele"],
			["Leonardo"],
			["Riccardo"],
			["Tommaso"],
			["Davide"],
			["Giuseppe"],
			["Antonio"],
			["Federico"],
			["Marco"],
			["Samuele"],
			["Luca"],
			["Giovanni"],
			["Pietro"],
			["Diego"],
			["Filippo"],
			["Alessio"],
			["Giacomo"],
			["Salvatore"],
			["Svangeir"],
			["Stefano"],
			["Vincenzo"],
			["Giulio"],
			["Giorgio"],
			["Þorleikur"],
			["Angelo"],
			["Domenico"],
			["Paolo"],
			["Mario"],
			["Enrico"],
			["Giancarlo"],
			["Basileo"],
			["Aureliano"],
			["Aresio"],
			["Galileo"],
			["Aresio"],
			["Teodoro"],
			["Vittorio"],
			["Mirocleto"],
			["Tosco"],
			["Diocleziano"],
			["Oderico"],
			["Pollione"],
			["Valter"],
			["Maccabeo"],
			["Adelardo"],
			["Sostene"],
			["Sebastiano"],
			["Crespignano"],
			["Luciano"],
			["Sicuro"],
			["Illidio"],
			["Verano"],
			["Agostino"],
			["Fortunato"],
			["Isacco"],
			["Dionigi"],
			["Plutarco"],
			["Clarenzio"],
			["Mattia"],
			["Beltramo"],
			["Adriano"],
			["Costanzo"],
			["Silvestro"],
			["Valerico"],
			["Doroteo"],
			["Panfilo"],
			["Zaccheo"],
			["Erasmo"],
			["Deodato"],
			["Telchide"],
			["Aldo"],
			["Onofrio"],
			["Landolfo"],
			["Speranzio"],
			["Vincenzo"],
			["Mirco"],
			["Gaetano"],
			["Crispino"],
			["Amedeo"],
			["Aronne"],
			["Liborio"],
			["Eliodoro"],
			["Bartolomeo"],

			
    ];

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
			["Rossi",150],
			["Russo",200],
			["Ferrari",300],
			["Esposito",350],
			["Bianchi",490],
			["Romano",550],
			["Colombo",600],
			["Ricci",620],
			["Marino",630],
			["Greco",650],
			["Gallo",660],
			["Conti",680],
			["De Luca",690],
			["Mancini",710],
			["Barbieri",730],
			["Fontana",745],
			["Santoro",750],
			["Moretti",785],
			["Lombardi",790],
			["Rinaldi",800],
			["Caruso",850],
			["Ferrara",860],
			["Galli",880],
			["Martini",900],
			["Leone",920],
			["Longo",930],
			["Gentile",950],
			["Martinelli",960],
			["Vitale",980],
			["Lombardo",1000],		
			["Coppola",1020],
			["De Santis",1030],
			["D'Angelo",1040],
			["Marchetti",1050],
			["Parisi",1060],
			["Villa",1070],		
			["Conte",1080],
			["Ferri",1090],		
			["Marini",1100],		
			["Bianco",1110],		
			["Villa",1120],
			["Sanna",1130],		
			["Farina",1140],		
			["Rizzi",1150],		
			["Giuliani",1160],		
			["Cattaneo",1170],		
			["De Rossa",1180],		
			["Bellini",1190],	
			["Basile",1205],
			["Riva",1210],
			["Donati",1220],
			["Piras",1230],
			["Vitali",1240],
			["Battaglia",1250],
			["Neri",1260],
			["Constantini",1270],
			["Milani",1280],
			["Pagano",1290],
			["Sorrentino",1300],
			["D'Amico",1305],
			["Orlando",1400],
			["Negri",1550],
			["Guerra",1650],
			["Montanari",1660],		
    ];

    return {
        first: first,
        last: last
    };
});
