/** * @name data.names * @namespace First names and last names. */ 

define('data/namesSwitzerland',[], function () { "use strict";
	var first, last;

	// http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
	// Name, Cumulative Frequency
	first = [
			 ["Luca"]   ,
			["Simon"]   ,
			["David"]   ,
			["Marco"]   ,
			["Joel"]    ,
			["Michael"] ,
			["Lukas"]   ,
			["Fabian"]  ,
			["Pascal"]  ,
			["Kevin"]   ,
			["Nicolas"] ,
			["Samuel"]  ,
			["Jan"] ,
			["Patrick"] ,
			["Daniel"]  ,
			["Robin"]   ,
			["Florian"] ,
			["Marc"]    ,
			["Manuel"]  ,
			["Jonas"]   ,
			["Raphael"] ,
			["Dominik"] ,
			["Fabio"]   ,
			["Sandro"]  ,
			["Dario"]   ,
			["Thomas"]  ,
			["Stefan"]  ,
			["Adrian"]  ,
			["Benjamin"]    ,
			["Tim"] ,
			["Tobias"]  ,
			["Christian"]   ,
			["Sven"]    ,
			["Nico"]    ,
			["Cédric"] ,
			["Philipp"] ,
			["Nicola"]  ,
			["Yannick"] ,
			["Dominic"] ,
			["Roman"]   ,
			["Dylan"]   ,
			["Alexandre"]   ,
			["Alessandro"]  ,
			["Silvan"]  ,
			["Lars"]    ,
			["Noah"]    ,
			["Jonathan"]    ,
			["Valentin"]    ,
			["Andreas"] ,
			["Ramon"]   ,
			["Matthias"]    ,
			["Maxime"]  ,
			["Gabriel"] ,
			["Lucas"]   ,
			["Mario"]   ,
			["Jeremy"]  ,
			["Oliver"]  ,
			["Ivan"]    ,
			["Remo"]    ,
			["Matteo"]  ,
			["Quentin"] ,
			["Claudio"] ,
			["Guillaume"]   ,
			["Sébastien"]  ,
			["Alain"]   ,
			["Joshua"]  ,
			["Louis"]   ,
			["Mathieu"] ,
			["Diego"]   ,
			["Noel"]    ,
			["Yann"]    ,
			["Patrik"]  ,
			["Cyrill"]  ,
			["Bruno"]   ,
			["Flavio"]  ,
			["Bastien"] ,
			["Marko"]   ,
			["Adrien"]  ,
			["Nick"]    ,
			["Timo"]    ,
			["Anthony"] ,
			["Gianluca"]    ,
			["Aaron"]   ,
			["Arnaud"]  ,
			["Flavio"]  ,
			["Max"] ,
			["Brian"]   ,
			["Gregory"] ,
			["Luc"] ,
			["Dimitri"] ,


	];

	// http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
	// Name, Cumulative Frequency
	last = [
			["Weiersmüller",   1    ]   ,
			["Augsburger",  2    ]   ,
			["Fawer",   3    ]   ,
			["Kröpfli",    4    ]   ,
			["Wettach", 5    ]   ,
			["Lütscher",   6    ]   ,
			["Held",    7    ]   ,
			["Berchtold",   8    ]   ,
			["Hegetschweiler",  9    ]   ,
			["Jäggli", 10   ]   ,
			["Obi", 11   ]   ,
			["Herzig",  12   ]   ,
			["Spahn",   13   ]   ,
			["Brügger",    14   ]   ,
			["Felber",  15   ]   ,
			["Böni",   16   ]   ,
			["Schwaller",   17   ]   ,
			["Lanz",    18   ]   ,
			["Eberle",  19   ]   ,
			["Trüb",   20   ]   ,
			["Dunkel",  21   ]   ,
			["Liechti", 22   ]   ,
			["Rüesch", 23   ]   ,
			["Naef",    24   ]   ,
			["Ryffel",  25   ]   ,
			["Dauss",   26   ]   ,
			["Corradi", 27   ]   ,
			["Rieben",  28   ]   ,
			["Handschin",   29   ]   ,
			["Haussener",   30  ]   ,           
			["Stuber",  31   ]   ,
			["Althaus", 32   ]   ,
			["Wehrli",  33   ]   ,
			["Zöbeli", 34   ]   ,
			["Schadegg",    35   ]   ,
			["Biefer",  36   ]   ,           
			["Raschle", 37   ]   ,
			["Winzenried",  38   ]   ,           
			["Ramseyer",    39   ]   ,           
			["Hubschmid",   40   ]   ,           
			["Saameli", 41   ]   ,
			["Trachsel",    42   ]   ,           
			["Utiger",  43   ]   ,           
			["Kreis",   44   ]   ,           
			["Brechbühler",    45   ]   ,           
			["Beyeler", 46   ]   ,           
			["Knöpfli",    47   ]   ,           
			["Gosteli", 48   ]   ,       
			["Koller",  49   ]   ,   
			["Mettler", 50   ]   ,   
			["Ottiker", 51   ]   ,   
			["Badertscher", 52   ]   ,   
			["Niedermaier", 53   ]   ,   
			["Sigrist", 54   ]   ,   
			["Thomi",   55   ]   ,   
			["Rätz",   56   ]   ,   
			["Schiffer",    57   ]   ,   
			["Städeli",    58   ]   ,   
			["Kasteler",    59   ]   ,   
			["Anderegg",    60   ]   ,   
			["Wittwer", 61  ]   ,   
			["Freund",  62   ]   ,   
			["Marchion",    63   ]   ,   
			["Greuter", 64   ]   ,
			["Ruffner", 65   ]   ,
			["Wyss",    66   ]   ,
			["Gehrig",  67   ]   ,
			["Rubi",    68   ]   ,
			["Körner", 69   ]   ,
			["Tschaggeny",  70   ]   ,
			["Grünenwald", 71   ]   ,
			["Frommenwiler",    72   ]   ,
			["Kramer",  73   ]   ,
			["Leeger",  74   ]   ,
			["Fricker", 75   ]   ,
			["Inäbnit",    76   ]   ,
			["Braun",   77   ]   ,
			["Rhyner",  78   ]   ,       
			["Lüscher",    79   ]   ,   
			["Moosmann", 80  ]   ,   
			["Pfister", 81   ]   ,   
			["Enz", 82   ]   ,   
			["Neukomm", 83   ]   ,                           
	];

	return {
		first: first,
		last: last
	};
});
