/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesTurkey',[], function () {
    "use strict";

    var first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
		["Abbas"]	,
		["Adem"]	,
		["Ahmad"]	,
		["Ahmed"]	,
		["Ahmet"]	,
		["Alp"]	,
		["Altan"]	,
		["Arash"]	,
		["Arif"]	,
		["Arkados"]	,
		["Ata"]	,
		["Aydin"]	,
		["Aykut"]	,
		["Bagatur"]	,
		["Baghadur"]	,
		["Baghatur"]	,
		["Bahadir"]	,
		["Bahi"]	,
		["Baki"]	,
		["Bari"]	,
		["Baris"]	,
		["Basir"]	,
		["Bedir"]	,
		["Berk"]	,
		["Berkant"]	,
		["Berker"]	,
		["Bora"]	,
		["Bulut"]	,
		["Cagatay"]	,
		["Cagri"]	,
		["Cahit"]	,
		["Cairo"]	,
		["Can"]	,
		["Candan"]	,
		["Cengiz"]	,
		["Coskun"]	,
		["Direnc"]	,
		["Ediz"]	,
		["Emin"]	,
		["Emre"]	,
		["Engin"]	,
		["Enver"]	,
		["Erdem"]	,
		["Erdogan"]	,
		["Eren"]	,
		["Erol"]	,
		["Evner"]	,
		["Eyup"]	,
		["Fadahunsi"]	,
		["Faruk"]	,
		["Fatin"]	,
		["Feza"]	,
		["Goker"]	,
		["Guül"]	,
		["Guülbahar"]	,
		["Guven"]	,
		["Hakan"]	,
		["Hakeem"]	,
		["Halil"]	,
		["Halim"]	,
		["Halit"]	,
		["Hasad"]	,
		["Himmet"]	,
		["Hud"]	,
		["Husamettin"]	,
		["Ibrahim"]	,
		["Ihsan"]	,
		["Ilhami"]	,
		["Ilhan"]	,
		["Ilker"]	,
		["Ilkin"]	,
		["Iskander"]	,
		["Iskender"]	,
		["Ismet"]	,
		["Jadallah"]	,
		["Jebran"]	,
		["Kabil"]	,
		["Kadim"]	,
		["Kadir"]	,
		["Kahraman"]	,
		["Kebir"]	,
		["Kemal"]	,
		["Kerem"]	,
		["Khalid"]	,
		["Khan"]	,
		["Kiral"]	,
		["Koray"]	,
		["Kudret"]	,
		["Kuvet"]	,
		["Levent"]	,
		["Madarik"]	,
		["Mamun"]	,
		["Mehmed"]	,
		["Mehmet"]	,
		["Mehr"]	,
		["Messut"]	,
		["Mesud"]	,
		["Mesut"]	,
		["Metin"]	,
		["Mihriban"]	,
		["Mukhtar"]	,
		["Murat"]	,
		["Muzaffer"]	,
		["Naji"]	,
		["Nazik"]	,
		["Nche"]	,
		["Necati"]	,
		["Ohannes"]	,
		["Ömer"]	,
		["Onan"]	,
		["Onur"]	,
		["Osman"]	,
		["Ottmar"]	,
		["Oz"]	,
		["Ozkan"]	,
		["Ozqur"]	,
		["Ozturk"]	,
		["Qamar"]	,
		["Rakin"]	,
		["Ramadan"]	,
		["Said"]	,
		["Salik"]	,
		["Savas"]	,
		["Selim"]	,
		["Sener"]	,
		["Serhat"]	,
		["Serkan"]	,
		["Sevilen"]	,
		["Sevim"]	,
		["Shakir"]	,
		["Sinan"]	,
		["Soner"]	,
		["Sukru"]	,
		["Sukuru"]	,
		["Suleiman"]	,
		["Suleyman"]	,
		["Tabari"]	,
		["Tabib"]	,
		["Temel"]	,
		["Temin"]	,
		["Tevfik"]	,
		["Thabit"]	,
		["Tolga"]	,
		["Tugay"]	,
		["Tunc"]	,
		["Turan"]	,
		["Turgay"]	,
		["Ufuk"]	,
		["Umit"]	,
		["Uner"]	,
		["Volkan"]	,
		["Xerxes"]	,
		["Yavuz"]	,
		["Yurcel"]	,
		["Zeheb"]	,
		["Zeki"]	,
		["Ziya"]	,
		["Zuhayr"]	

    ];

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
			["Abacı"]	,
			["Abdil"]	,
			["Açık"]	,
			["Adanır"]	,
			["Adem"]	,
			["Adin"]	,
			["Adıvar"]	,
			["Ağa"]	,
			["Ağaoğlu"]	,
			["Ağca"]	,
			["Ağçay"]	,
			["Ahmad"]	,
			["Ak"]	,
			["Akagündüz"]	,
			["Akalın"]	,
			["Akar"]	,
			["Akarsu"]	,
			["Akbaba"]	,
			["Akbaş"]	,
			["Akbay"]	,
			["Akbulut"]	,
			["Akburç"]	,
			["Akçam"]	,
			["Akçatepe"]	,
			["Akdarı"]	,
			["Akdemir"]	,
			["Akgül"]	,
			["Akgün"]	,
			["Akın"]	,
			["Akıncı"]	,
			["Akkaş"]	,
			["Akkaya"]	,
			["Akkoyun"]	,
			["Akman"]	,
			["Akpınar"]	,
			["Akşit"]	,
			["Aksoy"]	,
			["Aksu"]	,
			["Aktaş"]	,
			["Aktuna"]	,
			["Akyıldız"]	,
			["Akyol"]	,
			["Akyürek"]	,
			["Akyüz"]	,
			["Alabora"]	,
			["Aladağ"]	,
			["Albayrak"]	,
			["Aldemir"]	,
			["Ali"]	,
			["Alkan"]	,
			["Alpay"]	,
			["Alptekin"]	,
			["Altan"]	,
			["Altın"]	,
			["Altıntop"]	,
			["Altun"]	,
			["Apak"]	,
			["Arat"]	,
			["Arıca"]	,
			["Arıkan"]	,
			["Armağan"]	,
			["Arman"]	,
			["Asani"]	,
			["Asena"]	,
			["Aşık"]	,
			["Aslan"]	,
			["Ata"]	,
			["Atakan"]	,
			["Atalar"]	,
			["Atalay"]	,
			["Ataman"]	,
			["Atan"]	,
			["Ataseven"]	,
			["Atay"]	,
			["Ateş"]	,
			["Avcı"]	,
			["Avni"]	,
			["Ayda"]	,
			["Aydan"]	,
			["Aydemir"]	,
			["Aydın"]	,
			["Aydınlar"]	,
			["Aydoğan"]	,
			["Aydoğdu"]	,
			["Aygün"]	,
			["Ayhan"]	,
			["Ayik"]	,
			["Aykaç"]	,
			["Aykut"]	,
			["Ayral"]	,
			["Aytaç"]	,
			["Ayvaz"]	,
			["Babacan"]	,
			["Babaoğlu"]	,
			["Bagci"]	,
			["Bahadır"]	,
			["Bakkal"]	,
			["Bal"]	,
			["Balbay"]	,
			["Balcan"]	,
			["Balcı"]	,
			["Bardakçı"]	,
			["Başak"]	,
			["Başar"]	,
			["Başaran"]	,
			["Başer"]	,
			["Baştürk"]	,
			["Batuk"]	,
			["Bayar"]	,
			["Baybaşin"]	,
			["Baydar"]	,
			["Baykara"]	,
			["Bayraktar"]	,
			["Bayramoğlu"]	,
			["Behçet"]	,
			["Benli"]	,
			["Berker"]	,
			["Bilgi"]	,
			["Bilgili"]	,
			["Bilgin"]	,
			["Bilici"]	,
			["Birdal"]	,
			["Birkan"]	,
			["Birol"]	,
			["Birsen"]	,
			["Bolat"]	,
			["Bozer"]	,
			["Bozgüney"]	,
			["Bozkurt"]	,
			["Boztepe"]	,
			["Bucak"]	,
			["Buğra"]	,
			["Büker"]	,
			["Bulut"]	,
			["Buruk"]	,
			["Büyük"]	,
			["Çağatay"]	,
			["Çağlar"]	,
			["Çağrı"]	,
			["Çakır"]	,
			["Çakmak"]	,
			["Çalık"]	,
			["Çalış"]	,
			["Candan"]	,
			["Candemir"]	,
			["Caner"]	,
			["Çatlı"]	,
			["Çavdarlı"]	,
			["Cavus"]	,
			["Celal"]	,
			["Çelik"]	,
			["Cerci"]	,
			["Ceren"]	,
			["Çetin"]	,
			["Çetinkaya"]	,
			["Cevahir"]	,
			["Cevdet"]	,
			["Çevik"]	,
			["Ceylan"]	,
			["Çiçek"]	,
			["Çiftçi"]	,
			["Cihan"]	,
			["Çimen"]	,
			["Çoban"]	,
			["Çolak"]	,
			["Coşkun"]	,
			["Çubukçu"]	,
			["Cumali"]	,
			["Dağdelen"]	,
			["Dağtekin"]	,
			["Dalkılıç"]	,
			["Dalman"]	,
			["Dede"]	,
			["Deliktaş"]	,
			["Demir"]	,
			["Demirbaş"]	,
			["Demirel"]	,
			["Demirkan"]	,
			["Demirören"]	,
			["Demirtaş"]	,
			["Denkel"]	,
			["Denktaş"]	,
			["Derici"]	,
			["Derviş"]	,
			["Dilaver"]	,
			["Dinç"]	,
			["Dinçer"]	,
			["Dink"]	,
			["Doğan"]	,
			["Doğançay"]	,
			["Doğu"]	,
			["Duman"]	,
			["Durmaz"]	,
			["Dursun"]	,
			["Ece"]	,
			["Ecevit"]	,
			["Ekici"]	,
			["Ekinci"]	,
			["Ekren"]	,
			["Ekşi"]	,
			["Engin"]	,
			["Enver"]	,
			["Erbakan"]	,
			["Ercan"]	,
			["Erçetin"]	,
			["Erdal"]	,
			["Erdem"]	,
			["Erdinç"]	,
			["Erdoğan"]	,
			["Erem"]	,
			["Eren"]	,
			["Ergen"]	,
			["Ergin"]	,
			["Ergün"]	,
			["Erim"]	,
			["Eriş"]	,
			["Erkan"]	,
			["Erkin"]	,
			["Erkoç"]	,
			["Eroğlu"]	,
			["Erol"]	,
			["Ersin"]	,
			["Ersoy"]	,
			["Ersöz"]	,
			["Ertegün"]	,
			["Ertuğ"]	,
			["Eyüboğlu"]	,
			["Fahri"]	,
			["Farhi"]	,
			["Fırat"]	,
			["Fişek"]	,
			["Fraşerli"]	,
			["Genç"]	,
			["Giray"]	,
			["Gökay"]	,
			["Gökbakar"]	,
			["Gökçe"]	,
			["Gökçek"]	,
			["Gökçen"]	,
			["Gökdemir"]	,
			["Gökmen"]	,
			["Göllü"]	,
			["Gönül"]	,
			["Görgülü"]	,
			["Gözübüyük"]	,
			["Güçer"]	,
			["Güçlü"]	,
			["Gulden"]	,
			["Güler"]	,
			["Gülpınar"]	,
			["Gültekin"]	,
			["Gün"]	,
			["Günay"]	,
			["Günaydın"]	,
			["Gündoğdu"]	,
			["Gündüz"]	,
			["Güneş"]	,
			["Güngör"]	,
			["Gür"]	,
			["Gürsel"]	,
			["Gürses"]	,
			["Güven"]	,
			["Güvenç"]	,
			["Hacıoğlu"]	,
			["Halefoğlu"]	,
			["Hamdi"]	,
			["Hamzaoğlu"]	,
			["Hanım"]	,
			["Haşim"]	,
			["Hayrettin"]	,
			["Heper"]	,
			["Hikmet"]	,
			["Hoca"]	,
			["Hussein"]	,
			["Ilhan"]	,
			["Ilkin"]	,
			["İnan"]	,
			["İnanç"]	,
			["İncesu"]	,
			["İnci"]	,
			["İnönü"]	,
			["İpekçi"]	,
			["Irmak"]	,
			["İşcan"]	,
			["Işık"]	,
			["Isler"]	,
			["Izzet"]	,
			["Jamaković"]	,
			["Kaba"]	,
			["Kahraman"]	,
			["Kahveci"]	,
			["Kahya"]	,
			["Kaldırım"]	,
			["Kaner"]	,
			["Kaptan"]	,
			["Karabulut"]	,
			["Karaca"]	,
			["Karadag"]	,
			["Karadeniz"]	,
			["Karadere"]	,
			["Karaduman"]	,
			["Karagöz"]	,
			["Karahan"]	,
			["Karakaş"]	,
			["Karakaya"]	,
			["Karakoç"]	,
			["Karakuş"]	,
			["Karatay"]	,
			["Kashani"]	,
			["Kavak"]	,
			["Kavur"]	,
			["Kaynarca"]	,
			["Kayyali"]	,
			["Kekilli"]	,
			["Keleş"]	,
			["Kerimoğlu"]	,
			["Kılıçlı"]	,
			["Kimyacıoğlu"]	,
			["Kinali"]	,
			["Kıraç"]	,
			["Kiraz"]	,
			["Kirdar"]	,
			["Kıvanç"]	,
			["Kizil"]	,
			["Kızılırmak"]	,
			["Kızılkaya"]	,
			["Kobal"]	,
			["Koç"]	,
			["Koca"]	,
			["Koçak"]	,
			["Kocaman"]	,
			["Koçer"]	,
			["Koçoğlu"]	,
			["Koçyiğit"]	,
			["Köksal"]	,
			["Konca"]	,
			["Köprülü"]	,
			["Koray"]	,
			["Korkmaz"]	,
			["Koyuncu"]	,
			["Koz"]	,
			["Közen"]	,
			["Kubat"]	,
			["Kubilay"]	,
			["Kunt"]	,
			["Kunter"]	,
			["Kurtar"]	,
			["Kurtoğlu"]	,
			["Kuş"]	,
			["Kutay"]	,
			["Kutlu"]	,
			["Levni"]	,
			["Mehmed"]	,
			["Memiş"]	,
			["Mencik"]	,
			["Menderes"]	,
			["Meric"]	,
			["Metin"]	,
			["Muhiddin"]	,
			["Muhtar"]	,
			["Müjde"]	,
			["Mumcu"]	,
			["Mungan"]	,
			["Mustafa"]	,
			["Mutlu"]	,
			["Nabi"]	,
			["Nalci"]	,
			["Nazif"]	,
			["Nazlı"]	,
			["Nazmi"]	,
			["Necmi"]	,
			["Neyzi"]	,
			["Niazi"]	,
			["Ocak"]	,
			["Öcal"]	,
			["Öçal"]	,
			["Öcalan"]	,
			["Odabaşı"]	,
			["Öktem"]	,
			["Okur"]	,
			["Okyar"]	,
			["Okyay"]	,
			["Olgun"]	,
			["Ölmez"]	,
			["Önal"]	,
			["Onaral"]	,
			["Onarıcı"]	,
			["Öncel"]	,
			["Önder"]	,
			["Onut"]	,
			["Orbay"]	,
			["Örnek"]	,
			["Öz"]	,
			["Özal"]	,
			["Özbek"]	,
			["Özbey"]	,
			["Özbilen"]	,
			["Özbilgin"]	,
			["Özcan"]	,
			["Özçelik"]	,
			["Özdemir"]	,
			["Özden"]	,
			["Özek"]	,
			["Özel"]	,
			["Özen"]	,
			["Özer"]	,
			["Özgen"]	,
			["Özgür"]	,
			["Özhan"]	,
			["Özkan"]	,
			["Özker"]	,
			["Özkök"]	,
			["Özkul"]	,
			["Özmen"]	,
			["Özmert"]	,
			["Özoğuz"]	,
			["Öztoprak"]	,
			["Öztürk"]	,
			["Pamuk"]	,
			["Parlak"]	,
			["Paşa"]	,
			["Peker"]	,
			["Poçan"]	,
			["Polat"]	,
			["Poyraz"]	,
			["Remzi"]	,
			["Renda"]	,
			["Reza"]	,
			["Sabancı"]	,
			["Sabri"]	,
			["Saçan"]	,
			["Şafak"]	,
			["Sağlam"]	,
			["Sağlık"]	,
			["Şahan"]	,
			["Şahin"]	,
			["Şakir"]	,
			["Saltik"]	,
			["Şamdereli"]	,
			["Sancak"]	,
			["Sancaklı"]	,
			["Sançar"]	,
			["Şanlı"]	,
			["Sarı"]	,
			["Sarıca"]	,
			["Sarıgül"]	,
			["Sarıkaya"]	,
			["Sarper"]	,
			["Şaşmaz"]	,
			["Savaş"]	,
			["Saygı"]	,
			["Sayın"]	,
			["Saylan"]	,
			["Şeker"]	,
			["Şen"]	,
			["Şener"]	,
			["Şengül"]	,
			["Şensoy"]	,
			["Şentürk"]	,
			["Şerif"]	,
			["Sevgi"]	,
			["Sevim"]	,
			["Seyfi"]	,
			["Sezen"]	,
			["Sezer"]	,
			["Sezgin"]	,
			["Şimşek"]	,
			["Şipal"]	,
			["Şirin"]	,
			["Sofuoğlu"]	,
			["Sökmen"]	,
			["Sokullu"]	,
			["Solak"]	,
			["Sönmez"]	,
			["Soysal"]	,
			["Sözen"]	,
			["Sporel"]	,
			["Süleymanoğlu"]	,
			["Sümer"]	,
			["Sunay"]	,
			["Sunter"]	,
			["Suvari"]	,
			["Talay"]	,
			["Tandoğan"]	,
			["Taner"]	,
			["Tansel"]	,
			["Tanyu"]	,
			["Tarcan"]	,
			["Tarhan"]	,
			["Taş"]	,
			["Taşçı"]	,
			["Taşkıran"]	,
			["Tayfur"]	,
			["Taylan"]	,
			["Tekeli"]	,
			["Tekin"]	,
			["Terzi"]	,
			["Terzioğlu"]	,
			["Tezcan"]	,
			["Tiryaki"]	,
			["Togan"]	,
			["Togay"]	,
			["Toker"]	,
			["Topbaş"]	,
			["Toprak"]	,
			["Topuz"]	,
			["Toraman"]	,
			["Tosun"]	,
			["Tümer"]	,
			["Tunç"]	,
			["Tuncel"]	,
			["Tuncer"]	,
			["Türel"]	,
			["Turgut"]	,
			["Türkay"]	,
			["Türker"]	,
			["Türkyılmaz"]	,
			["Tüzün"]	,
			["Uçar"]	,
			["Üçüncü"]	,
			["Uğurlu"]	,
			["Ulusoy"]	,
			["Ünal"]	,
			["Üner"]	,
			["Ünsal"]	,
			["Usak"]	,
			["Uslu"]	,
			["Usta"]	,
			["Uyanık"]	,
			["Uygun"]	,
			["Uysal"]	,
			["Uzer"]	,
			["Üzümcü"]	,
			["Uzunlar"]	,
			["Veli"]	,
			["Volkan"]	,
			["Vural"]	,
			["Yağcı"]	,
			["Yağmur"]	,
			["Yakin"]	,
			["Yalaz"]	,
			["Yalçın"]	,
			["Yalçınkaya"]	,
			["Yanki"]	,
			["Yaşar"]	,
			["Yasin"]	,
			["Yavaş"]	,
			["Yavuz"]	,
			["Yazar"]	,
			["Yazıcı"]	,
			["Yenal"]	,
			["Yener"]	,
			["Yerli"]	,
			["Yerlikaya"]	,
			["Yeşil"]	,
			["Yeşilnil"]	,
			["Yetiş"]	,
			["Yiğit"]	,
			["Yıldırım"]	,
			["Yıldızoğlu"]	,
			["Yılmaz"]	,
			["Yüce"]	,
			["Yücel"]	,
			["Yüksel"]	,
			["Yumlu"]	,
			["Zaimoğlu"]	,
			["Zarakolu"]	,
			["Zengin"]	,
			["Zeybek"]	,
			["Zorlu"]	

    ];

    return {
        first: first,
        last: last
    };
});
