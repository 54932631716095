/**
 * @name data.championPatch
 * @namespace Name, Role, Role, Attack, Defense, Ability, Difficulty, Tracking Number (hid)
 */
define('data/championPatch',[], function () {
    "use strict";

    var championPatch;

// http://leagueoflegends.wikia.com/wiki/List_of_champions

// direct copy and past of champ rankings with corresponding role
//http://champion.gg/statistics/#?sortBy=general.overallPosition&order=ascend
    // Name, Role, Role, Attack, Defense, Ability, Difficulty, Tracking Number (hid)
    championPatch = [
	/*
			["ADC","Ashe",	62],
			["ADC","Caitlyn",	30],
			["ADC","Corki",	13],
			["ADC","Draven",	33],
			["ADC","Ezreal",	10],
			["ADC","Graves",	39],
			["ADC","Jinx",	2],
			["ADC","Kalista",	78],
			["ADC","Kog'Maw",	83],
			["ADC","Lucian",	60],
			["ADC","Miss Fortune",	68],
			["ADC","Quinn",	88],
			["ADC","Sivir",	23],
			["ADC","Tristana",	53],
			["ADC","Twitch",	73],
			["ADC","Urgot",	92],
			["ADC","Varus",	43],
			["ADC","Vayne",	17],
			["ADC","Mordekaiser",	46],
			["Jungle","Aatrox",	115],
			["Jungle","Amumu",	29],
			["Jungle","Diana",	9],
			["Jungle","Dr. Mundo",	166],
			["Jungle","Ekko",	55],
			["Jungle","Elise",	71],
			["Jungle","Evelynn",	25],
			["Jungle","Fizz",	103],
			["Jungle","Gragas",	150],
			["Jungle","Hecarim",	85],
			["Jungle","Jarvan IV",	163],
			["Jungle","Jax",	108],
			["Jungle","Kayle",	36],
			["Jungle","Kha'Zix",	107],
			["Jungle","Lee Sin",	119],
			["Jungle","Malphite",	160],
			["Jungle","Maokai",	174],
			["Jungle","Master Yi",	20],
			["Jungle","Nautilus",	168],
			["Jungle","Nidalee",	89],
			["Jungle","Nocturne",	56],
			["Jungle","Nunu",	153],
			["Jungle","Olaf",	137],
			["Jungle","Pantheon",	149],
			["Jungle","Poppy",	144],
			["Jungle","Rammus",	130],
			["Jungle","Rek'Sai",	127],
			["Jungle","Rengar",	50],
			["Jungle","Sejuani",	124],
			["Jungle","Shaco",	79],
			["Jungle","Shyvana",	3],
			["Jungle","Sion",	172],
			["Jungle","Skarner",	31],
			["Jungle","Tahm Kench",	96],
			["Jungle","Trundle",	138],
			["Jungle","Tryndamere",	132],
			["Jungle","Udyr",	67],
			["Jungle","Vi",	94],
			["Jungle","Volibear",	121],
			["Jungle","Warwick",	42],
			["Jungle","Wukong",	65],
			["Jungle","Xin Zhao",	11],
			["Jungle","Zac",	157],
			["Jungle","Fiddlesticks",	143],
			["Middle","Ahri",	8],
			["Middle","Akali",	170],
			["Middle","Anivia",	21],
			["Middle","Annie",	18],
			["Middle","Azir",	162],
			["Middle","Brand",	81],
			["Middle","Cassiopeia",	173],
			["Middle","Cho'Gath",	48],
			["Middle","Diana",	86],
			["Middle","Ekko",	151],
			["Middle","Fizz",	114],
			["Middle","Galio",	131],
			["Middle","Jayce",	125],
			["Middle","Karma",	156],
			["Middle","Karthus",	102],
			["Middle","Kassadin",	165],
			["Middle","Katarina",	64],
			["Middle","Kayle",	136],
			["Middle","Kennen",	148],
			["Middle","Kog'Maw",	91],
			["Middle","LeBlanc",	109],
			["Middle","Lissandra",	139],
			["Middle","Lulu",	159],
			["Middle","Lux",	4],
			["Middle","Malzahar",	27],
			["Middle","Morgana",	75],
			["Middle","Orianna",	141],
			["Middle","Ryze",	177],
			["Middle","Swain",	106],
			["Middle","Syndra",	134],
			["Middle","Talon",	34],
			["Middle","Twisted Fate",	44],
			["Middle","Urgot",	175],
			["Middle","Varus",	116],
			["Middle","Veigar",	15],
			["Middle","Vel'Koz",	51],
			["Middle","Viktor",	66],
			["Middle","Vladimir",	128],
			["Middle","Xerath",	77],
			["Middle","Yasuo",	58],
			["Middle","Zed",	38],
			["Middle","Ziggs",	98],
			["Middle","Zilean",	154],
			["Middle","Zyra",	146],
			["Middle","Heimerdinger",	122],
			["Support","Alistar",	69],
			["Support","Annie",	84],
			["Support","Bard",	35],
			["Support","Blitzcrank",	5],
			["Support","Brand",	59],
			["Support","Braum",	47],
			["Support","Galio",	110],
			["Support","Janna",	7],
			["Support","Karma",	99],
			["Support","Kennen",	105],
			["Support","Leona",	45],
			["Support","Lulu",	76],
			["Support","Morgana",	16],
			["Support","Nami",	24],
			["Support","Nautilus",	72],
			["Support","Nunu",	117],
			["Support","Sona",	26],
			["Support","Soraka",	12],
			["Support","Tahm Kench",	93],
			["Support","Taric",	101],
			["Support","Thresh",	52],
			["Support","Vel'Koz",	87],
			["Support","Zilean",	40],
			["Support","Zyra",	63],
			["Support","Fiddlesticks",	113],
			["Top","Aatrox",	97],
			["Top","Akali",	169],
			["Top","Cassiopeia",	185],
			["Top","Cho'Gath",	82],
			["Top","Darius",	22],
			["Top","Dr. Mundo",	158],
			["Top","Ekko",	145],
			["Top","Fiora",	41],
			["Top","Fizz",	178],
			["Top","Galio",	90],
			["Top","Gangplank",	1],
			["Top","Garen",	6],
			["Top","Gnar",	100],
			["Top","Hecarim",	49],
			["Top","Irelia",	19],
			["Top","Jarvan IV",	104],
			["Top","Jax",	142],
			["Top","Jayce",	118],
			["Top","Karthus",	95],
			["Top","Kassadin",	186],
			["Top","Kayle",	120],
			["Top","Kennen",	133],
			["Top","Lissandra",	123],
			["Top","Lulu",	183],
			["Top","Malphite",	70],
			["Top","Maokai",	180],
			["Top","Nasus",	80],
			["Top","Olaf",	129],
			["Top","Pantheon",	112],
			["Top","Poppy",	176],
			["Top","Quinn",	171],
			["Top","Renekton",	14],
			["Top","Rengar",	57],
			["Top","Riven",	28],
			["Top","Rumble",	164],
			["Top","Ryze",	184],
			["Top","Shen",	140],
			["Top","Shyvana",	152],
			["Top","Singed",	74],
			["Top","Sion",	179],
			["Top","Swain",	147],
			["Top","Tahm Kench",	111],
			["Top","Teemo",	135],
			["Top","Trundle",	155],
			["Top","Tryndamere",	61],
			["Top","Urgot",	187],
			["Top","Vladimir",	54],
			["Top","Volibear",	167],
			["Top","Wukong",	32],
			["Top","Yasuo",	37],
			["Top","Yorick",	182],
			["Top","Zac",	161],
			["Top","Heimerdinger",	126],
			["Top","Mordekaiser",	181	]
*/

      ["Top","Malphite",1],
      ["Top","Shen",4],
      ["Top","Camille",7],
      ["Top","Ornn",10],
      ["Top","Darius",13],
      ["Top","Maokai",16],
      ["Top","Kayle",19],
      ["Top","Aatrox",22],
      ["Top","Akali",25],
      ["Top","Volibear",28],
      ["Top","Poppy",31],
      ["Top","Jayce",34],
      ["Top","Renekton",37],
      ["Top","Fiora",40],
      ["Top","Quinn",43],
      ["Top","Jax",46],
      ["Top","Sion",49],
      ["Top","Kled",52],
      ["Top","Sylas",55],
      ["Top","Sett",58],
      ["Top","Teemo",61],
      ["Top","Heimerdinger",64],
      ["Top","Viktor",67],
      ["Top","Garen",70],
      ["Top","Illaoi",73],
      ["Top","Lucian",76],
      ["Top","Mordekaiser",79],
      ["Top","Rengar",82],
      ["Top","Zac",85],
      ["Top","Vayne",88],
      ["Top","Wukong",91],
      ["Top","Kennen",94],
      ["Top","Riven",97],
      ["Top","Singed",100],
      ["Top","Olaf",103],
      ["Top","Irelia",106],
      ["Top","Gangplank",109],
      ["Top","Nasus",112],
      ["Top","Tryndamere",115],
      ["Top","Cho'Gath",118],
      ["Top","Rumble",121],
      ["Top","Vladimir",124],
      ["Top","Gnar",127],
      ["Top","Ryze",130],
      ["Top","Kalista",133],
      ["Top","Urgot",136],
      ["Top","Yorick",139],
      ["Top","Yone",142],
      ["Top","Yasuo",145],
      ["Top","Dr. Mundo",148],
      ["Jungle","Kayn",1],
      ["Jungle","Amumu",5],
      ["Jungle","Nunu & Willump",9],
      ["Jungle","Graves",13],
      ["Jungle","Kha'Zix",17],
      ["Jungle","Rammus",21],
      ["Jungle","Hecarim",25],
      ["Jungle","Rek'Sai",29],
      ["Jungle","Poppy",33],
      ["Jungle","Evelynn",37],
      ["Jungle","Zac",41],
      ["Jungle","Olaf",45],
      ["Jungle","Elise",49],
      ["Jungle","Shaco",53],
      ["Jungle","Master Yi",57],
      ["Jungle","Udyr",61],
      ["Jungle","Sejuani",65],
      ["Jungle","Kindred",69],
      ["Jungle","Fiddlesticks",73],
      ["Jungle","Skarner",77],
      ["Jungle","Lillia",81],
      ["Jungle","Xin Zhao",85],
      ["Jungle","Lee Sin",89],
      ["Jungle","Vi",93],
      ["Jungle","Jarvan IV",97],
      ["Jungle","Jax",101],
      ["Jungle","Ekko",105],
      ["Jungle","Warwick",109],
      ["Jungle","Volibear",113],
      ["Jungle","Nidalee",117],
      ["Jungle","Dr. Mundo",121],
      ["Jungle","Shyvana",125],
      ["Jungle","Gragas",129],
      ["Jungle","Nocturne",133],
      ["Jungle","Karthus",137],
      ["Jungle","Trundle",141],
      ["Jungle","Ivern",145],
      ["Jungle","Sylas",149],
      ["Jungle","Rengar",153],
      ["Middle","Fizz",1],
      ["Middle","Ekko",4],
      ["Middle","Viktor",7],
      ["Middle","Akali",10],
      ["Middle","Zed",13],
      ["Middle","Galio",16],
      ["Middle","Sylas",19],
      ["Middle","Malphite",22],
      ["Middle","Kayle",25],
      ["Middle","Zoe",28],
      ["Middle","Katarina",31],
      ["Middle","Morgana",34],
      ["Middle","LeBlanc",37],
      ["Middle","Cassiopeia",40],
      ["Middle","Malzahar",43],
      ["Middle","Twisted Fate",46],
      ["Middle","Annie",49],
      ["Middle","Talon",52],
      ["Middle","Orianna",55],
      ["Middle","Lucian",58],
      ["Middle","Diana",61],
      ["Middle","Sett",64],
      ["Middle","Ahri",67],
      ["Middle","Brand",70],
      ["Middle","Nunu & Willump",73],
      ["Middle","Xerath",76],
      ["Middle","Yasuo",79],
      ["Middle","Jayce",82],
      ["Middle","Rumble",85],
      ["Middle","Aurelion Sol",88],
      ["Middle","Yone",91],
      ["Middle","Pyke",94],
      ["Middle","Vladimir",97],
      ["Middle","Aatrox",100],
      ["Middle","Neeko",103],
      ["Middle","Syndra",106],
      ["Middle","Kassadin",109],
      ["Middle","Lissandra",112],
      ["Middle","Anivia",115],
      ["Middle","Qiyana",118],
      ["Middle","Lux",121],
      ["Middle","Corki",124],
      ["Middle","Garen",127],
      ["Middle","Renekton",130],
      ["Middle","Irelia",133],
      ["Middle","Cho'Gath",136],
      ["Middle","Azir",139],
      ["Middle","Nocturne",142],
      ["Middle","Ryze",145],
      ["Middle","Ziggs",148],
      ["Middle","Veigar",151],
      ["ADC","Jhin",1],
      ["ADC","Samira",7],
      ["ADC","Ezreal",13],
      ["ADC","Swain",19],
      ["ADC","Kai'Sa",25],
      ["ADC","Miss Fortune",31],
      ["ADC","Vayne",37],
      ["ADC","Lucian",43],
      ["ADC","Ashe",49],
      ["ADC","Caitlyn",55],
      ["ADC","Tristana",61],
      ["ADC","Draven",67],
      ["ADC","Senna",73],
      ["ADC","Kalista",79],
      ["ADC","Varus",85],
      ["ADC","Jinx",91],
      ["ADC","Aphelios",97],
      ["ADC","Yasuo",103],
      ["ADC","Cassiopeia",109],
      ["ADC","Twitch",115],
      ["ADC","Kog'Maw",121],
      ["ADC","Xayah",127],
      ["ADC","Sivir",133],
      ["Support","Leona",1],
      ["Support","Maokai",5],
      ["Support","Thresh",9],
      ["Support","Pantheon",13],
      ["Support","Morgana",17],
      ["Support","Shaco",21],
      ["Support","Alistar",25],
      ["Support","Lulu",29],
      ["Support","Galio",33],
      ["Support","Nautilus",37],
      ["Support","Yuumi",41],
      ["Support","Blitzcrank",45],
      ["Support","Janna",49],
      ["Support","Xerath",53],
      ["Support","Bard",57],
      ["Support","Senna",61],
      ["Support","Swain",65],
      ["Support","Seraphine",69],
      ["Support","Zilean",73],
      ["Support","Brand",77],
      ["Support","Pyke",81],
      ["Support","Lux",85],
      ["Support","Zyra",89],
      ["Support","Nami",93],
      ["Support","Soraka",97],
      ["Support","Sett",101],
      ["Support","Braum",105],
      ["Support","Rakan",109],
      ["Support","Vel'Koz",113],
      ["Support","Shen",117],
      ["Support","Taric",121],
      ["Support","Zac",125],
      ["Support","Karma",129],
      ["Support","Annie",133],
      ["Support","Sona",137],
      ["Support","Poppy",141],
      ["Support","Tahm Kench",145],
      ["Support","Twitch",149],
      ["Support","Miss Fortune",153],

    ];

    return {
        championPatch: championPatch
    };
});

