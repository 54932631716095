/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesBrazil',[], function () {
    "use strict";

    var first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
			 ["Miguel"],
			["Davi"],
			["Gabriel"],
			["Arthur"],
			["Lucas"],
			["Matheus"],
			["Pedro"],
			["Guilherme"],
			["Gustavo"],
			["Rafael"],
			["Felipe"],
			["Bernardo"],
			["Matheus"],
			["Enzo"],
			["Nicolas"],
			["Caua"],
			["Mário"],
			["Júlio"],
			["Vitor"],
			["Eduardo"],
			["Daniel"],
			["Henrique"],
			["Murilo"],
			["Vinicius"],
			["Samuel"],
			["Pietro"],
			["Ícaro"],
			["Sandro"],
			["Alberto"],
			["Beto"],
			["Mário"],
			["Caio"],
			["Heitor"],
			["Hector"],
			["Fausto"],
			["Ulisses"],
			["Yan"],
			["Lorenzo"],
			["Isaac"],
			["Lucca"],
			["Thiago"],
			["João"],
			["Theo"],
			["Bruno"],
			["Bryan"],
			["Breno"],
			["Emanuel"],
			["Ryan"],
			["Yuri"],
			["Benjamin"],
			["Erick"],
			["Fernando"],
			["Joaquim"],
			["Andre"],
			["Thomas"],
			["Clóvis"],
			["Leonardo"],
			["Francisco"],
			["Rodrigo"],
			["Igor"],
			["Antônio"],
			["Ian"],
			["Valdo"],
			["Diogo"],
			["Otavio"],
			["Nathan"],
			["Calebe"],
			["Danilo"],
			["Luan"],
			["Kaíque"],
			["Alexandre"],
			["Iago"],
			["Ricardo"],
			["Raul"],
			["Marcelo"],
			["Caue"],
			["Benício"],
			["Augusto"],
			["Giovanni"],
			["Renato"],
			["Diego"],
			["Renan"],
			["Anthony"],
			["Thales"],
			["Pedro"],
			["Kevin"],
			["Levi"],
			["Enrico"],
			["Hugo"]	

    ];

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
			["Silva",20.88212],
			["Santos",34.316102],
			["Souza",44.126934],
			["Oliveira",50.336427],
			["Pereira",56.229364],
			["Lima",61.236757],
			["Carvalho",65.867144],
			["Ferreira",70.457154],
			["Rodrigues",74.967156],
			["Almeida",79.360554],
			["Costa",83.266354],
			["Gomes",87.114354],
			["Martins",90.764554],
			["Araújo",94.085334],
			["Mello",97.294334],
			["Barbosa",100.334634],
			["Ribeiro",103.344634],
			["Álvares",106.305134],
			["Cardoso",109.135254],
			["Schmidt",111.808254],
			["Rocha",114.348854],
			["Correa",116.853924],
			["Dias",119.320924],
			["Teixeira",121.729424],
			["Fernandez",124.109124],
			["Azevedo",126.479124],
			["Cavalcanti",128.845424],
			["Montes",131.207424],
			["Morais",133.561924],
			["Moretti",135.207424],
			["Pinto",138.207424],
			["Silveira",140.207424],
			["Fogaça",142.207424],
			["Santana",144.207424],
			["Coelho",146.207424],
			["Duarte",150.207424],
			["Antunes",151.207424],
			["Cunha",153.207424],
			["Guedes",155.207624],
			["Meireles",157.207424],
			["Carneiro",159.207424],
			["Pimenta",160.207424],
			["Esteves",161.207424],
			["Goulart",164.207424],
			["Bandeira",164.207424],
			["da Gama",165.207424],
			["Coqueiro",166.207424],
			["Parreira",167.207424],
			["Siqueira",169.207424],
			["Dantas",171.200000],
			["Antena",171.207424],
			["Gonçalves",173.909924]	

    ];

    return {
        first: first,
        last: last
    };
});
