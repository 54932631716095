/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesKorea',[], function () {
    "use strict";

    var first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
		   
		["Beom-seok"]	,
		["Beom-soo"]	,
		["Byung-chul"]	,
		["Byung-ho"]	,
		["Byung-hoon"]	,
		["Byung-hun"]	,
		["Byung-joon"]	,
		["Byung-woo"]	,
		["Byung-wook"]	,
		["Chang-ho"]	,
		["Chang-hoon"]	,
		["Chang-min"]	,
		["Chang-woo"]	,
		["Cheol-min"]	,
		["Chi-won"]	,
		["Chul"]	,
		["Chul-soo"]	,
		["Chul-soon"]	,
		["Dae-hyun"]	,
		["Dae-sung"]	,
		["Dae-won"]	,
		["Deok-su"]	,
		["Do-hun"]	,
		["Do-hyun"]	,
		["Dong-chan"]	,
		["Dong-chul"]	,
		["Dong-geun"]	,
		["Dong-gun"]	,
		["Dong-ha"]	,
		["Dong-hoon"]	,
		["Dong-hyuk"]	,
		["Dong-hyun"]	,
		["Dong-il"]	,
		["Dong-joo"]	,
		["Dong-jun"]	,
		["Dong-soo"]	,
		["Dong-suk"]	,
		["Dong-won"]	,
		["Dong-wook"]	,
		["Do-won"]	,
		["Geun"]	,
		["Gun"]	,
		["Hae-il"]	,
		["Hae-seong"]	,
		["Han-bin"]	,
		["Han-jae"]	,
		["Hee-chul"]	,
		["Hee-joon"]	,
		["Ho"]	,
		["Ho-jin"]	,
		["Ho-jun"]	,
		["Hong-gi"]	,
		["Hoon"]	,
		["Ho-sung"]	,
		["Hyuk"]	,
		["Hyung-joon"]	,
		["Hyung-min"]	,
		["Hyung-won"]	,
		["Hyun-jun"]	,
		["Hyun-seok"]	,
		["Hyun-seung"]	,
		["Hyun-tae"]	,
		["Hyun-woo"]	,
		["Hyun-wook"]	,
		["Il "]	,
		["Il-sung"]	,
		["In-sik"]	,
		["Jae-beom"]	,
		["Jae-geun"]	,
		["Jae-ho"]	,
		["Jae-hyuk"]	,
		["Jae-hyun"]	,
		["Jae-joon"]	,
		["Jae-suk"]	,
		["Jae-sung"]	,
		["Jae-won"]	,
		["Jae-woo"]	,
		["Jae-wook"]	,
		["Jae-woong"]	,
		["Jae-yong"]	,
		["Jae-yoon"]	,
		["Ji-ho"]	,
		["Ji-hoon"]	,
		["Ji-hwan"]	,
		["Jin-hwan"]	,
		["Jin-hyuk"]	,
		["Jin-sun"]	,
		["Jin-woo"]	,
		["Jin-wook"]	,
		["Ji-seok"]	,
		["Ji-tae"]	,
		["Ji-woon"]	,
		["Ji-woong"]	,
		["Jong-hoon"]	,
		["Jong-hyuk"]	,
		["Jong-hyun"]	,
		["Jong-il"]	,
		["Jong-seok"]	,
		["Jong-soo"]	,
		["Jong-yul"]	,
		["Joo-hwan"]	,
		["Joon-ho"]	,
		["Joon-hyuk"]	,
		["Joon-ki"]	,
		["Joon-tae"]	,
		["Joo-won"]	,
		["Jung-ho"]	,
		["Jung-hoon"]	,
		["Jung-hwan"]	,
		["Jung-il"]	,
		["Jung-jin"]	,
		["Jung-min"]	,
		["Jung-mo"]	,
		["Jung-myung"]	,
		["Jung-nam"]	,
		["Jung-sik"]	,
		["Jung-soo"]	,
		["Jung-woo"]	,
		["Jun-ha"]	,
		["Jun-sang"]	,
		["Jun-seo"]	,
		["Jun-seok"]	,
		["Jun-young"]	,
		["Kang-min"]	,
		["Ki-ha"]	,
		["Ki-jung"]	,
		["Ki-moon"]	,
		["Ki-nam"]	,
		["Ki-tae"]	,
		["Ki-woo"]	,
		["Ki-woong"]	,
		["Ki-young"]	,
		["Kun-woo"]	,
		["Kwang-ho"]	,
		["Kwang-hoon"]	,
		["Kwang-hwan"]	,
		["Kwang-hyok"]	,
		["Kwang-hyun"]	,
		["Kwang-jo"]	,
		["Kwang-min"]	,
		["Kwang-seok"]	,
		["Kwang-seon"]	,
		["Kwang-sik"]	,
		["Kwang-su"]	,
		["Kyu-chul"]	,
		["Kyung-chul"]	,
		["Kyung-gu"]	,
		["Kyung-ho"]	,
		["Kyung-hwan"]	,
		["Kyung-jae"]	,
		["Kyung-joon"]	,
		["Kyung-mo"]	,
		["Kyung-seok"]	,
		["Kyung-soo"]	,
		["Kyung-tae"]	,
		["Kyung-taek"]	,
		["Kyung-wan"]	,
		["Man-seok"]	,
		["Man-sik"]	,
		["Man-soo"]	,
		["Min-chul"]	,
		["Min-ho"]	,
		["Min-hyuk"]	,
		["Min-jae"]	,
		["Min-ki"]	,
		["Min-kyu"]	,
		["Min-seok"]	,
		["Min-woo"]	,
		["Moon-sik"]	,
		["Moon-soo"]	,
		["Mu-yeol"]	,
		["Myung-hoon"]	,
		["Myung-hwan"]	,
		["Myung-soo"]	,
		["Myung-yong"]	,
		["Nam-gi"]	,
		["Nam-il"]	,
		["Nuri"]	,
		["Oh-seong"]	,
		["Sang-chul"]	,
		["Sang-hoon"]	,
		["Sang-hyun"]	,
		["Sang-jun"]	,
		["Sang-won"]	,
		["Sang-woo"]	,
		["Sang-wook"]	,
		["Seo-jun"]	,
		["Seok-ho"]	,
		["Seok-ju"]	,
		["Seung-chul"]	,
		["Seung-gi"]	,
		["Seung-heon"]	,
		["Seung-ho"]	,
		["Seung-hoon"]	,
		["Seung-hwan"]	,
		["Seung-jae"]	,
		["Seung-jun"]	,
		["Seung-soo"]	,
		["Seung-won"]	,
		["Se-yoon"]	
   
   
    ];

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
	
		["Kim",	9.925949	]	,
		["Lee",	16.722176	]	,
		["Park",	20.617297	]	,
		["Chung",	22.847908	]	,
		["Choi",	25.017612	]	,
		["Cho",	26.365342	]	,
		["Kang",	27.535147	]	,
		["Yoo",	28.576131	]	,
		["Yoon",	29.524731	]	,
		["Chang",	30.467988	]	,
		["Shin",	31.379544	]	,
		["Lim",	32.115037	]	,
		["Hahn",	32.830593	]	,
		["Oh",	33.537501	]	,
		["Suh",	34.23275	]	,
		["Jun",	34.920617	]	,
		["Kwon",	35.573112	]	,
		["Whang",	36.217406	]	,
		["Soung",	36.856488	]	,
		["Ahn",	37.494274	]	,
		["Houng",	38.012909	]	,
		["Ryang",	38.499554	]	,
		["Ko",	38.935393	]	,
		["Moon",	39.36232	]	,
		["Sohn",	39.777502	]	,
		["Bai",	40.149566	]	,
		["Baik",	40.500841	]	,
		["Hur",	40.801289	]	,
		["Noh",	41.091723	]	,
		["Nahm",	41.348901	]	,
		["Shim",	41.601156	]	,
		["Joo",	41.816166	]	,
		["Hah",	42.029924	]	,
		["Sung",	42.215287	]	,
		["Char",	42.395876	]	,
		["Woo",	42.576017	]	,
		["Ra",	42.748039	]	,
		["Chin",	42.919019	]	,
		["Minn",	43.078073	]	,
		["Jee",	43.225645	]	,
		["Um",	43.358635	]	,
		["Byun",	43.490189	]	,
		["Weon",	43.610654	]	,
		["Pang",	43.730357	]	,
		["Chai",	43.849608	]	,
		["Chun",	43.961835	]	


    ];

    return {
        first: first,
        last: last
    };
});
