define('data/namesRomania',[], function () { "use strict";

	var female, first, last;

	first = [
		["Alexandru", 10779],
		["Alin", 10338],
		["Andrei", 9603],
		["Catalin", 9385],
		["Mihai", 7887],
		["Marius", 7426],
		["Denis", 6496],
		["Carol", 6193],
		["Costel", 6161],
		["Eduard", 5802],
		["Petrica", 5750],
		["George", 5664],
		["Gheorghe", 5009],
		["Gabriel", 4840],
		["Danut", 4805],
		["Radu", 4538],
		["Cristian", 4434],
		["Dumitru", 4331],
		["Emilian", 4287],
		["Flavius", 4281],
		["Florin", 4269],
		["Ion", 4187],
		["Ilie", 3655],
		["Iancu", 3569],
		["Horatiu", 3483],
		["Iulian", 2882],
		["Liviu", 2744],
		["Lucian", 2688],
		["Marian", 2676],
		["Petre", 2644],
		["Pompiliu", 2557],
		["Vasile", 2517],
		["Vali", 2511],
		["Virgiliu", 2332],
		["Viorel", 2249],
		["Claudiu", 2046],
		["Ciprian", 1912],
		["Constantin", 1881],
		["Cosmin", 1874],
		["Dorian", 1811],
		["Dorin", 1791],
		["Doru", 1762],
		["Dragos", 1738],
		["Grigore", 1658],
		["Teodor", 1649],
		["Iosif", 1638],
		["Mircea", 1632],
		["Nicu", 1594],
		["Ovidiu", 1567],
		["Tiberiu", 1519],
		["Stefan", 1385],
	];
	 

	female = [
		["Alina", 7087],
		["Anca", 6824],
		["Andreea", 6711],
		["Catalina", 6415],
		["Gabriela", 6299],
		["Laura", 5916],
		["Martha", 5866],
		["Alexandra", 5828],
		["Roxana", 5206],
		["Ana", 4948],
		["Maria", 4649],
		["Andra", 4337],
		["Madalina", 4271],
		["Cristina", 4232],
		["Patricia", 3753],
		["Mirela", 3723],
		["Miruna", 3582],
		["Teodora", 3111],
		["Oana", 2750],
		["Rodica", 2641],
		["Ioana", 2595],
		["Violeta", 2555],
		 
	];
	 
	
	
	last = [
		["Albu", 729862],
		["Alexe", 580000],
		["Pascu", 460000],
		["Vasile", 411000],
		["Popescu", 380000],
		["Ionescu", 316000],
		["Florescu", 230000],
		["Cojocaru", 227000],
		["Costea", 220000],
		["Goga", 219000],
		["Goian", 214000],
		["Baicu", 198000],
		["Balan", 195000],
		["Balauru", 187000],
		["Croitoru", 185000],
		["Barbu", 183000],
		["Blaga", 181000],
		["Boboc", 181000],
		["Dinu", 173000],
		["Dobre", 171000],
		["Dumitru", 166000],
		["Iacob", 161000],
		["Istrati", 161000],
		["Cazacu", 160000],
		["Florea", 159000],
		["Maior", 152000],
		["Manea", 152000],
		["Bratu", 151000],
		["Brancoveanu", 150000],
		["Bogza", 147000],
		["Hagi", 147000],
		["Huidi", 145000],
		["Hoban", 144000],
		["Grigorescu", 140000],
		["Harrison", 137000],
		["Georgescu", 134000],
		["Ciocan", 131000],
		["Cocea", 131000],
		["Constantinescu", 129000],
		["Grosu", 125000],
		["Lucescu", 125000],
		["Lupu", 124000],
		["Enache", 121000],
		["Ene", 121000],
		["Enescu", 119000],
		["Bunea", 116000],
		["Breban", 115000],
		["Bogdan", 115000],
		["Hasdeu", 113000],
		["Munteanu", 110000],
	];
	
	return {
		first: first,
		female: female,		 
		last: last
	};
});
