define('data/namesEngland',[], function () { "use strict";

		var female, first, last;

		first = [
			["Jack", 10779],
			["Daniel", 10338],
			["Thomas", 9603],
			["James", 9385],
			["Joshua", 7887],
			["Matthew", 7426],
			["Ryan", 6496],
			["Joseph", 6193],
			["Samuel", 6161],
			["Liam", 5802],
			["Jordan", 5750],
			["Luke", 5664],
			["Connor", 5009],
			["Alexander", 4840],
			["Benjamin", 4805],
			["Adam", 4538],
			["Harry", 4434],
			["Jake", 4331],
			["George", 4287],
			["Callum", 4281],
			["William", 4269],
			["Michael", 4187],
			["Oliver", 3655],
			["Lewis", 3569],
			["Christopher", 3483],
			["Kieran", 2882],
			["Nathan", 2744],
			["Jamie", 2688],
			["Andrew", 2676],
			["Robert", 2644],
			["Bradley", 2557],
			["David", 2517],
			["Aaron", 2511],
			["Ben", 2332],
			["Jacob", 2249],
			["Kyle", 2046],
			["Charlie", 1912],
			["Jonathan", 1881],
			["Ashley", 1874],
			["Reece", 1811],
			["Cameron", 1791],
			["Alex", 1762],
			["Charles", 1738],
			["Edward", 1658],
			["John", 1649],
			["Sam", 1638],
			["Scott", 1632],
			["Sean", 1594],
			["Joe", 1567],
			["Dominic", 1519],
			["Nicholas", 1385],
			["Brandon", 1337],
			["Rhys", 1259],
			["Mark", 1222],
			["Max", 1192],
			["Dylan", 1186],
			["Henry", 1135],
			["Peter", 1128],
			["Stephen", 1122],
			["Louis", 1100],
			["Richard", 1089],
			["Conor", 1081],
			["Lee", 1053],
			["Anthony", 1045],
			["Patrick", 1039],
			["Tyler", 1007],
			["Ross", 1004],
			["Jason", 1000],
			["Elliot", 1000],
			["Billy", 1000],
			["Paul",1000],
			["Steven",1000],
			["Shane",1000],
			["Joel",1000],
			["Shaun",1000],
			["Toby",1000],
			["Curtis",1000],
			["Mitchell",1000],
			["Dean",1000],
			["Craig",1000],
			["Timothy",1000],
			["Aidan",1000],
			["Declan",1000],
			["Marcus",1000],
			["Harrison",1000],
			["Simon",1000],
			["Elliott",1000],
			["Jay",1000],
			["Christian",1000],
			["Calum",1000],
			["Ethan",1000],
			["Rory",1000],
			["Josh",1000],
			["Macauley",1000],
			["Zachary",1000],
			["Danny",1000],
			["Kane",1000],
			["Owen",1000]
		];

		female = [

			["Sophie", 7087],
			["Chloe", 6824],
			["Jessica", 6711],
			["Emily", 6415],
			["Lauren", 6299],
			["Hannah", 5916],
			["Charlotte", 5866],
			["Rebecca", 5828],
			["Amy", 5206],
			["Megan", 4948],
			["Shannon", 4649],
			["Katie", 4337],
			["Bethany", 4271],
			["Emma", 4232],
			["Lucy", 3753],
			["Georgia", 3723],
			["Laura", 3582],
			["Sarah", 3111],
			["Jade", 2750],
			["Danielle", 2641],
			["Abigail", 2595],
			["Eleanor", 2555]

		];

		last = [
			["Smith", 729862],
			["Jones", 580000],
			["Taylor", 460000],
			["Williams", 411000],
			["Brown", 380000],
			["Davies", 316000],
			["Evans", 230000],
			["Wilson", 227000],
			["Thomas", 220000],
			["Roberts", 219000],
			["Johnson", 214000],
			["Lewis", 198000],
			["Walker", 195000],
			["Robinson", 187000],
			["Wood", 185000],
			["Thompson", 183000],
			["White", 181000],
			["Watson", 181000],
			["Jackson", 173000],
			["Wright", 171000],
			["Green", 166000],
			["Harris", 161000],
			["Cooper", 161000],
			["King", 160000],
			["Lee", 159000],
			["Martin", 152000],
			["Clarke", 152000],
			["James", 151000],
			["Morgan", 150000],
			["Hughes", 147000],
			["Edwards", 147000],
			["Hill", 145000],
			["Moore", 144000],
			["Clark", 140000],
			["Harrison", 137000],
			["Scott", 134000],
			["Young", 131000],
			["Morris", 131000],
			["Hall", 129000],
			["Ward", 125000],
			["Turner", 125000],
			["Carter", 124000],
			["Phillips", 121000],
			["Mitchell", 121000],
			["Patel", 119000],
			["Adams", 116000],
			["Campbell", 115000],
			["Anderson", 115000],
			["Allen", 113000],
			["Cook", 110000]
		];

	return {
		first: first,
		female: female,
		last: last
	};
});
