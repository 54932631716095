define('data/namesSpain',[], function () { "use strict";

		var female, first, last;

		first = [
		
			["Aarón"]	,
			["Abrahán"]	,
			["Absalón"]	,
			["Abundio"]	,
			["Adalberto"]	,
			["Adán"]	,
			["Adolfo"]	,
			["Adrián"]	,
			["Adriano"]	,
			["Agapito"]	,
			["Aimar"]	,
			["Aitor"]	,
			["Albano"]	,
			["Alberto"]	,
			["Alejandro"]	,
			["Alfonso"]	,
			["Alfredo"]	,
			["Álvaro"]	,
			["Andrés"]	,
			["Ángel"]	,
			["Aníbal"]	,
			["Aniceto"]	,
			["Antero"]	,
			["Antonio"]	,
			["Ariel"]	,
			["Armando"]	,
			["Arturo"]	,
			["Augustín"]	,
			["Aurelio"]	,
			["Bartolomé"]	,
			["Basilio"]	,
			["Beltrán"]	,
			["Benito"]	,
			["Benjamín"]	,
			["Bernardino"]	,
			["Bernardo"]	,
			["Blas"]	,
			["Bonifacio"]	,
			["Braulio"]	,
			["Calixto"]	,
			["Cándido"]	,
			["Canuto"]	,
			["Carlos"]	,
			["Casimiro"]	,
			["Cayetano"]	,
			["César"]	,
			["Chema"]	,
			["Claudio"]	,
			["Cornelio"]	,
			["Cristóbal"]	,
			["Cruz"]	,
			["Dámaso"]	,
			["Damián"]	,
			["Daniel"]	,
			["David"]	,
			["Diego"]	,
			["Dionisio"]	,
			["Domingo"]	,
			["Édgar"]	,
			["Edgardo"]	,
			["Edmundo"]	,
			["Eduardo"]	,
			["Egidio"]	,
			["Elías"]	,
			["Eliseo"]	,
			["Eloy"]	,
			["Emanuel"]	,
			["Emilio"]	,
			["Enrique"]	,
			["Ernesto"]	,
			["Estanislao"]	,
			["Esteban"]	,
			["Eugenio"]	,
			["Ezequiel"]	,
			["Fabián"]	,
			["Fabio"]	,
			["Fabricio"]	,
			["Facundo"]	,
			["Fadrique"]	,
			["Federico"]	,
			["Feliciano"]	,
			["Felipe"]	,
			["Fermín"]	,
			["Fernán"]	,
			["Fernando"]	,
			["Ferrando"]	,
			["Fidel"]	,
			["Florio"]	,
			["Floro"]	,
			["Francisco"]	,
			["Franco"]	,
			["Froila"]	,
			["Froilán"]	,
			["Fulberto"]	,
			["Fulco"]	,
			["Gabriel"]	,
			["Genaro"]	,
			["Germán"]	,
			["Gershu"]	,
			["Gilberto"]	,
			["Ginés"]	,
			["Gonzalo"]	,
			["Gregorio"]	,
			["Guillermo"]	,
			["Gustavo"]	,
			["Héctor"]	,
			["Hipólito"]	,
			["Hugo"]	,
			["Humberto"]	,
			["Ignacio"]	,
			["Iñaki"]	,
			["Íñigo"]	,
			["Ireneo"]	,
			["Isaac"]	,
			["Isidoro"]	,
			["Isidro"]	,
			["Ismael"]	,
			["Iván"]	,
			["Jacinto"]	,
			["Jacobo"]	,
			["Jaime"]	,
			["Jairo"]	,
			["Javier"]	,
			["Jesús"]	,
			["Joaquín"]	,
			["Jorge"]	,
			["José"]	,
			["Josué"]	,
			["Juan"]	,
			["Juanca"]	,
			["Juanfran"]	,
			["Juano"]	,
			["Julián"]	,
			["Julio"]	,
			["Justín"]	,
			["Justo"]	,
			["Ladislao"]	,
			["Leandro"]	,
			["León"]	,
			["Leonardo"]	,
			["Leopoldo"]	,
			["Lope"]	,
			["Lorenzo"]	,
			["Lucas"]	,
			["Luciano"]	,
			["Luis"]	,
			["Manuel"]	,
			["Marcelino"]	,
			["Marcelo"]	,
			["Marcial"]	,
			["Marcos"]	,
			["Mariano"]	,
			["Mario"]	,
			["Martín"]	,
			["Mateo"]	,
			["Matías"]	,
			["Máximo"]	,
			["Miguel"]	,
			["Modesto"]	,
			["Moisés"]	,
			["Murillo"]	,
			["Narciso"]	,
			["Natalio"]	,
			["Natan"]	,
			["Natán"]	,
			["Nicolás"]	,
			["Ordoño"]	,
			["Oriol"]	,
			["Óscar"]	,
			["Oswaldo"]	,
			["Pablito"]	,
			["Pablo"]	,
			["Pascual"]	,
			["Patricio"]	,
			["Paulito"]	,
			["Pedro"]	,
			["Pelayo"]	,
			["Pío"]	,
			["Rafael"]	,
			["Raimundo"]	,
			["Ramiro"]	,
			["Ramón"]	,
			["Raúl"]	,
			["Renato"]	,
			["Ricardo"]	,
			["Roberto"]	,
			["Rodolfo"]	,
			["Rodrigo"]	,
			["Rogelio"]	,
			["Rubén"]	,
			["Ruy"]	,
			["Salomón"]	,
			["Salvador"]	,
			["Samuel"]	,
			["Sancho"]	,
			["Santi"]	,
			["Santiago"]	,
			["Saúl"]	,
			["Sebastián"]	,
			["Segundo"]	,
			["Sergio"]	,
			["Silvio"]	,
			["Simón"]	,
			["Tadeo"]	,
			["Teodoro"]	,
			["Teófilo"]	,
			["Timoteo"]	,
			["Tito"]	,
			["Tomás"]	,
			["Tristán"]	,
			["Ulises"]	,
			["Valentín"]	,
			["Vicente"]	,
			["Vito"]	,
			["Yago"]	

		];

		female = [

			["Lucia",	5161],
			["Maria",	4951],
			["Martina",	4380],
			["Paula",	4210],
			["Daniela",	3792],
			["Sofia",	3568],
			["Valeria",	3246],
			["Carla",	3138],
			["Sara",	3116],
			["Alba",	3111],
			["Julia",	3107],
			["Noa",	2704],
			["Emma",	2479],
			["Claudia",	2456],
			["Carmen",	2147],
			["Marta",	1988],
			["Valentina",	1936],
			["Irene",	1902],
			["Adriana",	1881],
			["Ana",	1797],
			["Laura",	1794],
			["Elena",	1781],
			["Alejandra",	1552],
			["Ainhoa",	1485],
			["Ines",	1410],
			["Aitana",	1392],
			["Marina",	1378],
			["Vera",	1350],
			["Candela",	1331],
			["Laia",	1327],
			["Ariadna",	1305],
			["Ainara",	1251],
			["Lola",	1250],
			["Leire",	1228],
			["Andrea",	1174],
			["Nerea",	1173],
			["Carlota",	1138],
			["Rocio",	1131],
			["Angela",	1129],
			["Vega",	1129],
			["Nora",	1115],
			["Jimena",	1082],
			["Blanca",	1052],
			["Alicia",	1018],
			["Clara",	991],
			["Olivia",	979]


		];

		last = [
		
			["Garcia",	7.44	]	,
			["Fernandez",	12.47	]	,
			["Lopez",	16.98	]	,
			["Martinez",	21.31	]	,
			["Gonzalez",	25.64	]	,
			["Rodriguez",	29.96	]	,
			["Sanchez",	34.01	]	,
			["Perez",	38.05	]	,
			["Martin",	40.52	]	,
			["Gomez",	42.84	]	,
			["Ruiz",	44.7	]	,
			["Diaz",	46.41	]	,
			["Hernandez",	48.01	]	,
			["Alvarez",	49.6	]	,
			["Jimenez",	51.14	]	,
			["Moreno",	52.58	]	,
			["Munoz",	53.81	]	,
			["Alonso",	54.96	]	,
			["Romero",	55.93	]	,
			["Navarro",	56.83	]	,
			["Gutierrez",	57.72	]	,
			["Torres",	58.48	]	,
			["Dominguez",	59.22	]	,
			["Gil",	59.94	]	,
			["Vazquez",	60.66	]	,
			["Blanco",	61.36	]	,
			["Serrano",	62.05	]	,
			["Ramos",	62.71	]	,
			["Castro",	63.32	]	,
			["Suarez",	63.92	]	,
			["Sanz",	64.51	]	,
			["Rubio",	65.07	]	,
			["Ortega",	65.63	]	,
			["Molina",	66.18	]	,
			["Delgado",	66.72	]	,
			["Ortiz",	67.25	]	,
			["Morales",	67.78	]	,
			["Ramirez",	68.3	]	,
			["Marin",	68.77	]	,
			["Iglesias",	69.23	]	,
			["Santos",	69.67	]	,
			["Castillo",	70.1	]	,
			["Garrido",	70.52	]	,
			["Calvo",	70.94	]	,
			["Pena",	71.36	]	,
			["Cruz",	71.78	]	,
			["Cano",	72.19	]	,
			["Nunez",	72.59	]	,
			["Prieto",	72.99	]	,
			["Diez",	73.39	]	,
			["Lozano",	73.78	]	,
			["Vidal",	74.17	]	,
			["Pascual",	74.54	]	,
			["Ferrer",	74.91	]	,
			["Medina",	75.27	]	,
			["Vega",	75.63	]	,
			["Leon",	75.99	]	,
			["Herrero",	76.34	]	,
			["Vicente",	76.68	]	,
			["Mendez",	77.01	]	,
			["Guerrero",	77.34	]	,
			["Fuentes",	77.66	]	,
			["Campos",	77.98	]	,
			["Nieto",	78.3	]	,
			["Cortes",	78.61	]	,
			["Caballero",	78.91	]	,
			["Ibanez",	79.21	]	,
			["Lorenzo",	79.5	]	,
			["Pastor",	79.79	]	,
			["Gimenez",	80.08	]	,
			["Saez",	80.37	]	,
			["Soler",	80.65	]	,
			["Marquez",	80.93	]	,
			["Carrasco",	81.21	]	,
			["Herrera",	81.49	]	,
			["Montero",	81.76	]	,
			["Arias",	82.03	]	,
			["Crespo",	82.3	]	,
			["Flores",	82.57	]	,
			["Andres",	82.84	]	,
			["Aguilar",	83.1	]	,
			["Hidalgo",	83.36	]	,
			["Cabrera",	83.62	]	,
			["Mora",	83.88	]	,
			["Duran",	84.14	]	,
			["Velasco",	84.39	]	,
			["Rey",	84.63	]	,
			["Pardo",	84.87	]	,
			["Roman",	85.11	]	,
			["Vila",	85.35	]	,
			["Bravo",	85.59	]	,
			["Merino",	85.82	]	,
			["Moya",	86.05	]	,
			["Soto",	86.27	]	,
			["Izquierdo",	86.49	]	,
			["Reyes",	86.71	]	,
			["Redondo",	86.93	]	,
			["Marcos",	87.15	]	,
			["Carmona",	87.37	]	,
			["Menendez",	87.59	]	
		];

	return {
		first: first,
		female: female,
		last: last
	};
});
