define('data/namesPoland',[], function () { "use strict";
		var female, first, last;
		
		first = [
		
			["Jakub", 9382],
			["Kacper", 7232],
			["Antoni", 7143],
			["Filip", 6903],
			["Jan", 6817],
			["Szymon", 6112],
			["Franciszek", 5139],
			["Michal", 5004],
			["Wojciech", 4959],
			["Aleksander", 4896],
			["Mikolaj", 4787],
			["Adam", 4681],
			["Piotr", 4149],
			["Mateusz", 3978],
			["Bartosz", 3939],
			["Igor", 3795],
			["Wiktor", 3755],
			["Marcel", 3736],
			["Dawid", 3021],
			["Stanislaw", 2798],
			["Milosz", 2713],
			["Maksymilian", 2712],
			["Karol", 2684],
			["Alan", 2665],
			["Oliwier", 2556],
			["Oskar", 2549],
			["Maciej", 2514],
			["Tomasz", 2401],
			["Dominik", 2309],
			["Nikodem", 2293],
			["Krzysztof", 2160],
			["Pawel", 2103],
			["Leon", 2051],
			["Hubert", 1951]
			
		];

		female = [
					
			["Lena", 9642],
			["Zuzanna", 8856],
			["Julia", 8572],
			["Maja", 8055],
			["Zofia", 6733],
			["Hanna", 6407],
			["Aleksandra", 5935],
			["Amelia", 5586],
			["Natalia", 5202],
			["Wiktoria", 5149],
			["Alicja", 4932],
			["Oliwia", 4807],
			["Nikola", 3969],
			["Maria", 3719],
			["Emilia", 3604],
			["Anna", 3265],
			["Nadia", 3103],
			["Antonna", 2953]
			
		];


	
	last = [
	

["Nowak",	2.20217	]	,
["Kowalski",	3.52157	]	,
["Wisniewski",	4.56575	]	,
["Dabrowski",	5.4952	]	,
["Lewandowski",	6.38886	]	,
["Wójcik",	7.27818	]	,
["Kaminski",	8.15753	]	,
["Kowalczyk",	9.03443	]	,
["Zielinski",	9.89431	]	,
["Szymanski",	10.73958	]	,
["Wozniak",	11.55348	]	,
["Kozlowski",	12.27716	]	,
["Jankowski",	12.93658	]	,
["Wojciechowski",	13.57177	]	,
["Kwiatkowski",	14.19806	]	,
["Kaczmarek",	14.79209	]	,
["Mazur",	15.38278	]	,
["Krawczyk",	15.96524	]	,
["Piotrowski",	16.54458	]	,
["Grabowski",	17.0911	]	,
["Nowakowski",	17.63288	]	,
["Pawlowski",	18.16032	]	,
["Michalski",	18.67357	]	,
["Nowicki",	19.17128	]	,
["Adamczyk",	19.66727	]	,
["Dudek",	20.16155	]	,
["Zajac",	20.64394	]	,
["Wieczorek",	21.11314	]	,
["Jablonski",	21.58042	]	,
["Król",	22.045	]	,
["Majewski",	22.50879	]	,
["Olszewski",	22.95517	]	,
["Jaworski",	23.39621	]	,
["Wróbel",	23.83631	]	,
["Malinowski",	24.27468	]	,
["Pawlak",	24.71024	]	,
["Witkowski",	25.13197	]	,
["Walczak",	25.55316	]	,
["Stepien",	25.97378	]	,
["Górski",	26.39168	]	,
["Rutkowski",	26.80531	]	,
["Michalak",	27.2094	]	,
["Sikora",	27.6079	]	,
["Ostrowski",	27.99732	]	,
["Baran",	28.3828	]	,
["Duda",	28.7657	]	,
["Szewczyk",	29.14834	]	,
["Tomaszewski",	29.52973	]	,
["Pietrzak",	29.90228	]	,
["Marciniak",	30.27197	]	,
["Wróblewski",	30.63632	]	,
["Zalewski",	30.99695	]	,
["Jakubowski",	31.35546	]	,
["Jasinski",	31.71091	]	,
["Zawadzki",	32.06315	]	,
["Sadowski",	32.41239	]	,
["Bak",	32.74821	]	,
["Chmielewski",	33.08399	]	,
["Wlodarczyk",	33.41648	]	,
["Borkowski",	33.74203	]	,
["Czarnecki",	34.06728	]	,
["Sawicki",	34.38536	]	,
["Sokolowski",	34.70171	]	,
["Urbanski",	35.01692	]	,
["Kubiak",	35.32973	]	,
["Maciejewski",	35.64197	]	,
["Szczepanski",	35.95405	]	,
["Kucharski",	36.26354	]	,
["Wilk",	36.56449	]	,
["Kalinowski",	36.86461	]	,
["Lis",	37.16459	]	,
["Mazurek",	37.46256	]	,
["Wysocki",	37.75976	]	,
["Adamski",	38.04382	]	,
["Kazmierczak",	38.3258	]	,
["Wasilewski",	38.6045	]	,
["Sobczak",	38.88063	]	,
["Czerwinski",	39.15151	]	,
["Andrzejewski",	39.42068	]	,
["Cieslak",	39.68957	]	,
["Glowacki",	39.95658	]	,
["Zakrzewski",	40.21868	]	,
["Kolodziej",	40.47922	]	,
["Sikorski",	40.73973	]	,
["Krajewski",	40.99757	]	,
["Gajewski",	41.25423	]	,
["Szymczak",	41.51001	]	,
["Szulc",	41.76567	]	,
["Baranowski",	42.02111	]	,
["Laskowski",	42.27536	]	,
["Brzezinski",	42.52897	]	,
["Makowski",	42.78237	]	,
["Ziólkowski",	43.03463	]	,
["Przybylski",	43.28513	]	,
["Domanski",	43.53432	]	,
["Nowacki",	43.78342	]	,
["Borowski",	44.03231	]	,
["Blaszczyk",	44.28022	]	,
["Chojnacki",	44.52766	]	,
["Ciesielski",	44.77177	]	,
["Mróz",	45.01311	]	,
["Szczepaniak",	45.25038	]	,
["Wesolowski",	45.48691	]	,
["Górecki",	45.72315	]	,
["Krupa",	45.95934	]	,
["Kaczmarczyk",	46.19455	]	,
["Leszczynski",	46.42918	]	,
["Lipinski",	46.66308	]	,
["Kowalewski",	46.89574	]	,
["Urbaniak",	47.1211	]	,
["Kozak",	47.34572	]	,
["Kania",	47.57006	]	,
["Mikolajczyk",	47.79314	]	,
["Czajkowski",	48.01445	]	,
["Mucha",	48.2355	]	,
["Tomczak",	48.4559	]	,
["Koziol",	48.6763	]	,
["Markowski",	48.89568	]	,
["Kowalik",	49.11461	]	,
["Nawrocki",	49.33259	]	,
["Brzozowski",	49.54984	]	,
["Janik",	49.7653	]	,
["Musial",	49.98058	]	,
["Wawrzyniak",	50.19315	]	,
["Markiewicz",	50.40411	]	,
["Orlowski",	50.61425	]	,
["Tomczyk",	50.82393	]	,
["Jarosz",	51.03357	]	,
["Kolodziejczyk",	51.24169	]	,
["Kurek",	51.44866	]	,
["Kopec",	51.65173	]	,
["Zak",	51.85377	]	,
["Wolski",	52.05487	]	,
["Luczak",	52.25539	]	,
["Dziedzic",	52.4559	]	,
["Kot",	52.65492	]	,
["Stasiak",	52.85362	]	,
["Stankiewicz",	53.05188	]	,
["Piatek",	53.24984	]	,
["Józwiak",	53.44713	]	,
["Urban",	53.64109	]	,
["Dobrowolski",	53.83491	]	,
["Pawlik",	54.02787	]	,
["Kruk",	54.2208	]	,
["Domagala",	54.41353	]	,
["Piasecki",	54.606	]	,
["Wierzbicki",	54.79831	]	,
["Karpinski",	54.99005	]	,
["Jastrzebski",	55.18161	]	,
["Polak",	55.37229	]	,
["Zieba",	55.56253	]	,
["Janicki",	55.75156	]	,
["Wójtowicz",	55.93873	]	,
["Stefanski",	56.12547	]	,
["Sosnowski",	56.31147	]	,
["Bednarek",	56.49734	]	,
["Majchrzak",	56.68059	]	,
["Bielecki",	56.86289	]	,
["Malecki",	57.04337	]	,
["Maj",	57.221	]	,
["Sowa",	57.3985	]	,
["Milewski",	57.57384	]	,
["Gajda",	57.74875	]	,
["Klimek",	57.92293	]	,
["Olejniczak",	58.0962	]	,
["Ratajczak",	58.26898	]	,
["Romanowski",	58.43767	]	,
["Matuszewski",	58.60586	]	,
["Sliwinski",	58.77401	]	,
["Madej",	58.942	]	,
["Kasprzak",	59.10944	]	,
["Wilczynski",	59.27513	]	,
["Grzelak",	59.43833	]	,
["Socha",	59.60112	]	,
["Czajka",	59.76357	]	,
["Marek",	59.92559	]	,
["Kowal",	60.08593	]	,
["Bednarczyk",	60.24537	]	,
["Skiba",	60.40461	]	,
["Wrona",	60.56374	]	,
["Owczarek",	60.72273	]	,
["Marcinkowski",	60.88119	]	,
["Matusiak",	61.03879	]	,
["Orzechowski",	61.19535	]	,
["Sobolewski",	61.35166	]	,
["Kedzierski",	61.50747	]	,
["Kurowski",	61.66306	]	,
["Rogowski",	61.81829	]	,
["Olejnik",	61.97321	]	,
["Debski",	62.12704	]	,
["Baranski",	62.28079	]	,
["Skowronski",	62.4345	]	,
["Mazurkiewicz",	62.58814	]	,
["Pajak",	62.74136	]	,
["Czech",	62.89441	]	,
["Janiszewski",	63.04708	]	,
["Bednarski",	63.19939	]	,
["Lukasik",	63.35152	]	,
["Chrzanowski",	63.5034	]	,
["Bukowski",	63.65486	]	,
["Lesniak",	63.8058	]	,
["Cieslik",	63.95602	]	,
["Kosinski",	64.10483	]	,
["Jedrzejewski",	64.25341	]	,
["Muszynski",	64.40187	]	,
["Swiatek",	64.54986	]	,
["Koziel",	64.69669	]	,
["Osinski",	64.84326	]	,
["Czaja",	64.98978	]	,
["Lisowski",	65.13619	]	,
["Kuczynski",	65.28248	]	,
["Zukowski",	65.42756	]	,
["Grzybowski",	65.57254	]	,
["Kwiecien",	65.7173	]	,
["Pluta",	65.86179	]	,
["Morawski",	66.00586	]	,
["Czyz",	66.14897	]	,
["Sobczyk",	66.29147	]	,
["Augustyniak",	66.43358	]	,
["Rybak",	66.57552	]	,
["Krzeminski",	66.71706	]	,
["Marzec",	66.85837	]	,
["Konieczny",	66.99963	]	,
["Marczak",	67.14077	]	,
["Zych",	67.2816	]	,
["Michalik",	67.42197	]	,
["Michalowski",	67.562	]	,
["Kaczor",	67.70194	]	,
["Swiderski",	67.84174	]	,
["Kubicki",	67.98145	]	,
["Golebiowski",	68.12095	]	,
["Paluch",	68.25823	]	,
["Bialek",	68.39538	]	,
["Niemiec",	68.53235	]	,
["Sroka",	68.66913	]	,
["Stefaniak",	68.80578	]	,
["Cybulski",	68.94205	]	,
["Kacprzak",	69.07802	]	,
["Marszalek",	69.21386	]	,
["Kasprzyk",	69.34946	]	,
["Malek",	69.48448	]	,
["Szydlowski",	69.61941	]	,
["Smolinski",	69.75424	]	,
["Kujawa",	69.8888	]	,
["Lewicki",	70.02321	]	,
["Przybysz",	70.15703	]	,
["Stachowiak",	70.29075	]	,
["Poplawski",	70.42414	]	,
["Piekarski",	70.55706	]	,
["Matysiak",	70.68971	]	,
["Janowski",	70.82221	]	,
["Murawski",	70.95455	]	,
["Cichocki",	71.08683	]	,
["Witek",	71.21905	]	,
["Niewiadomski",	71.35125	]	,
["Staniszewski",	71.48292	]	,
["Bednarz",	71.61432	]	,
["Lech",	71.74557	]	,
["Rudnicki",	71.87681	]	,
["Kulesza",	72.00783	]	,
["Piatkowski",	72.13855	]	,
["Turek",	72.26921	]	,
["Chmiel",	72.39951	]	,
["Biernacki",	72.52964	]	,
["Sowinski",	72.65922	]	,
["Skrzypczak",	72.78779	]	,
["Podgórski",	72.91631	]	,
["Cichon",	73.04397	]	,
["Rosinski",	73.17135	]	,
["Karczewski",	73.29855	]	,
["Zurek",	73.42478	]	,
["Drozd",	73.55064	]	,
["Zurawski",	73.67619	]	,
["Pietrzyk",	73.80101	]	,
["Komorowski",	73.92526	]	,
["Antczak",	74.04935	]	,
["Golebiewski",	74.17265	]	,
["Góra",	74.29593	]	,
["Banach",	74.41911	]	,
["Filipiak",	74.54118	]	,
["Grochowski",	74.66255	]	,
["Krzyzanowski",	74.78282	]	,
["Graczyk",	74.90268	]	,
["Przybyla",	75.02217	]	,
["Gruszka",	75.14047	]	,
["Banas",	75.25875	]	,
["Klimczak",	75.37702	]	,
["Siwek",	75.49524	]	,
["Konieczna",	75.61346	]	,
["Serafin",	75.73155	]	,
["Bieniek",	75.84944	]	,
["Godlewski",	75.96698	]	,
["Rak",	76.08428	]	,
["Kulik",	76.20133	]	,
["Mackowiak",	76.31834	]	,
["Zawada",	76.4352	]	,
["Mikolajczak",	76.55186	]	,
["Rózanski",	76.6681	]	,
["Ciesla",	76.78367	]	,
["Dlugosz",	76.89906	]	,
["Sliwa",	77.01405	]	,
["Ptak",	77.12877	]	,
["Strzelecki",	77.24344	]	,
["Zarzycki",	77.35803	]	,
["Mielczarek",	77.47182	]	,
["Klos",	77.58554	]	,
["Bartkowiak",	77.69896	]	,
["Lesniewski",	77.81232	]	,
["Krawiec",	77.92502	]	,
["Górka",	78.03705	]	,
["Janiak",	78.14901	]	,
["Kaczynski",	78.26089	]	,
["Bartczak",	78.37273	]	,
["Winiarski",	78.48452	]	,
["Tokarski",	78.59627	]	,
["Gil",	78.7078	]	,
["Panek",	78.81918	]	,
["Konopka",	78.93039	]	,
["Frankowski",	79.04133	]	,
["Banasiak",	79.15207	]	,
["Grzyb",	79.26252	]	,
["Rakowski",	79.37259	]	,
["Kus",	79.48266	]	,
["Dudzinski",	79.59194	]	,
["Zaremba",	79.70101	]	,
["Skowron",	79.80998	]	,
["Fijalkowski",	79.91784	]	,
["Dobosz",	80.02568	]	,
["Witczak",	80.13326	]	,
["Nawrot",	80.2408	]	,
["Królikowski",	80.34811	]	,
["Mlynarczyk",	80.45501	]	,
["Sienkiewicz",	80.56159	]	,
["Fraczek",	80.66792	]	,
["Slowik",	80.77407	]	,
["Frackowiak",	80.88009	]	,
["Czyzewski",	80.98552	]	,
["Kostrzewa",	81.0906	]	,
["Kucharczyk",	81.19534	]	,
["Gawronski",	81.29989	]	,
["Rybicki",	81.40442	]	,
["Palka",	81.50889	]	,
["Biernat",	81.61309	]	,
["Rózycki",	81.7172	]	,
["Bogusz",	81.82117	]	,
["Rogalski",	81.92455	]	,
["Szymczyk",	82.02786	]	,
["Janus",	82.13099	]	,
["Szczepanik",	82.23356	]	,
["Szczygiel",	82.33601	]	,
["Buczek",	82.43842	]	,
["Szostak",	82.54034	]	,
["Kaleta",	82.64194	]	,
["Kaczorowski",	82.74353	]	,
["Zebrowski",	82.84503	]	,
["Tkaczyk",	82.94626	]	,
["Grzegorczyk",	83.04749	]	,
["Drzewiecki",	83.14849	]	,
["Trojanowski",	83.24937	]	,
["Baginski",	83.35019	]	,
["Ksiazek",	83.45083	]	,
["Jurek",	83.55142	]	,
["Trzcinski",	83.65194	]	,
["Gawron",	83.75195	]	,
["Wojtczak",	83.8518	]	,
["Rogala",	83.95149	]	,
["Kula",	84.05099	]	,
["Kubik",	84.15044	]	,
["Maliszewski",	84.24979	]	,
["Radomski",	84.3491	]	,
["Dabek",	84.4483	]	,
["Kisiel",	84.54723	]	,
["Cebula",	84.64591	]	,
["Rosiak",	84.74455	]	,
["Zareba",	84.84295	]	,
["Gasior",	84.94099	]	,
["Grzesiak",	85.03896	]	,
["Gawlik",	85.13675	]	,
["Cygan",	85.23433	]	,
["Rojek",	85.33187	]	,
["Debowski",	85.42932	]	,
["Bogucki",	85.52658	]	,
["Wieckowski",	85.62379	]	,
["Mikulski",	85.72072	]	,
["Walkowiak",	85.81726	]	,
["Malec",	85.91373	]	,
["Burzynski",	86.00956	]	,
["Flis",	86.10536	]	,
["Wasik",	86.20092	]	,
["Czapla",	86.29642	]	,
["Drozdowski",	86.39118	]	,
["Kwasniewski",	86.48592	]	,
["Wójcicki",	86.58027	]	,
["Rzepka",	86.67421	]	,
["Galazka",	86.76798	]	,
["Lukasiewicz",	86.86163	]	,
["Pawelec",	86.95526	]	,
["Lipski",	87.04879	]	,
["Wnuk",	87.1423	]	,
["Kolodziejski",	87.23572	]	,
["Andrzejczak",	87.329	]	,
["Zaborowski",	87.42227	]	,
["Sokól",	87.51552	]	,
["Urbanczyk",	87.60868	]	,
["Falkowski",	87.70174	]	,
["Filipek",	87.79466	]	,
["Jedrzejczak",	87.88742	]	,
["Ciszewski",	87.9796	]	,
["Zajaczkowski",	88.07176	]	,
["Nowaczyk",	88.16372	]	,
["Bielawski",	88.25559	]	,
["Wegrzyn",	88.34738	]	,
["Krysiak",	88.43917	]	,
["Hajduk",	88.5305	]	,
["Lisiecki",	88.62179	]	,
["Mroczek",	88.71215	]	,
["Jagodzinski",	88.80251	]	,
["Szafranski",	88.89282	]	,
["Bialas",	88.98311	]	,
["Pietras",	89.07318	]	,
["Karwowski",	89.16321	]	,
["Zmuda",	89.25313	]	,
["Lach",	89.34304	]	,
["Kaluza",	89.43293	]	,
["Dziuba",	89.52265	]	,
["Wronski",	89.6122	]	,
["Gruszczynski",	89.70138	]	,
["Skibinski",	89.79051	]	,
["Borek",	89.87957	]	,
["Krakowiak",	89.96858	]	,
["Wasiak",	90.05758	]	,
["Jagiello",	90.14656	]	,
["Skrzypek",	90.23543	]	,
["Lasota",	90.32424	]	,
["Mika",	90.41293	]	,
["Maslowski",	90.50136	]	,
["Juszczak",	90.58972	]	,
["Borowiec",	90.67744	]	,
["Raczynski",	90.76514	]	,
["Sobieraj",	90.85272	]	,
["Slusarczyk",	90.94005	]	,
["Karas",	91.02729	]	,
["Dubiel",	91.11451	]	,
["Lukaszewski",	91.20141	]	,
["Dominiak",	91.28732	]	,
["Kmiecik",	91.37318	]	,
["Bujak",	91.4587	]	,
["Kubacki",	91.54416	]	,
["Pilarski",	91.6296	]	,
["Gutowski",	91.71488	]	,
["Misiak",	91.80006	]	,
["Tarnowski",	91.88514	]	,
["Bartosik",	91.97001	]	,
["Mierzejewski",	92.05482	]	,
["Kopczynski",	92.13956	]	,
["Jakubiak",	92.2241	]	,
["Twardowski",	92.30858	]	,
["Zielonka",	92.39305	]	,
["Jezierski",	92.47745	]	,
["Jurkiewicz",	92.56164	]	,
["Lapinski",	92.64574	]	,
["Florczak",	92.72978	]	,
["Gasiorowski",	92.81369	]	,
["Pakula",	92.89743	]	,
["Piórkowski",	92.98102	]	,
["Janas",	93.06458	]	,
["Bilski",	93.14813	]	,
["Stelmach",	93.23167	]	,
["Bochenek",	93.31507	]	,
["Stec",	93.39842	]	,
["Staszewski",	93.48163	]	,
["Dudziak",	93.56479	]	,
["Noga",	93.64771	]	,
["Skoczylas",	93.73057	]	,
["Pasternak",	93.81333	]	,
["Dobrzynski",	93.89548	]	,
["Górniak",	93.97753	]	,
["Matuszak",	94.0595	]	,
["Piwowarczyk",	94.14143	]	,
["Filipowicz",	94.22326	]	,
["Milczarek",	94.30505	]	,
["Kedziora",	94.3867	]	,
["Adamus",	94.46816	]	,
["Cholewa",	94.54916	]	,
["Slowinski",	94.63013	]	,
["Olczak",	94.71101	]	,
["Koza",	94.79189	]	,
["Jedrzejczyk",	94.87253	]	,
["Czechowski",	94.95316	]	,
["Gawel",	95.03357	]	,
["Jaskiewicz",	95.11396	]	,
["Wilczek",	95.19426	]	,
["Kaczmarski",	95.27444	]	,
["Jankowiak",	95.35459	]	,
["Krupinski",	95.43445	]	,
["Strzelczyk",	95.51429	]	,
["Kubica",	95.59395	]	,
["Misztal",	95.67332	]	,
["Sieradzki",	95.75241	]	,
["Iwanski",	95.83145	]	,
["Slawinski",	95.91037	]	,
["Adamczak",	95.98909	]	,
["Szwed",	96.06777	]	,
["Zwolinski",	96.14641	]	,
["Zygmunt",	96.22504	]	,
["Paczkowski",	96.30356	]	,
["Kapusta",	96.38203	]	,
["Adamek",	96.4605	]	,
["Stepniak",	96.53886	]	,
["Modzelewski",	96.61696	]	,
["Majcher",	96.695	]	,
["Jackowski",	96.7729	]	,
["Siedlecki",	96.85076	]	,
["Niedzielski",	96.9286	]	,
["Adamiak",	97.00621	]	,
["Malicki",	97.08371	]	,
["Szczesniak",	97.16117	]	,
["Soltys",	97.23852	]	,
["Florek",	97.31577	]	,
["Rucinski",	97.39234	]	,
["Kaszuba",	97.46875	]	,
["Bober",	97.54516	]	,
["Kwiecinski",	97.62142	]	,
["Adamowicz",	97.69725	]	,
["Sochacki",	97.77294	]	,
["Grzywacz",	97.84838	]	,
["Golab",	97.92351	]	,
["Dec",	97.99851	]	,
["Wlodarski",	98.07334	]	,
["Stolarczyk",	98.148	]	,
["Bienkowski",	98.22259	]	,
["Niemczyk",	98.29712	]	,
["Szyszka",	98.37149	]	,
["Mroczkowski",	98.44576	]	,
["Prokop",	98.51996	]	,
["Góral",	98.59406	]	,
["Stanislawski",	98.66813	]	,
["Dudzik",	98.74214	]	,
["Stanek",	98.81597	]	,
["Kuc",	98.88975	]	,
["Molenda",	98.96337	]	,
["Paszkowski",	99.03698	]	,
["Sitek",	99.11046	]	,
["Swierczynski",	99.18391	]	,
["Suski",	99.25735	]	,
["Drabik",	99.33063	]	,
["Czekaj",	99.40391	]	,
["Rusin",	99.47708	]	,
["Galka",	99.54981	]	,
["Czerniak",	99.6225	]	,
["Kepa",	99.69507	]	,
["Fratczak",	99.76744	]	,
["Guzik",	99.83966	]	,
["Kuchta",	99.91179	]	,
["Budzynski",	99.98391	]	,
["Chojnowski",	100.05602	]	,
["Szatkowski",	100.12809	]	,
["Kruszewski",	100.20016	]	,
["Kowalczuk",	100.2722	]	,
["Wiecek",	100.3442	]	,
["Kaniewski",	100.41614	]	,
["Skóra",	100.48801	]	,
["Pytel",	100.55981	]	,
["Puchalski",	100.63161	]	,
["Kotowski",	100.70318	]	,
["Augustyn",	100.77461	]	,
["Michalek",	100.84598	]	,
["Szczesny",	100.9172	]	,
["Zuk",	100.9884	]	,
["Zdunek",	101.05958	]	,
["Urbanowicz",	101.13071	]	,
["Wolak",	101.20176	]	,
["Kolasa",	101.27273	]	,
["Januszewski",	101.34364	]	,
["Kobus",	101.41448	]	,
["Piechota",	101.48521	]	,
["Pawlikowski",	101.55591	]	,
["Skalski",	101.6266	]	,
["Bozek",	101.69729	]	,
["Motyka",	101.76788	]	,
["Urbanek",	101.83845	]	,
["Bielak",	101.90895	]	,
["Zagórski",	101.97935	]	,
["Banasik",	102.04963	]	,
["Wypych",	102.11969	]	,
["Rosa",	102.1897	]	,
["Lipka",	102.25925	]	,
["Dabkowski",	102.32877	]	,
["Trzeciak",	102.39818	]	,
["Plonka",	102.46758	]	,
["Bakowski",	102.53697	]	,
["Tomala",	102.60627	]	,
["Partyka",	102.67552	]	,
["Broda",	102.74471	]	,
["Glab",	102.8138	]	,
["Kita",	102.88287	]	,
["Jurkowski",	102.95182	]	,
["Kielbasa",	103.02075	]	,
["Glogowski",	103.08961	]	,
["Pilat",	103.1584	]	,
["Knapik",	103.22718	]	,
["Michalczyk",	103.29591	]	,
["Kolakowski",	103.36464	]	,
["Drózdz",	103.43324	]	,
["Bialkowski",	103.50182	]	,
["Przybyl",	103.57038	]	,
["Stolarski",	103.63888	]	,
["Napierala",	103.70737	]	,
["Balcerzak",	103.77579	]	,
["Zietek",	103.84404	]	,
["Aleksandrowicz",	103.91227	]	,
["Buczkowski",	103.98046	]	,
["Soltysiak",	104.04853	]	,
["Marchewka",	104.11653	]	,
["Koper",	104.18453	]	,
["Robak",	104.25241	]	,
["Cichy",	104.32013	]	,
["Miskiewicz",	104.38766	]	,
["Buczynski",	104.45514	]	,
["Rybarczyk",	104.52257	]	,
["Stachura",	104.58983	]	,
["Mrozek",	104.65698	]	,
["Wos",	104.72395	]	,
["Klosowski",	104.79092	]	,
["Jagielski",	104.85788	]	,
["Janusz",	104.92481	]	,
["Pawlicki",	104.99172	]	,
["Górny",	105.05857	]	,
["Skowronek",	105.12502	]	,
["Kasperek",	105.19145	]	,
["Grzeskowiak",	105.25781	]	,
["Warchol",	105.32416	]	,
["Szymkowiak",	105.39037	]	,
["Grudzien",	105.45656	]	,
["Wyszynski",	105.5227	]	,
["Zyla",	105.58864	]	,
["Wolinski",	105.65448	]	,
["Maciag",	105.72026	]	,
["Rudzinski",	105.78603	]	,
["Furman",	105.85178	]	,
["Flak",	105.91753	]	,
["Stachurski",	105.98326	]	,
["Grzesik",	106.04898	]	,
["Mackiewicz",	106.11465	]	,
["Zablocki",	106.1802	]	,
["Wojtas",	106.24575	]	,
["Korzeniowski",	106.31128	]	,
["Niedziela",	106.37671	]	,
["Gwózdz",	106.44214	]	,
["Gadomski",	106.50754	]	,
["Blaszczak",	106.57288	]	,
["Budzinski",	106.63814	]	,
["Ossowski",	106.70336	]	,
["Piotrowicz",	106.76857	]	,
["Prus",	106.83362	]	,
["Pietruszka",	106.89867	]	,
["Kucinski",	106.96356	]	,
["Chmura",	107.02839	]	,
["Kostecki",	107.09317	]	,
["Jarzabek",	107.15792	]	,
["Golec",	107.22266	]	,
["Jaros",	107.28715	]	,
["Radecki",	107.35157	]	,
["Chudzik",	107.41594	]	,
["Duszynski",	107.4803	]	,
["Niedzwiecki",	107.54462	]	,
["Surma",	107.60892	]	,
["Kawecki",	107.67315	]	,
["Wojtkowiak",	107.73719	]	,
["Bartnik",	107.80117	]	,
["Wolny",	107.86505	]	,
["Szczerba",	107.92876	]	,
["Maslanka",	107.99247	]	,
["Szczesna",	108.05585	]	,
["Wiacek",	108.11916	]	,
["Majka",	108.18243	]	,
["Tracz",	108.24566	]	,
["Szelag",	108.30877	]	,
["Kogut",	108.37179	]	,
["Stawicki",	108.4348	]	,
["Nowinski",	108.49777	]	,
["Rudzki",	108.56054	]	,
["Kulinski",	108.62327	]	,
["Wojtasik",	108.68594	]	,
["Izdebski",	108.74849	]	,
["Stachowicz",	108.811	]	,
["Kalisz",	108.87347	]	,
["Kulig",	108.93592	]	,
["Adamiec",	108.99836	]	,
["Krukowski",	109.06068	]	,
["Józwik",	109.12294	]	,
["Kos",	109.18516	]	,
["Miller",	109.24737	]	,
["Lenart",	109.30957	]	,
["Kosowski",	109.37165	]	,
["Kmiec",	109.43367	]	,
["Pilch",	109.49561	]	,
["Lesiak",	109.55754	]	,
["Porebski",	109.61905	]	,
["Szwarc",	109.68055	]	,
["Herman",	109.74204	]	,
["Kujawski",	109.8035	]	,
["Kawa",	109.86494	]	,
["Bielski",	109.92632	]	,
["Grudzinski",	109.98762	]	,
["Kuzma",	110.04881	]	,
["Roszak",	110.10999	]	,
["Krasowski",	110.17117	]	,
["Bielinski",	110.23229	]	,
["Skorupa",	110.29318	]	,
["Malik",	110.35398	]	,
["Gorczyca",	110.41467	]	,
["Krakowski",	110.47529	]	,
["Banaszek",	110.5359	]	,
["Lukaszewicz",	110.59647	]	,
["Dymek",	110.65674	]	,
["Dziura",	110.71691	]	,
["Sobanski",	110.77706	]	,
["Drozdz",	110.83696	]	,
["Sojka",	110.89683	]	,
["Ratajczyk",	110.95658	]	,
["Salamon",	111.01627	]	,
["Trela",	111.07594	]	,
["Rudnik",	111.1355	]	,
["Adamczewski",	111.19505	]	,
["Tomasik",	111.25443	]	,
["Smigielski",	111.31368	]	,
["Wojda",	111.37291	]	,
["Wrzesinski",	111.43212	]	,
["Dzikowski",	111.49112	]	,
["Woloszyn",	111.55011	]	,
["Dutkiewicz",	111.60907	]	,
["Kopacz",	111.66796	]	,
["Studzinski",	111.72684	]	,
["Ciolek",	111.78572	]	,
["Sek",	111.84454	]	,
["Magiera",	111.90334	]	,
["Ambroziak",	111.96212	]	,
["Moskal",	112.02088	]	,
["Bobrowski",	112.07962	]	,
["Slezak",	112.13831	]	,
["Olczyk",	112.19698	]	,
["Sledz",	112.25554	]	,
["Filip",	112.314	]	,
["Kawka",	112.37231	]	,
["Bury",	112.43056	]	,
["Dolata",	112.4888	]	,
["Rozek",	112.54685	]	,
["Pelka",	112.60481	]	,
["Swiatkowski",	112.66274	]	,
["Korczak",	112.72065	]	,
["Jurczyk",	112.77824	]	,
["Litwin",	112.83571	]	,
["Woznica",	112.89314	]	,
["Sobota",	112.95055	]	,
["Paprocki",	113.00792	]	,
["Roman",	113.06522	]	,
["Warzecha",	113.12249	]	,
["Szuba",	113.17972	]	,
["Mielcarek",	113.23695	]	,
["Rogozinski",	113.29411	]	,
["Pietka",	113.35122	]	,
["Kraszewski",	113.40833	]	,
["Makuch",	113.46541	]	,
["Kosmala",	113.52249	]	,
["Kozik",	113.57928	]	,
["Rusek",	113.63606	]	,
["Zuchowski",	113.69277	]	,
["Paszkiewicz",	113.74947	]	,
["Juszczyk",	113.80616	]	,
["Jurczak",	113.86284	]	,
["Lewinski",	113.91946	]	,
["Górna",	113.97607	]	,
["Królak",	114.03267	]	,
["Roszkowski",	114.08912	]	,
["Piwowarski",	114.14554	]	,
["Korzeniewski",	114.20192	]	,
["Rybka",	114.2582	]	,
["Jeziorski",	114.31445	]	,
["Pogorzelski",	114.37065	]	,
["Szkudlarek",	114.42683	]	,
["Lipiec",	114.48293	]	,
["Sidor",	114.539	]	,
["Mierzwa",	114.59496	]	,
["Rys",	114.65083	]	,
["Malkowski",	114.70669	]	,
["Czarnota",	114.76252	]	,
["Werner",	114.81827	]	,
["Karcz",	114.87401	]	,
["Kruszynski",	114.92974	]	,
["Grabarczyk",	114.98545	]	,
["Turowski",	115.0411	]	,
["Staszak",	115.09672	]	,
["Gut",	115.15221	]	,
["Bogdanski",	115.20764	]	,
["Figura",	115.26292	]	,
["Wyrzykowski",	115.31815	]	,
["Siwiec",	115.37332	]	,
["Rzepecki",	115.42846	]	,
["Gola",	115.4836	]	,
["Figiel",	115.53858	]	,
["Szot",	115.59355	]	,
["Kurzawa",	115.64846	]	,
["Karolak",	115.70312	]	,
["Ferenc",	115.75778	]	,
["Kozakiewicz",	115.81243	]	,
["Kwiatek",	115.86691	]	,
["Biskup",	115.92134	]	,
["Piechocki",	115.97571	]	,
["Kokot",	116.03	]	,
["Raczkowski",	116.08426	]	,
["Kapuscinski",	116.13848	]	,
["Banaszak",	116.19258	]	,
["Cichosz",	116.24665	]	,
["Bilinski",	116.30072	]	,
["Sekula",	116.35476	]	,
["Dolinski",	116.40876	]	,
["Szopa",	116.46275	]	,
["Krauze",	116.51667	]	,
["Szymanek",	116.57049	]	,
["Bogacz",	116.62427	]	,
["Bialecki",	116.67791	]	,
["Antoniak",	116.73151	]	,
["Suchecki",	116.78508	]	,
["Czuba",	116.8386	]	,
["Golinski",	116.89209	]	,
["Szymanowski",	116.94552	]	,
["Stepniewski",	116.99885	]	,
["Grodzki",	117.05206	]	,
["Jakubczyk",	117.10526	]	,
["Wojtowicz",	117.15845	]	,
["Wach",	117.21157	]	,
["Tokarz",	117.26466	]	,
["Tkacz",	117.31762	]	,
["Lange",	117.37057	]	,
["Kolasinski",	117.4235	]	,
["Olszak",	117.4763	]	,
["Filipczak",	117.5291	]	,
["Bartoszek",	117.58187	]	,
["Kochanski",	117.63453	]	,
["Perkowski",	117.68717	]	,
["Kalinski",	117.73967	]	,
["Czaplinski",	117.79212	]	,
["Rokicki",	117.84452	]	,
["Kazmierski",	117.89692	]	,
["Grzybek",	117.94932	]	,
["Brodowski",	118.00172	]	,
["Paradowski",	118.05411	]	,
["Lorenc",	118.10644	]	,
["Reszka",	118.1587	]	,
["Konarski",	118.21088	]	,
["Kepka",	118.26301	]	,
["Romanski",	118.31512	]	,
["Kusiak",	118.36702	]	,
["Hoffmann",	118.41889	]	,
["Barszcz",	118.47072	]	,
["Nadolski",	118.52254	]	,
["Piech",	118.57434	]	,
["Puchala",	118.62608	]	,
["Jaroszewski",	118.6778	]	,
["Lawniczak",	118.72951	]	,
["Waszkiewicz",	118.78119	]	,
["Niezgoda",	118.83286	]	,
["Janczak",	118.88451	]	,
["Iwanowski",	118.93615	]	,
["Polanski",	118.9877	]	,
["Plichta",	119.03919	]	,
["Potocki",	119.09059	]	,
["Bugaj",	119.14197	]	,
["Szarek",	119.19329	]	,
["Lachowicz",	119.2446	]	,
["Franczak",	119.29591	]	,
["Kupiec",	119.34706	]	,
["Jaskulski",	119.39819	]	,
["Borys",	119.4493	]	,
["Glinski",	119.50031	]	,
["Domagalski",	119.55129	]	,
["Knap",	119.60225	]	,
["Kazimierczak",	119.6532	]	,
["Kruczek",	119.70408	]	,
["Oleksy",	119.75495	]	,
["Majkowski",	119.80581	]	,
["Zarebski",	119.85664	]	,
["Olech",	119.90747	]	,
["Solecki",	119.95821	]	,
["Bojarski",	120.00894	]	,
["Szymaniak",	120.05962	]	,
["Kukla",	120.1103	]	,
["Wrzosek",	120.16091	]	,
["Golabek",	120.21151	]	,
["Kolodziejczak",	120.26209	]	,
["Kempa",	120.31267	]	,
["Cwiklinski",	120.36324	]	,
["Nalepa",	120.41375	]	,
["Frankiewicz",	120.46425	]	,
["Stachowski",	120.51474	]	,
["Piskorz",	120.56513	]	,
["Kozera",	120.61541	]	,
["Osuch",	120.66567	]	,
["Los",	120.71589	]	,
["Dobrzanski",	120.7661	]	,
["Szadkowski",	120.81629	]	,
["Wachowiak",	120.86641	]	,
["Cwik",	120.91653	]	,
["Dusza",	120.96655	]	,
["Radziszewski",	121.01637	]	,
["Wdowiak",	121.06616	]	,
["Osowski",	121.11587	]	,
["Jakubiec",	121.16555	]	,
["Brzeski",	121.21513	]	,
["Szczygielski",	121.26464	]	,
["Pasek",	121.31411	]	,
["Spiewak",	121.36354	]	,
["Drag",	121.4129	]	,
["Brozek",	121.46225	]	,
["Wielgus",	121.51156	]	,
["Koprowski",	121.56077	]	,
["Bugajski",	121.60996	]	,
["Okon",	121.65908	]	,
["Królik",	121.7082	]	,
["Antkowiak",	121.75718	]	,
["Matczak",	121.80612	]	,
["Balicki",	121.85506	]	,
["Galinski",	121.90392	]	,
["Pisarek",	121.95277	]	,
["Wagner",	122.00159	]	,
["Modrzejewski",	122.05032	]	,
["Kawalec",	122.09905	]	,
["Traczyk",	122.14775	]	,
["Gladysz",	122.19644	]	,
["Jaskólski",	122.24511	]	,
["Wisniowski",	122.29368	]	,
["Blazejewski",	122.34215	]	,
["Dudkiewicz",	122.39059	]	,
["Wozny",	122.43893	]	,
["Romaniuk",	122.48724	]	,
["Czubak",	122.53548	]	,
["Bernat",	122.58371	]	,
["Czerwonka",	122.63193	]	,
["Pekala",	122.68012	]	,
["Iwanicki",	122.72831	]	,
["Szczurek",	122.77648	]	,
["Swider",	122.82463	]	,
["Iwaniuk",	122.87274	]	,
["Morawiec",	122.92083	]	,
["Szpak",	122.96885	]	,
["Wojtaszek",	123.01685	]	,
["Smolarek",	123.06484	]	,
["Garbacz",	123.11281	]	,
["Kostrzewski",	123.16076	]	,
["Maczka",	123.20861	]	,
["Kedzior",	123.25638	]	,
["Blaszkiewicz",	123.30414	]	,
["Klimaszewski",	123.35187	]	,
["Drewniak",	123.3996	]	,
["Kokoszka",	123.44727	]	,
["Liszka",	123.49491	]	,
["Marczewski",	123.54254	]	,
["Szubert",	123.5901	]	,
["Galecki",	123.63766	]	,
["Switala",	123.68519	]	,
["Jedynak",	123.73272	]	,
["Pelc",	123.78022	]	,
["Gacek",	123.82771	]	,
["Kulikowski",	123.87519	]	,
["Spychala",	123.92266	]	,
["Debicki",	123.97008	]	,
["Gasiorek",	124.01749	]	,
["Sobocinski",	124.06489	]	,
["Grabski",	124.11227	]	,
["Kochanowski",	124.15955	]	,
["Góralczyk",	124.20677	]	,
["Platek",	124.25398	]	,
["Lach",	124.30119	]	,
["Caban",	124.34837	]	,
["Grela",	124.39551	]	,
["Rodak",	124.44264	]	,
["Tuszynski",	124.48975	]	,
["Zasada",	124.53684	]	,
["Burek",	124.58388	]	,
["Mularczyk",	124.63089	]	,
["Slominski",	124.67785	]	,
["Maziarz",	124.72476	]	,
["Bartosiewicz",	124.77164	]	,
["Pietrzykowski",	124.8184	]	,
["Biela",	124.86511	]	,
["Pieta",	124.91178	]	,
["Nosal",	124.95843	]	,
["Cieslinski",	125.00503	]	,
["Miszczak",	125.05161	]	,
["Piszczek",	125.09818	]	,
["Zborowski",	125.14471	]	,
["Bator",	125.19124	]	,
["Kapica",	125.23774	]	,
["Orzel",	125.28422	]	,
["Skwarek",	125.33061	]	,
["Winnicki",	125.37698	]	,
["Galas",	125.42317	]	,
["Gaj",	125.46933	]	,
["Dawid",	125.51549	]	,
["Klosinski",	125.56164	]	,
["Gajek",	125.60776	]	,
["Wloch",	125.65383	]	,
["Stoklosa",	125.69988	]	,
["Podsiadlo",	125.74592	]	,
["Sarnowski",	125.79191	]	,
["Plewa",	125.83786	]	,
["Mlynarski",	125.88375	]	,
["Bien",	125.92955	]	,
["Ludwiczak",	125.97534	]	,
["Przybyszewski",	126.02112	]	,
["Czapski",	126.06689	]	,
["Blonski",	126.11257	]	,
["Szeliga",	126.15819	]	,
["Bialy",	126.20378	]	,
["Szweda",	126.24932	]	,
["Kostka",	126.29486	]	,
["Pierzchala",	126.34034	]	,
["Napiórkowski",	126.38581	]	,
["Daniel",	126.43128	]	,
["Burda",	126.47672	]	,
["Cyran",	126.52213	]	,
["Kulpa",	126.56751	]	,
["Surowiec",	126.61286	]	,
["Wiszniewski",	126.65816	]	,
["Kornacki",	126.70343	]	,
["Skorupski",	126.74866	]	,
["Guz",	126.79388	]	,
["Dworak",	126.83908	]	,
["Kozicki",	126.88426	]	,
["Sobczynski",	126.92939	]	,
["Slomka",	126.97451	]	,
["Wydra",	127.01961	]	,
["Pastuszka",	127.06471	]	,
["Lemanski",	127.10979	]	,
["Frydrych",	127.15486	]	,
["Furtak",	127.19989	]	,
["Sala",	127.24491	]	,
["Sulkowski",	127.28992	]	,
["Jonczyk",	127.33491	]	,
["Klimas",	127.37981	]	,
["Póltorak",	127.42468	]	,
["Mieczkowski",	127.46955	]	,
["Makarewicz",	127.51439	]	,
["Walczyk",	127.55921	]	,
["Wajda",	127.60402	]	,
["Schmidt",	127.64882	]	,
["Grygiel",	127.69362	]	,
["Zawistowski",	127.73838	]	,
["Weber",	127.78303	]	,
["Dylewski",	127.82764	]	,
["Nycz",	127.8722	]	,
["Strzalkowski",	127.91675	]	,
["Buda",	127.96119	]	,
["Borowiak",	128.00562	]	,
["Baron",	128.04999	]	,
["Rybacki",	128.09433	]	,
["Dul",	128.13866	]	,
["Oles",	128.18292	]	,
["Nowosielski",	128.22714	]	,
["Fabisiak",	128.27136	]	,
["Rychlik",	128.31556	]	,
["Zapala",	128.35974	]	,
["Góralski",	128.4039	]	,
["Rybinski",	128.44798	]	,
["Lewczuk",	128.49203	]	,
["Plucinski",	128.53607	]	,
["Czaplicki",	128.5801	]	,
["Kraski",	128.6241	]	,
["Ruminski",	128.66809	]	,
["Jamróz",	128.71208	]	,
["Kolacz",	128.75605	]	,
["Iwan",	128.80002	]	,
["Matuszczak",	128.84395	]	,
["Rokita",	128.88786	]	,
["Mrowiec",	128.93176	]	,
["Sitko",	128.97563	]	,
["Marcinkiewicz",	129.01948	]	,
["Waligóra",	129.06331	]	,
["Stencel",	129.10711	]	,
["Chudy",	129.15091	]	,
["Janeczek",	129.1947	]	,
["Lasek",	129.23848	]	,
["Kaluzny",	129.28226	]	,
["Waszak",	129.32601	]	,
["Czapiewski",	129.36976	]	,
["Sudol",	129.4135	]	,
["Paszek",	129.45713	]	,
["Kozyra",	129.50073	]	,
["Szmidt",	129.54432	]	,
["Nocon",	129.58789	]	,
["Ogórek",	129.63143	]	,
["Zawislak",	129.67493	]	,
["Baczynski",	129.71834	]	,
["Sosinski",	129.76165	]	,
["Wilczewski",	129.80494	]	,
["Mach",	129.84823	]	,
["Turski",	129.89147	]	,
["Zaleski",	129.93469	]	,
["Wasielewski",	129.9779	]	,
["Luczynski",	130.0211	]	,
["Wielgosz",	130.06425	]	,
["Osiecki",	130.10736	]	,
["Pisarski",	130.15046	]	,
["Bernacki",	130.19351	]	,
["Bartnicki",	130.23654	]	,
["Golaszewski",	130.27956	]	,
["Kasperski",	130.32255	]	,
["Jakubczak",	130.36554	]	,
["Bryla",	130.40851	]	,
["Stopa",	130.45145	]	,
["Zatorski",	130.49432	]	,
["Wasowski",	130.53717	]	,
["Kalita",	130.58002	]	,
["Zawisza",	130.62285	]	,
["Trzaski",	130.66562	]	,
["Mikula",	130.70837	]	,
["Siuda",	130.75108	]	,
["Dzieciol",	130.79379	]	,
["Panasiuk",	130.83648	]	,
["Pilarczyk",	130.87915	]	,
["Wilinski",	130.92181	]	,
["Swierczek",	130.96445	]	,
["Bojanowski",	131.00709	]	,
["Wierzchowski",	131.04972	]	,
["Tarka",	131.09234	]	,
["Tyminski",	131.13494	]	,
["Cicha",	131.17754	]	,
["Neumann",	131.22013	]	,
["Mysliwiec",	131.26268	]	,
["Grzegorzewski",	131.30522	]	,
["Wcislo",	131.34774	]	,
["Musiol",	131.39023	]	,
["Kluski",	131.43271	]	,
["Cywinski",	131.47517	]	,
["Kalbarczyk",	131.51762	]	,
["Mis",	131.56003	]	,
["Seweryn",	131.60241	]	,
["Kulas",	131.64479	]	,
["Swiercz",	131.68715	]	,
["Walas",	131.72948	]	,
["Radzikowski",	131.77179	]	,
["Machnik",	131.814	]	,
["Boron",	131.85619	]	,
["Kreft",	131.89837	]	,
["Miler",	131.94053	]	,
["Koscielniak",	131.98267	]	,
["Krawczynski",	132.02478	]	,
["Bartkowski",	132.06687	]	,
["Smolen",	132.10892	]	,
["Ozga",	132.15096	]	,
["Kozinski",	132.19297	]	,
["Celinski",	132.23497	]	,
["Giza",	132.27689	]	,
["Mikolajewski",	132.31878	]	,
["Gaska",	132.36063	]	,
["Sliwka",	132.40246	]	,
["Klonowski",	132.44428	]	,
["Ryba",	132.48606	]	,
["Malczewski",	132.52774	]	,
["Cwiek",	132.56942	]	,
["Adamkiewicz",	132.6111	]	,
["Jackiewicz",	132.65276	]	,
["Chylinski",	132.69441	]	,
["Aniol",	132.73606	]	,
["Wachowski",	132.7777	]	,
["Smyk",	132.81926	]	,
["Lorek",	132.86081	]	,
["Biel",	132.90232	]	,
["Chojecki",	132.94375	]	,
["Filipowski",	132.98513	]	,
["Pater",	133.0265	]	,
["Truszkowski",	133.06785	]	,
["Tabor",	133.1092	]	,
["Szafran",	133.15054	]	,
["Ostaszewski",	133.19186	]	,
["Kakol",	133.23317	]	,
["Ozóg",	133.27447	]	,
["Gazda",	133.31576	]	,
["Motyl",	133.35696	]	,
["Gwiazda",	133.3981	]	,
["Dzik",	133.4392	]	,
["Chrobak",	133.4803	]	,
["Kaluzna",	133.52137	]	,
["Komar",	133.56243	]	,
["Kotarski",	133.60345	]	,
["Dawidowicz",	133.64447	]	,
["Dziegielewski",	133.68546	]	,
["Kloc",	133.72639	]	,
["Lukomski",	133.7673	]	,
["Florczyk",	133.80818	]	,
["Mrozinski",	133.84901	]	,
["Wojnowski",	133.88983	]	,
["Kupczyk",	133.93065	]	,
["Majda",	133.97146	]	,
["Calka",	134.0122	]	,
["Starzynski",	134.05291	]	,
["Sakowski",	134.09361	]	,
["Kuligowski",	134.13431	]	,
["Karwacki",	134.175	]	,
["Bogdanowicz",	134.21569	]	,
["Mizera",	134.25631	]	,
["Abramowicz",	134.29693	]	,
["Szczepanek",	134.33751	]	,
["Rydzewski",	134.37805	]	,
["Sobon",	134.41857	]	,
["Trawinski",	134.45908	]	,
["Karasinski",	134.49959	]	,
["Szewczuk",	134.54007	]	,
["Kurkowski",	134.58055	]	,
["Ziomek",	134.62101	]	,
["Niedzwiedz",	134.66147	]	,
["Bogdan",	134.7019	]	,
["Szostek",	134.7423	]	,
["Róg",	134.7827	]	,
["Gajos",	134.82299	]	,
["Sagan",	134.86327	]	,
["Lysiak",	134.90341	]	,
["Dawidowski",	134.9435	]	,
["Popiolek",	134.98358	]	,
["Szczotka",	135.02362	]	,
["Topolski",	135.06365	]	,
["Suchocki",	135.10368	]	,
["Karbowski",	135.14367	]	,
["Gregorczyk",	135.18366	]	,
["Zdanowicz",	135.2236	]	,
["Lugowski",	135.26352	]	,
["Sobkowiak",	135.30339	]	,
["Drzazga",	135.34326	]	,
["Przygoda",	135.3831	]	,
["Jezewski",	135.42293	]	,
["Józefowicz",	135.46272	]	,
["Ignaczak",	135.5025	]	,
["Frackiewicz",	135.54221	]	,
["Olesinski",	135.58191	]	,
["Placzek",	135.62158	]	,
["Stawski",	135.6612	]	,
["Ptaszynski",	135.70081	]	,
["Olender",	135.74042	]	,
["Borucki",	135.78	]	,
["Jankiewicz",	135.81957	]	,
["Majer",	135.85913	]	,
["Zaleski",	135.89865	]	,
["Mirowski",	135.93811	]	,
["Sroczynski",	135.97755	]	,
["Krause",	136.01697	]	,
["Kosiorek",	136.05639	]	,
["Borowicz",	136.0958	]	,
["Baczek",	136.1352	]	,
["Moskwa",	136.17458	]	,
["Andrzejak",	136.21384	]	,
["Gruca",	136.25308	]	,
["Dybowski",	136.29224	]	,
["Widera",	136.33137	]	,
["Karpowicz",	136.37042	]	,
["Myszka",	136.40945	]	,
["Adamczuk",	136.44847	]	,
["Mastalerz",	136.48744	]	,
["Daniluk",	136.52639	]	,
["Madejski",	136.56533	]	,
["Bocian",	136.60427	]	,
["Ciszek",	136.6432	]	,
["Kobylinski",	136.68208	]	,
["Kondracki",	136.72095	]	,
["Bloch",	136.75982	]	,
["Podolski",	136.79868	]	,
["Borowiecki",	136.83753	]	,
["Rybczynski",	136.87636	]	,
["Walkiewicz",	136.91518	]	,
["Maciaszek",	136.954	]	,
["Dopierala",	136.99282	]	,
["Borecki",	137.03164	]	,
["Pospiech",	137.07041	]	,
["Jarecki",	137.10918	]	,
["Krasuski",	137.14791	]	,
["Bogacki",	137.18662	]	,
["Kawczynski",	137.22531	]	,
["Sitarz",	137.26397	]	,
["Bieganski",	137.30262	]	,
["Oleksiak",	137.34115	]	,
["Halas",	137.37968	]	,
["Pokorski",	137.41817	]	,
["Paciorek",	137.45665	]	,
["Ziemba",	137.49511	]	,
["Kepinski",	137.53356	]	,
["Jez",	137.57198	]	,
["Piekarz",	137.61039	]	,
["Depta",	137.6488	]	,
["Kunicki",	137.6872	]	,
["Dominik",	137.72557	]	,
["Nosek",	137.76391	]	,
["Radwanski",	137.80223	]	,
["Zdrojewski",	137.84048	]	,
["Raczka",	137.87871	]	,
["Ruszkowski",	137.91691	]	,
["Sekowski",	137.9551	]	,
["Pawlowicz",	137.99326	]	,
["Kusz",	138.03142	]	,
["Wiatr",	138.06957	]	,
["Czerniawski",	138.10767	]	,
["Popek",	138.14573	]	,
["Matyja",	138.18378	]	,
["Lachowski",	138.22182	]	,
["Jelen",	138.25984	]	,
["Mielnik",	138.29785	]	,
["Krasinski",	138.33586	]	,
["Hofman",	138.37385	]	,
["Wierzbowski",	138.41183	]	,
["Chrusciel",	138.44979	]	,
["Kicinski",	138.4877	]	,
["Jach",	138.52561	]	,
["Sznajder",	138.5635	]	,
["Galuszka",	138.60139	]	,
["Synowiec",	138.63927	]	,
["Szumski",	138.67714	]	,
["Deptula",	138.71497	]	,
["Jelonek",	138.75279	]	,
["Kisielewski",	138.79059	]	,
["Rataj",	138.82838	]	,
["Janikowski",	138.86607	]	,
["Bielicki",	138.90374	]	,
["Sieminski",	138.9414	]	,
["Kasprowicz",	138.97904	]	,
["Kudla",	139.01665	]	,
["Zwierzchowski",	139.05425	]	,
["Strzelec",	139.09184	]	,
["Sulek",	139.12941	]	,
["Tarkowski",	139.16697	]	,
["Piechowiak",	139.20442	]	,
["Woznicki",	139.24186	]	,
["Bartosiak",	139.27927	]	,
["Jarocki",	139.31665	]	,
["Szafraniec",	139.35393	]	,
["Ogonowski",	139.39117	]	,
["Górzynski",	139.42841	]	,
["Szczesniak",	139.4656	]	,
["Kuna",	139.50279	]	,
["Suchodolski",	139.53996	]	,
["Klein",	139.57713	]	,
["Badura",	139.61429	]	,
["Ogrodnik",	139.65143	]	,
["Kuzminski",	139.68854	]	,
["Banaszkiewicz",	139.72563	]	,
["Kubala",	139.76271	]	,
["Lukowski",	139.79978	]	,
["Fornal",	139.83683	]	,
["Kosior",	139.87386	]	,
["Marczuk",	139.91084	]	,
["Lata",	139.94781	]	,
["Pankowski",	139.98477	]	,
["Piskorski",	140.0217	]	,
["Kuklinski",	140.05861	]	,
["Fortuna",	140.09551	]	,
["Kulakowski",	140.13239	]	,
["Zmijewski",	140.16922	]	,
["Guzek",	140.20604	]	,
["Zienkiewicz",	140.24282	]	,
["Badowski",	140.27958	]	,
["Glinka",	140.31633	]	,
["Poznanski",	140.35306	]	,
["Kossakowski",	140.38977	]	,
["Kuta",	140.42642	]	,
["Idzikowski",	140.46304	]	,
["Ficek",	140.49966	]	,
["Guzowski",	140.53625	]	,
["Cholewinski",	140.57284	]	,
["Abramczyk",	140.60943	]	,
["Wasowicz",	140.64597	]	,
["Wlazlo",	140.68249	]	,
["Machowski",	140.71897	]	,
["Debinski",	140.75545	]	,
["Skrzynski",	140.79189	]	,
["Myszkowski",	140.82832	]	,
["Oleszczuk",	140.86469	]	,
["Duraj",	140.90106	]	,
["Zajkowski",	140.93741	]	,
["Mirek",	140.97374	]	,
["Jaskula",	141.01007	]	,
["Fila",	141.04635	]	,
["Nykiel",	141.08262	]	,
["Andruszkiewicz",	141.11885	]	,
["Pyka",	141.15503	]	,
["Pawlaczyk",	141.19118	]	,
["Dembinski",	141.2273	]	,
["Misiewicz",	141.26335	]	,
["Mitura",	141.29939	]	,
["Wolanski",	141.33542	]	,
["Krzak",	141.37141	]	,
["Niedbala",	141.40738	]	,
["Piwonski",	141.44334	]	,
["Cabaj",	141.47929	]	,
["Sawczuk",	141.51518	]	,
["Kwasniak",	141.55106	]	,
["Stankowski",	141.58691	]	,
["Ulatowski",	141.62273	]	,
["Pacek",	141.65852	]	,
["Korczynski",	141.69428	]	,
["Zimny",	141.72995	]	,
["Szlachta",	141.76562	]	,
["Starczewski",	141.80129	]	,
["Krygier",	141.83696	]	,
["Mrozowski",	141.87262	]	,
["Krzyzaniak",	141.90828	]	,
["Formela",	141.94393	]	,
["Janiec",	141.97957	]	,
["Sobierajski",	142.0152	]	,
["Mrozik",	142.05082	]	,
["Przybylak",	142.08643	]	,
["Matusik",	142.12204	]	,
["Sarna",	142.15764	]	,
["Was",	142.19321	]	,
["Markowicz",	142.22876	]	,
["Cieslar",	142.26425	]	,
["Tworek",	142.29973	]	,
["Samborski",	142.33516	]	,
["Rynkiewicz",	142.37058	]	,
["Barczyk",	142.40599	]	,
["Rusinek",	142.44138	]	,
["Forys",	142.47674	]	,
["Kazimierski",	142.51208	]	,
["Boguslawski",	142.5474	]	,
["Czop",	142.58271	]	,
["Rowinski",	142.61799	]	,
["Tokarczyk",	142.65324	]	,
["Zbikowski",	142.68846	]	,
["Wójciak",	142.72368	]	,
["Czarnowski",	142.75888	]	,
["Klimowicz",	142.79406	]	,
["Spychalski",	142.82917	]	,
["Trojan",	142.86427	]	,
["Knop",	142.89937	]	,
["Gromek",	142.93442	]	,
["Baldyga",	142.96947	]	,
["Bochenski",	143.00448	]	,
["Bartoszewicz",	143.03949	]	,
["Cichecki",	143.07449	]	,
["Gadek",	143.10948	]	,
["Lukawski",	143.14443	]	,
["Januszkiewicz",	143.17934	]	,
["Chudzinski",	143.21424	]	,
["Skrzypiec",	143.2491	]	,
["Olek",	143.28393	]	,
["Kotowicz",	143.31875	]	,
["Kocur",	143.35351	]	,
["Szpakowski",	143.38825	]	,
["Urbanik",	143.42296	]	,
["Salata",	143.45763	]	,
["Koralewski",	143.49227	]	,
["Fiszer",	143.52687	]	,
["Beben",	143.56147	]	,
["Walasek",	143.59605	]	,
["Piwowar",	143.63062	]	,
["Lubinski",	143.66519	]	,
["Kulczycki",	143.69976	]	,
["Mackowski",	143.73431	]	,
["Cwikla",	143.76881	]	,
["Wojtkowski",	143.8033	]	,
["Golonka",	143.83779	]	,
["Zabielski",	143.87221	]	,
["Klimkiewicz",	143.9066	]	,
["Weglarz",	143.94098	]	,
["Górczynski",	143.97536	]	,
["Gawlowski",	144.00973	]	,
["Rzeznik",	144.04409	]	,
["Cichowski",	144.07844	]	,
["Kuczera",	144.11278	]	,
["Wolna",	144.14711	]	,
["Wojewoda",	144.18143	]	,
["Niziolek",	144.21572	]	,
["Janowicz",	144.24999	]	,
["Nikiel",	144.28425	]	,
["Slowikowski",	144.31848	]	,
["Gancarz",	144.35271	]	,
["Lisek",	144.3869	]	,
["Przybylek",	144.42104	]	,
["Gluchowski",	144.45513	]	,
["Domaradzki",	144.48922	]	,
["Rozwadowski",	144.5233	]	,
["Nedza",	144.55736	]	,
["Bajor",	144.59142	]	,
["Liszewski",	144.62546	]	,
["Cisek",	144.65947	]	,
["Siwak",	144.69338	]	,
["Kopka",	144.72729	]	,
["Czernik",	144.76117	]	,
["Glowacz",	144.79502	]	,
["Antosik",	144.82883	]	,
["Piaskowski",	144.86261	]	,
["Mateja",	144.89639	]	,
["Boguszewski",	144.93016	]	,
["Babiarz",	144.96393	]	,
["Binkowski",	144.99768	]	,
["Pieniazek",	145.03142	]	,
["Balinski",	145.06516	]	,
["Pokora",	145.09889	]	,
["Lada",	145.13262	]	,
["Rola",	145.16633	]	,
["Piróg",	145.20002	]	,
["Wojtysiak",	145.23369	]	,
["Soja",	145.26736	]	,
["Kedzia",	145.301	]	,
["Choinski",	145.33464	]	,
["Szmyt",	145.36827	]	,
["Drab",	145.40187	]	,
["Barczak",	145.43545	]	,
["Loboda",	145.46899	]	,
["Laski",	145.50253	]	,
["Slota",	145.53604	]	,
["Kupis",	145.56954	]	,
["Pajor",	145.60297	]	,
["Lacki",	145.6364	]	,
["Wysokinski",	145.66982	]	,
["Kacperski",	145.70324	]	,
["Kuriata",	145.73665	]	,
["Swoboda",	145.77004	]	,
["Terlecki",	145.80342	]	,
["Walicki",	145.83675	]	,
["Kaliszewski",	145.87007	]	,
["Szewc",	145.90337	]	,
["Matuszek",	145.93666	]	,
["Polec",	145.96994	]	,
["Gierczak",	146.00321	]	,
["Lukasiak",	146.03643	]	,
["Janecki",	146.06964	]	,
["Rakoczy",	146.10283	]	,
["Jarosinski",	146.13601	]	,
["Siwinski",	146.16916	]	,
["Wiatrowski",	146.2023	]	,
["Puzio",	146.23542	]	,
["Kusnierz",	146.26851	]	,
["Koc",	146.3016	]	,
["Wojnarowski",	146.33464	]	,
["Tobiasz",	146.36766	]	,
["Rogulski",	146.40068	]	,
["Kleszcz",	146.4337	]	,
["Boczek",	146.46671	]	,
["Jasiak",	146.49971	]	,
["Narloch",	146.53268	]	,
["Maka",	146.56562	]	,
["Dziubek",	146.59853	]	,
["Skuza",	146.63141	]	,
["Miernik",	146.66424	]	,
["Iskra",	146.69706	]	,
["Ozimek",	146.72987	]	,
["Baczkowski",	146.76267	]	,
["Bryk",	146.79545	]	,
["Kufel",	146.82822	]	,
["Gutkowski",	146.86099	]	,
["Czernecki",	146.8937	]	,
["Chlebowski",	146.92641	]	,
["Kierzkowski",	146.95908	]	,
["Plóciennik",	146.99173	]	,
["Nowosad",	147.02438	]	,
["Jurga",	147.05701	]	,
["Rozmus",	147.08963	]	,
["Kulaga",	147.12225	]	,
["Popiel",	147.15485	]	,
["Gierszewski",	147.18744	]	,
["Malachowski",	147.22002	]	,
["Ignasiak",	147.25259	]	,
["Wnek",	147.28515	]	,
["Plachta",	147.31771	]	,
["Kolek",	147.35027	]	,
["Marczyk",	147.38282	]	,
["Moszczynski",	147.41535	]	,
["Biedrzycki",	147.44788	]	,
["Sosna",	147.48038	]	,
["Polok",	147.51287	]	,
["Grabinski",	147.54533	]	,
["Pluciennik",	147.57775	]	,
["Klis",	147.61017	]	,
["Cieszynski",	147.64259	]	,
["Romanczuk",	147.67498	]	,
["Koch",	147.70737	]	,
["Bienias",	147.73972	]	,
["Kilian",	147.77204	]	,
["Szmit",	147.80435	]	,
["Stadnik",	147.83666	]	,
["Staron",	147.86896	]	,
["Patyk",	147.90125	]	,
["Labuda",	147.93354	]	,
["Czekalski",	147.96583	]	,
["Pniewski",	147.9981	]	,
["Zdanowski",	148.03036	]	,
["Wilkowski",	148.06262	]	,
["Sarnecki",	148.09488	]	,
["Sypniewski",	148.12713	]	,
["Fedorowicz",	148.15937	]	,
["Winkler",	148.1916	]	,
["Chmielecki",	148.22383	]	,
["Zaczek",	148.25604	]	,
["Kobiela",	148.28819	]	,
["Kasinski",	148.32032	]	,
["Salek",	148.35237	]	,
["Barszczewski",	148.38442	]	,
["Piechowski",	148.4164	]	,
["Orlinski",	148.44837	]	,
["Zochowski",	148.48032	]	,
["Warda",	148.51225	]	,
["Jurewicz",	148.54418	]	,
["Maciejczyk",	148.5761	]	,
["Czarnik",	148.60796	]	,
["Roguski",	148.6398	]	,
["Dragan",	148.67164	]	,
["Switalski",	148.70344	]	,
["Boniecki",	148.73524	]	,
["Kupczak",	148.76702	]	,
["Deja",	148.7988	]	,
["Pawelczyk",	148.83054	]	,
["Basinski",	148.86225	]	,
["Ciechanowski",	148.89395	]	,
["Kulka",	148.92564	]	,
["Ochman",	148.95731	]	,
["Kwapisz",	148.98898	]	,
["Budnik",	149.02064	]	,
["Sobiech",	149.05228	]	,
["Jakubik",	149.08392	]	,
["Rojewski",	149.11554	]	,
["Pankiewicz",	149.14711	]	,
["Kosmider",	149.17868	]	,
["Firlej",	149.21025	]	,
["Polkowski",	149.24181	]	,
["Wozna",	149.27335	]	,
["Jasik",	149.30488	]	,
["Bajer",	149.33641	]	,
["Stefanowski",	149.36793	]	,
["Krzemien",	149.39945	]	,
["Peplinski",	149.43096	]	,
["Skrzypczyk",	149.46246	]	,
["Mania",	149.49396	]	,
["Czekala",	149.52542	]	,
["Cisowski",	149.55688	]	,
["Stefanowicz",	149.58833	]	,
["Domin",	149.61978	]	,
["Bartosz",	149.65122	]	,
["Wicher",	149.68262	]	,
["Pawelek",	149.71402	]	,
["Meller",	149.74542	]	,
["Gaweda",	149.77682	]	,
["Orlik",	149.80819	]	,
["Suszek",	149.83953	]	,
["Polakowski",	149.87086	]	,
["Pietkiewicz",	149.90219	]	,
["Wawer",	149.9335	]	,
["Sajdak",	149.96481	]	,
["Hinc",	149.99612	]	,
["Boczkowski",	150.02743	]	,
["Mickiewicz",	150.05873	]	,
["Checinski",	150.09003	]	,
["Korycki",	150.12127	]	,
["Sas",	150.15249	]	,
["Babinski",	150.1837	]	,
["Mioduszewski",	150.21488	]	,
["Obara",	150.24603	]	,
["Gwizdala",	150.27717	]	,
["Swiecicki",	150.30828	]	,
["Ruta",	150.33935	]	,
["Dziubinski",	150.37038	]	,
["Ciosek",	150.4014	]	,
["Sadkowski",	150.4324	]	,
["Czopek",	150.46337	]	,
["Rodziewicz",	150.49433	]	,
["Lozinski",	150.52528	]	,
["Sak",	150.55621	]	,
["Czerski",	150.58713	]	,
["Golda",	150.61803	]	,
["Kukula",	150.64885	]	,
["Grabiec",	150.67967	]	,
["Kopiec",	150.71047	]	,
["Soltysik",	150.74122	]	,
["Klimczyk",	150.77194	]	,
["Kubas",	150.80265	]	,
["Nowik",	150.83335	]	,
["Napora",	150.86403	]	,
["Chrzaszcz",	150.89469	]	,
["Opalinski",	150.92533	]	,
["Jodlowski",	150.95596	]	,
["Czechowicz",	150.98658	]	,
["Jaworowski",	151.01719	]	,
["Okonski",	151.04778	]	,
["Kotecki",	151.07837	]	,
["Dmochowski",	151.10896	]	,
["Karbowiak",	151.13951	]	,
["Walaszczyk",	151.17005	]	,
["Gralak",	151.20052	]	,
["Dyrda",	151.23099	]	,
["Kiszka",	151.26145	]	,
["Tabaka",	151.29187	]	,
["Gondek",	151.32229	]	,
["Kozikowski",	151.3527	]	,
["Wachowicz",	151.3831	]	,
["Bebenek",	151.41348	]	,
["Zabinski",	151.44384	]	,
["Poreba",	151.4742	]	,
["Milek",	151.50455	]	,
["Sobiecki",	151.53487	]	,
["Musielak",	151.56518	]	,
["Pikula",	151.59548	]	,
["Tomaszek",	151.62577	]	,
["Szewczak",	151.65606	]	,
["Sasin",	151.68634	]	,
["Lenartowicz",	151.71661	]	,
["Cegielski",	151.74686	]	,
["Laski",	151.7771	]	,
["Skotnicki",	151.80732	]	,
["Rachwal",	151.83754	]	,
["Idziak",	151.86774	]	,
["Józefiak",	151.89793	]	,
["Bomba",	151.92811	]	,
["Swiniarski",	151.95828	]	,
["Moscicki",	151.98844	]	,
["Jaszczuk",	152.0186	]	,
["Fijolek",	152.04876	]	,
["Zawadka",	152.07888	]	,
["Osiak",	152.109	]	,
["Pliszka",	152.13909	]	,
["Janczewski",	152.16914	]	,
["Czarkowski",	152.19918	]	,
["Szymura",	152.22921	]	,
["Matysek",	152.25923	]	,
["Maciak",	152.28923	]	,
["Borawski",	152.31923	]	,
["Pietrucha",	152.34918	]	,
["Kielar",	152.37912	]	,
["Fryc",	152.40906	]	,
["Dmowski",	152.439	]	,
["Zydek",	152.46893	]	,
["Jarzyna",	152.49882	]	,
["Furmanek",	152.52871	]	,
["Krason",	152.55859	]	,
["Ziaja",	152.58846	]	,
["Gorzkowski",	152.61831	]	,
["Gawrys",	152.64816	]	,
["Gluszek",	152.678	]	,
["Nieweglowski",	152.70783	]	,
["Kownacki",	152.73766	]	,
["Szyszko",	152.76748	]	,
["Kalicki",	152.79729	]	,
["Mielewczyk",	152.82709	]	,
["Myslinski",	152.85687	]	,
["Deren",	152.88661	]	,
["Kozub",	152.91629	]	,
["Pietrasik",	152.94594	]	,
["Piecha",	152.97559	]	,
["Winiarczyk",	153.00522	]	,
["Klich",	153.03485	]	,
["Górnik",	153.06448	]	,
["Cybula",	153.09408	]	,
["Chowaniec",	153.12367	]	,
["Butkiewicz",	153.15326	]	,
["Tyszkiewicz",	153.1828	]	,
["Karwat",	153.21234	]	,
["Szymborski",	153.24187	]	,
["Sobala",	153.27138	]	,
["Poniatowski",	153.30089	]	,
["Bartoszewski",	153.3304	]	,
["Kasprzycki",	153.35989	]	,
["Danielewicz",	153.38938	]	,
["Skupien",	153.41886	]	,
["Koperski",	153.44834	]	,
["Kosecki",	153.47781	]	,
["Wasiluk",	153.50725	]	,
["Lewkowicz",	153.53668	]	,
["Drazek",	153.5661	]	,
["Rzepa",	153.59551	]	,
["Machaj",	153.6249	]	,
["Pietruszewski",	153.65428	]	,
["Madry",	153.68366	]	,
["Augustynowicz",	153.713	]	,
["Jagla",	153.7423	]	,
["Zdunczyk",	153.77158	]	,
["Marcinek",	153.80084	]	,
["Wlodarczak",	153.83008	]	,
["Kmieciak",	153.8593	]	,
["Ptasinski",	153.88851	]	,
["Fojcik",	153.91769	]	,
["Bedkowski",	153.94685	]	,
["Mruk",	153.976	]	,
["Stach",	154.00514	]	,
["Pacholski",	154.03428	]	,
["Pasieka",	154.06341	]	,
["Stawarz",	154.09251	]	,
["Wronka",	154.12159	]	,
["Lupa",	154.15067	]	,
["Glodek",	154.17975	]	,
["Cieplinski",	154.20883	]	,
["Stefanczyk",	154.23789	]	,
["Pozniak",	154.26695	]	,
["Pach",	154.29601	]	,
["Matkowski",	154.32507	]	,
["Miazga",	154.35412	]	,
["Krzywicki",	154.38317	]	,
["Ciupa",	154.41222	]	,
["Zajdel",	154.44126	]	,
["Strzyzewski",	154.47027	]	,
["Balcer",	154.49927	]	,
["Piwko",	154.52826	]	,
["Kwasny",	154.5572	]	,
["Borowik",	154.58614	]	,
["Biala",	154.61507	]	,
["Jablonowski",	154.64394	]	,
["Sienko",	154.67279	]	,
["Poloczek",	154.7016	]	,
["Kowalkowski",	154.73041	]	,
["Ciura",	154.75922	]	,
["Kwasnik",	154.78802	]	,
["Sadlowski",	154.81681	]	,
["Kosicki",	154.8456	]	,
["Skonieczna",	154.87438	]	,
["Borzecki",	154.90316	]	,
["Adamowski",	154.93193	]	,
["Piekarczyk",	154.96069	]	,
["Solarz",	154.98944	]	,
["Rostkowski",	155.01817	]	,
["Skoczek",	155.04686	]	,
["Gontarz",	155.07555	]	,
["Pudlo",	155.10418	]	,
["Lipowski",	155.13281	]	,
["Krzysztofik",	155.16144	]	,
["Jasek",	155.19007	]	,
["Moroz",	155.21869	]	,
["Mleczko",	155.24731	]	,
["Kusmierczyk",	155.27593	]	,
["Krzywda",	155.30455	]	,
["Gruszecki",	155.33316	]	,
["Daszkiewicz",	155.36175	]	,
["Stanik",	155.39032	]	,
["Sójka",	155.41889	]	,
["Blasiak",	155.44745	]	,
["Zóltowski",	155.47599	]	,
["Zwierzynski",	155.50453	]	,
["Zarzecki",	155.53306	]	,
["Kur",	155.56158	]	,
["Langner",	155.59006	]	,
["Orlikowski",	155.61853	]	,
["Klepacki",	155.64698	]	,
["Pakulski",	155.6754	]	,
["Chyla",	155.70379	]	,
["Arciszewski",	155.73214	]	,
["Maczynski",	155.76048	]	,
["Kosakowski",	155.78882	]	,
["Wojtas",	155.81714	]	,
["Kustra",	155.84545	]	,
["Wanat",	155.87373	]	,
["Gawin",	155.90199	]	,
["Przyborowski",	155.93024	]	,
["Kotwica",	155.95848	]	,
["Kocik",	155.98672	]	,
["Przytula",	156.01494	]	,
["Skarzynski",	156.04314	]	,
["Bryl",	156.0713	]	,
["Lesinski",	156.09944	]	,
["Hebda",	156.12758	]	,
["Lisiak",	156.15571	]	,
["Juraszek",	156.18382	]	,
["Lecki",	156.21192	]	,
["Gibas",	156.24002	]	,
["Zywicki",	156.26809	]	,
["Wilczak",	156.29615	]	,
["Fiedorowicz",	156.32421	]	,
["Wasik",	156.35226	]	,
["Tyszka",	156.38031	]	,
["Grzeszczak",	156.40836	]	,
["Chrzan",	156.43641	]	,
["Dorosz",	156.46445	]	,
["Starosta",	156.49248	]	,
["Spyra",	156.5205	]	,
["Rusiecki",	156.54851	]	,
["Piekut",	156.57652	]	,
["Konkol",	156.60453	]	,
["Fafara",	156.63252	]	,
["Pustelnik",	156.6605	]	,
["Stawiarski",	156.68847	]	,
["Samsel",	156.71643	]	,
["Bil",	156.74437	]	,
["Kijewski",	156.77229	]	,
["Kasza",	156.80021	]	,
["Tarasiuk",	156.82811	]	,
["Mokrzycki",	156.85601	]	,
["Czarny",	156.88388	]	,
["Dombrowski",	156.91174	]	,
["Lechowicz",	156.93959	]	,
["Migdal",	156.96742	]	,
["Durka",	156.99522	]	,
["Porada",	157.02301	]	,
["Momot",	157.0508	]	,
["Kosek",	157.07858	]	,
["Kedra",	157.10636	]	,
["Bykowski",	157.13414	]	,
["Dolecki",	157.16187	]	,
["Ciecierski",	157.18956	]	,
["Struzik",	157.21724	]	,
["Sobecki",	157.24491	]	,
["Suwala",	157.27257	]	,
["Perz",	157.30023	]	,
["Mielniczuk",	157.32789	]	,
["Giemza",	157.35555	]	,
["Pawluk",	157.3832	]	,
["Batko",	157.41085	]	,
["Zawierucha",	157.43848	]	,
["Suder",	157.46608	]	,
["Gronowski",	157.49366	]	,
["Gluch",	157.52124	]	,
["Grajewski",	157.5488	]	,
["Klak",	157.57635	]	,
["Bala",	157.6039	]	,
["Michalec",	157.63142	]	,
["Bartkiewicz",	157.65893	]	,
["Szablewski",	157.68641	]	,
["Regulski",	157.71388	]	,
["Olejarz",	157.74134	]	,
["Krupski",	157.76879	]	,
["Przybylo",	157.79623	]	,
["Krynicki",	157.82367	]	,
["Malkiewicz",	157.8511	]	,
["Prusak",	157.87851	]	,
["Galant",	157.90592	]	,
["Grala",	157.93331	]	,
["Duch",	157.9607	]	,
["Kaczkowski",	157.98808	]	,
["Prokopowicz",	158.01545	]	,
["Korcz",	158.0428	]	,
["Mnich",	158.07014	]	,
["Kacprzyk",	158.09748	]	,
["Wegner",	158.12479	]	,
["Klocek",	158.1521	]	,
["Kaczmarzyk",	158.17938	]	,
["Antonowicz",	158.20665	]	,
["Starzyk",	158.23391	]	,
["Majchrowski",	158.26117	]	,
["Tyczynski",	158.28842	]	,
["Pawlus",	158.31567	]	,
["Olkowski",	158.34292	]	,
["Malczyk",	158.37017	]	,
["Strózyk",	158.39738	]	,
["Waszczuk",	158.42458	]	,
["Malinski",	158.45178	]	,
["Brzóski",	158.47897	]	,
["Gebala",	158.50614	]	,
["Wala",	158.53328	]	,
["Romanowicz",	158.56041	]	,
["Sakowicz",	158.58753	]	,
["Pietraszek",	158.61463	]	,
["Dobek",	158.64173	]	,
["Wojnar",	158.66882	]	,
["Szczecinski",	158.69591	]	,
["Maksymiuk",	158.72299	]	,
["Wiktor",	158.75004	]	,
["Skwara",	158.77707	]	,
["Mrówka",	158.80408	]	,
["Lubanski",	158.83108	]	,
["Dutka",	158.85808	]	,
["Brzostek",	158.88507	]	,
["Piecuch",	158.91201	]	,
["Jarzynski",	158.93894	]	,
["Ryszka",	158.96585	]	,
["Nogaj",	158.99275	]	,
["Bialowas",	159.01965	]	,
["Koltun",	159.04654	]	,
["Magdziarz",	159.07342	]	,
["Radziejewski",	159.10029	]	,
["Kuznik",	159.12716	]	,
["Kadziela",	159.15403	]	,
["Niewinski",	159.18089	]	,
["Kolinski",	159.20774	]	,
["Juszkiewicz",	159.23457	]	,
["Falkiewicz",	159.2614	]	,
["Debek",	159.28823	]	,
["Kusmierz",	159.31505	]	,
["Lukaszuk",	159.34186	]	,
["Wojdyla",	159.36866	]	,
["Siekierski",	159.39546	]	,
["Górnicki",	159.42226	]	,
["Marczynski",	159.44902	]	,
["Kotas",	159.47578	]	,
["Dziadosz",	159.50254	]	,
["Grzegorek",	159.52929	]	,
["Biskupski",	159.55603	]	,
["Semeniuk",	159.58275	]	,
["Zimna",	159.60946	]	,
["Staskiewicz",	159.63616	]	,
["Piela",	159.66286	]	,
["Bolek",	159.68955	]	,
["Pióro",	159.71622	]	,
["Galus",	159.74287	]	,
["Milosz",	159.76951	]	,
["Kabat",	159.79615	]	,
["Palka",	159.82278	]	,
["Fic",	159.84941	]	,
["Paluszkiewicz",	159.87602	]	,
["Chodorowski",	159.90262	]	,
["Krynski",	159.92921	]	,
["Rajewski",	159.95578	]	,
["Parzych",	159.98234	]	,
["Jedlinski",	160.0089	]	,
["Filipkowski",	160.03546	]	,
["Libera",	160.062	]	,
["Foltyn",	160.08853	]	,
["Sobol",	160.11505	]	,
["Szydlo",	160.14155	]	,
["Brozyna",	160.16804	]	,
["Kocot",	160.1945	]	,
["Brzoza",	160.22095	]	,
["Pawelczyk",	160.24738	]	,
["Jedrzejak",	160.27381	]	,
["Burak",	160.30024	]	,
["Paduch",	160.32666	]	,
["Derda",	160.35307	]	,
["Miszczuk",	160.37947	]	,
["Kalemba",	160.40587	]	,
["Ginter",	160.43227	]	,
["Grad",	160.45866	]	,
["Slomski",	160.48504	]	,
["Domzalski",	160.51142	]	,
["Chrostowski",	160.53779	]	,
["Sielski",	160.56415	]	,
["Kociolek",	160.59051	]	,
["Jelinski",	160.61687	]	,
["Dzwonkowski",	160.64323	]	,
["Ziólek",	160.66958	]	,
["Lipa",	160.69593	]	,
["Kaim",	160.72228	]	,
["Konik",	160.74862	]	,
["Pabian",	160.77493	]	,
["Zaba",	160.80121	]	,
["Wojtkiewicz",	160.82745	]	,
["Kubinski",	160.85369	]	,
["Kurpiewski",	160.87992	]	,
["Stachyra",	160.90614	]	,
["Plaza",	160.93236	]	,
["Gebski",	160.95858	]	,
["Gebka",	160.9848	]	,
["Mrówczynski",	161.01099	]	,
["Kukielka",	161.03718	]	,
["Budny",	161.06337	]	,
["Baryla",	161.08955	]	,
["Kupka",	161.11572	]	,
["Kapala",	161.14188	]	,
["Ceglarek",	161.16804	]	,
["Grobelny",	161.19419	]	,
["Gapinski",	161.22034	]	,
["Watroba",	161.24648	]	,
["Walczuk",	161.27262	]	,
["Szalkowski",	161.29876	]	,
["Kuczkowski",	161.32489	]	,
["Jura",	161.35102	]	,
["Drzymala",	161.37715	]	,
["Krysinski",	161.40327	]	,
["Jaroszewicz",	161.42939	]	,
["Konieczka",	161.45547	]	,
["Tkocz",	161.48154	]	,
["Pomorski",	161.50755	]	,
["Karasiewicz",	161.53355	]	,
["Swiderek",	161.55953	]	,
["Zaniewski",	161.5855	]	,
["Buczak",	161.61147	]	,
["Palacz",	161.63741	]	,
["Danielak",	161.66334	]	,
["Niziol",	161.68926	]	,
["Dziadek",	161.71518	]	,
["Chmielowiec",	161.74109	]	,
["Nadolna",	161.76699	]	,
["Krysztofiak",	161.79288	]	,
["Wolek",	161.81876	]	,
["Makowiecki",	161.84464	]	,
["Figurski",	161.87052	]	,
["Gaca",	161.89639	]	,
["Palasz",	161.92222	]	,
["Idzik",	161.94804	]	,
["Zabek",	161.97385	]	,
["Pogoda",	161.99965	]	,
["Golawski",	162.02543	]	,
["Bloch",	162.05121	]	,
["Nejman",	162.07698	]	,
["Pytlik",	162.10274	]	,
["Zygadlo",	162.12847	]	,
["Stolarek",	162.1542	]	,
["Zurowski",	162.17992	]	,
["Jaszczak",	162.20564	]	,
["Hoffman",	162.23134	]	,
["Galek",	162.25701	]	,
["Michniewicz",	162.28266	]	,
["Rydz",	162.3083	]	,
["Golik",	162.33393	]	,
["Barnas",	162.35955	]	,
["Jopek",	162.38516	]	,
["Woch",	162.41075	]	,
["Swistak",	162.43634	]	,
["Szmigiel",	162.4619	]	,
["Barski",	162.48745	]	,
["Targosz",	162.51299	]	,
["Rafalski",	162.53851	]	,
["Sloma",	162.56402	]	,
["Czarniecki",	162.58953	]	,
["Lojek",	162.61503	]	,
["Krok",	162.64051	]	,
["Frontczak",	162.66599	]	,
["Moczulski",	162.69143	]	,
["Lagowski",	162.71687	]	,
["Stasik",	162.74229	]	,
["Matuszczyk",	162.7677	]	,
["Ciborowski",	162.79311	]	,
["Sadlo",	162.81851	]	,
["Paszko",	162.84391	]	,
["Kulczynski",	162.8693	]	,
["Dankowski",	162.89469	]	,
["Sieczka",	162.92007	]	,
["Hawryluk",	162.94545	]	,
["Rusak",	162.97082	]	,
["Krawczak",	162.99617	]	,
["Gradzki",	163.02152	]	,
["Dabrowa",	163.04687	]	,
["Wojtal",	163.07219	]	,
["Rychter",	163.0975	]	,
["Tarczynski",	163.12279	]	,
["Kaszubowski",	163.14808	]	,
["Galkowski",	163.17337	]	,
["Gniadek",	163.19865	]	,
["Stefaniuk",	163.22392	]	,
["Kluczynski",	163.24919	]	,
["Gozdzik",	163.27446	]	,
["Szczech",	163.29972	]	,
["Bujnowski",	163.32498	]	,
["Obrebski",	163.35023	]	,
["Dziewulski",	163.37547	]	,
["Gromadzki",	163.4007	]	,
["Siek",	163.42591	]	,
["Podolak",	163.45109	]	,
["Plotka",	163.47627	]	,
["Slupski",	163.50143	]	,
["Palczynski",	163.52658	]	,
["Sabat",	163.5517	]	,
["Marchlewski",	163.57682	]	,
["Wadolowski",	163.60191	]	,
["Jarzab",	163.627	]	,
["Grzeda",	163.65209	]	,
["Dera",	163.67718	]	,
["Konarzewski",	163.70225	]	,
["Wierzba",	163.72731	]	,
["Gruba",	163.75235	]	,
["Kret",	163.77737	]	,
["Czarnocki",	163.80238	]	,
["Lakomy",	163.82737	]	,
["Wojtala",	163.85234	]	,
["Wencel",	163.87731	]	,
["Skrzyniarz",	163.90225	]	,
["Dziekan",	163.92718	]	,
["Kaczorek",	163.95208	]	,
["Wolf",	163.97696	]	,
["Dudka",	164.00184	]	,
["Zamojski",	164.02671	]	,
["Stepinski",	164.05157	]	,
["Gózdz",	164.07643	]	,
["Wolowiec",	164.10127	]	,
["Szymkiewicz",	164.12609	]	,
["Kozuch",	164.1509	]	,
["Szreder",	164.1757	]	,
["Ignatowicz",	164.20046	]	,
["Rogacki",	164.22521	]	,
["Dembowski",	164.24996	]	,
["Faber",	164.2747	]	,
["Kempinski",	164.29943	]	,
["Siwik",	164.32415	]	,
["Michta",	164.34887	]	,
["Brzezicki",	164.37359	]	,
["Kijak",	164.39828	]	,
["Goldyn",	164.42297	]	,
["Wolanin",	164.44763	]	,
["Machura",	164.47228	]	,
["Tyburski",	164.49691	]	,
["Sulima",	164.52154	]	,
["Michalczuk",	164.54617	]	,
["Curylo",	164.5708	]	,
["Bartos",	164.59543	]	,
["Okrój",	164.62003	]	,
["Labedzki",	164.64462	]	,
["Baczyk",	164.66921	]	,
["Langer",	164.69379	]	,
["Zaluski",	164.71834	]	,
["Talaga",	164.74289	]	,
["Lukaszczyk",	164.76744	]	,
["Ochocki",	164.79197	]	,
["Dlugolecki",	164.8165	]	,
["Niewiarowski",	164.84102	]	,
["Izydorczyk",	164.86552	]	,
["Sulej",	164.89001	]	,
["Nawrat",	164.91448	]	,
["Blicharz",	164.93894	]	,
["Sukiennik",	164.96339	]	,
["Pszczólkowski",	164.98784	]	,
["Furmanski",	165.01229	]	,
["Blaszkowski",	165.03671	]	,
["Dura",	165.0611	]	,
["Walter",	165.08548	]	,
["Dyl",	165.10984	]	,
["Kurzynski",	165.13417	]	,
["Przywara",	165.15849	]	,
["Ligeza",	165.18281	]	,
["Brzózka",	165.20713	]	,
["Mlynski",	165.23143	]	,
["Michna",	165.25573	]	,
["Kochan",	165.28003	]	,
["Chabowski",	165.30433	]	,
["Jagiela",	165.32862	]	,
["Janeczko",	165.35288	]	,
["Rajski",	165.37712	]	,
["Niewczas",	165.40135	]	,
["Ilnicki",	165.42557	]	,
["Pastuszak",	165.44976	]	,
["Rosól",	165.47392	]	,
["Tecza",	165.49807	]	,
["Rawski",	165.5222	]	,
["Maslak",	165.54632	]	,
["Antoniuk",	165.57044	]	,
["Pruszynski",	165.59455	]	,
["Garstka",	165.61866	]	,
["Walewski",	165.64276	]	,
["Skórski",	165.66686	]	,
["Dworakowski",	165.69092	]	,
["Kuzniar",	165.71496	]	,
["Foks",	165.739	]	,
["Nicpon",	165.76303	]	,
["Jarek",	165.78706	]	,
["Antoniewicz",	165.81109	]	,
["Kmita",	165.83511	]	,
["Jaroszek",	165.85913	]	,
["Kropidlowski",	165.88314	]	,
["Byczkowski",	165.90715	]	,
["Gosciniak",	165.93115	]	,
["Bura",	165.95515	]	,
["Zieminski",	165.97913	]	,
["Dobkowski",	166.00311	]	,
["Rygielski",	166.02707	]	,
["Milkowski",	166.05101	]	,
["Dybala",	166.07495	]	,
["Pilecki",	166.09888	]	,
["Przybylowski",	166.1228	]	,
["Kiedrowski",	166.14672	]	,
["Niedzwiedzki",	166.17054	]	,
["Szczepkowski",	166.19435	]	,
["Karbownik",	166.21815	]	,
["Sawa",	166.24194	]	,
["Cedro",	166.26572	]	,
["Boruta",	166.28949	]	,
["Zakowski",	166.31324	]	,
["Zacharski",	166.33698	]	,
["Jazdzewski",	166.36072	]	,
["Cegielka",	166.38446	]	,
["Latos",	166.40818	]	,
["Blazejczyk",	166.43189	]	,
["Debiec",	166.45559	]	,
["Bigos",	166.47929	]	,
["Kadziolka",	166.50298	]	,
["Zaborski",	166.52665	]	,
["Garczynski",	166.55031	]	,
["Szerszen",	166.57395	]	,
["Jaworek",	166.59758	]	,
["Danowski",	166.6212	]	,
["Sitkowski",	166.64478	]	,
["Pieczynski",	166.66836	]	,
["Marszal",	166.69193	]	,
["Sierakowski",	166.71549	]	,
["Grobelna",	166.73903	]	,
["Skawinski",	166.76256	]	,
["Kubat",	166.78609	]	,
["Baczewski",	166.80962	]	,
["Opalka",	166.83313	]	,
["Kwietniewski",	166.85664	]	,
["Kulak",	166.88013	]	,
["Dudzic",	166.90362	]	,
["Glebocki",	166.92709	]	,
["Styczen",	166.95055	]	,
["Pacyna",	166.97401	]	,
["Jarzebski",	166.99747	]	,
["Matecki",	167.02091	]	,
["Hoppe",	167.04435	]	,
["Sidorowicz",	167.06778	]	,
["Berlinski",	167.09121	]	,
["Jakubek",	167.11463	]	,
["Demski",	167.13805	]	,
["Szablowski",	167.16146	]	,
["Klimkowski",	167.18487	]	,
["Owczarzak",	167.20827	]	,
["Franczyk",	167.23167	]	,
["Wieczorkowski",	167.25506	]	,
["Staniek",	167.27845	]	,
["Swierczewski",	167.30183	]	,
["Przygodzki",	167.32521	]	,
["Wrzeszcz",	167.34857	]	,
["Cierniak",	167.37191	]	,
["Parol",	167.39523	]	,
["Malolepszy",	167.41855	]	,
["Bakalarz",	167.44187	]	,
["Rynkowski",	167.46516	]	,
["Wenta",	167.48844	]	,
["Smolarczyk",	167.51172	]	,
["Skorek",	167.535	]	,
["Richter",	167.55828	]	,
["Mirecki",	167.58154	]	,
["Stasinski",	167.60479	]	,
["Andrzejczyk",	167.62804	]	,
["Olszówka",	167.65128	]	,
["Sarzynski",	167.6745	]	,
["Gos",	167.69771	]	,
["Rózalski",	167.7209	]	,
["Szyszkowski",	167.74407	]	,
["Szczepanowski",	167.76724	]	,
["Lojewski",	167.79041	]	,
["Jamrozik",	167.81358	]	,
["Wiktorowicz",	167.83674	]	,
["Wieliczko",	167.8599	]	,
["Wozniczka",	167.88303	]	,
["Górak",	167.90616	]	,
["Rekowski",	167.92928	]	,
["Mrozek",	167.9524	]	,
["Kantor",	167.97552	]	,
["Kwidzinski",	167.99862	]	,
["Gilewski",	168.02172	]	,
["Bucki",	168.04482	]	,
["Blach",	168.06792	]	,
["Brodziak",	168.09101	]	,
["Zietara",	168.11408	]	,
["Toczek",	168.13715	]	,
["Sokalski",	168.16022	]	,
["Kardas",	168.18329	]	,
["Wilkosz",	168.20631	]	,
["Kubera",	168.22933	]	,
["Kozlik",	168.25233	]	,
["Nitka",	168.27532	]	,
["Trzaskowski",	168.2983	]	,
["Karczmarczyk",	168.32127	]	,
["Gren",	168.34424	]	,
["Smolak",	168.36719	]	,
["Gradowski",	168.39014	]	,
["Sitarski",	168.41307	]	,
["Klepacz",	168.436	]	,
["Antos",	168.45893	]	,
["Kobialka",	168.48185	]	,
["Bajorek",	168.50475	]	,
["Migas",	168.52764	]	,
["Kowol",	168.55053	]	,
["Fraszczak",	168.57341	]	,
["Zylka",	168.59628	]	,
["Wajs",	168.61915	]	,
["Smaga",	168.642	]	,
["Zapart",	168.66484	]	,
["Kosmowski",	168.68768	]	,
["Wojcieszak",	168.71051	]	,
["Malesa",	168.73334	]	,
["Kumor",	168.75617	]	,
["Kryczka",	168.779	]	,
["Solinski",	168.80182	]	,
["Sobieski",	168.82463	]	,
["Karolczak",	168.84744	]	,
["Jaron",	168.87025	]	,
["Brzostowski",	168.89306	]	,
["Nawara",	168.91586	]	,
["Kuznicki",	168.93866	]	,
["Rutka",	168.96144	]	,
["Greda",	168.98422	]	,
["Turkiewicz",	169.00699	]	,
["Fuchs",	169.02974	]	,
["Grodzicki",	169.05248	]	,
["Waliszewski",	169.07521	]	,
["Pawelski",	169.09794	]	,
["Kardasz",	169.12066	]	,
["Kotula",	169.14337	]	,
["Ciok",	169.16607	]	,
["Kotlowski",	169.18876	]	,
["Jakubowicz",	169.21144	]	,
["Bonk",	169.23411	]	,
["Wieclaw",	169.25677	]	,
["Soszynski",	169.27943	]	,
["Leja",	169.30209	]	,
["Kubis",	169.32475	]	,
["Tomaszewicz",	169.34739	]	,
["Matys",	169.37002	]	,
["Kotlinski",	169.39265	]	,
["Bach",	169.41528	]	,
["Babicz",	169.43788	]	,
["Snopek",	169.46047	]	,
["Stawinski",	169.48304	]	,
["Wszolek",	169.50559	]	,
["Lucki",	169.52813	]	,
["Grzanka",	169.55066	]	,
["Brodzinski",	169.57319	]	,
["Miotk",	169.59571	]	,
["Zysk",	169.61822	]	,
["Styczynski",	169.64073	]	,
["Kossowski",	169.66324	]	,
["Staniak",	169.68574	]	,
["Mizerski",	169.70823	]	,
["Lebioda",	169.73071	]	,
["Brys",	169.75319	]	,
["Sulewski",	169.77564	]	,
["Cebulski",	169.79807	]	,
["Rebisz",	169.82049	]	,
["Pudelko",	169.8429	]	,
["Chmielowski",	169.86531	]	,
["Potrykus",	169.88769	]	,
["Dwornik",	169.91007	]	,
["Pytlak",	169.93244	]	,
["Bojko",	169.95481	]	,
["Maciejak",	169.97717	]	,
["Gacki",	169.99953	]	,
["Klosek",	170.02188	]	,
["Ptaszek",	170.04422	]	,
["Kurdziel",	170.06656	]	,
["Krawczuk",	170.0889	]	,
["Walaszek",	170.11123	]	,
["Radwan",	170.13356	]	,
["Lupinski",	170.15588	]	,
["Klys",	170.1782	]	,
["Mackiewicz",	170.2005	]	,
["Dutkowski",	170.2228	]	,
["Adach",	170.2451	]	,
["Szymoniak",	170.26739	]	,
["Schulz",	170.28966	]	,
["Gruchala",	170.31193	]	,
["Bujalski",	170.3342	]	,
["Rek",	170.35646	]	,
["Musialik",	170.37872	]	,
["Cendrowski",	170.40098	]	,
["Madeja",	170.42323	]	,
["Pazdzior",	170.44547	]	,
["Furmaniak",	170.46771	]	,
["Budzik",	170.48994	]	,
["Langowski",	170.51215	]	,
["Balcerek",	170.53435	]	,
["Brzyski",	170.55654	]	,
["Bazan",	170.57872	]	,
["Strzalka",	170.60089	]	,
["Lopata",	170.62306	]	,
["Szlachetka",	170.64522	]	,
["Kaplon",	170.66738	]	,
["Swietek",	170.68953	]	,
["Polit",	170.71167	]	,
["Zabicki",	170.7338	]	,
["Jadczak",	170.75593	]	,
["Uminski",	170.77805	]	,
["Sulikowski",	170.80017	]	,
["Plata",	170.82229	]	,
["Konior",	170.84441	]	,
["Malolepsza",	170.86652	]	,
["Luszczynski",	170.88862	]	,
["Pala",	170.91071	]	,
["Weiss",	170.93279	]	,
["Kurkiewicz",	170.95484	]	,
["Grzeszczyk",	170.97688	]	,
["Wiech",	170.99889	]	,
["Mazurowski",	171.02089	]	,
["Galczynski",	171.04289	]	,
["Wozniakowski",	171.06488	]	,
["Kasperczyk",	171.08687	]	,
["Duleba",	171.10886	]	,
["Drapala",	171.13085	]	,
["Mierzwinski",	171.1528	]	,
["Pulawski",	171.17473	]	,
["Karolewski",	171.19666	]	,
["Mazgaj",	171.21858	]	,
["Urbanowski",	171.24048	]	,
["Pijanowski",	171.26238	]	,
["Wiewióra",	171.28427	]	,
["Kopanski",	171.30616	]	,
["Kulak",	171.32804	]	,
["Goszczynski",	171.34992	]	,
["Fiuk",	171.3718	]	,
["Danielewski",	171.39367	]	,
["Starzec",	171.41553	]	,
["Rokosz",	171.43739	]	,
["Zelazny",	171.45924	]	,
["Krzyzak",	171.48109	]	,
["Kolano",	171.50294	]	,
["Jalocha",	171.52479	]	,
["Guzy",	171.54664	]	,
["Piec",	171.56846	]	,
["Smietana",	171.59027	]	,
["Cichonski",	171.61203	]	,
["Oczkowski",	171.63378	]	,
["Miedzinski",	171.65553	]	,
["Blaszak",	171.67727	]	,
["Skoczen",	171.699	]	,
["Kuc",	171.72072	]	,
["Garus",	171.74241	]	,
["Skórka",	171.76408	]	,
["Pawluczuk",	171.78575	]	,
["Bartyzel",	171.80742	]	,
["Talarek",	171.82908	]	,
["Pytka",	171.85074	]	,
["Petrykowski",	171.8724	]	,
["Siewierski",	171.89404	]	,
["Kolenda",	171.91567	]	,
["Gaik",	171.93729	]	,
["Najda",	171.9589	]	,
["Dunaj",	171.9805	]	,
["Grzywna",	172.00209	]	,
["Fialkowski",	172.02368	]	,
["Chuda",	172.04527	]	,
["Tchórzewski",	172.06685	]	,
["Stachera",	172.08843	]	,
["Janota",	172.11	]	,
["Budek",	172.13157	]	,
["Gonera",	172.15313	]	,
["Czerwiec",	172.17469	]	,
["Muzyka",	172.19624	]	,
["Dolega",	172.21778	]	,
["Zelek",	172.23931	]	,
["Wasniewski",	172.26084	]	,
["Kaczanowski",	172.28237	]	,
["Salwa",	172.30389	]	,
["Basiak",	172.32541	]	,
["Choluj",	172.34692	]	,
["Gackowski",	172.36842	]	,
["Tarnawski",	172.38991	]	,
["Pyzik",	172.4114	]	,
["Ruszkiewicz",	172.43286	]	,
["Wyka",	172.45431	]	,
["Dembski",	172.47576	]	,
["Stepkowski",	172.49718	]	,
["Lenkiewicz",	172.5186	]	,
["Iwanek",	172.54001	]	,
["Filipczyk",	172.56142	]	,
["Wiercinski",	172.58282	]	,
["Cielecki",	172.60422	]	,
["Stolarz",	172.62561	]	,
["Plonski",	172.64699	]	,
["Surmacz",	172.66836	]	,
["Piechaczek",	172.68973	]	,
["Pasik",	172.7111	]	,
["Buchta",	172.73247	]	,
["Szczerbinski",	172.75381	]	,
["Kurczewski",	172.77515	]	,
["Landowski",	172.79648	]	,
["Klecha",	172.81781	]	,
["Burczyk",	172.83911	]	,
["Strugala",	172.8604	]	,
["Staszkiewicz",	172.88169	]	,
["Palinski",	172.90297	]	,
["Korus",	172.92425	]	,
["Koczorowski",	172.94553	]	,
["Krystek",	172.9668	]	,
["Miroslaw",	172.98806	]	,
["Karp",	173.00931	]	,
["Gorczynski",	173.03056	]	,
["Szala",	173.0518	]	,
["Matysik",	173.07304	]	,
["Grzeszczuk",	173.09428	]	,
["Niedzialek",	173.1155	]	,
["Nocun",	173.1367	]	,
["Prokopiuk",	173.15789	]	,
["Godek",	173.17908	]	,
["Raszewski",	173.20026	]	,
["Krysa",	173.22144	]	,
["Pieczonka",	173.24261	]	,
["Keski",	173.26378	]	,
["Juchniewicz",	173.28495	]	,
["Ciach",	173.30611	]	,
["Palczewski",	173.32726	]	,
["Jaskowiak",	173.34841	]	,
["Dylag",	173.36956	]	,
["Antosz",	173.3907	]	,
["Wieczorkiewicz",	173.41183	]	,
["Malina",	173.43295	]	,
["Barczynski",	173.45407	]	,
["Stelmaszczyk",	173.47518	]	,
["Maksymowicz",	173.49629	]	,
["Konkel",	173.51739	]	,
["Keller",	173.53848	]	,
["Owczarczyk",	173.55956	]	,
["Mikos",	173.58062	]	,
["Sieczkowski",	173.60167	]	,
["Bis",	173.62272	]	,
["Pisula",	173.64375	]	,
["Drozdek",	173.66478	]	,
["Smialek",	173.68579	]	,
["Lakoma",	173.7068	]	,
["Ziobro",	173.7278	]	,
["Serwatka",	173.7488	]	,
["Jagoda",	173.7698	]	,
["Dubicki",	173.7908	]	,
["Grajek",	173.81178	]	,
["Rosik",	173.83275	]	,
["Grochal",	173.85372	]	,
["Polinski",	173.87467	]	,
["Ryczek",	173.8956	]	,
["Paterek",	173.91652	]	,
["Guminski",	173.93743	]	,
["Bizon",	173.95834	]	,
["Wieteski",	173.97924	]	,
["Gala",	174.00014	]	,
["Kondraciuk",	174.02103	]	,
["Maciolek",	174.04191	]	,
["Nagórski",	174.06277	]	,
["Kondratowicz",	174.08363	]	,
["Mikrut",	174.10448	]	,
["Wodzynski",	174.12532	]	,
["Mendel",	174.14615	]	,
["Masternak",	174.16698	]	,
["Rychlewski",	174.18779	]	,
["Michon",	174.2086	]	,
["Kocon",	174.22941	]	,
["Luniewski",	174.2502	]	,
["Kochanek",	174.27099	]	,
["Bieda",	174.29177	]	,
["Szustak",	174.31253	]	,
["Rajkowski",	174.33328	]	,
["Koziarski",	174.35402	]	,
["Kruczkowski",	174.37475	]	,
["Kosik",	174.39548	]	,
["Klim",	174.41621	]	,
["Wodzinski",	174.43693	]	,
["Otto",	174.45765	]	,
["Cieslewicz",	174.47837	]	,
["Turzynski",	174.49908	]	,
["Palys",	174.51979	]	,
["Mazurczak",	174.5405	]	,
["Opala",	174.56119	]	,
["Filipiuk",	174.58186	]	,
["Cwalina",	174.60251	]	,
["Celmer",	174.62316	]	,
["Nita",	174.6438	]	,
["Klusek",	174.66444	]	,
["Duchnowski",	174.68508	]	,
["Baraniak",	174.70572	]	,
["Szydlik",	174.72634	]	,
["Chalupka",	174.74696	]	,
["Kordek",	174.76756	]	,
["Wesolek",	174.78814	]	,
["Krasucki",	174.80871	]	,
["Hyla",	174.82927	]	,
["Rajca",	174.84981	]	,
["Kraus",	174.87035	]	,
["Czuprynski",	174.89089	]	,
["Pyrek",	174.91142	]	,
["Kubis",	174.93195	]	,
["Lehmann",	174.95245	]	,
["Maruszak",	174.97294	]	,
["Formella",	174.99341	]	,
["Wrzesien",	175.01386	]	,
["Felczak",	175.03431	]	,
["Stys",	175.05474	]	,
["Konopko",	175.07517	]	,
["Wadowski",	175.09559	]	,
["Martyniuk",	175.11601	]	,
["Gugala",	175.13643	]	,
["Lazarski",	175.15684	]	,
["Borowczyk",	175.17725	]	,
["Wolniewicz",	175.19764	]	,
["Ruszczyk",	175.21802	]	,
["Wasko",	175.23839	]	,
["Szuster",	175.25876	]	,
["Podbielski",	175.27913	]	,
["Paczek",	175.2995	]	,
["Litwinski",	175.31985	]	,
["Zylinski",	175.34018	]	,
["Luty",	175.36051	]	,
["Cuber",	175.38083	]	,
["Pietrowski",	175.40114	]	,
["Konefal",	175.42145	]	,
["Lizak",	175.44175	]	,
["Glowinski",	175.46204	]	,
["Marecki",	175.48232	]	,
["Podlewski",	175.50259	]	,
["Kobylarz",	175.52286	]	,
["Szklarski",	175.54312	]	,
["Kura",	175.56336	]	,
["Dyba",	175.58359	]	,
["Syski",	175.60381	]	,
["Fronczak",	175.62403	]	,
["Blicharski",	175.64423	]	,
["Oledzki",	175.66442	]	,
["Kluba",	175.68461	]	,
["Gach",	175.7048	]	,
["Piontek",	175.72498	]	,
["Garbowski",	175.74516	]	,
["Chomicz",	175.76533	]	,
["Madra",	175.78549	]	,
["Pisarczyk",	175.80564	]	,
["Baranek",	175.82579	]	,
["Pekala",	175.84593	]	,
["Kielak",	175.86607	]	,
["Glaz",	175.8862	]	,
["Gawor",	175.90633	]	,
["Legowski",	175.92645	]	,
["Wichrowski",	175.94656	]	,
["Slusarski",	175.96667	]	,
["Soroka",	175.98678	]	,
["Kielczewski",	176.00688	]	,
["Danek",	176.02698	]	,
["Bernas",	176.04707	]	,
["Slotwinski",	176.06715	]	,
["Wojcieszek",	176.08722	]	,
["Iwinski",	176.10729	]	,
["Boruch",	176.12736	]	,
["Pruski",	176.14742	]	,
["Malysz",	176.16748	]	,
["Kucia",	176.18754	]	,
["Klima",	176.2076	]	,
["Slusarz",	176.22765	]	,
["Papiernik",	176.2477	]	,
["Szymankiewicz",	176.26774	]	,
["Molski",	176.28777	]	,
["Iwaniec",	176.3078	]	,
["Krasnodebski",	176.32782	]	,
["Gryz",	176.34784	]	,
["Sobik",	176.36784	]	,
["Hernik",	176.38784	]	,
["Napieralski",	176.40781	]	,
["Magdziak",	176.42778	]	,
["Radzik",	176.44774	]	,
["Bulinski",	176.46769	]	,
["Siemiatkowski",	176.48763	]	,
["Gala",	176.50756	]	,
["Pszczola",	176.52748	]	,
["Maruszewski",	176.5474	]	,
["Kocjan",	176.56732	]	,
["Fudali",	176.58724	]	,
["Zarychta",	176.60715	]	,
["Treder",	176.62705	]	,
["Mlynek",	176.64695	]	,
["Mrzyglód",	176.66684	]	,
["Kramarz",	176.68673	]	,
["Wójcikowski",	176.70661	]	,
["Slonina",	176.72648	]	,
["Rychlicki",	176.74635	]	,
["Barwinski",	176.76622	]	,
["Tatara",	176.78608	]	,
["Gliszczynski",	176.80594	]	,
["Galicki",	176.8258	]	,
["Paul",	176.84565	]	,
["Bobowski",	176.8655	]	,
["Suliga",	176.88534	]	,
["Demczuk",	176.90518	]	,
["Tyc",	176.92501	]	,
["Ociepa",	176.94484	]	,
["Kosmalski",	176.96467	]	,
["Szukalski",	176.98448	]	,
["Szczypinski",	177.00429	]	,
["Gurgul",	177.02409	]	,
["Cwynar",	177.04389	]	,
["Szaniawski",	177.06368	]	,
["Rykowski",	177.08347	]	,
["Dembek",	177.10326	]	,
["Szczepankiewicz",	177.12304	]	,
["Krupka",	177.14281	]	,
["Miszewski",	177.16256	]	,
["Lazar",	177.18231	]	,
["Bijak",	177.20206	]	,
["Bajda",	177.22181	]	,
["Raczek",	177.24154	]	,
["Tomczuk",	177.26126	]	,
["Bula",	177.28098	]	,
["Kopinski",	177.30069	]	,
["Byczek",	177.32039	]	,
["Powroznik",	177.34007	]	,
["Konopacki",	177.35975	]	,
["Bienko",	177.37943	]	,
["Zyskowski",	177.3991	]	,
["Kukulski",	177.41875	]	,
["Glód",	177.4384	]	,
["Dwojak",	177.45804	]	,
["Kukulka",	177.47766	]	,
["Goc",	177.49728	]	,
["Wolniak",	177.51688	]	,
["Kramarczyk",	177.53648	]	,
["Grodecki",	177.55607	]	,
["Zur",	177.57565	]	,
["Wesierski",	177.59523	]	,
["Niedziólka",	177.61481	]	,
["Orzol",	177.63438	]	,
["Lubecki",	177.65395	]	,
["Glówka",	177.67352	]	,
["Lapa",	177.69308	]	,
["Barcikowski",	177.71264	]	,
["Niemyjski",	177.73219	]	,
["Banasiewicz",	177.75174	]	,
["Stec",	177.77128	]	,
["Popowski",	177.79081	]	,
["Surówka",	177.81033	]	,
["Lesnik",	177.82985	]	,
["Krezel",	177.84937	]	,
["Pietraszewski",	177.86888	]	,
["Wyszomirski",	177.88838	]	,
["Radon",	177.90788	]	,
["Przepiórka",	177.92738	]	,
["Zelazowski",	177.94687	]	,
["Murzyn",	177.96636	]	,
["Tatar",	177.98584	]	,
["Kocinski",	178.00532	]	,
["Duma",	178.0248	]	,
["Tucholski",	178.04427	]	,
["Balcerak",	178.06373	]	,
["Gabrysiak",	178.08316	]	,
["Dubinski",	178.10259	]	,
["Warszawski",	178.122	]	,
["Kokocinski",	178.14141	]	,
["Smigiel",	178.16081	]	,
["Piechowicz",	178.18021	]	,
["Bania",	178.19961	]	,
["Martyniak",	178.219	]	,
["Fatyga",	178.23838	]	,
["Zubrzycki",	178.25775	]	,
["Lisicki",	178.27712	]	,
["Fabian",	178.29649	]	,
["Wicinski",	178.31585	]	,
["Grzechnik",	178.33519	]	,
["Koscinski",	178.35452	]	,
["Szatan",	178.37384	]	,
["Kuras",	178.39315	]	,
["Perek",	178.41244	]	,
["Choma",	178.43173	]	,
["Samek",	178.451	]	,
["Poblocki",	178.47027	]	,
["Mrugala",	178.48954	]	,
["Podsiadly",	178.5088	]	,
["Straczek",	178.52805	]	,
["Papis",	178.5473	]	,
["Krzyzanski",	178.56655	]	,
["Bielec",	178.5858	]	,
["Matejko",	178.60504	]	,
["Berger",	178.62428	]	,
["Borowy",	178.64351	]	,
["Strak",	178.66273	]	,
["Szopinski",	178.68194	]	,
["Szczur",	178.70115	]	,
["Pol",	178.72036	]	,
["Tobola",	178.73956	]	,
["Dalecki",	178.75875	]	,
["Regula",	178.77792	]	,
["Pikul",	178.79709	]	,
["Blacha",	178.81626	]	,
["Zoladek",	178.83542	]	,
["Kapela",	178.85458	]	,
["Gozdek",	178.87374	]	,
["Turkowski",	178.89289	]	,
["Halicki",	178.91204	]	,
["Baj",	178.93119	]	,
["Dymowski",	178.95033	]	,
["Dudkowski",	178.96947	]	,
["Banaszczyk",	178.98861	]	,
["Ujma",	179.00774	]	,
["Biniek",	179.02687	]	,
["Szabat",	179.04599	]	,
["Popowicz",	179.06511	]	,
["Zadlo",	179.08422	]	,
["Radosz",	179.10333	]	,
["Chrapek",	179.12244	]	,
["Florkowski",	179.14154	]	,
["Bialobrzeski",	179.16064	]	,
["Antoszewski",	179.17974	]	,
["Juda",	179.19883	]	,
["Muras",	179.21791	]	,
["Chlopek",	179.23698	]	,
["Kosciuk",	179.25602	]	,
["Syrek",	179.27504	]	,
["Medrek",	179.29406	]	,
["Wosik",	179.31307	]	,
["Pomykala",	179.33208	]	,
["Sierpinski",	179.35108	]	,
["Lichota",	179.37008	]	,
["Ratynski",	179.38907	]	,
["Wierzbinski",	179.40805	]	,
["Jesionowski",	179.42703	]	,
["Cymerman",	179.44601	]	,
["Mencel",	179.46498	]	,
["Malanowski",	179.48395	]	,
["Idczak",	179.5029	]	,
["Arendt",	179.52185	]	,
["Macioszek",	179.54079	]	,
["Jatczak",	179.55973	]	,
["Kusmierek",	179.57866	]	,
["Targonski",	179.59758	]	,
["Radtke",	179.61649	]	,
["Suminski",	179.63539	]	,
["Cesarz",	179.65429	]	,
["Reszczynski",	179.67318	]	,
["Kwiek",	179.69207	]	,
["Romanek",	179.71095	]	,
["Skrodzki",	179.72982	]	,
["Kulczyk",	179.74869	]	,
["Sobotka",	179.76754	]	,
["Lemanczyk",	179.78639	]	,
["Zaworski",	179.80523	]	,
["Fabiszewski",	179.82407	]	,
["Piechnik",	179.8429	]	,
["Kociszewski",	179.86173	]	,
["Kobylecki",	179.88056	]	,
["Chorazy",	179.89938	]	,
["Winiecki",	179.91819	]	,
["Placek",	179.937	]	,
["Strojek",	179.9558	]	,
["Zimoch",	179.97458	]	,
["Kachel",	179.99334	]	,
["Budzisz",	180.0121	]	,
["Lenarczyk",	180.03085	]	,
["Koziara",	180.0496	]	,
["Mlodawski",	180.06834	]	,
["Biegun",	180.08708	]	,
["Kurzeja",	180.10581	]	,
["Grenda",	180.12454	]	,
["Smiech",	180.14326	]	,
["Wodecki",	180.16197	]	,
["Tomkiewicz",	180.18068	]	,
["Skibicki",	180.19939	]	,
["Fraczak",	180.2181	]	,
["Cyganek",	180.23681	]	,
["Puk",	180.25548	]	,
["Zelazko",	180.27414	]	,
["Niedzwiecki",	180.2928	]	,
["Kata",	180.31145	]	,
["Ignaciuk",	180.33009	]	,
["Kabala",	180.34872	]	,
["Jesionek",	180.36733	]	,
["Pluskota",	180.38593	]	,
["Piecyk",	180.40453	]	,
["Jaroslawski",	180.42312	]	,
["Wyrwa",	180.4417	]	,
["Kobierski",	180.46028	]	,
["Zabrocki",	180.47885	]	,
["Frak",	180.49742	]	,
["Laczynski",	180.51597	]	,
["Swiech",	180.5345	]	,
["Kolczynski",	180.55303	]	,
["Braun",	180.57156	]	,
["Perlinski",	180.59008	]	,
["Pietak",	180.60859	]	,
["Dziekonski",	180.6271	]	,
["Stocki",	180.6456	]	,
["Miszczyk",	180.6641	]	,
["Lechowski",	180.6826	]	,
["Prusinowski",	180.70109	]	,
["Pyra",	180.71957	]	,
["Maciejowski",	180.73805	]	,
["Lysakowski",	180.75653	]	,
["Ksiazkiewicz",	180.77501	]	,
["Kamola",	180.79349	]	,
["Solarski",	180.81196	]	,
["Kuleta",	180.83043	]	,
["Jedrych",	180.8489	]	,
["Tarasiewicz",	180.86735	]	,
["Szulik",	180.88579	]	,
["Kwasna",	180.90423	]	,
["Mikolajczuk",	180.92266	]	,
["Dettlaff",	180.94109	]	,
["Chmielarz",	180.95952	]	,
["Bodnar",	180.97795	]	,
["Zimnicki",	180.99637	]	,
["Swierk",	181.01479	]	,
["Hryniewicz",	181.03321	]	,
["Skorupka",	181.05162	]	,
["Sankowski",	181.07003	]	,
["Robakowski",	181.08842	]	,
["Chmielinski",	181.10681	]	,
["Bochniak",	181.1252	]	,
["Belka",	181.14359	]	,
["Szkutnik",	181.16196	]	,
["Karpiuk",	181.18033	]	,
["Maroszek",	181.19869	]	,
["Kulawik",	181.21705	]	,
["Siczek",	181.2354	]	,
["Kowolik",	181.25375	]	,
["Kijowski",	181.2721	]	,
["Jaromin",	181.29045	]	,
["Huk",	181.3088	]	,
["Oracz",	181.32714	]	,
["Burakowski",	181.34548	]	,
["Rolka",	181.36381	]	,
["Wawrzynczak",	181.38212	]	,
["Sobieszek",	181.40043	]	,
["Dziuk",	181.41874	]	,
["Staszczyk",	181.43704	]	,
["Kurylo",	181.45534	]	,
["Kuras",	181.47364	]	,
["Krzesniak",	181.49194	]	,
["Skolimowski",	181.51023	]	,
["Nurzynski",	181.52851	]	,
["Wawro",	181.54678	]	,
["Kuberski",	181.56505	]	,
["Gawrysiak",	181.58332	]	,
["Chrobot",	181.60158	]	,
["Biesiada",	181.61983	]	,
["Antonik",	181.63808	]	,
["Walus",	181.65632	]	,
["Rozbicki",	181.67456	]	,
["Ludwikowski",	181.6928	]	,
["Brol",	181.71104	]	,
["Olszanski",	181.72927	]	,
["Oliwa",	181.7475	]	,
["Bukala",	181.76573	]	,
["Miecznikowski",	181.78395	]	,
["Cislo",	181.80217	]	,
["Wojas",	181.82038	]	,
["Remiszewski",	181.83858	]	,
["Bobek",	181.85678	]	,
["Siemaszko",	181.87497	]	,
["Kuzniak",	181.89316	]	,
["Ziolo",	181.91134	]	,
["Mieszkowski",	181.92952	]	,
["Jastrzab",	181.9477	]	,
["Sulkowski",	181.96587	]	,
["Müller",	181.98403	]	,
["Slaby",	182.00218	]	,
["Matyjaszczyk",	182.02033	]	,
["Golanski",	182.03848	]	,
["Korona",	182.05662	]	,
["Mocek",	182.07475	]	,
["Walczynski",	182.09286	]	,
["Cieplak",	182.11097	]	,
["Blachut",	182.12908	]	,
["Swietochowski",	182.14718	]	,
["Malak",	182.16528	]	,
["Gac",	182.18337	]	,
["Nasilowski",	182.20145	]	,
["Mrowinski",	182.21953	]	,
["Grzywaczewski",	182.23761	]	,
["Manikowski",	182.25568	]	,
["Kuca",	182.27375	]	,
["Kozielski",	182.29182	]	,
["Daniszewski",	182.30989	]	,
["Oldak",	182.32795	]	,
["Brodzik",	182.34601	]	,
["Caputa",	182.36406	]	,
["Stanowski",	182.3821	]	,
["Skrzypczynski",	182.40014	]	,
["Parys",	182.41818	]	,
["Broniszewski",	182.43622	]	,
["Rasinski",	182.45425	]	,
["Drabek",	182.47228	]	,
["Waclawek",	182.4903	]	,
["Rzeczkowski",	182.50832	]	,
["Paruzel",	182.52634	]	,
["Berezowski",	182.54436	]	,
["Kubiczek",	182.56237	]	,
["Chodkowski",	182.58038	]	,
["Siejka",	182.59837	]	,
["Pekalski",	182.61636	]	,
["Krzewinski",	182.63435	]	,
["Kotlarz",	182.65234	]	,
["Pieczka",	182.67032	]	,
["Kasztelan",	182.6883	]	,
["Staniewski",	182.70627	]	,
["Pacholczyk",	182.72424	]	,
["Adamik",	182.74221	]	,
["Filipczuk",	182.76016	]	,
["Zadrozny",	182.77809	]	,
["Walach",	182.79602	]	,
["Andrysiak",	182.81395	]	,
["Stasiuk",	182.83187	]	,
["Dryja",	182.84978	]	,
["Pedziwiatr",	182.86768	]	,
["Konecki",	182.88558	]	,
["Glazewski",	182.90348	]	,
["Richert",	182.92135	]	,
["Deka",	182.93922	]	,
["Kozubek",	182.95708	]	,
["Karon",	182.97494	]	,
["Schab",	182.99279	]	,
["Pietraszko",	183.01064	]	,
["Jung",	183.02848	]	,
["Grzelka",	183.04631	]	,
["Dmitruk",	183.06413	]	,
["Nowocien",	183.08194	]	,
["Michno",	183.09975	]	,
["Wnorowski",	183.11755	]	,
["Raszka",	183.13535	]	,
["Binek",	183.15315	]	,
["Swietlik",	183.17094	]	,
["Major",	183.18873	]	,
["Czachor",	183.20652	]	,
["Byrski",	183.22431	]	,
["Orczyk",	183.24209	]	,
["Kaszynski",	183.25987	]	,
["Giera",	183.27765	]	,
["Mikolajek",	183.29541	]	,
["Ligocki",	183.31317	]	,
["Fraczyk",	183.33093	]	,
["Drezek",	183.34869	]	,
["Wojewódzki",	183.36644	]	,
["Graca",	183.38419	]	,
["Zembrzuski",	183.40193	]	,
["Smoczynski",	183.41967	]	,
["Wolnik",	183.4374	]	,
["Weglinski",	183.45513	]	,
["Witt",	183.47285	]	,
["Pokrywka",	183.49057	]	,
["Oleszek",	183.50828	]	,
["Kozuchowski",	183.52598	]	,
["Ulanowski",	183.54366	]	,
["Siudak",	183.56134	]	,
["Luszcz",	183.57902	]	,
["Gronek",	183.5967	]	,
["Skalecki",	183.61437	]	,
["Polomski",	183.63204	]	,
["Mech",	183.6497	]	,
["Stokowski",	183.66735	]	,
["Harasim",	183.685	]	,
["Koscielski",	183.70264	]	,
["Gizinski",	183.72028	]	,
["Rzeszutek",	183.73791	]	,
["Antosiewicz",	183.75554	]	,
["Tyrala",	183.77316	]	,
["Cupial",	183.79078	]	,
["Bobinski",	183.8084	]	,
["Ogrodowczyk",	183.82601	]	,
["Ratajski",	183.84361	]	,
["Chwastek",	183.86121	]	,
["Kozbial",	183.8788	]	,
["Kasperowicz",	183.89639	]	,
["Kalkowski",	183.91398	]	,
["Suchanek",	183.93156	]	,
["Soból",	183.94914	]	,
["Rogóz",	183.96672	]	,
["Mozdzen",	183.9843	]	,
["Gadzinski",	184.00188	]	,
["Feliks",	184.01946	]	,
["Ociepka",	184.03703	]	,
["Langowski",	184.05459	]	,
["Marut",	184.07213	]	,
["Ciechanowicz",	184.08967	]	,
["Brandt",	184.10721	]	,
["Wlodek",	184.12474	]	,
["Groszek",	184.14227	]	,
["Kopera",	184.15979	]	,
["Franke",	184.17731	]	,
["Milanowski",	184.19481	]	,
["Kepczynski",	184.21231	]	,
["Gomólka",	184.22981	]	,
["Zmudzinski",	184.24729	]	,
["Sicinski",	184.26477	]	,
["Albrecht",	184.28225	]	,
["Urbas",	184.29971	]	,
["Mocarski",	184.31717	]	,
["Imiolek",	184.33463	]	,
["Koscielny",	184.35208	]	,
["Bronowicki",	184.36953	]	,
["Szary",	184.38697	]	,
["Pamula",	184.40441	]	,
["Marcisz",	184.42184	]	,
["Kalata",	184.43927	]	,
["Zdeb",	184.45669	]	,
["Przepióra",	184.47411	]	,
["Nieckarz",	184.49153	]	,
["Kominek",	184.50895	]	,
["Ilczuk",	184.52637	]	,
["Szychowski",	184.54378	]	,
["Pastusiak",	184.56119	]	,
["Swat",	184.57859	]	,
["Stojek",	184.59599	]	,
["Ordon",	184.61339	]	,
["Koszalka",	184.63079	]	,
["Spalek",	184.64818	]	,
["Ozga",	184.66557	]	,
["Michaluk",	184.68296	]	,
["Skrzypinski",	184.70033	]	,
["Burdzy",	184.7177	]	,
["Witecki",	184.73506	]	,
["Koziolek",	184.75242	]	,
["Syta",	184.76976	]	,
["Krys",	184.7871	]	,
["Koczwara",	184.80444	]	,
["Ulman",	184.82174	]	,
["Ras",	184.83904	]	,
["Krzyszton",	184.85634	]	,
["Kaniecki",	184.87364	]	,
["Szypula",	184.89093	]	,
["Matras",	184.90822	]	,
["Lawrynowicz",	184.92551	]	,
["Kesik",	184.9428	]	,
["Holda",	184.96009	]	,
["Wojtyla",	184.97737	]	,
["Wojdak",	184.99465	]	,
["Lyko",	185.01193	]	,
["Kulis",	185.0292	]	,
["Kepski",	185.04647	]	,
["Jedrasik",	185.06374	]	,
["Chelstowski",	185.08101	]	,
["Barabasz",	185.09828	]	,
["Ukleja",	185.11554	]	,
["Gasowski",	185.1328	]	,
["Rapacz",	185.15005	]	,
["Jania",	185.16729	]	,
["Gubala",	185.18453	]	,
["Wachala",	185.20176	]	,
["Waligórski",	185.21899	]	,
["Antolak",	185.23622	]	,
["Kotarba",	185.25344	]	,
["Kondrat",	185.27066	]	,
["Gwiazdowski",	185.28788	]	,
["Turczyn",	185.30509	]	,
["Krych",	185.3223	]	,
["Zientek",	185.3395	]	,
["Bodzioch",	185.35669	]	,
["Solak",	185.37387	]	,
["Siminski",	185.39105	]	,
["Michalowicz",	185.40823	]	,
["Krzyminski",	185.42541	]	,
["Gawrych",	185.44258	]	,
["Szczuka",	185.45974	]	,
["Maciaszczyk",	185.47689	]	,
["Kras",	185.49404	]	,
["Klusek",	185.51119	]	,
["Zdyb",	185.52833	]	,
["Sieron",	185.54547	]	,
["Pietruczuk",	185.5626	]	,
["Firek",	185.57972	]	,
["Szeremeta",	185.59683	]	,
["Lopatka",	185.61394	]	,
["Kolodynski",	185.63104	]	,
["Kloda",	185.64814	]	,
["Karlinski",	185.66524	]	,
["Gucwa",	185.68234	]	,
["Szczypka",	185.69943	]	,
["Malysa",	185.71652	]	,
["Makówka",	185.7336	]	,
["Komenda",	185.75068	]	,
["Gryc",	185.76776	]	,
["Frank",	185.78484	]	,
["Machala",	185.80191	]	,
["Inglot",	185.81898	]	,
["Gabor",	185.83604	]	,
["Brzezina",	185.8531	]	,
["Moskala",	185.87015	]	,
["Kutyla",	185.8872	]	,
["Bereza",	185.90425	]	,
["Drogosz",	185.92129	]	,
["Opara",	185.93832	]	,
["Kucharek",	185.95535	]	,
["Nazaruk",	185.97237	]	,
["Zaczek",	185.98938	]	,
["Waliczek",	186.00639	]	,
["Pilarz",	186.0234	]	,
["Lisewski",	186.04041	]	,
["Lelek",	186.05742	]	,
["Jaszczyk",	186.07443	]	,
["Faron",	186.09144	]	,
["Jedras",	186.10842	]	,
["Czernicki",	186.12539	]	,
["Woszczyk",	186.14235	]	,
["Owczarski",	186.1593	]	,
["Krasniewski",	186.17625	]	,
["Czubek",	186.1932	]	,
["Chwalek",	186.21015	]	,
["Abram",	186.22709	]	,
["Michalewicz",	186.24402	]	,
["Czapnik",	186.26095	]	,
["Rossa",	186.27787	]	,
["Kubicz",	186.29479	]	,
["Ciupak",	186.31171	]	,
["Ejsmont",	186.32862	]	,
["Zadrozna",	186.34552	]	,
["Matusiewicz",	186.36242	]	,
["Kolecki",	186.37932	]	,
["Jacek",	186.39622	]	,
["Chrabaszcz",	186.41312	]	,
["Zajda",	186.43001	]	,
["Dus",	186.4469	]	,
["Dalek",	186.46379	]	,
["Zmyslowski",	186.48067	]	,
["Respondek",	186.49755	]	,
["Maryniak",	186.51443	]	,
["Hinz",	186.53131	]	,
["Wolyniec",	186.54818	]	,
["Wardak",	186.56505	]	,
["Rzasa",	186.58192	]	,
["Poprawa",	186.59879	]	,
["Lenard",	186.61566	]	,
["Drazkiewicz",	186.63253	]	,
["Bulka",	186.6494	]	,
["Zachara",	186.66626	]	,
["Janek",	186.68312	]	,
["Glodowski",	186.69998	]	,
["Labuz",	186.71683	]	,
["Stanisz",	186.73367	]	,
["Lubczynski",	186.75051	]	,
["Kruczynski",	186.76735	]	,
["Gomulka",	186.78419	]	,
["Szelagowski",	186.80102	]	,
["Zuber",	186.81784	]	,
["Szklarz",	186.83466	]	,
["Kotulski",	186.85148	]	,
["Pieniak",	186.86829	]	,
["Wojdat",	186.88509	]	,
["Strózynski",	186.90189	]	,
["Sadurski",	186.91869	]	,
["Pietron",	186.93549	]	,
["Wiktorowski",	186.95228	]	,
["Pac",	186.96907	]	,
["Matula",	186.98586	]	,
["Latka",	187.00265	]	,
["Grabarek",	187.01944	]	,
["Babula",	187.03623	]	,
["Papiez",	187.05301	]	,
["Ledwon",	187.06978	]	,
["Skrzypkowski",	187.08654	]	,
["Czernek",	187.1033	]	,
["Ferens",	187.12004	]	,
["Doniec",	187.13678	]	,
["Budna",	187.15352	]	,
["Jucha",	187.17025	]	,
["Hirsz",	187.18698	]	,
["Lozowski",	187.2037	]	,
["Czub",	187.22042	]	,
["Swiecki",	187.23713	]	,
["Szwedo",	187.25384	]	,
["Janda",	187.27055	]	,
["Gibala",	187.28726	]	,
["Paczynski",	187.30396	]	,
["Ceglarski",	187.32066	]	,
["Mosakowski",	187.33735	]	,
["Pastwa",	187.35403	]	,
["Jakóbczyk",	187.37071	]	,
["Fudala",	187.38739	]	,
["Szczawinski",	187.40406	]	,
["Miksa",	187.42073	]	,
["Pecak",	187.43739	]	,
["Niedzialkowski",	187.45405	]	,
["Chojnicki",	187.4707	]	,
["Koszela",	187.48734	]	,
["Zdziarski",	187.50397	]	,
["Stopka",	187.5206	]	,
["Smólka",	187.53723	]	,
["Dygas",	187.55386	]	,
["Wiecha",	187.57048	]	,
["Tomanek",	187.5871	]	,
["Noworyta",	187.60372	]	,
["Burski",	187.62034	]	,
["Skrok",	187.63695	]	,
["Tutak",	187.65354	]	,
["Kotkowski",	187.67013	]	,
["Wiater",	187.68671	]	,
["Tomiak",	187.70329	]	,
["Rabiega",	187.71987	]	,
["Zarski",	187.73644	]	,
["Misiura",	187.753	]	,
["Krajnik",	187.76956	]	,
["Czerw",	187.78611	]	,
["Zwolak",	187.80265	]	,
["Wyrwas",	187.81919	]	,
["Piosik",	187.83573	]	,
["Otreba",	187.85227	]	,
["Kopaczewski",	187.86881	]	,
["Karkowski",	187.88535	]	,
["Jarzebowski",	187.90189	]	,
["Dluzniewski",	187.91842	]	,
["Wojciechowicz",	187.93494	]	,
["Harasimowicz",	187.95146	]	,
["Wolosz",	187.96797	]	,
["Bucior",	187.98448	]	,
["Wiercioch",	188.00098	]	,
["Uszynski",	188.01748	]	,
["Sulowski",	188.03398	]	,
["Glinkowski",	188.05048	]	,
["Ciupek",	188.06698	]	,
["Chabros",	188.08348	]	,
["Stawiarz",	188.09997	]	,
["Siennicki",	188.11646	]	,
["Hebel",	188.13295	]	,
["Wzorek",	188.14942	]	,
["Skupinski",	188.16589	]	,
["Kurasz",	188.18236	]	,
["Gorzelak",	188.19883	]	,
["Sitkiewicz",	188.21529	]	,
["Sacha",	188.23175	]	,
["Prusik",	188.24821	]	,
["Lisiewicz",	188.26467	]	,
["Jacewicz",	188.28113	]	,
["Dybek",	188.29759	]	,
["Kotula",	188.31404	]	,
["Derkacz",	188.33049	]	,
["Antosiak",	188.34694	]	,
["Zarnowski",	188.36338	]	,
["Mielnicki",	188.37982	]	,
["Krzesinski",	188.39626	]	,
["Kosciólek",	188.41267	]	,
["Szczepanczyk",	188.42907	]	,
["Sienicki",	188.44547	]	,
["Pindel",	188.46187	]	,
["Kolodziejek",	188.47827	]	,
["Borycki",	188.49467	]	,
["Toporek",	188.51106	]	,
["Sobótka",	188.52745	]	,
["Nakielski",	188.54384	]	,
["Jaskowski",	188.56022	]	,
["Czerny",	188.5766	]	,
["Smiechowski",	188.59297	]	,
["Przybylowicz",	188.60934	]	,
["Stypulkowski",	188.6257	]	,
["Knapczyk",	188.64206	]	,
["Biedron",	188.65842	]	,
["Staniec",	188.67477	]	,
["Przadka",	188.69112	]	,
["Kurzawski",	188.70747	]	,
["Jachimowicz",	188.72382	]	,
["Mostowski",	188.74016	]	,
["Wojdylo",	188.75649	]	,
["Okoniewski",	188.77282	]	,
["Kielb",	188.78915	]	,
["Gross",	188.80548	]	,
["Czarna",	188.82181	]	,
["Sosnicki",	188.83813	]	,
["Okrasa",	188.85445	]	,
["Kusmierski",	188.87076	]	,
["Kornas",	188.88707	]	,
["Mielniczek",	188.90337	]	,
["Lakomski",	188.91966	]	,
["Falinski",	188.93595	]	,
["Swiader",	188.95223	]	,
["Perzanowski",	188.96851	]	,
["Data",	188.98479	]	,
["Pruszkowski",	189.00106	]	,
["Krzyzowski",	189.01733	]	,
["Gdula",	189.0336	]	,
["Kuczek",	189.04986	]	,
["Kijek",	189.06612	]	,
["Grzelczak",	189.08238	]	,
["Blaszczuk",	189.09864	]	,
["Turczynski",	189.11489	]	,
["Natkaniec",	189.13112	]	,
["Bucko",	189.14734	]	,
["Aksamit",	189.16356	]	,
["Was",	189.17977	]	,
["Magda",	189.19598	]	,
["Jonca",	189.21218	]	,
["Zolnowski",	189.22837	]	,
["Skubisz",	189.24455	]	,
["Wadas",	189.26072	]	,
["Tkaczuk",	189.27689	]	,
["Waskiewicz",	189.29305	]	,
["Napieraj",	189.30921	]	,
["Makosa",	189.32537	]	,
["Kielpinski",	189.34153	]	,
["Czerniejewski",	189.35769	]	,
["Bar",	189.37384	]	,
["Malewski",	189.38997	]	,
["Zelazna",	189.40609	]	,
["Wlodkowski",	189.42221	]	,
["Rejman",	189.43832	]	,
["Meyer",	189.45443	]	,
["Bosak",	189.47054	]	,
["Derlatka",	189.48664	]	,
["Rygiel",	189.50272	]	,
["Labuda",	189.5188	]	,
["Gniazdowski",	189.53487	]	,
["Wybraniec",	189.55093	]	,
["Sroda",	189.56699	]	,
["Kabacinski",	189.58304	]	,
["Dragon",	189.59909	]	,
["Chudziak",	189.61514	]	,
["Fornalczyk",	189.63118	]	,
["Budka",	189.64722	]	,
["Zambrzycki",	189.66325	]	,
["Karasek",	189.67928	]	,
["Józwicki",	189.69531	]	,
["Marszalkowski",	189.71133	]	,
["Marynowski",	189.72734	]	,
["Szymik",	189.74333	]	,
["Niec",	189.75932	]	,
["Korytkowski",	189.77531	]	,
["Kacki",	189.79129	]	,
["Juskowiak",	189.80727	]	,
["Ciesluk",	189.82325	]	,
["Pieróg",	189.83922	]	,
["Gulczynski",	189.85519	]	,
["Zemla",	189.87115	]	,
["Wator",	189.88711	]	,
["Slusarek",	189.90307	]	,
["Bialik",	189.91903	]	,
["Stenka",	189.93496	]	,
["Rytel",	189.95089	]	,
["Grzesiuk",	189.96682	]	,
["Dyjak",	189.98275	]	,
["Abramowski",	189.99867	]	,
["Pestka",	190.01458	]	,
["Szukala",	190.03048	]	,
["Rekas",	190.04638	]	,
["Lazarz",	190.06228	]	,
["Cichowicz",	190.07817	]	,
["Neuman",	190.09405	]	,
["Lagoda",	190.10993	]	,
["Klimiuk",	190.12581	]	,
["Karaszewski",	190.14169	]	,
["Handzlik",	190.15757	]	,
["Celej",	190.17345	]	,
["Trojak",	190.18932	]	,
["Jawor",	190.20518	]	,
["Kargul",	190.22103	]	,
["Iwanowicz",	190.23688	]	,
["Smola",	190.25272	]	,
["Olbrys",	190.26856	]	,
["Lyson",	190.2844	]	,
["Dziubak",	190.30024	]	,
["Jonski",	190.31607	]	,
["Pas",	190.33189	]	,
["Budziszewski",	190.34771	]	,
["Bauer",	190.36353	]	,
["Szefler",	190.37934	]	,
["Strzeszewski",	190.39515	]	,
["Majek",	190.41096	]	,
["Stasiewicz",	190.42676	]	,
["Bargiel",	190.44256	]	,
["Szumowski",	190.45835	]	,
["Sordyl",	190.47414	]	,
["Krzyzewski",	190.48992	]	,
["Ciepiela",	190.5057	]	,
["Ziemski",	190.52147	]	,
["Kapron",	190.53724	]	,
["Bal",	190.55301	]	,
["Bakula",	190.56878	]	,
["Jeziorowski",	190.58454	]	,
["Lenczewski",	190.60029	]	,
["Koszyk",	190.61604	]	,
["Kitowski",	190.63179	]	,
["Deska",	190.64754	]	,
["Pietryka",	190.66328	]	,
["Kozminski",	190.67902	]	,
["Jurczynski",	190.69476	]	,
["Suchy",	190.71049	]	,
["Ploszaj",	190.72622	]	,
["Mentel",	190.74195	]	,
["Labedz",	190.75767	]	,
["Szczech",	190.77338	]	,
["Borsuk",	190.78909	]	,
["Zdun",	190.80479	]	,
["Lipnicki",	190.82049	]	,
["Witowski",	190.83618	]	,
["Politowski",	190.85186	]	,
["Orszulak",	190.86754	]	,
["Jackowiak",	190.88322	]	,
["Marciszewski",	190.89889	]	,
["Blazewicz",	190.91456	]	,
["Szydelko",	190.93022	]	,
["Widomski",	190.94587	]	,
["Gryczka",	190.96152	]	,
["Dziegiel",	190.97717	]	,
["Solowiej",	190.99281	]	,
["Naumowicz",	191.00845	]	,
["Szczurowski",	191.02408	]	,
["Sobczuk",	191.03971	]	,
["Slawek",	191.05534	]	,
["Luka",	191.07097	]	,
["Lazowski",	191.0866	]	,
["Gawryluk",	191.10222	]	,
["Blok",	191.11784	]	,
["Baca",	191.13346	]	,
["Tomecki",	191.14907	]	,
["Kaszubski",	191.16468	]	,
["Iwaszkiewicz",	191.18029	]	,
["Dobrzycki",	191.1959	]	,
["Jakubas",	191.2115	]	,
["Grabka",	191.2271	]	,
["Cioch",	191.2427	]	,
["Furmanczyk",	191.25829	]	,
["Czerwik",	191.27388	]	,
["Wiktorski",	191.28945	]	,
["Miazek",	191.30502	]	,
["Majchrzyk",	191.32059	]	,
["Urbaniec",	191.33615	]	,
["Goraj",	191.35171	]	,
["Bera",	191.36727	]	,
["Bednorz",	191.38283	]	,
["Szmigielski",	191.39838	]	,
["Rydel",	191.41393	]	,
["Grabek",	191.42948	]	,
["Kosiba",	191.44502	]	,
["Karski",	191.46056	]	,
["Jarczewski",	191.47609	]	,
["Kapczynski",	191.49161	]	,
["Kalina",	191.50713	]	,
["Swierc",	191.52264	]	,
["Szlendak",	191.53815	]	,
["Twarowski",	191.55365	]	,
["Olejarczyk",	191.56915	]	,
["Musialowski",	191.58465	]	,
["Kudelski",	191.60015	]	,
["Glowala",	191.61565	]	,
["Czogala",	191.63115	]	,
["Najder",	191.64664	]	,
["Snarski",	191.66212	]	,
["Bratek",	191.6776	]	,
["Tutaj",	191.69306	]	,
["Tobolski",	191.70852	]	,
["Siemieniuk",	191.72398	]	,
["Jaroszynski",	191.73944	]	,
["Nizio",	191.75489	]	,
["Dolegowski",	191.77034	]	,
["Szypulski",	191.78578	]	,
["Szalek",	191.80122	]	,
["Rodzik",	191.81666	]	,
["Lasocki",	191.8321	]	,
["Dulski",	191.84754	]	,
["Tyszko",	191.86297	]	,
["Wojno",	191.87839	]	,
["Klawikowski",	191.89381	]	,
["Banaszewski",	191.90923	]	,
["Sternik",	191.92464	]	,
["Polus",	191.94005	]	,
["Chludzinski",	191.95546	]	,
["Sobek",	191.97086	]	,
["Panas",	191.98626	]	,
["Wrzesniewski",	192.00165	]	,
["Plewinski",	192.01704	]	,
["Dorsz",	192.03243	]	,
["Wolszczak",	192.04781	]	,
["Scibor",	192.06319	]	,
["Roginski",	192.07857	]	,
["Luba",	192.09395	]	,
["Warzocha",	192.10932	]	,
["Trocha",	192.12469	]	,
["Rus",	192.14006	]	,
["Lebek",	192.15543	]	,
["Kostyra",	192.1708	]	,
["Slazak",	192.18616	]	,
["Grzywinski",	192.20152	]	,
["Rymarczyk",	192.21687	]	,
["Popko",	192.23222	]	,
["Peczek",	192.24757	]	,
["Trzebiatowski",	192.26291	]	,
["Schneider",	192.27825	]	,
["Kampa",	192.29359	]	,
["Czepiel",	192.30893	]	,
["Zwierz",	192.32426	]	,
["Smolka",	192.33959	]	,
["Dyduch",	192.35492	]	,
["Piekos",	192.37024	]	,
["Kordowski",	192.38556	]	,
["Narewski",	192.40087	]	,
["Kluza",	192.41618	]	,
["Raj",	192.43148	]	,
["Popielarz",	192.44678	]	,
["Merta",	192.46208	]	,
["Wegrzynowski",	192.47737	]	,
["Sawinski",	192.49266	]	,
["Plusa",	192.50795	]	,
["Józefowski",	192.52324	]	,
["Nizinski",	192.53852	]	,
["Gonciarz",	192.5538	]	,
["Laszczak",	192.56907	]	,
["Cala",	192.58434	]	,
["Sierant",	192.5996	]	,
["Rutecki",	192.61486	]	,
["Dzierzanowski",	192.63012	]	,
["Dunajski",	192.64538	]	,
["Jazwinski",	192.66063	]	,
["Haber",	192.67588	]	,
["Grabarz",	192.69113	]	,
["Choromanski",	192.70638	]	,
["Plewka",	192.72162	]	,
["Kieliszek",	192.73686	]	,
["Dyminski",	192.7521	]	,
["Chilinski",	192.76734	]	,
["Wycisk",	192.78257	]	,
["Pabich",	192.7978	]	,
["Gorgon",	192.81303	]	,
["Dzida",	192.82826	]	,
["Ceglowski",	192.84349	]	,
["Boratynski",	192.85872	]	,
["Antkiewicz",	192.87395	]	,
["Wieclawski",	192.88916	]	,
["Koczur",	192.90437	]	,
["Janiczek",	192.91958	]	,
["Krzysiak",	192.93478	]	,
["Psiuk",	192.94997	]	,
["Janczyk",	192.96516	]	,
["Guzinski",	192.98035	]	,
["Szafranek",	192.99553	]	,
["Kieltyka",	193.01071	]	,
["Adam",	193.02589	]	,
["Wysoczanski",	193.04106	]	,
["Rachwalski",	193.05623	]	,
["Latala",	193.0714	]	,
["Aniszewski",	193.08655	]	,
["Suchan",	193.10169	]	,
["Polanowski",	193.11683	]	,
["Kamienski",	193.13197	]	,
["Franek",	193.14711	]	,
["Rybski",	193.16224	]	,
["Kaptur",	193.17737	]	,
["Duszak",	193.1925	]	,
["Szoltysek",	193.20762	]	,
["Golba",	193.22274	]	,
["Galan",	193.23786	]	,
["Rucki",	193.25297	]	,
["Olesiak",	193.26808	]	,
["Jureczko",	193.28319	]	,
["Czyzyk",	193.2983	]	,
["Dawidziuk",	193.3134	]	,
["Topolewski",	193.32849	]	,
["Grzegrzólka",	193.34358	]	,
["Nogalski",	193.35866	]	,
["Kociuba",	193.37374	]	,
["Babik",	193.38882	]	,
["Trebacz",	193.40389	]	,
["Kudlinski",	193.41896	]	,
["Bilicki",	193.43403	]	,
["Szczupak",	193.44909	]	,
["Stadnicki",	193.46415	]	,
["Kramek",	193.47921	]	,
["Musialek",	193.49426	]	,
["Gula",	193.50931	]	,
["Galewski",	193.52435	]	,
["Buras",	193.53939	]	,
["Stalmach",	193.55441	]	,
["Sapinski",	193.56943	]	,
["Oginski",	193.58445	]	,
["Gosk",	193.59947	]	,
["Berent",	193.61449	]	,
["Seliga",	193.6295	]	,
["Naskret",	193.64451	]	,
["Betkowski",	193.65952	]	,
["Sówka",	193.6745	]	,
["Lukaszek",	193.68948	]	,
["Gracz",	193.70446	]	,
["Sklodowski",	193.71943	]	,
["Torba",	193.73439	]	,
["Szota",	193.74935	]	,
["Olszowy",	193.76431	]	,
["Glapa",	193.77927	]	,
["Woroniecki",	193.79422	]	,
["Holub",	193.80916	]	,
["Matera",	193.82409	]	,
["Malyska",	193.83902	]	,
["Golis",	193.85395	]	,
["Gmyrek",	193.86888	]	,
["Blachnio",	193.88381	]	,
["Rudek",	193.89873	]	,
["Zientara",	193.91364	]	,
["Rzepkowski",	193.92855	]	,
["Nowaczek",	193.94346	]	,
["Malek",	193.95837	]	,
["Zalecki",	193.97327	]	,
["Wirkus",	193.98817	]	,
["Szumilas",	194.00307	]	,
["Ogorzalek",	194.01797	]	,
["Jablonka",	194.03287	]	,
["Drzyzga",	194.04777	]	,
["Goluch",	194.06266	]	,
["Golas",	194.07755	]	,
["Bagrowski",	194.09243	]	,
["Ziarko",	194.1073	]	,
["Sztandera",	194.12217	]	,
["Stryjewski",	194.13704	]	,
["Korzen",	194.15191	]	,
["Ciba",	194.16678	]	,
["Klukowski",	194.18164	]	,
["Siwy",	194.19649	]	,
["Oleszkiewicz",	194.21134	]	,
["Marianski",	194.22619	]	,
["Madalinski",	194.24104	]	,
["Lawicki",	194.25589	]	,
["Latocha",	194.27074	]	,
["Kusy",	194.28559	]	,
["Cieslicki",	194.30044	]	,
["Wiejak",	194.31528	]	,
["Matejek",	194.33012	]	,
["Przydatek",	194.34495	]	,
["Nitecki",	194.35978	]	,
["Knopik",	194.3746	]	,
["Truszczynski",	194.38941	]	,
["Olechnowicz",	194.40422	]	,
["Kobylanski",	194.41903	]	,
["Slomczynski",	194.43383	]	,
["Machnicki",	194.44863	]	,
["Ludwig",	194.46343	]	,
["Leskiewicz",	194.47823	]	,
["Kulej",	194.49303	]	,
["Ambrozy",	194.50782	]	,
["Klasa",	194.5226	]	,
["Cudak",	194.53738	]	,
["Wilamowski",	194.55215	]	,
["Olszowski",	194.56692	]	,
["Prusinski",	194.58168	]	,
["Marks",	194.59644	]	,
["Gromada",	194.6112	]	,
["Gancarczyk",	194.62596	]	,
["Wawrzynski",	194.64071	]	,
["Modlinski",	194.65546	]	,
["Fabianski",	194.67021	]	,
["Wojtyniak",	194.68495	]	,
["Karkoszka",	194.69969	]	,
["Tyl",	194.71442	]	,
["Obuchowski",	194.72915	]	,
["Latkowski",	194.74388	]	,
["Fischer",	194.75861	]	,
["Jeznach",	194.77333	]	,
["Hetman",	194.78805	]	,
["Legierski",	194.80276	]	,
["Taraszkiewicz",	194.81746	]	,
["Markuszewski",	194.83216	]	,
["Twaróg",	194.84685	]	,
["Paluszek",	194.86154	]	,
["Chwialkowski",	194.87623	]	,
["Paruch",	194.89091	]	,
["Kieszkowski",	194.90559	]	,
["Denis",	194.92027	]	,
["Cieszkowski",	194.93495	]	,
["Widawski",	194.94962	]	,
["Pochec",	194.96429	]	,
["Nowara",	194.97895	]	,
["Niepsuj",	194.99361	]	,
["Siwicki",	195.00826	]	,
["Mos",	195.02291	]	,
["Krzeszewski",	195.03756	]	,
["Dobija",	195.05221	]	,
["Chruscinski",	195.06686	]	,
["Potempa",	195.08149	]	,
["Kulawiak",	195.09612	]	,
["Sitarek",	195.11074	]	,
["Pecyna",	195.12536	]	,
["Mikucki",	195.13998	]	,
["Lason",	195.1546	]	,
["Kupisz",	195.16922	]	,
["Tarasewicz",	195.18383	]	,
["Sucharski",	195.19844	]	,
["Les",	195.21305	]	,
["Domalewski",	195.22766	]	,
["Demianiuk",	195.24227	]	,
["Strach",	195.25687	]	,
["Rutkiewicz",	195.27147	]	,
["Kudyba",	195.28607	]	,
["Kwas",	195.30066	]	,
["Kusmirek",	195.31525	]	,
["Kolanowski",	195.32984	]	,
["Kierzek",	195.34441	]	,
["Dominiczak",	195.35898	]	,
["Wojtyra",	195.37354	]	,
["Pikulski",	195.3881	]	,
["Kuczma",	195.40266	]	,
["Iwaniak",	195.41722	]	,
["Szkop",	195.43177	]	,
["Radek",	195.44632	]	,
["Kotlarski",	195.46087	]	,
["Bala",	195.47542	]	,
["Grzonka",	195.48996	]	,
["Stolc",	195.50449	]	,
["Niestrój",	195.51902	]	,
["Latka",	195.53355	]	,
["Filipski",	195.54808	]	,
["Eliasz",	195.56261	]	,
["Seroka",	195.57713	]	,
["Ruda",	195.59165	]	,
["Miklaszewski",	195.60617	]	,
["Stryczek",	195.62068	]	,
["Chromik",	195.63519	]	,
["Pelczar",	195.64969	]	,
["Kozina",	195.66419	]	,
["Fajfer",	195.67869	]	,
["Podraza",	195.69318	]	,
["Miklas",	195.70766	]	,
["Kesicki",	195.72214	]	,
["Wardega",	195.73661	]	,
["Pieprzyk",	195.75108	]	,
["Kawalek",	195.76555	]	,
["Arent",	195.78002	]	,
["Niedbalski",	195.79448	]	,
["Mendyk",	195.80894	]	,
["Kurpiel",	195.8234	]	,
["Jurasz",	195.83786	]	,
["Suchomski",	195.85231	]	,
["Rosolowski",	195.86676	]	,
["Reczek",	195.88121	]	,
["Pasierb",	195.89566	]	,
["Nieradka",	195.91011	]	,
["Najdek",	195.92456	]	,
["Kudzia",	195.93901	]	,
["Blawat",	195.95346	]	,
["Koszewski",	195.9679	]	,
["Ekiert",	195.98233	]	,
["Gmerek",	195.99675	]	,
["Cierpial",	196.01117	]	,
["Jurecki",	196.02558	]	,
["Budziak",	196.03999	]	,
["Sadecki",	196.05439	]	,
["Rewers",	196.06879	]	,
["Maniak",	196.08319	]	,
["Majerski",	196.09759	]	,
["Kin",	196.11199	]	,
["Jezak",	196.12639	]	,
["Szabelski",	196.14078	]	,
["Sawicz",	196.15517	]	,
["Grochocki",	196.16956	]	,
["Figas",	196.18395	]	,
["Boryczka",	196.19834	]	,
["Tchórz",	196.21272	]	,
["Stachnik",	196.2271	]	,
["Korol",	196.24148	]	,
["Komorek",	196.25586	]	,
["Gebicki",	196.27024	]	,
["Kluz",	196.28461	]	,
["Szczypior",	196.29897	]	,
["Jachowicz",	196.31333	]	,
["Piorun",	196.32768	]	,
["Konopinski",	196.34203	]	,
["Rostek",	196.35637	]	,
["Cwiertnia",	196.37071	]	,
["Sliwowski",	196.38504	]	,
["Palmowski",	196.39937	]	,
["Kocemba",	196.4137	]	,
["Ciszak",	196.42803	]	,
["Matlak",	196.44234	]	,
["Lazarczyk",	196.45665	]	,
["Lakomiec",	196.47096	]	,
["Kaniowski",	196.48527	]	,
["Arndt",	196.49958	]	,
["Hankus",	196.51388	]	,
["Bartecki",	196.52817	]	,
["Wojnicki",	196.54245	]	,
["Jagusiak",	196.55673	]	,
["Centkowski",	196.571	]	,
["Stodulski",	196.58526	]	,
["Pawelczak",	196.59952	]	,
["Nowok",	196.61378	]	,
["Bentkowski",	196.62804	]	,
["Springer",	196.64228	]	,
["Sawka",	196.65652	]	,
["Kwolek",	196.67076	]	,
["Jasiewicz",	196.685	]	,
["Dyrcz",	196.69923	]	,
["Fudala",	196.71345	]	,
["Zagajewski",	196.72766	]	,
["Wijas",	196.74187	]	,
["Tomkowiak",	196.75608	]	,
["Slabon",	196.77029	]	,
["Skura",	196.7845	]	,
["Ludwicki",	196.79871	]	,
["Zybala",	196.81291	]	,
["Mianowski",	196.82711	]	,
["Czyzak",	196.84131	]	,
["Laszkiewicz",	196.8555	]	,
["Dzioba",	196.86969	]	,
["Korba",	196.88387	]	,
["Januszek",	196.89805	]	,
["Wolowski",	196.91222	]	,
["Studnicki",	196.92639	]	,
["Kopacki",	196.94056	]	,
["Malyszko",	196.95472	]	,
["Podlaski",	196.96887	]	,
["Gawlak",	196.98302	]	,
["Roszczyk",	196.99716	]	,
["Osak",	197.0113	]	,
["Danilewicz",	197.02544	]	,
["Raczkowski",	197.03957	]	,
["Lyzwa",	197.0537	]	,
["Dampc",	197.06783	]	,
["Siatkowski",	197.08195	]	,
["Garbarczyk",	197.09607	]	,
["Teodorczyk",	197.11018	]	,
["Kaca",	197.12429	]	,
["Gunia",	197.1384	]	,
["Bigaj",	197.15251	]	,
["Seremak",	197.16661	]	,
["Mól",	197.18071	]	,
["Labecki",	197.19481	]	,
["Zuber",	197.2089	]	,
["Radzki",	197.22299	]	,
["Pozorski",	197.23708	]	,
["Pawlica",	197.25117	]	,
["Bronisz",	197.26526	]	,
["Korolczuk",	197.27934	]	,
["Tur",	197.29341	]	,
["Szura",	197.30748	]	,
["Cuper",	197.32155	]	,
["Szymaszek",	197.33561	]	,
["Rebacz",	197.34967	]	,
["Lyszczarz",	197.36373	]	,
["Janosz",	197.37779	]	,
["Brandys",	197.39185	]	,
["Wilga",	197.4059	]	,
["Swidzinski",	197.41995	]	,
["Kubisiak",	197.434	]	,
["Jerzak",	197.44805	]	,
["Stefan",	197.46209	]	,
["Bojar",	197.47613	]	,
["Bednarczuk",	197.49017	]	,
["Tomasiak",	197.50419	]	,
["Konieczko",	197.51821	]	,
["Czekanski",	197.53223	]	,
["Serwin",	197.54624	]	,
["Miziolek",	197.56025	]	,
["Kroczek",	197.57426	]	,
["Jedryka",	197.58827	]	,
["Gumowski",	197.60228	]	,
["Grzymala",	197.61629	]	,
["Zuraw",	197.63029	]	,
["Zabawa",	197.64429	]	,
["Wronowski",	197.65829	]	,
["Macura",	197.67229	]	,
["Niczyporuk",	197.68628	]	,
["Jamka",	197.70027	]	,
["Niwinski",	197.71425	]	,
["Kulpinski",	197.72823	]	,
["Branicki",	197.74221	]	,
["Dziurzynski",	197.75618	]	,
["Ciecwierz",	197.77015	]	,
["Jarczynski",	197.78411	]	,
["Federowicz",	197.79807	]	,
["Hryciuk",	197.81201	]	,
["Glowa",	197.82595	]	,
["Chrominski",	197.83989	]	,
["Borowa",	197.85383	]	,
["Pasinski",	197.86776	]	,
["Kaliszuk",	197.88169	]	,
["Chudek",	197.89562	]	,
["Uznanski",	197.90954	]	,
["Ostojski",	197.92346	]	,
["Nakonieczny",	197.93738	]	,
["Grochala",	197.9513	]	,
["Gogolewski",	197.96522	]	,
["Radkiewicz",	197.97913	]	,
["Prorok",	197.99304	]	,
["Owczarz",	198.00695	]	,
["Olechowski",	198.02086	]	,
["Martyna",	198.03477	]	,
["Grochulski",	198.04868	]	,
["Golen",	198.06259	]	,
["Grzeskiewicz",	198.07649	]	,
["Bem",	198.09039	]	,
["Olkiewicz",	198.10428	]	,
["Matuszkiewicz",	198.11817	]	,
["Majkut",	198.13206	]	,
["Kaiser",	198.14595	]	,
["Wieloch",	198.15982	]	,
["Szefer",	198.17369	]	,
["Strojny",	198.18756	]	,
["Papuga",	198.20143	]	,
["Mierzynski",	198.2153	]	,
["Matyjasik",	198.22917	]	,
["Leczycki",	198.24304	]	,
["Lasecki",	198.25691	]	,
["Straszewski",	198.27077	]	,
["Skorupinski",	198.28463	]	,
["Komarnicki",	198.29849	]	,
["Kluk",	198.31235	]	,
["Szolc",	198.3262	]	,
["Stala",	198.34005	]	,
["Seroczynski",	198.3539	]	,
["Lubinski",	198.36775	]	,
["Zaloga",	198.38159	]	,
["Kilinski",	198.39543	]	,
["Gluszak",	198.40927	]	,
["Szuminski",	198.4231	]	,
["Ciupinski",	198.43693	]	,
["Grygier",	198.45075	]	,
["Durski",	198.46457	]	,
["Bandura",	198.47839	]	,
["Zaorski",	198.4922	]	,
["Porowski",	198.50601	]	,
["Kaszewski",	198.51982	]	,
["Zygadlo",	198.53362	]	,
["Roslaniec",	198.54742	]	,
["Mac",	198.56122	]	,
["Ciechomski",	198.57502	]	,
["Stefanek",	198.58881	]	,
["Spyrka",	198.6026	]	,
["Majcherczyk",	198.61639	]	,
["Janocha",	198.63018	]	,
["Bledowski",	198.64397	]	,
["Polczynski",	198.65775	]	,
["Molinski",	198.67153	]	,
["Gliniecki",	198.68531	]	,
["Cieply",	198.69909	]	,
["Brylka",	198.71287	]	,
["Abramek",	198.72665	]	,
["Rekawek",	198.74042	]	,
["Bogus",	198.75418	]	,
["Wardzinski",	198.76793	]	,
["Stepnik",	198.78168	]	,
["Niznik",	198.79543	]	,
["Misiek",	198.80918	]	,
["Kunikowski",	198.82293	]	,
["Fluder",	198.83668	]	,
["Statkiewicz",	198.85042	]	,
["Marcinowski",	198.86416	]	,
["Hryniewicki",	198.8779	]	,
["Bojda",	198.89164	]	,
["Bigus",	198.90538	]	,
["Koncewicz",	198.91911	]	,
["Beczek",	198.93284	]	,
["Pyrka",	198.94656	]	,
["Ponikowski",	198.96028	]	,
["Papierz",	198.974	]	,
["Molik",	198.98772	]	,
["Komosa",	199.00144	]	,
["Bialczak",	199.01516	]	,
["Bargiel",	199.02888	]	,
["Wodnicki",	199.04259	]	,
["Gawlas",	199.0563	]	,
["Adler",	199.07001	]	,
["Laba",	199.08371	]	,
["Piasek",	199.0974	]	,
["Bartosinski",	199.11109	]	,
["Ucinski",	199.12477	]	,
["Sygula",	199.13845	]	,
["Kruszyna",	199.15213	]	,
["Calus",	199.16581	]	,
["Bialon",	199.17949	]	,
["Tylkowski",	199.19316	]	,
["Poprawski",	199.20683	]	,
["Maik",	199.2205	]	,
["Zachariasz",	199.23416	]	,
["Wereszczynski",	199.24782	]	,
["Suszynski",	199.26148	]	,
["Postek",	199.27514	]	,
["Szczyrba",	199.28879	]	,
["Karbowniczek",	199.30244	]	,
["Jurak",	199.31609	]	,
["Jagus",	199.32974	]	,
["Ciarkowski",	199.34339	]	,
["Ciupka",	199.35703	]	,
["Sierocki",	199.37066	]	,
["Rój",	199.38429	]	,
["Borzyszkowski",	199.39792	]	,
["Lyczko",	199.41154	]	,
["Glen",	199.42516	]	,
["Lesniowski",	199.43877	]	,
["Gzik",	199.45238	]	,
["Gasinski",	199.46599	]	,
["Bujok",	199.4796	]	,
["Olewinski",	199.4932	]	,
["Tryba",	199.50679	]	,
["Perzyna",	199.52037	]	,
["Gostkowski",	199.53395	]	,
["Ziemniak",	199.54752	]	,
["Walczewski",	199.56109	]	,
["Tyrka",	199.57466	]	,
["Jaskot",	199.58823	]	,
["Ploski",	199.60179	]	,
["Trzesniewski",	199.61534	]	,
["Maciazek",	199.62889	]	,
["Korzec",	199.64244	]	,
["Sulinski",	199.65598	]	,
["Toporowski",	199.66951	]	,
["Szlezak",	199.68304	]	,
["Knitter",	199.69657	]	,
["Ignaszak",	199.7101	]	,
["Danielczyk",	199.72363	]	,
["Bekier",	199.73716	]	,
["Ksiezopolski",	199.75068	]	,
["Komor",	199.7642	]	,
["Kardas",	199.77772	]	,
["Skrzek",	199.79123	]	,
["Iwaszko",	199.80474	]	,
["Anuszkiewicz",	199.81825	]	,
["Michalkiewicz",	199.83175	]	,
["Dymkowski",	199.84525	]	,
["Matelski",	199.85874	]	,
["Krzykowski",	199.87223	]	,
["Dulinski",	199.88572	]	,
["Wasinski",	199.8992	]	,
["Tomiczek",	199.91268	]	,
["Popis",	199.92616	]	,
["Migacz",	199.93964	]	,
["Balon",	199.95312	]	,
["Kiraga",	199.96659	]	,
["Winiarz",	199.98005	]	,
["Lendzion",	199.99351	]	,
["Bajek",	200.00697	]	,
["Roszyk",	200.02042	]	,
["Dzido",	200.03387	]	,
["Dolatowski",	200.04732	]	,
["Bara",	200.06077	]	,
["Szal",	200.07421	]	,
["Marciniec",	200.08765	]	,
["Lopacinski",	200.10109	]	,
["Grzadziel",	200.11453	]	,
["Chadzynski",	200.12797	]	,
["Cap",	200.14141	]	,
["Bula",	200.15485	]	,
["Baba",	200.16829	]	,
["Kuzmicki",	200.18172	]	,
["Joniec",	200.19515	]	,
["Domachowski",	200.20858	]	,
["Smyczek",	200.222	]	,
["Jachimowski",	200.23542	]	,
["Gontarczyk",	200.24884	]	,
["Bidzinski",	200.26225	]	,
["Aftyka",	200.27566	]	,
["Wojnarowicz",	200.28906	]	,
["Stepnowski",	200.30246	]	,
["Skop",	200.31586	]	,
["Ploch",	200.32926	]	,
["Ochmanski",	200.34265	]	,
["Kamecki",	200.35604	]	,
["Uchman",	200.36942	]	,
["Siuta",	200.3828	]	,
["Rabenda",	200.39618	]	,
["Nakonieczna",	200.40956	]	,
["Loza",	200.42294	]	,
["Kapusniak",	200.43632	]	,
["Homa",	200.4497	]	,
["Gliwa",	200.46308	]	,
["Fuks",	200.47646	]	,
["Bylica",	200.48984	]	,
["Ziarkowski",	200.50321	]	,
["Rybakowski",	200.51658	]	,
["Igielski",	200.52995	]	,
["Brych",	200.54332	]	,
["Suwalski",	200.55668	]	,
["Lubas",	200.57004	]	,
["Gluza",	200.5834	]	,
["Sztuka",	200.59675	]	,
["Sielicki",	200.6101	]	,
["Rogaczewski",	200.62345	]	,
["Lesniewicz",	200.6368	]	,
["Dolny",	200.65015	]	,
["Taras",	200.66349	]	,
["Milewicz",	200.67683	]	,
["Szpunar",	200.69016	]	,
["Skulski",	200.70349	]	,
["Perzynski",	200.71682	]	,
["Korsak",	200.73015	]	,
["Zalas",	200.74347	]	,
["Witak",	200.75679	]	,
["Kesy",	200.77011	]	,
["Balcerowski",	200.78343	]	,
["Sierzputowski",	200.79674	]	,
["Pawinski",	200.81005	]	,
["Krogulec",	200.82336	]	,
["Malarz",	200.83666	]	,
["Flisikowski",	200.84996	]	,
["Brzeczek",	200.86326	]	,
["Ryczkowski",	200.87655	]	,
["Frej",	200.88984	]	,
["Florkiewicz",	200.90312	]	,
["Telega",	200.91639	]	,
["Skierski",	200.92966	]	,
["Kryszak",	200.94293	]	,
["Kryger",	200.9562	]	,
["Prusaczyk",	200.96945	]	,
["Majcherek",	200.9827	]	,
["Grzegorzek",	200.99595	]	,
["Dobrzeniecki",	201.0092	]	,
["Lowicki",	201.02244	]	,
["Latosinski",	201.03568	]	,
["Zawieja",	201.04891	]	,
["Archacki",	201.06214	]	,
["Smykowski",	201.07536	]	,
["Pajewski",	201.08858	]	,
["Kopcinski",	201.1018	]	,
["Holownia",	201.11502	]	,
["Cieplik",	201.12824	]	,
["Reda",	201.14145	]	,
["Polowczyk",	201.15466	]	,
["Kusak",	201.16787	]	,
["Krzysztofiak",	201.18108	]	,
["Kleczek",	201.19429	]	,
["Siekiera",	201.20749	]	,
["Religa",	201.22069	]	,
["Grzechowiak",	201.23389	]	,
["Czyszczon",	201.24709	]	,
["Luszczek",	201.26028	]	,
["Wawrzak",	201.27346	]	,
["Dziki",	201.28664	]	,
["Bulat",	201.29982	]	,
["Waclawik",	201.31299	]	,
["Sutkowski",	201.32616	]	,
["Lipczynski",	201.33933	]	,
["Kozanecki",	201.3525	]	,
["Walecki",	201.36566	]	,
["Nikodem",	201.37882	]	,
["Kordas",	201.39198	]	,
["Bajkowski",	201.40514	]	,
["Wawrowski",	201.41829	]	,
["Sniezek",	201.43144	]	,
["Polewski",	201.44459	]	,
["Drwal",	201.45774	]	,
["Skrobisz",	201.47088	]	,
["Korecki",	201.48402	]	,
["Dworzynski",	201.49716	]	,
["Bajerski",	201.5103	]	,
["Scislowski",	201.52343	]	,
["Sanecki",	201.53656	]	,
["Lysek",	201.54969	]	,
["Kupinski",	201.56282	]	,
["Czapka",	201.57595	]	,
["Zgoda",	201.58907	]	,
["Wal",	201.60219	]	,
["Kosiorowski",	201.61531	]	,
["Smolenski",	201.62842	]	,
["Potepa",	201.64153	]	,
["Mista",	201.65464	]	,
["Jadach",	201.66775	]	,
["Gdaniec",	201.68086	]	,
["Brulinski",	201.69397	]	,
["Bandurski",	201.70708	]	,
["Palubicki",	201.72018	]	,
["Hildebrandt",	201.73328	]	,
["Fabis",	201.74638	]	,
["Depczynski",	201.75948	]	,
["Wrzos",	201.77257	]	,
["Wantuch",	201.78566	]	,
["Kantorski",	201.79875	]	,
["Kala",	201.81184	]	,
["Pucek",	201.82492	]	,
["Popczyk",	201.838	]	,
["Hibner",	201.85108	]	,
["Swierkowski",	201.86415	]	,
["Sobiesiak",	201.87722	]	,
["Osika",	201.89029	]	,
["Narkiewicz",	201.90336	]	,
["Micek",	201.91643	]	,
["Górowski",	201.9295	]	,
["Zamorski",	201.94256	]	,
["Worek",	201.95562	]	,
["Kielkowski",	201.96868	]	,
["Brychcy",	201.98174	]	,
["Szymiczek",	201.99479	]	,
["Chomicki",	202.00784	]	,
["Zychowicz",	202.02088	]	,
["Szuszkiewicz",	202.03392	]	,
["Sylwestrzak",	202.04696	]	,
["Kostrubiec",	202.06	]	,
["Jedrzejowski",	202.07304	]	,
["Ziecina",	202.08607	]	,
["Orzech",	202.0991	]	,
["Kosla",	202.11213	]	,
["Drelich",	202.12516	]	,
["Barton",	202.13819	]	,
["Walkowicz",	202.15121	]	,
["Podyma",	202.16423	]	,
["Kuzmicz",	202.17725	]	,
["Blachowicz",	202.19027	]	,
["Bernaciak",	202.20329	]	,
["Paziewski",	202.2163	]	,
["Kalus",	202.22931	]	,
["Fiedor",	202.24232	]	,
["Staszczak",	202.25532	]	,
["Drazkowski",	202.26832	]	,
["Bieniasz",	202.28132	]	,
["Lalik",	202.2943	]	,
["Jeziorek",	202.30728	]	,
["Wegiel",	202.32025	]	,
["Walega",	202.33322	]	,
["Talarczyk",	202.34619	]	,
["Musik",	202.35916	]	,
["Kowalewicz",	202.37213	]	,
["Jachym",	202.3851	]	,
["Winiarek",	202.39806	]	,
["Szynal",	202.41102	]	,
["Smolarz",	202.42398	]	,
["Kacala",	202.43694	]	,
["Melon",	202.44989	]	,
["Jedrysiak",	202.46284	]	,
["Grygoruk",	202.47579	]	,
["Gozdziewski",	202.48874	]	,
["Charkiewicz",	202.50169	]	,
["Bartoszuk",	202.51464	]	,
["Rapala",	202.52758	]	,
["Nalepka",	202.54052	]	,
["Kobylka",	202.55346	]	,
["Wicik",	202.56639	]	,
["Biegaj",	202.57932	]	,
["Kusinski",	202.59223	]	,
["Grot",	202.60514	]	,
["Gajowniczek",	202.61805	]	,
["Durda",	202.63096	]	,
["Borówka",	202.64387	]	,
["Sujka",	202.65677	]	,
["Franczak",	202.66967	]	,
["Piskorek",	202.68256	]	,
["Obrzut",	202.69545	]	,
["Drewnowski",	202.70834	]	,
["Kubasik",	202.72122	]	,
["Bratkowski",	202.7341	]	,
["Wojna",	202.74697	]	,
["Waga",	202.75984	]	,
["Serek",	202.77271	]	,
["Pohl",	202.78558	]	,
["Deregowski",	202.79845	]	,
["Sieracki",	202.81131	]	,
["Siembida",	202.82417	]	,
["Mlodzianowski",	202.83703	]	,
["Waclawski",	202.84988	]	,
["Talik",	202.86273	]	,
["Szymala",	202.87558	]	,
["Just",	202.88843	]	,
["Goluch",	202.90128	]	,
["Denisiuk",	202.91413	]	,
["Bugala",	202.92698	]	,
["Bucholc",	202.93983	]	,
["Smolik",	202.95267	]	,
["Nyga",	202.96551	]	,
["Nogal",	202.97835	]	,
["Latusek",	202.99119	]	,
["Gradzik",	203.00403	]	,
["Tymoszuk",	203.01686	]	,
["Stachon",	203.02969	]	,
["Ozdoba",	203.04252	]	,
["Joachimiak",	203.05535	]	,
["Dziwisz",	203.06818	]	,
["Zub",	203.081	]	,
["Skwarczynski",	203.09382	]	,
["Plochocki",	203.10664	]	,
["Pawlina",	203.11946	]	,
["Zon",	203.13227	]	,
["Wychowaniec",	203.14508	]	,
["Rogowicz",	203.15789	]	,
["Podstawka",	203.1707	]	,
["Pieczara",	203.18351	]	,
["Opolski",	203.19632	]	,
["Grocholski",	203.20913	]	,
["Drozdzynski",	203.22194	]	,
["Wlosek",	203.23474	]	,
["Topór",	203.24754	]	,
["Krzywdzinski",	203.26034	]	,
["Kornatowski",	203.27314	]	,
["Jokiel",	203.28594	]	,
["Jaruga",	203.29874	]	,
["Szmyd",	203.31153	]	,
["Polaczek",	203.32432	]	,
["Makula",	203.33711	]	,
["Kisielewicz",	203.3499	]	,
["Dratwa",	203.36269	]	,
["Danecki",	203.37548	]	,
["Czupryniak",	203.38827	]	,
["Antonczyk",	203.40106	]	,
["Swist",	203.41384	]	,
["Strózik",	203.42662	]	,
["Wojtecki",	203.43939	]	,
["Paprota",	203.45216	]	,
["Kacprowicz",	203.46493	]	,
["Staszek",	203.47769	]	,
["Norek",	203.49045	]	,
["Migdalski",	203.50321	]	,
["Socko",	203.51596	]	,
["Orczykowski",	203.52871	]	,
["Dombek",	203.54146	]	,
["Bronk",	203.55421	]	,
["Górczak",	203.56695	]	,
["Francuz",	203.57969	]	,
["Rosiek",	203.59242	]	,
["Papaj",	203.60515	]	,
["Bronikowski",	203.61788	]	,
["Sykula",	203.6306	]	,
["Zimnoch",	203.64331	]	,
["Swiatczak",	203.65602	]	,
["Jaskólka",	203.66873	]	,
["Figarski",	203.68144	]	,
["Chelminiak",	203.69415	]	,
["Tomasiewicz",	203.70685	]	,
["Szponar",	203.71955	]	,
["Rdzanek",	203.73224	]	,
["Grunwald",	203.74493	]	,
["Wojtan",	203.75761	]	,
["Piekutowski",	203.77029	]	,
["Klopotowski",	203.78297	]	,
["Woronowicz",	203.79564	]	,
["Lysik",	203.80831	]	,
["Koscianski",	203.82098	]	,
["Kalka",	203.83365	]	,
["Kornas",	203.84631	]	,
["Kaznowski",	203.85897	]	,
["Barcik",	203.87163	]	,
["Rzadkowski",	203.88428	]	,
["Jaczewski",	203.89693	]	,
["Czok",	203.90958	]	,
["Antas",	203.92223	]	,
["Osuchowski",	203.93487	]	,
["Maciejczak",	203.94751	]	,
["Kamionka",	203.96015	]	,
["Wojtanowski",	203.97278	]	,
["Michalewski",	203.98541	]	,
["Chec",	203.99804	]	,
["Wolowicz",	204.01066	]	,
["Wojtasiak",	204.02328	]	,
["Szafarczyk",	204.0359	]	,
["Iwanczuk",	204.04852	]	,
["Talar",	204.06113	]	,
["Kucza",	204.07374	]	,
["Welna",	204.08634	]	,
["Pazio",	204.09894	]	,
["Lisinski",	204.11154	]	,
["Filar",	204.12414	]	,
["Fil",	204.13674	]	,
["Dabski",	204.14934	]	,
["Splawski",	204.16193	]	,
["Rowicki",	204.17452	]	,
["Switek",	204.1871	]	,
["Gontarek",	204.19968	]	,
["Pedzich",	204.21224	]	,
["Mozejko",	204.2248	]	,
["Materna",	204.23736	]	,
["Klimczuk",	204.24992	]	,
["Godula",	204.26248	]	,
["Majczak",	204.27503	]	,
["Wydrzynski",	204.28757	]	,
["Kozakowski",	204.30011	]	,
["Korniluk",	204.31265	]	,
["Cieluch",	204.32519	]	,
["Chrobok",	204.33773	]	,
["Sieniawski",	204.35026	]	,
["Pociecha",	204.36279	]	,
["Karolczyk",	204.37532	]	,
["Dolna",	204.38785	]	,
["Boczar",	204.40038	]	,
["Troszczynski",	204.4129	]	,
["Gryko",	204.42542	]	,
["Chrostek",	204.43794	]	,
["Bieszczad",	204.45046	]	,
["Dziekanski",	204.46297	]	,
["Najman",	204.47547	]	,
["Moczydlowski",	204.48797	]	,
["Matwiejczuk",	204.50047	]	,
["Mankiewicz",	204.51297	]	,
["Dlugokecki",	204.52547	]	,
["Borowczak",	204.53797	]	,
["Barylski",	204.55047	]	,
["Popiela",	204.56296	]	,
["Niklas",	204.57545	]	,
["Matoga",	204.58794	]	,
["Maron",	204.60043	]	,
["Dzwonek",	204.61292	]	,
["Ogrodowski",	204.6254	]	,
["Majchrowicz",	204.63788	]	,
["Korneluk",	204.65036	]	,
["Chodakowski",	204.66284	]	,
["Waz",	204.67531	]	,
["Starowicz",	204.68778	]	,
["Jalowiecki",	204.70025	]	,
["Stelmaszyk",	204.71271	]	,
["Smok",	204.72517	]	,
["Gnat",	204.73763	]	,
["Czarniak",	204.75009	]	,
["Bielewicz",	204.76255	]	,
["Wlodarek",	204.775	]	,
["Moch",	204.78745	]	,
["Goryl",	204.7999	]	,
["Szczodrowski",	204.81234	]	,
["Rutyna",	204.82478	]	,
["Jedrusik",	204.83722	]	,
["Purzycki",	204.84965	]	,
["Mezyk",	204.86208	]	,
["Pacholec",	204.8745	]	,
["Jarmolowicz",	204.88692	]	,
["Halasa",	204.89934	]	,
["Balut",	204.91176	]	,
["Szych",	204.92417	]	,
["Rudowski",	204.93658	]	,
["Konopa",	204.94899	]	,
["Kasperkiewicz",	204.9614	]	,
["Weglewski",	204.9738	]	,
["Saja",	204.9862	]	,
["Pielak",	204.9986	]	,
["Manowski",	205.011	]	,
["Lepkowski",	205.0234	]	,
["Korycinski",	205.0358	]	,
["Fraczkowski",	205.0482	]	,
["Dobrosielski",	205.0606	]	,
["Szczecina",	205.07299	]	,
["Rolnik",	205.08538	]	,
["Lasak",	205.09777	]	,
["Durak",	205.11016	]	,
["Muchowski",	205.12254	]	,
["Zima",	205.13491	]	,
["Sokólski",	205.14728	]	,
["Gwizdz",	205.15965	]	,
["Smietanka",	205.17201	]	,
["Stanicki",	205.18436	]	,
["Przychodzen",	205.19671	]	,
["Gralewski",	205.20906	]	,
["Matusz",	205.2214	]	,
["Czerniecki",	205.23374	]	,
["Stawowy",	205.24607	]	,
["Falek",	205.2584	]	,
["Dubaj",	205.27073	]	,
["Osmanski",	205.28305	]	,
["Dobies",	205.29537	]	,
["Winter",	205.30768	]	,
["Szymanowicz",	205.31999	]	,
["Sambor",	205.3323	]	,
["Piasta",	205.34461	]	,
["Pason",	205.35692	]	,
["Derkowski",	205.36923	]	,
["Loch",	205.38153	]	,
["Jedrychowski",	205.39383	]	,
["Cios",	205.40613	]	,
["Sumara",	205.41842	]	,
["Sobkowicz",	205.43071	]	,
["Pieczykolan",	205.443	]	,
["Minda",	205.45529	]	,
["Ewertowski",	205.46758	]	,
["Baniak",	205.47987	]	,
["Kamrowski",	205.49215	]	,
["Zawila",	205.50442	]	,
["Witkiewicz",	205.51669	]	,
["Polek",	205.52896	]	,
["Pacak",	205.54123	]	,
["Moleda",	205.5535	]	,
["Dyla",	205.56577	]	,
["Szendzielorz",	205.57803	]	,
["Koziej",	205.59029	]	,
["Fijal",	205.60255	]	,
["Chelminski",	205.61481	]	,
["Wiechec",	205.62706	]	,
["Mitrega",	205.63931	]	,
["Maniecki",	205.65156	]	,
["Kuzniewski",	205.66381	]	,
["Kopania",	205.67606	]	,
["Debkowski",	205.68831	]	,
["Binkiewicz",	205.70055	]	,
["Tusinski",	205.71278	]	,
["Trocki",	205.72501	]	,
["Switon",	205.73724	]	,
["Myslek",	205.74947	]	,
["Motylewski",	205.7617	]	,
["Miarka",	205.77393	]	,
["Durczak",	205.78616	]	,
["Sledziewski",	205.79838	]	,
["Oniszczuk",	205.8106	]	,
["Butryn",	205.82282	]	,
["Widz",	205.83503	]	,
["Supinski",	205.84724	]	,
["Mularski",	205.85945	]	,
["Korczyk",	205.87166	]	,
["Wachnik",	205.88386	]	,
["Rylko",	205.89606	]	,
["Madaj",	205.90826	]	,
["Lobodzinski",	205.92046	]	,
["Kakolewski",	205.93265	]	,
["Rams",	205.94483	]	,
["Marchel",	205.95701	]	,
["Leszek",	205.96919	]	,
["Janaszek",	205.98137	]	,
["Dworaczek",	205.99355	]	,
["Zadroga",	206.00572	]	,
["Szul",	206.01789	]	,
["Panfil",	206.03006	]	,
["Lamparski",	206.04223	]	,
["Krasnicki",	206.0544	]	,
["Goslawski",	206.06657	]	,
["Ptasznik",	206.07873	]	,
["Zdybel",	206.09088	]	,
["Kawalko",	206.10303	]	,
["Dubas",	206.11518	]	,
["Ciaston",	206.12733	]	,
["Chodkiewicz",	206.13948	]	,
["Bruski",	206.15163	]	,
["Sosin",	206.16377	]	,
["Pikus",	206.17591	]	,
["Babiak",	206.18805	]	,
["Olak",	206.20018	]	,
["Nobis",	206.21231	]	,
["Jurgielewicz",	206.22444	]	,
["Jaszewski",	206.23657	]	,
["Grzenkowicz",	206.2487	]	,
["Strek",	206.26082	]	,
["Krzewski",	206.27294	]	,
["Gajdzinski",	206.28506	]	,
["Struski",	206.29717	]	,
["Fiut",	206.30928	]	,
["Wit",	206.32138	]	,
["Sokal",	206.33348	]	,
["Chmurzynski",	206.34558	]	,
["Polcyn",	206.35767	]	,
["Mieszczak",	206.36976	]	,
["Magnuszewski",	206.38185	]	,
["Gasiewski",	206.39394	]	,
["Czaban",	206.40603	]	,
["Slodkowski",	206.41811	]	,
["Sidoruk",	206.43019	]	,
["Chudzicki",	206.44227	]	,
["Blaut",	206.45435	]	,
["Próchniak",	206.46642	]	,
["Podlesny",	206.47849	]	,
["Kuziola",	206.49056	]	,
["Szarzynski",	206.50262	]	,
["Mikos",	206.51468	]	,
["Lyczkowski",	206.52674	]	,
["Duk",	206.5388	]	,
["Wieleba",	206.55085	]	,
["Matczuk",	206.5629	]	,
["Machnio",	206.57495	]	,
["Kraj",	206.587	]	,
["Grzes",	206.59905	]	,
["Pacula",	206.61109	]	,
["Dyga",	206.62313	]	,
["Masztalerz",	206.63516	]	,
["Krasicki",	206.64719	]	,
["Sitnik",	206.65921	]	,
["Sedziak",	206.67123	]	,
["Jarzynka",	206.68325	]	,
["Auguscik",	206.69527	]	,
["Zelechowski",	206.70728	]	,
["Pilichowski",	206.71929	]	,
["Kortas",	206.7313	]	,
["Andrzejczuk",	206.74331	]	,
["Trojnar",	206.75531	]	,
["Skarbek",	206.76731	]	,
["Kajzer",	206.77931	]	,
["Fronc",	206.79131	]	,
["Stroinski",	206.8033	]	,
["Zuba",	206.81528	]	,
["Walendzik",	206.82725	]	,
["Smulski",	206.83922	]	,
["Lysak",	206.85119	]	,
["Krzeszowski",	206.86316	]	,
["Bozyk",	206.87513	]	,
["Paz",	206.88709	]	,
["Latawiec",	206.89904	]	,
["Mitka",	206.91098	]	,
["Szklarczyk",	206.92291	]	,
["Kachniarz",	206.93484	]	,
["Jakimiuk",	206.94677	]	,
["Grabowicz",	206.9587	]	,
["Bajon",	206.97063	]	,
["Barczewski",	206.98255	]	,
["Wyszkowicz",	206.99446	]	,
["Szurgot",	207.00637	]	,
["Szozda",	207.01828	]	,
["Nowowiejski",	207.03019	]	,
["Kajda",	207.0421	]	,
["Dyk",	207.05401	]	,
["Zebala",	207.06591	]	,
["Sztorc",	207.07781	]	,
["Nowotarski",	207.08971	]	,
["Lojko",	207.10161	]	,
["Kaluski",	207.11351	]	,
["Szymczuk",	207.1254	]	,
["Strus",	207.13729	]	,
["Stopyra",	207.14918	]	,
["Oldakowski",	207.16107	]	,
["Bochen",	207.17296	]	,
["Wojtyczka",	207.18484	]	,
["Katarzynski",	207.19672	]	,
["Bulik",	207.2086	]	,
["Pinkowski",	207.22047	]	,
["Kadlubowski",	207.23234	]	,
["Józefczyk",	207.24421	]	,
["Trybula",	207.25607	]	,
["Graf",	207.26793	]	,
["Ostapowicz",	207.27978	]	,
["Mietkiewicz",	207.29163	]	,
["Leszko",	207.30347	]	,
["Koscielna",	207.31531	]	,
["Ceglarz",	207.32715	]	,
["Zagrodzki",	207.33898	]	,
["Stepaniuk",	207.35081	]	,
["Mroczka",	207.36264	]	,
["Kostuch",	207.37447	]	,
["Kolcz",	207.3863	]	,
["Hadrys",	207.39813	]	,
["Gostynski",	207.40996	]	,
["Tworkowski",	207.42178	]	,
["Pacan",	207.4336	]	,
["Lapka",	207.44542	]	,
["Kopecki",	207.45724	]	,
["Frac",	207.46906	]	,
["Dziak",	207.48088	]	,
["Sedlak",	207.49269	]	,
["Lukaszyk",	207.5045	]	,
["Rózga",	207.5163	]	,
["Romanik",	207.5281	]	,
["Kida",	207.5399	]	,
["Buchwald",	207.5517	]	,
["Pralat",	207.56349	]	,
["Opiela",	207.57528	]	,
["Kolacki",	207.58707	]	,
["Siwiak",	207.59885	]	,
["Rudy",	207.61063	]	,
["Plawecki",	207.62241	]	,
["Ryszkowski",	207.63418	]	,
["Ponichtera",	207.64595	]	,
["Kmiotek",	207.65772	]	,
["Blaszczynski",	207.66949	]	,
["Basta",	207.68126	]	,
["Augustowski",	207.69303	]	,
["Siemianowski",	207.70479	]	,
["Prus",	207.71655	]	,
["Krzanowski",	207.72831	]	,
["Gapski",	207.74007	]	,
["Pasko",	207.75182	]	,
["Pasikowski",	207.76357	]	,
["Lewandowicz",	207.77532	]	,
["Jakimowicz",	207.78707	]	,
["Gospodarczyk",	207.79882	]	,
["Glos",	207.81057	]	,
["Gad",	207.82232	]	,
["Cisak",	207.83407	]	,
["Krzos",	207.84581	]	,
["Kotwicki",	207.85755	]	,
["Depa",	207.86929	]	,
["Tomas",	207.88102	]	,
["Gorzkiewicz",	207.89275	]	,
["Goch",	207.90447	]	,
["Busz",	207.91619	]	,
["Baraniecki",	207.92791	]	,
["Zduniak",	207.93962	]	,
["Scigala",	207.95133	]	,
["Ozarowski",	207.96304	]	,
["Mocko",	207.97475	]	,
["Jakóbczak",	207.98645	]	,
["Zelichowski",	207.99814	]	,
["Topczewski",	208.00983	]	,
["Puchacz",	208.02152	]	,
["Makara",	208.03321	]	,
["Królicki",	208.0449	]	,
["Dynowski",	208.05659	]	,
["Danilowicz",	208.06828	]	,
["Szady",	208.07996	]	,
["Osadnik",	208.09164	]	,
["Lubkowski",	208.10332	]	,
["Grzegorski",	208.115	]	,
["Chamera",	208.12668	]	,
["Zubek",	208.13835	]	,
["Soszka",	208.15002	]	,
["Paprzycki",	208.16169	]	,
["Makulski",	208.17336	]	,
["Bolesta",	208.18503	]	,
["Bielawa",	208.1967	]	,
["Brzoski",	208.20836	]	,
["Swieca",	208.22001	]	,
["Jordan",	208.23166	]	,
["Gabriel",	208.24331	]	,
["Pozoga",	208.25495	]	,
["Pawlas",	208.26659	]	,
["Stempien",	208.27822	]	,
["Smuga",	208.28985	]	,
["Olechno",	208.30148	]	,
["Naruszewicz",	208.31311	]	,
["Kotynia",	208.32474	]	,
["Slawski",	208.33636	]	,
["Kanclerz",	208.34798	]	,
["Ignatowski",	208.3596	]	,
["Haba",	208.37122	]	,
["Gawenda",	208.38284	]	,
["Zwolski",	208.39445	]	,
["Struk",	208.40606	]	,
["Sternal",	208.41767	]	,
["Lawecki",	208.42928	]	,
["Koba",	208.44089	]	,
["Bukowiecki",	208.4525	]	,
["Szarmach",	208.4641	]	,
["Sopel",	208.4757	]	,
["Gruda",	208.4873	]	,
["Swieboda",	208.49889	]	,
["Szulczewski",	208.51048	]	,
["Rykaczewski",	208.52207	]	,
["Marciniuk",	208.53366	]	,
["Lesiuk",	208.54525	]	,
["Chrzastek",	208.55684	]	,
["Zawalski",	208.56842	]	,
["Czyzowski",	208.58	]	,
["Wandzel",	208.59157	]	,
["Pajda",	208.60314	]	,
["Golunski",	208.61471	]	,
["Dzienis",	208.62628	]	,
["Borysewicz",	208.63785	]	,
["Szlaga",	208.64941	]	,
["Krzysiek",	208.66097	]	,
["Iwanczyk",	208.67253	]	,
["Modrzynski",	208.68408	]	,
["Cierpisz",	208.69563	]	,
["Cackowski",	208.70718	]	,
["Borczyk",	208.71873	]	,
["Ziembicki",	208.73027	]	,
["Szymula",	208.74181	]	,
["Kaluzynski",	208.75335	]	,
["Danielski",	208.76489	]	,
["Pietrasiak",	208.77641	]	,
["Minkiewicz",	208.78793	]	,
["Kazanowski",	208.79945	]	,
["Hojka",	208.81097	]	,
["Goral",	208.82249	]	,
["Stas",	208.834	]	,
["Smoter",	208.84551	]	,
["Przewlocki",	208.85702	]	,
["Dziewiecki",	208.86853	]	,
["Czubinski",	208.88004	]	,
["Bosek",	208.89155	]	,
["Bitner",	208.90305	]	,
["Ulinski",	208.91454	]	,
["Kornecki",	208.92603	]	,
["Kondej",	208.93752	]	,
["Furgal",	208.94901	]	,
["Durlik",	208.9605	]	,
["Kusa",	208.97198	]	,
["Durzynski",	208.98346	]	,
["Chacinski",	208.99494	]	,
["Bakalarski",	209.00642	]	,
["Zgierski",	209.01789	]	,
["Pacewicz",	209.02936	]	,
["Masiak",	209.04083	]	,
["Lempicki",	209.0523	]	,
["Kucz",	209.06377	]	,
["Kopycinski",	209.07524	]	,
["Cichowlas",	209.08671	]	,
["Babicki",	209.09818	]	,
["Anders",	209.10965	]	,
["Wawszczak",	209.12111	]	,
["Szalanski",	209.13257	]	,
["Slupek",	209.14403	]	,
["Pych",	209.15549	]	,
["Piszcz",	209.16695	]	,
["Opoka",	209.17841	]	,
["Lorenz",	209.18987	]	,
["Grochowina",	209.20133	]	,
["Ziemianski",	209.21278	]	,
["Wicha",	209.22423	]	,
["Pawliczek",	209.23568	]	,
["Kus",	209.24713	]	,
["Zysk",	209.25857	]	,
["Sroga",	209.27001	]	,
["Rychel",	209.28145	]	,
["Patora",	209.29289	]	,
["Maciocha",	209.30433	]	,
["Sujecki",	209.31576	]	,
["Rozmiarek",	209.32719	]	,
["Pesta",	209.33862	]	,
["Licznerski",	209.35005	]	,
["Kozerski",	209.36148	]	,
["Fabijanski",	209.37291	]	,
["Dzialak",	209.38434	]	,
["Cieslikowski",	209.39577	]	,
["Godyn",	209.40719	]	,
["Chmara",	209.41861	]	,
["Jakubaszek",	209.43002	]	,
["Balazy",	209.44143	]	,
["Rykala",	209.45283	]	,
["Wika",	209.46422	]	,
["Kotala",	209.47561	]	,
["Brzoskowski",	209.487	]	,
["Kuchcinski",	209.49838	]	,
["Kamieniecki",	209.50976	]	,
["Fikus",	209.52114	]	,
["Sus",	209.53251	]	,
["Kunc",	209.54388	]	,
["Chruscicki",	209.55525	]	,
["Salacinski",	209.56661	]	,
["Mateusiak",	209.57797	]	,
["Kusyk",	209.58933	]	,
["Ceglinski",	209.60069	]	,
["Romanczyk",	209.61204	]	,
["Makiela",	209.62339	]	,
["Lejman",	209.63474	]	,
["Kolaczek",	209.64609	]	,
["Bystrzycki",	209.65744	]	,
["Kurzak",	209.66878	]	,
["Czaplewski",	209.68012	]	,
["Bondyra",	209.69146	]	,
["Podkowa",	209.70279	]	,
["Pasnik",	209.71412	]	,
["Oleszko",	209.72545	]	,
["Marcol",	209.73678	]	,
["Wolicki",	209.7481	]	,
["Szybiak",	209.75942	]	,
["Ruszczak",	209.77074	]	,
["Machalski",	209.78206	]	,
["Kwapinski",	209.79338	]	,
["Zbroja",	209.80469	]	,
["Stosik",	209.816	]	,
["Gruchot",	209.82731	]	,
["Gladkowski",	209.83862	]	,
["Bos",	209.84993	]	,
["Wozniak",	209.86123	]	,
["Sibinski",	209.87253	]	,
["Gniewek",	209.88383	]	,
["Bulawa",	209.89513	]	,
["Wielogórski",	209.90642	]	,
["Wiatrak",	209.91771	]	,
["Talaska",	209.929	]	,
["Patalas",	209.94029	]	,
["Kwoka",	209.95158	]	,
["Krzempek",	209.96287	]	,
["Kolosowski",	209.97416	]	,
["Drzewinski",	209.98545	]	,
["Danilczuk",	209.99674	]	,
["Wazny",	210.00802	]	,
["Sidorczuk",	210.0193	]	,
["Miloszewski",	210.03058	]	,
["Legutko",	210.04186	]	,
["Kobos",	210.05314	]	,
["Domeracki",	210.06442	]	,
["Tylek",	210.07569	]	,
["Szkoda",	210.08696	]	,
["Przerwa",	210.09823	]	,
["Niski",	210.1095	]	,
["Linek",	210.12077	]	,
["Kuczewski",	210.13204	]	,
["Galik",	210.14331	]	,
["Dulewicz",	210.15458	]	,
["Drozda",	210.16585	]	,
["Bonislawski",	210.17712	]	,
["Nowek",	210.18838	]	,
["Matulewicz",	210.19964	]	,
["Karpeta",	210.2109	]	,
["Jurczuk",	210.22216	]	,
["Busko",	210.23342	]	,
["Slomian",	210.24467	]	,
["Drywa",	210.25592	]	,
["Rybus",	210.26716	]	,
["Langa",	210.2784	]	,
["Kluczek",	210.28964	]	,
["Wreczycki",	210.30087	]	,
["Paluchowski",	210.3121	]	,
["Pachulski",	210.32333	]	,
["Orkisz",	210.33456	]	,
["Lagodzinski",	210.34579	]	,
["Kalucki",	210.35702	]	,
["Brudnicki",	210.36825	]	,
["Gnatowski",	210.37947	]	,
["Gaworski",	210.39069	]	,
["Dominikowski",	210.40191	]	,
["Ziemkiewicz",	210.41312	]	,
["Siara",	210.42433	]	,
["Para",	210.43554	]	,
["Kwasek",	210.44675	]	,
["Kukowski",	210.45796	]	,
["Januszko",	210.46917	]	,
["Hejduk",	210.48038	]	,
["Luszczak",	210.49158	]	,
["Sprawka",	210.50277	]	,
["Zblewski",	210.51395	]	,
["Przybyslawski",	210.52513	]	,
["Korytowski",	210.53631	]	,
["Kielek",	210.54749	]	,
["Jop",	210.55867	]	,
["Faryna",	210.56985	]	,
["Zimon",	210.58102	]	,
["Utrata",	210.59219	]	,
["Radlinski",	210.60336	]	,
["Mirga",	210.61453	]	,
["Kozaczuk",	210.6257	]	,
["Wojtyna",	210.63686	]	,
["Rzonca",	210.64802	]	,
["Madejczyk",	210.65917	]	,
["Glapiak",	210.67032	]	,
["Dziadkowiec",	210.68147	]	,
["Ochnio",	210.69261	]	,
["Gawecki",	210.70375	]	,
["Sieja",	210.71488	]	,
["Malewicz",	210.72601	]	,
["Burczynski",	210.73714	]	,
["Bachanek",	210.74827	]	,
["Swiatlowski",	210.75939	]	,
["Mirocha",	210.77051	]	,
["Konopski",	210.78163	]	,
["Domzal",	210.79275	]	,
["Tworzydlo",	210.80386	]	,
["Strychalski",	210.81497	]	,
["Planeta",	210.82608	]	,
["Feret",	210.83719	]	,
["Witas",	210.84829	]	,
["Sekulski",	210.85939	]	,
["Popielarski",	210.87049	]	,
["Figat",	210.88159	]	,
["Muc",	210.89268	]	,
["Kuciel",	210.90377	]	,
["Kielan",	210.91486	]	,
["Halat",	210.92595	]	,
["Ziobrowski",	210.93703	]	,
["Teclaw",	210.94811	]	,
["Radkowski",	210.95919	]	,
["Loba",	210.97027	]	,
["Klucznik",	210.98135	]	,
["Bielas",	210.99242	]	,
["Rajczyk",	211.00348	]	,
["Myszak",	211.01454	]	,
["Muniak",	211.0256	]	,
["Michalczak",	211.03666	]	,
["Kochanowicz",	211.04772	]	,
["Zórawski",	211.05877	]	,
["Szoltysik",	211.06982	]	,
["Rychert",	211.08087	]	,
["Pyda",	211.09192	]	,
["Janowiak",	211.10297	]	,
["Janiga",	211.11402	]	,
["Gradziel",	211.12507	]	,
["Wdowczyk",	211.13611	]	,
["Pytlarz",	211.14715	]	,
["Kuzia",	211.15819	]	,
["Dziewa",	211.16923	]	,
["Bernatowicz",	211.18027	]	,
["Ostapiuk",	211.1913	]	,
["Tomicki",	211.20232	]	,
["Rejniak",	211.21334	]	,
["Marcinski",	211.22436	]	,
["Kotlarek",	211.23538	]	,
["Kosieradzki",	211.2464	]	,
["Gajownik",	211.25742	]	,
["Brach",	211.26844	]	,
["Tatarek",	211.27945	]	,
["Szyc",	211.29046	]	,
["Masny",	211.30147	]	,
["Krzyszkowski",	211.31248	]	,
["Drop",	211.32349	]	,
["Jaloszynski",	211.33449	]	,
["Saternus",	211.34548	]	,
["Podsiadla",	211.35647	]	,
["Patyna",	211.36746	]	,
["Kargol",	211.37845	]	,
["Truchan",	211.38943	]	,
["Pietrusiak",	211.40041	]	,
["Kolbusz",	211.41139	]	,
["Kalota",	211.42237	]	,
["Holubowicz",	211.43335	]	,
["Goscinski",	211.44433	]	,
["Andrzejuk",	211.45531	]	,
["Zdziech",	211.46628	]	,
["Szymonik",	211.47725	]	,
["Sych",	211.48822	]	,
["Strojna",	211.49919	]	,
["Seta",	211.51016	]	,
["Orman",	211.52113	]	,
["Hermanowicz",	211.5321	]	,
["Denkiewicz",	211.54307	]	,
["Bulanda",	211.55404	]	,
["Brocki",	211.56501	]	,
["Szwaja",	211.57597	]	,
["Makarski",	211.58693	]	,
["Jankowicz",	211.59789	]	,
["Pochopien",	211.60884	]	,
["Piechocinski",	211.61979	]	,
["Kobza",	211.63074	]	,
["Karwot",	211.64169	]	,
["Kalek",	211.65264	]	,
["Tylicki",	211.66358	]	,
["Laszuk",	211.67452	]	,
["Gesicki",	211.68546	]	,
["Aleksiejuk",	211.6964	]	,
["Witaszek",	211.70733	]	,
["Wawryniuk",	211.71826	]	,
["Sokulski",	211.72919	]	,
["Jacak",	211.74012	]	,
["Bugla",	211.75105	]	,
["Wejman",	211.76197	]	,
["Stobiecki",	211.77289	]	,
["Palucki",	211.78381	]	,
["Jaroch",	211.79473	]	,
["Janiszek",	211.80565	]	,
["Gorzelanczyk",	211.81657	]	,
["Zieja",	211.82748	]	,
["Medynski",	211.83839	]	,
["Krochmal",	211.8493	]	,
["Filas",	211.86021	]	,
["Wawrzynowicz",	211.87111	]	,
["Szalas",	211.88201	]	,
["Machon",	211.89291	]	,
["Labus",	211.90381	]	,
["Krawiecki",	211.91471	]	,
["Irzyk",	211.92561	]	,
["Gomula",	211.93651	]	,
["Wesoly",	211.9474	]	,
["Solarek",	211.95829	]	,
["Koska",	211.96918	]	,
["Dziamski",	211.98007	]	,
["Szymkowski",	211.99095	]	,
["Ratkowski",	212.00183	]	,
["Myszk",	212.01271	]	,
["Moryc",	212.02359	]	,
["Lizon",	212.03447	]	,
["Lesisz",	212.04535	]	,
["Kielbowicz",	212.05623	]	,
["Serwa",	212.0671	]	,
["Rylski",	212.07797	]	,
["Przezdziecki",	212.08884	]	,
["Piórek",	212.09971	]	,
["Majdak",	212.11058	]	,
["Bruzda",	212.12145	]	,
["Rzeszowski",	212.13231	]	,
["Przylucki",	212.14317	]	,
["Bakun",	212.15403	]	,
["Subocz",	212.16488	]	,
["Stypula",	212.17573	]	,
["Orlicki",	212.18658	]	,
["Okuniewski",	212.19743	]	,
["Golek",	212.20828	]	,
["Fik",	212.21913	]	,
["Bochynski",	212.22998	]	,
["Daszkowski",	212.24082	]	,
["Wolczyk",	212.25165	]	,
["Waniek",	212.26248	]	,
["Parzyszek",	212.27331	]	,
["Oszust",	212.28414	]	,
["Niebrzydowski",	212.29497	]	,
["Burza",	212.3058	]	,
["Zbik",	212.31662	]	,
["Misztela",	212.32744	]	,
["Kurant",	212.33826	]	,
["Drygas",	212.34908	]	,
["Bolewski",	212.3599	]	,
["Laciak",	212.37071	]	,
["Franczuk",	212.38152	]	,
["Rycerz",	212.39232	]	,
["Majdanski",	212.40312	]	,
["Maciorowski",	212.41392	]	,
["Grzymkowski",	212.42472	]	,
["Zok",	212.43551	]	,
["Zeman",	212.4463	]	,
["Mejer",	212.45709	]	,
["Kanarek",	212.46788	]	,
["Jedruch",	212.47867	]	,
["Saj",	212.48945	]	,
["Nieroda",	212.50023	]	,
["Juskiewicz",	212.51101	]	,
["Surdyk",	212.52178	]	,
["Paliga",	212.53255	]	,
["Makaruk",	212.54332	]	,
["Hamera",	212.55409	]	,
["Stoinski",	212.56485	]	,
["Przygocki",	212.57561	]	,
["Lukowicz",	212.58637	]	,
["Liberski",	212.59713	]	,
["Barcz",	212.60789	]	,
["Wronkowski",	212.61864	]	,
["Sibilski",	212.62939	]	,
["Chmielnicki",	212.64014	]	,
["Zmudzki",	212.65088	]	,
["Witos",	212.66161	]	,
["Trzebinski",	212.67234	]	,
["Strzelczak",	212.68307	]	,
["Siedlaczek",	212.6938	]	,
["Pakosz",	212.70453	]	,
["Mordarski",	212.71526	]	,
["Burchardt",	212.72599	]	,
["Prabucki",	212.73671	]	,
["Nurek",	212.74743	]	,
["Morys",	212.75815	]	,
["Korbel",	212.76887	]	,
["Kokosza",	212.77959	]	,
["Kijanka",	212.79031	]	,
["Bobak",	212.80103	]	,
["Samson",	212.81174	]	,
["Raniszewski",	212.82245	]	,
["Jarosiewicz",	212.83316	]	,
["Bielanski",	212.84387	]	,
["Szelest",	212.85457	]	,
["Stanislawek",	212.86527	]	,
["Potoczny",	212.87597	]	,
["Perka",	212.88667	]	,
["Ciepluch",	212.89737	]	,
["Bryja",	212.90807	]	,
["Swiatkiewicz",	212.91876	]	,
["Samul",	212.92945	]	,
["Rohde",	212.94014	]	,
["Prucnal",	212.95083	]	,
["Pomaranski",	212.96152	]	,
["Miszkiewicz",	212.97221	]	,
["Kuropatwa",	212.9829	]	,
["Kleczkowski",	212.99359	]	,
["Gajdzik",	213.00428	]	,
["Ciechanski",	213.01497	]	,
["Racki",	213.02565	]	,
["Niedzwiedzki",	213.03633	]	,
["Mucka",	213.04701	]	,
["Misiaszek",	213.05769	]	,
["Fornalik",	213.06837	]	,
["Chelchowski",	213.07905	]	,
["Zlotkowski",	213.08972	]	,
["Wiszowaty",	213.10039	]	,
["Thiel",	213.11106	]	,
["Pachucki",	213.12173	]	,
["Osiadacz",	213.1324	]	,
["Misko",	213.14307	]	,
["Mielcarz",	213.15374	]	,
["Drózd",	213.16441	]	,
["Zapolski",	213.17507	]	,
["Oleksiuk",	213.18573	]	,
["Matyka",	213.19639	]	,
["Lyczak",	213.20705	]	,
["Cabala",	213.21771	]	,
["Rydzynski",	213.22836	]	,
["Oska",	213.23901	]	,
["Beres",	213.24966	]	,
["Armatys",	213.26031	]	,
["Szmajda",	213.27095	]	,
["Seredynski",	213.28159	]	,
["Mlynczak",	213.29223	]	,
["Losinski",	213.30287	]	,
["Kupidura",	213.31351	]	,
["Kijas",	213.32415	]	,
["Chomiuk",	213.33479	]	,
["Szczucki",	213.34542	]	,
["Lopuszynski",	213.35605	]	,
["Gowin",	213.36668	]	,
["Dybka",	213.37731	]	,
["Bródka",	213.38794	]	,
["Wziatek",	213.39856	]	,
["Sleczka",	213.40918	]	,
["Skibniewski",	213.4198	]	,
["Koj",	213.43042	]	,
["Drabczyk",	213.44104	]	,
["Bulski",	213.45166	]	,
["Buczko",	213.46228	]	,
["Sawko",	213.47289	]	,
["Lubowiecki",	213.4835	]	,
["Kobielski",	213.49411	]	,
["Klysz",	213.50472	]	,
["Karpiel",	213.51533	]	,
["Jarczyk",	213.52594	]	,
["Flaga",	213.53655	]	,
["Fiedorczuk",	213.54716	]	,
["Bzowski",	213.55777	]	,
["Tomalak",	213.56837	]	,
["Nalecz",	213.57897	]	,
["Choros",	213.58957	]	,
["Branka",	213.60017	]	,
["Sienkowski",	213.61076	]	,
["Rajchel",	213.62135	]	,
["Malecki",	213.63194	]	,
["Kiedrowicz",	213.64253	]	,
["Gabka",	213.65312	]	,
["Fiolek",	213.66371	]	,
["Drozdowicz",	213.6743	]	,
["Stypa",	213.68488	]	,
["Kluszczynski",	213.69546	]	,
["Kawala",	213.70604	]	,
["Ciezkowski",	213.71662	]	,
["Mazanek",	213.72719	]	,
["Maleszewski",	213.73776	]	,
["Kwinta",	213.74833	]	,
["Koczy",	213.7589	]	,
["Hyzy",	213.76947	]	,
["Grzejszczak",	213.78004	]	,
["Augustynski",	213.79061	]	,
["Wywial",	213.80117	]	,
["Sledzinski",	213.81173	]	,
["Sacharczuk",	213.82229	]	,
["Rupinski",	213.83285	]	,
["Jaroszuk",	213.84341	]	,
["Golon",	213.85397	]	,
["Chachula",	213.86453	]	,
["Slomczewski",	213.87508	]	,
["Rozmarynowski",	213.88563	]	,
["Malarczyk",	213.89618	]	,
["Klodzinski",	213.90673	]	,
["Kawula",	213.91728	]	,
["Bohdanowicz",	213.92783	]	,
["Bartocha",	213.93838	]	,
["Musialski",	213.94892	]	,
["Lewko",	213.95946	]	,
["Igras",	213.97	]	,
["Damps",	213.98054	]	,
["Tlalka",	213.99107	]	,
["Niechcial",	214.0016	]	,
["Lyskawa",	214.01213	]	,
["Laszewski",	214.02266	]	,
["Gos",	214.03319	]	,
["Wieckiewicz",	214.04371	]	,
["Lesko",	214.05423	]	,
["Konsek",	214.06475	]	,
["Juszczuk",	214.07527	]	,
["Hermanowski",	214.08579	]	,
["Szczudlo",	214.0963	]	,
["Ramotowski",	214.10681	]	,
["Poniedzialek",	214.11732	]	,
["Palus",	214.12783	]	,
["Bodziony",	214.13834	]	,
["Smieszek",	214.14884	]	,
["Rej",	214.15934	]	,
["Pietryga",	214.16984	]	,
["Mieszala",	214.18034	]	,
["Malcher",	214.19084	]	,
["Kopij",	214.20134	]	,
["Kaczan",	214.21184	]	,
["Janasik",	214.22234	]	,
["Watras",	214.23283	]	,
["Stojak",	214.24332	]	,
["Loniewski",	214.25381	]	,
["Batorski",	214.2643	]	,
["Strzyz",	214.27478	]	,
["Siemieniec",	214.28526	]	,
["Rzewuski",	214.29574	]	,
["Masalski",	214.30622	]	,
["Kosnik",	214.3167	]	,
["Kosewski",	214.32718	]	,
["Kasperczak",	214.33766	]	,
["Bronowski",	214.34814	]	,
["Woszczyna",	214.35861	]	,
["Wiech",	214.36908	]	,
["Stefanik",	214.37955	]	,
["Miara",	214.39002	]	,
["Lodyga",	214.40049	]	,
["Felski",	214.41096	]	,
["Walo",	214.42142	]	,
["Stolowski",	214.43188	]	,
["Przychodzki",	214.44234	]	,
["Oleksiewicz",	214.4528	]	,
["Mainka",	214.46326	]	,
["Kolaczynski",	214.47372	]	,
["Baka",	214.48418	]	,
["Trybus",	214.49463	]	,
["Samol",	214.50508	]	,
["Kujawinski",	214.51553	]	,
["Klimecki",	214.52598	]	,
["Jamrozy",	214.53643	]	,
["Gruszczyk",	214.54688	]	,
["Deluga",	214.55733	]	,
["Stojanowski",	214.56777	]	,
["Kaluzinski",	214.57821	]	,
["Trzos",	214.58864	]	,
["Sinkiewicz",	214.59907	]	,
["Lesik",	214.6095	]	,
["Kroczak",	214.61993	]	,
["Klamka",	214.63036	]	,
["Grzelczyk",	214.64079	]	,
["Dycha",	214.65122	]	,
["Ciesielczyk",	214.66165	]	,
["Armata",	214.67208	]	,
["Wawrzyczek",	214.6825	]	,
["Prokopczyk",	214.69292	]	,
["Jablecki",	214.70334	]	,
["Hampel",	214.71376	]	,
["Grzech",	214.72418	]	,
["Grudniewski",	214.7346	]	,
["Brodacki",	214.74502	]	,
["Rzucidlo",	214.75543	]	,
["Ryszewski",	214.76584	]	,
["Rawa",	214.77625	]	,
["Krecisz",	214.78666	]	,
["Karys",	214.79707	]	,
["Weglowski",	214.80747	]	,
["Rodzen",	214.81787	]	,
["Karalus",	214.82827	]	,
["Mikosz",	214.83866	]	,
["Kazimierczuk",	214.84905	]	,
["Hajda",	214.85944	]	,
["Ciepielewski",	214.86983	]	,
["Berg",	214.88022	]	,
["Teper",	214.8906	]	,
["Slabosz",	214.90098	]	,
["Dziechciarz",	214.91136	]	,
["Dmoch",	214.92174	]	,
["Zarczynski",	214.93211	]	,
["Sleziak",	214.94248	]	,
["Pietrek",	214.95285	]	,
["Martyka",	214.96322	]	,
["Wolk",	214.97358	]	,
["Wolczynski",	214.98394	]	,
["Smetek",	214.9943	]	,
["Kroll",	215.00466	]	,
["Grab",	215.01502	]	,
["Dziedzina",	215.02538	]	,
["Perczynski",	215.03573	]	,
["Pawlinski",	215.04608	]	,
["Noszczyk",	215.05643	]	,
["Kazek",	215.06678	]	,
["Jedrusiak",	215.07713	]	,
["Cebo",	215.08748	]	,
["Tokarek",	215.09782	]	,
["Szulinski",	215.10816	]	,
["Malota",	215.1185	]	,
["Hanc",	215.12884	]	,
["Uliasz",	215.13917	]	,
["Skoczynski",	215.1495	]	,
["Pysz",	215.15983	]	,
["Pilka",	215.17015	]	,
["Karczynski",	215.18047	]	,
["Blaszyk",	215.19079	]	,
["Wyrobek",	215.2011	]	,
["Trybus",	215.21141	]	,
["Szlek",	215.22172	]	,
["Rzepczynski",	215.23203	]	,
["Ruszczynski",	215.24234	]	,
["Linowski",	215.25265	]	,
["Kalicinski",	215.26296	]	,
["Ziembinski",	215.27326	]	,
["Rzeznicki",	215.28356	]	,
["Rogalinski",	215.29386	]	,
["Pindor",	215.30416	]	,
["Luc",	215.31446	]	,
["Kabzinski",	215.32476	]	,
["Glapinski",	215.33506	]	,
["Baszak",	215.34536	]	,
["Oborski",	215.35565	]	,
["Majak",	215.36594	]	,
["Lój",	215.37623	]	,
["Helinski",	215.38652	]	,
["Szczypek",	215.3968	]	,
["Radlowski",	215.40708	]	,
["Luczkiewicz",	215.41736	]	,
["Laszcz",	215.42764	]	,
["Fron",	215.43792	]	,
["Dybas",	215.4482	]	,
["Chlewicki",	215.45848	]	,
["Budner",	215.46876	]	,
["Wielinski",	215.47903	]	,
["Ostasz",	215.4893	]	,
["Luczkowski",	215.49957	]	,
["Siekierka",	215.50983	]	,
["Pilipczuk",	215.52009	]	,
["Kandzia",	215.53035	]	,
["Gieron",	215.54061	]	,
["Drost",	215.55087	]	,
["Czachorowski",	215.56113	]	,
["Chwala",	215.57139	]	,
["Malesza",	215.58164	]	,
["Jesiolowski",	215.59189	]	,
["Fiedler",	215.60214	]	,
["Suszko",	215.61238	]	,
["Samulski",	215.62262	]	,
["Kurnik",	215.63286	]	,
["Korzeniecki",	215.6431	]	,
["Dzieniszewski",	215.65334	]	,
["Bereda",	215.66358	]	,
["Nalewajko",	215.67381	]	,
["Gabryszewski",	215.68404	]	,
["Duczmal",	215.69427	]	,
["Stanecki",	215.70449	]	,
["Sieradzan",	215.71471	]	,
["Poniewierski",	215.72493	]	,
["Pietrasz",	215.73515	]	,
["Cecot",	215.74537	]	,
["Tomaszkiewicz",	215.75558	]	,
["Rudkowski",	215.76579	]	,
["Rabiej",	215.776	]	,
["Staniaszek",	215.7862	]	,
["Mikusek",	215.7964	]	,
["Kurylowicz",	215.8066	]	,
["Herda",	215.8168	]	,
["Brzykcy",	215.827	]	,
["Wietrzykowski",	215.83719	]	,
["Poczatek",	215.84738	]	,
["Pierzchalski",	215.85757	]	,
["Ochal",	215.86776	]	,
["Koral",	215.87795	]	,
["Kazmierczyk",	215.88814	]	,
["Kandziora",	215.89833	]	,
["Sycz",	215.90851	]	,
["Stopinski",	215.91869	]	,
["Reich",	215.92887	]	,
["Lindner",	215.93905	]	,
["Fulara",	215.94923	]	,
["Dragowski",	215.95941	]	,
["Przybycien",	215.96958	]	,
["Hermann",	215.97975	]	,
["Glówczynski",	215.98992	]	,
["Forysiak",	216.00009	]	,
["Damiecki",	216.01026	]	,
["Strzepek",	216.02042	]	,
["Sondej",	216.03058	]	,
["Pyc",	216.04074	]	,
["Piascik",	216.0509	]	,
["Grygo",	216.06106	]	,
["Wita",	216.07121	]	,
["Szynkiewicz",	216.08136	]	,
["Piesik",	216.09151	]	,
["Pacanowski",	216.10166	]	,
["Nasiadka",	216.11181	]	,
["Murach",	216.12196	]	,
["Modelski",	216.13211	]	,
["Kostro",	216.14226	]	,
["Konicki",	216.15241	]	,
["Hinca",	216.16256	]	,
["Engler",	216.17271	]	,
["Zawlocki",	216.18285	]	,
["Tulacz",	216.19299	]	,
["Skórzewski",	216.20313	]	,
["Przewozny",	216.21327	]	,
["Pizon",	216.22341	]	,
["Lapacz",	216.23355	]	,
["Hajduga",	216.24369	]	,
["Bulczak",	216.25383	]	,
["Bubel",	216.26397	]	,
["Smutek",	216.2741	]	,
["Samoraj",	216.28423	]	,
["Plaskota",	216.29436	]	,
["Kurczynski",	216.30449	]	,
["Fras",	216.31462	]	,
["Becker",	216.32475	]	,
["Baranowicz",	216.33488	]	,
["Trznadel",	216.345	]	,
["Topa",	216.35512	]	,
["Stanislawczyk",	216.36524	]	,
["Lato",	216.37536	]	,
["Kolton",	216.38548	]	,
["Bobkowski",	216.3956	]	,
["Uryga",	216.40571	]	,
["Tomaszczyk",	216.41582	]	,
["Szymanik",	216.42593	]	,
["Sztukowski",	216.43604	]	,
["Stochmal",	216.44615	]	,
["Lubieniecki",	216.45626	]	,
["Kiszczak",	216.46637	]	,
["Kilanowski",	216.47648	]	,
["Chowanski",	216.48659	]	,
["Dylong",	216.49669	]	,
["Chruszcz",	216.50679	]	,
["Byra",	216.51689	]	,
["Gostomski",	216.52698	]	,
["Friedrich",	216.53707	]	,
["Cyganik",	216.54716	]	,
["Pacocha",	216.55724	]	,
["Jonczyk",	216.56732	]	,
["Warzynski",	216.57739	]	,
["Szymanczyk",	216.58746	]	,
["Sanocki",	216.59752	]	,
["Radko",	216.60758	]	,
["Meler",	216.61764	]	,
["Kuran",	216.6277	]	,
["Koman",	216.63776	]	,
["Bladek",	216.64782	]	,
["Banachowicz",	216.65788	]	,
["Babiuch",	216.66794	]	,
["Witulski",	216.67799	]	,
["Michalczewski",	216.68804	]	,
["Magierski",	216.69809	]	,
["Kruszka",	216.70814	]	,
["Groszewski",	216.71819	]	,
["Fijol",	216.72824	]	,
["Tyrakowski",	216.73828	]	,
["Rynski",	216.74832	]	,
["Kwasiborski",	216.75836	]	,
["Kempski",	216.7684	]	,
["Dzieciolowski",	216.77844	]	,
["Zaton",	216.78847	]	,
["Wlodarz",	216.7985	]	,
["Trepka",	216.80853	]	,
["Swierszcz",	216.81856	]	,
["Strzala",	216.82859	]	,
["Opiola",	216.83862	]	,
["Kursa",	216.84865	]	,
["Krowicki",	216.85868	]	,
["Dys",	216.86871	]	,
["Bros",	216.87874	]	,
["Tyka",	216.88876	]	,
["Syroka",	216.89878	]	,
["Grys",	216.9088	]	,
["Szczepaniuk",	216.91881	]	,
["Marcinczyk",	216.92882	]	,
["Leks",	216.93883	]	,
["Kubina",	216.94884	]	,
["Janke",	216.95885	]	,
["Dabrowicz",	216.96886	]	,
["Hulbój",	216.97886	]	,
["Cieciura",	216.98886	]	,
["Chochól",	216.99886	]	,
["Zlobinski",	217.00885	]	,
["Wasniowski",	217.01884	]	,
["Szpila",	217.02883	]	,
["Samiec",	217.03882	]	,
["Rduch",	217.04881	]	,
["Nabialek",	217.0588	]	,
["Margol",	217.06879	]	,
["Kopa",	217.07878	]	,
["Engel",	217.08877	]	,
["Czerepak",	217.09876	]	,
["Solski",	217.10874	]	,
["Roslon",	217.11872	]	,
["Pusz",	217.1287	]	,
["Matla",	217.13868	]	,
["Krainski",	217.14866	]	,
["Klimowski",	217.15864	]	,
["Dominski",	217.16862	]	,
["Woloch",	217.17859	]	,
["Pazik",	217.18856	]	,
["Nazimek",	217.19853	]	,
["Kuska",	217.2085	]	,
["Karczmarz",	217.21847	]	,
["Gajzler",	217.22844	]	,
["Slawik",	217.2384	]	,
["Siudzinski",	217.24836	]	,
["Saganowski",	217.25832	]	,
["Lalak",	217.26828	]	,
["Jasionowski",	217.27824	]	,
["Grabias",	217.2882	]	,
["Gagala",	217.29816	]	,
["Chwedoruk",	217.30812	]	,
["Wasil",	217.31807	]	,
["Smolarski",	217.32802	]	,
["Próchnicki",	217.33797	]	,
["Pacholek",	217.34792	]	,
["Wosinski",	217.35786	]	,
["Wichlacz",	217.3678	]	,
["Walentynowicz",	217.37774	]	,
["Tylus",	217.38768	]	,
["Pradzynski",	217.39762	]	,
["Kosz",	217.40756	]	,
["Iwanow",	217.4175	]	,
["Garczarek",	217.42744	]	,
["Gadowski",	217.43738	]	,
["Dorociak",	217.44732	]	,
["Czyzycki",	217.45726	]	,
["Boguta",	217.4672	]	,
["Betka",	217.47714	]	,
["Widuch",	217.48707	]	,
["Wawrzynek",	217.497	]	,
["Slubowski",	217.50693	]	,
["Szymajda",	217.51686	]	,
["Stanaszek",	217.52679	]	,
["Rychlinski",	217.53672	]	,
["Plominski",	217.54665	]	,
["Klama",	217.55658	]	,
["Goj",	217.56651	]	,
["Dzierzak",	217.57644	]	,
["Bylinski",	217.58637	]	,
["Barwicki",	217.5963	]	,
["Winkowski",	217.60622	]	,
["Walasik",	217.61614	]	,
["Skwira",	217.62606	]	,
["Skwierawski",	217.63598	]	,
["Luks",	217.6459	]	,
["Kujawiak",	217.65582	]	,
["Gardocki",	217.66574	]	,
["Dworczak",	217.67566	]	,
["Tofil",	217.68557	]	,
["Smardzewski",	217.69548	]	,
["Rurarz",	217.70539	]	,
["Radzimski",	217.7153	]	,
["Puczynski",	217.72521	]	,
["Pachla",	217.73512	]	,
["Lenarcik",	217.74503	]	,
["Kusztal",	217.75494	]	,
["Kukawski",	217.76485	]	,
["Chaber",	217.77476	]	,
["Skala",	217.78466	]	,
["Radzewicz",	217.79456	]	,
["Kramer",	217.80446	]	,
["Kochel",	217.81436	]	,
["Dukat",	217.82426	]	,
["Naglik",	217.83415	]	,
["Zapalski",	217.84403	]	,
["Szurek",	217.85391	]	,
["Raciborski",	217.86379	]	,
["Pietrusinski",	217.87367	]	,
["Litwiniuk",	217.88355	]	,
["Halama",	217.89343	]	,
["Grzela",	217.90331	]	,
["Wojaczek",	217.91318	]	,
["Popielarczyk",	217.92305	]	,
["Krzywanski",	217.93292	]	,
["Krysik",	217.94279	]	,
["Dawidczyk",	217.95266	]	,
["Barteczko",	217.96253	]	,
["Balik",	217.9724	]	,
["Warych",	217.98226	]	,
["Miodek",	217.99212	]	,
["Smialkowski",	218.00197	]	,
["Madera",	218.01182	]	,
["Lapczynski",	218.02167	]	,
["Leszczyk",	218.03152	]	,
["Kuszewski",	218.04137	]	,
["Kotynski",	218.05122	]	,
["Kolanek",	218.06107	]	,
["Fijak",	218.07092	]	,
["Wolynski",	218.08076	]	,
["Szwajkowski",	218.0906	]	,
["Furgala",	218.10044	]	,
["Faruga",	218.11028	]	,
["Czerkawski",	218.12012	]	,
["Swirski",	218.12995	]	,
["Slomkowski",	218.13978	]	,
["Skoneczny",	218.14961	]	,
["Rolbiecki",	218.15944	]	,
["Poleszak",	218.16927	]	,
["Kusek",	218.1791	]	,
["Kosobucki",	218.18893	]	,
["Herok",	218.19876	]	,
["Grabowiecki",	218.20859	]	,
["Golda",	218.21842	]	,
["Wnukowski",	218.22824	]	,
["Rymarz",	218.23806	]	,
["Roznowski",	218.24788	]	,
["Pociask",	218.2577	]	,
["Pietruszynski",	218.26752	]	,
["Milczarski",	218.27734	]	,
["Kowalak",	218.28716	]	,
["Czupryna",	218.29698	]	,
["Trzcionka",	218.30679	]	,
["Szulecki",	218.3166	]	,
["Sulik",	218.32641	]	,
["Matulka",	218.33622	]	,
["Herbut",	218.34603	]	,
["Grosicki",	218.35584	]	,
["Sujkowski",	218.36564	]	,
["Stosio",	218.37544	]	,
["Sarnacki",	218.38524	]	,
["Pokojski",	218.39504	]	,
["Kurtyka",	218.40484	]	,
["Ciuk",	218.41464	]	,
["Szczerbiak",	218.42443	]	,
["Snoch",	218.43422	]	,
["Podwysocki",	218.44401	]	,
["Gajkowski",	218.4538	]	,
["Budniak",	218.46359	]	,
["Boruc",	218.47338	]	,
["Tylka",	218.48316	]	,
["Soroczynski",	218.49294	]	,
["Plawski",	218.50272	]	,
["Kwak",	218.5125	]	,
["Garncarz",	218.52228	]	,
["Szuta",	218.53205	]	,
["Miskowiec",	218.54182	]	,
["Kruszelnicki",	218.55159	]	,
["Szczyglowski",	218.56135	]	,
["Sykut",	218.57111	]	,
["Rózynski",	218.58087	]	,
["Nowaczewski",	218.59063	]	,
["Jarosik",	218.60039	]	,
["Golus",	218.61015	]	,
["Chmielak",	218.61991	]	,
["Betlejewski",	218.62967	]	,
["Abramczuk",	218.63943	]	,
["Swietlicki",	218.64918	]	,
["Skrobek",	218.65893	]	,
["Ruczynski",	218.66868	]	,
["Patrzalek",	218.67843	]	,
["Palkowski",	218.68818	]	,
["Linkiewicz",	218.69793	]	,
["Karnowski",	218.70768	]	,
["Jereczek",	218.71743	]	,
["Jarema",	218.72718	]	,
["Glanowski",	218.73693	]	,
["Flasza",	218.74668	]	,
["Fiedoruk",	218.75643	]	,
["Budkiewicz",	218.76618	]	,
["Swigon",	218.77592	]	,
["Przewoznik",	218.78566	]	,
["Parada",	218.7954	]	,
["Paciorkowski",	218.80514	]	,
["Kluczewski",	218.81488	]	,
["Heller",	218.82462	]	,
["Gierak",	218.83436	]	,
["Ferdyn",	218.8441	]	,
["Sumera",	218.85383	]	,
["Muszalski",	218.86356	]	,
["Bik",	218.87329	]	,
["Kamela",	218.88301	]	,
["Ciereszko",	218.89273	]	,
["Balewski",	218.90245	]	,
["Switaj",	218.91216	]	,
["Slupecki",	218.92187	]	,
["Popielski",	218.93158	]	,
["Pastuszko",	218.94129	]	,
["Obrycki",	218.951	]	,
["Lobacz",	218.96071	]	,
["Kuba",	218.97042	]	,
["Krzywonos",	218.98013	]	,
["Granat",	218.98984	]	,
["Gawinski",	218.99955	]	,
["Wojtaszewski",	219.00925	]	,
["Szóstak",	219.01895	]	,
["Serafinski",	219.02865	]	,
["Ploskonka",	219.03835	]	,
["Kumorek",	219.04805	]	,
["Komuda",	219.05775	]	,
["Klinkosz",	219.06745	]	,
["Jaronski",	219.07715	]	,
["Falba",	219.08685	]	,
["Byczynski",	219.09655	]	,
["Szczechowicz",	219.10624	]	,
["Rozum",	219.11593	]	,
["Pucilowski",	219.12562	]	,
["Moron",	219.13531	]	,
["Matynia",	219.145	]	,
["Greszta",	219.15469	]	,
["Gawlinski",	219.16438	]	,
["Zimowski",	219.17406	]	,
["Luczka",	219.18374	]	,
["Krzymowski",	219.19342	]	,
["Dziewit",	219.2031	]	,
["Mueller",	219.21277	]	,
["Kazmierowski",	219.22244	]	,
["Kapral",	219.23211	]	,
["Hrynkiewicz",	219.24178	]	,
["Gonsior",	219.25145	]	,
["Forma",	219.26112	]	,
["Ciesiólka",	219.27079	]	,
["Bors",	219.28046	]	,
["Siwa",	219.29012	]	,
["Niemczuk",	219.29978	]	,
["Nicinski",	219.30944	]	,
["Nazar",	219.3191	]	,
["Liskiewicz",	219.32876	]	,
["Jarczak",	219.33842	]	,
["Felisiak",	219.34808	]	,
["Fedorczyk",	219.35774	]	,
["Wilusz",	219.36739	]	,
["Stronski",	219.37704	]	,
["Pastor",	219.38669	]	,
["Gierek",	219.39634	]	,
["Boguski",	219.40599	]	,
["Romaniak",	219.41563	]	,
["Oleszczak",	219.42527	]	,
["Juras",	219.43491	]	,
["Zachwieja",	219.44454	]	,
["Szmurlo",	219.45417	]	,
["Smektala",	219.4638	]	,
["Przewozna",	219.47343	]	,
["Nikel",	219.48306	]	,
["Chlebek",	219.49269	]	,
["Balas",	219.50232	]	,
["Latuszek",	219.51194	]	,
["Ambrozik",	219.52156	]	,
["Zielski",	219.53117	]	,
["Palaszewski",	219.54078	]	,
["Janczura",	219.55039	]	,
["Gosiewski",	219.56	]	,
["Aleksandrzak",	219.56961	]	,
["Wojtalik",	219.57921	]	,
["Trawczynski",	219.58881	]	,
["Rok",	219.59841	]	,
["Puzdrowski",	219.60801	]	,
["Nagórka",	219.61761	]	,
["Lebiedzinski",	219.62721	]	,
["Latoszek",	219.63681	]	,
["Kupski",	219.64641	]	,
["Kubowicz",	219.65601	]	,
["Domian",	219.66561	]	,
["Ciemiega",	219.67521	]	,
["Soliwoda",	219.6848	]	,
["Komsta",	219.69439	]	,
["Iwicki",	219.70398	]	,
["Goliszewski",	219.71357	]	,
["Filus",	219.72316	]	,
["Wierzchon",	219.73274	]	,
["Skotarczak",	219.74232	]	,
["Czubacki",	219.7519	]	,
["Chlopecki",	219.76148	]	,
["Cader",	219.77106	]	,
["Trzmiel",	219.78063	]	,
["Jamiolkowski",	219.7902	]	,
["Jagielo",	219.79976	]	,
["Witwicki",	219.80931	]	,
["Wawszczyk",	219.81886	]	,
["Troc",	219.82841	]	,
["Swatek",	219.83796	]	,
["Baczkiewicz",	219.84751	]	,
["Ulewicz",	219.85705	]	,
["Tutka",	219.86659	]	,
["Podemski",	219.87613	]	,
["Palac",	219.88567	]	,
["Paczuski",	219.89521	]	,
["Mydlarz",	219.90475	]	,
["Molka",	219.91429	]	,
["Janiuk",	219.92383	]	,
["Guziak",	219.93337	]	,
["Frycz",	219.94291	]	,
["Drzal",	219.95245	]	,
["Zacharek",	219.96198	]	,
["Wiencek",	219.97151	]	,
["Szlapka",	219.98104	]	,
["Kurach",	219.99057	]	,
["Bareja",	220.0001	]	,
["Pawlukiewicz",	220.00962	]	,
["Mozdzierz",	220.01914	]	,
["Mich",	220.02866	]	,
["Lisik",	220.03818	]	,
["Komosinski",	220.0477	]	,
["Kalwa",	220.05722	]	,
["Dadej",	220.06674	]	,
["Szajkowski",	220.07625	]	,
["Matela",	220.08576	]	,
["Lenda",	220.09527	]	,
["Grochowalski",	220.10478	]	,
["Foltynski",	220.11429	]	,
["Wolff",	220.12379	]	,
["Wojnicz",	220.13329	]	,
["Peczkowski",	220.14279	]	,
["Dziwinski",	220.15229	]	,
["Terlikowski",	220.16178	]	,
["Sendor",	220.17127	]	,
["Mrózek",	220.18076	]	,
["Lagiewka",	220.19025	]	,
["Kulisz",	220.19974	]	,
["Kolarz",	220.20923	]	,
["Walus",	220.21871	]	,
["Mikoda",	220.22819	]	,
["Kral",	220.23767	]	,
["Kawinski",	220.24715	]	,
["Grobelski",	220.25663	]	,
["Darul",	220.26611	]	,
["Brylowski",	220.27559	]	,
["Warczak",	220.28506	]	,
["Pietrzycki",	220.29453	]	,
["Kunysz",	220.304	]	,
["Kidon",	220.31347	]	,
["Kanigowski",	220.32294	]	,
["Ciula",	220.33241	]	,
["Chomiak",	220.34188	]	,
["Rzezniczak",	220.35134	]	,
["Przenioslo",	220.3608	]	,
["Orchowski",	220.37026	]	,
["Chomik",	220.37972	]	,
["Zimolag",	220.38917	]	,
["Wojtys",	220.39862	]	,
["Polski",	220.40807	]	,
["Medrala",	220.41752	]	,
["Hennig",	220.42697	]	,
["Handzel",	220.43642	]	,
["Twardzik",	220.44586	]	,
["Smieja",	220.4553	]	,
["Solarczyk",	220.46474	]	,
["Mendak",	220.47418	]	,
["Lemieszek",	220.48362	]	,
["Kiryluk",	220.49306	]	,
["Wrzesniak",	220.50249	]	,
["Targowski",	220.51192	]	,
["Kwarciak",	220.52135	]	,
["Jezowski",	220.53078	]	,
["Gasik",	220.54021	]	,
["Fajkowski",	220.54964	]	,
["Borysiewicz",	220.55907	]	,
["Sierota",	220.56849	]	,
["Mysiak",	220.57791	]	,
["Kraszkiewicz",	220.58733	]	,
["Hyjek",	220.59675	]	,
["Wiechowski",	220.60616	]	,
["Troczynski",	220.61557	]	,
["Sniegocki",	220.62498	]	,
["Polaszek",	220.63439	]	,
["Pazera",	220.6438	]	,
["Nadrowski",	220.65321	]	,
["Lichocki",	220.66262	]	,
["Kubisz",	220.67203	]	,
["Kosciukiewicz",	220.68144	]	,
["Kopczyk",	220.69085	]	,
["Kliber",	220.70026	]	,
["Kaczmar",	220.70967	]	,
["Kaczka",	220.71908	]	,
["Bicz",	220.72849	]	,
["Augustynek",	220.7379	]	,
["Straszak",	220.7473	]	,
["Sajewicz",	220.7567	]	,
["Glanc",	220.7661	]	,
["Bzymek",	220.7755	]	,
["Zieniewicz",	220.78489	]	,
["Pagacz",	220.79428	]	,
["Kulbacki",	220.80367	]	,
["Gortat",	220.81306	]	,
["Fornalski",	220.82245	]	,
["Buraczewski",	220.83184	]	,
["Bubak",	220.84123	]	,
["Warwas",	220.85061	]	,
["Skoneczna",	220.85999	]	,
["Nestorowicz",	220.86937	]	,
["Dziopa",	220.87875	]	,
["Danisz",	220.88813	]	,
["Bazydlo",	220.89751	]	,
["Jaranowski",	220.90688	]	,
["Garncarek",	220.91625	]	,
["Albin",	220.92562	]	,
["Szeszko",	220.93498	]	,
["Naczk",	220.94434	]	,
["Lukowiak",	220.9537	]	,
["Kurpinski",	220.96306	]	,
["Kopciuch",	220.97242	]	,
["Jakoniuk",	220.98178	]	,
["Wegrzynowicz",	220.99113	]	,
["Walencik",	221.00048	]	,
["Turlej",	221.00983	]	,
["Tomczynski",	221.01918	]	,
["Leonowicz",	221.02853	]	,
["Szkodzinski",	221.03787	]	,
["Kierepka",	221.04721	]	,
["Hendzel",	221.05655	]	,
["Fronczek",	221.06589	]	,
["Zarzeczna",	221.07522	]	,
["Zagrodnik",	221.08455	]	,
["Walesa",	221.09388	]	,
["Trzepizur",	221.10321	]	,
["Tereszkiewicz",	221.11254	]	,
["Szczubelek",	221.12187	]	,
["Magier",	221.1312	]	,
["Dzialo",	221.14053	]	,
["Drygala",	221.14986	]	,
["Czesak",	221.15919	]	,
["Branski",	221.16852	]	,
["Zglinski",	221.17784	]	,
["Zawidzki",	221.18716	]	,
["Majorek",	221.19648	]	,
["Kuskowski",	221.2058	]	,
["Kopycki",	221.21512	]	,
["Grzelinski",	221.22444	]	,
["Zaczynski",	221.23375	]	,
["Wlizlo",	221.24306	]	,
["Skutnik",	221.25237	]	,
["Radke",	221.26168	]	,
["Piatkiewicz",	221.27099	]	,
["Pajaczkowski",	221.2803	]	,
["Otwinowski",	221.28961	]	,
["Oslizlo",	221.29892	]	,
["Kokosinski",	221.30823	]	,
["Kansy",	221.31754	]	,
["Szela",	221.32684	]	,
["Mol",	221.33614	]	,
["Mlotkowski",	221.34544	]	,
["Kuswik",	221.35474	]	,
["Kowalinski",	221.36404	]	,
["Karpik",	221.37334	]	,
["Janczarek",	221.38264	]	,
["Hajdukiewicz",	221.39194	]	,
["Woszczynski",	221.40123	]	,
["Skwarski",	221.41052	]	,
["Pomianowski",	221.41981	]	,
["Niezabitowski",	221.4291	]	,
["Mzyk",	221.43839	]	,
["Kostera",	221.44768	]	,
["Leszkiewicz",	221.45696	]	,
["Hutnik",	221.46624	]	,
["Glaza",	221.47552	]	,
["Fydrych",	221.4848	]	,
["Swieczkowski",	221.49407	]	,
["Piegza",	221.50334	]	,
["Matusewicz",	221.51261	]	,
["Matus",	221.52188	]	,
["Kluczyk",	221.53115	]	,
["Jaczynski",	221.54042	]	,
["Drobnik",	221.54969	]	,
["Polom",	221.55895	]	,
["Okraska",	221.56821	]	,
["Neska",	221.57747	]	,
["Kozlowicz",	221.58673	]	,
["Brodnicki",	221.59599	]	,
["Wolos",	221.60524	]	,
["Waclawczyk",	221.61449	]	,
["Ochnik",	221.62374	]	,
["Maruszczak",	221.63299	]	,
["Lesner",	221.64224	]	,
["Leciejewski",	221.65149	]	,
["Kuncewicz",	221.66074	]	,
["Kobusinski",	221.66999	]	,
["Kieszek",	221.67924	]	,
["Felinski",	221.68849	]	,
["Betlej",	221.69774	]	,
["Waldoch",	221.70698	]	,
["Szarejko",	221.71622	]	,
["Smalec",	221.72546	]	,
["Losiewicz",	221.7347	]	,
["Lisak",	221.74394	]	,
["Arendarski",	221.75318	]	,
["Zietarski",	221.76241	]	,
["Wietrzynski",	221.77164	]	,
["Walkusz",	221.78087	]	,
["Skrzynecki",	221.7901	]	,
["Owsiak",	221.79933	]	,
["Kowaluk",	221.80856	]	,
["Simon",	221.81778	]	,
["Rydlewski",	221.827	]	,
["Rup",	221.83622	]	,
["Plocki",	221.84544	]	,
["Neubauer",	221.85466	]	,
["Muskala",	221.86388	]	,
["Mossakowski",	221.8731	]	,
["Kucharzyk",	221.88232	]	,
["Gabryel",	221.89154	]	,
["Chaberski",	221.90076	]	,
["Bujanowski",	221.90998	]	,
["Zimniak",	221.91919	]	,
["Warmuz",	221.9284	]	,
["Opas",	221.93761	]	,
["Michniak",	221.94682	]	,
["Kucharzewski",	221.95603	]	,
["Cieloch",	221.96524	]	,
["Wójcikiewicz",	221.97444	]	,
["Swiech",	221.98364	]	,
["Szepietowski",	221.99284	]	,
["Powierza",	222.00204	]	,
["Olko",	222.01124	]	,
["Miekus",	222.02044	]	,
["Lasinski",	222.02964	]	,
["Kutnik",	222.03884	]	,
["Kustosz",	222.04804	]	,
["Kochman",	222.05724	]	,
["Gawlikowski",	222.06644	]	,
["Trabka",	222.07563	]	,
["Szyja",	222.08482	]	,
["Skapski",	222.09401	]	,
["Mlynarz",	222.1032	]	,
["Laczkowski",	222.11239	]	,
["Wojtak",	222.12157	]	,
["Paslawski",	222.13075	]	,
["Galezowski",	222.13993	]	,
["Dzierwa",	222.14911	]	,
["Zygula",	222.15828	]	,
["Taciak",	222.16745	]	,
["Koziatek",	222.17662	]	,
["Koss",	222.18579	]	,
["Walenciak",	222.19495	]	,
["Twardosz",	222.20411	]	,
["Pakos",	222.21327	]	,
["Mezynski",	222.22243	]	,
["Mamcarz",	222.23159	]	,
["Burzawa",	222.24075	]	,
["Szczeblewski",	222.2499	]	,
["Otrebski",	222.25905	]	,
["Lenik",	222.2682	]	,
["Gronkowski",	222.27735	]	,
["Franc",	222.2865	]	,
["Sierocinski",	222.29564	]	,
["Sadza",	222.30478	]	,
["Ochocinski",	222.31392	]	,
["Michniewski",	222.32306	]	,
["Madrzak",	222.3322	]	,
["Mak",	222.34134	]	,
["Bobel",	222.35048	]	,
["Szajna",	222.35961	]	,
["Pruchnicki",	222.36874	]	,
["Proch",	222.37787	]	,
["Niemczewski",	222.387	]	,
["Koszowski",	222.39613	]	,
["Kosela",	222.40526	]	,
["Guzniczak",	222.41439	]	,
["Stemplewski",	222.42351	]	,
["Radziewicz",	222.43263	]	,
["Olchawa",	222.44175	]	,
["Morcinek",	222.45087	]	,
["Darowski",	222.45999	]	,
["Bugalski",	222.46911	]	,
["Bastek",	222.47823	]	,
["Ragan",	222.48734	]	,
["Podeszwa",	222.49645	]	,
["Mitek",	222.50556	]	,
["Mirkowski",	222.51467	]	,
["Janoszka",	222.52378	]	,
["Stolinski",	222.53288	]	,
["Slaba",	222.54198	]	,
["Rusnak",	222.55108	]	,
["Plócienniczak",	222.56018	]	,
["Krzykawski",	222.56928	]	,
["Hanke",	222.57838	]	,
["Gronski",	222.58748	]	,
["Gosek",	222.59658	]	,
["Wujek",	222.60567	]	,
["Warchal",	222.61476	]	,
["Starzak",	222.62385	]	,
["Przedpelski",	222.63294	]	,
["Prochownik",	222.64203	]	,
["Molak",	222.65112	]	,
["Imielski",	222.66021	]	,
["Duszkiewicz",	222.6693	]	,
["Sztaba",	222.67838	]	,
["Stasiowski",	222.68746	]	,
["Piwek",	222.69654	]	,
["Nowotnik",	222.70562	]	,
["Kiljan",	222.7147	]	,
["Dusinski",	222.72378	]	,
["Dubel",	222.73286	]	,
["Brodowicz",	222.74194	]	,
["Tylec",	222.75101	]	,
["Pik",	222.76008	]	,
["Pastucha",	222.76915	]	,
["Ksiezak",	222.77822	]	,
["Krzywinski",	222.78729	]	,
["Gumieniak",	222.79636	]	,
["Winczewski",	222.80542	]	,
["Ufnal",	222.81448	]	,
["Stawinoga",	222.82354	]	,
["Slon",	222.8326	]	,
["Kolarczyk",	222.84166	]	,
["John",	222.85072	]	,
["Fleszar",	222.85978	]	,
["Filinski",	222.86884	]	,
["Potrzebowski",	222.87789	]	,
["Palicki",	222.88694	]	,
["Lemke",	222.89599	]	,
["Kurc",	222.90504	]	,
["Kamieniarz",	222.91409	]	,
["Jaskóla",	222.92314	]	,
["Jaremko",	222.93219	]	,
["Gogacz",	222.94124	]	,
["Dudala",	222.95029	]	,
["Chlipala",	222.95934	]	,
["Berkowski",	222.96839	]	,
["Zycinski",	222.97743	]	,
["Walendowski",	222.98647	]	,
["Szlapa",	222.99551	]	,
["Seidel",	223.00455	]	,
["Kopyt",	223.01359	]	,
["Karlowicz",	223.02263	]	,
["Gebura",	223.03167	]	,
["Fraczkiewicz",	223.04071	]	,
["Frankowicz",	223.04975	]	,
["Dybiec",	223.05879	]	,
["Drobny",	223.06783	]	,
["Brózda",	223.07687	]	,
["Borun",	223.08591	]	,
["Pelka",	223.09494	]	,
["Macias",	223.10397	]	,
["Lobocki",	223.113	]	,
["Czerniakowski",	223.12203	]	,
["Ruszel",	223.13105	]	,
["Pabis",	223.14007	]	,
["Lipkowski",	223.14909	]	,
["Krefta",	223.15811	]	,
["Ducki",	223.16713	]	,
["Cwierz",	223.17615	]	,
["Bielen",	223.18517	]	,
["Szyca",	223.19418	]	,
["Przesmycki",	223.20319	]	,
["Pronobis",	223.2122	]	,
["Pietrykowski",	223.22121	]	,
["Dreszer",	223.23022	]	,
["Bryzek",	223.23923	]	,
["Ambrozewicz",	223.24824	]	,
["Slobodzian",	223.25724	]	,
["Mrozowicz",	223.26624	]	,
["Jusinski",	223.27524	]	,
["Jaskowski",	223.28424	]	,
["Dumanski",	223.29324	]	,
["Wojak",	223.30223	]	,
["Szklarek",	223.31122	]	,
["Slugocki",	223.32021	]	,
["Paw",	223.3292	]	,
["Okninski",	223.33819	]	,
["Koscielak",	223.34718	]	,
["Kalarus",	223.35617	]	,
["Zarzeczny",	223.36515	]	,
["Wylegala",	223.37413	]	,
["Powazka",	223.38311	]	,
["Mlot",	223.39209	]	,
["Krekora",	223.40107	]	,
["Bilewicz",	223.41005	]	,
["Pyszka",	223.41902	]	,
["Niedzwiadek",	223.42799	]	,
["Lubera",	223.43696	]	,
["Chodak",	223.44593	]	,
["Bregula",	223.4549	]	,
["Synak",	223.46386	]	,
["Supel",	223.47282	]	,
["Suda",	223.48178	]	,
["Roczniak",	223.49074	]	,
["Matuszyk",	223.4997	]	,
["Kazanecki",	223.50866	]	,
["Helak",	223.51762	]	,
["Gubernat",	223.52658	]	,
["Wojtera",	223.53553	]	,
["Wiszowata",	223.54448	]	,
["Swieton",	223.55343	]	,
["Rzeszotarski",	223.56238	]	,
["Ilski",	223.57133	]	,
["Derylo",	223.58028	]	,
["Zurkowski",	223.58922	]	,
["Szalaj",	223.59816	]	,
["Rzeszutko",	223.6071	]	,
["Matejczuk",	223.61604	]	,
["Dziewonski",	223.62498	]	,
["Druzynski",	223.63392	]	,
["Zoladz",	223.64285	]	,
["Zientarski",	223.65178	]	,
["Suchta",	223.66071	]	,
["Pokrzywa",	223.66964	]	,
["Pigula",	223.67857	]	,
["Mlodzinski",	223.6875	]	,
["Litwinczuk",	223.69643	]	,
["Kraczkowski",	223.70536	]	,
["Kik",	223.71429	]	,
["Kaszowski",	223.72322	]	,
["Gula",	223.73215	]	,
["Geisler",	223.74108	]	,
["Dejewski",	223.75001	]	,
["Dachowski",	223.75894	]	,
["Osmólski",	223.76786	]	,
["Mical",	223.77678	]	,
["Maszota",	223.7857	]	,
["Kurzyna",	223.79462	]	,
["Kunowski",	223.80354	]	,
["Feliksiak",	223.81246	]	,
["Cybul",	223.82138	]	,
["Wiaderek",	223.83029	]	,
["Snieg",	223.8392	]	,
["Linka",	223.84811	]	,
["Fidler",	223.85702	]	,
["Fabiszak",	223.86593	]	,
["Cibor",	223.87484	]	,
["Ryczko",	223.88374	]	,
["Rudolf",	223.89264	]	,
["Przyjemski",	223.90154	]	,
["Pogodzinski",	223.91044	]	,
["Lazewski",	223.91934	]	,
["Jedrzejek",	223.92824	]	,
["Bekus",	223.93714	]	,
["Bek",	223.94604	]	,
["Zdrodowski",	223.95493	]	,
["Wolan",	223.96382	]	,
["Radzio",	223.97271	]	,
["Kuliberda",	223.9816	]	,
["Kolanko",	223.99049	]	,
["Zacharzewski",	223.99937	]	,
["Szykula",	224.00825	]	,
["Skowyra",	224.01713	]	,
["Porwol",	224.02601	]	,
["Kosiak",	224.03489	]	,
["Kasica",	224.04377	]	,
["Jakiel",	224.05265	]	,
["Rusinowski",	224.06152	]	,
["Prazmowski",	224.07039	]	,
["Piejko",	224.07926	]	,
["Wielechowski",	224.08812	]	,
["Owczarczak",	224.09698	]	,
["Michnik",	224.10584	]	,
["Linke",	224.1147	]	,
["Kutera",	224.12356	]	,
["Bobryk",	224.13242	]	,
["Szabla",	224.14127	]	,
["Rybarski",	224.15012	]	,
["Powala",	224.15897	]	,
["Marciniszyn",	224.16782	]	,
["Grajkowski",	224.17667	]	,
["Gorgol",	224.18552	]	,
["Czerwionka",	224.19437	]	,
["Mikolajski",	224.20321	]	,
["Ledzion",	224.21205	]	,
["Dykas",	224.22089	]	,
["Zygmuntowicz",	224.22972	]	,
["Listwan",	224.23855	]	,
["Bobrowicz",	224.24738	]	,
["Zurawik",	224.2562	]	,
["Migala",	224.26502	]	,
["Merchel",	224.27384	]	,
["Literski",	224.28266	]	,
["Drobinski",	224.29148	]	,
["Bogumil",	224.3003	]	,
["Wojsa",	224.30911	]	,
["Sadura",	224.31792	]	,
["Pudlowski",	224.32673	]	,
["Mochocki",	224.33554	]	,
["Lyjak",	224.34435	]	,
["Giers",	224.35316	]	,
["Galat",	224.36197	]	,
["Cyrankowski",	224.37078	]	,
["Parczewski",	224.37958	]	,
["Parafiniuk",	224.38838	]	,
["Modrzewski",	224.39718	]	,
["Kryszkiewicz",	224.40598	]	,
["Wyrostek",	224.41477	]	,
["Wolnicki",	224.42356	]	,
["Walek",	224.43235	]	,
["Rembisz",	224.44114	]	,
["Pasciak",	224.44993	]	,
["Olszynski",	224.45872	]	,
["Maksym",	224.46751	]	,
["Luszczewski",	224.4763	]	,
["Kusio",	224.48509	]	,
["Kostek",	224.49388	]	,
["Kalisiak",	224.50267	]	,
["Bzdziuch",	224.51146	]	,
["Zbierski",	224.52024	]	,
["Szubski",	224.52902	]	,
["Szlufik",	224.5378	]	,
["Szajewski",	224.54658	]	,
["Pogorzelec",	224.55536	]	,
["Podlecki",	224.56414	]	,
["Pielech",	224.57292	]	,
["Kafel",	224.5817	]	,
["Gmur",	224.59048	]	,
["Glazer",	224.59926	]	,
["Borysiuk",	224.60804	]	,
["Bialk",	224.61682	]	,
["Adamaszek",	224.6256	]	,
["Wiesiolek",	224.63437	]	,
["Warminski",	224.64314	]	,
["Wakula",	224.65191	]	,
["Schabowski",	224.66068	]	,
["Rogula",	224.66945	]	,
["Linkowski",	224.67822	]	,
["Leszczuk",	224.68699	]	,
["Kapciak",	224.69576	]	,
["Gul",	224.70453	]	,
["Buszka",	224.7133	]	,
["Wichowski",	224.72206	]	,
["Sklorz",	224.73082	]	,
["Pochylski",	224.73958	]	,
["Parda",	224.74834	]	,
["Miszkiel",	224.7571	]	,
["Latek",	224.76586	]	,
["Kurzydlo",	224.77462	]	,
["Kucharz",	224.78338	]	,
["Kotlewski",	224.79214	]	,
["Giec",	224.8009	]	,
["Bylicki",	224.80966	]	,
["Bladowski",	224.81842	]	,
["Wierzejski",	224.82717	]	,
["Wajdzik",	224.83592	]	,
["Mazik",	224.84467	]	,
["Klimko",	224.85342	]	,
["Kleina",	224.86217	]	,
["Dorawa",	224.87092	]	,
["Walinski",	224.87966	]	,
["Perczak",	224.8884	]	,
["Pelczarski",	224.89714	]	,
["Nowaczynski",	224.90588	]	,
["Lang",	224.91462	]	,
["Guzikowski",	224.92336	]	,
["Grunt",	224.9321	]	,
["Goluchowski",	224.94084	]	,
["Cywka",	224.94958	]	,
["Batóg",	224.95832	]	,
["Widlak",	224.96705	]	,
["Miszta",	224.97578	]	,
["Kosc",	224.98451	]	,
["Kosidlo",	224.99324	]	,
["Jarzembowski",	225.00197	]	,
["Aleksander",	225.0107	]	,
["Nartowski",	225.01942	]	,
["Marchlewicz",	225.02814	]	,
["Korkosz",	225.03686	]	,
["Beska",	225.04558	]	,
["Bak",	225.0543	]	,
["Suchanski",	225.06301	]	,
["Stoch",	225.07172	]	,
["Makles",	225.08043	]	,
["Hudzik",	225.08914	]	,
["Hornik",	225.09785	]	,
["Bujko",	225.10656	]	,
["Zietal",	225.11526	]	,
["Zawal",	225.12396	]	,
["Wagrowski",	225.13266	]	,
["Tarlowski",	225.14136	]	,
["Sochaj",	225.15006	]	,
["Podpora",	225.15876	]	,
["Malyszek",	225.16746	]	,
["Macków",	225.17616	]	,
["Latacz",	225.18486	]	,
["Kozdra",	225.19356	]	,
["Kosno",	225.20226	]	,
["Kordecki",	225.21096	]	,
["Gogól",	225.21966	]	,
["Fit",	225.22836	]	,
["Bienia",	225.23706	]	,
["Wendt",	225.24575	]	,
["Smietanski",	225.25444	]	,
["Szyda",	225.26313	]	,
["Suchon",	225.27182	]	,
["Sobel",	225.28051	]	,
["Rosicki",	225.2892	]	,
["Nierzwicki",	225.29789	]	,
["Lesiewicz",	225.30658	]	,
["Kolesnik",	225.31527	]	,
["Kinder",	225.32396	]	,
["Kasper",	225.33265	]	,
["Jaszczyszyn",	225.34134	]	,
["Weremczuk",	225.35002	]	,
["Steinke",	225.3587	]	,
["Sadej",	225.36738	]	,
["Pula",	225.37606	]	,
["Nowrot",	225.38474	]	,
["Nowotny",	225.39342	]	,
["Mystkowski",	225.4021	]	,
["Majorczyk",	225.41078	]	,
["Kunert",	225.41946	]	,
["Jerzyk",	225.42814	]	,
["Capala",	225.43682	]	,
["Bartos",	225.4455	]	,
["Wojciech",	225.45417	]	,
["Wasikowski",	225.46284	]	,
["Stelmasiak",	225.47151	]	,
["Przytulski",	225.48018	]	,
["Portka",	225.48885	]	,
["Pietrak",	225.49752	]	,
["Luksza",	225.50619	]	,
["Kulma",	225.51486	]	,
["Jeske",	225.52353	]	,
["Góraj",	225.5322	]	,
["Fyda",	225.54087	]	,
["Stawecki",	225.54953	]	,
["Siemion",	225.55819	]	,
["Rusiniak",	225.56685	]	,
["Flisiak",	225.57551	]	,
["Cherek",	225.58417	]	,
["Bryndza",	225.59283	]	,
["Brudzinski",	225.60149	]	,
["Ziola",	225.61014	]	,
["Zapasnik",	225.61879	]	,
["Raszkiewicz",	225.62744	]	,
["Pszczólka",	225.63609	]	,
["Palgan",	225.64474	]	,
["Kozar",	225.65339	]	,
["Gumienny",	225.66204	]	,
["Fedak",	225.67069	]	,
["Erdmann",	225.67934	]	,
["Nizynski",	225.68798	]	,
["Matura",	225.69662	]	,
["Kapera",	225.70526	]	,
["Golan",	225.7139	]	,
["Gawryszewski",	225.72254	]	,
["Szczesiak",	225.73117	]	,
["Szambelan",	225.7398	]	,
["Serkowski",	225.74843	]	,
["Przestrzelski",	225.75706	]	,
["Proszowski",	225.76569	]	,
["Pólchlopek",	225.77432	]	,
["Podobinski",	225.78295	]	,
["Luszczyk",	225.79158	]	,
["Famulski",	225.80021	]	,
["Wegierski",	225.80883	]	,
["Szymocha",	225.81745	]	,
["Piskorowski",	225.82607	]	,
["Pielka",	225.83469	]	,
["Maciol",	225.84331	]	,
["Krusinski",	225.85193	]	,
["Kaliski",	225.86055	]	,
["Kacprzycki",	225.86917	]	,
["Brudny",	225.87779	]	,
["Babij",	225.88641	]	,
["Zychlinski",	225.89502	]	,
["Zalucki",	225.90363	]	,
["Zacharczuk",	225.91224	]	,
["Pilarek",	225.92085	]	,
["Owsianka",	225.92946	]	,
["Lonski",	225.93807	]	,
["Harasimiuk",	225.94668	]	,
["Durlak",	225.95529	]	,
["Dlugajczyk",	225.9639	]	,
["Cieminski",	225.97251	]	,
["Wijata",	225.98111	]	,
["Szyndler",	225.98971	]	,
["Pyrzynski",	225.99831	]	,
["Morka",	226.00691	]	,
["Mendyka",	226.01551	]	,
["Kubiaczyk",	226.02411	]	,
["Kij",	226.03271	]	,
["Gaudyn",	226.04131	]	,
["Bok",	226.04991	]	,
["Weglowski",	226.0585	]	,
["Trebicki",	226.06709	]	,
["Posluszny",	226.07568	]	,
["Plich",	226.08427	]	,
["Pacyga",	226.09286	]	,
["Mietus",	226.10145	]	,
["Gaczynski",	226.11004	]	,
["Ficner",	226.11863	]	,
["Brylinski",	226.12722	]	,
["Swierkosz",	226.1358	]	,
["Krzywon",	226.14438	]	,
["Kojder",	226.15296	]	,
["Kiepura",	226.16154	]	,
["Godzisz",	226.17012	]	,
["Ciuba",	226.1787	]	,
["Bukowiec",	226.18728	]	,
["Bachorski",	226.19586	]	,
["Wlazlak",	226.20443	]	,
["Teterycz",	226.213	]	,
["Scibisz",	226.22157	]	,
["Sobkiewicz",	226.23014	]	,
["Raczkiewicz",	226.23871	]	,
["Paczkowski",	226.24728	]	,
["Konrad",	226.25585	]	,
["Kohut",	226.26442	]	,
["Gonet",	226.27299	]	,
["Frydel",	226.28156	]	,
["Dyka",	226.29013	]	,
["Zarembski",	226.29869	]	,
["Struzynski",	226.30725	]	,
["Stawowski",	226.31581	]	,
["Siemek",	226.32437	]	,
["Osko",	226.33293	]	,
["Gospodarek",	226.34149	]	,
["Zdebski",	226.35004	]	,
["Stryjek",	226.35859	]	,
["Siecinski",	226.36714	]	,
["Labudda",	226.37569	]	,
["Kosiec",	226.38424	]	,
["Kolodzinski",	226.39279	]	,
["Indyk",	226.40134	]	,
["Franik",	226.40989	]	,
["Fiolka",	226.41844	]	,
["Drzewicki",	226.42699	]	,
["Strycharz",	226.43553	]	,
["Stolecki",	226.44407	]	,
["Skladanowski",	226.45261	]	,
["Ostapczuk",	226.46115	]	,
["Obarski",	226.46969	]	,
["Laszczyk",	226.47823	]	,
["Lament",	226.48677	]	,
["Korzekwa",	226.49531	]	,
["Kedziorek",	226.50385	]	,
["Dziuban",	226.51239	]	,
["Brylski",	226.52093	]	,
["Biegala",	226.52947	]	,
["Witon",	226.538	]	,
["Szpara",	226.54653	]	,
["Padlo",	226.55506	]	,
["Otremba",	226.56359	]	,
["Mierzwiak",	226.57212	]	,
["Kordus",	226.58065	]	,
["Dybalski",	226.58918	]	,
["Dluzewski",	226.59771	]	,
["Budnicki",	226.60624	]	,
["Bojczuk",	226.61477	]	,
["Szmelter",	226.62329	]	,
["Rudzik",	226.63181	]	,
["Madzia",	226.64033	]	,
["Lyszkowski",	226.64885	]	,
["Kunecki",	226.65737	]	,
["Kostanski",	226.66589	]	,
["Hamerski",	226.67441	]	,
["Grabara",	226.68293	]	,
["Górkiewicz",	226.69145	]	,
["Bartel",	226.69997	]	,
["Sliz",	226.70848	]	,
["Sura",	226.71699	]	,
["Skrzecz",	226.7255	]	,
["Puto",	226.73401	]	,
["Pulka",	226.74252	]	,
["Piotrowiak",	226.75103	]	,
["Nitkowski",	226.75954	]	,
["Mazan",	226.76805	]	,
["Kubiszewski",	226.77656	]	,
["Kobryn",	226.78507	]	,
["Klatka",	226.79358	]	,
["Januchta",	226.80209	]	,
["Grubba",	226.8106	]	,
["Boruszewski",	226.81911	]	,
["Zaucha",	226.82761	]	,
["Witucki",	226.83611	]	,
["Szczepankowski",	226.84461	]	,
["Sularz",	226.85311	]	,
["Siergiej",	226.86161	]	,
["Pianka",	226.87011	]	,
["Jedruszczak",	226.87861	]	,
["Groth",	226.88711	]	,
["Sobisz",	226.8956	]	,
["Siejak",	226.90409	]	,
["Robaczewski",	226.91258	]	,
["Recko",	226.92107	]	,
["Lorens",	226.92956	]	,
["Kicki",	226.93805	]	,
["Cegla",	226.94654	]	,
["Wochnik",	226.95502	]	,
["Sliwinski",	226.9635	]	,
["Mówinski",	226.97198	]	,
["Kurys",	226.98046	]	,
["Kryski",	226.98894	]	,
["Krasnowski",	226.99742	]	,
["Kleszczewski",	227.0059	]	,
["Kanicki",	227.01438	]	,
["Gulinski",	227.02286	]	,
["Gregorowicz",	227.03134	]	,
["Filek",	227.03982	]	,
["Salawa",	227.04829	]	,
["Piekarek",	227.05676	]	,
["Peplowski",	227.06523	]	,
["Pabisiak",	227.0737	]	,
["Kloskowski",	227.08217	]	,
["Glonek",	227.09064	]	,
["Dardzinski",	227.09911	]	,
["Butrym",	227.10758	]	,
["Szynkowski",	227.11604	]	,
["Sochaczewski",	227.1245	]	,
["Przewozniak",	227.13296	]	,
["Plachecki",	227.14142	]	,
["Macek",	227.14988	]	,
["Konstanty",	227.15834	]	,
["Kolber",	227.1668	]	,
["Jedrasiak",	227.17526	]	,
["Jakóbowski",	227.18372	]	,
["Chwalinski",	227.19218	]	,
["Buchalski",	227.20064	]	,
["Bonikowski",	227.2091	]	,
["Wezyk",	227.21755	]	,
["Szaj",	227.226	]	,
["Malara",	227.23445	]	,
["Lykowski",	227.2429	]	,
["Luchowski",	227.25135	]	,
["Kloczko",	227.2598	]	,
["Kieronski",	227.26825	]	,
["Karsznia",	227.2767	]	,
["Golenia",	227.28515	]	,
["Zajko",	227.29359	]	,
["Wudarczyk",	227.30203	]	,
["Szybowski",	227.31047	]	,
["Stanuch",	227.31891	]	,
["Pierscinski",	227.32735	]	,
["Niklewicz",	227.33579	]	,
["Matejczyk",	227.34423	]	,
["Kopyto",	227.35267	]	,
["Horodecki",	227.36111	]	,
["Grygorowicz",	227.36955	]	,
["Szajda",	227.37798	]	,
["Stachelek",	227.38641	]	,
["Slyk",	227.39484	]	,
["Rolinski",	227.40327	]	,
["Pelczynski",	227.4117	]	,
["Loska",	227.42013	]	,
["Job",	227.42856	]	,
["Dziadura",	227.43699	]	,
["Dworniczak",	227.44542	]	,
["Skulimowski",	227.45384	]	,
["Skubis",	227.46226	]	,
["Seklecki",	227.47068	]	,
["Rembowski",	227.4791	]	,
["Obst",	227.48752	]	,
["Mosinski",	227.49594	]	,
["Maszkowski",	227.50436	]	,
["Kazimierczyk",	227.51278	]	,
["Derecki",	227.5212	]	,
["Cymer",	227.52962	]	,
["Ciak",	227.53804	]	,
["Chudoba",	227.54646	]	,
["Achtelik",	227.55488	]	,
["Zietkowski",	227.56329	]	,
["Tytko",	227.5717	]	,
["Skupin",	227.58011	]	,
["Skierka",	227.58852	]	,
["Panus",	227.59693	]	,
["Pabis",	227.60534	]	,
["Kutkowski",	227.61375	]	,
["Jasnowski",	227.62216	]	,
["Gawkowski",	227.63057	]	,
["Folta",	227.63898	]	,
["Bogaczyk",	227.64739	]	,
["Basa",	227.6558	]	,
["Trzpil",	227.6642	]	,
["Morek",	227.6726	]	,
["Kloska",	227.681	]	,
["Kapustka",	227.6894	]	,
["Gzyl",	227.6978	]	,
["Golos",	227.7062	]	,
["Danel",	227.7146	]	,
["Borkiewicz",	227.723	]	,
["Araszkiewicz",	227.7314	]	,
["Zuranski",	227.73979	]	,
["Niegowski",	227.74818	]	,
["Miotke",	227.75657	]	,
["Skrocki",	227.76495	]	,
["Rezler",	227.77333	]	,
["Potyrala",	227.78171	]	,
["Patkowski",	227.79009	]	,
["Pacholak",	227.79847	]	,
["Herba",	227.80685	]	,
["Grzenia",	227.81523	]	,
["Giezek",	227.82361	]	,
["Gajowiak",	227.83199	]	,
["Filak",	227.84037	]	,
["Fechner",	227.84875	]	,
["Drozdzik",	227.85713	]	,
["Cyman",	227.86551	]	,
["Wieczerzak",	227.87388	]	,
["Stróz",	227.88225	]	,
["Staciwa",	227.89062	]	,
["Ruchala",	227.89899	]	,
["Rogal",	227.90736	]	,
["Reszke",	227.91573	]	,
["Moczynski",	227.9241	]	,
["Kurpisz",	227.93247	]	,
["Gryga",	227.94084	]	,
["Wolkowski",	227.9492	]	,
["Stempniak",	227.95756	]	,
["Naklicki",	227.96592	]	,
["Matraszek",	227.97428	]	,
["Kózka",	227.98264	]	,
["Kietlinski",	227.991	]	,
["Gizynski",	227.99936	]	,
["Elsner",	228.00772	]	,
["Boba",	228.01608	]	,
["Barlóg",	228.02444	]	,
["Praski",	228.03279	]	,
["Michnowski",	228.04114	]	,
["Kiliszek",	228.04949	]	,
["Jessa",	228.05784	]	,
["Ignatiuk",	228.06619	]	,
["Gogola",	228.07454	]	,
["Drobek",	228.08289	]	,
["Dopieralski",	228.09124	]	,
["Skwierczynski",	228.09958	]	,
["Rzymski",	228.10792	]	,
["Lica",	228.11626	]	,
["Larysz",	228.1246	]	,
["Kalka",	228.13294	]	,
["Januchowski",	228.14128	]	,
["Dziczek",	228.14962	]	,
["Czupryn",	228.15796	]	,
["Cwojdzinski",	228.1663	]	,
["Chalecki",	228.17464	]	,
["Zolna",	228.18297	]	,
["Pytko",	228.1913	]	,
["Misiarz",	228.19963	]	,
["Majnusz",	228.20796	]	,
["Kielbasinski",	228.21629	]	,
["Kaszkowiak",	228.22462	]	,
["Jonak",	228.23295	]	,
["Chlebicki",	228.24128	]	,
["Basista",	228.24961	]	,
["Zaporowski",	228.25793	]	,
["Slonski",	228.26625	]	,
["Silski",	228.27457	]	,
["Potega",	228.28289	]	,
["Natanek",	228.29121	]	,
["Matyszczak",	228.29953	]	,
["Majerczyk",	228.30785	]	,
["Lapaj",	228.31617	]	,
["Korzonek",	228.32449	]	,
["Jasko",	228.33281	]	,
["Futyma",	228.34113	]	,
["Duszczyk",	228.34945	]	,
["Antonczak",	228.35777	]	,
["Wysota",	228.36608	]	,
["Wilanowski",	228.37439	]	,
["Wawrzycki",	228.3827	]	,
["Dela",	228.39101	]	,
["Stawowczyk",	228.39931	]	,
["Radzinski",	228.40761	]	,
["Milczarczyk",	228.41591	]	,
["Malisz",	228.42421	]	,
["Czoski",	228.43251	]	,
["Andrearczyk",	228.44081	]	,
["Zynda",	228.4491	]	,
["Swaczyna",	228.45739	]	,
["Ryndak",	228.46568	]	,
["Moskalik",	228.47397	]	,
["Mitoraj",	228.48226	]	,
["Martynowski",	228.49055	]	,
["Lys",	228.49884	]	,
["Lepek",	228.50713	]	,
["Kosiarski",	228.51542	]	,
["Kniec",	228.52371	]	,
["Janisz",	228.532	]	,
["Gorol",	228.54029	]	,
["Ciezka",	228.54858	]	,
["Zyrek",	228.55686	]	,
["Zmarzly",	228.56514	]	,
["Wojtaszczyk",	228.57342	]	,
["Szygula",	228.5817	]	,
["Szalast",	228.58998	]	,
["Rzad",	228.59826	]	,
["Nicewicz",	228.60654	]	,
["Danieluk",	228.61482	]	,
["Bulak",	228.6231	]	,
["Wojtasiewicz",	228.63137	]	,
["Pleskot",	228.63964	]	,
["Materek",	228.64791	]	,
["Kurczak",	228.65618	]	,
["Dytko",	228.66445	]	,
["Adamkowski",	228.67272	]	,
["Swistek",	228.68098	]	,
["Szymecki",	228.68924	]	,
["Szafarz",	228.6975	]	,
["Stradomski",	228.70576	]	,
["Rafinski",	228.71402	]	,
["Litwa",	228.72228	]	,
["Kreczmer",	228.73054	]	,
["Jastrzebowski",	228.7388	]	,
["Idec",	228.74706	]	,
["Grabczak",	228.75532	]	,
["Goliszek",	228.76358	]	,
["Flieger",	228.77184	]	,
["Filiks",	228.7801	]	,
["Dyszy",	228.78836	]	,
["Blazejczak",	228.79662	]	,
["Wroclawski",	228.80487	]	,
["Sychowski",	228.81312	]	,
["Rudawski",	228.82137	]	,
["Maksimowicz",	228.82962	]	,
["Komisarczyk",	228.83787	]	,
["Jewula",	228.84612	]	,
["Hallmann",	228.85437	]	,
["Gabara",	228.86262	]	,
["Dorozynski",	228.87087	]	,
["Budzyn",	228.87912	]	,
["Andruszko",	228.88737	]	,
["Wojtasinski",	228.89561	]	,
["Szczukowski",	228.90385	]	,
["Soczynski",	228.91209	]	,
["Serocki",	228.92033	]	,
["Palyga",	228.92857	]	,
["Moj",	228.93681	]	,
["Koterba",	228.94505	]	,
["Gruza",	228.95329	]	,
["Gamon",	228.96153	]	,
["Derezinski",	228.96977	]	,
["Zlotowski",	228.978	]	,
["Pasierbek",	228.98623	]	,
["Maslinski",	228.99446	]	,
["Kuchciak",	229.00269	]	,
["Kanik",	229.01092	]	,
["Cis",	229.01915	]	,
["Zegar",	229.02737	]	,
["Taborski",	229.03559	]	,
["Sadlik",	229.04381	]	,
["Paprotny",	229.05203	]	,
["Nalazek",	229.06025	]	,
["Mikita",	229.06847	]	,
["Kucab",	229.07669	]	,
["Kranc",	229.08491	]	,
["Kobuszewski",	229.09313	]	,
["Godzik",	229.10135	]	,
["Ciszkowski",	229.10957	]	,
["Straczynski",	229.11778	]	,
["Odrzywolski",	229.12599	]	,
["Grotkowski",	229.1342	]	,
["Galikowski",	229.14241	]	,
["Ciezki",	229.15062	]	,
["Brodecki",	229.15883	]	,
["Sip",	229.16703	]	,
["Powalka",	229.17523	]	,
["Penkala",	229.18343	]	,
["Pachuta",	229.19163	]	,
["Nagel",	229.19983	]	,
["Mrugalski",	229.20803	]	,
["Maciejski",	229.21623	]	,
["Lopinski",	229.22443	]	,
["Litwinowicz",	229.23263	]	,
["Kukuczka",	229.24083	]	,
["Knysak",	229.24903	]	,
["Fojt",	229.25723	]	,
["Brejnak",	229.26543	]	,
["Tasarz",	229.27362	]	,
["Zielke",	229.2818	]	,
["Zaras",	229.28998	]	,
["Zaranek",	229.29816	]	,
["Waleczek",	229.30634	]	,
["Rubaj",	229.31452	]	,
["Niesluchowski",	229.3227	]	,
["Medrzycki",	229.33088	]	,
["Bazylewicz",	229.33906	]	,
["Banys",	229.34724	]	,
["Balawender",	229.35542	]	,
["Zmuda",	229.36359	]	,
["Wojcik",	229.37176	]	,
["Labno",	229.37993	]	,
["Gesiarz",	229.3881	]	,
["Frost",	229.39627	]	,
["Bany",	229.40444	]	,
["Zero",	229.4126	]	,
["Rudowicz",	229.42076	]	,
["Nyk",	229.42892	]	,
["Milcarz",	229.43708	]	,
["Lipowicz",	229.44524	]	,
["Kycia",	229.4534	]	,
["Kosciolek",	229.46156	]	,
["Korda",	229.46972	]	,
["Kolaczkowski",	229.47788	]	,
["Berus",	229.48604	]	,
["Arczewski",	229.4942	]	,
["Wiese",	229.50235	]	,
["Swatowski",	229.5105	]	,
["Suszczynski",	229.51865	]	,
["Surowiecki",	229.5268	]	,
["Redzimski",	229.53495	]	,
["Olkowicz",	229.5431	]	,
["Maczkowski",	229.55125	]	,
["Lutynski",	229.5594	]	,
["Dzieza",	229.56755	]	,
["Doroszkiewicz",	229.5757	]	,
["Cetera",	229.58385	]	,
["Urzedowski",	229.59199	]	,
["Sulecki",	229.60013	]	,
["Rychlowski",	229.60827	]	,
["Pazdan",	229.61641	]	,
["Pacia",	229.62455	]	,
["Kobylski",	229.63269	]	,
["Kempka",	229.64083	]	,
["Gruszewski",	229.64897	]	,
["Dydak",	229.65711	]	,
["Biegalski",	229.66525	]	,
["Zaczkowski",	229.67338	]	,
["Uscinski",	229.68151	]	,
["Sredzinski",	229.68964	]	,
["Scibior",	229.69777	]	,
["Szyjka",	229.7059	]	,
["Pyziak",	229.71403	]	,
["Plesniak",	229.72216	]	,
["Maszczyk",	229.73029	]	,
["Ludwiniak",	229.73842	]	,
["Kliszewski",	229.74655	]	,
["Zadora",	229.75467	]	,
["Strug",	229.76279	]	,
["Piwinski",	229.77091	]	,
["Mokwa",	229.77903	]	,
["Malarski",	229.78715	]	,
["Lasak",	229.79527	]	,
["Kulczak",	229.80339	]	,
["Kruszona",	229.81151	]	,
["Dobrucki",	229.81963	]	,
["Antecki",	229.82775	]	,
["Zacharewicz",	229.83586	]	,
["Nagrodzki",	229.84397	]	,
["Miekina",	229.85208	]	,
["Klaus",	229.86019	]	,
["Glegola",	229.8683	]	,
["Wyderka",	229.8764	]	,
["Stecki",	229.8845	]	,
["Owsinski",	229.8926	]	,
["Maleszka",	229.9007	]	,
["Malcherek",	229.9088	]	,
["Lew",	229.9169	]	,
["Kulis",	229.925	]	,
["Bodzak",	229.9331	]	,
["Blaziak",	229.9412	]	,
["Bieganowski",	229.9493	]	,
["Bartlomiejczyk",	229.9574	]	,
["Tos",	229.96549	]	,
["Kulwicki",	229.97358	]	,
["Kubasiak",	229.98167	]	,
["Drozynski",	229.98976	]	,
["Dorobisz",	229.99785	]	,
["Cukier",	230.00594	]	,
["Ciecko",	230.01403	]	,
["Zapadka",	230.02211	]	,
["Srednicki",	230.03019	]	,
["Sniegowski",	230.03827	]	,
["Sworowski",	230.04635	]	,
["Klosowicz",	230.05443	]	,
["Kasak",	230.06251	]	,
["Dzierzawski",	230.07059	]	,
["Czubaszek",	230.07867	]	,
["Baumgart",	230.08675	]	,
["Szemraj",	230.09482	]	,
["Nosarzewski",	230.10289	]	,
["Nogiec",	230.11096	]	,
["Burczak",	230.11903	]	,
["Pietras",	230.12709	]	,
["Ostafin",	230.13515	]	,
["Noculak",	230.14321	]	,
["Kukiela",	230.15127	]	,
["Fogel",	230.15933	]	,
["Duczek",	230.16739	]	,
["Czainski",	230.17545	]	,
["Cylwik",	230.18351	]	,
["Biernacik",	230.19157	]	,
["Wydrych",	230.19962	]	,
["Szajek",	230.20767	]	,
["Siwczak",	230.21572	]	,
["Majewicz",	230.22377	]	,
["Losiak",	230.23182	]	,
["Karkut",	230.23987	]	,
["Durys",	230.24792	]	,
["Chwalisz",	230.25597	]	,
["Byszewski",	230.26402	]	,
["Bembenek",	230.27207	]	,
["Bartkowicz",	230.28012	]	,
["Piskor",	230.28816	]	,
["Miodonski",	230.2962	]	,
["Mikus",	230.30424	]	,
["Ksiezyk",	230.31228	]	,
["Goss",	230.32032	]	,
["Duzynski",	230.32836	]	,
["Drewniok",	230.3364	]	,
["Bakiewicz",	230.34444	]	,
["Zdunski",	230.35247	]	,
["Wódka",	230.3605	]	,
["Wota",	230.36853	]	,
["Prazmo",	230.37656	]	,
["Pachocki",	230.38459	]	,
["Kiwior",	230.39262	]	,
["Bogdal",	230.40065	]	,
["Rubacha",	230.40867	]	,
["Hanus",	230.41669	]	,
["Wasiewicz",	230.4247	]	,
["Trochimiuk",	230.43271	]	,
["Szwiec",	230.44072	]	,
["Suszka",	230.44873	]	,
["Palak",	230.45674	]	,
["Anuszewski",	230.46475	]	,
["Ziemann",	230.47275	]	,
["Macczak",	230.48075	]	,
["Kubaszewski",	230.48875	]	,
["Kruzel",	230.49675	]	,
["Kolaczyk",	230.50475	]	,
["Kapka",	230.51275	]	,
["Jodko",	230.52075	]	,
["Jeszke",	230.52875	]	,
["Gros",	230.53675	]	,
["Gendek",	230.54475	]	,
["Dubik",	230.55275	]	,
["Wazna",	230.56074	]	,
["Pierchala",	230.56873	]	,
["Nieszporek",	230.57672	]	,
["Kandora",	230.58471	]	,
["Janasz",	230.5927	]	,
["Gryszkiewicz",	230.60069	]	,
["Drobik",	230.60868	]	,
["Ciolczyk",	230.61667	]	,
["Wolkowicz",	230.62465	]	,
["Tylman",	230.63263	]	,
["Pulkowski",	230.64061	]	,
["Pluski",	230.64859	]	,
["Pitula",	230.65657	]	,
["Pioch",	230.66455	]	,
["Pilich",	230.67253	]	,
["Marach",	230.68051	]	,
["Malon",	230.68849	]	,
["Lepa",	230.69647	]	,
["Kaliciak",	230.70445	]	,
["Juszczynski",	230.71243	]	,
["Joszko",	230.72041	]	,
["Hejna",	230.72839	]	,
["Gryta",	230.73637	]	,
["Frelich",	230.74435	]	,
["Bieniecki",	230.75233	]	,
["Belz",	230.76031	]	,
["Bakalarczyk",	230.76829	]	,
["Piszczatowski",	230.77626	]	,
["Nózka",	230.78423	]	,
["Holewa",	230.7922	]	,
["Fierek",	230.80017	]	,
["Chylewski",	230.80814	]	,
["Zuchowicz",	230.8161	]	,
["Wojtunik",	230.82406	]	,
["Trzop",	230.83202	]	,
["Maslon",	230.83998	]	,
["Marchwinski",	230.84794	]	,
["Marchwicki",	230.8559	]	,
["Lubelski",	230.86386	]	,
["Linda",	230.87182	]	,
["Kurp",	230.87978	]	,
["Grzelewski",	230.88774	]	,
["Gryka",	230.8957	]	,
["Draus",	230.90366	]	,
["Skarzynski",	230.91161	]	,
["Rezmer",	230.91956	]	,
["Mizak",	230.92751	]	,
["Makurat",	230.93546	]	,
["Koscik",	230.94341	]	,
["Helman",	230.95136	]	,
["Gendera",	230.95931	]	,
["Dydo",	230.96726	]	,
["Bondaruk",	230.97521	]	,
["Bodek",	230.98316	]	,
["Bocianowski",	230.99111	]	,
["Wujec",	230.99905	]	,
["Sady",	231.00699	]	,
["Przekwas",	231.01493	]	,
["Postawa",	231.02287	]	,
["Polasik",	231.03081	]	,
["Plebanek",	231.03875	]	,
["Lejk",	231.04669	]	,
["Kacperek",	231.05463	]	,
["Golofit",	231.06257	]	,
["Brzezowski",	231.07051	]	,
["Tomys",	231.07844	]	,
["Swiadek",	231.08637	]	,
["Mizgala",	231.0943	]	,
["Kubrak",	231.10223	]	,
["Kleczewski",	231.11016	]	,
["Gontarski",	231.11809	]	,
["Ernst",	231.12602	]	,
["Baszczynski",	231.13395	]	,
["Wisnicki",	231.14187	]	,
["Wielgos",	231.14979	]	,
["Martynowicz",	231.15771	]	,
["Drela",	231.16563	]	,
["Ziarnik",	231.17354	]	,
["Stasica",	231.18145	]	,
["Semik",	231.18936	]	,
["Mytych",	231.19727	]	,
["Moszynski",	231.20518	]	,
["Melka",	231.21309	]	,
["Marat",	231.221	]	,
["Hetmanski",	231.22891	]	,
["Dabrówka",	231.23682	]	,
["Wyroba",	231.24472	]	,
["Szczerbowski",	231.25262	]	,
["Siudek",	231.26052	]	,
["Senator",	231.26842	]	,
["Ryszkiewicz",	231.27632	]	,
["Podsiedlik",	231.28422	]	,
["Malys",	231.29212	]	,
["Lepianka",	231.30002	]	,
["Kolomanski",	231.30792	]	,
["Giersz",	231.31582	]	,
["Zugaj",	231.32371	]	,
["Przeworski",	231.3316	]	,
["Procek",	231.33949	]	,
["Makosz",	231.34738	]	,
["Kunda",	231.35527	]	,
["Beszczynski",	231.36316	]	,
["Ziólko",	231.37104	]	,
["Wyszomierski",	231.37892	]	,
["Trzyna",	231.3868	]	,
["Stroka",	231.39468	]	,
["Rzeszut",	231.40256	]	,
["Pyza",	231.41044	]	,
["Lewalski",	231.41832	]	,
["Krezolek",	231.4262	]	,
["Kazior",	231.43408	]	,
["Fidos",	231.44196	]	,
["Solek",	231.44983	]	,
["Skopinski",	231.4577	]	,
["Kowalczewski",	231.46557	]	,
["Gordon",	231.47344	]	,
["Dubis",	231.48131	]	,
["Ciochon",	231.48918	]	,
["Bieszke",	231.49705	]	,
["Zolnierczyk",	231.50491	]	,
["Szwedowski",	231.51277	]	,
["Sobstyl",	231.52063	]	,
["Skalik",	231.52849	]	,
["Okulski",	231.53635	]	,
["Namyslo",	231.54421	]	,
["Litewka",	231.55207	]	,
["Krzysztofek",	231.55993	]	,
["Karlikowski",	231.56779	]	,
["Grycz",	231.57565	]	,
["Felus",	231.58351	]	,
["Downar",	231.59137	]	,
["Szram",	231.59922	]	,
["Szalinski",	231.60707	]	,
["Rzepinski",	231.61492	]	,
["Runowski",	231.62277	]	,
["Oleksik",	231.63062	]	,
["Milej",	231.63847	]	,
["Kudela",	231.64632	]	,
["Klaja",	231.65417	]	,
["Giedrojc",	231.66202	]	,
["Getka",	231.66987	]	,
["Durma",	231.67772	]	,
["Dudko",	231.68557	]	,
["Dubowski",	231.69342	]	,
["Drzazgowski",	231.70127	]	,
["Dobrogowski",	231.70912	]	,
["Debosz",	231.71697	]	,
["Browarczyk",	231.72482	]	,
["Sasiadek",	231.73266	]	,
["Picheta",	231.7405	]	,
["Peciak",	231.74834	]	,
["Ornowski",	231.75618	]	,
["Niecko",	231.76402	]	,
["Midura",	231.77186	]	,
["Maciejko",	231.7797	]	,
["Gregorek",	231.78754	]	,
["Budzowski",	231.79538	]	,
["Ziemnicki",	231.80321	]	,
["Wasiewicz",	231.81104	]	,
["Twardy",	231.81887	]	,
["Szeligowski",	231.8267	]	,
["Szachniewicz",	231.83453	]	,
["Sypek",	231.84236	]	,
["Sojda",	231.85019	]	,
["Saran",	231.85802	]	,
["Mosiolek",	231.86585	]	,
["Jaglowski",	231.87368	]	,
["Gusciora",	231.88151	]	,
["Golak",	231.88934	]	,
["Glowicki",	231.89717	]	,
["Ellwart",	231.905	]	,
["Drewicz",	231.91283	]	,
["Celejewski",	231.92066	]	,
["Barszczak",	231.92849	]	,
["Zielezinski",	231.93631	]	,
["Wójt",	231.94413	]	,
["Strawa",	231.95195	]	,
["Sereda",	231.95977	]	,
["Rejmer",	231.96759	]	,
["Prostak",	231.97541	]	,
["Molendowski",	231.98323	]	,
["Kolak",	231.99105	]	,
["Klekot",	231.99887	]	,
["Gerlach",	232.00669	]	,
["Drabinski",	232.01451	]	,
["Ciepla",	232.02233	]	,
["Barankiewicz",	232.03015	]	,
["Welc",	232.03796	]	,
["Szubinski",	232.04577	]	,
["Skotarek",	232.05358	]	,
["Sadlocha",	232.06139	]	,
["Roszkiewicz",	232.0692	]	,
["Poletek",	232.07701	]	,
["Ofiara",	232.08482	]	,
["Lesnikowski",	232.09263	]	,
["Kielbus",	232.10044	]	,
["Kalwak",	232.10825	]	,
["Jas",	232.11606	]	,
["Jarkiewicz",	232.12387	]	,
["Jambor",	232.13168	]	,
["Hartman",	232.13949	]	,
["Gras",	232.1473	]	,
["Razniak",	232.1551	]	,
["Janc",	232.1629	]	,
["Doroz",	232.1707	]	,
["Baster",	232.1785	]	,
["Banak",	232.1863	]	,
["Spólnik",	232.19409	]	,
["Poreda",	232.20188	]	,
["Orwat",	232.20967	]	,
["Matyjas",	232.21746	]	,
["Laskus",	232.22525	]	,
["Kropiewnicki",	232.23304	]	,
["Bajak",	232.24083	]	,
["Witko",	232.24861	]	,
["Slimak",	232.25639	]	,
["Stromski",	232.26417	]	,
["Sapeta",	232.27195	]	,
["Sadownik",	232.27973	]	,
["Roszko",	232.28751	]	,
["Raszkowski",	232.29529	]	,
["Nazarewicz",	232.30307	]	,
["Mrotek",	232.31085	]	,
["Matuszynski",	232.31863	]	,
["Kupniewski",	232.32641	]	,
["Gnyp",	232.33419	]	,
["Glówczewski",	232.34197	]	,
["Dziarmaga",	232.34975	]	,
["Zaniewicz",	232.35752	]	,
["Walusiak",	232.36529	]	,
["Toborek",	232.37306	]	,
["Szulim",	232.38083	]	,
["Sieklucki",	232.3886	]	,
["Pawliczak",	232.39637	]	,
["Nikolajuk",	232.40414	]	,
["Niciejewski",	232.41191	]	,
["Myszor",	232.41968	]	,
["Mila",	232.42745	]	,
["Liedtke",	232.43522	]	,
["Korpal",	232.44299	]	,
["Jazwiec",	232.45076	]	,
["Groborz",	232.45853	]	,
["Dymarski",	232.4663	]	,
["Swierkot",	232.47406	]	,
["Sabala",	232.48182	]	,
["Rypinski",	232.48958	]	,
["Rogucki",	232.49734	]	,
["Pogonowski",	232.5051	]	,
["Kluj",	232.51286	]	,
["Ferfecki",	232.52062	]	,
["Zach",	232.52837	]	,
["Wawrzynczyk",	232.53612	]	,
["Szumilo",	232.54387	]	,
["Sulich",	232.55162	]	,
["Stepak",	232.55937	]	,
["Rutowicz",	232.56712	]	,
["Piwnicki",	232.57487	]	,
["Maminski",	232.58262	]	,
["Krzyszczak",	232.59037	]	,
["Kielbik",	232.59812	]	,
["Gogol",	232.60587	]	,
["Buszkiewicz",	232.61362	]	,
["Basaj",	232.62137	]	,
["Bartus",	232.62912	]	,
["Tomalski",	232.63686	]	,
["Stuczynski",	232.6446	]	,
["Samulak",	232.65234	]	,
["Ryfa",	232.66008	]	,
["Potoczna",	232.66782	]	,
["Panicz",	232.67556	]	,
["Lesny",	232.6833	]	,
["Lada",	232.69104	]	,
["Kuska",	232.69878	]	,
["Gleba",	232.70652	]	,
["Folga",	232.71426	]	,
["Barczuk",	232.722	]	,
["Slebioda",	232.72973	]	,
["Rudecki",	232.73746	]	,
["Olma",	232.74519	]	,
["Kusnierek",	232.75292	]	,
["Kubanski",	232.76065	]	,
["Krzan",	232.76838	]	,
["Hubert",	232.77611	]	,
["Grzebyk",	232.78384	]	,
["Fras",	232.79157	]	,
["Durlej",	232.7993	]	,
["Ciskowski",	232.80703	]	,
["Baclawski",	232.81476	]	,
["Pielach",	232.82248	]	,
["Klin",	232.8302	]	,
["Kiersnowski",	232.83792	]	,
["Jedrak",	232.84564	]	,
["Frelek",	232.85336	]	,
["Chechelski",	232.86108	]	,
["Brzakala",	232.8688	]	,
["Borysiak",	232.87652	]	,
["Zagozda",	232.88423	]	,
["Sliz",	232.89194	]	,
["Szkopek",	232.89965	]	,
["Razny",	232.90736	]	,
["Olearczyk",	232.91507	]	,
["Mironczuk",	232.92278	]	,
["Kruszczynski",	232.93049	]	,
["Kolacinski",	232.9382	]	,
["Chyb",	232.94591	]	,
["Zybura",	232.95361	]	,
["Zelazo",	232.96131	]	,
["Kunka",	232.96901	]	,
["Kryszewski",	232.97671	]	,
["Kosalka",	232.98441	]	,
["Idzkowski",	232.99211	]	,
["Gosz",	232.99981	]	,
["Dulas",	233.00751	]	,
["Zelazek",	233.0152	]	,
["Terka",	233.02289	]	,
["Sosniak",	233.03058	]	,
["Pikor",	233.03827	]	,
["Pezda",	233.04596	]	,
["Laniewski",	233.05365	]	,
["Kiljanski",	233.06134	]	,
["Hadam",	233.06903	]	,
["Gron",	233.07672	]	,
["Fal",	233.08441	]	,
["Chalimoniuk",	233.0921	]	,
["Buraczynski",	233.09979	]	,
["Kilianski",	233.10747	]	,
["Karnas",	233.11515	]	,
["Uzieblo",	233.12282	]	,
["Grochola",	233.13049	]	,
["Gawliczek",	233.13816	]	,
["Freitag",	233.14583	]	,
["Cmiel",	233.1535	]	,
["Waclaw",	233.16116	]	,
["Symonowicz",	233.16882	]	,
["Strzoda",	233.17648	]	,
["Sterna",	233.18414	]	,
["Spadlo",	233.1918	]	,
["Skierkowski",	233.19946	]	,
["Rajtar",	233.20712	]	,
["Krzykala",	233.21478	]	,
["Ignaszewski",	233.22244	]	,
["Holc",	233.2301	]	,
["Gurzynski",	233.23776	]	,
["Gronostaj",	233.24542	]	,
["Barej",	233.25308	]	,
["Wasilewicz",	233.26073	]	,
["Podgórny",	233.26838	]	,
["Lastowski",	233.27603	]	,
["Lapot",	233.28368	]	,
["Lepak",	233.29133	]	,
["Hojda",	233.29898	]	,
["Grzonkowski",	233.30663	]	,
["Dziuda",	233.31428	]	,
["Burdzinski",	233.32193	]	,
["Seczkowski",	233.32957	]	,
["Przybylinski",	233.33721	]	,
["Liwinski",	233.34485	]	,
["Kulicki",	233.35249	]	,
["Klups",	233.36013	]	,
["Brzezniak",	233.36777	]	,
["Bojarczuk",	233.37541	]	,
["Zychowski",	233.38304	]	,
["Tryka",	233.39067	]	,
["Nalewajek",	233.3983	]	,
["Lisiewski",	233.40593	]	,
["Kudlacik",	233.41356	]	,
["Kubasiewicz",	233.42119	]	,
["Bazyluk",	233.42882	]	,
["Bartoszak",	233.43645	]	,
["Zbylut",	233.44407	]	,
["Toloczko",	233.45169	]	,
["Szaruga",	233.45931	]	,
["Obuchowicz",	233.46693	]	,
["Lebkowski",	233.47455	]	,
["Gryska",	233.48217	]	,
["Gliwinski",	233.48979	]	,
["Bociek",	233.49741	]	,
["Wowra",	233.50502	]	,
["Szramka",	233.51263	]	,
["Spychaj",	233.52024	]	,
["Roj",	233.52785	]	,
["Pytkowski",	233.53546	]	,
["Musiolik",	233.54307	]	,
["Kawecki",	233.55068	]	,
["Franas",	233.55829	]	,
["Dlubak",	233.5659	]	,
["Cholewka",	233.57351	]	,
["Bobko",	233.58112	]	,
["Bialous",	233.58873	]	,
["Wilenski",	233.59633	]	,
["Smiarowski",	233.60393	]	,
["Steplewski",	233.61153	]	,
["Piaseczny",	233.61913	]	,
["Osial",	233.62673	]	,
["Nieborak",	233.63433	]	,
["Minta",	233.64193	]	,
["Lozynski",	233.64953	]	,
["Kozica",	233.65713	]	,
["Kowara",	233.66473	]	,
["Gwara",	233.67233	]	,
["Tekieli",	233.67992	]	,
["Szumanski",	233.68751	]	,
["Pancerz",	233.6951	]	,
["Mleczak",	233.70269	]	,
["Kowacki",	233.71028	]	,
["Dwornicki",	233.71787	]	,
["Chorazyczewski",	233.72546	]	,
["Celuch",	233.73305	]	,
["Bruszewski",	233.74064	]	,
["Zapiór",	233.74822	]	,
["Rogalewski",	233.7558	]	,
["Iskrzycki",	233.76338	]	,
["Grabos",	233.77096	]	,
["Fidura",	233.77854	]	,
["Cyrek",	233.78612	]	,
["Bronicki",	233.7937	]	,
["Bracha",	233.80128	]	,
["Gradek",	233.80885	]	,
["Uzdowski",	233.81641	]	,
["Noras",	233.82397	]	,
["Mulawa",	233.83153	]	,
["Moniuszko",	233.83909	]	,
["Kapcia",	233.84665	]	,
["Gumienna",	233.85421	]	,
["Graj",	233.86177	]	,
["Gilewicz",	233.86933	]	,
["Charzynski",	233.87689	]	,
["Arkuszewski",	233.88445	]	,
["Zóltek",	233.892	]	,
["Wojtalewicz",	233.89955	]	,
["Slizewski",	233.9071	]	,
["Szumny",	233.91465	]	,
["Struminski",	233.9222	]	,
["Sozanski",	233.92975	]	,
["Rembelski",	233.9373	]	,
["Opyrchal",	233.94485	]	,
["Macha",	233.9524	]	,
["Luczyk",	233.95995	]	,
["Hus",	233.9675	]	,
["Gdowski",	233.97505	]	,
["Domeradzki",	233.9826	]	,
["Czak",	233.99015	]	,
["Borzym",	233.9977	]	,
["Wojtczuk",	234.00524	]	,
["Winnik",	234.01278	]	,
["Skrzeczkowski",	234.02032	]	,
["Kuk",	234.02786	]	,
["Kubanek",	234.0354	]	,
["Dzielak",	234.04294	]	,
["Dudziec",	234.05048	]	,
["Cimoch",	234.05802	]	,
["Ciapa",	234.06556	]	,
["Buchalik",	234.0731	]	,
["Zbróg",	234.08063	]	,
["Wyzykowski",	234.08816	]	,
["Wegrzyniak",	234.09569	]	,
["Wawrzkiewicz",	234.10322	]	,
["Teodorowicz",	234.11075	]	,
["Szkola",	234.11828	]	,
["Sutor",	234.12581	]	,
["Kapuscik",	234.13334	]	,
["Hajdas",	234.14087	]	,
["Gójski",	234.1484	]	,
["Folta",	234.15593	]	,
["Dyczkowski",	234.16346	]	,
["Burkiewicz",	234.17099	]	,
["Bartnikowski",	234.17852	]	,
["Aleksa",	234.18605	]	,
["Wierucki",	234.19357	]	,
["Wajer",	234.20109	]	,
["Siembab",	234.20861	]	,
["Nurkowski",	234.21613	]	,
["Kozon",	234.22365	]	,
["Gajecki",	234.23117	]	,
["Zawacki",	234.23868	]	,
["Wojewódka",	234.24619	]	,
["Wenda",	234.2537	]	,
["Sobieralski",	234.26121	]	,
["Majos",	234.26872	]	,
["Huczek",	234.27623	]	,
["Domon",	234.28374	]	,
["Zubel",	234.29124	]	,
["Szymaniuk",	234.29874	]	,
["Saniewski",	234.30624	]	,
["Salomon",	234.31374	]	,
["Mikiciuk",	234.32124	]	,
["Grodek",	234.32874	]	,
["Zgórski",	234.33623	]	,
["Wlostowski",	234.34372	]	,
["Wieladek",	234.35121	]	,
["Trybulski",	234.3587	]	,
["Szymanczak",	234.36619	]	,
["Szwejkowski",	234.37368	]	,
["Sommer",	234.38117	]	,
["Saczuk",	234.38866	]	,
["Pastuszek",	234.39615	]	,
["Naporowski",	234.40364	]	,
["Mroczko",	234.41113	]	,
["Lokaj",	234.41862	]	,
["Deptuch",	234.42611	]	,
["Wawak",	234.43359	]	,
["Szczepaniec",	234.44107	]	,
["Romejko",	234.44855	]	,
["Rogacz",	234.45603	]	,
["Poczta",	234.46351	]	,
["Osowiecki",	234.47099	]	,
["Nowotka",	234.47847	]	,
["Klopocki",	234.48595	]	,
["Jaszcz",	234.49343	]	,
["Jany",	234.50091	]	,
["Hewelt",	234.50839	]	,
["Cirocki",	234.51587	]	,
["Tulodziecki",	234.52334	]	,
["Stachów",	234.53081	]	,
["Smykla",	234.53828	]	,
["Sedek",	234.54575	]	,
["Niemira",	234.55322	]	,
["Mlodzik",	234.56069	]	,
["Lyczek",	234.56816	]	,
["Koniecki",	234.57563	]	,
["Kleban",	234.5831	]	,
["Gizycki",	234.59057	]	,
["Fura",	234.59804	]	,
["Fudalej",	234.60551	]	,
["Cyron",	234.61298	]	,
["Blachowski",	234.62045	]	,
["Zagozdzon",	234.62791	]	,
["Kenig",	234.63537	]	,
["Górnisiewicz",	234.64283	]	,
["Gierczynski",	234.65029	]	,
["Woloszyk",	234.65774	]	,
["Wlosinski",	234.66519	]	,
["Szczypkowski",	234.67264	]	,
["Szatanik",	234.68009	]	,
["Sajda",	234.68754	]	,
["Pyrkosz",	234.69499	]	,
["Misiejuk",	234.70244	]	,
["Mirski",	234.70989	]	,
["Mikolajewicz",	234.71734	]	,
["Meczynski",	234.72479	]	,
["Kupczynski",	234.73224	]	,
["Kolsut",	234.73969	]	,
["Glenc",	234.74714	]	,
["Gasecki",	234.75459	]	,
["Eckert",	234.76204	]	,
["Dziadowicz",	234.76949	]	,
["Brylewski",	234.77694	]	,
["Waszczyk",	234.78438	]	,
["Szyba",	234.79182	]	,
["Steckiewicz",	234.79926	]	,
["Otocki",	234.8067	]	,
["Kroplewski",	234.81414	]	,
["Kloch",	234.82158	]	,
["Kabala",	234.82902	]	,
["Barcicki",	234.83646	]	,
["Zamora",	234.84389	]	,
["Tabis",	234.85132	]	,
["Sobków",	234.85875	]	,
["Pupek",	234.86618	]	,
["Neugebauer",	234.87361	]	,
["Laskarzewski",	234.88104	]	,
["Koltuniak",	234.88847	]	,
["Galek",	234.8959	]	,
["Bronski",	234.90333	]	,
["Stój",	234.91075	]	,
["Sochocki",	234.91817	]	,
["Rajda",	234.92559	]	,
["Pruchnik",	234.93301	]	,
["Miski",	234.94043	]	,
["Kuza",	234.94785	]	,
["Karaskiewicz",	234.95527	]	,
["Judek",	234.96269	]	,
["Jedryczka",	234.97011	]	,
["Grzegorzak",	234.97753	]	,
["Drobniak",	234.98495	]	,
["Chowaniak",	234.99237	]	,
["Wasek",	234.99978	]	,
["Smagacz",	235.00719	]	,
["Pedzik",	235.0146	]	,
["Kuszynski",	235.02201	]	,
["Klinger",	235.02942	]	,
["Kleczar",	235.03683	]	,
["Zdzieblowski",	235.04423	]	,
["Wochna",	235.05163	]	,
["Wieruszewski",	235.05903	]	,
["Szulborski",	235.06643	]	,
["Stachecki",	235.07383	]	,
["Rejek",	235.08123	]	,
["Krakowczyk",	235.08863	]	,
["Kobak",	235.09603	]	,
["Kawiak",	235.10343	]	,
["Grosz",	235.11083	]	,
["Czubaj",	235.11823	]	,
["Czarnomski",	235.12563	]	,
["Chorazewicz",	235.13303	]	,
["Zandecki",	235.14042	]	,
["Zadka",	235.14781	]	,
["Wietecha",	235.1552	]	,
["Tylinski",	235.16259	]	,
["Sass",	235.16998	]	,
["Mecik",	235.17737	]	,
["Gustaw",	235.18476	]	,
["Furga",	235.19215	]	,
["Fracz",	235.19954	]	,
["Dawiec",	235.20693	]	,
["Czajczynski",	235.21432	]	,
["Wypchlo",	235.2217	]	,
["Tarasek",	235.22908	]	,
["Szmaj",	235.23646	]	,
["Ornat",	235.24384	]	,
["Olbinski",	235.25122	]	,
["Justynski",	235.2586	]	,
["Huszcza",	235.26598	]	,
["Gebarowski",	235.27336	]	,
["Dudczak",	235.28074	]	,
["Babski",	235.28812	]	,
["Ulanowicz",	235.29549	]	,
["Rubin",	235.30286	]	,
["Przytarski",	235.31023	]	,
["Pich",	235.3176	]	,
["Nieciecki",	235.32497	]	,
["Makos",	235.33234	]	,
["Krepa",	235.33971	]	,
["Korek",	235.34708	]	,
["Kolanski",	235.35445	]	,
["Jonik",	235.36182	]	,
["Bukalski",	235.36919	]	,
["Andrejczuk",	235.37656	]	,
["Wiertel",	235.38392	]	,
["Sternicki",	235.39128	]	,
["Soroko",	235.39864	]	,
["Skladanek",	235.406	]	,
["Palyski",	235.41336	]	,
["Mortka",	235.42072	]	,
["Malocha",	235.42808	]	,
["Majsterek",	235.43544	]	,
["Lemanowicz",	235.4428	]	,
["Lelito",	235.45016	]	,
["Kubecki",	235.45752	]	,
["Krystkowiak",	235.46488	]	,
["Krasa",	235.47224	]	,
["Kierat",	235.4796	]	,
["Jedraszczyk",	235.48696	]	,
["Izbicki",	235.49432	]	,
["Handke",	235.50168	]	,
["Dymarczyk",	235.50904	]	,
["Doruch",	235.5164	]	,
["Beker",	235.52376	]	,
["Suwinski",	235.53111	]	,
["Peszko",	235.53846	]	,
["Osik",	235.54581	]	,
["Mroczynski",	235.55316	]	,
["Mogielnicki",	235.56051	]	,
["Milczanowski",	235.56786	]	,
["Lyp",	235.57521	]	,
["Karmelita",	235.58256	]	,
["Herdzik",	235.58991	]	,
["Brzek",	235.59726	]	,
["Bialczyk",	235.60461	]	,
["Uss",	235.61195	]	,
["Tolwinski",	235.61929	]	,
["Pitura",	235.62663	]	,
["Lusiak",	235.63397	]	,
["Konwerski",	235.64131	]	,
["Kolkowski",	235.64865	]	,
["Knapek",	235.65599	]	,
["Gumula",	235.66333	]	,
["Darlak",	235.67067	]	,
["Znojek",	235.678	]	,
["Wilkos",	235.68533	]	,
["Slesinski",	235.69266	]	,
["Rut",	235.69999	]	,
["Przekop",	235.70732	]	,
["Krecichwost",	235.71465	]	,
["Korab",	235.72198	]	,
["Józwik",	235.72931	]	,
["Jaszczynski",	235.73664	]	,
["Jagielka",	235.74397	]	,
["Chylak",	235.7513	]	,
["Zbiciak",	235.75862	]	,
["Wasaznik",	235.76594	]	,
["Tluczek",	235.77326	]	,
["Syldatk",	235.78058	]	,
["Suchorski",	235.7879	]	,
["Skrzydlewski",	235.79522	]	,
["Piankowski",	235.80254	]	,
["Pagowski",	235.80986	]	,
["Parkitny",	235.81718	]	,
["Olborski",	235.8245	]	,
["Moraczewski",	235.83182	]	,
["Juroszek",	235.83914	]	,
["Jeleniewski",	235.84646	]	,
["Groblewski",	235.85378	]	,
["Wisz",	235.86109	]	,
["Wiciak",	235.8684	]	,
["Szczepinski",	235.87571	]	,
["Szamocki",	235.88302	]	,
["Palonek",	235.89033	]	,
["Makowski",	235.89764	]	,
["Kusik",	235.90495	]	,
["Kocurek",	235.91226	]	,
["Kochmanski",	235.91957	]	,
["Klodnicki",	235.92688	]	,
["Kacperczyk",	235.93419	]	,
["Dworecki",	235.9415	]	,
["Bluszcz",	235.94881	]	,
["Wydmuch",	235.95611	]	,
["Wereda",	235.96341	]	,
["Trybala",	235.97071	]	,
["Sleszynski",	235.97801	]	,
["Sito",	235.98531	]	,
["Pietraszkiewicz",	235.99261	]	,
["Nojek",	235.99991	]	,
["Madziar",	236.00721	]	,
["Kazana",	236.01451	]	,
["Tamborski",	236.0218	]	,
["Szulczyk",	236.02909	]	,
["Rosolek",	236.03638	]	,
["Roskosz",	236.04367	]	,
["Proc",	236.05096	]	,
["Mazek",	236.05825	]	,
["Koniecko",	236.06554	]	,
["Horbacz",	236.07283	]	,
["Bujakowski",	236.08012	]	,
["Zastawny",	236.0874	]	,
["Tyborowski",	236.09468	]	,
["Swistowski",	236.10196	]	,
["Piernicki",	236.10924	]	,
["Orszulik",	236.11652	]	,
["Mydlowski",	236.1238	]	,
["Mesjasz",	236.13108	]	,
["Margas",	236.13836	]	,
["Lazicki",	236.14564	]	,
["Kozlak",	236.15292	]	,
["Korpalski",	236.1602	]	,
["Dzidek",	236.16748	]	,
["Damek",	236.17476	]	,
["Zinkiewicz",	236.18203	]	,
["Sznura",	236.1893	]	,
["Sapala",	236.19657	]	,
["Piaseczna",	236.20384	]	,
["Osada",	236.21111	]	,
["Koziarz",	236.21838	]	,
["Korta",	236.22565	]	,
["Klosiewicz",	236.23292	]	,
["Klyszcz",	236.24019	]	,
["Janoszek",	236.24746	]	,
["Deszcz",	236.25473	]	,
["Zubrowski",	236.26199	]	,
["Zawadzinski",	236.26925	]	,
["Okla",	236.27651	]	,
["Matacz",	236.28377	]	,
["Hankiewicz",	236.29103	]	,
["Front",	236.29829	]	,
["Daraz",	236.30555	]	,
["Czura",	236.31281	]	,
["Bylina",	236.32007	]	,
["Bugiel",	236.32733	]	,
["Aniola",	236.33459	]	,
["Amanowicz",	236.34185	]	,
["Zalejski",	236.3491	]	,
["Zach",	236.35635	]	,
["Wilewski",	236.3636	]	,
["Starosciak",	236.37085	]	,
["Kliszcz",	236.3781	]	,
["Hadala",	236.38535	]	,
["Czopik",	236.3926	]	,
["Cysewski",	236.39985	]	,
["Chodnicki",	236.4071	]	,
["Chmielarski",	236.41435	]	,
["Bytomski",	236.4216	]	,
["Bytner",	236.42885	]	,
["Budkowski",	236.4361	]	,
["Wosko",	236.44334	]	,
["Wawrzyn",	236.45058	]	,
["Swit",	236.45782	]	,
["Sanetra",	236.46506	]	,
["Pyszczek",	236.4723	]	,
["Potaczek",	236.47954	]	,
["Pelowski",	236.48678	]	,
["Osman",	236.49402	]	,
["Materka",	236.50126	]	,
["Malawski",	236.5085	]	,
["Madura",	236.51574	]	,
["Kniaz",	236.52298	]	,
["Gryciuk",	236.53022	]	,
["Fidor",	236.53746	]	,
["Dunal",	236.5447	]	,
["Dobron",	236.55194	]	,
["Dembicki",	236.55918	]	,
["Czaicki",	236.56642	]	,
["Chlebda",	236.57366	]	,
["Slupik",	236.58089	]	,
["Osica",	236.58812	]	,
["Oleksak",	236.59535	]	,
["Maraszek",	236.60258	]	,
["Kubski",	236.60981	]	,
["Kregiel",	236.61704	]	,
["Kopytko",	236.62427	]	,
["Gomola",	236.6315	]	,
["Drozdziel",	236.63873	]	,
["Szott",	236.64595	]	,
["Szkup",	236.65317	]	,
["Posmyk",	236.66039	]	,
["Mlotek",	236.66761	]	,
["Matyszewski",	236.67483	]	,
["Kubalski",	236.68205	]	,
["Klejna",	236.68927	]	,
["Jalowiec",	236.69649	]	,
["Heinrich",	236.70371	]	,
["Haraburda",	236.71093	]	,
["Grupa",	236.71815	]	,
["Grabarski",	236.72537	]	,
["Dziadkiewicz",	236.73259	]	,
["Butowski",	236.73981	]	,
["Baczynski",	236.74703	]	,
["Zaczyk",	236.75424	]	,
["Wislocki",	236.76145	]	,
["Wicki",	236.76866	]	,
["Szczerbicki",	236.77587	]	,
["Rapa",	236.78308	]	,
["Nietupski",	236.79029	]	,
["Lodej",	236.7975	]	,
["Lempart",	236.80471	]	,
["Lamch",	236.81192	]	,
["Krzyczkowski",	236.81913	]	,
["Gluszko",	236.82634	]	,
["Cudzich",	236.83355	]	,
["Brojek",	236.84076	]	,
["Ziemak",	236.84796	]	,
["Ulicki",	236.85516	]	,
["Tusk",	236.86236	]	,
["Kieloch",	236.86956	]	,
["Dziduch",	236.87676	]	,
["Dudkowiak",	236.88396	]	,
["Czerner",	236.89116	]	,
["Sommerfeld",	236.89835	]	,
["Okurowski",	236.90554	]	,
["Muczynski",	236.91273	]	,
["Migon",	236.91992	]	,
["Macheta",	236.92711	]	,
["Dusik",	236.9343	]	,
["Cwirko",	236.94149	]	,
["Bilik",	236.94868	]	,
["Sydor",	236.95586	]	,
["Swiatek",	236.96304	]	,
["Sporek",	236.97022	]	,
["Olesiejuk",	236.9774	]	,
["Kutek",	236.98458	]	,
["Kordalski",	236.99176	]	,
["Jaszczur",	236.99894	]	,
["Jarmuz",	237.00612	]	,
["Gronkiewicz",	237.0133	]	,
["Witan",	237.02047	]	,
["Staniczek",	237.02764	]	,
["Sokolnicki",	237.03481	]	,
["Smyczynski",	237.04198	]	,
["Rzaca",	237.04915	]	,
["Roter",	237.05632	]	,
["Pracz",	237.06349	]	,
["Politanski",	237.07066	]	,
["Hnat",	237.07783	]	,
["Cydzik",	237.085	]	,
["Ciolkowski",	237.09217	]	,
["Szatko",	237.09933	]	,
["Styrna",	237.10649	]	,
["Podlesna",	237.11365	]	,
["Oleksa",	237.12081	]	,
["Niescior",	237.12797	]	,
["Matyjaszek",	237.13513	]	,
["Malski",	237.14229	]	,
["Lasica",	237.14945	]	,
["Kwapien",	237.15661	]	,
["Kozubski",	237.16377	]	,
["Koronkiewicz",	237.17093	]	,
["Klinski",	237.17809	]	,
["Izycki",	237.18525	]	,
["Holota",	237.19241	]	,
["Elert",	237.19957	]	,
["Czochara",	237.20673	]	,
["Toczko",	237.21388	]	,
["Swies",	237.22103	]	,
["Slysz",	237.22818	]	,
["Salach",	237.23533	]	,
["Nojszewski",	237.24248	]	,
["Lesna",	237.24963	]	,
["Glownia",	237.25678	]	,
["Galica",	237.26393	]	,
["Filarski",	237.27108	]	,
["Cieniuch",	237.27823	]	,
["Bialobrzewski",	237.28538	]	,
["Szulist",	237.29252	]	,
["Rybkowski",	237.29966	]	,
["Pedrycz",	237.3068	]	,
["Nowogórski",	237.31394	]	,
["Królczyk",	237.32108	]	,
["Konwinski",	237.32822	]	,
["Kociecki",	237.33536	]	,
["Jarzebinski",	237.3425	]	,
["Graczykowski",	237.34964	]	,
["Dymerski",	237.35678	]	,
["Zyzik",	237.36391	]	,
["Zaborek",	237.37104	]	,
["Skalka",	237.37817	]	,
["Sankiewicz",	237.3853	]	,
["Pleban",	237.39243	]	,
["Martin",	237.39956	]	,
["Lewek",	237.40669	]	,
["Jedrys",	237.41382	]	,
["Guzdek",	237.42095	]	,
["Golkowski",	237.42808	]	,
["Dumala",	237.43521	]	,
["Zasadzinski",	237.44233	]	,
["Wszola",	237.44945	]	,
["Rebis",	237.45657	]	,
["Posnik",	237.46369	]	,
["Porzucek",	237.47081	]	,
["Hawro",	237.47793	]	,
["Gladki",	237.48505	]	,
["Faszczewski",	237.49217	]	,
["Dziób",	237.49929	]	,
["Zwara",	237.5064	]	,
["Wiraszka",	237.51351	]	,
["Romankiewicz",	237.52062	]	,
["Roch",	237.52773	]	,
["Palen",	237.53484	]	,
["Ogonek",	237.54195	]	,
["Motylinski",	237.54906	]	,
["Makar",	237.55617	]	,
["Majdan",	237.56328	]	,
["Kozdrój",	237.57039	]	,
["Kozdron",	237.5775	]	,
["Jachna",	237.58461	]	,
["Dziedzinski",	237.59172	]	,
["Duniec",	237.59883	]	,
["Dulak",	237.60594	]	,
["Bialoskórski",	237.61305	]	,
["Wojtanowicz",	237.62015	]	,
["Waskowski",	237.62725	]	,
["Waloch",	237.63435	]	,
["Ubysz",	237.64145	]	,
["Stozek",	237.64855	]	,
["Poweski",	237.65565	]	,
["Malycha",	237.66275	]	,
["Kobierecki",	237.66985	]	,
["Kmak",	237.67695	]	,
["Hass",	237.68405	]	,
["Frydrychowicz",	237.69115	]	,
["Domka",	237.69825	]	,
["Chachulski",	237.70535	]	,
["Zugaj",	237.71244	]	,
["Zubowicz",	237.71953	]	,
["Wyrwal",	237.72662	]	,
["Perlowski",	237.73371	]	,
["Mordal",	237.7408	]	,
["Kordys",	237.74789	]	,
["Grochalski",	237.75498	]	,
["Gozdur",	237.76207	]	,
["Gabrych",	237.76916	]	,
["Zbrozek",	237.77624	]	,
["Zbroszczyk",	237.78332	]	,
["Wojton",	237.7904	]	,
["Tórz",	237.79748	]	,
["Torbus",	237.80456	]	,
["Rzeczycki",	237.81164	]	,
["Letkiewicz",	237.81872	]	,
["Lampart",	237.8258	]	,
["Turecki",	237.83287	]	,
["Terpilowski",	237.83994	]	,
["Superson",	237.84701	]	,
["Sopata",	237.85408	]	,
["Sobilo",	237.86115	]	,
["Sapa",	237.86822	]	,
["Salwin",	237.87529	]	,
["Pera",	237.88236	]	,
["Organisciak",	237.88943	]	,
["Nowogrodzki",	237.8965	]	,
["Napierski",	237.90357	]	,
["Matwiejczyk",	237.91064	]	,
["Matejuk",	237.91771	]	,
["Maly",	237.92478	]	,
["Lochowski",	237.93185	]	,
["Lorkowski",	237.93892	]	,
["Krüger",	237.94599	]	,
["Jasniewski",	237.95306	]	,
["Jachowski",	237.96013	]	,
["Dyszkiewicz",	237.9672	]	,
["Boinski",	237.97427	]	,
["Basak",	237.98134	]	,
["Ankiewicz",	237.98841	]	,
["Adamiuk",	237.99548	]	,
["Zieleniewski",	238.00254	]	,
["Sykala",	238.0096	]	,
["Skonieczka",	238.01666	]	,
["Pawelko",	238.02372	]	,
["Obidzinski",	238.03078	]	,
["Nojman",	238.03784	]	,
["Iskierka",	238.0449	]	,
["Ziecik",	238.05195	]	,
["Zboralski",	238.059	]	,
["Zaskórski",	238.06605	]	,
["Wyczólkowski",	238.0731	]	,
["Wawrzonkowski",	238.08015	]	,
["Trojanek",	238.0872	]	,
["Slocinski",	238.09425	]	,
["Sadlak",	238.1013	]	,
["Równicki",	238.10835	]	,
["Nieradko",	238.1154	]	,
["Litynski",	238.12245	]	,
["Behrendt",	238.1295	]	,
["Wojewodzic",	238.13654	]	,
["Polewka",	238.14358	]	,
["Obszynski",	238.15062	]	,
["Koziorowski",	238.15766	]	,
["Dziwulski",	238.1647	]	,
["Zubrycki",	238.17173	]	,
["Zasepa",	238.17876	]	,
["Szczerek",	238.18579	]	,
["Szalata",	238.19282	]	,
["Sot",	238.19985	]	,
["Paruszewski",	238.20688	]	,
["Mleczek",	238.21391	]	,
["Kukawka",	238.22094	]	,
["Kobrzynski",	238.22797	]	,
["Kaczmarkiewicz",	238.235	]	,
["Dorobek",	238.24203	]	,
["Danilowski",	238.24906	]	,
["Cukrowski",	238.25609	]	,
["Burchard",	238.26312	]	,
["Blaut",	238.27015	]	,
["Bardzinski",	238.27718	]	,
["Witka",	238.2842	]	,
["Skomorowski",	238.29122	]	,
["Sasak",	238.29824	]	,
["Pludowski",	238.30526	]	,
["Pasiak",	238.31228	]	,
["Panasiewicz",	238.3193	]	,
["Oleksinski",	238.32632	]	,
["Motak",	238.33334	]	,
["Lizurej",	238.34036	]	,
["Kubon",	238.34738	]	,
["Jedraszek",	238.3544	]	,
["Flasinski",	238.36142	]	,
["Dylik",	238.36844	]	,
["Cal",	238.37546	]	,
["Buszko",	238.38248	]	,
["Burnat",	238.3895	]	,
["Wyskiel",	238.39651	]	,
["Winek",	238.40352	]	,
["Wiertelak",	238.41053	]	,
["Wiak",	238.41754	]	,
["Rusinski",	238.42455	]	,
["Ros",	238.43156	]	,
["Rakowiecki",	238.43857	]	,
["Orzeszek",	238.44558	]	,
["Ochota",	238.45259	]	,
["Mijas",	238.4596	]	,
["Maculewicz",	238.46661	]	,
["Kaja",	238.47362	]	,
["Ciesielka",	238.48063	]	,
["Bejm",	238.48764	]	,
["Trzesniowski",	238.49464	]	,
["Szmuc",	238.50164	]	,
["Sygut",	238.50864	]	,
["Staszynski",	238.51564	]	,
["Siarkiewicz",	238.52264	]	,
["Ryznar",	238.52964	]	,
["Patoka",	238.53664	]	,
["Miszkurka",	238.54364	]	,
["Kudelka",	238.55064	]	,
["Krzysko",	238.55764	]	,
["Koszykowski",	238.56464	]	,
["Galon",	238.57164	]	,
["Buczma",	238.57864	]	,
["Ziegler",	238.58563	]	,
["Wisinski",	238.59262	]	,
["Uroda",	238.59961	]	,
["Turczyk",	238.6066	]	,
["Tolak",	238.61359	]	,
["Sypula",	238.62058	]	,
["Slojewski",	238.62757	]	,
["Sadowy",	238.63456	]	,
["Rasala",	238.64155	]	,
["Myslowski",	238.64854	]	,
["Kazubek",	238.65553	]	,
["Han",	238.66252	]	,
["Wasiuk",	238.6695	]	,
["Tucki",	238.67648	]	,
["Strykowski",	238.68346	]	,
["Stempin",	238.69044	]	,
["Stawczyk",	238.69742	]	,
["Prokopiak",	238.7044	]	,
["Pospiech",	238.71138	]	,
["Polakiewicz",	238.71836	]	,
["Olas",	238.72534	]	,
["Matracki",	238.73232	]	,
["Maruszczyk",	238.7393	]	,
["Kapinos",	238.74628	]	,
["Kabza",	238.75326	]	,
["Tuchowski",	238.76023	]	,
["Szwalek",	238.7672	]	,
["Smagala",	238.77417	]	,
["Patynowski",	238.78114	]	,
["Musiala",	238.78811	]	,
["Miksza",	238.79508	]	,
["Magierowski",	238.80205	]	,
["Lampa",	238.80902	]	,
["Kulon",	238.81599	]	,
["Koczara",	238.82296	]	,
["Gumkowski",	238.82993	]	,
["Drynda",	238.8369	]	,
["Szmaglinski",	238.84386	]	,
["Szczypiór",	238.85082	]	,
["Ratajewski",	238.85778	]	,
["Pawelkiewicz",	238.86474	]	,
["Myk",	238.8717	]	,
["Kuczak",	238.87866	]	,
["Kolata",	238.88562	]	,
["Zywica",	238.89257	]	,
["Tondera",	238.89952	]	,
["Szmalec",	238.90647	]	,
["Szczerkowski",	238.91342	]	,
["Szczap",	238.92037	]	,
["Sypien",	238.92732	]	,
["Soltysek",	238.93427	]	,
["Mosur",	238.94122	]	,
["Kosciesza",	238.94817	]	,
["Kosowicz",	238.95512	]	,
["Kolendo",	238.96207	]	,
["Huber",	238.96902	]	,
["Giel",	238.97597	]	,
["Galeza",	238.98292	]	,
["Dyja",	238.98987	]	,
["Czubkowski",	238.99682	]	,
["Czarnacki",	239.00377	]	,
["Cacko",	239.01072	]	,
["Apanowicz",	239.01767	]	,
["Wilmanski",	239.02461	]	,
["Wandas",	239.03155	]	,
["Tabaczynski",	239.03849	]	,
["Siebert",	239.04543	]	,
["Sarbinowski",	239.05237	]	,
["Moneta",	239.05931	]	,
["Izykowski",	239.06625	]	,
["Zylowski",	239.07318	]	,
["Ziajka",	239.08011	]	,
["Skubiszewski",	239.08704	]	,
["Sieg",	239.09397	]	,
["Paluszak",	239.1009	]	,
["Lichon",	239.10783	]	,
["Kastelik",	239.11476	]	,
["Gwizdek",	239.12169	]	,
["Golembiewski",	239.12862	]	,
["Drewa",	239.13555	]	,
["Barcinski",	239.14248	]	,
["Andrys",	239.14941	]	,
["Zbrzezniak",	239.15633	]	,
["Wlazly",	239.16325	]	,
["Wittbrodt",	239.17017	]	,
["Niksa",	239.17709	]	,
["Lubojanski",	239.18401	]	,
["Habdas",	239.19093	]	,
["Frys",	239.19785	]	,
["Doktór",	239.20477	]	,
["Detka",	239.21169	]	,
["Cieplucha",	239.21861	]	,
["Ciarka",	239.22553	]	,
["Brzuski",	239.23245	]	,
["Witkowicz",	239.23936	]	,
["Wardzala",	239.24627	]	,
["Szarkowski",	239.25318	]	,
["Stapór",	239.26009	]	,
["Pniak",	239.267	]	,
["Pierzak",	239.27391	]	,
["Lagocki",	239.28082	]	,
["Kryk",	239.28773	]	,
["Kozuszek",	239.29464	]	,
["Kohnke",	239.30155	]	,
["Kapalka",	239.30846	]	,
["Domino",	239.31537	]	,
["Czuj",	239.32228	]	,
["Boksa",	239.32919	]	,
["Bachowski",	239.3361	]	,
["Wocial",	239.343	]	,
["Stuglik",	239.3499	]	,
["Steciuk",	239.3568	]	,
["Smela",	239.3637	]	,
["Plona",	239.3706	]	,
["Piwowarek",	239.3775	]	,
["Pernak",	239.3844	]	,
["Minkina",	239.3913	]	,
["Klos",	239.3982	]	,
["Halik",	239.4051	]	,
["Garlicki",	239.412	]	,
["Dzika",	239.4189	]	,
["Dargacz",	239.4258	]	,
["Damian",	239.4327	]	,
["Adrian",	239.4396	]	,
["Wykowski",	239.44649	]	,
["Wegrzynek",	239.45338	]	,
["Tomal",	239.46027	]	,
["Swierad",	239.46716	]	,
["Szkatula",	239.47405	]	,
["Sajnóg",	239.48094	]	,
["Kudlak",	239.48783	]	,
["Karwecki",	239.49472	]	,
["Golczyk",	239.50161	]	,
["Fronczyk",	239.5085	]	,
["Czapiga",	239.51539	]	,
["Chodacki",	239.52228	]	,
["Blazejak",	239.52917	]	,
["Bejma",	239.53606	]	,
["Bartela",	239.54295	]	,
["Tokarzewski",	239.54983	]	,
["Tadeusiak",	239.55671	]	,
["Nedzi",	239.56359	]	,
["Kurcz",	239.57047	]	,
["Jasionek",	239.57735	]	,
["Heleniak",	239.58423	]	,
["Ziarek",	239.5911	]	,
["Zera",	239.59797	]	,
["Weclawski",	239.60484	]	,
["Sarniak",	239.61171	]	,
["Rózak",	239.61858	]	,
["Plaszewski",	239.62545	]	,
["Packowski",	239.63232	]	,
["Ligas",	239.63919	]	,
["Kuzior",	239.64606	]	,
["Kuder",	239.65293	]	,
["Korzeniak",	239.6598	]	,
["Kinowski",	239.66667	]	,
["Fac",	239.67354	]	,
["Domowicz",	239.68041	]	,
["Debniak",	239.68728	]	,
["Cieciora",	239.69415	]	,
["Chaberek",	239.70102	]	,
["Bogusiewicz",	239.70789	]	,
["Block",	239.71476	]	,
["Wardziak",	239.72162	]	,
["Prawdzik",	239.72848	]	,
["Niebudek",	239.73534	]	,
["Kuziemski",	239.7422	]	,
["Krzewicki",	239.74906	]	,
["Kierski",	239.75592	]	,
["Jeszka",	239.76278	]	,
["Baszynski",	239.76964	]	,
["Szpyrka",	239.77649	]	,
["Szkaradek",	239.78334	]	,
["Starek",	239.79019	]	,
["Pasich",	239.79704	]	,
["Moscinski",	239.80389	]	,
["Milejski",	239.81074	]	,
["Macinski",	239.81759	]	,
["Lademann",	239.82444	]	,
["Jantos",	239.83129	]	,
["Grzelec",	239.83814	]	,
["Czaczkowski",	239.84499	]	,
["Zapora",	239.85183	]	,
["Wnuczek",	239.85867	]	,
["Wasala",	239.86551	]	,
["Rycharski",	239.87235	]	,
["Pompa",	239.87919	]	,
["Narolski",	239.88603	]	,
["Malas",	239.89287	]	,
["Janka",	239.89971	]	,
["Galaj",	239.90655	]	,
["Dybal",	239.91339	]	,
["Druzkowski",	239.92023	]	,
["Chromy",	239.92707	]	,
["Szpyt",	239.9339	]	,
["Sobieszczanski",	239.94073	]	,
["Senger",	239.94756	]	,
["Prygiel",	239.95439	]	,
["Pawela",	239.96122	]	,
["Obiedzinski",	239.96805	]	,
["Lakota",	239.97488	]	,
["Jama",	239.98171	]	,
["Graban",	239.98854	]	,
["Fogt",	239.99537	]	,
["Chrapkowski",	240.0022	]	,
["Cebulak",	240.00903	]	,
["Boryczko",	240.01586	]	,
["Bojdo",	240.02269	]	,
["Biesek",	240.02952	]	,
["Arendarczyk",	240.03635	]	,
["Sobiechowski",	240.04317	]	,
["Schubert",	240.04999	]	,
["Namysl",	240.05681	]	,
["Milewczyk",	240.06363	]	,
["Lubowicki",	240.07045	]	,
["Hetmanczyk",	240.07727	]	,
["Dyczko",	240.08409	]	,
["Dankiewicz",	240.09091	]	,
["Czerniec",	240.09773	]	,
["Chrustowski",	240.10455	]	,
["Stasko",	240.11136	]	,
["Skurski",	240.11817	]	,
["Rochowiak",	240.12498	]	,
["Podlasinski",	240.13179	]	,
["Parszewski",	240.1386	]	,
["Misiuk",	240.14541	]	,
["Markiel",	240.15222	]	,
["Ksel",	240.15903	]	,
["Krzyzostaniak",	240.16584	]	,
["Elwart",	240.17265	]	,
["Delekta",	240.17946	]	,
["Zebik",	240.18626	]	,
["Wojnarski",	240.19306	]	,
["Siatka",	240.19986	]	,
["Rzadkowski",	240.20666	]	,
["Niewiara",	240.21346	]	,
["Miozga",	240.22026	]	,
["Metel",	240.22706	]	,
["Letowski",	240.23386	]	,
["Korgul",	240.24066	]	,
["Karwan",	240.24746	]	,
["Grzankowski",	240.25426	]	,
["Garski",	240.26106	]	,
["Franków",	240.26786	]	,
["Domek",	240.27466	]	,
["Ciepluch",	240.28146	]	,
["Chojna",	240.28826	]	,
["Surmiak",	240.29505	]	,
["Strama",	240.30184	]	,
["Stein",	240.30863	]	,
["Siewiera",	240.31542	]	,
["Robaszkiewicz",	240.32221	]	,
["Piksa",	240.329	]	,
["Kociemba",	240.33579	]	,
["Klyta",	240.34258	]	,
["Gromala",	240.34937	]	,
["Gill",	240.35616	]	,
["Broszkiewicz",	240.36295	]	,
["Blazejowski",	240.36974	]	,
["Zontek",	240.37652	]	,
["Stiller",	240.3833	]	,
["Soski",	240.39008	]	,
["Rosada",	240.39686	]	,
["Mieloch",	240.40364	]	,
["Krzepkowski",	240.41042	]	,
["Kornak",	240.4172	]	,
["Goworek",	240.42398	]	,
["Ginalski",	240.43076	]	,
["Gadzala",	240.43754	]	,
["Fitas",	240.44432	]	,
["Dluski",	240.4511	]	,
["Uzar",	240.45787	]	,
["Siedlarz",	240.46464	]	,
["Rorat",	240.47141	]	,
["Rokowski",	240.47818	]	,
["Podzorski",	240.48495	]	,
["Oskroba",	240.49172	]	,
["Mitera",	240.49849	]	,
["Kordylewski",	240.50526	]	,
["Grygorcewicz",	240.51203	]	,
["Gmurczyk",	240.5188	]	,
["Gadecki",	240.52557	]	,
["Faliszewski",	240.53234	]	,
["Dylak",	240.53911	]	,
["Drygalski",	240.54588	]	,
["Zybura",	240.55264	]	,
["Wojtaszak",	240.5594	]	,
["Wisla",	240.56616	]	,
["Wasyluk",	240.57292	]	,
["Szyperski",	240.57968	]	,
["Szalkiewicz",	240.58644	]	,
["Szalapski",	240.5932	]	,
["Popinski",	240.59996	]	,
["Krzysztoszek",	240.60672	]	,
["Kosciuszko",	240.61348	]	,
["Kasiak",	240.62024	]	,
["Kalecinski",	240.627	]	,
["Chmurski",	240.63376	]	,
["Wyrwich",	240.64051	]	,
["Woloszczuk",	240.64726	]	,
["Sledzik",	240.65401	]	,
["Smorag",	240.66076	]	,
["Satora",	240.66751	]	,
["Pochron",	240.67426	]	,
["Melaniuk",	240.68101	]	,
["Laskawski",	240.68776	]	,
["Jajko",	240.69451	]	,
["Czajor",	240.70126	]	,
["Bajko",	240.70801	]	,
["Wojslaw",	240.71475	]	,
["Trocinski",	240.72149	]	,
["Szumiec",	240.72823	]	,
["Nehring",	240.73497	]	,
["Naumiuk",	240.74171	]	,
["Luberda",	240.74845	]	,
["Kesek",	240.75519	]	,
["Jaskowiec",	240.76193	]	,
["Gubanski",	240.76867	]	,
["Foit",	240.77541	]	,
["Fita",	240.78215	]	,
["Fedyk",	240.78889	]	,
["Dziala",	240.79563	]	,
["Cygal",	240.80237	]	,
["Chronowski",	240.80911	]	,
["Calinski",	240.81585	]	,
["Antoniewski",	240.82259	]	,
["Ziminski",	240.82932	]	,
["Zdancewicz",	240.83605	]	,
["Walocha",	240.84278	]	,
["Toma",	240.84951	]	,
["Soczewka",	240.85624	]	,
["Monkiewicz",	240.86297	]	,
["Majtyka",	240.8697	]	,
["Hynek",	240.87643	]	,
["Dynia",	240.88316	]	,
["Czurylo",	240.88989	]	,
["Bernatek",	240.89662	]	,
["Apostel",	240.90335	]	,
["Zawiasa",	240.91007	]	,
["Piersa",	240.91679	]	,
["Megger",	240.92351	]	,
["Maszewski",	240.93023	]	,
["Machnikowski",	240.93695	]	,
["Kukier",	240.94367	]	,
["Kitlinski",	240.95039	]	,
["Jarka",	240.95711	]	,
["Glazik",	240.96383	]	,
["Dzierzynski",	240.97055	]	,
["Dyjas",	240.97727	]	,
["Czachowski",	240.98399	]	,
["Bus",	240.99071	]	,
["Bona",	240.99743	]	,
["Bandyk",	241.00415	]	,
["Zieciak",	241.01086	]	,
["Talarowski",	241.01757	]	,
["Krajniak",	241.02428	]	,
["Koperek",	241.03099	]	,
["Kleszczynski",	241.0377	]	,
["Kazberuk",	241.04441	]	,
["Dziewior",	241.05112	]	,
["Chachaj",	241.05783	]	,
["Albinski",	241.06454	]	,
["Szewczykowski",	241.07124	]	,
["Soloducha",	241.07794	]	,
["Slomiany",	241.08464	]	,
["Slabicki",	241.09134	]	,
["Skolik",	241.09804	]	,
["Roclawski",	241.10474	]	,
["Peksa",	241.11144	]	,
["Mularz",	241.11814	]	,
["Kosman",	241.12484	]	,
["Kolonko",	241.13154	]	,
["Januszewicz",	241.13824	]	,
["Gramza",	241.14494	]	,
["Foremniak",	241.15164	]	,
["Fijalek",	241.15834	]	,
["Cierpka",	241.16504	]	,
["Polnik",	241.17173	]	,
["Perski",	241.17842	]	,
["Krzoski",	241.18511	]	,
["Klaczynski",	241.1918	]	,
["Kaszycki",	241.19849	]	,
["Drwiega",	241.20518	]	,
["Cimochowski",	241.21187	]	,
["Chybowski",	241.21856	]	,
["Wojakowski",	241.22524	]	,
["Szulkowski",	241.23192	]	,
["Strusinski",	241.2386	]	,
["Stodolski",	241.24528	]	,
["Semenowicz",	241.25196	]	,
["Pieszak",	241.25864	]	,
["Narozna",	241.26532	]	,
["Ladniak",	241.272	]	,
["Kontny",	241.27868	]	,
["Klemens",	241.28536	]	,
["Jancewicz",	241.29204	]	,
["Faferek",	241.29872	]	,
["Czerniewski",	241.3054	]	,
["Bisaga",	241.31208	]	,
["Zlotnik",	241.31875	]	,
["Wosiek",	241.32542	]	,
["Uzarski",	241.33209	]	,
["Supernak",	241.33876	]	,
["Mialkowski",	241.34543	]	,
["Kala",	241.3521	]	,
["Giza",	241.35877	]	,
["Czyzykowski",	241.36544	]	,
["Cukierski",	241.37211	]	,
["Brudzynski",	241.37878	]	,
["Bielat",	241.38545	]	,
["Zyto",	241.39211	]	,
["Wenerski",	241.39877	]	,
["Sobkowski",	241.40543	]	,
["Rompa",	241.41209	]	,
["Kurpanik",	241.41875	]	,
["Kolpak",	241.42541	]	,
["Grzesinski",	241.43207	]	,
["Golas",	241.43873	]	,
["Dlugozima",	241.44539	]	,
["Badzinski",	241.45205	]	,
["Bacia",	241.45871	]	,
["Zagdanski",	241.46536	]	,
["Wincenciak",	241.47201	]	,
["Wiernicki",	241.47866	]	,
["Wegrzynski",	241.48531	]	,
["Toczynski",	241.49196	]	,
["Slaski",	241.49861	]	,
["Styn",	241.50526	]	,
["Osmulski",	241.51191	]	,
["Moczko",	241.51856	]	,
["Lechtanski",	241.52521	]	,
["Langier",	241.53186	]	,
["Chabrowski",	241.53851	]	,
["Szrama",	241.54515	]	,
["Szok",	241.55179	]	,
["Suchenek",	241.55843	]	,
["Sokolinski",	241.56507	]	,
["Pieczarka",	241.57171	]	,
["Parus",	241.57835	]	,
["Machul",	241.58499	]	,
["Latko",	241.59163	]	,
["Krzysków",	241.59827	]	,
["Kosiedowski",	241.60491	]	,
["Grotowski",	241.61155	]	,
["Galos",	241.61819	]	,
["Ekert",	241.62483	]	,
["Drzymalski",	241.63147	]	,
["Dawidek",	241.63811	]	,
["Czerkies",	241.64475	]	,
["Bujas",	241.65139	]	,
["Andryszczyk",	241.65803	]	,
["Zuziak",	241.66466	]	,
["Wegrzyk",	241.67129	]	,
["Stapor",	241.67792	]	,
["Reglinski",	241.68455	]	,
["Pinda",	241.69118	]	,
["Najdowski",	241.69781	]	,
["Muzyk",	241.70444	]	,
["Maliglówka",	241.71107	]	,
["Lukasiuk",	241.7177	]	,
["Kuczmarski",	241.72433	]	,
["Kinal",	241.73096	]	,
["Dobosiewicz",	241.73759	]	,
["Bilecki",	241.74422	]	,
["Waraksa",	241.75084	]	,
["Szywala",	241.75746	]	,
["Nastaly",	241.76408	]	,
["Mordak",	241.7707	]	,
["Mechlinski",	241.77732	]	,
["Ligenza",	241.78394	]	,
["Leszczak",	241.79056	]	,
["Krauz",	241.79718	]	,
["Kopala",	241.8038	]	,
["Byzdra",	241.81042	]	,
["Bartman",	241.81704	]	,
["Znamirowski",	241.82365	]	,
["Zelewski",	241.83026	]	,
["Wojtach",	241.83687	]	,
["Walaszek",	241.84348	]	,
["Szara",	241.85009	]	,
["Pstragowski",	241.8567	]	,
["Polonski",	241.86331	]	,
["Nieznanski",	241.86992	]	,
["Kielczykowski",	241.87653	]	,
["Jakacki",	241.88314	]	,
["Hapka",	241.88975	]	,
["Wielgat",	241.89635	]	,
["Wegier",	241.90295	]	,
["Pokusa",	241.90955	]	,
["Malz",	241.91615	]	,
["Kononowicz",	241.92275	]	,
["Hawrylak",	241.92935	]	,
["Grund",	241.93595	]	,
["Grabkowski",	241.94255	]	,
["Druszcz",	241.94915	]	,
["Dacko",	241.95575	]	,
["Toczydlowski",	241.96234	]	,
["Swiderski",	241.96893	]	,
["Sprycha",	241.97552	]	,
["Pryszcz",	241.98211	]	,
["Lachut",	241.9887	]	,
["Dobrosz",	241.99529	]	,
["Brygola",	242.00188	]	,
["Zatonski",	242.00846	]	,
["Smogorzewski",	242.01504	]	,
["Rygula",	242.02162	]	,
["Posluszna",	242.0282	]	,
["Mydlak",	242.03478	]	,
["Iglewski",	242.04136	]	,
["Borski",	242.04794	]	,
["Bernard",	242.05452	]	,
["BendingerBiesiadecki",	242.0611	]	,
["Woroch",	242.06767	]	,
["Watkowski",	242.07424	]	,
["Uliczka",	242.08081	]	,
["Tomaszuk",	242.08738	]	,
["Pastula",	242.09395	]	,
["Pachnik",	242.10052	]	,
["Opalski",	242.10709	]	,
["Kudra",	242.11366	]	,
["Kretek",	242.12023	]	,
["Kotlicki",	242.1268	]	,
["Keler",	242.13337	]	,
["Karlowski",	242.13994	]	,
["Heczko",	242.14651	]	,
["Godziszewski",	242.15308	]	,
["Gesikowski",	242.15965	]	,
["Beck",	242.16622	]	,
["Wojkowski",	242.17278	]	,
["Warecki",	242.17934	]	,
["Tekiela",	242.1859	]	,
["Plizga",	242.19246	]	,
["Piekacz",	242.19902	]	,
["Ochab",	242.20558	]	,
["Maziarczyk",	242.21214	]	,
["Krzosek",	242.2187	]	,
["Glazowski",	242.22526	]	,
["Gabryelczyk",	242.23182	]	,
["Falecki",	242.23838	]	,
["Stepka",	242.24493	]	,
["Rajch",	242.25148	]	,
["Pasieczny",	242.25803	]	,
["Owsiany",	242.26458	]	,
["Ochojski",	242.27113	]	,
["Kossak",	242.27768	]	,
["Kocaj",	242.28423	]	,
["Gryglewski",	242.29078	]	,
["Gierach",	242.29733	]	,
["Ebertowski",	242.30388	]	,
["Dolacinski",	242.31043	]	,
["Buza",	242.31698	]	,
["Berendt",	242.32353	]	,
["Tabak",	242.33007	]	,
["Skurzynski",	242.33661	]	,
["Siarkowski",	242.34315	]	,
["Przewloka",	242.34969	]	,
["Nytko",	242.35623	]	,
["Kuban",	242.36277	]	,
["Kilarski",	242.36931	]	,
["Gebauer",	242.37585	]	,
["Gajcy",	242.38239	]	,
["Franaszek",	242.38893	]	,
["Chwedczuk",	242.39547	]	,
["Bochnak",	242.40201	]	,
["Szymichowski",	242.40854	]	,
["Stachewicz",	242.41507	]	,
["Sosnówka",	242.4216	]	,
["Slowiak",	242.42813	]	,
["Siwecki",	242.43466	]	,
["Racinowski",	242.44119	]	,
["Prominski",	242.44772	]	,
["Pilawski",	242.45425	]	,
["Madro",	242.46078	]	,
["Malcharek",	242.46731	]	,
["Lukasz",	242.47384	]	,
["Kornek",	242.48037	]	,
["Hanusiak",	242.4869	]	,
["Furmankiewicz",	242.49343	]	,
["Dzikiewicz",	242.49996	]	,
["Duzy",	242.50649	]	,
["Delikat",	242.51302	]	,
["Chojak",	242.51955	]	,
["Zyga",	242.52607	]	,
["Pyrz",	242.53259	]	,
["Pietrusiewicz",	242.53911	]	,
["Olszyna",	242.54563	]	,
["Olszowa",	242.55215	]	,
["Ograbek",	242.55867	]	,
["Molga",	242.56519	]	,
["Maron",	242.57171	]	,
["Jendrzejewski",	242.57823	]	,
["Jasica",	242.58475	]	,
["Frymus",	242.59127	]	,
["Ciborski",	242.59779	]	,
["Buszta",	242.60431	]	,
["Woszczak",	242.61082	]	,
["Woronko",	242.61733	]	,
["Trawka",	242.62384	]	,
["Rychcik",	242.63035	]	,
["Redlicki",	242.63686	]	,
["Przystupa",	242.64337	]	,
["Porczynski",	242.64988	]	,
["Oczko",	242.65639	]	,
["Ochenkowski",	242.6629	]	,
["Migda",	242.66941	]	,
["Laszczewski",	242.67592	]	,
["Klebba",	242.68243	]	,
["Jaje",	242.68894	]	,
["Grabas",	242.69545	]	,
["Bugno",	242.70196	]	,
["Bortkiewicz",	242.70847	]	,
["Wesola",	242.71497	]	,
["Sudak",	242.72147	]	,
["Puc",	242.72797	]	,
["Przeklasa",	242.73447	]	,
["Oraczewski",	242.74097	]	,
["Lutomski",	242.74747	]	,
["Lorbiecki",	242.75397	]	,
["Kocol",	242.76047	]	,
["Knapinski",	242.76697	]	,
["Goik",	242.77347	]	,
["Blazejewicz",	242.77997	]	,
["Wargacki",	242.78646	]	,
["Tuzimek",	242.79295	]	,
["Siniarski",	242.79944	]	,
["Radowski",	242.80593	]	,
["Pierzynski",	242.81242	]	,
["Petrus",	242.81891	]	,
["Pawlaczek",	242.8254	]	,
["Pacholczak",	242.83189	]	,
["Oltarzewski",	242.83838	]	,
["Maciejewicz",	242.84487	]	,
["Jakóbik",	242.85136	]	,
["Glodkowski",	242.85785	]	,
["Frania",	242.86434	]	,
["Duszczak",	242.87083	]	,
["Domurad",	242.87732	]	,
["Bednarowicz",	242.88381	]	,
["Thomas",	242.89029	]	,
["Sloniewski",	242.89677	]	,
["Rakus",	242.90325	]	,
["Przybys",	242.90973	]	,
["Pasiut",	242.91621	]	,
["Malyszka",	242.92269	]	,
["Lutostanski",	242.92917	]	,
["Kurz",	242.93565	]	,
["Kuczaj",	242.94213	]	,
["Doktor",	242.94861	]	,
["Tadla",	242.95508	]	,
["Praczyk",	242.96155	]	,
["Osicki",	242.96802	]	,
["Milka",	242.97449	]	,
["Leszcz",	242.98096	]	,
["Kryza",	242.98743	]	,
["Kryszczuk",	242.9939	]	,
["Juraszczyk",	243.00037	]	,
["Durczok",	243.00684	]	,
["Boduch",	243.01331	]	,
["Ujazdowski",	243.01977	]	,
["Szeja",	243.02623	]	,
["Pryk",	243.03269	]	,
["Pitala",	243.03915	]	,
["Piórecki",	243.04561	]	,
["Palusinski",	243.05207	]	,
["Molek",	243.05853	]	,
["Gogulski",	243.06499	]	,
["Dziekanowski",	243.07145	]	,
["Duchnik",	243.07791	]	,
["Brachaczek",	243.08437	]	,
["Wieja",	243.09082	]	,
["Waloszek",	243.09727	]	,
["Szmytkowski",	243.10372	]	,
["Rytwinski",	243.11017	]	,
["Nawrotek",	243.11662	]	,
["Nawój",	243.12307	]	,
["Mironiuk",	243.12952	]	,
["Matyjasek",	243.13597	]	,
["Lachacz",	243.14242	]	,
["Kwaczynski",	243.14887	]	,
["Kubów",	243.15532	]	,
["Kosobudzki",	243.16177	]	,
["Kidawa",	243.16822	]	,
["Jaremek",	243.17467	]	,
["Horowski",	243.18112	]	,
["Hasiak",	243.18757	]	,
["Gierat",	243.19402	]	,
["Gawlowicz",	243.20047	]	,
["Wiczkowski",	243.20691	]	,
["Wichary",	243.21335	]	,
["Sornat",	243.21979	]	,
["Solich",	243.22623	]	,
["Padewski",	243.23267	]	,
["Kurczab",	243.23911	]	,
["Krawczykowski",	243.24555	]	,
["Jasnoch",	243.25199	]	,
["Folwarski",	243.25843	]	,
["Famula",	243.26487	]	,
["Budrewicz",	243.27131	]	,
["Arczynski",	243.27775	]	,
["Zmuda-Trzebiatowski",	243.28418	]	,
["Sladowski",	243.29061	]	,
["Pawliszyn",	243.29704	]	,
["Kulach",	243.30347	]	,
["Kuffel",	243.3099	]	,
["Konieczek",	243.31633	]	,
["Kocwin",	243.32276	]	,
["Kiernicki",	243.32919	]	,
["Imiolczyk",	243.33562	]	,
["Dyda",	243.34205	]	,
["Bosiacki",	243.34848	]	,
["Biniecki",	243.35491	]	,
["Zander",	243.36133	]	,
["Stochel",	243.36775	]	,
["Podniesinski",	243.37417	]	,
["Osojca",	243.38059	]	,
["Mysior",	243.38701	]	,
["Mrowicki",	243.39343	]	,
["Kuciak",	243.39985	]	,
["Klósek",	243.40627	]	,
["Cwikowski",	243.41269	]	,
["Buchholz",	243.41911	]	,
["Zegadlo",	243.42552	]	,
["Wiewiórka",	243.43193	]	,
["Slaski",	243.43834	]	,
["Stochaj",	243.44475	]	,
["Smolka",	243.45116	]	,
["Piotrak",	243.45757	]	,
["Ostolski",	243.46398	]	,
["Misior",	243.47039	]	,
["Milinski",	243.4768	]	,
["Leoniak",	243.48321	]	,
["Karwala",	243.48962	]	,
["Jasina",	243.49603	]	,
["Grzymski",	243.50244	]	,
["Cieciwa",	243.50885	]	,
["Ciastek",	243.51526	]	,
["Chadaj",	243.52167	]	,
["Bialach",	243.52808	]	,
["Apolinarski",	243.53449	]	,
["Zasadzki",	243.54089	]	,
["Warsinski",	243.54729	]	,
["Tarczewski",	243.55369	]	,
["Talkowski",	243.56009	]	,
["Tabisz",	243.56649	]	,
["Such",	243.57289	]	,
["Sromek",	243.57929	]	,
["Rysz",	243.58569	]	,
["Puch",	243.59209	]	,
["Plak",	243.59849	]	,
["Piestrzynski",	243.60489	]	,
["Palej",	243.61129	]	,
["Och",	243.61769	]	,
["Niedbal",	243.62409	]	,
["Mytnik",	243.63049	]	,
["Morgala",	243.63689	]	,
["Lukas",	243.64329	]	,
["Lison",	243.64969	]	,
["Królikiewicz",	243.65609	]	,
["Kamieniak",	243.66249	]	,
["Jachimczyk",	243.66889	]	,
["Grzywnowicz",	243.67529	]	,
["Frukacz",	243.68169	]	,
["Feliniak",	243.68809	]	,
["Dzienisz",	243.69449	]	,
["Drazyk",	243.70089	]	,
["Zelasko",	243.70728	]	,
["Waloszczyk",	243.71367	]	,
["Traczynski",	243.72006	]	,
["Szymski",	243.72645	]	,
["Szlagowski",	243.73284	]	,
["Strójwas",	243.73923	]	,
["Smoczyk",	243.74562	]	,
["Klorek",	243.75201	]	,
["Kajdan",	243.7584	]	,
["Kajak",	243.76479	]	,
["Gral",	243.77118	]	,
["Banachowski",	243.77757	]	,
["Zawodnik",	243.78395	]	,
["Ulfik",	243.79033	]	,
["Torzewski",	243.79671	]	,
["Sobieszczyk",	243.80309	]	,
["Skrobot",	243.80947	]	,
["Sinicki",	243.81585	]	,
["Ochal",	243.82223	]	,
["Lezon",	243.82861	]	,
["Krywult",	243.83499	]	,
["Iciek",	243.84137	]	,
["Gasek",	243.84775	]	,
["Czenczek",	243.85413	]	,
["Czapczynski",	243.86051	]	,
["Budzen",	243.86689	]	,
["Brozynski",	243.87327	]	,
["Botor",	243.87965	]	,
["Wiklo",	243.88602	]	,
["Weglarski",	243.89239	]	,
["Tymczyszyn",	243.89876	]	,
["Szpyra",	243.90513	]	,
["Slonka",	243.9115	]	,
["Prasek",	243.91787	]	,
["Majczyna",	243.92424	]	,
["Lula",	243.93061	]	,
["Liberadzki",	243.93698	]	,
["Jakubiuk",	243.94335	]	,
["Hanzel",	243.94972	]	,
["Gruzewski",	243.95609	]	,
["Glowiak",	243.96246	]	,
["Chudecki",	243.96883	]	,
["Calik",	243.9752	]	,
["Zakrocki",	243.98156	]	,
["Zagrajek",	243.98792	]	,
["Stefankiewicz",	243.99428	]	,
["Stawarski",	244.00064	]	,
["Serzysko",	244.007	]	,
["Piechna",	244.01336	]	,
["Ojrzynski",	244.01972	]	,
["Myga",	244.02608	]	,
["Maslankiewicz",	244.03244	]	,
["Marszewski",	244.0388	]	,
["Lejkowski",	244.04516	]	,
["Kuziora",	244.05152	]	,
["Korniak",	244.05788	]	,
["Klimczewski",	244.06424	]	,
["Indyka",	244.0706	]	,
["Garwolinski",	244.07696	]	,
["Galach",	244.08332	]	,
["Gadzina",	244.08968	]	,
["Darnowski",	244.09604	]	,
["Cyba",	244.1024	]	,
["Bystrek",	244.10876	]	,
["Bereznicki",	244.11512	]	,
["Bazela",	244.12148	]	,
["Weclewski",	244.12783	]	,
["Wabik",	244.13418	]	,
["Ragus",	244.14053	]	,
["Pitek",	244.14688	]	,
["Moranski",	244.15323	]	,
["Mizia",	244.15958	]	,
["Laskawiec",	244.16593	]	,
["Holeksa",	244.17228	]	,
["Hajdasz",	244.17863	]	,
["Fugiel",	244.18498	]	,
["Bialasik",	244.19133	]	,
["Wozniczko",	244.19767	]	,
["Wilma",	244.20401	]	,
["Rode",	244.21035	]	,
["Pres",	244.21669	]	,
["Komander",	244.22303	]	,
["Klus",	244.22937	]	,
["Sarosiek",	244.2357	]	,
["Sadoch",	244.24203	]	,
["Osipowicz",	244.24836	]	,
["Leski",	244.25469	]	,
["Lelonek",	244.26102	]	,
["Korbut",	244.26735	]	,
["Jarmuzek",	244.27368	]	,
["Dunikowski",	244.28001	]	,
["Bugajewski",	244.28634	]	,
["Zabski",	244.29266	]	,
["Wlodyka",	244.29898	]	,
["Wardzynski",	244.3053	]	,
["Macierzynski",	244.31162	]	,
["Józefczak",	244.31794	]	,
["Jedra",	244.32426	]	,
["Hamerla",	244.33058	]	,
["Grecki",	244.3369	]	,
["Gegotek",	244.34322	]	,
["Dominczak",	244.34954	]	,
["Daczkowski",	244.35586	]	,
["Charzewski",	244.36218	]	,
["Wypiór",	244.36849	]	,
["Sudnik",	244.3748	]	,
["Sloboda",	244.38111	]	,
["Pela",	244.38742	]	,
["Papierowski",	244.39373	]	,
["Macherzynski",	244.40004	]	,
["Kups",	244.40635	]	,
["Kostorz",	244.41266	]	,
["Kosak",	244.41897	]	,
["Kopysc",	244.42528	]	,
["Klejnowski",	244.43159	]	,
["Jarmula",	244.4379	]	,
["Gronczewski",	244.44421	]	,
["Dziedzicki",	244.45052	]	,
["Daniec",	244.45683	]	,
["Blank",	244.46314	]	,
["Balcewicz",	244.46945	]	,
["Wiecki",	244.47575	]	,
["Starostka",	244.48205	]	,
["Sobiczewski",	244.48835	]	,
["Siemienczuk",	244.49465	]	,
["Rymaszewski",	244.50095	]	,
["Reiter",	244.50725	]	,
["Radzicki",	244.51355	]	,
["Nuckowski",	244.51985	]	,
["Mycek",	244.52615	]	,
["Mietka",	244.53245	]	,
["Lupina",	244.53875	]	,
["Lipok",	244.54505	]	,
["Krycki",	244.55135	]	,
["Koski",	244.55765	]	,
["Knych",	244.56395	]	,
["Drobisz",	244.57025	]	,
["Cuch",	244.57655	]	,
["Wojtarowicz",	244.58284	]	,
["Wojniak",	244.58913	]	,
["Snochowski",	244.59542	]	,
["Polawski",	244.60171	]	,
["Piechura",	244.608	]	,
["Meissner",	244.61429	]	,
["Lemiesz",	244.62058	]	,
["Kropiwnicki",	244.62687	]	,
["Klek",	244.63316	]	,
["Jargielo",	244.63945	]	,
["Jamroz",	244.64574	]	,
["Huczko",	244.65203	]	,
["Ceynowa",	244.65832	]	,
["Trochim",	244.6646	]	,
["Kurasinski",	244.67088	]	,
["Kremer",	244.67716	]	,
["Janic",	244.68344	]	,
["Gal",	244.68972	]	,
["Dzialowski",	244.696	]	,
["Dworzanski",	244.70228	]	,
["Cyrulik",	244.70856	]	,
["Bejger",	244.71484	]	,
["Bawol",	244.72112	]	,
["Wargocki",	244.72739	]	,
["Szczepan",	244.73366	]	,
["Suchcicki",	244.73993	]	,
["Plewnia",	244.7462	]	,
["Pedrak",	244.75247	]	,
["Niedospial",	244.75874	]	,
["Maras",	244.76501	]	,
["Liberacki",	244.77128	]	,
["Klepka",	244.77755	]	,
["Kawulok",	244.78382	]	,
["Katana",	244.79009	]	,
["Cichanski",	244.79636	]	,
["Bronka",	244.80263	]	,
["Beczkowski",	244.8089	]	,
["Bender",	244.81517	]	,
["Baldys",	244.82144	]	,
["Wawrzonek",	244.8277	]	,
["Taranek",	244.83396	]	,
["Tadych",	244.84022	]	,
["Szymala",	244.84648	]	,
["Stebel",	244.85274	]	,
["Stawikowski",	244.859	]	,
["Skup",	244.86526	]	,
["Skubala",	244.87152	]	,
["Placzkowski",	244.87778	]	,
["Pasieczna",	244.88404	]	,
["Papierski",	244.8903	]	,
["Karkocha",	244.89656	]	,
["Hak",	244.90282	]	,
["Gaszczak",	244.90908	]	,
["Brucki",	244.91534	]	,
["Woldanski",	244.92159	]	,
["Sendecki",	244.92784	]	,
["Pys",	244.93409	]	,
["Prazuch",	244.94034	]	,
["Politowicz",	244.94659	]	,
["Piestrzeniewicz",	244.95284	]	,
["Pajek",	244.95909	]	,
["Okupski",	244.96534	]	,
["Nitek",	244.97159	]	,
["Motylski",	244.97784	]	,
["Krzeczkowski",	244.98409	]	,
["Kozok",	244.99034	]	,
["Kowala",	244.99659	]	,
["Karnicki",	245.00284	]	,
["Kalinka",	245.00909	]	,
["Grzegorowski",	245.01534	]	,
["Galuba",	245.02159	]	,
["Dziecielski",	245.02784	]	,
["Buk",	245.03409	]	,
["Bres",	245.04034	]	,
["Bodych",	245.04659	]	,
["Bittner",	245.05284	]	,
["Bakiera",	245.05909	]	,
["Rembacz",	245.06533	]	,
["Podgórna",	245.07157	]	,
["Nienaltowski",	245.07781	]	,
["Myrcik",	245.08405	]	,
["Mojsa",	245.09029	]	,
["Koniarski",	245.09653	]	,
["Karpiak",	245.10277	]	,
["Kajdas",	245.10901	]	,
["Gregorczuk",	245.11525	]	,
["Dziurla",	245.12149	]	,
["Dzienniak",	245.12773	]	,
["Dyrek",	245.13397	]	,
["Zoladkiewicz",	245.1402	]	,
["Wiewiórski",	245.14643	]	,
["Szumacher",	245.15266	]	,
["Sado",	245.15889	]	,
["Pyszny",	245.16512	]	,
["Piotrkowski",	245.17135	]	,
["Narozny",	245.17758	]	,
["Mizgalski",	245.18381	]	,
["Magdzinski",	245.19004	]	,
["Kuszyk",	245.19627	]	,
["Jakimiak",	245.2025	]	,
["Garwacki",	245.20873	]	,
["Dynak",	245.21496	]	,
["Dejneka",	245.22119	]	,
["Adaszewski",	245.22742	]	,
["Zycki",	245.23364	]	,
["Zasowski",	245.23986	]	,
["Wszeborowski",	245.24608	]	,
["Wiekiera",	245.2523	]	,
["Tatarczuk",	245.25852	]	,
["Rudyk",	245.26474	]	,
["Niescioruk",	245.27096	]	,
["Laszkiewicz",	245.27718	]	,
["Golota",	245.2834	]	,
["Golisz",	245.28962	]	,
["Babel",	245.29584	]	,
["Batkowski",	245.30206	]	,
["Wierciszewski",	245.30827	]	,
["Taczala",	245.31448	]	,
["Swic",	245.32069	]	,
["Slesicki",	245.3269	]	,
["Siciarz",	245.33311	]	,
["Ropiak",	245.33932	]	,
["Pacura",	245.34553	]	,
["Makulec",	245.35174	]	,
["Laczny",	245.35795	]	,
["Lacinski",	245.36416	]	,
["Krauza",	245.37037	]	,
["Grzesiek",	245.37658	]	,
["Gemza",	245.38279	]	,
["Dering",	245.389	]	,
["Banek",	245.39521	]	,
["Andziak",	245.40142	]	,
["Wiza",	245.40762	]	,
["Trojanowicz",	245.41382	]	,
["Parkitna",	245.42002	]	,
["Pacholik",	245.42622	]	,
["Majtczak",	245.43242	]	,
["Krenc",	245.43862	]	,
["Koniec",	245.44482	]	,
["Wozinski",	245.45101	]	,
["Wawrzenczyk",	245.4572	]	,
["Szybinski",	245.46339	]	,
["Szawlowski",	245.46958	]	,
["Stupak",	245.47577	]	,
["Strózewski",	245.48196	]	,
["Roda",	245.48815	]	,
["Niemirski",	245.49434	]	,
["Michonski",	245.50053	]	,
["Maciejczuk",	245.50672	]	,
["Kosmicki",	245.51291	]	,
["Irla",	245.5191	]	,
["Husak",	245.52529	]	,
["Fulawka",	245.53148	]	,
["Fabianczyk",	245.53767	]	,
["Bryda",	245.54386	]	,
["Zackiewicz",	245.55004	]	,
["Walski",	245.55622	]	,
["Trawicki",	245.5624	]	,
["Szoka",	245.56858	]	,
["Sandecki",	245.57476	]	,
["Pradzinski",	245.58094	]	,
["Pilewski",	245.58712	]	,
["Pietowski",	245.5933	]	,
["Olszanowski",	245.59948	]	,
["Mozdzynski",	245.60566	]	,
["Melcer",	245.61184	]	,
["Kempny",	245.61802	]	,
["Dulemba",	245.6242	]	,
["Duc",	245.63038	]	,
["Cichomski",	245.63656	]	,
["Zóltanski",	245.64273	]	,
["Ziniewicz",	245.6489	]	,
["Truchel",	245.65507	]	,
["Szajner",	245.66124	]	,
["Razniewski",	245.66741	]	,
["Petryk",	245.67358	]	,
["Peda",	245.67975	]	,
["Patelski",	245.68592	]	,
["Paszynski",	245.69209	]	,
["Obarzanek",	245.69826	]	,
["Maszkiewicz",	245.70443	]	,
["Lodzinski",	245.7106	]	,
["Labaj",	245.71677	]	,
["Latanski",	245.72294	]	,
["Gieraltowski",	245.72911	]	,
["Gerwatowski",	245.73528	]	,
["Garlinski",	245.74145	]	,
["Damski",	245.74762	]	,
["Cymbala",	245.75379	]	,
["Biesaga",	245.75996	]	,
["Zdobylak",	245.76612	]	,
["Wojtiuk",	245.77228	]	,
["Ulrych",	245.77844	]	,
["Szymków",	245.7846	]	,
["Sporysz",	245.79076	]	,
["Snopkowski",	245.79692	]	,
["Smardz",	245.80308	]	,
["Rosowski",	245.80924	]	,
["Piotrzkowski",	245.8154	]	,
["Mandrysz",	245.82156	]	,
["Kulus",	245.82772	]	,
["Duras",	245.83388	]	,
["Dumin",	245.84004	]	,
["Borejko",	245.8462	]	,
["Wylupek",	245.85235	]	,
["Ufniarz",	245.8585	]	,
["Stypka",	245.86465	]	,
["Stobinski",	245.8708	]	,
["Slupinski",	245.87695	]	,
["Mlynczyk",	245.8831	]	,
["Miros",	245.88925	]	,
["Maciuk",	245.8954	]	,
["Hrabia",	245.90155	]	,
["Groszkowski",	245.9077	]	,
["Burzec",	245.91385	]	,
["Buksa",	245.92	]	,
["Wygoda",	245.92614	]	,
["Widulinski",	245.93228	]	,
["Tomzik",	245.93842	]	,
["Rajczakowski",	245.94456	]	,
["Pindral",	245.9507	]	,
["Nijak",	245.95684	]	,
["Mszyca",	245.96298	]	,
["Maciejuk",	245.96912	]	,
["Listowski",	245.97526	]	,
["Kudlacz",	245.9814	]	,
["Krygowski",	245.98754	]	,
["Dziwak",	245.99368	]	,
["Dobiecki",	245.99982	]	,
["Chaba",	246.00596	]	,
["Borkowicz",	246.0121	]	,
["Berek",	246.01824	]	,
["Zuralski",	246.02437	]	,
["Zakiewicz",	246.0305	]	,
["Wykret",	246.03663	]	,
["Sztuba",	246.04276	]	,
["Smykala",	246.04889	]	,
["Pyc",	246.05502	]	,
["Peciak",	246.06115	]	,
["Parzonka",	246.06728	]	,
["Kyc",	246.07341	]	,
["Klemczak",	246.07954	]	,
["Glowski",	246.08567	]	,
["Gasienica",	246.0918	]	,
["Gabryszak",	246.09793	]	,
["Degórski",	246.10406	]	,
["Czescik",	246.11019	]	,
["Cison",	246.11632	]	,
["Zmyslony",	246.12244	]	,
["Swierzewski",	246.12856	]	,
["Sieprawski",	246.13468	]	,
["Majecki",	246.1408	]	,
["Komisarek",	246.14692	]	,
["Ficon",	246.15304	]	,
["Citko",	246.15916	]	,
["Bidas",	246.16528	]	,
["Bas",	246.1714	]	,
["Zabierek",	246.17751	]	,
["Wyciszkiewicz",	246.18362	]	,
["Tarach",	246.18973	]	,
["Staniewicz",	246.19584	]	,
["Skrzeszewski",	246.20195	]	,
["Reichel",	246.20806	]	,
["Pazderski",	246.21417	]	,
["Panasewicz",	246.22028	]	,
["Kucewicz",	246.22639	]	,
["Kosacki",	246.2325	]	,
["Kmiecinski",	246.23861	]	,
["Kilar",	246.24472	]	,
["Kalwasinski",	246.25083	]	,
["Hein",	246.25694	]	,
["Fronia",	246.26305	]	,
["Derek",	246.26916	]	,
["Brus",	246.27527	]	,
["Bojakowski",	246.28138	]	,
["Anton",	246.28749	]	,
["Wodynski",	246.29359	]	,
["Rzymowski",	246.29969	]	,
["Pawlos",	246.30579	]	,
["Ochwat",	246.31189	]	,
["Kurbiel",	246.31799	]	,
["Gosik",	246.32409	]	,
["Gierasimiuk",	246.33019	]	,
["Duczynski",	246.33629	]	,
["Doroba",	246.34239	]	,
["Cwalinski",	246.34849	]	,
["Chlad",	246.35459	]	,
["Bieronski",	246.36069	]	,
["Zgorzelski",	246.36678	]	,
["Wrochna",	246.37287	]	,
["Wieczkowski",	246.37896	]	,
["Szutkowski",	246.38505	]	,
["Szulczynski",	246.39114	]	,
["Sewerynski",	246.39723	]	,
["Protasiuk",	246.40332	]	,
["Prochowski",	246.40941	]	,
["Powichrowski",	246.4155	]	,
["Opalach",	246.42159	]	,
["Mucko",	246.42768	]	,
["Martyn",	246.43377	]	,
["Majchrzycki",	246.43986	]	,
["Drgas",	246.44595	]	,
["Ceran",	246.45204	]	,
["Bryczek",	246.45813	]	,
["Borzymowski",	246.46422	]	,
["Ziarno",	246.4703	]	,
["Wolodzko",	246.47638	]	,
["Wac",	246.48246	]	,
["Szpala",	246.48854	]	,
["Szlachcic",	246.49462	]	,
["Rurka",	246.5007	]	,
["Pietrzynski",	246.50678	]	,
["Oczkowicz",	246.51286	]	,
["Mik",	246.51894	]	,
["Malysiak",	246.52502	]	,
["Kubek",	246.5311	]	,
["Imiela",	246.53718	]	,
["Grabon",	246.54326	]	,
["Garbacik",	246.54934	]	,
["Dolega",	246.55542	]	,
["Broncel",	246.5615	]	,
["Baum",	246.56758	]	,
["Bancerz",	246.57366	]	,
["Sieranski",	246.57973	]	,
["Siedlik",	246.5858	]	,
["Nawojski",	246.59187	]	,
["Miasko",	246.59794	]	,
["Lomnicki",	246.60401	]	,
["Lenc",	246.61008	]	,
["Konat",	246.61615	]	,
["Klawinski",	246.62222	]	,
["Kaletka",	246.62829	]	,
["Jenek",	246.63436	]	,
["Honkisz",	246.64043	]	,
["Dros",	246.6465	]	,
["Suchojad",	246.65256	]	,
["Ratka",	246.65862	]	,
["Raba",	246.66468	]	,
["Putkowski",	246.67074	]	,
["Lulek",	246.6768	]	,
["Komperda",	246.68286	]	,
["Kolodziejak",	246.68892	]	,
["Koloch",	246.69498	]	,
["Kolka",	246.70104	]	,
["Kisielinski",	246.7071	]	,
["Joniak",	246.71316	]	,
["Jezior",	246.71922	]	,
["Faltyn",	246.72528	]	,
["Dyjach",	246.73134	]	,
["Czulak",	246.7374	]	,
["Cop",	246.74346	]	,
["Wyroslak",	246.74951	]	,
["Woda",	246.75556	]	,
["Stranc",	246.76161	]	,
["Solis",	246.76766	]	,
["Skomra",	246.77371	]	,
["Sierpien",	246.77976	]	,
["Rzezniczek",	246.78581	]	,
["Rosochacki",	246.79186	]	,
["Pajdak",	246.79791	]	,
["Mostek",	246.80396	]	,
["Machowiak",	246.81001	]	,
["Lopacki",	246.81606	]	,
["Kwarcinski",	246.82211	]	,
["Kistowski",	246.82816	]	,
["Jandula",	246.83421	]	,
["Fitrzyk",	246.84026	]	,
["Domanowski",	246.84631	]	,
["Welenc",	246.85235	]	,
["Tyczka",	246.85839	]	,
["Skiepko",	246.86443	]	,
["Sienski",	246.87047	]	,
["Potok",	246.87651	]	,
["Olewniczak",	246.88255	]	,
["Nitkiewicz",	246.88859	]	,
["Myrcha",	246.89463	]	,
["Krata",	246.90067	]	,
["Klepczynski",	246.90671	]	,
["Kara",	246.91275	]	,
["Holysz",	246.91879	]	,
["Halka",	246.92483	]	,
["Florian",	246.93087	]	,
["Dziurdzia",	246.93691	]	,
["Dryka",	246.94295	]	,
["Brewinski",	246.94899	]	,
["Zurakowski",	246.95502	]	,
["Syslo",	246.96105	]	,
["Rolek",	246.96708	]	,
["Podkanski",	246.97311	]	,
["Mlocek",	246.97914	]	,
["Idzi",	246.98517	]	,
["Haponiuk",	246.9912	]	,
["Grebowiec",	246.99723	]	,
["Geca",	247.00326	]	,
["Domarecki",	247.00929	]	,
["Bochnia",	247.01532	]	,
["Barczykowski",	247.02135	]	,
["Slipek",	247.02737	]	,
["Sieczko",	247.03339	]	,
["Pierz",	247.03941	]	,
["Nyc",	247.04543	]	,
["Lacina",	247.05145	]	,
["Ludwisiak",	247.05747	]	,
["Kujda",	247.06349	]	,
["Kowieski",	247.06951	]	,
["Hutyra",	247.07553	]	,
["Grodzinski",	247.08155	]	,
["Dziugiel",	247.08757	]	,
["Dobaczewski",	247.09359	]	,
["Bockowski",	247.09961	]	,
["Bialka",	247.10563	]	,
["Zemanek",	247.11164	]	,
["Zawartka",	247.11765	]	,
["Wegrowski",	247.12366	]	,
["Szybalski",	247.12967	]	,
["Smyl",	247.13568	]	,
["Smolec",	247.14169	]	,
["Sloka",	247.1477	]	,
["Putek",	247.15371	]	,
["Pietrewicz",	247.15972	]	,
["Lepka",	247.16573	]	,
["Krzeszowiec",	247.17174	]	,
["Kowalówka",	247.17775	]	,
["Josko",	247.18376	]	,
["Hamrol",	247.18977	]	,
["Gapys",	247.19578	]	,
["Antoszczyk",	247.20179	]	,
["Turon",	247.20779	]	,
["Teter",	247.21379	]	,
["Surdel",	247.21979	]	,
["Pieczyrak",	247.22579	]	,
["Mudlaff",	247.23179	]	,
["Manista",	247.23779	]	,
["Kuzniarski",	247.24379	]	,
["Kolek",	247.24979	]	,
["Kadela",	247.25579	]	,
["Jeka",	247.26179	]	,
["Jamrozek",	247.26779	]	,
["Grzemski",	247.27379	]	,
["Goliasz",	247.27979	]	,
["Garstecki",	247.28579	]	,
["Falkowski",	247.29179	]	,
["Dywan",	247.29779	]	,
["Drewnik",	247.30379	]	,
["Dabros",	247.30979	]	,
["Cias",	247.31579	]	,
["Obiala",	247.32178	]	,
["Nocek",	247.32777	]	,
["Marko",	247.33376	]	,
["Ladziak",	247.33975	]	,
["Hadas",	247.34574	]	,
["Gozdzikowski",	247.35173	]	,
["Golanowski",	247.35772	]	,
["Dulik",	247.36371	]	,
["Dorynek",	247.3697	]	,
["Donarski",	247.37569	]	,
["Wolanczyk",	247.38167	]	,
["Szczechowski",	247.38765	]	,
["Stoltmann",	247.39363	]	,
["Rozumek",	247.39961	]	,
["Ludzik",	247.40559	]	,
["Las",	247.41157	]	,
["Leoniuk",	247.41755	]	,
["Krzyk",	247.42353	]	,
["Karol",	247.42951	]	,
["Kamyszek",	247.43549	]	,
["Haniszewski",	247.44147	]	,
["Filusz",	247.44745	]	,
["Czermak",	247.45343	]	,
["Chrzastowski",	247.45941	]	,
["Budych",	247.46539	]	,
["Zólkiewicz",	247.47136	]	,
["Tatarczyk",	247.47733	]	,
["Pietrus",	247.4833	]	,
["Pachowicz",	247.48927	]	,
["Niesporek",	247.49524	]	,
["Marjanski",	247.50121	]	,
["Kultys",	247.50718	]	,
["Kornet",	247.51315	]	,
["Kajstura",	247.51912	]	,
["Grzesków",	247.52509	]	,
["Gozdowski",	247.53106	]	,
["Dub",	247.53703	]	,
["Drobot",	247.543	]	,
["Zjawinski",	247.54896	]	,
["Urynowicz",	247.55492	]	,
["Swacha",	247.56088	]	,
["Prokopczuk",	247.56684	]	,
["Mynarski",	247.5728	]	,
["Michnowicz",	247.57876	]	,
["Malka",	247.58472	]	,
["Lawski",	247.59068	]	,
["Labocha",	247.59664	]	,
["Capiga",	247.6026	]	,
["Boronski",	247.60856	]	,
["Zawalich",	247.61451	]	,
["Wizner",	247.62046	]	,
["Stawiszynski",	247.62641	]	,
["Startek",	247.63236	]	,
["Smolorz",	247.63831	]	,
["Rozynek",	247.64426	]	,
["Pal",	247.65021	]	,
["Oporski",	247.65616	]	,
["Madajczyk",	247.66211	]	,
["Lawniczek",	247.66806	]	,
["Lesnicki",	247.67401	]	,
["Jasicki",	247.67996	]	,
["Haremza",	247.68591	]	,
["Gruchalski",	247.69186	]	,
["Bejnarowicz",	247.69781	]	,
["Bagniewski",	247.70376	]	,
["Zuberek",	247.7097	]	,
["Windak",	247.71564	]	,
["Sobolak",	247.72158	]	,
["Sibiga",	247.72752	]	,
["Rajczak",	247.73346	]	,
["Pudelek",	247.7394	]	,
["Michalkiewicz",	247.74534	]	,
["Kuchnicki",	247.75128	]	,
["Fularczyk",	247.75722	]	,
["Foksinski",	247.76316	]	,
["Ciazynski",	247.7691	]	,
["Broniarek",	247.77504	]	,
["Zabka",	247.78097	]	,
["Ziebinski",	247.7869	]	,
["Wnuk-Lipinski",	247.79283	]	,
["Towarek",	247.79876	]	,
["Sugier",	247.80469	]	,
["Pikula",	247.81062	]	,
["Pawlonka",	247.81655	]	,
["Opacki",	247.82248	]	,
["Marosz",	247.82841	]	,
["Kut",	247.83434	]	,
["Grymuza",	247.84027	]	,
["Dabkiewicz",	247.8462	]	,
["Ciechowicz",	247.85213	]	,
["Cembrzynski",	247.85806	]	,
["Brodawka",	247.86399	]	,
["Borzych",	247.86992	]	,
["Bela",	247.87585	]	,
["Zagula",	247.88177	]	,
["Warszewski",	247.88769	]	,
["Tyniec",	247.89361	]	,
["Trepczyk",	247.89953	]	,
["Toczyski",	247.90545	]	,
["Swedrowski",	247.91137	]	,
["Stwora",	247.91729	]	,
["Paczos",	247.92321	]	,
["Olbrych",	247.92913	]	,
["Ogrodowicz",	247.93505	]	,
["Nadratowski",	247.94097	]	,
["Michel",	247.94689	]	,
["Mazepa",	247.95281	]	,
["Lazarek",	247.95873	]	,
["Krzystek",	247.96465	]	,
["Klodawski",	247.97057	]	,
["Jazdzyk",	247.97649	]	,
["Gralinski",	247.98241	]	,
["Goska",	247.98833	]	,
["Garbacki",	247.99425	]	,
["Fraszczyk",	248.00017	]	,
["Drozdzal",	248.00609	]	,
["Cofala",	248.01201	]	,
["Cholody",	248.01793	]	,
["Brewczynski",	248.02385	]	,
["Wawrzyk",	248.02976	]	,
["Prokurat",	248.03567	]	,
["Policht",	248.04158	]	,
["Plodzien",	248.04749	]	,
["Pasztaleniec",	248.0534	]	,
["Osipiuk",	248.05931	]	,
["Matenko",	248.06522	]	,
["Kiciak",	248.07113	]	,
["Grotek",	248.07704	]	,
["Czlonka",	248.08295	]	,
["Ciurzynski",	248.08886	]	,
["Zal",	248.09476	]	,
["Zimmer",	248.10066	]	,
["Wosiak",	248.10656	]	,
["Srokosz",	248.11246	]	,
["Pazdziora",	248.11836	]	,
["Patola",	248.12426	]	,
["Palega",	248.13016	]	,
["Orawiec",	248.13606	]	,
["Nosowski",	248.14196	]	,
["Nastaj",	248.14786	]	,
["Mirgos",	248.15376	]	,
["Merda",	248.15966	]	,
["Machniak",	248.16556	]	,
["Lokietek",	248.17146	]	,
["Fogiel",	248.17736	]	,
["Elias",	248.18326	]	,
["Zolynski",	248.18915	]	,
["Swiergiel",	248.19504	]	,
["Stempel",	248.20093	]	,
["Skocz",	248.20682	]	,
["Senkowski",	248.21271	]	,
["Potoczek",	248.2186	]	,
["Plutowski",	248.22449	]	,
["Penar",	248.23038	]	,
["Miecznik",	248.23627	]	,
["Kwapiszewski",	248.24216	]	,
["Kwapis",	248.24805	]	,
["Jakóbiak",	248.25394	]	,
["Gietka",	248.25983	]	,
["Flisek",	248.26572	]	,
["Dudzicz",	248.27161	]	,
["Cich",	248.2775	]	,
["Broniek",	248.28339	]	,
["Bielaszewski",	248.28928	]	,
["Wiercigroch",	248.29516	]	,
["Usarek",	248.30104	]	,
["Tryc",	248.30692	]	,
["Szylar",	248.3128	]	,
["Szczot",	248.31868	]	,
["Pytlinski",	248.32456	]	,
["Ptok",	248.33044	]	,
["Prystupa",	248.33632	]	,
["Preuss",	248.3422	]	,
["Piekara",	248.34808	]	,
["Laszczyk",	248.35396	]	,
["Kurzaj",	248.35984	]	,
["Kopiczko",	248.36572	]	,
["Jachimczak",	248.3716	]	,
["Hryniewiecki",	248.37748	]	,
["Hirsch",	248.38336	]	,
["Dytrych",	248.38924	]	,
["Dorna",	248.39512	]	,
["Bystron",	248.401	]	,
["Worach",	248.40687	]	,
["Werpachowski",	248.41274	]	,
["Tokaj",	248.41861	]	,
["Szmagaj",	248.42448	]	,
["Solnica",	248.43035	]	,
["Rejmak",	248.43622	]	,
["Reimann",	248.44209	]	,
["Pazola",	248.44796	]	,
["Nieradzik",	248.45383	]	,
["Migalski",	248.4597	]	,
["Miechowicz",	248.46557	]	,
["Lawinski",	248.47144	]	,
["Lepczynski",	248.47731	]	,
["Langiewicz",	248.48318	]	,
["Krus",	248.48905	]	,
["Kozien",	248.49492	]	,
["Kielczyk",	248.50079	]	,
["Jargilo",	248.50666	]	,
["Gwiazdzinski",	248.51253	]	,
["Dabal",	248.5184	]	,
["Cichos",	248.52427	]	,
["Browarski",	248.53014	]	,
["Angowski",	248.53601	]	,
["Sulowski",	248.54187	]	,
["Sorbian",	248.54773	]	,
["Ruman",	248.55359	]	,
["Rapacki",	248.55945	]	,
["Piotrkowicz",	248.56531	]	,
["Ozieblo",	248.57117	]	,
["Marzecki",	248.57703	]	,
["Litkowski",	248.58289	]	,
["Henke",	248.58875	]	,
["Drapiewski",	248.59461	]	,
["Czosnyka",	248.60047	]	,
["Choina",	248.60633	]	,
["Chabior",	248.61219	]	,
["Babecki",	248.61805	]	,
["Widerski",	248.6239	]	,
["Warzybok",	248.62975	]	,
["Walachowski",	248.6356	]	,
["Seweryniak",	248.64145	]	,
["Pyzel",	248.6473	]	,
["Niewola",	248.65315	]	,
["Nesterowicz",	248.659	]	,
["Liss",	248.66485	]	,
["Kurnicki",	248.6707	]	,
["Kiepas",	248.67655	]	,
["Kalista",	248.6824	]	,
["Jaruszewski",	248.68825	]	,
["Drohomirecki",	248.6941	]	,
["Demianczuk",	248.69995	]	,
["Clapa",	248.7058	]	,
["Blasik",	248.71165	]	,
["Berdzik",	248.7175	]	,
["Belza",	248.72335	]	,
["Zlotek",	248.72919	]	,
["Wojdalski",	248.73503	]	,
["Tonder",	248.74087	]	,
["Szwaj",	248.74671	]	,
["Szarzec",	248.75255	]	,
["Suchora",	248.75839	]	,
["Strzelinski",	248.76423	]	,
["Sarota",	248.77007	]	,
["Palica",	248.77591	]	,
["Nafalski",	248.78175	]	,
["Matula",	248.78759	]	,
["Malecha",	248.79343	]	,
["Magryta",	248.79927	]	,
["Luckiewicz",	248.80511	]	,
["Kuster",	248.81095	]	,
["Gromadzinski",	248.81679	]	,
["Teski",	248.82262	]	,
["Stoltman",	248.82845	]	,
["Siewert",	248.83428	]	,
["Serwach",	248.84011	]	,
["Schwarz",	248.84594	]	,
["Rytlewski",	248.85177	]	,
["Niezbecki",	248.8576	]	,
["Kuznia",	248.86343	]	,
["Kusmider",	248.86926	]	,
["Kurzac",	248.87509	]	,
["Klisz",	248.88092	]	,
["Gwardiak",	248.88675	]	,
["Gotfryd",	248.89258	]	,
["Deneka",	248.89841	]	,
["Ciurus",	248.90424	]	,
["Zmija",	248.91006	]	,
["Zdunowski",	248.91588	]	,
["Talaj",	248.9217	]	,
["Swierzynski",	248.92752	]	,
["Sobus",	248.93334	]	,
["Serwinski",	248.93916	]	,
["Rajman",	248.94498	]	,
["Perlik",	248.9508	]	,
["Kurda",	248.95662	]	,
["Kosznik",	248.96244	]	,
["Kaluga",	248.96826	]	,
["Jaracz",	248.97408	]	,
["Hanas",	248.9799	]	,
["Gilarski",	248.98572	]	,
["Dzwonnik",	248.99154	]	,
["Dlugoszewski",	248.99736	]	,
["Ziegert",	249.00317	]	,
["Zapotoczny",	249.00898	]	,
["Wyszogrodzki",	249.01479	]	,
["Sliwczynski",	249.0206	]	,
["Szyma",	249.02641	]	,
["Szczypiorski",	249.03222	]	,
["Rózewicz",	249.03803	]	,
["Paszkowiak",	249.04384	]	,
["Maslach",	249.04965	]	,
["Lewicz",	249.05546	]	,
["Krassowski",	249.06127	]	,
["Heba",	249.06708	]	,
["Godzwon",	249.07289	]	,
["Drej",	249.0787	]	,
["Borak",	249.08451	]	,
["Adamów",	249.09032	]	,
["Tywoniuk",	249.09612	]	,
["Scieszka",	249.10192	]	,
["Smal",	249.10772	]	,
["Labus",	249.11352	]	,
["Krokowski",	249.11932	]	,
["Kominiak",	249.12512	]	,
["Dietrich",	249.13092	]	,
["Cakala",	249.13672	]	,
["Budzich",	249.14252	]	,
["Babol",	249.14832	]	,
["Zgola",	249.15411	]	,
["Sladek",	249.1599	]	,
["Sierzant",	249.16569	]	,
["Radulski",	249.17148	]	,
["Naczynski",	249.17727	]	,
["Moczarski",	249.18306	]	,
["Misiurek",	249.18885	]	,
["Miasik",	249.19464	]	,
["Madrzyk",	249.20043	]	,
["Kretowicz",	249.20622	]	,
["Kasznia",	249.21201	]	,
["Jezyna",	249.2178	]	,
["Humeniuk",	249.22359	]	,
["Fiutak",	249.22938	]	,
["Czerniakiewicz",	249.23517	]	,
["Cierocki",	249.24096	]	,
["Bork",	249.24675	]	,
["Zymelka",	249.25253	]	,
["Tomalik",	249.25831	]	,
["Szarpak",	249.26409	]	,
["Soltan",	249.26987	]	,
["Maciuszek",	249.27565	]	,
["Krysta",	249.28143	]	,
["Kluczny",	249.28721	]	,
["Judycki",	249.29299	]	,
["Grzeszkowiak",	249.29877	]	,
["Dziwoki",	249.30455	]	,
["Brachman",	249.31033	]	,
["Berski",	249.31611	]	,
["Zys",	249.32188	]	,
["Wyrwinski",	249.32765	]	,
["Westfal",	249.33342	]	,
["Waluk",	249.33919	]	,
["Waclawiak",	249.34496	]	,
["Stopczynski",	249.35073	]	,
["Saluda",	249.3565	]	,
["Sabak",	249.36227	]	,
["Papinski",	249.36804	]	,
["Nieznalski",	249.37381	]	,
["Niedojadlo",	249.37958	]	,
["Nazarko",	249.38535	]	,
["Murat",	249.39112	]	,
["Majzner",	249.39689	]	,
["Ludwin",	249.40266	]	,
["Kubaczyk",	249.40843	]	,
["Kielich",	249.4142	]	,
["Dzierzgowski",	249.41997	]	,
["Dominowski",	249.42574	]	,
["Doliwa",	249.43151	]	,
["Dej",	249.43728	]	,
["Chuchla",	249.44305	]	,
["Bogus",	249.44882	]	,
["Bobik",	249.45459	]	,
["Zadworny",	249.46035	]	,
["Wójs",	249.46611	]	,
["Tyma",	249.47187	]	,
["Sztuczka",	249.47763	]	,
["Strzadala",	249.48339	]	,
["Sowala",	249.48915	]	,
["Omiotek",	249.49491	]	,
["Oleskiewicz",	249.50067	]	,
["Morawiak",	249.50643	]	,
["Kwapisiewicz",	249.51219	]	,
["Krokosz",	249.51795	]	,
["Kretkowski",	249.52371	]	,
["Kostkowski",	249.52947	]	,
["Hajder",	249.53523	]	,
["Garczyk",	249.54099	]	,
["Drapinski",	249.54675	]	,
["Burdach",	249.55251	]	,
["Buchowski",	249.55827	]	,
["Zwiazek",	249.56402	]	,
["Wojczuk",	249.56977	]	,
["Wachnicki",	249.57552	]	,
["Staszowski",	249.58127	]	,
["Stanclik",	249.58702	]	,
["Sidorski",	249.59277	]	,
["Piekart",	249.59852	]	,
["Parulski",	249.60427	]	,
["Mielke",	249.61002	]	,
["Machowicz",	249.61577	]	,
["Kozieja",	249.62152	]	,
["Kaziród",	249.62727	]	,
["Gas",	249.63302	]	,
["Garbaciak",	249.63877	]	,
["Chatys",	249.64452	]	,
["Bzdega",	249.65027	]	,
["Bartoszczyk",	249.65602	]	,
["Alberski",	249.66177	]	,
["Zdulski",	249.66751	]	,
["Zdonek",	249.67325	]	,
["Wyrebski",	249.67899	]	,
["Wieclawek",	249.68473	]	,
["Wielgo",	249.69047	]	,
["Steuer",	249.69621	]	,
["Starega",	249.70195	]	,
["Sakwa",	249.70769	]	,
["Orpel",	249.71343	]	,
["Lazinski",	249.71917	]	,
["Kobel",	249.72491	]	,
["Golonko",	249.73065	]	,
["Stark",	249.73638	]	,
["Soczówka",	249.74211	]	,
["Nickel",	249.74784	]	,
["Kwasnicki",	249.75357	]	,
["Kupaj",	249.7593	]	,
["Kolman",	249.76503	]	,
["Kieca",	249.77076	]	,
["Kamyk",	249.77649	]	,
["Jezyk",	249.78222	]	,
["Godzinski",	249.78795	]	,
["Glica",	249.79368	]	,
["Gasz",	249.79941	]	,
["Gamrat",	249.80514	]	,
["Franiak",	249.81087	]	,
["Bonarski",	249.8166	]	,
["Bacik",	249.82233	]	,
["Andrukiewicz",	249.82806	]	,
["Ulikowski",	249.83378	]	,
["Troka",	249.8395	]	,
["Stanikowski",	249.84522	]	,
["Siwka",	249.85094	]	,
["Odrzywolek",	249.85666	]	,
["Nurkiewicz",	249.86238	]	,
["Kozubal",	249.8681	]	,
["Kott",	249.87382	]	,
["Karmowski",	249.87954	]	,
["Glowienka",	249.88526	]	,
["Drozdzowski",	249.89098	]	,
["Doroszuk",	249.8967	]	,
["Cogiel",	249.90242	]	,
["Cheba",	249.90814	]	,
["Bas",	249.91386	]	,
["Andreasik",	249.91958	]	,
["Zakaszewski",	249.92529	]	,
["Wenzel",	249.931	]	,
["Tuczynski",	249.93671	]	,
["Szumna",	249.94242	]	,
["Szumlanski",	249.94813	]	,
["Roslon",	249.95384	]	,
["Piaszczynski",	249.95955	]	,
["Oglaza",	249.96526	]	,
["Myslicki",	249.97097	]	,
["Miklaszewicz",	249.97668	]	,
["Kubieniec",	249.98239	]	,
["Jedral",	249.9881	]	,
["Gustowski",	249.99381	]	,
["Dunajewski",	249.99952	]	,
["Bortnowski",	250.00523	]	,
["Bieniak",	250.01094	]	,
["Wons",	250.01664	]	,
["Wladyka",	250.02234	]	,
["Sulecki",	250.02804	]	,
["Rolak",	250.03374	]	,
["Prejs",	250.03944	]	,
["Pokrzywnicki",	250.04514	]	,
["Plocharczyk",	250.05084	]	,
["Ostrega",	250.05654	]	,
["Legowik",	250.06224	]	,
["Ludwik",	250.06794	]	,
["Kopik",	250.07364	]	,
["Kleinschmidt",	250.07934	]	,
["Karczmarek",	250.08504	]	,
["Gladka",	250.09074	]	,
["Czylok",	250.09644	]	,
["Ciechowski",	250.10214	]	,
["Bandrowski",	250.10784	]	,
["Wawrzynkiewicz",	250.11353	]	,
["Warmbier",	250.11922	]	,
["Tober",	250.12491	]	,
["Smilowski",	250.1306	]	,
["Staskowiak",	250.13629	]	,
["Staruch",	250.14198	]	,
["Sobania",	250.14767	]	,
["Sergiel",	250.15336	]	,
["Prymas",	250.15905	]	,
["Nowobilski",	250.16474	]	,
["Luterek",	250.17043	]	,
["Lidzbarski",	250.17612	]	,
["Komornicki",	250.18181	]	,
["Klinowski",	250.1875	]	,
["Kaszyca",	250.19319	]	,
["Gorzycki",	250.19888	]	,
["Zólkowski",	250.20456	]	,
["Zgraja",	250.21024	]	,
["Zawilinski",	250.21592	]	,
["Wozniewski",	250.2216	]	,
["Szymeczko",	250.22728	]	,
["Szablinski",	250.23296	]	,
["Sidlo",	250.23864	]	,
["Purol",	250.24432	]	,
["Kumiega",	250.25	]	,
["Grzejszczyk",	250.25568	]	,
["Fortuniak",	250.26136	]	,
["Cyganski",	250.26704	]	,
["Banaskiewicz",	250.27272	]	,
["Zygowski",	250.27839	]	,
["Walerowicz",	250.28406	]	,
["Smolnik",	250.28973	]	,
["Siwczyk",	250.2954	]	,
["Rzyski",	250.30107	]	,
["Rafalowski",	250.30674	]	,
["Mielczarski",	250.31241	]	,
["Maluchnik",	250.31808	]	,
["Malczak",	250.32375	]	,
["Kostrzynski",	250.32942	]	,
["Jasiczek",	250.33509	]	,
["Hernas",	250.34076	]	,
["Haberka",	250.34643	]	,
["Gawarecki",	250.3521	]	,
["Gapa",	250.35777	]	,
["Felis",	250.36344	]	,
["Czauderna",	250.36911	]	,
["Cyra",	250.37478	]	,
["Biadala",	250.38045	]	,
["Zukiewicz",	250.38611	]	,
["Zbytniewski",	250.39177	]	,
["Wira",	250.39743	]	,
["Tarnacki",	250.40309	]	,
["Szymendera",	250.40875	]	,
["Rumin",	250.41441	]	,
["Pudlik",	250.42007	]	,
["Obszanski",	250.42573	]	,
["Miedzianowski",	250.43139	]	,
["Lyzwinski",	250.43705	]	,
["Lewanski",	250.44271	]	,
["Kotkiewicz",	250.44837	]	,
["Katulski",	250.45403	]	,
["Jonkisz",	250.45969	]	,
["Dymecki",	250.46535	]	,
["Drzycimski",	250.47101	]	,
["Chodzinski",	250.47667	]	,
["Bilas",	250.48233	]	,
["Andres",	250.48799	]	,
["Zmitrowicz",	250.49364	]	,
["Welniak",	250.49929	]	,
["Spytek",	250.50494	]	,
["Slodczyk",	250.51059	]	,
["Pieszko",	250.51624	]	,
["Pieprzyca",	250.52189	]	,
["Lyszcz",	250.52754	]	,
["Lazarek",	250.53319	]	,
["Kubczak",	250.53884	]	,
["Korman",	250.54449	]	,
["Jochymek",	250.55014	]	,
["Fedor",	250.55579	]	,
["Drewek",	250.56144	]	,
["Ciosk",	250.56709	]	,
["Bogdziewicz",	250.57274	]	,
["Blazynski",	250.57839	]	,
["Baczkowski",	250.58404	]	,
["Angielczyk",	250.58969	]	,
["Toman",	250.59533	]	,
["Slepowronski",	250.60097	]	,
["Supera",	250.60661	]	,
["Petka",	250.61225	]	,
["Oko",	250.61789	]	,
["Mirosz",	250.62353	]	,
["Mikulec",	250.62917	]	,
["Mielech",	250.63481	]	,
["Kulesa",	250.64045	]	,
["Komorniczak",	250.64609	]	,
["Glabicki",	250.65173	]	,
["Durajczyk",	250.65737	]	,
["Brzecki",	250.66301	]	,
["Bolda",	250.66865	]	,
["Bochniarz",	250.67429	]	,
["Bielik",	250.67993	]	,
["Zdzieblo",	250.68556	]	,
["Wasacz",	250.69119	]	,
["Telus",	250.69682	]	,
["Tatarynowicz",	250.70245	]	,
["Sciebura",	250.70808	]	,
["Skowera",	250.71371	]	,
["Ruranski",	250.71934	]	,
["Rocki",	250.72497	]	,
["Niemczak",	250.7306	]	,
["Mozdzonek",	250.73623	]	,
["Królewicz",	250.74186	]	,
["Kazubski",	250.74749	]	,
["Horoszko",	250.75312	]	,
["Gumiela",	250.75875	]	,
["Gamrot",	250.76438	]	,
["Dechnik",	250.77001	]	,
["Cyl",	250.77564	]	,
["Chuchro",	250.78127	]	,
["Celary",	250.7869	]	,
["Bafia",	250.79253	]	,
["Szmytka",	250.79815	]	,
["Romek",	250.80377	]	,
["Paluszewski",	250.80939	]	,
["Morga",	250.81501	]	,
["Krzyzanek",	250.82063	]	,
["Gryniewicz",	250.82625	]	,
["Gierlach",	250.83187	]	,
["Folek",	250.83749	]	,
["Fert",	250.84311	]	,
["Dryl",	250.84873	]	,
["Chyczewski",	250.85435	]	,
["Chudzynski",	250.85997	]	,
["Biadun",	250.86559	]	,
["Zydron",	250.8712	]	,
["Zalech",	250.87681	]	,
["Syga",	250.88242	]	,
["Semczuk",	250.88803	]	,
["Sadzinski",	250.89364	]	,
["Repec",	250.89925	]	,
["Post",	250.90486	]	,
["Plowiec",	250.91047	]	,
["Pilinski",	250.91608	]	,
["Piernik",	250.92169	]	,
["Osmialowski",	250.9273	]	,
["Oryl",	250.93291	]	,
["Nagadowski",	250.93852	]	,
["Mekarski",	250.94413	]	,
["Kuzak",	250.94974	]	,
["Kluge",	250.95535	]	,
["Kafarski",	250.96096	]	,
["Jakubów",	250.96657	]	,
["Cieslikiewicz",	250.97218	]	,
["Chyla",	250.97779	]	,
["Chmielarczyk",	250.9834	]	,
["Cempa",	250.98901	]	,
["Boroch",	250.99462	]	,
["Zmyslona",	251.00022	]	,
["Wiaderny",	251.00582	]	,
["Tyszkowski",	251.01142	]	,
["Sliwiak",	251.01702	]	,
["Szalkowski",	251.02262	]	,
["Sasinowski",	251.02822	]	,
["Rozpedowski",	251.03382	]	,
["Raczak",	251.03942	]	,
["Platkowski",	251.04502	]	,
["Olewnik",	251.05062	]	,
["Nawracaj",	251.05622	]	,
["Krzyzek",	251.06182	]	,
["Krokos",	251.06742	]	,
["Koziak",	251.07302	]	,
["Klobukowski",	251.07862	]	,
["Horak",	251.08422	]	,
["Gagatek",	251.08982	]	,
["Dziewanowski",	251.09542	]	,
["Dawidziak",	251.10102	]	,
["Czekajlo",	251.10662	]	,
["Cieplucha",	251.11222	]	,
["Chlosta",	251.11782	]	,
["Zubik",	251.12341	]	,
["Zator",	251.129	]	,
["Tybura",	251.13459	]	,
["Stola",	251.14018	]	,
["Reichert",	251.14577	]	,
["Nastarowicz",	251.15136	]	,
["Miastkowski",	251.15695	]	,
["Leman",	251.16254	]	,
["Kryjak",	251.16813	]	,
["Grzeszkiewicz",	251.17372	]	,
["Grudziecki",	251.17931	]	,
["Gabzdyl",	251.1849	]	,
["Fibich",	251.19049	]	,
["Dziok",	251.19608	]	,
["Drabarek",	251.20167	]	,
["Dawicki",	251.20726	]	,
["Ulbrich",	251.21284	]	,
["Synoradzki",	251.21842	]	,
["Staniucha",	251.224	]	,
["Sowula",	251.22958	]	,
["Sielawa",	251.23516	]	,
["Rymkiewicz",	251.24074	]	,
["Rakowicz",	251.24632	]	,
["Pruchniewski",	251.2519	]	,
["Prawda",	251.25748	]	,
["Nowotna",	251.26306	]	,
["Necel",	251.26864	]	,
["Majerowski",	251.27422	]	,
["Lesicki",	251.2798	]	,
["Kwit",	251.28538	]	,
["Krystosiak",	251.29096	]	,
["Karolewicz",	251.29654	]	,
["Jurzyk",	251.30212	]	,
["Irek",	251.3077	]	,
["Gurbiel",	251.31328	]	,
["Grzona",	251.31886	]	,
["Gbur",	251.32444	]	,
["Ciosmak",	251.33002	]	,
["Zietkiewicz",	251.33559	]	,
["Wernicki",	251.34116	]	,
["Sobaszek",	251.34673	]	,
["Sajewski",	251.3523	]	,
["Reczulski",	251.35787	]	,
["Podoba",	251.36344	]	,
["Maciagowski",	251.36901	]	,
["Loranty",	251.37458	]	,
["Liberda",	251.38015	]	,
["Kurosz",	251.38572	]	,
["Kucybala",	251.39129	]	,
["Kortus",	251.39686	]	,
["Kocyba",	251.40243	]	,
["Kapitan",	251.408	]	,
["Huras",	251.41357	]	,
["Hoszowski",	251.41914	]	,
["Dziag",	251.42471	]	,
["Chilimoniuk",	251.43028	]	,
["Boszko",	251.43585	]	,
["Zurawicz",	251.44141	]	,
["Zólkiewski",	251.44697	]	,
["Zloch",	251.45253	]	,
["Wojtynek",	251.45809	]	,
["Szostok",	251.46365	]	,
["Sykulski",	251.46921	]	,
["Sliwa",	251.47477	]	,
["Piwnik",	251.48033	]	,
["Papiewski",	251.48589	]	,
["Malkinski",	251.49145	]	,
["Kuliga",	251.49701	]	,
["Jaglarz",	251.50257	]	,
["Holak",	251.50813	]	,
["Hareza",	251.51369	]	,
["Firszt",	251.51925	]	,
["Dworski",	251.52481	]	,
["Dakowicz",	251.53037	]	,
["Cieslukowski",	251.53593	]	,
["Andryszczak",	251.54149	]	,
["Zegan",	251.54704	]	,
["Szalewski",	251.55259	]	,
["Popielas",	251.55814	]	,
["Perdek",	251.56369	]	,
["Mazurczyk",	251.56924	]	,
["Masiewicz",	251.57479	]	,
["Lezanski",	251.58034	]	,
["Kuter",	251.58589	]	,
["Kunkel",	251.59144	]	,
["Kotyrba",	251.59699	]	,
["Kosmatka",	251.60254	]	,
["Koprowicz",	251.60809	]	,
["Klóska",	251.61364	]	,
["Kasjaniuk",	251.61919	]	,
["Henzel",	251.62474	]	,
["Gurdak",	251.63029	]	,
["Gryn",	251.63584	]	,
["Doroszko",	251.64139	]	,
["Bienczak",	251.64694	]	,
["Bauman",	251.65249	]	,
["Anisko",	251.65804	]	,
["Swiat",	251.66358	]	,
["Szotek",	251.66912	]	,
["Ramus",	251.67466	]	,
["Pyszniak",	251.6802	]	,
["Lasocha",	251.68574	]	,
["Dziergas",	251.69128	]	,
["Dulak",	251.69682	]	,
["Dabrowiecki",	251.70236	]	,
["Waszkowiak",	251.70789	]	,
["Spaczynski",	251.71342	]	,
["Sandomierski",	251.71895	]	,
["Pierzgalski",	251.72448	]	,
["Pachura",	251.73001	]	,
["Nachyla",	251.73554	]	,
["Michaliszyn",	251.74107	]	,
["Kurgan",	251.7466	]	,
["Klaman",	251.75213	]	,
["Jekielek",	251.75766	]	,
["Jabczynski",	251.76319	]	,
["Grybos",	251.76872	]	,
["Gorzka",	251.77425	]	,
["Goracy",	251.77978	]	,
["Gauza",	251.78531	]	,
["Fabisz",	251.79084	]	,
["Brewka",	251.79637	]	,
["Bolinski",	251.8019	]	,
["Bartz",	251.80743	]	,
["Wicherek",	251.81295	]	,
["Walendziak",	251.81847	]	,
["Stepczynski",	251.82399	]	,
["Sionek",	251.82951	]	,
["Pilc",	251.83503	]	,
["Piaszczyk",	251.84055	]	,
["Naróg",	251.84607	]	,
["Lubiejewski",	251.85159	]	,
["Kurzepa",	251.85711	]	,
["Dzierzega",	251.86263	]	,
["Dworczyk",	251.86815	]	,
["Drews",	251.87367	]	,
["Chajec",	251.87919	]	,
["Bedynski",	251.88471	]	,
["Zlotnicki",	251.89022	]	,
["Wypychowski",	251.89573	]	,
["Wykurz",	251.90124	]	,
["Wyganowski",	251.90675	]	,
["Wdowik",	251.91226	]	,
["Tarnawa",	251.91777	]	,
["Strugarek",	251.92328	]	,
["Romaszko",	251.92879	]	,
["Rogoza",	251.9343	]	,
["Rafal",	251.93981	]	,
["Plaski",	251.94532	]	,
["Palimaka",	251.95083	]	,
["Ogórkiewicz",	251.95634	]	,
["Myslak",	251.96185	]	,
["Luba",	251.96736	]	,
["Kwasigroch",	251.97287	]	,
["Kubiszyn",	251.97838	]	,
["Kopel",	251.98389	]	,
["Klebek",	251.9894	]	,
["Holynski",	251.99491	]	,
["Gryglicki",	252.00042	]	,
["Gniadzik",	252.00593	]	,
["Glasek",	252.01144	]	,
["Gawryjolek",	252.01695	]	,
["Fajkis",	252.02246	]	,
["Dyrkacz",	252.02797	]	,
["Ciesinski",	252.03348	]	,
["Cibis",	252.03899	]	,
["Cekala",	252.0445	]	,
["Brzuszkiewicz",	252.05001	]	,
["Blaszczok",	252.05552	]	,
["Batura",	252.06103	]	,
["Barucha",	252.06654	]	,
["Swierzewski",	252.07204	]	,
["Stachniuk",	252.07754	]	,
["Podstawski",	252.08304	]	,
["Patelczyk",	252.08854	]	,
["Olów",	252.09404	]	,
["Machalica",	252.09954	]	,
["Kondratiuk",	252.10504	]	,
["Koczkodaj",	252.11054	]	,
["Klonowski",	252.11604	]	,
["Grobel",	252.12154	]	,
["Grden",	252.12704	]	,
["Ewiak",	252.13254	]	,
["Damasiewicz",	252.13804	]	,
["Brus",	252.14354	]	,
["Binieda",	252.14904	]	,
["Bialozyt",	252.15454	]	,
["Bartusiak",	252.16004	]	,
["Abramski",	252.16554	]	,
["Zawora",	252.17103	]	,
["Wardecki",	252.17652	]	,
["Nosiadek",	252.18201	]	,
["Marc",	252.1875	]	,
["Lapeta",	252.19299	]	,
["Klisiewicz",	252.19848	]	,
["Jurzak",	252.20397	]	,
["Jurski",	252.20946	]	,
["Jadczyk",	252.21495	]	,
["Gniot",	252.22044	]	,
["Gasperowicz",	252.22593	]	,
["Dargiewicz",	252.23142	]	,
["Cudzilo",	252.23691	]	,
["Chwistek",	252.2424	]	,
["Bies",	252.24789	]	,
["Wojtynski",	252.25337	]	,
["Wojtun",	252.25885	]	,
["Tuz",	252.26433	]	,
["Sochon",	252.26981	]	,
["Slapek",	252.27529	]	,
["Siekanski",	252.28077	]	,
["Rachuba",	252.28625	]	,
["Plaza",	252.29173	]	,
["Pietruszkiewicz",	252.29721	]	,
["Piepiórka",	252.30269	]	,
["Owoc",	252.30817	]	,
["Mórawski",	252.31365	]	,
["Link",	252.31913	]	,
["Kryszczak",	252.32461	]	,
["Kornaszewski",	252.33009	]	,
["Kontek",	252.33557	]	,
["Hajok",	252.34105	]	,
["Fedoruk",	252.34653	]	,
["Dzimira",	252.35201	]	,
["Dreszler",	252.35749	]	,
["Bryszewski",	252.36297	]	,
["Banat",	252.36845	]	,
["Ziebicki",	252.37392	]	,
["Zablotny",	252.37939	]	,
["Wrzyszcz",	252.38486	]	,
["Wardach",	252.39033	]	,
["Siwko",	252.3958	]	,
["Redzik",	252.40127	]	,
["Radzioch",	252.40674	]	,
["Radlak",	252.41221	]	,
["Przybylko",	252.41768	]	,
["Potoniec",	252.42315	]	,
["Pieper",	252.42862	]	,
["Mitula",	252.43409	]	,
["Miroslawski",	252.43956	]	,
["Lepiarz",	252.44503	]	,
["Kuboszek",	252.4505	]	,
["Kotara",	252.45597	]	,
["Kieda",	252.46144	]	,
["Jarych",	252.46691	]	,
["Jaglinski",	252.47238	]	,
["Daroszewski",	252.47785	]	,
["Ciechacki",	252.48332	]	,
["Chyra",	252.48879	]	,
["Banasiuk",	252.49426	]	,
["Zakowicz",	252.49972	]	,
["Zastawna",	252.50518	]	,
["Weiner",	252.51064	]	,
["Starnawski",	252.5161	]	,
["Soluch",	252.52156	]	,
["Rymer",	252.52702	]	,
["Muciek",	252.53248	]	,
["Karolczuk",	252.53794	]	,
["Idasiak",	252.5434	]	,
["Firla",	252.54886	]	,
["Dyrka",	252.55432	]	,
["Dreger",	252.55978	]	,
["Cichacki",	252.56524	]	,
["Bodo",	252.5707	]	,
["Blauciak",	252.57616	]	,
["Wosztyl",	252.58161	]	,
["Szlaski",	252.58706	]	,
["Siewruk",	252.59251	]	,
["Pólrolniczak",	252.59796	]	,
["Pietkun",	252.60341	]	,
["Pielecki",	252.60886	]	,
["Paulinski",	252.61431	]	,
["Minkowski",	252.61976	]	,
["Kurczyk",	252.62521	]	,
["Galeski",	252.63066	]	,
["Fonfara",	252.63611	]	,
["Durek",	252.64156	]	,
["Belter",	252.64701	]	,
["Zulewski",	252.65245	]	,
["Zimmermann",	252.65789	]	,
["Turalski",	252.66333	]	,
["Sztuk",	252.66877	]	,
["Smusz",	252.67421	]	,
["Sarapata",	252.67965	]	,
["Pituch",	252.68509	]	,
["Murek",	252.69053	]	,
["Mayer",	252.69597	]	,
["Lamek",	252.70141	]	,
["Florianczyk",	252.70685	]	,
["Dziewiatkowski",	252.71229	]	,
["Dmuchowski",	252.71773	]	,
["Cwyl",	252.72317	]	,
["Szczepek",	252.7286	]	,
["Slodownik",	252.73403	]	,
["Skalinski",	252.73946	]	,
["Rafalko",	252.74489	]	,
["Pampuch",	252.75032	]	,
["Obloza",	252.75575	]	,
["Nieslony",	252.76118	]	,
["Madon",	252.76661	]	,
["Lagosz",	252.77204	]	,
["Kusior",	252.77747	]	,
["Koscielecki",	252.7829	]	,
["Knura",	252.78833	]	,
["Drazewski",	252.79376	]	,
["Demkowicz",	252.79919	]	,
["Cierzniak",	252.80462	]	,
["Brycki",	252.81005	]	,
["Zaczkiewicz",	252.81547	]	,
["Zgódka",	252.82089	]	,
["Zawodniak",	252.82631	]	,
["Zablotna",	252.83173	]	,
["Wojtulewicz",	252.83715	]	,
["Wojteczek",	252.84257	]	,
["Tomczyszyn",	252.84799	]	,
["Rzodkiewicz",	252.85341	]	,
["Paterak",	252.85883	]	,
["Nadzieja",	252.86425	]	,
["Mizinski",	252.86967	]	,
["Miklasz",	252.87509	]	,
["Lacny",	252.88051	]	,
["Laton",	252.88593	]	,
["Kies",	252.89135	]	,
["Gara",	252.89677	]	,
["Fira",	252.90219	]	,
["Fabia",	252.90761	]	,
["Dziendziel",	252.91303	]	,
["Druzgala",	252.91845	]	,
["Broniecki",	252.92387	]	,
["Binda",	252.92929	]	,
["Wisnik",	252.9347	]	,
["Widurski",	252.94011	]	,
["Wasinski",	252.94552	]	,
["Szkopinski",	252.95093	]	,
["Socik",	252.95634	]	,
["Skokowski",	252.96175	]	,
["Reluga",	252.96716	]	,
["Pypec",	252.97257	]	,
["Necki",	252.97798	]	,
["Magon",	252.98339	]	,
["Kucner",	252.9888	]	,
["Komorski",	252.99421	]	,
["Jurzysta",	252.99962	]	,
["Dratwinski",	253.00503	]	,
["Czernia",	253.01044	]	,
["Choszcz",	253.01585	]	,
["Aranowski",	253.02126	]	,
["Tomkowicz",	253.02666	]	,
["Swierczyna",	253.03206	]	,
["Szumielewicz",	253.03746	]	,
["Szuman",	253.04286	]	,
["Samolej",	253.04826	]	,
["Romaniec",	253.05366	]	,
["Rawicki",	253.05906	]	,
["Petryka",	253.06446	]	,
["Pawski",	253.06986	]	,
["Nierobisz",	253.07526	]	,
["Metelski",	253.08066	]	,
["Masiarz",	253.08606	]	,
["Latkowski",	253.09146	]	,
["Laczna",	253.09686	]	,
["Labeda",	253.10226	]	,
["Kublik",	253.10766	]	,
["Jatkowski",	253.11306	]	,
["Hess",	253.11846	]	,
["Fengler",	253.12386	]	,
["Falat",	253.12926	]	,
["Dziaduch",	253.13466	]	,
["Dulian",	253.14006	]	,
["Boczula",	253.14546	]	,
["Biczysko",	253.15086	]	,
["Bembnista",	253.15626	]	,
["Barzowski",	253.16166	]	,
["Wodzislawski",	253.16705	]	,
["Trelinski",	253.17244	]	,
["Torunski",	253.17783	]	,
["Smaza",	253.18322	]	,
["Porozynski",	253.18861	]	,
["Pilawa",	253.194	]	,
["Pieklo",	253.19939	]	,
["Madrzejewski",	253.20478	]	,
["Machnowski",	253.21017	]	,
["Luczaj",	253.21556	]	,
["Kurzyk",	253.22095	]	,
["Kornaga",	253.22634	]	,
["Korejwo",	253.23173	]	,
["Kolankowski",	253.23712	]	,
["Holuj",	253.24251	]	,
["Holowacz",	253.2479	]	,
["Gluszczak",	253.25329	]	,
["Dryjanski",	253.25868	]	,
["Cnota",	253.26407	]	,
["Borucinski",	253.26946	]	,
["Borowicki",	253.27485	]	,
["Szustakowski",	253.28023	]	,
["Szczechura",	253.28561	]	,
["Stolorz",	253.29099	]	,
["Sochanski",	253.29637	]	,
["Sobien",	253.30175	]	,
["Retkowski",	253.30713	]	,
["Potyra",	253.31251	]	,
["Otulak",	253.31789	]	,
["Osypiuk",	253.32327	]	,
["Natkanski",	253.32865	]	,
["Mura",	253.33403	]	,
["Lichwa",	253.33941	]	,
["Kapturski",	253.34479	]	,
["Jeziorny",	253.35017	]	,
["Gozdecki",	253.35555	]	,
["Wiezik",	253.36092	]	,
["Trzmielewski",	253.36629	]	,
["Rachwalik",	253.37166	]	,
["Powalski",	253.37703	]	,
["Platos",	253.3824	]	,
["Ortyl",	253.38777	]	,
["Muzolf",	253.39314	]	,
["Liszkowski",	253.39851	]	,
["Halupka",	253.40388	]	,
["Gryglewicz",	253.40925	]	,
["Fedorczuk",	253.41462	]	,
["Cieplowski",	253.41999	]	,
["Chrupek",	253.42536	]	,
["Bazylak",	253.43073	]	,
["Badora",	253.4361	]	,
["Zolyniak",	253.44146	]	,
["Wojtacha",	253.44682	]	,
["Szostakiewicz",	253.45218	]	,
["Styk",	253.45754	]	,
["Strycharski",	253.4629	]	,
["Pradela",	253.46826	]	,
["Poszwa",	253.47362	]	,
["Naroznik",	253.47898	]	,
["Marcinów",	253.48434	]	,
["Lesniczak",	253.4897	]	,
["Klisowski",	253.49506	]	,
["Holka",	253.50042	]	,
["Fafinski",	253.50578	]	,
["Dzieciuch",	253.51114	]	,
["Dach",	253.5165	]	,
["Cetnarowski",	253.52186	]	,
["Antoszek",	253.52722	]	,
["Aniolkowski",	253.53258	]	,
["Andryszak",	253.53794	]	,
["Zuchowicz",	253.54329	]	,
["Wyborski",	253.54864	]	,
["Wiktorzak",	253.55399	]	,
["Wawrzaszek",	253.55934	]	,
["Watola",	253.56469	]	,
["Walowski",	253.57004	]	,
["Szymonek",	253.57539	]	,
["Slonecki",	253.58074	]	,
["Salata",	253.58609	]	,
["Sajkowski",	253.59144	]	,
["Rugala",	253.59679	]	,
["Piórko",	253.60214	]	,
["Peczak",	253.60749	]	,
["Osiewicz",	253.61284	]	,
["Kotyla",	253.61819	]	,
["Góralik",	253.62354	]	,
["Fiebig",	253.62889	]	,
["Cwiakala",	253.63424	]	,
["Buhl",	253.63959	]	,
["Bogiel",	253.64494	]	,
["Berbec",	253.65029	]	,
["Bendyk",	253.65564	]	,
["Balos",	253.66099	]	,
["Wiewiór",	253.66633	]	,
["Tuleja",	253.67167	]	,
["Tasak",	253.67701	]	,
["Rozwalka",	253.68235	]	,
["Oszczyk",	253.68769	]	,
["Madejek",	253.69303	]	,
["Kurzatkowski",	253.69837	]	,
["Krysztoforski",	253.70371	]	,
["Kosyl",	253.70905	]	,
["Jarkowski",	253.71439	]	,
["Granda",	253.71973	]	,
["Garbiec",	253.72507	]	,
["Dadas",	253.73041	]	,
["Boryn",	253.73575	]	,
["Blakala",	253.74109	]	,
["Ambroziewicz",	253.74643	]	,
["Agatowski",	253.75177	]	,
["Prusek",	253.7571	]	,
["Porzezinski",	253.76243	]	,
["Namyslak",	253.76776	]	,
["Mandziuk",	253.77309	]	,
["Kwiaton",	253.77842	]	,
["Kalenik",	253.78375	]	,
["Jusko",	253.78908	]	,
["Gieniusz",	253.79441	]	,
["Garbarz",	253.79974	]	,
["Folwarczny",	253.80507	]	,
["Fido",	253.8104	]	,
["Ernest",	253.81573	]	,
["Dubielecki",	253.82106	]	,
["Domitrz",	253.82639	]	,
["Chodor",	253.83172	]	,
["Bywalec",	253.83705	]	,
["Zembrzycki",	253.84237	]	,
["Wroniecki",	253.84769	]	,
["Welnicki",	253.85301	]	,
["Szokalski",	253.85833	]	,
["Szkwarek",	253.86365	]	,
["Skoniecki",	253.86897	]	,
["Sadzik",	253.87429	]	,
["Podgajny",	253.87961	]	,
["Nawalany",	253.88493	]	,
["Myszkiewicz",	253.89025	]	,
["Mistrzak",	253.89557	]	,
["Mirkiewicz",	253.90089	]	,
["Lip",	253.90621	]	,
["Jarmolinski",	253.91153	]	,
["Hasiuk",	253.91685	]	,
["Gudowski",	253.92217	]	,
["Derwich",	253.92749	]	,
["Czerpak",	253.93281	]	,
["Choroba",	253.93813	]	,
["Burylo",	253.94345	]	,
["Bortnik",	253.94877	]	,
["Torchala",	253.95408	]	,
["Tomków",	253.95939	]	,
["Suwara",	253.9647	]	,
["Pozarowszczyk",	253.97001	]	,
["Podles",	253.97532	]	,
["Pecki",	253.98063	]	,
["Lenartowski",	253.98594	]	,
["Kulesz",	253.99125	]	,
["Konowalski",	253.99656	]	,
["Kawiecki",	254.00187	]	,
["Karabin",	254.00718	]	,
["Haladus",	254.01249	]	,
["Grzebien",	254.0178	]	,
["Godzina",	254.02311	]	,
["Dybich",	254.02842	]	,
["Cwiklak",	254.03373	]	,
["Czapp",	254.03904	]	,
["Czapinski",	254.04435	]	,
["Budz",	254.04966	]	,
["Bombala",	254.05497	]	,
["Beszterda",	254.06028	]	,
["Aszyk",	254.06559	]	,
["Zadykowicz",	254.07089	]	,
["Wielemborek",	254.07619	]	,
["Sudól",	254.08149	]	,
["Stajniak",	254.08679	]	,
["Spisak",	254.09209	]	,
["Sopinski",	254.09739	]	,
["Roslan",	254.10269	]	,
["Rajchert",	254.10799	]	,
["Podgórniak",	254.11329	]	,
["Pikos",	254.11859	]	,
["Milik",	254.12389	]	,
["Maciszewski",	254.12919	]	,
["Lankiewicz",	254.13449	]	,
["Kasprzykowski",	254.13979	]	,
["Grubski",	254.14509	]	,
["Decyk",	254.15039	]	,
["Wietrzyk",	254.15568	]	,
["Trajdos",	254.16097	]	,
["Szrajber",	254.16626	]	,
["Surzyn",	254.17155	]	,
["Sontowski",	254.17684	]	,
["Sierka",	254.18213	]	,
["Popik",	254.18742	]	,
["Pabin",	254.19271	]	,
["Milkowski",	254.198	]	,
["Lakatosz",	254.20329	]	,
["Kopytek",	254.20858	]	,
["Karwasz",	254.21387	]	,
["Iracki",	254.21916	]	,
["Garbala",	254.22445	]	,
["Dziemba",	254.22974	]	,
["Czeczot",	254.23503	]	,
["Czaplarski",	254.24032	]	,
["Charczuk",	254.24561	]	,
["Biczak",	254.2509	]	,
["Ziober",	254.25618	]	,
["Zadworna",	254.26146	]	,
["Wujkowski",	254.26674	]	,
["Uchanski",	254.27202	]	,
["Smeja",	254.2773	]	,
["Siepka",	254.28258	]	,
["Raczkiewicz",	254.28786	]	,
["Przepiórkowski",	254.29314	]	,
["Otlowski",	254.29842	]	,
["Nazim",	254.3037	]	,
["Lisowicz",	254.30898	]	,
["Kotus",	254.31426	]	,
["Kolak",	254.31954	]	,
["Horodyski",	254.32482	]	,
["Grajcar",	254.3301	]	,
["Gas",	254.33538	]	,
["Dzitkowski",	254.34066	]	,
["Bernacik",	254.34594	]	,
["Bartków",	254.35122	]	,
["Wierdak",	254.35649	]	,
["Tomaka",	254.36176	]	,
["Szczuchniak",	254.36703	]	,
["Szczerbak",	254.3723	]	,
["Szczepka",	254.37757	]	,
["Ryk",	254.38284	]	,
["Pustula",	254.38811	]	,
["Poniewozik",	254.39338	]	,
["Pietrowicz",	254.39865	]	,
["Piegat",	254.40392	]	,
["Paszenda",	254.40919	]	,
["Orlowicz",	254.41446	]	,
["Odrobina",	254.41973	]	,
["Lekki",	254.425	]	,
["Kuszaj",	254.43027	]	,
["Kurpas",	254.43554	]	,
["Kloczkowski",	254.44081	]	,
["Jasnos",	254.44608	]	,
["Grabowiec",	254.45135	]	,
["Gortych",	254.45662	]	,
["Fokt",	254.46189	]	,
["Figa",	254.46716	]	,
["Fidelus",	254.47243	]	,
["Doering",	254.4777	]	,
["Czelusniak",	254.48297	]	,
["Bryniarski",	254.48824	]	,
["Baranik",	254.49351	]	,
["Bandosz",	254.49878	]	,
["Balcerski",	254.50405	]	,
["Winogrodzki",	254.50931	]	,
["Wilman",	254.51457	]	,
["Student",	254.51983	]	,
["Skórzynski",	254.52509	]	,
["Rychlak",	254.53035	]	,
["Przebinda",	254.53561	]	,
["Paleczny",	254.54087	]	,
["Motloch",	254.54613	]	,
["Madrawski",	254.55139	]	,
["Majdecki",	254.55665	]	,
["Kalandyk",	254.56191	]	,
["Kaczówka",	254.56717	]	,
["Janusiewicz",	254.57243	]	,
["Jachnik",	254.57769	]	,
["Holik",	254.58295	]	,
["Falk",	254.58821	]	,
["Ebert",	254.59347	]	,
["Cugier",	254.59873	]	,
["Agacinski",	254.60399	]	,
["Weclawek",	254.60924	]	,
["Tryniszewski",	254.61449	]	,
["Tatarski",	254.61974	]	,
["Skweres",	254.62499	]	,
["Salachna",	254.63024	]	,
["Pytlewski",	254.63549	]	,
["Pycia",	254.64074	]	,
["Pustulka",	254.64599	]	,
["Puka",	254.65124	]	,
["Papke",	254.65649	]	,
["Okonek",	254.66174	]	,
["Maternowski",	254.66699	]	,
["Latas",	254.67224	]	,
["Kurkowiak",	254.67749	]	,
["Klimkowicz",	254.68274	]	,
["Fialek",	254.68799	]	,
["Ciuraj",	254.69324	]	,
["Ciebien",	254.69849	]	,
["Radola",	254.70373	]	,
["Potomski",	254.70897	]	,
["Ornoch",	254.71421	]	,
["Okraszewski",	254.71945	]	,
["Murzynowski",	254.72469	]	,
["Mozolewski",	254.72993	]	,
["Mansfeld",	254.73517	]	,
["Kopczewski",	254.74041	]	,
["Karkosz",	254.74565	]	,
["Izydorek",	254.75089	]	,
["Guzenda",	254.75613	]	,
["Derengowski",	254.76137	]	,
["Broniewski",	254.76661	]	,
["Ból",	254.77185	]	,
["Birecki",	254.77709	]	,
["Zapotoczna",	254.78232	]	,
["Wrzodak",	254.78755	]	,
["Tatarczak",	254.79278	]	,
["Swiatlon",	254.79801	]	,
["Szturc",	254.80324	]	,
["Szczutkowski",	254.80847	]	,
["Skolmowski",	254.8137	]	,
["Ropelewski",	254.81893	]	,
["Pyrzanowski",	254.82416	]	,
["Piekorz",	254.82939	]	,
["Osiej",	254.83462	]	,
["Lacz",	254.83985	]	,
["Likus",	254.84508	]	,
["Kruzynski",	254.85031	]	,
["Koczan",	254.85554	]	,
["Kocyk",	254.86077	]	,
["Grzelecki",	254.866	]	,
["Gnutek",	254.87123	]	,
["Czapracki",	254.87646	]	,
["Barton",	254.88169	]	,
["Wilgocki",	254.88691	]	,
["Uchacz",	254.89213	]	,
["Tercjak",	254.89735	]	,
["Swiatecki",	254.90257	]	,
["Studniarek",	254.90779	]	,
["Senderowski",	254.91301	]	,
["Salamonczyk",	254.91823	]	,
["Rosikon",	254.92345	]	,
["Radojewski",	254.92867	]	,
["Myjak",	254.93389	]	,
["Mistarz",	254.93911	]	,
["Marcinczak",	254.94433	]	,
["Lutek",	254.94955	]	,
["Kunz",	254.95477	]	,
["Kundera",	254.95999	]	,
["Krogulski",	254.96521	]	,
["Kondziela",	254.97043	]	,
["Holubowski",	254.97565	]	,
["Hemmerling",	254.98087	]	,
["Gaczkowski",	254.98609	]	,
["Buch",	254.99131	]	,
["Beta",	254.99653	]	,
["Affek",	255.00175	]	,
["Wyspianski",	255.00696	]	,
["Wrabel",	255.01217	]	,
["Wojdyga",	255.01738	]	,
["Waszkowski",	255.02259	]	,
["Szachnowski",	255.0278	]	,
["Stanislawiak",	255.03301	]	,
["Skrzypa",	255.03822	]	,
["Rogosz",	255.04343	]	,
["Radziwon",	255.04864	]	,
["Pers",	255.05385	]	,
["Luzynski",	255.05906	]	,
["Loranc",	255.06427	]	,
["Konowrocki",	255.06948	]	,
["Glówczyk",	255.07469	]	,
["Gibek",	255.0799	]	,
["Dolski",	255.08511	]	,
["Dobiesz",	255.09032	]	,
["Dlugi",	255.09553	]	,
["Czeczko",	255.10074	]	,
["Burchacki",	255.10595	]	,
["Barna",	255.11116	]	,
["Winkel",	255.11636	]	,
["Wiechnik",	255.12156	]	,
["Widla",	255.12676	]	,
["Szczypa",	255.13196	]	,
["Struzyna",	255.13716	]	,
["Stecyk",	255.14236	]	,
["Ryniec",	255.14756	]	,
["Radoszewski",	255.15276	]	,
["Pawleta",	255.15796	]	,
["Okrzesik",	255.16316	]	,
["Miszczyszyn",	255.16836	]	,
["Mikiewicz",	255.17356	]	,
["Kusal",	255.17876	]	,
["Kurka",	255.18396	]	,
["Koszarek",	255.18916	]	,
["Kolski",	255.19436	]	,
["Kobeszko",	255.19956	]	,
["Kaplan",	255.20476	]	,
["Kapkowski",	255.20996	]	,
["Ignacy",	255.21516	]	,
["Hojan",	255.22036	]	,
["Gozlinski",	255.22556	]	,
["Golla",	255.23076	]	,
["Fiutowski",	255.23596	]	,
["Dzieciatkowski",	255.24116	]	,
["Demel",	255.24636	]	,
["Cielebak",	255.25156	]	,
["Brylak",	255.25676	]	,
["Balaban",	255.26196	]	,
["Wiosna",	255.26715	]	,
["Szwajca",	255.27234	]	,
["Suchowiecki",	255.27753	]	,
["Sobiegraj",	255.28272	]	,
["Samociuk",	255.28791	]	,
["Puzniak",	255.2931	]	,
["Palczynski",	255.29829	]	,
["Mlynarek",	255.30348	]	,
["Lubas",	255.30867	]	,
["Liber",	255.31386	]	,
["Lewczyk",	255.31905	]	,
["Kopydlowski",	255.32424	]	,
["Jobda",	255.32943	]	,
["Guza",	255.33462	]	,
["Dudys",	255.33981	]	,
["Dmitrzak",	255.345	]	,
["Blukacz",	255.35019	]	,
["Blak",	255.35538	]	,
["Aniolek",	255.36057	]	,
["Ziewiec",	255.36575	]	,
["Weinert",	255.37093	]	,
["Tukaj",	255.37611	]	,
["Swidurski",	255.38129	]	,
["Sleboda",	255.38647	]	,
["Szemplinski",	255.39165	]	,
["Serafinowicz",	255.39683	]	,
["Sabatowski",	255.40201	]	,
["Markowiak",	255.40719	]	,
["Korpak",	255.41237	]	,
["Hulewicz",	255.41755	]	,
["Hahn",	255.42273	]	,
["Grzywocz",	255.42791	]	,
["Gracki",	255.43309	]	,
["Dziadon",	255.43827	]	,
["Durmaj",	255.44345	]	,
["Busse",	255.44863	]	,
["Blochowiak",	255.45381	]	,
["Scibek",	255.45898	]	,
["Szpot",	255.46415	]	,
["Ring",	255.46932	]	,
["Rejdych",	255.47449	]	,
["Rajek",	255.47966	]	,
["Marchewa",	255.48483	]	,
["Kunat",	255.49	]	,
["Krzton",	255.49517	]	,
["Kondziolka",	255.50034	]	,
["Klodowski",	255.50551	]	,
["Dzialek",	255.51068	]	,
["Czerkas",	255.51585	]	,
["Brania",	255.52102	]	,
["Borcuch",	255.52619	]	,
["Biskupek",	255.53136	]	,
["Zeromski",	255.53652	]	,
["Wiecko",	255.54168	]	,
["Tosik",	255.54684	]	,
["Stelmaszewski",	255.552	]	,
["Rymarski",	255.55716	]	,
["Rozpara",	255.56232	]	,
["Pryciak",	255.56748	]	,
["Nikodemski",	255.57264	]	,
["Murdza",	255.5778	]	,
["Madrowski",	255.58296	]	,
["Lekawski",	255.58812	]	,
["Jusiak",	255.59328	]	,
["Jarnot",	255.59844	]	,
["Glowniak",	255.6036	]	,
["Geborys",	255.60876	]	,
["Florczuk",	255.61392	]	,
["Dancewicz",	255.61908	]	,
["Cimek",	255.62424	]	,
["Chyzynski",	255.6294	]	,
["Braciszewski",	255.63456	]	,
["Bezak",	255.63972	]	,
["Zygmanski",	255.64487	]	,
["Zalinski",	255.65002	]	,
["Wypijewski",	255.65517	]	,
["Wojciuk",	255.66032	]	,
["Wascinski",	255.66547	]	,
["Szczeszek",	255.67062	]	,
["Pilip",	255.67577	]	,
["Pardo",	255.68092	]	,
["Nalewajk",	255.68607	]	,
["Matyasik",	255.69122	]	,
["Kornobis",	255.69637	]	,
["Kopytowski",	255.70152	]	,
["Kochanczyk",	255.70667	]	,
["Kochaniec",	255.71182	]	,
["Kasicki",	255.71697	]	,
["Karaban",	255.72212	]	,
["Jekot",	255.72727	]	,
["Halman",	255.73242	]	,
["Firlit",	255.73757	]	,
["Brajer",	255.74272	]	,
["Wojtek",	255.74786	]	,
["Wiesner",	255.753	]	,
["Wichlinski",	255.75814	]	,
["Uszko",	255.76328	]	,
["Uchto",	255.76842	]	,
["Topka",	255.77356	]	,
["Strus",	255.7787	]	,
["Spryszynski",	255.78384	]	,
["Seifert",	255.78898	]	,
["Rydzik",	255.79412	]	,
["Purgal",	255.79926	]	,
["Protasiewicz",	255.8044	]	,
["Preis",	255.80954	]	,
["Prajs",	255.81468	]	,
["Kusmierczak",	255.81982	]	,
["Kocon",	255.82496	]	,
["Klik",	255.8301	]	,
["Jerczynski",	255.83524	]	,
["Grzebielucha",	255.84038	]	,
["Fracek",	255.84552	]	,
["Borcz",	255.85066	]	,
["Baradziej",	255.8558	]	,
["Anusiewicz",	255.86094	]	,
["Zarkowski",	255.86607	]	,
["Wloszek",	255.8712	]	,
["Terelak",	255.87633	]	,
["Tempski",	255.88146	]	,
["Sztyber",	255.88659	]	,
["Szmania",	255.89172	]	,
["Podkowinski",	255.89685	]	,
["Nidecki",	255.90198	]	,
["Musinski",	255.90711	]	,
["Motyczynski",	255.91224	]	,
["Morawa",	255.91737	]	,
["Mierzwicki",	255.9225	]	,
["Mekal",	255.92763	]	,
["Malczak",	255.93276	]	,
["Janko",	255.93789	]	,
["Hanak",	255.94302	]	,
["Gaciarz",	255.94815	]	,
["Dobranowski",	255.95328	]	,
["Znyk",	255.9584	]	,
["Wróz",	255.96352	]	,
["Witoszek",	255.96864	]	,
["Wielocha",	255.97376	]	,
["Werynski",	255.97888	]	,
["Walak",	255.984	]	,
["Turaj",	255.98912	]	,
["Srokowski",	255.99424	]	,
["Kosikowski",	255.99936	]	,
["Kisala",	256.00448	]	,
["Kazik",	256.0096	]	,
["Gniewkowski",	256.01472	]	,
["Gatner",	256.01984	]	,
["Felczynski",	256.02496	]	,
["Fabjanski",	256.03008	]	,
["Dlubala",	256.0352	]	,
["Ciemniewski",	256.04032	]	,
["Wilkolek",	256.04543	]	,
["Parysek",	256.05054	]	,
["Padzik",	256.05565	]	,
["Moczek",	256.06076	]	,
["Krzciuk",	256.06587	]	,
["Krupowicz",	256.07098	]	,
["Harazim",	256.07609	]	,
["Grabczynski",	256.0812	]	,
["Gorzawski",	256.08631	]	,
["Gajewicz",	256.09142	]	,
["Czosnek",	256.09653	]	,
["Ciapala",	256.10164	]	,
["Chomka",	256.10675	]	,
["Binder",	256.11186	]	,
["Barc",	256.11697	]	,
["Zylak",	256.12207	]	,
["Zulawnik",	256.12717	]	,
["Witaszczyk",	256.13227	]	,
["Wdowinski",	256.13737	]	,
["Slawecki",	256.14247	]	,
["Plawiak",	256.14757	]	,
["Plis",	256.15267	]	,
["Ordak",	256.15777	]	,
["Nizialek",	256.16287	]	,
["Losik",	256.16797	]	,
["Lewanczyk",	256.17307	]	,
["Kurzepa",	256.17817	]	,
["Kopicki",	256.18327	]	,
["Jaszkowski",	256.18837	]	,
["Iglinski",	256.19347	]	,
["Gradecki",	256.19857	]	,
["Gbiorczyk",	256.20367	]	,
["Ferdynus",	256.20877	]	,
["Dunst",	256.21387	]	,
["Chodorek",	256.21897	]	,
["Brauer",	256.22407	]	,
["Bohdan",	256.22917	]	,
["Wieczynski",	256.23426	]	,
["Trochowski",	256.23935	]	,
["Szudrowicz",	256.24444	]	,
["Stecko",	256.24953	]	,
["Smak",	256.25462	]	,
["Ruszala",	256.25971	]	,
["Radomyski",	256.2648	]	,
["Rachanski",	256.26989	]	,
["Plocica",	256.27498	]	,
["Piedel",	256.28007	]	,
["Mikina",	256.28516	]	,
["Lewkowski",	256.29025	]	,
["Jarco",	256.29534	]	,
["Holeczek",	256.30043	]	,
["Halaczkiewicz",	256.30552	]	,
["Dziarski",	256.31061	]	,
["Dyla",	256.3157	]	,
["Dyderski",	256.32079	]	,
["Draminski",	256.32588	]	,
["Dajnowski",	256.33097	]	,
["Bekisz",	256.33606	]	,
["Asztemborski",	256.34115	]	,
["Szyper",	256.34623	]	,
["Stalewski",	256.35131	]	,
["Skrzypski",	256.35639	]	,
["Sipa",	256.36147	]	,
["Predki",	256.36655	]	,
["Pek",	256.37163	]	,
["Moryn",	256.37671	]	,
["Mlodzikowski",	256.38179	]	,
["Lotocki",	256.38687	]	,
["Lukoszek",	256.39195	]	,
["Lamczyk",	256.39703	]	,
["Kuziak",	256.40211	]	,
["Jedraszak",	256.40719	]	,
["Gredzinski",	256.41227	]	,
["Gostomczyk",	256.41735	]	,
["Bis",	256.42243	]	,
["Wszelaki",	256.4275	]	,
["Walukiewicz",	256.43257	]	,
["Szczypczyk",	256.43764	]	,
["Szczukiewicz",	256.44271	]	,
["Smuda",	256.44778	]	,
["Siodlak",	256.45285	]	,
["Schroeder",	256.45792	]	,
["Sar",	256.46299	]	,
["Rydzkowski",	256.46806	]	,
["Pysiak",	256.47313	]	,
["Podstawa",	256.4782	]	,
["Piesiak",	256.48327	]	,
["Nikonowicz",	256.48834	]	,
["Niewiadomy",	256.49341	]	,
["Makselon",	256.49848	]	,
["Grzelakowski",	256.50355	]	,
["Gocyla",	256.50862	]	,
["Dzirba",	256.51369	]	,
["Dyoniziak",	256.51876	]	,
["Dukala",	256.52383	]	,
["Ciemny",	256.5289	]	,
["Cembala",	256.53397	]	,
["Berk",	256.53904	]	,
["Bendkowski",	256.54411	]	,
["Artemiuk",	256.54918	]	,
["Zamiara",	256.55424	]	,
["Weremko",	256.5593	]	,
["Szczykutowicz",	256.56436	]	,
["Stachniak",	256.56942	]	,
["Salski",	256.57448	]	,
["Ramza",	256.57954	]	,
["Powalowski",	256.5846	]	,
["Maga",	256.58966	]	,
["Krzywiec",	256.59472	]	,
["Krysiuk",	256.59978	]	,
["Kosatka",	256.60484	]	,
["Kaszak",	256.6099	]	,
["Hara",	256.61496	]	,
["Ciecielag",	256.62002	]	,
["Burkat",	256.62508	]	,
["Zaglewski",	256.63013	]	,
["Ziemkowski",	256.63518	]	,
["Wtorek",	256.64023	]	,
["Trochimowicz",	256.64528	]	,
["Staszczuk",	256.65033	]	,
["Sola",	256.65538	]	,
["Proksa",	256.66043	]	,
["Plutecki",	256.66548	]	,
["Operacz",	256.67053	]	,
["Nogala",	256.67558	]	,
["Miskowicz",	256.68063	]	,
["Mil",	256.68568	]	,
["Marucha",	256.69073	]	,
["Malmon",	256.69578	]	,
["Klepczarek",	256.70083	]	,
["Janus",	256.70588	]	,
["Grabia",	256.71093	]	,
["Bereta",	256.71598	]	,
["Zabawski",	256.72102	]	,
["Wawryk",	256.72606	]	,
["Walecki",	256.7311	]	,
["Walentowicz",	256.73614	]	,
["Twardoch",	256.74118	]	,
["Trzaskoma",	256.74622	]	,
["Szymaniec",	256.75126	]	,
["Szwej",	256.7563	]	,
["Szparaga",	256.76134	]	,
["Susek",	256.76638	]	,
["Stenzel",	256.77142	]	,
["Spaleniak",	256.77646	]	,
["Przeradzki",	256.7815	]	,
["Pajka",	256.78654	]	,
["Maksim",	256.79158	]	,
["Lugiewicz",	256.79662	]	,
["Kordos",	256.80166	]	,
["Kempisty",	256.8067	]	,
["Kapusciak",	256.81174	]	,
["Januszczak",	256.81678	]	,
["Guja",	256.82182	]	,
["Gozdziewicz",	256.82686	]	,
["Glowinkowski",	256.8319	]	,
["Filonowicz",	256.83694	]	,
["Chwesiuk",	256.84198	]	,
["Burghardt",	256.84702	]	,
["Brzuzy",	256.85206	]	,
["Brudek",	256.8571	]	,
["Boch",	256.86214	]	,
["Woszczek",	256.86717	]	,
["Weltrowski",	256.8722	]	,
["Wawruch",	256.87723	]	,
["Warnke",	256.88226	]	,
["Turbak",	256.88729	]	,
["Szymikowski",	256.89232	]	,
["Szczegielniak",	256.89735	]	,
["Sygnowski",	256.90238	]	,
["Stypinski",	256.90741	]	,
["Sadel",	256.91244	]	,
["Rzeplinski",	256.91747	]	,
["Rycombel",	256.9225	]	,
["Purgal",	256.92753	]	,
["Kern",	256.93256	]	,
["Kapsa",	256.93759	]	,
["Jedruszek",	256.94262	]	,
["Jastrzabek",	256.94765	]	,
["Halemba",	256.95268	]	,
["Didyk",	256.95771	]	,
["Bus",	256.96274	]	,
["Bruzdzinski",	256.96777	]	,
["Wlazlowski",	256.97279	]	,
["Wawryszuk",	256.97781	]	,
["Tanas",	256.98283	]	,
["Szczerbik",	256.98785	]	,
["Stoszek",	256.99287	]	,
["Staniecki",	256.99789	]	,
["Paulus",	257.00291	]	,
["Matysiewicz",	257.00793	]	,
["Marchut",	257.01295	]	,
["Kwiatosz",	257.01797	]	,
["Kaniuk",	257.02299	]	,
["Jarota",	257.02801	]	,
["Jamrozy",	257.03303	]	,
["Grebski",	257.03805	]	,
["Gocek",	257.04307	]	,
["Garbos",	257.04809	]	,
["Gaida",	257.05311	]	,
["Firlus",	257.05813	]	,
["Buta",	257.06315	]	,
["Bujacz",	257.06817	]	,
["Budyta",	257.07319	]	,
["Zielenkiewicz",	257.0782	]	,
["Wantoch-Rekowski",	257.08321	]	,
["Tazbir",	257.08822	]	,
["Szygenda",	257.09323	]	,
["Stania",	257.09824	]	,
["Pomietlo",	257.10325	]	,
["Podlasek",	257.10826	]	,
["Maziarka",	257.11327	]	,
["Mamet",	257.11828	]	,
["Kulaszewski",	257.12329	]	,
["Kuczko",	257.1283	]	,
["Kluzek",	257.13331	]	,
["Klapa",	257.13832	]	,
["Groszyk",	257.14333	]	,
["Dziedziak",	257.14834	]	,
["Czeszejko",	257.15335	]	,
["Ciecka",	257.15836	]	,
["Blachowiak",	257.16337	]	,
["Bartoszynski",	257.16838	]	,
["Vogt",	257.17338	]	,
["Szlazak",	257.17838	]	,
["Szeluga",	257.18338	]	,
["Sapieja",	257.18838	]	,
["Riedel",	257.19338	]	,
["Reczko",	257.19838	]	,
["Procyk",	257.20338	]	,
["Poslednik",	257.20838	]	,
["Luc",	257.21338	]	,
["Kubit",	257.21838	]	,
["Kopek",	257.22338	]	,
["Kadziola",	257.22838	]	,
["Kaliszczak",	257.23338	]	,
["Grochowicz",	257.23838	]	,
["Gedlek",	257.24338	]	,
["Dziembowski",	257.24838	]	,
["Dragun",	257.25338	]	,
["Brzychcy",	257.25838	]	,
["Bienek",	257.26338	]	,
["Zemlik",	257.26837	]	,
["Wyczynski",	257.27336	]	,
["Wiesek",	257.27835	]	,
["Wasiela",	257.28334	]	,
["Trabinski",	257.28833	]	,
["Smorawski",	257.29332	]	,
["Siekacz",	257.29831	]	,
["Ringwelski",	257.3033	]	,
["Radajewski",	257.30829	]	,
["Plocharski",	257.31328	]	,
["Pipka",	257.31827	]	,
["Peter",	257.32326	]	,
["Lukaszczuk",	257.32825	]	,
["Kurpik",	257.33324	]	,
["Jeziorna",	257.33823	]	,
["Hudziak",	257.34322	]	,
["Grzejdziak",	257.34821	]	,
["Gosztyla",	257.3532	]	,
["Gembara",	257.35819	]	,
["Galeziowski",	257.36318	]	,
["Dziobek",	257.36817	]	,
["Cudny",	257.37316	]	,
["Buszman",	257.37815	]	,
["Bozym",	257.38314	]	,
["Bogunia",	257.38813	]	,
["Bobowicz",	257.39312	]	,
["Uscinowicz",	257.3981	]	,
["Trybek",	257.40308	]	,
["Szwaba",	257.40806	]	,
["Szponder",	257.41304	]	,
["Pudo",	257.41802	]	,
["Przymuszala",	257.423	]	,
["Nowatkowski",	257.42798	]	,
["Noskowicz",	257.43296	]	,
["Mrowca",	257.43794	]	,
["Kurzacz",	257.44292	]	,
["Kurasiewicz",	257.4479	]	,
["Kocimski",	257.45288	]	,
["Kloszewski",	257.45786	]	,
["Klag",	257.46284	]	,
["Jasiecki",	257.46782	]	,
["Horzela",	257.4728	]	,
["Heród",	257.47778	]	,
["Gerc",	257.48276	]	,
["Dluzak",	257.48774	]	,
["Derbich",	257.49272	]	,
["Cymbalista",	257.4977	]	,
["Chromiec",	257.50268	]	,
["Chlasta",	257.50766	]	,
["Bodak",	257.51264	]	,
["Zeglen",	257.51761	]	,
["Telka",	257.52258	]	,
["Skobel",	257.52755	]	,
["Resiak",	257.53252	]	,
["Polis",	257.53749	]	,
["Podrazka",	257.54246	]	,
["Pitera",	257.54743	]	,
["Ostalowski",	257.5524	]	,
["Narowski",	257.55737	]	,
["Migdalek",	257.56234	]	,
["Lacek",	257.56731	]	,
["Liwski",	257.57228	]	,
["Lewtak",	257.57725	]	,
["Krzyzynski",	257.58222	]	,
["Kruczyk",	257.58719	]	,
["Kowalcze",	257.59216	]	,
["Korfel",	257.59713	]	,
["Knut",	257.6021	]	,
["Grzadka",	257.60707	]	,
["Grochot",	257.61204	]	,
["Goldman",	257.61701	]	,
["Gnida",	257.62198	]	,
["Gibowski",	257.62695	]	,
["Fojut",	257.63192	]	,
["Dymel",	257.63689	]	,
["Durkiewicz",	257.64186	]	,
["Dobrakowski",	257.64683	]	,
["Dasko",	257.6518	]	,
["Czerwien",	257.65677	]	,
["Bochinski",	257.66174	]	,
["Wikiera",	257.6667	]	,
["Rusnarczyk",	257.67166	]	,
["Rubik",	257.67662	]	,
["Puton",	257.68158	]	,
["Pudzianowski",	257.68654	]	,
["Pacha",	257.6915	]	,
["Niwa",	257.69646	]	,
["Miga",	257.70142	]	,
["Leniart",	257.70638	]	,
["Krasny",	257.71134	]	,
["Kowacz",	257.7163	]	,
["Kostur",	257.72126	]	,
["Hyra",	257.72622	]	,
["Górnowicz",	257.73118	]	,
["Czuchra",	257.73614	]	,
["Cislak",	257.7411	]	,
["Buba",	257.74606	]	,
["Anklewicz",	257.75102	]	,
["Adwent",	257.75598	]	,
["Weglicki",	257.76093	]	,
["Swiety",	257.76588	]	,
["Swiacki",	257.77083	]	,
["Szwec",	257.77578	]	,
["Scholz",	257.78073	]	,
["Rachel",	257.78568	]	,
["Pipczynski",	257.79063	]	,
["Pijewski",	257.79558	]	,
["Kuchnowski",	257.80053	]	,
["Kóska",	257.80548	]	,
["Kozaczka",	257.81043	]	,
["Falenta",	257.81538	]	,
["Dacewicz",	257.82033	]	,
["Cwiok",	257.82528	]	,
["Bytnar",	257.83023	]	,
["Bigda",	257.83518	]	,
["Zglinicki",	257.84012	]	,
["Zalesny",	257.84506	]	,
["Urgacz",	257.85	]	,
["Twarda",	257.85494	]	,
["Teklinski",	257.85988	]	,
["Tarasinski",	257.86482	]	,
["Sniezko",	257.86976	]	,
["Strzezek",	257.8747	]	,
["Stepowski",	257.87964	]	,
["Stachula",	257.88458	]	,
["Slabik",	257.88952	]	,
["Rudziewicz",	257.89446	]	,
["Rajewicz",	257.8994	]	,
["Podgajna",	257.90434	]	,
["Palonka",	257.90928	]	,
["Okarmus",	257.91422	]	,
["Nikoniuk",	257.91916	]	,
["Niemczycki",	257.9241	]	,
["Mscichowski",	257.92904	]	,
["Malejka",	257.93398	]	,
["Lopatowski",	257.93892	]	,
["Lagan",	257.94386	]	,
["Kyrcz",	257.9488	]	,
["Krzyzosiak",	257.95374	]	,
["Koter",	257.95868	]	,
["Korzycki",	257.96362	]	,
["Kisiala",	257.96856	]	,
["Jaguszewski",	257.9735	]	,
["Gralec",	257.97844	]	,
["Goleniewski",	257.98338	]	,
["Gerlich",	257.98832	]	,
["Farys",	257.99326	]	,
["Dziel",	257.9982	]	,
["Czoch",	258.00314	]	,
["Czerminski",	258.00808	]	,
["Bojanowicz",	258.01302	]	,
["Bianga",	258.01796	]	,
["Zaboklicki",	258.02289	]	,
["Wólczynski",	258.02782	]	,
["Warchalowski",	258.03275	]	,
["Utnik",	258.03768	]	,
["Szychta",	258.04261	]	,
["Szastak",	258.04754	]	,
["Sawczak",	258.05247	]	,
["Sarzala",	258.0574	]	,
["Pretka",	258.06233	]	,
["Ponikiewski",	258.06726	]	,
["Pepek",	258.07219	]	,
["Laufer",	258.07712	]	,
["Korgól",	258.08205	]	,
["Kajkowski",	258.08698	]	,
["Jurczyszyn",	258.09191	]	,
["Gierula",	258.09684	]	,
["Garsztka",	258.10177	]	,
["Dybcio",	258.1067	]	,
["Czlapa",	258.11163	]	,
["Cichewicz",	258.11656	]	,
["Burakiewicz",	258.12149	]	,
["Borszcz",	258.12642	]	,
["Boral",	258.13135	]	,
["Bebel",	258.13628	]	,
["Balcerowicz",	258.14121	]	,
["Zarnecki",	258.14613	]	,
["Zegarek",	258.15105	]	,
["Wódz",	258.15597	]	,
["Taberski",	258.16089	]	,
["Smierzchalski",	258.16581	]	,
["Szczesny",	258.17073	]	,
["Sut",	258.17565	]	,
["Stankowiak",	258.18057	]	,
["Sprengel",	258.18549	]	,
["Skopek",	258.19041	]	,
["Skonecki",	258.19533	]	,
["Sedzicki",	258.20025	]	,
["Sasal",	258.20517	]	,
["Potera",	258.21009	]	,
["Podkówka",	258.21501	]	,
["Piglowski",	258.21993	]	,
["Pedzisz",	258.22485	]	,
["Pecherzewski",	258.22977	]	,
["Olchowik",	258.23469	]	,
["Niemier",	258.23961	]	,
["Namyslowski",	258.24453	]	,
["Najgebauer",	258.24945	]	,
["Macewicz",	258.25437	]	,
["Krzyzan",	258.25929	]	,
["Krzaczek",	258.26421	]	,
["Kotlarczyk",	258.26913	]	,
["Kostyk",	258.27405	]	,
["Koprek",	258.27897	]	,
["Kilichowski",	258.28389	]	,
["Kaski",	258.28881	]	,
["Gwizd",	258.29373	]	,
["Bys",	258.29865	]	,
["Brochocki",	258.30357	]	,
["Zaczkiewicz",	258.30848	]	,
["Wykrota",	258.31339	]	,
["Wrotniak",	258.3183	]	,
["Rudak",	258.32321	]	,
["Rajzer",	258.32812	]	,
["Puchowski",	258.33303	]	,
["Proczek",	258.33794	]	,
["Poterek",	258.34285	]	,
["Miczek",	258.34776	]	,
["Miciak",	258.35267	]	,
["Lichosik",	258.35758	]	,
["Kwapich",	258.36249	]	,
["Kubisztal",	258.3674	]	,
["Krzywkowski",	258.37231	]	,
["Kolbuc",	258.37722	]	,
["Klys",	258.38213	]	,
["Klak",	258.38704	]	,
["Karwanski",	258.39195	]	,
["Jurgiel",	258.39686	]	,
["Galwas",	258.40177	]	,
["Bugajny",	258.40668	]	,
["Borodziuk",	258.41159	]	,
["Werra",	258.41649	]	,
["Sijka",	258.42139	]	,
["Sierzega",	258.42629	]	,
["Serowik",	258.43119	]	,
["Sarwa",	258.43609	]	,
["Rycaj",	258.44099	]	,
["Pacuszka",	258.44589	]	,
["Olenderek",	258.45079	]	,
["Mielecki",	258.45569	]	,
["Kumala",	258.46059	]	,
["Kucmierz",	258.46549	]	,
["Krzystyniak",	258.47039	]	,
["Korga",	258.47529	]	,
["Knas",	258.48019	]	,
["Kardynal",	258.48509	]	,
["Jawien",	258.48999	]	,
["Ignaczewski",	258.49489	]	,
["Gaczol",	258.49979	]	,
["Charytoniuk",	258.50469	]	,
["Arasimowicz",	258.50959	]	,
["Zelent",	258.51448	]	,
["Zagala",	258.51937	]	,
["Trebski",	258.52426	]	,
["Torun",	258.52915	]	,
["Szaflik",	258.53404	]	,
["Smoluch",	258.53893	]	,
["Siudy",	258.54382	]	,
["Rzadkiewicz",	258.54871	]	,
["Purtak",	258.5536	]	,
["Osetek",	258.55849	]	,
["Nowalski",	258.56338	]	,
["Mucharski",	258.56827	]	,
["Mroziewicz",	258.57316	]	,
["Mietla",	258.57805	]	,
["Lodzinski",	258.58294	]	,
["Lerch",	258.58783	]	,
["Ladzinski",	258.59272	]	,
["Kural",	258.59761	]	,
["Koenig",	258.6025	]	,
["Kantyka",	258.60739	]	,
["Jonderko",	258.61228	]	,
["Jerzykowski",	258.61717	]	,
["Jelenski",	258.62206	]	,
["Jasiukiewicz",	258.62695	]	,
["Hiszpanski",	258.63184	]	,
["Grzmil",	258.63673	]	,
["Gawinek",	258.64162	]	,
["Gardzielewski",	258.64651	]	,
["Fajer",	258.6514	]	,
["Dziemian",	258.65629	]	,
["Domaszewicz",	258.66118	]	,
["Czyzowicz",	258.66607	]	,
["Albert",	258.67096	]	,
["Zawodny",	258.67584	]	,
["Wolowik",	258.68072	]	,
["Wlodarkiewicz",	258.6856	]	,
["Urbas",	258.69048	]	,
["Przespolewski",	258.69536	]	,
["Pietrzkiewicz",	258.70024	]	,
["Pernal",	258.70512	]	,
["Onoszko",	258.71	]	,
["Miloch",	258.71488	]	,
["Majowski",	258.71976	]	,
["Huzar",	258.72464	]	,
["Grebosz",	258.72952	]	,
["Goryczka",	258.7344	]	,
["Gaszewski",	258.73928	]	,
["Falowski",	258.74416	]	,
["Dowgiallo",	258.74904	]	,
["Ciejka",	258.75392	]	,
["Broniarczyk",	258.7588	]	,
["Bensz",	258.76368	]	,
["Zeranski",	258.76855	]	,
["Waluga",	258.77342	]	,
["Slazyk",	258.77829	]	,
["Stachlewski",	258.78316	]	,
["Smentek",	258.78803	]	,
["Skretowski",	258.7929	]	,
["Seget",	258.79777	]	,
["Rytelewski",	258.80264	]	,
["Pulit",	258.80751	]	,
["Lazarewicz",	258.81238	]	,
["Krysiewicz",	258.81725	]	,
["Krupnik",	258.82212	]	,
["Kozdeba",	258.82699	]	,
["Korzeb",	258.83186	]	,
["Kiwak",	258.83673	]	,
["Kafka",	258.8416	]	,
["Jaszczolt",	258.84647	]	,
["Jarmul",	258.85134	]	,
["Janaszkiewicz",	258.85621	]	,
["Erdman",	258.86108	]	,
["Dziarnowski",	258.86595	]	,
["Dybkowski",	258.87082	]	,
["Dlugopolski",	258.87569	]	,
["Ziora",	258.88055	]	,
["Wietecki",	258.88541	]	,
["Waszczak",	258.89027	]	,
["Walenski",	258.89513	]	,
["Polczyk",	258.89999	]	,
["Podlejski",	258.90485	]	,
["Ostrowicki",	258.90971	]	,
["Noworolnik",	258.91457	]	,
["Mantaj",	258.91943	]	,
["Majdzinski",	258.92429	]	,
["Lesiecki",	258.92915	]	,
["Kucharewicz",	258.93401	]	,
["Kalisiewicz",	258.93887	]	,
["Kadej",	258.94373	]	,
["Gorzala",	258.94859	]	,
["Gicala",	258.95345	]	,
["Gaszynski",	258.95831	]	,
["Gac",	258.96317	]	,
["Denys",	258.96803	]	,
["Czardybon",	258.97289	]	,
["Bondar",	258.97775	]	,
["Sochan",	258.9826	]	,
["Sobucki",	258.98745	]	,
["Snela",	258.9923	]	,
["Semrau",	258.99715	]	,
["Sadek",	259.002	]	,
["Razik",	259.00685	]	,
["Miaskowski",	259.0117	]	,
["Lubocki",	259.01655	]	,
["Krzewina",	259.0214	]	,
["Kroczynski",	259.02625	]	,
["Koniuszy",	259.0311	]	,
["Knopek",	259.03595	]	,
["Herkt",	259.0408	]	,
["Gwarda",	259.04565	]	,
["Graba",	259.0505	]	,
["Goryszewski",	259.05535	]	,
["Gebal",	259.0602	]	,
["Fraj",	259.06505	]	,
["Dziubaltowski",	259.0699	]	,
["Dykta",	259.07475	]	,
["Dobrodziej",	259.0796	]	,
["Czakon",	259.08445	]	,
["Alama",	259.0893	]	,
["Wlaz",	259.09414	]	,
["Witula",	259.09898	]	,
["Wawoczny",	259.10382	]	,
["Slipko",	259.10866	]	,
["Sacewicz",	259.1135	]	,
["Papciak",	259.11834	]	,
["Nader",	259.12318	]	,
["Leonczuk",	259.12802	]	,
["Klatt",	259.13286	]	,
["Holody",	259.1377	]	,
["Grzegórski",	259.14254	]	,
["Grabczewski",	259.14738	]	,
["Golecki",	259.15222	]	,
["Garbien",	259.15706	]	,
["Draga",	259.1619	]	,
["Dobras",	259.16674	]	,
["Wojtczyk",	259.17157	]	,
["Wojcinski",	259.1764	]	,
["Wenc",	259.18123	]	,
["Teresinski",	259.18606	]	,
["Sucharzewski",	259.19089	]	,
["Stodolny",	259.19572	]	,
["Stempniewicz",	259.20055	]	,
["Stando",	259.20538	]	,
["Skrzypacz",	259.21021	]	,
["Rychta",	259.21504	]	,
["Rybaczuk",	259.21987	]	,
["Robakiewicz",	259.2247	]	,
["Poremba",	259.22953	]	,
["Pomaski",	259.23436	]	,
["Piskozub",	259.23919	]	,
["Pellowski",	259.24402	]	,
["Morzynski",	259.24885	]	,
["Modrak",	259.25368	]	,
["Mientus",	259.25851	]	,
["Merchut",	259.26334	]	,
["Machula",	259.26817	]	,
["Machel",	259.273	]	,
["Latacz",	259.27783	]	,
["Kwiotek",	259.28266	]	,
["Konewka",	259.28749	]	,
["Kolodziejczuk",	259.29232	]	,
["Kobierowski",	259.29715	]	,
["Klar",	259.30198	]	,
["Irzykowski",	259.30681	]	,
["Grams",	259.31164	]	,
["Czysz",	259.31647	]	,
["Chamerski",	259.3213	]	,
["Cabanski",	259.32613	]	,
["Bul",	259.33096	]	,
["Belina",	259.33579	]	,
["Bachorz",	259.34062	]	,
["Zmorzynski",	259.34544	]	,
["Wolodko",	259.35026	]	,
["Wegierek",	259.35508	]	,
["Warpas",	259.3599	]	,
["Synowski",	259.36472	]	,
["Slaboszewski",	259.36954	]	,
["Skrzydlo",	259.37436	]	,
["Skibowski",	259.37918	]	,
["Rzetelski",	259.384	]	,
["Pucyk",	259.38882	]	,
["Procner",	259.39364	]	,
["Pretki",	259.39846	]	,
["Patrzykat",	259.40328	]	,
["Klaput",	259.4081	]	,
["Kazaniecki",	259.41292	]	,
["Kalaski",	259.41774	]	,
["Feder",	259.42256	]	,
["Dzióbek",	259.42738	]	,
["Debecki",	259.4322	]	,
["Detko",	259.43702	]	,
["Car",	259.44184	]	,
["Berdychowski",	259.44666	]	,
["Baryga",	259.45148	]	,
["Zelek",	259.45629	]	,
["Wleklinski",	259.4611	]	,
["Warowny",	259.46591	]	,
["Szarafinski",	259.47072	]	,
["Suwaj",	259.47553	]	,
["Stysiak",	259.48034	]	,
["Pregowski",	259.48515	]	,
["Okrasinski",	259.48996	]	,
["Lagódka",	259.49477	]	,
["Kwarta",	259.49958	]	,
["Kreja",	259.50439	]	,
["Kochalski",	259.5092	]	,
["Klopotek",	259.51401	]	,
["Kleniewski",	259.51882	]	,
["Kielbowski",	259.52363	]	,
["Kalamarz",	259.52844	]	,
["Grycuk",	259.53325	]	,
["Gajdowski",	259.53806	]	,
["Czempiel",	259.54287	]	,
["Chylek",	259.54768	]	,
["Charubin",	259.55249	]	,
["Agaciak",	259.5573	]	,
["Zep",	259.5621	]	,
["Tomsia",	259.5669	]	,
["Swierzy",	259.5717	]	,
["Placha",	259.5765	]	,
["Petelski",	259.5813	]	,
["Olinski",	259.5861	]	,
["Niesiolowski",	259.5909	]	,
["Niedzielak",	259.5957	]	,
["Moryl",	259.6005	]	,
["Macudzinski",	259.6053	]	,
["Kwasniewicz",	259.6101	]	,
["Krysciak",	259.6149	]	,
["Kolus",	259.6197	]	,
["Kober",	259.6245	]	,
["Jurasik",	259.6293	]	,
["Gminski",	259.6341	]	,
["Galkiewicz",	259.6389	]	,
["Dzialach",	259.6437	]	,
["Dulko",	259.6485	]	,
["Dadacz",	259.6533	]	,
["Boloz",	259.6581	]	,
["Bodys",	259.6629	]	,
["Bleszynski",	259.6677	]	,
["Blazej",	259.6725	]	,
["Zyczkowski",	259.67729	]	,
["Wójkowski",	259.68208	]	,
["Ubych",	259.68687	]	,
["Tulinski",	259.69166	]	,
["Trzebuniak",	259.69645	]	,
["Taracha",	259.70124	]	,
["Sulejewski",	259.70603	]	,
["Sobocki",	259.71082	]	,
["Radzyminski",	259.71561	]	,
["Pora",	259.7204	]	,
["Pieniek",	259.72519	]	,
["Owsianko",	259.72998	]	,
["Molicki",	259.73477	]	,
["Mikulak",	259.73956	]	,
["Makos",	259.74435	]	,
["Lizakowski",	259.74914	]	,
["Lebek",	259.75393	]	,
["Lasik",	259.75872	]	,
["Korepta",	259.76351	]	,
["Kimak",	259.7683	]	,
["Kajewski",	259.77309	]	,
["Grudkowski",	259.77788	]	,
["Gniado",	259.78267	]	,
["Fastyn",	259.78746	]	,
["Fabich",	259.79225	]	,
["Etmanski",	259.79704	]	,
["Duszenko",	259.80183	]	,
["Czyzo",	259.80662	]	,
["Czepinski",	259.81141	]	,
["Bronakowski",	259.8162	]	,
["Bobula",	259.82099	]	,
["Badziak",	259.82578	]	,
["Woskowiak",	259.83056	]	,
["Weis",	259.83534	]	,
["Trzybinski",	259.84012	]	,
["Tomczewski",	259.8449	]	,
["Taborek",	259.84968	]	,
["Switkowski",	259.85446	]	,
["Sucha",	259.85924	]	,
["Strzabala",	259.86402	]	,
["Ryl",	259.8688	]	,
["Rybaczek",	259.87358	]	,
["Put",	259.87836	]	,
["Pisera",	259.88314	]	,
["Owcarz",	259.88792	]	,
["Mus",	259.8927	]	,
["Miczka",	259.89748	]	,
["Mamrot",	259.90226	]	,
["Makarowski",	259.90704	]	,
["Magdziarek",	259.91182	]	,
["Kwartnik",	259.9166	]	,
["Kuzio",	259.92138	]	,
["Kuhn",	259.92616	]	,
["Kryskiewicz",	259.93094	]	,
["Jagosz",	259.93572	]	,
["Glina",	259.9405	]	,
["Galus",	259.94528	]	,
["Gaffke",	259.95006	]	,
["Dobczynski",	259.95484	]	,
["Cepa",	259.95962	]	,
["Burkacki",	259.9644	]	,
["Asman",	259.96918	]	,
["Wirski",	259.97395	]	,
["Trusz",	259.97872	]	,
["Tomera",	259.98349	]	,
["Szpura",	259.98826	]	,
["Szaraniec",	259.99303	]	,
["Stepnicki",	259.9978	]	,
["Soltysinski",	260.00257	]	,
["Soboczynski",	260.00734	]	,
["Rzemek",	260.01211	]	,
["Rupniewski",	260.01688	]	,
["Pyclik",	260.02165	]	,
["Polatynski",	260.02642	]	,
["Petruk",	260.03119	]	,
["Peryt",	260.03596	]	,
["Panic",	260.04073	]	,
["Padiasek",	260.0455	]	,
["Nozynski",	260.05027	]	,
["Krzyszczuk",	260.05504	]	,
["Klamecki",	260.05981	]	,
["Józwiakowski",	260.06458	]	,
["Jarzabkowski",	260.06935	]	,
["Holyst",	260.07412	]	,
["Gruszkiewicz",	260.07889	]	,
["Ciara",	260.08366	]	,
["Zmudzinski",	260.08842	]	,
["Zieniuk",	260.09318	]	,
["Wódkowski",	260.09794	]	,
["Tylski",	260.1027	]	,
["Szpiech",	260.10746	]	,
["Szarata",	260.11222	]	,
["Staporek",	260.11698	]	,
["Rusinowicz",	260.12174	]	,
["Rupik",	260.1265	]	,
["Plech",	260.13126	]	,
["Parka",	260.13602	]	,
["Ogieglo",	260.14078	]	,
["Narwojsz",	260.14554	]	,
["Morgas",	260.1503	]	,
["Machocki",	260.15506	]	,
["Lakomiak",	260.15982	]	,
["Ludwinski",	260.16458	]	,
["Litwicki",	260.16934	]	,
["Lachman",	260.1741	]	,
["Kijo",	260.17886	]	,
["Karpierz",	260.18362	]	,
["Janczuk",	260.18838	]	,
["Hanusek",	260.19314	]	,
["Gwadera",	260.1979	]	,
["Grzegolec",	260.20266	]	,
["Gogolin",	260.20742	]	,
["Gilowski",	260.21218	]	,
["Durasiewicz",	260.21694	]	,
["Budynek",	260.2217	]	,
["Bieniaszewski",	260.22646	]	,
["Bida",	260.23122	]	,
["Beltowski",	260.23598	]	,
["Bartuzi",	260.24074	]	,
["Wiatrzyk",	260.24549	]	,
["Wadecki",	260.25024	]	,
["Ulfig",	260.25499	]	,
["Tylenda",	260.25974	]	,
["Taraski",	260.26449	]	,
["Siwon",	260.26924	]	,
["Sinkowski",	260.27399	]	,
["Prasal",	260.27874	]	,
["Pazur",	260.28349	]	,
["Obremski",	260.28824	]	,
["Niecikowski",	260.29299	]	,
["Napieracz",	260.29774	]	,
["Matyjek",	260.30249	]	,
["Malcherczyk",	260.30724	]	,
["Liszkiewicz",	260.31199	]	,
["Kozdras",	260.31674	]	,
["Korszen",	260.32149	]	,
["Kieres",	260.32624	]	,
["Kasprowiak",	260.33099	]	,
["Jasieniecki",	260.33574	]	,
["Hul",	260.34049	]	,
["Garlej",	260.34524	]	,
["Dolot",	260.34999	]	,
["Bursa",	260.35474	]	,
["Barylka",	260.35949	]	,
["Zyczynski",	260.36423	]	,
["Tandecki",	260.36897	]	,
["Szofer",	260.37371	]	,
["Smolski",	260.37845	]	,
["Skraba",	260.38319	]	,
["Rembecki",	260.38793	]	,
["Ptach",	260.39267	]	,
["Pisarkiewicz",	260.39741	]	,
["Pankau",	260.40215	]	,
["Nogacki",	260.40689	]	,
["Musielski",	260.41163	]	,
["Matyszkiewicz",	260.41637	]	,
["Makowiec",	260.42111	]	,
["Litke",	260.42585	]	,
["Kizewski",	260.43059	]	,
["Jawoszek",	260.43533	]	,
["Grzegorzewicz",	260.44007	]	,
["Gers",	260.44481	]	,
["Dziemidowicz",	260.44955	]	,
["Czyrnek",	260.45429	]	,
["Czerwieniec",	260.45903	]	,
["Bieszk",	260.46377	]	,
["Basiura",	260.46851	]	,
["Wylot",	260.47324	]	,
["Wodniok",	260.47797	]	,
["Turzanski",	260.4827	]	,
["Romaniszyn",	260.48743	]	,
["Radochonski",	260.49216	]	,
["Pruszak",	260.49689	]	,
["Powierza",	260.50162	]	,
["Onyszko",	260.50635	]	,
["Niebieszczanski",	260.51108	]	,
["Matloka",	260.51581	]	,
["Macias",	260.52054	]	,
["Luciuk",	260.52527	]	,
["Lasko",	260.53	]	,
["Koszuta",	260.53473	]	,
["Kedzierawski",	260.53946	]	,
["Hordejuk",	260.54419	]	,
["Gedek",	260.54892	]	,
["Gagorowski",	260.55365	]	,
["Furtek",	260.55838	]	,
["Frelik",	260.56311	]	,
["Czekierda",	260.56784	]	,
["Bryg",	260.57257	]	,
["Anielski",	260.5773	]	,
["Zaród",	260.58202	]	,
["Wylezek",	260.58674	]	,
["Wojdan",	260.59146	]	,
["Wadrzyk",	260.59618	]	,
["Tafelski",	260.6009	]	,
["Szwagrzyk",	260.60562	]	,
["Stanulewicz",	260.61034	]	,
["Sedzik",	260.61506	]	,
["Puszka",	260.61978	]	,
["Mydlo",	260.6245	]	,
["Matusek",	260.62922	]	,
["Lysy",	260.63394	]	,
["Lempa",	260.63866	]	,
["Lembicz",	260.64338	]	,
["Kusztelak",	260.6481	]	,
["Klementowski",	260.65282	]	,
["Kardys",	260.65754	]	,
["Jasion",	260.66226	]	,
["Jakubiszyn",	260.66698	]	,
["Ilków",	260.6717	]	,
["Ilasz",	260.67642	]	,
["Hiller",	260.68114	]	,
["Czaplejewicz",	260.68586	]	,
["Bednarkiewicz",	260.69058	]	,
["Bajan",	260.6953	]	,
["Andrejuk",	260.70002	]	,
["Tarapata",	260.70473	]	,
["Slemp",	260.70944	]	,
["Sperka",	260.71415	]	,
["Pochmara",	260.71886	]	,
["Mazanowski",	260.72357	]	,
["Maciej",	260.72828	]	,
["Lubos",	260.73299	]	,
["Lipiecki",	260.7377	]	,
["Lipecki",	260.74241	]	,
["Koson",	260.74712	]	,
["Jendrysik",	260.75183	]	,
["Jedut",	260.75654	]	,
["Jader",	260.76125	]	,
["Dadela",	260.76596	]	,
["Cudnik",	260.77067	]	,
["Buchacz",	260.77538	]	,
["Barzyk",	260.78009	]	,
["Baranczyk",	260.7848	]	,
["Wozniak",	260.7895	]	,
["Warakomski",	260.7942	]	,
["Walilko",	260.7989	]	,
["Walczyna",	260.8036	]	,
["Tumilowicz",	260.8083	]	,
["Szczesnowicz",	260.813	]	,
["Stopczyk",	260.8177	]	,
["Sowada",	260.8224	]	,
["Sander",	260.8271	]	,
["Ostrycharz",	260.8318	]	,
["Oleszczyk",	260.8365	]	,
["Miedziak",	260.8412	]	,
["Mercik",	260.8459	]	,
["Krzysik",	260.8506	]	,
["Krzaczkowski",	260.8553	]	,
["Kampka",	260.86	]	,
["Kaliszan",	260.8647	]	,
["Jachimek",	260.8694	]	,
["Huc",	260.8741	]	,
["Hertel",	260.8788	]	,
["Grubecki",	260.8835	]	,
["Goraca",	260.8882	]	,
["Gajo",	260.8929	]	,
["Dziurkowski",	260.8976	]	,
["Dziadak",	260.9023	]	,
["Czypionka",	260.907	]	,
["Cempel",	260.9117	]	,
["Burkowski",	260.9164	]	,
["Brysiak",	260.9211	]	,
["Bosacki",	260.9258	]	,
["Biadacz",	260.9305	]	,
["Wyzga",	260.93519	]	,
["Wyrozumski",	260.93988	]	,
["Wudarski",	260.94457	]	,
["Weglorz",	260.94926	]	,
["Walesiak",	260.95395	]	,
["Tymicki",	260.95864	]	,
["Szostakowski",	260.96333	]	,
["Syczewski",	260.96802	]	,
["Przyborek",	260.97271	]	,
["Parcheta",	260.9774	]	,
["Opala",	260.98209	]	,
["Olejnicki",	260.98678	]	,
["Olbrycht",	260.99147	]	,
["Miernicki",	260.99616	]	,
["Katolik",	261.00085	]	,
["Harezlak",	261.00554	]	,
["Haase",	261.01023	]	,
["Gwardys",	261.01492	]	,
["Grzesica",	261.01961	]	,
["Goszcz",	261.0243	]	,
["Gabryelski",	261.02899	]	,
["Fujak",	261.03368	]	,
["Dulat",	261.03837	]	,
["Bujnowicz",	261.04306	]	,
["Bekas",	261.04775	]	,
["Andrusiewicz",	261.05244	]	,
["Widelski",	261.05712	]	,
["Snioch",	261.0618	]	,
["Szmid",	261.06648	]	,
["Szeszula",	261.07116	]	,
["Sybilski",	261.07584	]	,
["Slodkiewicz",	261.08052	]	,
["Sieklicki",	261.0852	]	,
["Sektas",	261.08988	]	,
["Rzeszotek",	261.09456	]	,
["Pytlos",	261.09924	]	,
["Pratnicki",	261.10392	]	,
["Pikulik",	261.1086	]	,
["Otlewski",	261.11328	]	,
["Matyskiewicz",	261.11796	]	,
["Masarczyk",	261.12264	]	,
["Lucinski",	261.12732	]	,
["Lubaszka",	261.132	]	,
["Litka",	261.13668	]	,
["Kupracz",	261.14136	]	,
["Kulacz",	261.14604	]	,
["Kucharzak",	261.15072	]	,
["Krzysztofowicz",	261.1554	]	,
["Grolik",	261.16008	]	,
["Gomulski",	261.16476	]	,
["Galoch",	261.16944	]	,
["Belski",	261.17412	]	,
["Wybranowski",	261.17879	]	,
["Wojdala",	261.18346	]	,
["Szuper",	261.18813	]	,
["Stalka",	261.1928	]	,
["Pruchniak",	261.19747	]	,
["Predka",	261.20214	]	,
["Pisz",	261.20681	]	,
["Pakiela",	261.21148	]	,
["Matyla",	261.21615	]	,
["Malysza",	261.22082	]	,
["Lotko",	261.22549	]	,
["Ksiazczyk",	261.23016	]	,
["Kropielnicki",	261.23483	]	,
["Kordaszewski",	261.2395	]	,
["Kiedos",	261.24417	]	,
["Jedrzejko",	261.24884	]	,
["Golacki",	261.25351	]	,
["Goclawski",	261.25818	]	,
["Gloc",	261.26285	]	,
["Fituch",	261.26752	]	,
["Fabinski",	261.27219	]	,
["Dajczak",	261.27686	]	,
["Ceremuga",	261.28153	]	,
["Beer",	261.2862	]	,
["Wulczynski",	261.29086	]	,
["Wojtania",	261.29552	]	,
["Wiklinski",	261.30018	]	,
["Tocha",	261.30484	]	,
["Szustakiewicz",	261.3095	]	,
["Stuchlik",	261.31416	]	,
["Strzepka",	261.31882	]	,
["Poswiata",	261.32348	]	,
["Plackowski",	261.32814	]	,
["Paplinski",	261.3328	]	,
["Papka",	261.33746	]	,
["Majgier",	261.34212	]	,
["Lik",	261.34678	]	,
["Lenczowski",	261.35144	]	,
["Krzynówek",	261.3561	]	,
["Klej",	261.36076	]	,
["Kaminski",	261.36542	]	,
["Juszynski",	261.37008	]	,
["Józwiak",	261.37474	]	,
["Jeczmionka",	261.3794	]	,
["Jemiolo",	261.38406	]	,
["Jasniewicz",	261.38872	]	,
["Jaraczewski",	261.39338	]	,
["Jamroziak",	261.39804	]	,
["Hajto",	261.4027	]	,
["Grzymek",	261.40736	]	,
["Gorecki",	261.41202	]	,
["Gagat",	261.41668	]	,
["Domaszewski",	261.42134	]	,
["Bujakiewicz",	261.426	]	,
["Zaranski",	261.43065	]	,
["Wernik",	261.4353	]	,
["Wasicki",	261.43995	]	,
["Wackowski",	261.4446	]	,
["Sterniczuk",	261.44925	]	,
["Stawowa",	261.4539	]	,
["Saczawa",	261.45855	]	,
["Reszkowski",	261.4632	]	,
["Remus",	261.46785	]	,
["Przyborski",	261.4725	]	,
["Paszke",	261.47715	]	,
["Nawiesniak",	261.4818	]	,
["Mietelski",	261.48645	]	,
["Lackowski",	261.4911	]	,
["Krzemieniewski",	261.49575	]	,
["Kriger",	261.5004	]	,
["Kosk",	261.50505	]	,
["Kaniak",	261.5097	]	,
["Gromowski",	261.51435	]	,
["Grelak",	261.519	]	,
["Gajak",	261.52365	]	,
["Cwiklik",	261.5283	]	,
["Bragiel",	261.53295	]	,
["Zapalowski",	261.53759	]	,
["Zalesinski",	261.54223	]	,
["Wiselka",	261.54687	]	,
["Ulrich",	261.55151	]	,
["Tront",	261.55615	]	,
["Taflinski",	261.56079	]	,
["Szreter",	261.56543	]	,
["Szalajko",	261.57007	]	,
["Starzycki",	261.57471	]	,
["Pysk",	261.57935	]	,
["Potulski",	261.58399	]	,
["Perlak",	261.58863	]	,
["Paciej",	261.59327	]	,
["Mitko",	261.59791	]	,
["Maselko",	261.60255	]	,
["Koc",	261.60719	]	,
["Kloza",	261.61183	]	,
["Klose",	261.61647	]	,
["Kiec",	261.62111	]	,
["Kazala",	261.62575	]	,
["Jadowski",	261.63039	]	,
["Grosiak",	261.63503	]	,
["Cora",	261.63967	]	,
["Ciucka",	261.64431	]	,
["Chajecki",	261.64895	]	,
["Benke",	261.65359	]	,
["Soboniak",	261.65822	]	,
["Romel",	261.66285	]	,
["Rabczak",	261.66748	]	,
["Pranga",	261.67211	]	,
["Piekarniak",	261.67674	]	,
["Pasiecznik",	261.68137	]	,
["Ostatek",	261.686	]	,
["Ochmann",	261.69063	]	,
["Nagiel",	261.69526	]	,
["Martynski",	261.69989	]	,
["Kotwa",	261.70452	]	,
["Kankowski",	261.70915	]	,
["Kanabus",	261.71378	]	,
["Jelito",	261.71841	]	,
["Jedlikowski",	261.72304	]	,
["Haluszczak",	261.72767	]	,
["Groch",	261.7323	]	,
["Gnap",	261.73693	]	,
["Dolezych",	261.74156	]	,
["Deszczynski",	261.74619	]	,
["Bohdziewicz",	261.75082	]	,
["Balaga",	261.75545	]	,
["Andruszewski",	261.76008	]	,
["Widzinski",	261.7647	]	,
["Szymkowicz",	261.76932	]	,
["Szwajda",	261.77394	]	,
["Szramowski",	261.77856	]	,
["Sawala",	261.78318	]	,
["Remisz",	261.7878	]	,
["Malaczek",	261.79242	]	,
["Ladzinski",	261.79704	]	,
["Lipko",	261.80166	]	,
["Kudelko",	261.80628	]	,
["Kucma",	261.8109	]	,
["Karpala",	261.81552	]	,
["Jaskólowski",	261.82014	]	,
["Ignacak",	261.82476	]	,
["Golas",	261.82938	]	,
["Dekowski",	261.834	]	,
["Decowski",	261.83862	]	,
["Czerniewicz",	261.84324	]	,
["Buchowiecki",	261.84786	]	,
["Bedzinski",	261.85248	]	,
["Bebak",	261.8571	]	,
["Bartol",	261.86172	]	,
["Adameczek",	261.86634	]	,
["Widlarz",	261.87095	]	,
["Wiaderna",	261.87556	]	,
["Ulanicki",	261.88017	]	,
["Tyski",	261.88478	]	,
["Tupaj",	261.88939	]	,
["Toczylowski",	261.894	]	,
["Syc",	261.89861	]	,
["Stokowiec",	261.90322	]	,
["Steczek",	261.90783	]	,
["Siemko",	261.91244	]	,
["Setlak",	261.91705	]	,
["Radgowski",	261.92166	]	,
["Pintal",	261.92627	]	,
["Pertek",	261.93088	]	,
["Patla",	261.93549	]	,
["Ogrodniczak",	261.9401	]	,
["Meger",	261.94471	]	,
["Marcyniuk",	261.94932	]	,
["Lipiak",	261.95393	]	,
["Krajka",	261.95854	]	,
["Janta",	261.96315	]	,
["Janoski",	261.96776	]	,
["Jachymek",	261.97237	]	,
["Jachacy",	261.97698	]	,
["Herzyk",	261.98159	]	,
["Firmanty",	261.9862	]	,
["Domurat",	261.99081	]	,
["Czornik",	261.99542	]	,
["Czmoch",	262.00003	]	,
["Cyranowski",	262.00464	]	,
["Bloniarz",	262.00925	]	,
["Biezunski",	262.01386	]	,
["Zok",	262.01846	]	,
["Waldon",	262.02306	]	,
["Tarwacki",	262.02766	]	,
["Slawski",	262.03226	]	,
["Szmajduch",	262.03686	]	,
["Szafraniak",	262.04146	]	,
["Styrczula",	262.04606	]	,
["Sobera",	262.05066	]	,
["Prusko",	262.05526	]	,
["Plecha",	262.05986	]	,
["Pawlus",	262.06446	]	,
["Oktaba",	262.06906	]	,
["Musz",	262.07366	]	,
["Maniewski",	262.07826	]	,
["Krajza",	262.08286	]	,
["Kasowski",	262.08746	]	,
["Karmanski",	262.09206	]	,
["Kantek",	262.09666	]	,
["Kalman",	262.10126	]	,
["Jasniak",	262.10586	]	,
["Hys",	262.11046	]	,
["Grygorczuk",	262.11506	]	,
["Gardecki",	262.11966	]	,
["Freda",	262.12426	]	,
["Flakiewicz",	262.12886	]	,
["Chyc",	262.13346	]	,
["Bronder",	262.13806	]	,
["Boratyn",	262.14266	]	,
["Zabkowski",	262.14725	]	,
["Zawól",	262.15184	]	,
["Witoslawski",	262.15643	]	,
["Wewiór",	262.16102	]	,
["Szkuta",	262.16561	]	,
["Steglinski",	262.1702	]	,
["Staszel",	262.17479	]	,
["Sobis",	262.17938	]	,
["Rau",	262.18397	]	,
["Pardela",	262.18856	]	,
["Niemirowski",	262.19315	]	,
["Michowski",	262.19774	]	,
["Macieja",	262.20233	]	,
["Kuzba",	262.20692	]	,
["Kurdek",	262.21151	]	,
["Klauza",	262.2161	]	,
["Herc",	262.22069	]	,
["Gnilka",	262.22528	]	,
["Donat",	262.22987	]	,
["Dolina",	262.23446	]	,
["Czestochowski",	262.23905	]	,
["Cyranek",	262.24364	]	,
["Byk",	262.24823	]	,
["Braszka",	262.25282	]	,
["Blaszków",	262.25741	]	,
["Biniak",	262.262	]	,
["Wiczynski",	262.26658	]	,
["Warzycha",	262.27116	]	,
["Trochimiak",	262.27574	]	,
["Traczewski",	262.28032	]	,
["Tomana",	262.2849	]	,
["Szczypta",	262.28948	]	,
["Szaleniec",	262.29406	]	,
["Segiet",	262.29864	]	,
["Pawliszak",	262.30322	]	,
["Mikolajec",	262.3078	]	,
["Macko",	262.31238	]	,
["Lorent",	262.31696	]	,
["Limanowski",	262.32154	]	,
["Ledwig",	262.32612	]	,
["Kordel",	262.3307	]	,
["Kaliniak",	262.33528	]	,
["Jakusz",	262.33986	]	,
["Guzewicz",	262.34444	]	,
["Gajowski",	262.34902	]	,
["Frey",	262.3536	]	,
["Dorabiala",	262.35818	]	,
["Ciesiolkiewicz",	262.36276	]	,
["Cichawa",	262.36734	]	,
["Bebas",	262.37192	]	,
["Wypart",	262.37649	]	,
["Salbut",	262.38106	]	,
["Rymsza",	262.38563	]	,
["Ritter",	262.3902	]	,
["Purczynski",	262.39477	]	,
["Nosowicz",	262.39934	]	,
["Mokry",	262.40391	]	,
["Lyszkiewicz",	262.40848	]	,
["Leyk",	262.41305	]	,
["Krasnopolski",	262.41762	]	,
["Kandulski",	262.42219	]	,
["Gojny",	262.42676	]	,
["Ewert",	262.43133	]	,
["Chmal",	262.4359	]	,
["Zemla",	262.44046	]	,
["Zysko",	262.44502	]	,
["Walat",	262.44958	]	,
["Tomica",	262.45414	]	,
["Studencki",	262.4587	]	,
["Stano",	262.46326	]	,
["Spytkowski",	262.46782	]	,
["Spica",	262.47238	]	,
["Plaszczyk",	262.47694	]	,
["Kozarzewski",	262.4815	]	,
["Konkolewski",	262.48606	]	,
["Kacpura",	262.49062	]	,
["Jaworowicz",	262.49518	]	,
["Jastrzembski",	262.49974	]	,
["Halon",	262.5043	]	,
["Górzny",	262.50886	]	,
["Golasz",	262.51342	]	,
["Dzierla",	262.51798	]	,
["Dados",	262.52254	]	,
["Czapczyk",	262.5271	]	,
["Cylka",	262.53166	]	,
["Cwajna",	262.53622	]	,
["Chomiczewski",	262.54078	]	,
["Budzyn",	262.54534	]	,
["Zemsta",	262.54989	]	,
["Wardowski",	262.55444	]	,
["Tyranski",	262.55899	]	,
["Szczesniewski",	262.56354	]	,
["Rejowski",	262.56809	]	,
["Radka",	262.57264	]	,
["Pilsniak",	262.57719	]	,
["Patan",	262.58174	]	,
["Moldoch",	262.58629	]	,
["Krempa",	262.59084	]	,
["Kanecki",	262.59539	]	,
["Gzowski",	262.59994	]	,
["Gwarek",	262.60449	]	,
["Gumulak",	262.60904	]	,
["Gumula",	262.61359	]	,
["Grocholewski",	262.61814	]	,
["Cierpiol",	262.62269	]	,
["Breza",	262.62724	]	,
["Bojke",	262.63179	]	,
["Bobkiewicz",	262.63634	]	,
["Basiewicz",	262.64089	]	,
["Barnat",	262.64544	]	,
["Balys",	262.64999	]	,
["Zbyszynski",	262.65453	]	,
["Wodyk",	262.65907	]	,
["Warot",	262.66361	]	,
["Trafalski",	262.66815	]	,
["Tomkowski",	262.67269	]	,
["Sterczewski",	262.67723	]	,
["Stan",	262.68177	]	,
["Salkowski",	262.68631	]	,
["Puszcz",	262.69085	]	,
["Powaga",	262.69539	]	,
["Porzycki",	262.69993	]	,
["Patek",	262.70447	]	,
["Owsik",	262.70901	]	,
["Omelanczuk",	262.71355	]	,
["Kostkiewicz",	262.71809	]	,
["Koniuszewski",	262.72263	]	,
["Kieliszczyk",	262.72717	]	,
["Gebus",	262.73171	]	,
["Dzwigala",	262.73625	]	,
["Czarski",	262.74079	]	,
["Cholewicki",	262.74533	]	,
["Berner",	262.74987	]	,
["Zalega",	262.7544	]	,
["Wieszolek",	262.75893	]	,
["Wiechecki",	262.76346	]	,
["Wartacz",	262.76799	]	,
["Smiechowicz",	262.77252	]	,
["Rosloniec",	262.77705	]	,
["Ploszynski",	262.78158	]	,
["Naworski",	262.78611	]	,
["Mosz",	262.79064	]	,
["Machcinski",	262.79517	]	,
["Luczko",	262.7997	]	,
["Krefft",	262.80423	]	,
["Kozlarek",	262.80876	]	,
["Klunder",	262.81329	]	,
["Kieler",	262.81782	]	,
["Ingielewicz",	262.82235	]	,
["Graczkowski",	262.82688	]	,
["Fryca",	262.83141	]	,
["Dydyna",	262.83594	]	,
["Dreja",	262.84047	]	,
["Chabasinski",	262.845	]	,
["Androsiuk",	262.84953	]	,
["Ziemlewski",	262.85405	]	,
["Zdziebko",	262.85857	]	,
["Wardecki",	262.86309	]	,
["Trafas",	262.86761	]	,
["Szoldra",	262.87213	]	,
["Stezycki",	262.87665	]	,
["Smyrak",	262.88117	]	,
["Osajda",	262.88569	]	,
["Orzelski",	262.89021	]	,
["Opilka",	262.89473	]	,
["Mielec",	262.89925	]	,
["Majkrzak",	262.90377	]	,
["Lubik",	262.90829	]	,
["Liwak",	262.91281	]	,
["Kyc",	262.91733	]	,
["Korban",	262.92185	]	,
["Jarski",	262.92637	]	,
["Jakuc",	262.93089	]	,
["Jagusz",	262.93541	]	,
["Jacyna",	262.93993	]	,
["Idkowiak",	262.94445	]	,
["Heluszka",	262.94897	]	,
["Grasza",	262.95349	]	,
["Gatarek",	262.95801	]	,
["Furmanik",	262.96253	]	,
["Cycon",	262.96705	]	,
["Choroszewski",	262.97157	]	,
["Chodan",	262.97609	]	,
["Bromboszcz",	262.98061	]	,
["Bonar",	262.98513	]	,
["Bartodziej",	262.98965	]	,
["Wolejko",	262.99416	]	,
["Wojdylak",	262.99867	]	,
["Szydlak",	263.00318	]	,
["Szpilski",	263.00769	]	,
["Stanula",	263.0122	]	,
["Rozko",	263.01671	]	,
["Ronowski",	263.02122	]	,
["Policha",	263.02573	]	,
["Plinta",	263.03024	]	,
["Piskula",	263.03475	]	,
["Nowotniak",	263.03926	]	,
["Niemczynski",	263.04377	]	,
["Nastula",	263.04828	]	,
["Mastej",	263.05279	]	,
["Lelinski",	263.0573	]	,
["Króliczek",	263.06181	]	,
["Kosiek",	263.06632	]	,
["Korkus",	263.07083	]	,
["Jonek",	263.07534	]	,
["Herrmann",	263.07985	]	,
["Grabda",	263.08436	]	,
["Dykiel",	263.08887	]	,
["Dola",	263.09338	]	,
["Czarnuch",	263.09789	]	,
["Zdrzalek",	263.10239	]	,
["Witasik",	263.10689	]	,
["Wedzicha",	263.11139	]	,
["Ustaszewski",	263.11589	]	,
["Szetela",	263.12039	]	,
["Swierczynski",	263.12489	]	,
["Skawski",	263.12939	]	,
["Salach",	263.13389	]	,
["Rózewski",	263.13839	]	,
["Poludniak",	263.14289	]	,
["Polonis",	263.14739	]	,
["Patejuk",	263.15189	]	,
["Paszewski",	263.15639	]	,
["Pastuch",	263.16089	]	,
["Natorski",	263.16539	]	,
["Maczewski",	263.16989	]	,
["Kuklewski",	263.17439	]	,
["Kret",	263.17889	]	,
["Komasa",	263.18339	]	,
["Kilan",	263.18789	]	,
["Kazmierczak",	263.19239	]	,
["Jaminski",	263.19689	]	,
["Horoszkiewicz",	263.20139	]	,
["Gzella",	263.20589	]	,
["Gwozdzik",	263.21039	]	,
["Gudz",	263.21489	]	,
["Goscicki",	263.21939	]	,
["Gawdzik",	263.22389	]	,
["Flaszynski",	263.22839	]	,
["Fijas",	263.23289	]	,
["Dysko",	263.23739	]	,
["Donocik",	263.24189	]	,
["Cieciera",	263.24639	]	,
["Calusinski",	263.25089	]	,
["Bubacz",	263.25539	]	,
["Bojek",	263.25989	]	,
["Zoldak",	263.26438	]	,
["Sztabinski",	263.26887	]	,
["Salega",	263.27336	]	,
["Ryng",	263.27785	]	,
["Przystal",	263.28234	]	,
["Pacut",	263.28683	]	,
["Organek",	263.29132	]	,
["Oramus",	263.29581	]	,
["Marczykowski",	263.3003	]	,
["Mandrela",	263.30479	]	,
["Katny",	263.30928	]	,
["Karch",	263.31377	]	,
["Cyganowski",	263.31826	]	,
["Brygier",	263.32275	]	,
["Zerdzinski",	263.32723	]	,
["Wtulich",	263.33171	]	,
["Tyburczy",	263.33619	]	,
["Skoracki",	263.34067	]	,
["Razna",	263.34515	]	,
["Popiól",	263.34963	]	,
["Pacyniak",	263.35411	]	,
["Mycka",	263.35859	]	,
["Mosionek",	263.36307	]	,
["Moniak",	263.36755	]	,
["Melkowski",	263.37203	]	,
["Malag",	263.37651	]	,
["Koniarz",	263.38099	]	,
["Kiczka",	263.38547	]	,
["Kerner",	263.38995	]	,
["Horbaczewski",	263.39443	]	,
["Grelewicz",	263.39891	]	,
["Flor",	263.40339	]	,
["Filus",	263.40787	]	,
["Ciepal",	263.41235	]	,
["Chmiela",	263.41683	]	,
["Chilicki",	263.42131	]	,
["Brzuchacz",	263.42579	]	,
["Zogala",	263.43026	]	,
["Zimecki",	263.43473	]	,
["Wydmanski",	263.4392	]	,
["Uba",	263.44367	]	,
["Tyczkowski",	263.44814	]	,
["Sowul",	263.45261	]	,
["Smorczewski",	263.45708	]	,
["Skok",	263.46155	]	,
["Pytlarczyk",	263.46602	]	,
["Pekacz",	263.47049	]	,
["Paska",	263.47496	]	,
["Orzolek",	263.47943	]	,
["Oblizajek",	263.4839	]	,
["Miazio",	263.48837	]	,
["Kucio",	263.49284	]	,
["Krystkiewicz",	263.49731	]	,
["Kosmal",	263.50178	]	,
["Kocyla",	263.50625	]	,
["Kluch",	263.51072	]	,
["Kawczak",	263.51519	]	,
["Kakietek",	263.51966	]	,
["Huzarski",	263.52413	]	,
["Haratyk",	263.5286	]	,
["Grzegory",	263.53307	]	,
["Gargol",	263.53754	]	,
["Frydrysiak",	263.54201	]	,
["Flakowski",	263.54648	]	,
["Eichler",	263.55095	]	,
["Cierpikowski",	263.55542	]	,
["Ciechan",	263.55989	]	,
["Brud",	263.56436	]	,
["Brodzki",	263.56883	]	,
["Biedka",	263.5733	]	,
["Banaszczak",	263.57777	]	,
["Zowczak",	263.58223	]	,
["Wszedybyl",	263.58669	]	,
["Tryk",	263.59115	]	,
["Szawara",	263.59561	]	,
["Suprun",	263.60007	]	,
["Stefaniszyn",	263.60453	]	,
["Rebowski",	263.60899	]	,
["Rabczuk",	263.61345	]	,
["Paterski",	263.61791	]	,
["Lizewski",	263.62237	]	,
["Jasztal",	263.62683	]	,
["Garbaczewski",	263.63129	]	,
["Durczynski",	263.63575	]	,
["Dalewski",	263.64021	]	,
["Bujnicki",	263.64467	]	,
["Balcarek",	263.64913	]	,
["Adaszynski",	263.65359	]	,
["Zyski",	263.65804	]	,
["Zielony",	263.66249	]	,
["Szkarlat",	263.66694	]	,
["Studniarz",	263.67139	]	,
["Stabla",	263.67584	]	,
["Rajkiewicz",	263.68029	]	,
["Plaga",	263.68474	]	,
["Palosz",	263.68919	]	,
["Micula",	263.69364	]	,
["Matyjewicz",	263.69809	]	,
["Matyjasiak",	263.70254	]	,
["Lubiszewski",	263.70699	]	,
["Kucal",	263.71144	]	,
["Korpik",	263.71589	]	,
["Kopys",	263.72034	]	,
["Golianek",	263.72479	]	,
["Gierej",	263.72924	]	,
["Fabianowicz",	263.73369	]	,
["Dunat",	263.73814	]	,
["Dresler",	263.74259	]	,
["Dorau",	263.74704	]	,
["Bzdak",	263.75149	]	,
["Boniek",	263.75594	]	,
["Blazków",	263.76039	]	,
["Wybranski",	263.76483	]	,
["Szczupakowski",	263.76927	]	,
["Serwicki",	263.77371	]	,
["Salwowski",	263.77815	]	,
["Saletra",	263.78259	]	,
["Peksa",	263.78703	]	,
["Pawelak",	263.79147	]	,
["Palarczyk",	263.79591	]	,
["Mendrek",	263.80035	]	,
["Marchlik",	263.80479	]	,
["Langosz",	263.80923	]	,
["Kudas",	263.81367	]	,
["Krygiel",	263.81811	]	,
["Komarowski",	263.82255	]	,
["Klonica",	263.82699	]	,
["Husar",	263.83143	]	,
["Dzidowski",	263.83587	]	,
["Drozdzewski",	263.84031	]	,
["Chelkowski",	263.84475	]	,
["Zlotucha",	263.84918	]	,
["Wdowski",	263.85361	]	,
["Tyszer",	263.85804	]	,
["Tyszecki",	263.86247	]	,
["Swiszcz",	263.8669	]	,
["Szysz",	263.87133	]	,
["Synowiecki",	263.87576	]	,
["Raus",	263.88019	]	,
["Pojda",	263.88462	]	,
["Pieprz",	263.88905	]	,
["Milczewski",	263.89348	]	,
["Meres",	263.89791	]	,
["Labinski",	263.90234	]	,
["Ligaj",	263.90677	]	,
["Kowalec",	263.9112	]	,
["Korytko",	263.91563	]	,
["Kielczynski",	263.92006	]	,
["Kempczynski",	263.92449	]	,
["Harasiuk",	263.92892	]	,
["Grzedzicki",	263.93335	]	,
["Gorycki",	263.93778	]	,
["Gajdamowicz",	263.94221	]	,
["Duran",	263.94664	]	,
["Czepulkowski",	263.95107	]	,
["Czamara",	263.9555	]	,
["Arnold",	263.95993	]	,
["Weszka",	263.96435	]	,
["Szorc",	263.96877	]	,
["Szecówka",	263.97319	]	,
["Szachta",	263.97761	]	,
["Strzemecki",	263.98203	]	,
["Ryngwelski",	263.98645	]	,
["Rozborski",	263.99087	]	,
["Pruss",	263.99529	]	,
["Pogwizd",	263.99971	]	,
["Piechula",	264.00413	]	,
["Onyszkiewicz",	264.00855	]	,
["Niedworok",	264.01297	]	,
["Kukwa",	264.01739	]	,
["Kopala",	264.02181	]	,
["Kociubinski",	264.02623	]	,
["Jakiela",	264.03065	]	,
["Jahn",	264.03507	]	,
["Gregor",	264.03949	]	,
["Graczewski",	264.04391	]	,
["Gratkowski",	264.04833	]	,
["Dynek",	264.05275	]	,
["Derewonko",	264.05717	]	,
["Czmiel",	264.06159	]	,
["Cichoszewski",	264.06601	]	,
["Chodyna",	264.07043	]	,
["Bocianski",	264.07485	]	,
["Begier",	264.07927	]	,
["Zerkowski",	264.08368	]	,
["Zielen",	264.08809	]	,
["Wyrebek",	264.0925	]	,
["Wujczak",	264.09691	]	,
["Wiesyk",	264.10132	]	,
["Widynski",	264.10573	]	,
["Wardyn",	264.11014	]	,
["Szelazek",	264.11455	]	,
["Stanke",	264.11896	]	,
["Skarbinski",	264.12337	]	,
["Schiller",	264.12778	]	,
["Ruzik",	264.13219	]	,
["Rudz",	264.1366	]	,
["Redlinski",	264.14101	]	,
["Pszonka",	264.14542	]	,
["Plywacz",	264.14983	]	,
["Plewniak",	264.15424	]	,
["Pionka",	264.15865	]	,
["Misków",	264.16306	]	,
["Marzynski",	264.16747	]	,
["Lulewicz",	264.17188	]	,
["Kurszewski",	264.17629	]	,
["Krysztofowicz",	264.1807	]	,
["Jonda",	264.18511	]	,
["Grudnik",	264.18952	]	,
["Godawa",	264.19393	]	,
["Gawelek",	264.19834	]	,
["Funk",	264.20275	]	,
["Cyniak",	264.20716	]	,
["Bruzdziak",	264.21157	]	,
["Blotnicki",	264.21598	]	,
["Bartold",	264.22039	]	,
["Zajfert",	264.22479	]	,
["Wozniczak",	264.22919	]	,
["Sniec",	264.23359	]	,
["Szybka",	264.23799	]	,
["Szabala",	264.24239	]	,
["Stykowski",	264.24679	]	,
["Siry",	264.25119	]	,
["Reszko",	264.25559	]	,
["Pogrzeba",	264.25999	]	,
["Plesinski",	264.26439	]	,
["Pieron",	264.26879	]	,
["Pazura",	264.27319	]	,
["Narojczyk",	264.27759	]	,
["Nalewaj",	264.28199	]	,
["Mikler",	264.28639	]	,
["Marszalik",	264.29079	]	,
["Kuch",	264.29519	]	,
["Krysztofik",	264.29959	]	,
["Kosiewicz",	264.30399	]	,
["Kokowicz",	264.30839	]	,
["Kaput",	264.31279	]	,
["Grabowy",	264.31719	]	,
["Gawior",	264.32159	]	,
["Dymon",	264.32599	]	,
["Czach",	264.33039	]	,
["Banacki",	264.33479	]	,
["Ziemian",	264.33918	]	,
["Zegota",	264.34357	]	,
["Wojcieski",	264.34796	]	,
["Wiktorek",	264.35235	]	,
["Ulanowski",	264.35674	]	,
["Szymacha",	264.36113	]	,
["Szkurlat",	264.36552	]	,
["Spyt",	264.36991	]	,
["Rosak",	264.3743	]	,
["Roczek",	264.37869	]	,
["Ratowski",	264.38308	]	,
["Purwin",	264.38747	]	,
["Psujek",	264.39186	]	,
["Polewiak",	264.39625	]	,
["Pieczkowski",	264.40064	]	,
["Oprzadek",	264.40503	]	,
["Milaszewski",	264.40942	]	,
["Migut",	264.41381	]	,
["Malaga",	264.4182	]	,
["Krówka",	264.42259	]	,
["Kotonski",	264.42698	]	,
["Konstantynowicz",	264.43137	]	,
["Klata",	264.43576	]	,
["Kapa",	264.44015	]	,
["Junik",	264.44454	]	,
["Haranczyk",	264.44893	]	,
["Gierada",	264.45332	]	,
["Domski",	264.45771	]	,
["Dolniak",	264.4621	]	,
["Copija",	264.46649	]	,
["Chabinski",	264.47088	]	,
["Bralewski",	264.47527	]	,
["Blad",	264.47966	]	,
["Benisz",	264.48405	]	,
["Baumann",	264.48844	]	,
["Barzycki",	264.49283	]	,
["Wilczkowski",	264.49721	]	,
["Seremet",	264.50159	]	,
["Pstrag",	264.50597	]	,
["Prudlo",	264.51035	]	,
["Prandzioch",	264.51473	]	,
["Pokrzywinski",	264.51911	]	,
["Pichlak",	264.52349	]	,
["Majerczak",	264.52787	]	,
["Kuziel",	264.53225	]	,
["Kurzela",	264.53663	]	,
["Kurpios",	264.54101	]	,
["Kubiec",	264.54539	]	,
["Kozyrski",	264.54977	]	,
["Gryszko",	264.55415	]	,
["Glebicki",	264.55853	]	,
["Glabik",	264.56291	]	,
["Furgol",	264.56729	]	,
["Filipinski",	264.57167	]	,
["Dybizbanski",	264.57605	]	,
["Drzewucki",	264.58043	]	,
["Drobna",	264.58481	]	,
["Boczon",	264.58919	]	,
["Blizniak",	264.59357	]	,
["Beda",	264.59795	]	,
["Babczynski",	264.60233	]	,
["Andersz",	264.60671	]	,
["Zimon",	264.61108	]	,
["Wilkiewicz",	264.61545	]	,
["Szwedzinski",	264.61982	]	,
["Skalny",	264.62419	]	,
["Puscian",	264.62856	]	,
["Puszko",	264.63293	]	,
["Peczynski",	264.6373	]	,
["Osmalek",	264.64167	]	,
["Ociesa",	264.64604	]	,
["Mrozicki",	264.65041	]	,
["Mikuszewski",	264.65478	]	,
["Matyszczyk",	264.65915	]	,
["Lopaciuk",	264.66352	]	,
["Lepicki",	264.66789	]	,
["Klajn",	264.67226	]	,
["Kadluczka",	264.67663	]	,
["Jaksa",	264.681	]	,
["Hajduczenia",	264.68537	]	,
["Guba",	264.68974	]	,
["Grzebalski",	264.69411	]	,
["Gniady",	264.69848	]	,
["Gieras",	264.70285	]	,
["Dziurawiec",	264.70722	]	,
["Dyczewski",	264.71159	]	,
["Derwisz",	264.71596	]	,
["Danaj",	264.72033	]	,
["Cylkowski",	264.7247	]	,
["Ciotucha",	264.72907	]	,
["Buzala",	264.73344	]	,
["Brzuszek",	264.73781	]	,
["Bisewski",	264.74218	]	,
["Zwolan",	264.74654	]	,
["Zalewa",	264.7509	]	,
["Wilkonski",	264.75526	]	,
["Szturo",	264.75962	]	,
["Sznapka",	264.76398	]	,
["Smyrek",	264.76834	]	,
["Skotniczny",	264.7727	]	,
["Siola",	264.77706	]	,
["Rybnik",	264.78142	]	,
["Rumian",	264.78578	]	,
["Romanów",	264.79014	]	,
["Rachtan",	264.7945	]	,
["Przyszlak",	264.79886	]	,
["Paterka",	264.80322	]	,
["Pastewski",	264.80758	]	,
["Paczula",	264.81194	]	,
["Oleszak",	264.8163	]	,
["Ogar",	264.82066	]	,
["Mitas",	264.82502	]	,
["Melerski",	264.82938	]	,
["Matwiej",	264.83374	]	,
["Laczak",	264.8381	]	,
["Libiszewski",	264.84246	]	,
["Kubuj",	264.84682	]	,
["Kopaczynski",	264.85118	]	,
["Kedziorski",	264.85554	]	,
["Kasprowski",	264.8599	]	,
["Kapel",	264.86426	]	,
["Kalski",	264.86862	]	,
["Herzog",	264.87298	]	,
["Grosman",	264.87734	]	,
["Gotowicki",	264.8817	]	,
["Gorczycki",	264.88606	]	,
["Franecki",	264.89042	]	,
["Dziurka",	264.89478	]	,
["Brzycki",	264.89914	]	,
["Waskowiak",	264.90349	]	,
["Wanke",	264.90784	]	,
["Taraszka",	264.91219	]	,
["Szypura",	264.91654	]	,
["Sirko",	264.92089	]	,
["Sarek",	264.92524	]	,
["Rudka",	264.92959	]	,
["Radzimowski",	264.93394	]	,
["Plocha",	264.93829	]	,
["Opechowski",	264.94264	]	,
["Onak",	264.94699	]	,
["Nyka",	264.95134	]	,
["Mosek",	264.95569	]	,
["Lomza",	264.96004	]	,
["Kramarski",	264.96439	]	,
["Korbecki",	264.96874	]	,
["Jarzombek",	264.97309	]	,
["Idziaszek",	264.97744	]	,
["Haczek",	264.98179	]	,
["Grygierczyk",	264.98614	]	,
["Gielniak",	264.99049	]	,
["Gaworek",	264.99484	]	,
["Dzien",	264.99919	]	,
["Duplaga",	265.00354	]	,
["Dlugaj",	265.00789	]	,
["Cwik",	265.01224	]	,
["Cielen",	265.01659	]	,
["Chodór",	265.02094	]	,
["Boryszewski",	265.02529	]	,
["Biczyk",	265.02964	]	,
["Zbroinski",	265.03398	]	,
["Wrotek",	265.03832	]	,
["Wojtków",	265.04266	]	,
["Wiecaszek",	265.047	]	,
["Wielgomas",	265.05134	]	,
["Truskolaski",	265.05568	]	,
["Supryn",	265.06002	]	,
["Strzalek",	265.06436	]	,
["Skrzeczynski",	265.0687	]	,
["Simlat",	265.07304	]	,
["Raczko",	265.07738	]	,
["Plotkowiak",	265.08172	]	,
["Paluszczak",	265.08606	]	,
["Padol",	265.0904	]	,
["Omyla",	265.09474	]	,
["Maleszyk",	265.09908	]	,
["Lazar",	265.10342	]	,
["Lurka",	265.10776	]	,
["Korbiel",	265.1121	]	,
["Goly",	265.11644	]	,
["Gebczyk",	265.12078	]	,
["Gawda",	265.12512	]	,
["Cymerys",	265.12946	]	,
["Chareza",	265.1338	]	,
["Borczynski",	265.13814	]	,
["Bokuniewicz",	265.14248	]	,
["Blocki",	265.14682	]	,
["Badziag",	265.15116	]	,
["Zytkiewicz",	265.15549	]	,
["Zywert",	265.15982	]	,
["Zabost",	265.16415	]	,
["Wlóka",	265.16848	]	,
["Wilhelm",	265.17281	]	,
["Tuznik",	265.17714	]	,
["Tota",	265.18147	]	,
["Szylak",	265.1858	]	,
["Stramski",	265.19013	]	,
["Skrzyszowski",	265.19446	]	,
["Sadowa",	265.19879	]	,
["Rysak",	265.20312	]	,
["Rink",	265.20745	]	,
["Midor",	265.21178	]	,
["Maks",	265.21611	]	,
["Kuleczka",	265.22044	]	,
["Kozma",	265.22477	]	,
["Kopko",	265.2291	]	,
["Kecik",	265.23343	]	,
["Kajka",	265.23776	]	,
["Jakubski",	265.24209	]	,
["Hering",	265.24642	]	,
["Franica",	265.25075	]	,
["Chomczyk",	265.25508	]	,
["Brdak",	265.25941	]	,
["Biernaczyk",	265.26374	]	,
["Bicki",	265.26807	]	,
["Belniak",	265.2724	]	,
["Bascik",	265.27673	]	,
["Szulta",	265.28105	]	,
["Szkólka",	265.28537	]	,
["Szczechowiak",	265.28969	]	,
["Stasiewski",	265.29401	]	,
["Salamonski",	265.29833	]	,
["Rozniata",	265.30265	]	,
["Pec",	265.30697	]	,
["Molczyk",	265.31129	]	,
["Ladra",	265.31561	]	,
["Kulaszewicz",	265.31993	]	,
["Kowalicki",	265.32425	]	,
["Kitlas",	265.32857	]	,
["Kawaler",	265.33289	]	,
["Jurowski",	265.33721	]	,
["Jaszek",	265.34153	]	,
["Jakielski",	265.34585	]	,
["Gurdek",	265.35017	]	,
["Galgan",	265.35449	]	,
["Figula",	265.35881	]	,
["Cicharski",	265.36313	]	,
["Betcher",	265.36745	]	,
["Bartniczak",	265.37177	]	,
["Zejmo",	265.37608	]	,
["Zaplata",	265.38039	]	,
["Wolodkiewicz",	265.3847	]	,
["Wiaczek",	265.38901	]	,
["Wankiewicz",	265.39332	]	,
["Strzeminski",	265.39763	]	,
["Slodzinski",	265.40194	]	,
["Saczek",	265.40625	]	,
["Salinski",	265.41056	]	,
["Pitucha",	265.41487	]	,
["Pinkosz",	265.41918	]	,
["Obroslak",	265.42349	]	,
["Nawalaniec",	265.4278	]	,
["Mastalski",	265.43211	]	,
["Macuga",	265.43642	]	,
["Lutowski",	265.44073	]	,
["Krzych",	265.44504	]	,
["Krajczynski",	265.44935	]	,
["Konikowski",	265.45366	]	,
["Klamann",	265.45797	]	,
["Juszczyszyn",	265.46228	]	,
["Jara",	265.46659	]	,
["Hachula",	265.4709	]	,
["Falarz",	265.47521	]	,
["Dziennik",	265.47952	]	,
["Chalupczak",	265.48383	]	,
["Cetnar",	265.48814	]	,
["Bójko",	265.49245	]	,
["Bogun",	265.49676	]	,
["Bankiewicz",	265.50107	]	,
["Szyjkowski",	265.50537	]	,
["Skladowski",	265.50967	]	,
["Opozda",	265.51397	]	,
["Nagraba",	265.51827	]	,
["Maluga",	265.52257	]	,
["Machulec",	265.52687	]	,
["Loskot",	265.53117	]	,
["Leski",	265.53547	]	,
["Lampka",	265.53977	]	,
["Kuros",	265.54407	]	,
["Krak",	265.54837	]	,
["Kozubowski",	265.55267	]	,
["Komon",	265.55697	]	,
["Kalmuk",	265.56127	]	,
["Jeziak",	265.56557	]	,
["Haraf",	265.56987	]	,
["Gladys",	265.57417	]	,
["Falgowski",	265.57847	]	,
["Czajko",	265.58277	]	,
["Citak",	265.58707	]	,
["Chorazak",	265.59137	]	,
["Brzegowy",	265.59567	]	,
["Zywiec",	265.59996	]	,
["Trepkowski",	265.60425	]	,
["Swierzbinski",	265.60854	]	,
["Szalecki",	265.61283	]	,
["Stodolna",	265.61712	]	,
["Sokolik",	265.62141	]	,
["Slomiana",	265.6257	]	,
["Sekunda",	265.62999	]	,
["Sadlon",	265.63428	]	,
["Putowski",	265.63857	]	,
["Pasnicki",	265.64286	]	,
["Panecki",	265.64715	]	,
["Obolewicz",	265.65144	]	,
["Nowocin",	265.65573	]	,
["Niemczura",	265.66002	]	,
["Myrda",	265.66431	]	,
["Maksimiuk",	265.6686	]	,
["Kusowski",	265.67289	]	,
["Kulewicz",	265.67718	]	,
["Kuchnio",	265.68147	]	,
["Koczyk",	265.68576	]	,
["Kass",	265.69005	]	,
["Goman",	265.69434	]	,
["Glomb",	265.69863	]	,
["Fryt",	265.70292	]	,
["Fierka",	265.70721	]	,
["Dziepak",	265.7115	]	,
["Byrdy",	265.71579	]	,
["Bazarnik",	265.72008	]	,
["Wojton",	265.72436	]	,
["Wasylów",	265.72864	]	,
["Tronina",	265.73292	]	,
["Szwedek",	265.7372	]	,
["Szatanek",	265.74148	]	,
["Skrobala",	265.74576	]	,
["Selwa",	265.75004	]	,
["Pracki",	265.75432	]	,
["Pierscionek",	265.7586	]	,
["Parchanski",	265.76288	]	,
["Olszok",	265.76716	]	,
["Murawa",	265.77144	]	,
["Misterski",	265.77572	]	,
["Mietek",	265.78	]	,
["Mejza",	265.78428	]	,
["Leonarczyk",	265.78856	]	,
["Legut",	265.79284	]	,
["Kudlik",	265.79712	]	,
["Jesiotr",	265.8014	]	,
["Fotek",	265.80568	]	,
["Duziak",	265.80996	]	,
["Daciuk",	265.81424	]	,
["Czubala",	265.81852	]	,
["Czeszejko-Sochacki",	265.8228	]	,
["Cudna",	265.82708	]	,
["Chojka",	265.83136	]	,
["Brzezinka",	265.83564	]	,
["Bruch",	265.83992	]	,
["Bonkowski",	265.8442	]	,
["Aleksiejczuk",	265.84848	]	,
["Zazula",	265.85275	]	,
["Zatyka",	265.85702	]	,
["Wiszniowski",	265.86129	]	,
["Weclawiak",	265.86556	]	,
["Walecki",	265.86983	]	,
["Ulenberg",	265.8741	]	,
["Swica",	265.87837	]	,
["Szwoch",	265.88264	]	,
["Ston",	265.88691	]	,
["Siekierzynski",	265.89118	]	,
["Seredyn",	265.89545	]	,
["Rulka",	265.89972	]	,
["Reczynski",	265.90399	]	,
["Rachubinski",	265.90826	]	,
["Rabski",	265.91253	]	,
["Przysucha",	265.9168	]	,
["Prószynski",	265.92107	]	,
["Parzuchowski",	265.92534	]	,
["Palczak",	265.92961	]	,
["Murias",	265.93388	]	,
["Maslankowski",	265.93815	]	,
["Masna",	265.94242	]	,
["Kuszneruk",	265.94669	]	,
["Kucharczak",	265.95096	]	,
["Krótki",	265.95523	]	,
["Koperwas",	265.9595	]	,
["Kolarski",	265.96377	]	,
["Hulak",	265.96804	]	,
["Drelichowski",	265.97231	]	,
["Domasik",	265.97658	]	,
["Chrószcz",	265.98085	]	,
["Chlebosz",	265.98512	]	,
["Zuchora",	265.98938	]	,
["Wylezol",	265.99364	]	,
["Walenda",	265.9979	]	,
["Staroszczyk",	266.00216	]	,
["Sendal",	266.00642	]	,
["Rotuski",	266.01068	]	,
["Ratuszny",	266.01494	]	,
["Pulikowski",	266.0192	]	,
["Przygonski",	266.02346	]	,
["Plucienniczak",	266.02772	]	,
["Plotek",	266.03198	]	,
["Ostanski",	266.03624	]	,
["Opyd",	266.0405	]	,
["Mogielski",	266.04476	]	,
["Kulbicki",	266.04902	]	,
["Kucharuk",	266.05328	]	,
["Kluziak",	266.05754	]	,
["Kazubowski",	266.0618	]	,
["Kamysz",	266.06606	]	,
["Gorlewski",	266.07032	]	,
["Golasinski",	266.07458	]	,
["Formicki",	266.07884	]	,
["Dydula",	266.0831	]	,
["Dittrich",	266.08736	]	,
["Desperak",	266.09162	]	,
["Bzdyra",	266.09588	]	,
["Blaz",	266.10014	]	,
["Bedra",	266.1044	]	,
["Zyzak",	266.10865	]	,
["Zlomanczuk",	266.1129	]	,
["Wycislik",	266.11715	]	,
["Woldan",	266.1214	]	,
["Woinski",	266.12565	]	,
["Wichert",	266.1299	]	,
["Rzeszot",	266.13415	]	,
["Pukas",	266.1384	]	,
["Plachcinski",	266.14265	]	,
["Piesio",	266.1469	]	,
["Otfinowski",	266.15115	]	,
["Organista",	266.1554	]	,
["Nisiewicz",	266.15965	]	,
["Meszka",	266.1639	]	,
["Leszkowicz",	266.16815	]	,
["Laszczkowski",	266.1724	]	,
["Kopta",	266.17665	]	,
["Kolat",	266.1809	]	,
["Karwicki",	266.18515	]	,
["Haczyk",	266.1894	]	,
["Gruszkowski",	266.19365	]	,
["Giermek",	266.1979	]	,
["Flont",	266.20215	]	,
["Farynski",	266.2064	]	,
["Demko",	266.21065	]	,
["Cwiekala",	266.2149	]	,
["Cerajewski",	266.21915	]	,
["Byrtek",	266.2234	]	,
["Wozniacki",	266.22764	]	,
["Wenderlich",	266.23188	]	,
["Tuminski",	266.23612	]	,
["Trzonkowski",	266.24036	]	,
["Pozdzik",	266.2446	]	,
["Pak",	266.24884	]	,
["Pasterski",	266.25308	]	,
["Oblak",	266.25732	]	,
["Mystek",	266.26156	]	,
["Michulec",	266.2658	]	,
["Laga",	266.27004	]	,
["Klupa",	266.27428	]	,
["Kacprowski",	266.27852	]	,
["Holowinski",	266.28276	]	,
["Hepner",	266.287	]	,
["Drachal",	266.29124	]	,
["Dendys",	266.29548	]	,
["Daszynski",	266.29972	]	,
["Czastka",	266.30396	]	,
["Buko",	266.3082	]	,
["Beger",	266.31244	]	,
["Barycki",	266.31668	]	,
["Zalesna",	266.32091	]	,
["Wójcinski",	266.32514	]	,
["Srama",	266.32937	]	,
["Satola",	266.3336	]	,
["Samsonowicz",	266.33783	]	,
["Rogut",	266.34206	]	,
["Radzimirski",	266.34629	]	,
["Potyka",	266.35052	]	,
["Poros",	266.35475	]	,
["Pabianczyk",	266.35898	]	,
["Osiewala",	266.36321	]	,
["Ocwieja",	266.36744	]	,
["Nabrdalik",	266.37167	]	,
["Minor",	266.3759	]	,
["Marasek",	266.38013	]	,
["Lepecki",	266.38436	]	,
["Lewna",	266.38859	]	,
["Lebiecki",	266.39282	]	,
["Kupiecki",	266.39705	]	,
["Kundzicz",	266.40128	]	,
["Koltowski",	266.40551	]	,
["Jechna",	266.40974	]	,
["Jaskulowski",	266.41397	]	,
["Gradzinski",	266.4182	]	,
["Gajc",	266.42243	]	,
["Falana",	266.42666	]	,
["Dyczek",	266.43089	]	,
["Cisz",	266.43512	]	,
["Chwist",	266.43935	]	,
["Chrobocinski",	266.44358	]	,
["Zuromski",	266.4478	]	,
["Ziolkowski",	266.45202	]	,
["Zasun",	266.45624	]	,
["Zapert",	266.46046	]	,
["Wolanowski",	266.46468	]	,
["Wielicki",	266.4689	]	,
["Wiankowski",	266.47312	]	,
["Wendland",	266.47734	]	,
["Wagiel",	266.48156	]	,
["Spurek",	266.48578	]	,
["Skwark",	266.49	]	,
["Rogiewicz",	266.49422	]	,
["Pazgan",	266.49844	]	,
["Pajdzik",	266.50266	]	,
["Norkowski",	266.50688	]	,
["Mikus",	266.5111	]	,
["Lagwa",	266.51532	]	,
["Luranc",	266.51954	]	,
["Kurlapski",	266.52376	]	,
["Kurantowicz",	266.52798	]	,
["Kuprewicz",	266.5322	]	,
["Krypczyk",	266.53642	]	,
["Koronowski",	266.54064	]	,
["Kocan",	266.54486	]	,
["Jamróg",	266.54908	]	,
["Horwat",	266.5533	]	,
["Goszczycki",	266.55752	]	,
["Golebski",	266.56174	]	,
["Glibowski",	266.56596	]	,
["Garbicz",	266.57018	]	,
["Fryga",	266.5744	]	,
["Frydrychowski",	266.57862	]	,
["Frejlich",	266.58284	]	,
["Droszcz",	266.58706	]	,
["Ciaputa",	266.59128	]	,
["Albiniak",	266.5955	]	,
["Ziejewski",	266.59971	]	,
["Zajdler",	266.60392	]	,
["Wieckowicz",	266.60813	]	,
["Warias",	266.61234	]	,
["Walaszewski",	266.61655	]	,
["Ulanski",	266.62076	]	,
["Stelmaszek",	266.62497	]	,
["Stacewicz",	266.62918	]	,
["Rubak",	266.63339	]	,
["Pura",	266.6376	]	,
["Przychodzien",	266.64181	]	,
["Pomian",	266.64602	]	,
["Polubinski",	266.65023	]	,
["Petryszyn",	266.65444	]	,
["Naguszewski",	266.65865	]	,
["Morzyk",	266.66286	]	,
["Majocha",	266.66707	]	,
["Lydka",	266.67128	]	,
["Lubaszewski",	266.67549	]	,
["Lisowiec",	266.6797	]	,
["Ligus",	266.68391	]	,
["Kowzan",	266.68812	]	,
["Kawiorski",	266.69233	]	,
["Jedrysik",	266.69654	]	,
["Jakimiec",	266.70075	]	,
["Hudy",	266.70496	]	,
["Grota",	266.70917	]	,
["Graszka",	266.71338	]	,
["Dziadowiec",	266.71759	]	,
["Czubernat",	266.7218	]	,
["Ciekanski",	266.72601	]	,
["Cena",	266.73022	]	,
["Bonin",	266.73443	]	,
["Bogatek",	266.73864	]	,
["Blaszka",	266.74285	]	,
["Basiaga",	266.74706	]	,
["Barela",	266.75127	]	,
["Arak",	266.75548	]	,
["Wymyslowski",	266.75968	]	,
["Wojtachnio",	266.76388	]	,
["Szwech",	266.76808	]	,
["Stradowski",	266.77228	]	,
["Sepiolo",	266.77648	]	,
["Rumak",	266.78068	]	,
["Peziol",	266.78488	]	,
["Pawezka",	266.78908	]	,
["Najwer",	266.79328	]	,
["Muraszko",	266.79748	]	,
["Maslyk",	266.80168	]	,
["Majerowicz",	266.80588	]	,
["Machelski",	266.81008	]	,
["Liberkowski",	266.81428	]	,
["Kwitek",	266.81848	]	,
["Kryjom",	266.82268	]	,
["Kolankiewicz",	266.82688	]	,
["Kieres",	266.83108	]	,
["Górzna",	266.83528	]	,
["Golos",	266.83948	]	,
["Goczol",	266.84368	]	,
["Garnek",	266.84788	]	,
["Dziewicki",	266.85208	]	,
["Ciski",	266.85628	]	,
["Cichon",	266.86048	]	,
["Wróblewicz",	266.86467	]	,
["Sniegula",	266.86886	]	,
["Stryjak",	266.87305	]	,
["Siemieniak",	266.87724	]	,
["Rosiecki",	266.88143	]	,
["Radziminski",	266.88562	]	,
["Oswiecinski",	266.88981	]	,
["Odziemczyk",	266.894	]	,
["Nowosadzki",	266.89819	]	,
["Mietlicki",	266.90238	]	,
["Mieleszko",	266.90657	]	,
["Meus",	266.91076	]	,
["Lewartowski",	266.91495	]	,
["Kulczewski",	266.91914	]	,
["Kromer",	266.92333	]	,
["Kolesinski",	266.92752	]	,
["Kleinert",	266.93171	]	,
["Janulewicz",	266.9359	]	,
["Gielnik",	266.94009	]	,
["Gargas",	266.94428	]	,
["Fundament",	266.94847	]	,
["Dzierzecki",	266.95266	]	,
["Drewczynski",	266.95685	]	,
["Deresz",	266.96104	]	,
["Depka",	266.96523	]	,
["Dadura",	266.96942	]	,
["Burzyk",	266.97361	]	,
["Buchcik",	266.9778	]	,
["Brodala",	266.98199	]	,
["Bodzek",	266.98618	]	,
["Argasinski",	266.99037	]	,
["Arciuch",	266.99456	]	,
["Ziach",	266.99874	]	,
["Zaradzki",	267.00292	]	,
["Waniewski",	267.0071	]	,
["Uciechowski",	267.01128	]	,
["Szczepocki",	267.01546	]	,
["Sowik",	267.01964	]	,
["Niesyto",	267.02382	]	,
["Nerkowski",	267.028	]	,
["Morel",	267.03218	]	,
["Marzeda",	267.03636	]	,
["Machalowski",	267.04054	]	,
["Lobejko",	267.04472	]	,
["Liczbinski",	267.0489	]	,
["Goslinski",	267.05308	]	,
["Gadaj",	267.05726	]	,
["Dron",	267.06144	]	,
["Dobrychlop",	267.06562	]	,
["Diakowski",	267.0698	]	,
["Chelmecki",	267.07398	]	,
["Celka",	267.07816	]	,
["Balabuch",	267.08234	]	,
["Wajman",	267.08651	]	,
["Szlagor",	267.09068	]	,
["Suslo",	267.09485	]	,
["Schreiber",	267.09902	]	,
["Saks",	267.10319	]	,
["Rolski",	267.10736	]	,
["Ratuszniak",	267.11153	]	,
["Rafalowicz",	267.1157	]	,
["Puszczewicz",	267.11987	]	,
["Puczylowski",	267.12404	]	,
["Poniewaz",	267.12821	]	,
["Podlaszewski",	267.13238	]	,
["Podczaski",	267.13655	]	,
["Mocha",	267.14072	]	,
["May",	267.14489	]	,
["Mancewicz",	267.14906	]	,
["Ludkiewicz",	267.15323	]	,
["Lerka",	267.1574	]	,
["Legiec",	267.16157	]	,
["Krawcewicz",	267.16574	]	,
["Kornafel",	267.16991	]	,
["Kondzior",	267.17408	]	,
["Klicki",	267.17825	]	,
["Kilijanski",	267.18242	]	,
["Kiel",	267.18659	]	,
["Kawski",	267.19076	]	,
["Kawalkowski",	267.19493	]	,
["Kapias",	267.1991	]	,
["Jandy",	267.20327	]	,
["Gojtowski",	267.20744	]	,
["Gierlowski",	267.21161	]	,
["Gierczyk",	267.21578	]	,
["Galecki",	267.21995	]	,
["Dziatkiewicz",	267.22412	]	,
["Dobrzyniecki",	267.22829	]	,
["Dalmata",	267.23246	]	,
["Borczuch",	267.23663	]	,
["Bonowicz",	267.2408	]	,
["Baluk",	267.24497	]	,
["Wojdyna",	267.24913	]	,
["Trusiewicz",	267.25329	]	,
["Terczynski",	267.25745	]	,
["Szalowski",	267.26161	]	,
["Surman",	267.26577	]	,
["Strachota",	267.26993	]	,
["Spera",	267.27409	]	,
["Sobieranski",	267.27825	]	,
["Smarzynski",	267.28241	]	,
["Sierak",	267.28657	]	,
["Safian",	267.29073	]	,
["Rosner",	267.29489	]	,
["Reszel",	267.29905	]	,
["Ochonski",	267.30321	]	,
["Oberda",	267.30737	]	,
["Milos",	267.31153	]	,
["Mikitiuk",	267.31569	]	,
["Kromski",	267.31985	]	,
["Kielesinski",	267.32401	]	,
["Kadlubek",	267.32817	]	,
["Janczy",	267.33233	]	,
["Janaszak",	267.33649	]	,
["Jamiol",	267.34065	]	,
["Jachura",	267.34481	]	,
["Habrat",	267.34897	]	,
["Gorgolewski",	267.35313	]	,
["Gadawski",	267.35729	]	,
["Ferdek",	267.36145	]	,
["Chyzy",	267.36561	]	,
["Bukowinski",	267.36977	]	,
["Bolanowski",	267.37393	]	,
["Biedak",	267.37809	]	,
["Badach",	267.38225	]	,
["Zywczak",	267.3864	]	,
["Zoledziewski",	267.39055	]	,
["Scislo",	267.3947	]	,
["Sztwiertnia",	267.39885	]	,
["Srebro",	267.403	]	,
["Skraburski",	267.40715	]	,
["Sendek",	267.4113	]	,
["Rozkosz",	267.41545	]	,
["Rozalski",	267.4196	]	,
["Raubo",	267.42375	]	,
["Przywecki",	267.4279	]	,
["Podhorodecki",	267.43205	]	,
["Peski",	267.4362	]	,
["Paszczyk",	267.44035	]	,
["Olendzki",	267.4445	]	,
["Odachowski",	267.44865	]	,
["Muranowicz",	267.4528	]	,
["Morkowski",	267.45695	]	,
["Mielcarski",	267.4611	]	,
["Kubsik",	267.46525	]	,
["Kolos",	267.4694	]	,
["Kierpiec",	267.47355	]	,
["Jerzynski",	267.4777	]	,
["Jarysz",	267.48185	]	,
["Hauke",	267.486	]	,
["Dorosinski",	267.49015	]	,
["Cuprys",	267.4943	]	,
["Cel",	267.49845	]	,
["Bobola",	267.5026	]	,
["Baski",	267.50675	]	,
["Zadecki",	267.51089	]	,
["Wensierski",	267.51503	]	,
["Strekowski",	267.51917	]	,
["Steplowski",	267.52331	]	,
["Sapula",	267.52745	]	,
["Rybakiewicz",	267.53159	]	,
["Rusilowicz",	267.53573	]	,
["Radlo",	267.53987	]	,
["Odrobinski",	267.54401	]	,
["Mysza",	267.54815	]	,
["Kozielec",	267.55229	]	,
["Konopczynski",	267.55643	]	,
["Kledzik",	267.56057	]	,
["Herka",	267.56471	]	,
["Gawedzki",	267.56885	]	,
["Gano",	267.57299	]	,
["Drabowicz",	267.57713	]	,
["Cynar",	267.58127	]	,
["Ciurko",	267.58541	]	,
["Bies",	267.58955	]	,
["Batyra",	267.59369	]	,
["Babraj",	267.59783	]	,
["Zukrowski",	267.60196	]	,
["Zwarycz",	267.60609	]	,
["Zielnik",	267.61022	]	,
["Zabkiewicz",	267.61435	]	,
["Wroniak",	267.61848	]	,
["Trzaskalski",	267.62261	]	,
["Trabski",	267.62674	]	,
["Stus",	267.63087	]	,
["Strugacz",	267.635	]	,
["Stadler",	267.63913	]	,
["Slupianek",	267.64326	]	,
["Romik",	267.64739	]	,
["Radziej",	267.65152	]	,
["Puzon",	267.65565	]	,
["Popielewski",	267.65978	]	,
["Pelech",	267.66391	]	,
["Niepieklo",	267.66804	]	,
["Nasiadko",	267.67217	]	,
["Nagaj",	267.6763	]	,
["Metera",	267.68043	]	,
["Lysien",	267.68456	]	,
["Las",	267.68869	]	,
["Krzysztof",	267.69282	]	,
["Kropacz",	267.69695	]	,
["Kondek",	267.70108	]	,
["Kojro",	267.70521	]	,
["Kobylarczyk",	267.70934	]	,
["Klara",	267.71347	]	,
["Karbarz",	267.7176	]	,
["Kaleja",	267.72173	]	,
["Jaksik",	267.72586	]	,
["Górniewicz",	267.72999	]	,
["Gebel",	267.73412	]	,
["Gaszka",	267.73825	]	,
["Gajdek",	267.74238	]	,
["Figacz",	267.74651	]	,
["Dzieminski",	267.75064	]	,
["Dolkowski",	267.75477	]	,
["Danielczak",	267.7589	]	,
["Cwielag",	267.76303	]	,
["Curzydlo",	267.76716	]	,
["Bajerowski",	267.77129	]	,
["Worwa",	267.77541	]	,
["Wojtylak",	267.77953	]	,
["Widenka",	267.78365	]	,
["Welpa",	267.78777	]	,
["Sledziona",	267.79189	]	,
["Szmydt",	267.79601	]	,
["Roczen",	267.80013	]	,
["Rekus",	267.80425	]	,
["Reder",	267.80837	]	,
["Poleszczuk",	267.81249	]	,
["Pilis",	267.81661	]	,
["Odzimek",	267.82073	]	,
["Misiowiec",	267.82485	]	,
["Misiolek",	267.82897	]	,
["Lapies",	267.83309	]	,
["Littwin",	267.83721	]	,
["Lendzioszek",	267.84133	]	,
["Lelen",	267.84545	]	,
["Koszel",	267.84957	]	,
["Kikosicki",	267.85369	]	,
["Kciuk",	267.85781	]	,
["Hunek",	267.86193	]	,
["Grzesko",	267.86605	]	,
["Greber",	267.87017	]	,
["Gaura",	267.87429	]	,
["Dykier",	267.87841	]	,
["Bawolski",	267.88253	]	,
["Barylak",	267.88665	]	,
["Zygmanowski",	267.89076	]	,
["Zybert",	267.89487	]	,
["Warowna",	267.89898	]	,
["Walentek",	267.90309	]	,
["Tadajewski",	267.9072	]	,
["Sredniawa",	267.91131	]	,
["Stypczynski",	267.91542	]	,
["Salyga",	267.91953	]	,
["Salak",	267.92364	]	,
["Rolewski",	267.92775	]	,
["Paryz",	267.93186	]	,
["Ossolinski",	267.93597	]	,
["Nogly",	267.94008	]	,
["Mirota",	267.94419	]	,
["Kuchna",	267.9483	]	,
["Krzywania",	267.95241	]	,
["Krotowski",	267.95652	]	,
["Kostyla",	267.96063	]	,
["Kocikowski",	267.96474	]	,
["Kiernozek",	267.96885	]	,
["Haduch",	267.97296	]	,
["Forc",	267.97707	]	,
["Dyc",	267.98118	]	,
["Ciuchta",	267.98529	]	,
["Ciemala",	267.9894	]	,
["Bystry",	267.99351	]	,
["Bryjak",	267.99762	]	,
["Bolimowski",	268.00173	]	,
["Belica",	268.00584	]	,
["Bachurski",	268.00995	]	,
["Zurawinski",	268.01405	]	,
["Zawadowski",	268.01815	]	,
["Wasilczyk",	268.02225	]	,
["Tetkowski",	268.02635	]	,
["Szumigaj",	268.03045	]	,
["Szarszewski",	268.03455	]	,
["Skwiercz",	268.03865	]	,
["Skalbania",	268.04275	]	,
["Sajak",	268.04685	]	,
["Ryzinski",	268.05095	]	,
["Pysklo",	268.05505	]	,
["Pazdur",	268.05915	]	,
["Paradzinski",	268.06325	]	,
["Oczos",	268.06735	]	,
["Niewdana",	268.07145	]	,
["Narozniak",	268.07555	]	,
["Mosio",	268.07965	]	,
["Mojecki",	268.08375	]	,
["Markus",	268.08785	]	,
["Laszkowski",	268.09195	]	,
["Kryszczynski",	268.09605	]	,
["Karolkiewicz",	268.10015	]	,
["Jonas",	268.10425	]	,
["Imielowski",	268.10835	]	,
["Halaj",	268.11245	]	,
["Gotowala",	268.11655	]	,
["Dula",	268.12065	]	,
["Czorny",	268.12475	]	,
["Czerwiak",	268.12885	]	,
["Cieszczyk",	268.13295	]	,
["Trochimczuk",	268.13704	]	,
["Sobinski",	268.14113	]	,
["Roslonski",	268.14522	]	,
["Obidowski",	268.14931	]	,
["Niechaj",	268.1534	]	,
["Muszalik",	268.15749	]	,
["Mokrzynski",	268.16158	]	,
["Minge",	268.16567	]	,
["Merski",	268.16976	]	,
["Ledzinski",	268.17385	]	,
["Kuzniacki",	268.17794	]	,
["Kumór",	268.18203	]	,
["Krzystanek",	268.18612	]	,
["Kozluk",	268.19021	]	,
["Komór",	268.1943	]	,
["Knopp",	268.19839	]	,
["Kielkiewicz",	268.20248	]	,
["Kanadys",	268.20657	]	,
["Judzinski",	268.21066	]	,
["Jezioro",	268.21475	]	,
["Jablkowski",	268.21884	]	,
["Grabicki",	268.22293	]	,
["Drabicki",	268.22702	]	,
["Dega",	268.23111	]	,
["Dacki",	268.2352	]	,
["Chwiejczak",	268.23929	]	,
["Albrycht",	268.24338	]	,
["Zuczek",	268.24746	]	,
["Zbucki",	268.25154	]	,
["Zarecki",	268.25562	]	,
["Wrycza",	268.2597	]	,
["Warchulski",	268.26378	]	,
["Scislowicz",	268.26786	]	,
["Szczepara",	268.27194	]	,
["Szast",	268.27602	]	,
["Szablak",	268.2801	]	,
["Ryzner",	268.28418	]	,
["Rafa",	268.28826	]	,
["Ozimkiewicz",	268.29234	]	,
["Marona",	268.29642	]	,
["Malkowski",	268.3005	]	,
["Kotanski",	268.30458	]	,
["Kister",	268.30866	]	,
["Jasiulewicz",	268.31274	]	,
["Hodun",	268.31682	]	,
["Gryczynski",	268.3209	]	,
["Górnicz",	268.32498	]	,
["Fiutek",	268.32906	]	,
["Dorocinski",	268.33314	]	,
["Benedyk",	268.33722	]	,
["Belczyk",	268.3413	]	,
["Babik",	268.34538	]	,
["Banaszynski",	268.34946	]	,
["Abraham",	268.35354	]	,
["Zgutka",	268.35761	]	,
["Zdunczuk",	268.36168	]	,
["Zaborniak",	268.36575	]	,
["Winczura",	268.36982	]	,
["Wiechetek",	268.37389	]	,
["Waszynski",	268.37796	]	,
["Tywonek",	268.38203	]	,
["Tomkiel",	268.3861	]	,
["Szyler",	268.39017	]	,
["Szmulewicz",	268.39424	]	,
["Szejna",	268.39831	]	,
["Szczesna",	268.40238	]	,
["Sury",	268.40645	]	,
["Spizewski",	268.41052	]	,
["Skubik",	268.41459	]	,
["Satlawa",	268.41866	]	,
["Paluba",	268.42273	]	,
["Ogiela",	268.4268	]	,
["Nitschke",	268.43087	]	,
["Niewiadoma",	268.43494	]	,
["Mordel",	268.43901	]	,
["Latkowski",	268.44308	]	,
["Korytek",	268.44715	]	,
["Korpowski",	268.45122	]	,
["Kipka",	268.45529	]	,
["Habel",	268.45936	]	,
["Franz",	268.46343	]	,
["Drygiel",	268.4675	]	,
["Dorota",	268.47157	]	,
["Derucki",	268.47564	]	,
["Ciolko",	268.47971	]	,
["Boreczek",	268.48378	]	,
["Bizewski",	268.48785	]	,
["Biarda",	268.49192	]	,
["Balon",	268.49599	]	,
["Arabski",	268.50006	]	,
["Wroniszewski",	268.50412	]	,
["Szumlas",	268.50818	]	,
["Sotomski",	268.51224	]	,
["Sosnierz",	268.5163	]	,
["Smagiel",	268.52036	]	,
["Salaj",	268.52442	]	,
["Reclik",	268.52848	]	,
["Rabsztyn",	268.53254	]	,
["Pawlisz",	268.5366	]	,
["Paweloszek",	268.54066	]	,
["Nieduzak",	268.54472	]	,
["Nidzgorski",	268.54878	]	,
["Matias",	268.55284	]	,
["Lecyk",	268.5569	]	,
["Kuzmiak",	268.56096	]	,
["Kolanczyk",	268.56502	]	,
["Kluzniak",	268.56908	]	,
["Karczmarski",	268.57314	]	,
["Jerzewski",	268.5772	]	,
["Gromelski",	268.58126	]	,
["Furs",	268.58532	]	,
["Frosztega",	268.58938	]	,
["Flisak",	268.59344	]	,
["Fajdek",	268.5975	]	,
["Derbin",	268.60156	]	,
["Czubala",	268.60562	]	,
["Chrapkiewicz",	268.60968	]	,
["Bikowski",	268.61374	]	,
["Albecki",	268.6178	]	,
["Ziental",	268.62185	]	,
["Zielak",	268.6259	]	,
["Zawierta",	268.62995	]	,
["Tamiola",	268.634	]	,
["Tabaszewski",	268.63805	]	,
["Smieciuch",	268.6421	]	,
["Szymiec",	268.64615	]	,
["Sluzewski",	268.6502	]	,
["Rzewnicki",	268.65425	]	,
["Rycyk",	268.6583	]	,
["Piotr",	268.66235	]	,
["Pazdro",	268.6664	]	,
["Owsianik",	268.67045	]	,
["Moson",	268.6745	]	,
["Majowicz",	268.67855	]	,
["Kocela",	268.6826	]	,
["Jujka",	268.68665	]	,
["Gluszkowski",	268.6907	]	,
["Gburek",	268.69475	]	,
["Franosz",	268.6988	]	,
["Fedorowski",	268.70285	]	,
["Dziedziela",	268.7069	]	,
["Dytkowski",	268.71095	]	,
["Drabina",	268.715	]	,
["Dondalski",	268.71905	]	,
["Darda",	268.7231	]	,
["Chodorski",	268.72715	]	,
["Chodara",	268.7312	]	,
["Cejrowski",	268.73525	]	,
["Burzak",	268.7393	]	,
["Bratko",	268.74335	]	,
["Boltryk",	268.7474	]	,
["Bock",	268.75145	]	,
["Blaszke",	268.7555	]	,
["Atlas",	268.75955	]	,
["Zdebel",	268.76359	]	,
["Wytrwal",	268.76763	]	,
["Trytek",	268.77167	]	,
["Teperek",	268.77571	]	,
["Tadeusz",	268.77975	]	,
["Szkiladz",	268.78379	]	,
["Syrocki",	268.78783	]	,
["Stryszowski",	268.79187	]	,
["Stangret",	268.79591	]	,
["Spala",	268.79995	]	,
["Sobas",	268.80399	]	,
["Skrobol",	268.80803	]	,
["Pijet",	268.81207	]	,
["Nalewajka",	268.81611	]	,
["Marcjan",	268.82015	]	,
["Machola",	268.82419	]	,
["Labanowski",	268.82823	]	,
["Lubon",	268.83227	]	,
["Lepiarczyk",	268.83631	]	,
["Lara",	268.84035	]	,
["Kwasnica",	268.84439	]	,
["Kosut",	268.84843	]	,
["Kosin",	268.85247	]	,
["Kordula",	268.85651	]	,
["Konopnicki",	268.86055	]	,
["Knychala",	268.86459	]	,
["Jakalski",	268.86863	]	,
["Ilczyszyn",	268.87267	]	,
["Granica",	268.87671	]	,
["Golosz",	268.88075	]	,
["Gibula",	268.88479	]	,
["Dyczka",	268.88883	]	,
["Dubrowski",	268.89287	]	,
["Droszczak",	268.89691	]	,
["Czwarno",	268.90095	]	,
["Copik",	268.90499	]	,
["Cetnarski",	268.90903	]	,
["Cendrowicz",	268.91307	]	,
["Bugajna",	268.91711	]	,
["Brociek",	268.92115	]	,
["Bombik",	268.92519	]	,
["Bochenko",	268.92923	]	,
["Babka",	268.93327	]	,
["Zurawiecki",	268.9373	]	,
["Wzietek",	268.94133	]	,
["Turowicz",	268.94536	]	,
["Smialowski",	268.94939	]	,
["Szojda",	268.95342	]	,
["Steczko",	268.95745	]	,
["Sluzalek",	268.96148	]	,
["Siurek",	268.96551	]	,
["Salamacha",	268.96954	]	,
["Roguszczak",	268.97357	]	,
["Pydych",	268.9776	]	,
["Predota",	268.98163	]	,
["Porzuczek",	268.98566	]	,
["Porazinski",	268.98969	]	,
["Ponicki",	268.99372	]	,
["Pietruszczak",	268.99775	]	,
["Palkowski",	269.00178	]	,
["Osóbka",	269.00581	]	,
["Nesteruk",	269.00984	]	,
["Mosiek",	269.01387	]	,
["Marcula",	269.0179	]	,
["Manicki",	269.02193	]	,
["Malikowski",	269.02596	]	,
["Lobos",	269.02999	]	,
["Labiak",	269.03402	]	,
["Kurys",	269.03805	]	,
["Knasiak",	269.04208	]	,
["Kalczynski",	269.04611	]	,
["Hodur",	269.05014	]	,
["Gembalczyk",	269.05417	]	,
["Galla",	269.0582	]	,
["Duzniak",	269.06223	]	,
["Dronia",	269.06626	]	,
["Daszuta",	269.07029	]	,
["Cyga",	269.07432	]	,
["Chróscicki",	269.07835	]	,
["Cechowski",	269.08238	]	,
["Boguszewicz",	269.08641	]	,
["Beyer",	269.09044	]	,
["Bakala",	269.09447	]	,
["Angielski",	269.0985	]	,
["Andrulewicz",	269.10253	]	,
["Wodniak",	269.10655	]	,
["Wocki",	269.11057	]	,
["Twardawa",	269.11459	]	,
["Szlachcikowski",	269.11861	]	,
["Streciwilk",	269.12263	]	,
["Soloch",	269.12665	]	,
["Sedkowski",	269.13067	]	,
["Rolirad",	269.13469	]	,
["Rejent",	269.13871	]	,
["Puchala",	269.14273	]	,
["Mocny",	269.14675	]	,
["Malkiewicz",	269.15077	]	,
["Macikowski",	269.15479	]	,
["Kokoszkiewicz",	269.15881	]	,
["Knuth",	269.16283	]	,
["Kleczynski",	269.16685	]	,
["Izworski",	269.17087	]	,
["Grzegorz",	269.17489	]	,
["Gliniak",	269.17891	]	,
["Gargala",	269.18293	]	,
["Galwa",	269.18695	]	,
["Ertel",	269.19097	]	,
["Domaszk",	269.19499	]	,
["Czuper",	269.19901	]	,
["Ciurej",	269.20303	]	,
["Burtan",	269.20705	]	,
["Bochra",	269.21107	]	,
["Blazik",	269.21509	]	,
["Bergiel",	269.21911	]	,
["Bartoszcze",	269.22313	]	,
["Wiench",	269.22714	]	,
["Szwajka",	269.23115	]	,
["Szczytowski",	269.23516	]	,
["Sujak",	269.23917	]	,
["Straus",	269.24318	]	,
["Sarnicki",	269.24719	]	,
["Rudas",	269.2512	]	,
["Roszczak",	269.25521	]	,
["Przyczyna",	269.25922	]	,
["Potorski",	269.26323	]	,
["Nieckula",	269.26724	]	,
["Najduch",	269.27125	]	,
["Matlosz",	269.27526	]	,
["Konstanciak",	269.27927	]	,
["Kina",	269.28328	]	,
["Juranek",	269.28729	]	,
["Junak",	269.2913	]	,
["Harmata",	269.29531	]	,
["Gurdziel",	269.29932	]	,
["Gromski",	269.30333	]	,
["Gabrysz",	269.30734	]	,
["Dudar",	269.31135	]	,
["Ciemna",	269.31536	]	,
["Brela",	269.31937	]	,
["Adrych",	269.32338	]	,
["Ziólkiewicz",	269.32738	]	,
["Zdziechowski",	269.33138	]	,
["Wodarczyk",	269.33538	]	,
["Toporowicz",	269.33938	]	,
["Szocik",	269.34338	]	,
["Szalwinski",	269.34738	]	,
["Susz",	269.35138	]	,
["Sikon",	269.35538	]	,
["Siemienski",	269.35938	]	,
["Runo",	269.36338	]	,
["Rogos",	269.36738	]	,
["Recki",	269.37138	]	,
["Posiadala",	269.37538	]	,
["Pietura",	269.37938	]	,
["Piecki",	269.38338	]	,
["Pander",	269.38738	]	,
["Pajestka",	269.39138	]	,
["Mrugacz",	269.39538	]	,
["Mielewski",	269.39938	]	,
["Miasek",	269.40338	]	,
["Matwiejuk",	269.40738	]	,
["Komajda",	269.41138	]	,
["Gryczan",	269.41538	]	,
["Frydryk",	269.41938	]	,
["Foszcz",	269.42338	]	,
["Forystek",	269.42738	]	,
["Filo",	269.43138	]	,
["Dybczak",	269.43538	]	,
["Druzba",	269.43938	]	,
["Drawc",	269.44338	]	,
["Czapor",	269.44738	]	,
["Cielinski",	269.45138	]	,
["Chorzewski",	269.45538	]	,
["Cech",	269.45938	]	,
["Burchart",	269.46338	]	,
["Bronkowski",	269.46738	]	,
["Bialic",	269.47138	]	,
["Wolsztyniak",	269.47537	]	,
["Wnetrzak",	269.47936	]	,
["Wasag",	269.48335	]	,
["Trafny",	269.48734	]	,
["Tomporowski",	269.49133	]	,
["Spiewok",	269.49532	]	,
["Smiecinski",	269.49931	]	,
["Szutko",	269.5033	]	,
["Sedzikowski",	269.50729	]	,
["Rózek",	269.51128	]	,
["Przyklenk",	269.51527	]	,
["Poplonski",	269.51926	]	,
["Pancer",	269.52325	]	,
["Ozarek",	269.52724	]	,
["Obojski",	269.53123	]	,
["Nowocinski",	269.53522	]	,
["Nowalinski",	269.53921	]	,
["Muzalewski",	269.5432	]	,
["Mironski",	269.54719	]	,
["Lukiewicz",	269.55118	]	,
["Lubian",	269.55517	]	,
["Liana",	269.55916	]	,
["Lal",	269.56315	]	,
["Krybus",	269.56714	]	,
["Jelski",	269.57113	]	,
["Imianowski",	269.57512	]	,
["Grajczyk",	269.57911	]	,
["Dziedziczak",	269.5831	]	,
["Burcon",	269.58709	]	,
["Benkowski",	269.59108	]	,
["Baldowski",	269.59507	]	,
["Zulawski",	269.59905	]	,
["Zapal",	269.60303	]	,
["Talalaj",	269.60701	]	,
["Russek",	269.61099	]	,
["Rubaszewski",	269.61497	]	,
["Psyk",	269.61895	]	,
["Moc",	269.62293	]	,
["Michalecki",	269.62691	]	,
["Liberek",	269.63089	]	,
["Lemiech",	269.63487	]	,
["Lampkowski",	269.63885	]	,
["Kurczyna",	269.64283	]	,
["Konopelski",	269.64681	]	,
["Kokoszko",	269.65079	]	,
["Klaczynski",	269.65477	]	,
["Kluczkowski",	269.65875	]	,
["Klekowski",	269.66273	]	,
["Klat",	269.66671	]	,
["Jon",	269.67069	]	,
["Jojko",	269.67467	]	,
["Ibrom",	269.67865	]	,
["Gburzynski",	269.68263	]	,
["Gasinski",	269.68661	]	,
["Dzielinski",	269.69059	]	,
["Dylewicz",	269.69457	]	,
["Dlugaszek",	269.69855	]	,
["Dalkowski",	269.70253	]	,
["Dahlke",	269.70651	]	,
["Czuber",	269.71049	]	,
["Bujek",	269.71447	]	,
["Brzozowiec",	269.71845	]	,
["Biedrawa",	269.72243	]	,
["Bartelik",	269.72641	]	,
["Bajorski",	269.73039	]	,
["Zdunkiewicz",	269.73436	]	,
["Zajchowski",	269.73833	]	,
["Wloszczynski",	269.7423	]	,
["Telecki",	269.74627	]	,
["Siepsiak",	269.75024	]	,
["Ratkiewicz",	269.75421	]	,
["Poterala",	269.75818	]	,
["Plaszczyk",	269.76215	]	,
["Picz",	269.76612	]	,
["Macuda",	269.77009	]	,
["Lubiarz",	269.77406	]	,
["Lipiarz",	269.77803	]	,
["Latoch",	269.782	]	,
["Kranz",	269.78597	]	,
["Kieras",	269.78994	]	,
["Kielian",	269.79391	]	,
["Haliniak",	269.79788	]	,
["Gworek",	269.80185	]	,
["Gerke",	269.80582	]	,
["Daron",	269.80979	]	,
["Cychowski",	269.81376	]	,
["Cur",	269.81773	]	,
["Borgosz",	269.8217	]	,
["Barysz",	269.82567	]	,
["Banik",	269.82964	]	,
["Zamorowski",	269.8336	]	,
["Wlusek",	269.83756	]	,
["Went",	269.84152	]	,
["Wanot",	269.84548	]	,
["Walkuski",	269.84944	]	,
["Ulbrych",	269.8534	]	,
["Tulej",	269.85736	]	,
["Tluscik",	269.86132	]	,
["Sztyk",	269.86528	]	,
["Stafiej",	269.86924	]	,
["Rychlewicz",	269.8732	]	,
["Pelikan",	269.87716	]	,
["Pacer",	269.88112	]	,
["Ornatowski",	269.88508	]	,
["Minko",	269.88904	]	,
["Manelski",	269.893	]	,
["Kusza",	269.89696	]	,
["Kardacz",	269.90092	]	,
["Jedziniak",	269.90488	]	,
["Jarzembski",	269.90884	]	,
["Gurtowski",	269.9128	]	,
["Gryglak",	269.91676	]	,
["Goszka",	269.92072	]	,
["Frysztak",	269.92468	]	,
["Dziebowski",	269.92864	]	,
["Dzierzbicki",	269.9326	]	,
["Dymala",	269.93656	]	,
["Danilczyk",	269.94052	]	,
["Ceranka",	269.94448	]	,
["Bromirski",	269.94844	]	,
["Biszczanik",	269.9524	]	,
["Batycki",	269.95636	]	,
["Badenski",	269.96032	]	,
["Wysmyk",	269.96427	]	,
["Urtnowski",	269.96822	]	,
["Trykacz",	269.97217	]	,
["Tochowicz",	269.97612	]	,
["Solyga",	269.98007	]	,
["Smakowski",	269.98402	]	,
["Schodowski",	269.98797	]	,
["Rozenek",	269.99192	]	,
["Rezner",	269.99587	]	,
["Pacuk",	269.99982	]	,
["Oklinski",	270.00377	]	,
["Ogierman",	270.00772	]	,
["Matykiewicz",	270.01167	]	,
["Kamien",	270.01562	]	,
["Jutkiewicz",	270.01957	]	,
["Jaciubek",	270.02352	]	,
["Imbierowicz",	270.02747	]	,
["Hornowski",	270.03142	]	,
["Gryglas",	270.03537	]	,
["Gryczewski",	270.03932	]	,
["Gorajski",	270.04327	]	,
["Gizicki",	270.04722	]	,
["Gaszczyk",	270.05117	]	,
["Donaj",	270.05512	]	,
["Danielczuk",	270.05907	]	,
["Cudek",	270.06302	]	,
["Balcerzyk",	270.06697	]	,
["Armatowski",	270.07092	]	,
["Wysoczynski",	270.07486	]	,
["Worobiej",	270.0788	]	,
["Wloczewski",	270.08274	]	,
["Weclawik",	270.08668	]	,
["Wereski",	270.09062	]	,
["Wandtke",	270.09456	]	,
["Szapiel",	270.0985	]	,
["Syty",	270.10244	]	,
["Strzebonski",	270.10638	]	,
["Stajkowski",	270.11032	]	,
["Solka",	270.11426	]	,
["Slupczynski",	270.1182	]	,
["Sielecki",	270.12214	]	,
["Sajna",	270.12608	]	,
["Owsiana",	270.13002	]	,
["Nadulski",	270.13396	]	,
["Mikuta",	270.1379	]	,
["Lubomski",	270.14184	]	,
["Lalewicz",	270.14578	]	,
["Kuzdub",	270.14972	]	,
["Kliza",	270.15366	]	,
["Kandefer",	270.1576	]	,
["Jeruzal",	270.16154	]	,
["Jazowski",	270.16548	]	,
["Hajduczek",	270.16942	]	,
["Gnoinski",	270.17336	]	,
["Gawryszczak",	270.1773	]	,
["Figaj",	270.18124	]	,
["Duplicki",	270.18518	]	,
["Dubaniewicz",	270.18912	]	,
["Czaplak",	270.19306	]	,
["Brudz",	270.197	]	,
["Bracik",	270.20094	]	,
["Bendowski",	270.20488	]	,
["Zniszczol",	270.20881	]	,
["Slowicki",	270.21274	]	,
["Senski",	270.21667	]	,
["Rzempoluch",	270.2206	]	,
["Rother",	270.22453	]	,
["Reczuch",	270.22846	]	,
["Piór",	270.23239	]	,
["Paprotna",	270.23632	]	,
["Onisk",	270.24025	]	,
["Nylec",	270.24418	]	,
["Nartowicz",	270.24811	]	,
["Lakowski",	270.25204	]	,
["Leda",	270.25597	]	,
["Kuszka",	270.2599	]	,
["Kubien",	270.26383	]	,
["Klyz",	270.26776	]	,
["Hartwich",	270.27169	]	,
["Gwizdak",	270.27562	]	,
["Gotowiec",	270.27955	]	,
["Fret",	270.28348	]	,
["Dziurda",	270.28741	]	,
["Burnos",	270.29134	]	,
["Bulicz",	270.29527	]	,
["Brachmanski",	270.2992	]	,
["Berka",	270.30313	]	,
["Bagan",	270.30706	]	,
["Zarach",	270.31098	]	,
["Woloszczak",	270.3149	]	,
["Turos",	270.31882	]	,
["Trefon",	270.32274	]	,
["Tessmer",	270.32666	]	,
["Szober",	270.33058	]	,
["Surdyka",	270.3345	]	,
["Sapota",	270.33842	]	,
["Rulka",	270.34234	]	,
["Rozmyslowski",	270.34626	]	,
["Pozyczka",	270.35018	]	,
["Powezka",	270.3541	]	,
["Plonczak",	270.35802	]	,
["Piwkowski",	270.36194	]	,
["Orawski",	270.36586	]	,
["Nozewski",	270.36978	]	,
["Niznik",	270.3737	]	,
["Mikulewicz",	270.37762	]	,
["Mikowski",	270.38154	]	,
["Matyga",	270.38546	]	,
["Kuzniarek",	270.38938	]	,
["Ksiazak",	270.3933	]	,
["Iwanczak",	270.39722	]	,
["Hoffa",	270.40114	]	,
["Gurski",	270.40506	]	,
["Forycki",	270.40898	]	,
["Downarowicz",	270.4129	]	,
["Bernad",	270.41682	]	,
["Wittek",	270.42073	]	,
["Werwinski",	270.42464	]	,
["Ustupski",	270.42855	]	,
["Tendera",	270.43246	]	,
["Szaro",	270.43637	]	,
["Sprysak",	270.44028	]	,
["Rabinski",	270.44419	]	,
["Puzik",	270.4481	]	,
["Poszytek",	270.45201	]	,
["Piastowski",	270.45592	]	,
["Pedzinski",	270.45983	]	,
["Patro",	270.46374	]	,
["Panowicz",	270.46765	]	,
["Palichleb",	270.47156	]	,
["Okreglicki",	270.47547	]	,
["Naploszek",	270.47938	]	,
["Lochowicz",	270.48329	]	,
["Letocha",	270.4872	]	,
["Lega",	270.49111	]	,
["Kurowicki",	270.49502	]	,
["Kubus",	270.49893	]	,
["Krzyworzeka",	270.50284	]	,
["Kawicki",	270.50675	]	,
["Jonczy",	270.51066	]	,
["Jasielski",	270.51457	]	,
["Grynkiewicz",	270.51848	]	,
["Gluc",	270.52239	]	,
["Fortunski",	270.5263	]	,
["Figlus",	270.53021	]	,
["Feliksik",	270.53412	]	,
["Feldman",	270.53803	]	,
["Farion",	270.54194	]	,
["Falacinski",	270.54585	]	,
["Dzionek",	270.54976	]	,
["Dolowy",	270.55367	]	,
["Deda",	270.55758	]	,
["Czartoryjski",	270.56149	]	,
["Bielaszka",	270.5654	]	,
["Zwiernik",	270.5693	]	,
["Zmarzlik",	270.5732	]	,
["Zasuwa",	270.5771	]	,
["Zalisz",	270.581	]	,
["Wludyka",	270.5849	]	,
["Wilczura",	270.5888	]	,
["Tyrna",	270.5927	]	,
["Swiac",	270.5966	]	,
["Sopala",	270.6005	]	,
["Solik",	270.6044	]	,
["Smalcerz",	270.6083	]	,
["Skórnicki",	270.6122	]	,
["Rymarowicz",	270.6161	]	,
["Racis",	270.62	]	,
["Polaczyk",	270.6239	]	,
["Nasierowski",	270.6278	]	,
["Mijalski",	270.6317	]	,
["Michnicki",	270.6356	]	,
["Lepich",	270.6395	]	,
["Ksiadz",	270.6434	]	,
["Kiszkiel",	270.6473	]	,
["Guzdziol",	270.6512	]	,
["Godowski",	270.6551	]	,
["Gembicki",	270.659	]	,
["Gargula",	270.6629	]	,
["Ewald",	270.6668	]	,
["Dudycz",	270.6707	]	,
["Dluga",	270.6746	]	,
["Diduch",	270.6785	]	,
["Chlebowicz",	270.6824	]	,
["Bierla",	270.6863	]	,
["Antonkiewicz",	270.6902	]	,
["Wrzeciono",	270.69409	]	,
["Turlinski",	270.69798	]	,
["Swiatly",	270.70187	]	,
["Szypowski",	270.70576	]	,
["Stojecki",	270.70965	]	,
["Sposób",	270.71354	]	,
["Rost",	270.71743	]	,
["Reinert",	270.72132	]	,
["Pawelczak",	270.72521	]	,
["Parzniewski",	270.7291	]	,
["Palarz",	270.73299	]	,
["Milowski",	270.73688	]	,
["Lydzinski",	270.74077	]	,
["Korgol",	270.74466	]	,
["Kieruzel",	270.74855	]	,
["Janica",	270.75244	]	,
["Górczewski",	270.75633	]	,
["Gierwatowski",	270.76022	]	,
["Dymnicki",	270.76411	]	,
["Dalka",	270.768	]	,
["Czyrek",	270.77189	]	,
["Ciekot",	270.77578	]	,
["Ciaglo",	270.77967	]	,
["Bzura",	270.78356	]	,
["Budziosz",	270.78745	]	,
["Bejda",	270.79134	]	,
["Zganiacz",	270.79522	]	,
["Waldowski",	270.7991	]	,
["Wakuluk",	270.80298	]	,
["Ura",	270.80686	]	,
["Tenerowicz",	270.81074	]	,
["Szacon",	270.81462	]	,
["Stempinski",	270.8185	]	,
["Siemieniako",	270.82238	]	,
["Rydzinski",	270.82626	]	,
["Rózylo",	270.83014	]	,
["Rajwa",	270.83402	]	,
["Przysiecki",	270.8379	]	,
["Polniak",	270.84178	]	,
["Okun",	270.84566	]	,
["Milarski",	270.84954	]	,
["Michlewicz",	270.85342	]	,
["Kurzawinski",	270.8573	]	,
["Kolaski",	270.86118	]	,
["Kalupa",	270.86506	]	,
["Jendrzejczak",	270.86894	]	,
["Jary",	270.87282	]	,
["Gluszcz",	270.8767	]	,
["Flejszar",	270.88058	]	,
["Dorniak",	270.88446	]	,
["Derela",	270.88834	]	,
["Delimata",	270.89222	]	,
["Chroscicki",	270.8961	]	,
["Chalupnik",	270.89998	]	,
["Bojdol",	270.90386	]	,
["Boczarski",	270.90774	]	,
["Aksamitowski",	270.91162	]	,
["Wolosiuk",	270.91549	]	,
["Wesek",	270.91936	]	,
["Tynski",	270.92323	]	,
["Slosarczyk",	270.9271	]	,
["Sciana",	270.93097	]	,
["Stanios",	270.93484	]	,
["Sirocki",	270.93871	]	,
["Sasim",	270.94258	]	,
["Rotter",	270.94645	]	,
["Pajaczek",	270.95032	]	,
["Nyczka",	270.95419	]	,
["Laguna",	270.95806	]	,
["Kosma",	270.96193	]	,
["Koltunski",	270.9658	]	,
["Koleda",	270.96967	]	,
["Kalowski",	270.97354	]	,
["Jacaszek",	270.97741	]	,
["Herma",	270.98128	]	,
["Helbin",	270.98515	]	,
["Gromulski",	270.98902	]	,
["Gogolinski",	270.99289	]	,
["Gal",	270.99676	]	,
["Gajer",	271.00063	]	,
["Foltynowicz",	271.0045	]	,
["Filoda",	271.00837	]	,
["Celarek",	271.01224	]	,
["But",	271.01611	]	,
["Borzuchowski",	271.01998	]	,
["Bigosinski",	271.02385	]	,
["Biber",	271.02772	]	,
["Zawodna",	271.03158	]	,
["Zamecki",	271.03544	]	,
["Wierciak",	271.0393	]	,
["Sendrowicz",	271.04316	]	,
["Rozmyslowicz",	271.04702	]	,
["Poskrobko",	271.05088	]	,
["Pielorz",	271.05474	]	,
["Misiuda",	271.0586	]	,
["Malasiewicz",	271.06246	]	,
["Kulkowski",	271.06632	]	,
["Kotewicz",	271.07018	]	,
["Kostrz",	271.07404	]	,
["Kondas",	271.0779	]	,
["Jarczok",	271.08176	]	,
["Hryn",	271.08562	]	,
["Honisz",	271.08948	]	,
["Gizewski",	271.09334	]	,
["Franiczek",	271.0972	]	,
["Fietko",	271.10106	]	,
["Drazba",	271.10492	]	,
["Dopart",	271.10878	]	,
["Cichorek",	271.11264	]	,
["Bugara",	271.1165	]	,
["Bazyl",	271.12036	]	,
["Barchanski",	271.12422	]	,
["Balwierz",	271.12808	]	,
["Babiarczyk",	271.13194	]	,
["Achramowicz",	271.1358	]	,
["Zyber",	271.13965	]	,
["Zboinski",	271.1435	]	,
["Trzpis",	271.14735	]	,
["Swiercz",	271.1512	]	,
["Surdacki",	271.15505	]	,
["Streich",	271.1589	]	,
["Stasiukiewicz",	271.16275	]	,
["Sobesto",	271.1666	]	,
["Rokicinski",	271.17045	]	,
["Rogalewicz",	271.1743	]	,
["Puszkarski",	271.17815	]	,
["Przytocki",	271.182	]	,
["Proszek",	271.18585	]	,
["Popkowski",	271.1897	]	,
["Piatyszek",	271.19355	]	,
["Peszynski",	271.1974	]	,
["Niekrasz",	271.20125	]	,
["Nadarzynski",	271.2051	]	,
["Mysiakowski",	271.20895	]	,
["Mosiadz",	271.2128	]	,
["Morawiecki",	271.21665	]	,
["Modrzyk",	271.2205	]	,
["Mikolajko",	271.22435	]	,
["Metryka",	271.2282	]	,
["Lyczywek",	271.23205	]	,
["Kwitowski",	271.2359	]	,
["Krzeszowiak",	271.23975	]	,
["Konert",	271.2436	]	,
["Kelm",	271.24745	]	,
["Kaczala",	271.2513	]	,
["Judasz",	271.25515	]	,
["Jakus",	271.259	]	,
["Hylinski",	271.26285	]	,
["Hydzik",	271.2667	]	,
["Horosz",	271.27055	]	,
["Fiks",	271.2744	]	,
["Fidyk",	271.27825	]	,
["Domaszczynski",	271.2821	]	,
["Czernielewski",	271.28595	]	,
["Ciapala",	271.2898	]	,
["Cegiela",	271.29365	]	,
["Zawilski",	271.29749	]	,
["Zagórowski",	271.30133	]	,
["Wrzeszczynski",	271.30517	]	,
["Wielebski",	271.30901	]	,
["Turkot",	271.31285	]	,
["Szwankowski",	271.31669	]	,
["Szudra",	271.32053	]	,
["Szkaluba",	271.32437	]	,
["Szczachor",	271.32821	]	,
["Starczyk",	271.33205	]	,
["Smyka",	271.33589	]	,
["Skrobacz",	271.33973	]	,
["Sieniuc",	271.34357	]	,
["Rojkowski",	271.34741	]	,
["Pyczek",	271.35125	]	,
["Pitak",	271.35509	]	,
["Pfeifer",	271.35893	]	,
["Parzybut",	271.36277	]	,
["Olowski",	271.36661	]	,
["Olik",	271.37045	]	,
["Natkowski",	271.37429	]	,
["Nanowski",	271.37813	]	,
["Nagórna",	271.38197	]	,
["Myka",	271.38581	]	,
["Mozga",	271.38965	]	,
["Moder",	271.39349	]	,
["Mecina",	271.39733	]	,
["Maksymiak",	271.40117	]	,
["Kniat",	271.40501	]	,
["Kimel",	271.40885	]	,
["Kapelinski",	271.41269	]	,
["Jermak",	271.41653	]	,
["Hajdamowicz",	271.42037	]	,
["Gumulka",	271.42421	]	,
["Gregula",	271.42805	]	,
["Ginda",	271.43189	]	,
["Gigon",	271.43573	]	,
["Gancarek",	271.43957	]	,
["Filarowski",	271.44341	]	,
["Feliga",	271.44725	]	,
["Dziub",	271.45109	]	,
["Dubiela",	271.45493	]	,
["Drobniewski",	271.45877	]	,
["Chraniuk",	271.46261	]	,
["Chalupa",	271.46645	]	,
["Burkot",	271.47029	]	,
["Ambroszczyk",	271.47413	]	,
["Zowada",	271.47796	]	,
["Zdanski",	271.48179	]	,
["Woszek",	271.48562	]	,
["Wolosewicz",	271.48945	]	,
["Vogel",	271.49328	]	,
["Szubzda",	271.49711	]	,
["Szlosek",	271.50094	]	,
["Salasinski",	271.50477	]	,
["Pecherz",	271.5086	]	,
["Patalon",	271.51243	]	,
["Parma",	271.51626	]	,
["Paluszynski",	271.52009	]	,
["Orzelek",	271.52392	]	,
["Najderek",	271.52775	]	,
["Mstowski",	271.53158	]	,
["Menes",	271.53541	]	,
["Masnica",	271.53924	]	,
["Lucarz",	271.54307	]	,
["Lidwin",	271.5469	]	,
["Kunce",	271.55073	]	,
["Kudla",	271.55456	]	,
["Kijanowski",	271.55839	]	,
["Kesler",	271.56222	]	,
["Gnas",	271.56605	]	,
["Gawelczyk",	271.56988	]	,
["Frodyma",	271.57371	]	,
["Drogowski",	271.57754	]	,
["Dobski",	271.58137	]	,
["Dana",	271.5852	]	,
["Czudaj",	271.58903	]	,
["Czechura",	271.59286	]	,
["Brusilo",	271.59669	]	,
["Broszko",	271.60052	]	,
["Berlik",	271.60435	]	,
["Bergier",	271.60818	]	,
["Wysoglad",	271.612	]	,
["Wenderski",	271.61582	]	,
["Walasiak",	271.61964	]	,
["Szubka",	271.62346	]	,
["Stempkowski",	271.62728	]	,
["Sorek",	271.6311	]	,
["Skotak",	271.63492	]	,
["Rzepniewski",	271.63874	]	,
["Robaczynski",	271.64256	]	,
["Pyrcz",	271.64638	]	,
["Przystalski",	271.6502	]	,
["Próchniewicz",	271.65402	]	,
["Polewczyk",	271.65784	]	,
["Pogroszewski",	271.66166	]	,
["Pacult",	271.66548	]	,
["Machynia",	271.6693	]	,
["Krakowian",	271.67312	]	,
["Korbus",	271.67694	]	,
["Kopras",	271.68076	]	,
["Klepek",	271.68458	]	,
["Kitka",	271.6884	]	,
["Izbinski",	271.69222	]	,
["Harabin",	271.69604	]	,
["Gutek",	271.69986	]	,
["Fryz",	271.70368	]	,
["Franus",	271.7075	]	,
["Fabrowski",	271.71132	]	,
["Dukiewicz",	271.71514	]	,
["Chruscik",	271.71896	]	,
["Charko",	271.72278	]	,
["Chajewski",	271.7266	]	,
["Bylewski",	271.73042	]	,
["Bulawski",	271.73424	]	,
["Brzostowicz",	271.73806	]	,
["Bronczyk",	271.74188	]	,
["Breski",	271.7457	]	,
["Arcimowicz",	271.74952	]	,
["Zarów",	271.75333	]	,
["Worobiec",	271.75714	]	,
["Wolter",	271.76095	]	,
["Wezowski",	271.76476	]	,
["Wegielski",	271.76857	]	,
["Wejer",	271.77238	]	,
["Wasylik",	271.77619	]	,
["Wartalski",	271.78	]	,
["Terech",	271.78381	]	,
["Sniadecki",	271.78762	]	,
["Szynkaruk",	271.79143	]	,
["Szymko",	271.79524	]	,
["Szafron",	271.79905	]	,
["Suchowski",	271.80286	]	,
["Skurczynski",	271.80667	]	,
["Skarzycki",	271.81048	]	,
["Sebzda",	271.81429	]	,
["Reterski",	271.8181	]	,
["Rechnio",	271.82191	]	,
["Raclawski",	271.82572	]	,
["Puda",	271.82953	]	,
["Pryczek",	271.83334	]	,
["Piontkowski",	271.83715	]	,
["Pasturczak",	271.84096	]	,
["Olszar",	271.84477	]	,
["Ogryzek",	271.84858	]	,
["Mitrus",	271.85239	]	,
["Mikolajuk",	271.8562	]	,
["Michulka",	271.86001	]	,
["Matuszny",	271.86382	]	,
["Lupkowski",	271.86763	]	,
["Kutkiewicz",	271.87144	]	,
["Kubaj",	271.87525	]	,
["Kruzel",	271.87906	]	,
["Klimza",	271.88287	]	,
["Katra",	271.88668	]	,
["Kaczmarowski",	271.89049	]	,
["Gurak",	271.8943	]	,
["Goncerz",	271.89811	]	,
["Filutowski",	271.90192	]	,
["Donica",	271.90573	]	,
["Czepek",	271.90954	]	,
["Chociej",	271.91335	]	,
["Broczkowski",	271.91716	]	,
["Bozejewicz",	271.92097	]	,
["Bokota",	271.92478	]	,
["Antoszczyszyn",	271.92859	]	,
["Zyra",	271.93239	]	,
["Szyller",	271.93619	]	,
["Szuber",	271.93999	]	,
["Syposz",	271.94379	]	,
["Staszalek",	271.94759	]	,
["Stalinski",	271.95139	]	,
["Polanczyk",	271.95519	]	,
["Plenzler",	271.95899	]	,
["Pilczuk",	271.96279	]	,
["Pakowski",	271.96659	]	,
["Niekurzak",	271.97039	]	,
["Mosiej",	271.97419	]	,
["Martowicz",	271.97799	]	,
["Macioch",	271.98179	]	,
["Kotwas",	271.98559	]	,
["Jeglinski",	271.98939	]	,
["Hejman",	271.99319	]	,
["Glomb",	271.99699	]	,
["Faryniarz",	272.00079	]	,
["Durkalec",	272.00459	]	,
["Derleta",	272.00839	]	,
["Czuchryta",	272.01219	]	,
["Czembor",	272.01599	]	,
["Chmarzynski",	272.01979	]	,
["Bujoczek",	272.02359	]	,
["Wydro",	272.02738	]	,
["Worona",	272.03117	]	,
["Trus",	272.03496	]	,
["Swirk",	272.03875	]	,
["Swiniarski",	272.04254	]	,
["Saramak",	272.04633	]	,
["Przeliorz",	272.05012	]	,
["Podlinski",	272.05391	]	,
["Pocwiardowski",	272.0577	]	,
["Plaskocinski",	272.06149	]	,
["Pierzga",	272.06528	]	,
["Nolbert",	272.06907	]	,
["Naworol",	272.07286	]	,
["Nalborczyk",	272.07665	]	,
["Musiatowicz",	272.08044	]	,
["Mscisz",	272.08423	]	,
["Maniura",	272.08802	]	,
["Maciejasz",	272.09181	]	,
["Machej",	272.0956	]	,
["Lonc",	272.09939	]	,
["Kuderski",	272.10318	]	,
["Karlak",	272.10697	]	,
["Jedrkowiak",	272.11076	]	,
["Janiczak",	272.11455	]	,
["Grabalski",	272.11834	]	,
["Golemo",	272.12213	]	,
["Galasinski",	272.12592	]	,
["Chelinski",	272.12971	]	,
["Bujala",	272.1335	]	,
["Antoniszyn",	272.13729	]	,
["Zwierzykowski",	272.14107	]	,
["Wolowczyk",	272.14485	]	,
["Wasiuta",	272.14863	]	,
["Ulasiuk",	272.15241	]	,
["Tokar",	272.15619	]	,
["Szlasa",	272.15997	]	,
["Szczurko",	272.16375	]	,
["Stromecki",	272.16753	]	,
["Smolin",	272.17131	]	,
["Skorza",	272.17509	]	,
["Rukat",	272.17887	]	,
["Puzon",	272.18265	]	,
["Przezdziak",	272.18643	]	,
["Pekacki",	272.19021	]	,
["Niewiada",	272.19399	]	,
["Mosler",	272.19777	]	,
["Matak",	272.20155	]	,
["Ladno",	272.20533	]	,
["Luto",	272.20911	]	,
["Krzys",	272.21289	]	,
["Kontowicz",	272.21667	]	,
["Kolorz",	272.22045	]	,
["Kokowski",	272.22423	]	,
["Jemielity",	272.22801	]	,
["Jaroni",	272.23179	]	,
["Janczur",	272.23557	]	,
["Hermanski",	272.23935	]	,
["Halasz",	272.24313	]	,
["Gucia",	272.24691	]	,
["Gieroba",	272.25069	]	,
["Garbas",	272.25447	]	,
["Gajowczyk",	272.25825	]	,
["Bielesz",	272.26203	]	,
["Bazak",	272.26581	]	,
["Bakowski",	272.26959	]	,
["Zuchlinski",	272.27336	]	,
["Zólcinski",	272.27713	]	,
["Zielewski",	272.2809	]	,
["Swieczak",	272.28467	]	,
["Suligowski",	272.28844	]	,
["Stawarczyk",	272.29221	]	,
["Sotek",	272.29598	]	,
["Skotarczyk",	272.29975	]	,
["Serba",	272.30352	]	,
["Ryfka",	272.30729	]	,
["Rolewicz",	272.31106	]	,
["Rejczak",	272.31483	]	,
["Puszkiewicz",	272.3186	]	,
["Preiss",	272.32237	]	,
["Poziemski",	272.32614	]	,
["Plewik",	272.32991	]	,
["Pieniadz",	272.33368	]	,
["Piastka",	272.33745	]	,
["Olesiuk",	272.34122	]	,
["Mogila",	272.34499	]	,
["Mistak",	272.34876	]	,
["Mendelewski",	272.35253	]	,
["Marecik",	272.3563	]	,
["Majsner",	272.36007	]	,
["Lubasinski",	272.36384	]	,
["Kuzaj",	272.36761	]	,
["Kosminski",	272.37138	]	,
["Kluka",	272.37515	]	,
["Klimala",	272.37892	]	,
["Juchimiuk",	272.38269	]	,
["Gesla",	272.38646	]	,
["Dybicz",	272.39023	]	,
["Dobrzykowski",	272.394	]	,
["Budasz",	272.39777	]	,
["Bakaj",	272.40154	]	,
["Badaczewski",	272.40531	]	,
["Bac",	272.40908	]	,
["Zdybal",	272.41284	]	,
["Zawrotniak",	272.4166	]	,
["Wyzlic",	272.42036	]	,
["Tomza",	272.42412	]	,
["Tobor",	272.42788	]	,
["Tloczek",	272.43164	]	,
["Swierski",	272.4354	]	,
["Skreta",	272.43916	]	,
["Sender",	272.44292	]	,
["Rubach",	272.44668	]	,
["Pyrgiel",	272.45044	]	,
["Piesiewicz",	272.4542	]	,
["Ogaza",	272.45796	]	,
["Nowomiejski",	272.46172	]	,
["Niwczyk",	272.46548	]	,
["Naumczyk",	272.46924	]	,
["Mosór",	272.473	]	,
["Kubaczka",	272.47676	]	,
["Krzyzyk",	272.48052	]	,
["Kramkowski",	272.48428	]	,
["Krakowiecki",	272.48804	]	,
["Kolbus",	272.4918	]	,
["Gryczon",	272.49556	]	,
["Dykowski",	272.49932	]	,
["Dworaczyk",	272.50308	]	,
["Czwojdrak",	272.50684	]	,
["Czlapinski",	272.5106	]	,
["Chetnik",	272.51436	]	,
["Bosy",	272.51812	]	,
["Balasa",	272.52188	]	,
["Zywiecki",	272.52563	]	,
["Zolnierek",	272.52938	]	,
["Witomski",	272.53313	]	,
["Waldowski",	272.53688	]	,
["Tyborski",	272.54063	]	,
["Trzeszczkowski",	272.54438	]	,
["Tomski",	272.54813	]	,
["Tadzik",	272.55188	]	,
["Strulak",	272.55563	]	,
["Straszynski",	272.55938	]	,
["Skitek",	272.56313	]	,
["Rosadzinski",	272.56688	]	,
["Pieloch",	272.57063	]	,
["Pelinski",	272.57438	]	,
["Oziminski",	272.57813	]	,
["Otolinski",	272.58188	]	,
["Ordowski",	272.58563	]	,
["Matosek",	272.58938	]	,
["Mandecki",	272.59313	]	,
["Magulski",	272.59688	]	,
["Lekka",	272.60063	]	,
["Kubara",	272.60438	]	,
["Korulczyk",	272.60813	]	,
["Kolan",	272.61188	]	,
["Kiszel",	272.61563	]	,
["Jeremicz",	272.61938	]	,
["Hajdo",	272.62313	]	,
["Gulik",	272.62688	]	,
["Gulewicz",	272.63063	]	,
["Gawlicki",	272.63438	]	,
["Erbel",	272.63813	]	,
["Dukaczewski",	272.64188	]	,
["Dobiech",	272.64563	]	,
["Debczynski",	272.64938	]	,
["Cimala",	272.65313	]	,
["Chrusciak",	272.65688	]	,
["Bytniewski",	272.66063	]	,
["Albinowski",	272.66438	]	,
["Zelewski",	272.66812	]	,
["Ziembla",	272.67186	]	,
["Zawojski",	272.6756	]	,
["Wodarz",	272.67934	]	,
["Wasiewski",	272.68308	]	,
["Smyla",	272.68682	]	,
["Slabiak",	272.69056	]	,
["Skakuj",	272.6943	]	,
["Sasiela",	272.69804	]	,
["Rzeszótko",	272.70178	]	,
["Reclaw",	272.70552	]	,
["Ratajek",	272.70926	]	,
["Pustkowski",	272.713	]	,
["Ptaszkowski",	272.71674	]	,
["Przyluski",	272.72048	]	,
["Przeorski",	272.72422	]	,
["Pinkas",	272.72796	]	,
["Ozon",	272.7317	]	,
["Okruta",	272.73544	]	,
["Mozol",	272.73918	]	,
["Mozgawa",	272.74292	]	,
["Lazarowicz",	272.74666	]	,
["Korbik",	272.7504	]	,
["Karelus",	272.75414	]	,
["Józefczuk",	272.75788	]	,
["Jelonkiewicz",	272.76162	]	,
["Hojnacki",	272.76536	]	,
["Francik",	272.7691	]	,
["Ejdys",	272.77284	]	,
["Dobroszek",	272.77658	]	,
["Dalke",	272.78032	]	,
["Czyzkowski",	272.78406	]	,
["Chelmowski",	272.7878	]	,
["Cebrat",	272.79154	]	,
["Biazik",	272.79528	]	,
["Barwik",	272.79902	]	,
["Zygan",	272.80275	]	,
["Wierzchucki",	272.80648	]	,
["Umiastowski",	272.81021	]	,
["Sniady",	272.81394	]	,
["Slaczka",	272.81767	]	,
["Smagowski",	272.8214	]	,
["Rachuta",	272.82513	]	,
["Przygodzinski",	272.82886	]	,
["Przeczek",	272.83259	]	,
["Potapczuk",	272.83632	]	,
["Podhajski",	272.84005	]	,
["Placzkiewicz",	272.84378	]	,
["Piernikarczyk",	272.84751	]	,
["Mlynarczuk",	272.85124	]	,
["Makal",	272.85497	]	,
["Lominski",	272.8587	]	,
["Lusnia",	272.86243	]	,
["Latoszewski",	272.86616	]	,
["Kurs",	272.86989	]	,
["Krupczak",	272.87362	]	,
["Korbas",	272.87735	]	,
["Koniarek",	272.88108	]	,
["Hercog",	272.88481	]	,
["Golc",	272.88854	]	,
["Gajtkowski",	272.89227	]	,
["Fink",	272.896	]	,
["Dulnik",	272.89973	]	,
["Draczynski",	272.90346	]	,
["Deszczka",	272.90719	]	,
["Bonecki",	272.91092	]	,
["Woloszynski",	272.91464	]	,
["Walotek",	272.91836	]	,
["Trzewik",	272.92208	]	,
["Talacha",	272.9258	]	,
["Szalbot",	272.92952	]	,
["Siakala",	272.93324	]	,
["Pyzio",	272.93696	]	,
["Purta",	272.94068	]	,
["Plura",	272.9444	]	,
["Pettke",	272.94812	]	,
["Oskwarek",	272.95184	]	,
["Obacz",	272.95556	]	,
["Nabozny",	272.95928	]	,
["Mochalski",	272.963	]	,
["Materski",	272.96672	]	,
["Manuszewski",	272.97044	]	,
["Kuzmiuk",	272.97416	]	,
["Krystyniak",	272.97788	]	,
["Krowiak",	272.9816	]	,
["Kornowski",	272.98532	]	,
["Karecki",	272.98904	]	,
["Kalwat",	272.99276	]	,
["Hupa",	272.99648	]	,
["Gurba",	273.0002	]	,
["Giez",	273.00392	]	,
["Dybus",	273.00764	]	,
["Durnas",	273.01136	]	,
["Dubowik",	273.01508	]	,
["Drob",	273.0188	]	,
["Cudo",	273.02252	]	,
["Cieslarczyk",	273.02624	]	,
["Chmielik",	273.02996	]	,
["Belkowski",	273.03368	]	,
["Becmer",	273.0374	]	,
["Basek",	273.04112	]	,
["Baniecki",	273.04484	]	,
["Zezula",	273.04855	]	,
["Zdrojkowski",	273.05226	]	,
["Wisniewski",	273.05597	]	,
["Wiski",	273.05968	]	,
["Wejner",	273.06339	]	,
["Wasyliszyn",	273.0671	]	,
["Tadrzak",	273.07081	]	,
["Szwarczewski",	273.07452	]	,
["Szarafin",	273.07823	]	,
["Sutowicz",	273.08194	]	,
["Rudkiewicz",	273.08565	]	,
["Rosciszewski",	273.08936	]	,
["Ronowicz",	273.09307	]	,
["Preisner",	273.09678	]	,
["Pientka",	273.10049	]	,
["Palewicz",	273.1042	]	,
["Ostachowski",	273.10791	]	,
["Olesik",	273.11162	]	,
["Olenski",	273.11533	]	,
["Makuchowski",	273.11904	]	,
["Kuzdzal",	273.12275	]	,
["Kusmierczuk",	273.12646	]	,
["Kupc",	273.13017	]	,
["Kryspin",	273.13388	]	,
["Krenz",	273.13759	]	,
["Kotapka",	273.1413	]	,
["Kononczuk",	273.14501	]	,
["Kecki",	273.14872	]	,
["Jezusek",	273.15243	]	,
["Gulcz",	273.15614	]	,
["Gnacinski",	273.15985	]	,
["Elzbieciak",	273.16356	]	,
["Dymczyk",	273.16727	]	,
["Dolak",	273.17098	]	,
["Detmer",	273.17469	]	,
["Borys",	273.1784	]	,
["Betlinski",	273.18211	]	,
["Babala",	273.18582	]	,
["Bawól",	273.18953	]	,
["Bartniak",	273.19324	]	,
["Uchnast",	273.19694	]	,
["Trytko",	273.20064	]	,
["Tarabasz",	273.20434	]	,
["Szwagierczak",	273.20804	]	,
["Szadurski",	273.21174	]	,
["Starczynski",	273.21544	]	,
["Smokowski",	273.21914	]	,
["Skronski",	273.22284	]	,
["Rodzoch",	273.22654	]	,
["Rapczynski",	273.23024	]	,
["Pyrczak",	273.23394	]	,
["Plocinski",	273.23764	]	,
["Pek",	273.24134	]	,
["Ostrówka",	273.24504	]	,
["Nycek",	273.24874	]	,
["Macialek",	273.25244	]	,
["Lukasinski",	273.25614	]	,
["Lotkowski",	273.25984	]	,
["Lizut",	273.26354	]	,
["Kopiecki",	273.26724	]	,
["Klink",	273.27094	]	,
["Kasków",	273.27464	]	,
["Kanafa",	273.27834	]	,
["Jedrej",	273.28204	]	,
["Jachec",	273.28574	]	,
["Hryszkiewicz",	273.28944	]	,
["Horodynski",	273.29314	]	,
["Gumny",	273.29684	]	,
["Gryszka",	273.30054	]	,
["Gorynski",	273.30424	]	,
["Gocel",	273.30794	]	,
["Gidzinski",	273.31164	]	,
["Fabijanczyk",	273.31534	]	,
["Dziambor",	273.31904	]	,
["Debczak",	273.32274	]	,
["Chodyra",	273.32644	]	,
["Chobot",	273.33014	]	,
["Anielak",	273.33384	]	,
["Zytkowski",	273.33753	]	,
["Zawrzykraj",	273.34122	]	,
["Zagroba",	273.34491	]	,
["Zabdyr",	273.3486	]	,
["Sleczek",	273.35229	]	,
["Starostecki",	273.35598	]	,
["Sech",	273.35967	]	,
["Radziuk",	273.36336	]	,
["Pryba",	273.36705	]	,
["Pasiewicz",	273.37074	]	,
["Opila",	273.37443	]	,
["Nawloka",	273.37812	]	,
["Morski",	273.38181	]	,
["Miniszewski",	273.3855	]	,
["Mendera",	273.38919	]	,
["Kurylak",	273.39288	]	,
["Kurak",	273.39657	]	,
["Krosnicki",	273.40026	]	,
["Kraczek",	273.40395	]	,
["Korzybski",	273.40764	]	,
["Komada",	273.41133	]	,
["Kamoda",	273.41502	]	,
["Jesionka",	273.41871	]	,
["Janyga",	273.4224	]	,
["Holona",	273.42609	]	,
["Hejnowski",	273.42978	]	,
["Habas",	273.43347	]	,
["Guzicki",	273.43716	]	,
["Grzeczka",	273.44085	]	,
["Gnitecki",	273.44454	]	,
["Gibaszek",	273.44823	]	,
["Galej",	273.45192	]	,
["Franciszkiewicz",	273.45561	]	,
["Firkowski",	273.4593	]	,
["Falandysz",	273.46299	]	,
["Domogala",	273.46668	]	,
["Daszek",	273.47037	]	,
["Capek",	273.47406	]	,
["Bolechowski",	273.47775	]	,
["Barszczyk",	273.48144	]	,
["Ataman",	273.48513	]	,
["Zdrada",	273.48881	]	,
["Wagrodzki",	273.49249	]	,
["Walos",	273.49617	]	,
["Wajnert",	273.49985	]	,
["Taraszewski",	273.50353	]	,
["Sron",	273.50721	]	,
["Szocinski",	273.51089	]	,
["Szlachetko",	273.51457	]	,
["Sobka",	273.51825	]	,
["Rembalski",	273.52193	]	,
["Pasionek",	273.52561	]	,
["Packo",	273.52929	]	,
["Nikitiuk",	273.53297	]	,
["Muranski",	273.53665	]	,
["Lancucki",	273.54033	]	,
["Lichy",	273.54401	]	,
["Lebiedz",	273.54769	]	,
["Kuczer",	273.55137	]	,
["Krzywosz",	273.55505	]	,
["Krosta",	273.55873	]	,
["Kopyra",	273.56241	]	,
["Klesk",	273.56609	]	,
["Karpa",	273.56977	]	,
["Kabot",	273.57345	]	,
["Hendel",	273.57713	]	,
["Grodowski",	273.58081	]	,
["Gilner",	273.58449	]	,
["Fludra",	273.58817	]	,
["Florysiak",	273.59185	]	,
["Dauksza",	273.59553	]	,
["Danik",	273.59921	]	,
["Cieslakowski",	273.60289	]	,
["Chylicki",	273.60657	]	,
["Chybinski",	273.61025	]	,
["Buksinski",	273.61393	]	,
["Bogusiak",	273.61761	]	,
["Bachor",	273.62129	]	,
["Zmudzin",	273.62496	]	,
["Zachcial",	273.62863	]	,
["Traczykowski",	273.6323	]	,
["Szarkowicz",	273.63597	]	,
["Spryszak",	273.63964	]	,
["Snopczynski",	273.64331	]	,
["Przybytek",	273.64698	]	,
["Popielnicki",	273.65065	]	,
["Pokrzywka",	273.65432	]	,
["Pieciak",	273.65799	]	,
["Pieprzycki",	273.66166	]	,
["Oboza",	273.66533	]	,
["Nogowski",	273.669	]	,
["Motala",	273.67267	]	,
["Mordzak",	273.67634	]	,
["Mincewicz",	273.68001	]	,
["Marchewicz",	273.68368	]	,
["Maliborski",	273.68735	]	,
["Magnowski",	273.69102	]	,
["Lawnik",	273.69469	]	,
["Laube",	273.69836	]	,
["Kosierkiewicz",	273.70203	]	,
["Korbanek",	273.7057	]	,
["Kononiuk",	273.70937	]	,
["Jaroszkiewicz",	273.71304	]	,
["Janosik",	273.71671	]	,
["Gwizdalski",	273.72038	]	,
["Góralewski",	273.72405	]	,
["Goclowski",	273.72772	]	,
["Gan",	273.73139	]	,
["Galadyk",	273.73506	]	,
["Frymark",	273.73873	]	,
["Frencel",	273.7424	]	,
["Derylak",	273.74607	]	,
["Cwiek",	273.74974	]	,
["Cichal",	273.75341	]	,
["Burandt",	273.75708	]	,
["Bras",	273.76075	]	,
["Bociaga",	273.76442	]	,
["Zelezik",	273.76808	]	,
["Ziomkowski",	273.77174	]	,
["Wrzalik",	273.7754	]	,
["Wasilczuk",	273.77906	]	,
["Wasak",	273.78272	]	,
["Sugalski",	273.78638	]	,
["Stelmaszuk",	273.79004	]	,
["Sluszniak",	273.7937	]	,
["Rojecki",	273.79736	]	,
["Pulchny",	273.80102	]	,
["Przybylka",	273.80468	]	,
["Pawul",	273.80834	]	,
["Parzynski",	273.812	]	,
["Owca",	273.81566	]	,
["Oron",	273.81932	]	,
["Myszynski",	273.82298	]	,
["Moska",	273.82664	]	,
["Milas",	273.8303	]	,
["Lobaczewski",	273.83396	]	,
["Ludian",	273.83762	]	,
["Kostusiak",	273.84128	]	,
["Klepuszewski",	273.84494	]	,
["Kimla",	273.8486	]	,
["Kakareko",	273.85226	]	,
["Jasiówka",	273.85592	]	,
["Jasinowski",	273.85958	]	,
["Jacunski",	273.86324	]	,
["Jachimski",	273.8669	]	,
["Hryszko",	273.87056	]	,
["Hajkowski",	273.87422	]	,
["Guziolek",	273.87788	]	,
["Grzegorzyca",	273.88154	]	,
["Gronostajski",	273.8852	]	,
["Gajdecki",	273.88886	]	,
["Czopor",	273.89252	]	,
["Ciebiera",	273.89618	]	,
["Bielatowicz",	273.89984	]	,
["Walków",	273.90349	]	,
["Uniejewski",	273.90714	]	,
["Trzeciakowski",	273.91079	]	,
["Trybulec",	273.91444	]	,
["Trusewicz",	273.91809	]	,
["Topyla",	273.92174	]	,
["Teodorczuk",	273.92539	]	,
["Strawinski",	273.92904	]	,
["Stolarczuk",	273.93269	]	,
["Sadzikowski",	273.93634	]	,
["Rogus",	273.93999	]	,
["Rajter",	273.94364	]	,
["Pózniak",	273.94729	]	,
["Polowniak",	273.95094	]	,
["Podolecki",	273.95459	]	,
["Nowecki",	273.95824	]	,
["Niezborala",	273.96189	]	,
["Najmowicz",	273.96554	]	,
["Miszczynski",	273.96919	]	,
["Mijal",	273.97284	]	,
["Lojszczyk",	273.97649	]	,
["Luzar",	273.98014	]	,
["Leszczyszyn",	273.98379	]	,
["Lesiczka",	273.98744	]	,
["Krafczyk",	273.99109	]	,
["Kost",	273.99474	]	,
["Koguciuk",	273.99839	]	,
["Kasiarz",	274.00204	]	,
["Kamuda",	274.00569	]	,
["Kalkowski",	274.00934	]	,
["Grzadziela",	274.01299	]	,
["Gaszcz",	274.01664	]	,
["Gabler",	274.02029	]	,
["Fecko",	274.02394	]	,
["Falkus",	274.02759	]	,
["Dziadzio",	274.03124	]	,
["Duszyk",	274.03489	]	,
["Daszczyk",	274.03854	]	,
["Cydejko",	274.04219	]	,
["Cinal",	274.04584	]	,
["Cienciala",	274.04949	]	,
["Chudyk",	274.05314	]	,
["Chorazewski",	274.05679	]	,
["Bujara",	274.06044	]	,
["Boronczyk",	274.06409	]	,
["Bilyk",	274.06774	]	,
["Bartula",	274.07139	]	,
["Adamusiak",	274.07504	]	,
["Zebracki",	274.07868	]	,
["Wrótniak",	274.08232	]	,
["Tomaszczuk",	274.08596	]	,
["Szymonowicz",	274.0896	]	,
["Szenk",	274.09324	]	,
["Suszczewicz",	274.09688	]	,
["Slezak",	274.10052	]	,
["Skroban",	274.10416	]	,
["Rodek",	274.1078	]	,
["Prochota",	274.11144	]	,
["Porczyk",	274.11508	]	,
["Pilkowski",	274.11872	]	,
["Perlikowski",	274.12236	]	,
["Omasta",	274.126	]	,
["Nowakiewicz",	274.12964	]	,
["Modras",	274.13328	]	,
["Mioduchowski",	274.13692	]	,
["Ladosz",	274.14056	]	,
["Lubszczyk",	274.1442	]	,
["Libner",	274.14784	]	,
["Lefik",	274.15148	]	,
["Laskiewicz",	274.15512	]	,
["Laprus",	274.15876	]	,
["Kucaj",	274.1624	]	,
["Krutul",	274.16604	]	,
["Kacprzynski",	274.16968	]	,
["Jocz",	274.17332	]	,
["Hassa",	274.17696	]	,
["Harat",	274.1806	]	,
["Grocki",	274.18424	]	,
["Gertner",	274.18788	]	,
["Dluzynski",	274.19152	]	,
["Dejnek",	274.19516	]	,
["Czurak",	274.1988	]	,
["Czerczak",	274.20244	]	,
["Ciombor",	274.20608	]	,
["Chmaj",	274.20972	]	,
["Chlopik",	274.21336	]	,
["Bryski",	274.217	]	,
["Bezubik",	274.22064	]	,
["Zdzienicki",	274.22427	]	,
["Zbyrad",	274.2279	]	,
["Wysiecki",	274.23153	]	,
["Wiezowski",	274.23516	]	,
["Widelka",	274.23879	]	,
["Wec",	274.24242	]	,
["Werkowski",	274.24605	]	,
["Warzywoda",	274.24968	]	,
["Tymecki",	274.25331	]	,
["Tubacki",	274.25694	]	,
["Tietz",	274.26057	]	,
["Rawinski",	274.2642	]	,
["Opach",	274.26783	]	,
["Okulewicz",	274.27146	]	,
["Ochnicki",	274.27509	]	,
["Naglowski",	274.27872	]	,
["Matenka",	274.28235	]	,
["Mardyla",	274.28598	]	,
["Lodygowski",	274.28961	]	,
["Latarski",	274.29324	]	,
["Korwel",	274.29687	]	,
["Koput",	274.3005	]	,
["Jus",	274.30413	]	,
["Hryniuk",	274.30776	]	,
["Gardian",	274.31139	]	,
["Froncek",	274.31502	]	,
["Englert",	274.31865	]	,
["Dwulit",	274.32228	]	,
["Deputat",	274.32591	]	,
["Beldowski",	274.32954	]	,
["Ast",	274.33317	]	,
["Szaja",	274.33679	]	,
["Szafer",	274.34041	]	,
["Surdy",	274.34403	]	,
["Siewior",	274.34765	]	,
["Sabela",	274.35127	]	,
["Rzymkowski",	274.35489	]	,
["Pliszczynski",	274.35851	]	,
["Pierzyna",	274.36213	]	,
["Pielok",	274.36575	]	,
["Moczala",	274.36937	]	,
["Misa",	274.37299	]	,
["Materla",	274.37661	]	,
["Libuda",	274.38023	]	,
["Kroc",	274.38385	]	,
["Kotrys",	274.38747	]	,
["Kosiór",	274.39109	]	,
["Korczakowski",	274.39471	]	,
["Kolosinski",	274.39833	]	,
["Kitel",	274.40195	]	,
["Justyna",	274.40557	]	,
["Idzior",	274.40919	]	,
["Hrycaj",	274.41281	]	,
["Gryzlo",	274.41643	]	,
["Flisinski",	274.42005	]	,
["Dudus",	274.42367	]	,
["Dowgiert",	274.42729	]	,
["Czyzniewski",	274.43091	]	,
["Czepczor",	274.43453	]	,
["Chodowiec",	274.43815	]	,
["Chamier-Gliszczynski",	274.44177	]	,
["Cejmer",	274.44539	]	,
["Bychowski",	274.44901	]	,
["Bialucha",	274.45263	]	,
["Bassa",	274.45625	]	,
["Zagrabski",	274.45986	]	,
["Wojtusiak",	274.46347	]	,
["Turala",	274.46708	]	,
["Truszkiewicz",	274.47069	]	,
["Toronczak",	274.4743	]	,
["Talarski",	274.47791	]	,
["Szurlej",	274.48152	]	,
["Stezala",	274.48513	]	,
["Stelmachowski",	274.48874	]	,
["Popenda",	274.49235	]	,
["Ogloza",	274.49596	]	,
["Motowidlo",	274.49957	]	,
["Mlodziejewski",	274.50318	]	,
["Mierkiewicz",	274.50679	]	,
["Lesniarek",	274.5104	]	,
["Leszczewski",	274.51401	]	,
["Kolis",	274.51762	]	,
["Kiper",	274.52123	]	,
["Kic",	274.52484	]	,
["Kaplita",	274.52845	]	,
["Jakowski",	274.53206	]	,
["Gladyszewski",	274.53567	]	,
["Fak",	274.53928	]	,
["Dziubich",	274.54289	]	,
["Dziasek",	274.5465	]	,
["Debowczyk",	274.55011	]	,
["Czajowski",	274.55372	]	,
["Cyranski",	274.55733	]	,
["Cieszewski",	274.56094	]	,
["Chyl",	274.56455	]	,
["Chruslinski",	274.56816	]	,
["Choczaj",	274.57177	]	,
["Chadrys",	274.57538	]	,
["Bulkowski",	274.57899	]	,
["Bijata",	274.5826	]	,
["Ziarkiewicz",	274.5862	]	,
["Zabiegala",	274.5898	]	,
["Wytrykowski",	274.5934	]	,
["Wycech",	274.597	]	,
["Wilski",	274.6006	]	,
["Wachowiec",	274.6042	]	,
["Turbanski",	274.6078	]	,
["Torz",	274.6114	]	,
["Templin",	274.615	]	,
["Szubartowski",	274.6186	]	,
["Sychowicz",	274.6222	]	,
["Strupiechowski",	274.6258	]	,
["Staszków",	274.6294	]	,
["Stachanczyk",	274.633	]	,
["Spalinski",	274.6366	]	,
["Sipowicz",	274.6402	]	,
["Sadko",	274.6438	]	,
["Przybecki",	274.6474	]	,
["Podlasiak",	274.651	]	,
["Pawlata",	274.6546	]	,
["Pandel",	274.6582	]	,
["Otorowski",	274.6618	]	,
["Ostropolski",	274.6654	]	,
["Olbrich",	274.669	]	,
["Nuszkiewicz",	274.6726	]	,
["Niejadlik",	274.6762	]	,
["Mosiewicz",	274.6798	]	,
["Micewicz",	274.6834	]	,
["Meier",	274.687	]	,
["Lubiak",	274.6906	]	,
["Kurzynoga",	274.6942	]	,
["Kurleto",	274.6978	]	,
["Kuklewicz",	274.7014	]	,
["Koseski",	274.705	]	,
["Jamrozinski",	274.7086	]	,
["Helwich",	274.7122	]	,
["Figiela",	274.7158	]	,
["Czuk",	274.7194	]	,
["Czartoryski",	274.723	]	,
["Ciasnocha",	274.7266	]	,
["Burdziak",	274.7302	]	,
["Boguslaw",	274.7338	]	,
["Bobrek",	274.7374	]	,
["Bartyla",	274.741	]	,
["Alchimowicz",	274.7446	]	,
["Zdrenka",	274.74819	]	,
["Zdankiewicz",	274.75178	]	,
["Wabnic",	274.75537	]	,
["Srodon",	274.75896	]	,
["Szpinda",	274.76255	]	,
["Szolucha",	274.76614	]	,
["Sodel",	274.76973	]	,
["Sochalski",	274.77332	]	,
["Skruch",	274.77691	]	,
["Sierpowski",	274.7805	]	,
["Roslonek",	274.78409	]	,
["Rachon",	274.78768	]	,
["Przydzial",	274.79127	]	,
["Prochera",	274.79486	]	,
["Pielas",	274.79845	]	,
["Perucki",	274.80204	]	,
["Palma",	274.80563	]	,
["Olewicz",	274.80922	]	,
["Olchowy",	274.81281	]	,
["Nosewicz",	274.8164	]	,
["Nawotka",	274.81999	]	,
["Milkiewicz",	274.82358	]	,
["Mieczynski",	274.82717	]	,
["Micyk",	274.83076	]	,
["Mamak",	274.83435	]	,
["Mala",	274.83794	]	,
["Laskowski",	274.84153	]	,
["Lasek",	274.84512	]	,
["Kujawka",	274.84871	]	,
["Kotas",	274.8523	]	,
["Korbal",	274.85589	]	,
["Kocój",	274.85948	]	,
["Kajszczak",	274.86307	]	,
["Guga",	274.86666	]	,
["Golian",	274.87025	]	,
["Gadzinowski",	274.87384	]	,
["Fas",	274.87743	]	,
["Domaros",	274.88102	]	,
["Dolat",	274.88461	]	,
["Darski",	274.8882	]	,
["Cygankiewicz",	274.89179	]	,
["Cudzik",	274.89538	]	,
["Bonna",	274.89897	]	,
["Balak",	274.90256	]	,
["Agacki",	274.90615	]	,
["Zulinski",	274.90973	]	,
["Zjawin",	274.91331	]	,
["Sliwakowski",	274.91689	]	,
["Szynol",	274.92047	]	,
["Stasicki",	274.92405	]	,
["Serwan",	274.92763	]	,
["Salajczyk",	274.93121	]	,
["Pustul",	274.93479	]	,
["Puchta",	274.93837	]	,
["Piekielniak",	274.94195	]	,
["Parfieniuk",	274.94553	]	,
["Pamieta",	274.94911	]	,
["Oset",	274.95269	]	,
["Opieka",	274.95627	]	,
["Mitkowski",	274.95985	]	,
["Mistal",	274.96343	]	,
["Masiarek",	274.96701	]	,
["Lasia",	274.97059	]	,
["Kulasza",	274.97417	]	,
["Kucaba",	274.97775	]	,
["Kosiacki",	274.98133	]	,
["Klas",	274.98491	]	,
["Kiermasz",	274.98849	]	,
["Kiecana",	274.99207	]	,
["Jedraszczak",	274.99565	]	,
["Gruzlewski",	274.99923	]	,
["Gradek",	275.00281	]	,
["Gawle",	275.00639	]	,
["Franczuk",	275.00997	]	,
["Foremski",	275.01355	]	,
["Dymarek",	275.01713	]	,
["Cieszko",	275.02071	]	,
["Chalat",	275.02429	]	,
["Bryczkowski",	275.02787	]	,
["Brajczewski",	275.03145	]	,
["Bijok",	275.03503	]	,
["Bernecki",	275.03861	]	,
["August",	275.04219	]	,
["Zeglinski",	275.04576	]	,
["Znaniecki",	275.04933	]	,
["Zagawa",	275.0529	]	,
["Wolsza",	275.05647	]	,
["Wasilkowski",	275.06004	]	,
["Warcholinski",	275.06361	]	,
["Trojanski",	275.06718	]	,
["Telenga",	275.07075	]	,
["Tajchman",	275.07432	]	,
["Szyk",	275.07789	]	,
["Szlas",	275.08146	]	,
["Surmanski",	275.08503	]	,
["Strozek",	275.0886	]	,
["Stasiek",	275.09217	]	,
["Stawik",	275.09574	]	,
["Siejek",	275.09931	]	,
["Senderski",	275.10288	]	,
["Rolla",	275.10645	]	,
["Roicki",	275.11002	]	,
["Prokopek",	275.11359	]	,
["Poturalski",	275.11716	]	,
["Okupniak",	275.12073	]	,
["Nasiadek",	275.1243	]	,
["Misiurski",	275.12787	]	,
["Matysiuk",	275.13144	]	,
["Mamot",	275.13501	]	,
["Magalski",	275.13858	]	,
["Machajewski",	275.14215	]	,
["Lacheta",	275.14572	]	,
["Larwa",	275.14929	]	,
["Kuron",	275.15286	]	,
["Kroker",	275.15643	]	,
["Klimasara",	275.16	]	,
["Kielkowicz",	275.16357	]	,
["Kazmierkiewicz",	275.16714	]	,
["Karlik",	275.17071	]	,
["Kargulewicz",	275.17428	]	,
["Jadacki",	275.17785	]	,
["Hyski",	275.18142	]	,
["Gumna",	275.18499	]	,
["Gromadka",	275.18856	]	,
["Golubski",	275.19213	]	,
["Gmyz",	275.1957	]	,
["Gebczynski",	275.19927	]	,
["Frasek",	275.20284	]	,
["Ciurla",	275.20641	]	,
["Cioczek",	275.20998	]	,
["Ciepielowski",	275.21355	]	,
["Ciechonski",	275.21712	]	,
["Boguszynski",	275.22069	]	,
["Binias",	275.22426	]	,
["Belkot",	275.22783	]	,
["Bauza",	275.2314	]	,
["Zychowicz",	275.23496	]	,
["Zbiec",	275.23852	]	,
["Zagata",	275.24208	]	,
["Wójciuk",	275.24564	]	,
["Wasyl",	275.2492	]	,
["Sladewski",	275.25276	]	,
["Szwajnoch",	275.25632	]	,
["Szczodrak",	275.25988	]	,
["Rygalski",	275.26344	]	,
["Pijanka",	275.267	]	,
["Patynski",	275.27056	]	,
["Odolinski",	275.27412	]	,
["Obal",	275.27768	]	,
["Mruczek",	275.28124	]	,
["Moskalewicz",	275.2848	]	,
["Mlodozeniec",	275.28836	]	,
["Mironowicz",	275.29192	]	,
["Mela",	275.29548	]	,
["Maslany",	275.29904	]	,
["Lazorczyk",	275.3026	]	,
["Lehman",	275.30616	]	,
["Lawrenc",	275.30972	]	,
["Kurkus",	275.31328	]	,
["Kud",	275.31684	]	,
["Kuchnia",	275.3204	]	,
["Kubarski",	275.32396	]	,
["Kolczyk",	275.32752	]	,
["Kloskowski",	275.33108	]	,
["Kalis",	275.33464	]	,
["Humiecki",	275.3382	]	,
["Haluch",	275.34176	]	,
["Gozdzicki",	275.34532	]	,
["Garbat",	275.34888	]	,
["Figlewicz",	275.35244	]	,
["Fedko",	275.356	]	,
["Damaziak",	275.35956	]	,
["Ciurkot",	275.36312	]	,
["Zubko",	275.36667	]	,
["Zadura",	275.37022	]	,
["Woloszka",	275.37377	]	,
["Wencki",	275.37732	]	,
["Wawrzykowski",	275.38087	]	,
["Waledziak",	275.38442	]	,
["Uszok",	275.38797	]	,
["Tylak",	275.39152	]	,
["Trzeciecki",	275.39507	]	,
["Swiatnicki",	275.39862	]	,
["Szaton",	275.40217	]	,
["Rochalski",	275.40572	]	,
["Pogan",	275.40927	]	,
["Pisanko",	275.41282	]	,
["Paleczna",	275.41637	]	,
["Pacholarz",	275.41992	]	,
["Oberski",	275.42347	]	,
["Niewadzi",	275.42702	]	,
["Mochol",	275.43057	]	,
["Misz",	275.43412	]	,
["Marchwiak",	275.43767	]	,
["Macioszczyk",	275.44122	]	,
["Lysio",	275.44477	]	,
["Lubak",	275.44832	]	,
["Krukar",	275.45187	]	,
["Kosciuczyk",	275.45542	]	,
["Koszarski",	275.45897	]	,
["Kordys",	275.46252	]	,
["Klemba",	275.46607	]	,
["Jedrocha",	275.46962	]	,
["Jachim",	275.47317	]	,
["Czaniecki",	275.47672	]	,
["Chomentowski",	275.48027	]	,
["Biszczak",	275.48382	]	,
["Zietala",	275.48736	]	,
["Zglobicki",	275.4909	]	,
["Strugalski",	275.49444	]	,
["Stelmaszczuk",	275.49798	]	,
["Stangreciak",	275.50152	]	,
["Sobór",	275.50506	]	,
["Rumianowski",	275.5086	]	,
["Porczak",	275.51214	]	,
["Polaszewski",	275.51568	]	,
["Podsiad",	275.51922	]	,
["Palenga",	275.52276	]	,
["Oscilowski",	275.5263	]	,
["Niechwiadowicz",	275.52984	]	,
["Nagórny",	275.53338	]	,
["Moryson",	275.53692	]	,
["Magrys",	275.54046	]	,
["Lekan",	275.544	]	,
["Kuwalek",	275.54754	]	,
["Kliks",	275.55108	]	,
["Kikut",	275.55462	]	,
["Katafiasz",	275.55816	]	,
["Karpus",	275.5617	]	,
["Kalitka",	275.56524	]	,
["Grembowski",	275.56878	]	,
["Granosik",	275.57232	]	,
["Gnieciak",	275.57586	]	,
["Gierasinski",	275.5794	]	,
["Gastol",	275.58294	]	,
["Dubrawski",	275.58648	]	,
["Dospial",	275.59002	]	,
["Danielik",	275.59356	]	,
["Cichuta",	275.5971	]	,
["Chochorowski",	275.60064	]	,
["Zaslona",	275.60417	]	,
["Wrembel",	275.6077	]	,
["Wisnioch",	275.61123	]	,
["Waryszak",	275.61476	]	,
["Sztachelski",	275.61829	]	,
["Szpilewski",	275.62182	]	,
["Szkudlarski",	275.62535	]	,
["Strzesak",	275.62888	]	,
["Strojecki",	275.63241	]	,
["Stepniowski",	275.63594	]	,
["Senderek",	275.63947	]	,
["Samojluk",	275.643	]	,
["Rzepnikowski",	275.64653	]	,
["Pycki",	275.65006	]	,
["Psonka",	275.65359	]	,
["Pinkowicz",	275.65712	]	,
["Pazdzierski",	275.66065	]	,
["Pabjan",	275.66418	]	,
["Pabijan",	275.66771	]	,
["Okularczyk",	275.67124	]	,
["Nogajczyk",	275.67477	]	,
["Nagly",	275.6783	]	,
["Mulik",	275.68183	]	,
["Mostowik",	275.68536	]	,
["Mital",	275.68889	]	,
["Miech",	275.69242	]	,
["Lepa",	275.69595	]	,
["Lekawa",	275.69948	]	,
["Kurcewicz",	275.70301	]	,
["Kolada",	275.70654	]	,
["Kluczniok",	275.71007	]	,
["Kawik",	275.7136	]	,
["Karasiak",	275.71713	]	,
["Juszko",	275.72066	]	,
["Jonczak",	275.72419	]	,
["Hartwig",	275.72772	]	,
["Hamela",	275.73125	]	,
["Gora",	275.73478	]	,
["Filimoniuk",	275.73831	]	,
["Doskocz",	275.74184	]	,
["Cierpicki",	275.74537	]	,
["Brak",	275.7489	]	,
["Baszkiewicz",	275.75243	]	,
["Zychla",	275.75595	]	,
["Walenczak",	275.75947	]	,
["Tulik",	275.76299	]	,
["Szmanda",	275.76651	]	,
["Sutula",	275.77003	]	,
["Sosik",	275.77355	]	,
["Siódmiak",	275.77707	]	,
["Reczkowski",	275.78059	]	,
["Reinke",	275.78411	]	,
["Raducki",	275.78763	]	,
["Oszczeda",	275.79115	]	,
["Orski",	275.79467	]	,
["Olesniewicz",	275.79819	]	,
["Olbert",	275.80171	]	,
["Odziomek",	275.80523	]	,
["Nazarkiewicz",	275.80875	]	,
["Lelewski",	275.81227	]	,
["Leder",	275.81579	]	,
["Krueger",	275.81931	]	,
["Kozal",	275.82283	]	,
["Kondras",	275.82635	]	,
["Koloczek",	275.82987	]	,
["Kleczka",	275.83339	]	,
["Kalat",	275.83691	]	,
["Juchacz",	275.84043	]	,
["Jóskowski",	275.84395	]	,
["Jedryszczak",	275.84747	]	,
["Giczewski",	275.85099	]	,
["Gatkowski",	275.85451	]	,
["Gardas",	275.85803	]	,
["Fryda",	275.86155	]	,
["Frach",	275.86507	]	,
["Ferus",	275.86859	]	,
["Dzioch",	275.87211	]	,
["Dworzecki",	275.87563	]	,
["Dudziuk",	275.87915	]	,
["Ciszowski",	275.88267	]	,
["Cerazy",	275.88619	]	,
["Cepak",	275.88971	]	,
["Brytan",	275.89323	]	,
["Bartold",	275.89675	]	,
["Balsam",	275.90027	]	,
["Aleksiewicz",	275.90379	]	,
["Zarnoch",	275.9073	]	,
["Zwardon",	275.91081	]	,
["Wludarski",	275.91432	]	,
["Wiesniak",	275.91783	]	,
["Wawrzon",	275.92134	]	,
["Wapinski",	275.92485	]	,
["Wacowski",	275.92836	]	,
["Utracki",	275.93187	]	,
["Tyras",	275.93538	]	,
["Tolkacz",	275.93889	]	,
["Tobera",	275.9424	]	,
["Szwichtenberg",	275.94591	]	,
["Szarlej",	275.94942	]	,
["Sudomir",	275.95293	]	,
["Stajszczak",	275.95644	]	,
["Sienko",	275.95995	]	,
["Siedlanowski",	275.96346	]	,
["Samolyk",	275.96697	]	,
["Rulewski",	275.97048	]	,
["Róznicki",	275.97399	]	,
["Przylecki",	275.9775	]	,
["Pniok",	275.98101	]	,
["Pionke",	275.98452	]	,
["Oblój",	275.98803	]	,
["Nyckowski",	275.99154	]	,
["Nos",	275.99505	]	,
["Mroziak",	275.99856	]	,
["Mozer",	276.00207	]	,
["Matuski",	276.00558	]	,
["Majdzik",	276.00909	]	,
["Lyskawka",	276.0126	]	,
["Losin",	276.01611	]	,
["Lassota",	276.01962	]	,
["Kuchno",	276.02313	]	,
["Kruszyk",	276.02664	]	,
["Kotlega",	276.03015	]	,
["Kilis",	276.03366	]	,
["Jarnutowski",	276.03717	]	,
["Hadrian",	276.04068	]	,
["Gugula",	276.04419	]	,
["Grudka",	276.0477	]	,
["Grech",	276.05121	]	,
["Gradkowski",	276.05472	]	,
["Gocal",	276.05823	]	,
["Glowania",	276.06174	]	,
["Gacioch",	276.06525	]	,
["Dzienisiewicz",	276.06876	]	,
["Dural",	276.07227	]	,
["Dubielak",	276.07578	]	,
["Derewecki",	276.07929	]	,
["Cichoracki",	276.0828	]	,
["Buchcic",	276.08631	]	,
["Bierzynski",	276.08982	]	,
["Bielan",	276.09333	]	,
["Bawor",	276.09684	]	,
["Wyczesany",	276.10034	]	,
["Witkos",	276.10384	]	,
["Taudul",	276.10734	]	,
["Susfal",	276.11084	]	,
["Suchanecki",	276.11434	]	,
["Starszak",	276.11784	]	,
["Smalarz",	276.12134	]	,
["Serewa",	276.12484	]	,
["Rzeminski",	276.12834	]	,
["Rydzek",	276.13184	]	,
["Ressel",	276.13534	]	,
["Ramian",	276.13884	]	,
["Pszonak",	276.14234	]	,
["Popa",	276.14584	]	,
["Piskala",	276.14934	]	,
["Penkala",	276.15284	]	,
["Pancewicz",	276.15634	]	,
["Orzelowski",	276.15984	]	,
["Mlonek",	276.16334	]	,
["Mlak",	276.16684	]	,
["Madetko",	276.17034	]	,
["Machulski",	276.17384	]	,
["Lysko",	276.17734	]	,
["Lickiewicz",	276.18084	]	,
["Kucieba",	276.18434	]	,
["Kolecki",	276.18784	]	,
["Kisiolek",	276.19134	]	,
["Gwozdziewicz",	276.19484	]	,
["Golynski",	276.19834	]	,
["Garczewski",	276.20184	]	,
["Galbarczyk",	276.20534	]	,
["Furmaga",	276.20884	]	,
["Dysarz",	276.21234	]	,
["Dunowski",	276.21584	]	,
["Duliban",	276.21934	]	,
["Dropinski",	276.22284	]	,
["Bursztynowicz",	276.22634	]	,
["Bojkowski",	276.22984	]	,
["Bochnacki",	276.23334	]	,
["Aleksiuk",	276.23684	]	,
["Waszewski",	276.24033	]	,
["Tylko",	276.24382	]	,
["Sus",	276.24731	]	,
["Smosarski",	276.2508	]	,
["Siudut",	276.25429	]	,
["Safin",	276.25778	]	,
["Rydzak",	276.26127	]	,
["Rozinski",	276.26476	]	,
["Przewodowski",	276.26825	]	,
["Prusakowski",	276.27174	]	,
["Polowy",	276.27523	]	,
["Paderewski",	276.27872	]	,
["Osipiak",	276.28221	]	,
["Nicieja",	276.2857	]	,
["Modelewski",	276.28919	]	,
["Moczadlo",	276.29268	]	,
["Misterkiewicz",	276.29617	]	,
["Mermer",	276.29966	]	,
["Maziarski",	276.30315	]	,
["Mathea",	276.30664	]	,
["Laganowski",	276.31013	]	,
["Lotka",	276.31362	]	,
["Laszczynski",	276.31711	]	,
["Krol",	276.3206	]	,
["Klobus",	276.32409	]	,
["Kadzielawa",	276.32758	]	,
["Kalmus",	276.33107	]	,
["Kadula",	276.33456	]	,
["Grochla",	276.33805	]	,
["Gestwa",	276.34154	]	,
["Gardzinski",	276.34503	]	,
["Dobrochowski",	276.34852	]	,
["Brykalski",	276.35201	]	,
["Bialota",	276.3555	]	,
["Besztak",	276.35899	]	,
["Bester",	276.36248	]	,
["Bartochowski",	276.36597	]	,
["Bartlomiejczak",	276.36946	]	,
["Zboch",	276.37294	]	,
["Wikiel",	276.37642	]	,
["Weglarczyk",	276.3799	]	,
["Utkowski",	276.38338	]	,
["Trembecki",	276.38686	]	,
["Szpilka",	276.39034	]	,
["Szleszynski",	276.39382	]	,
["Szalach",	276.3973	]	,
["Stepek",	276.40078	]	,
["Stelmasik",	276.40426	]	,
["Stasz",	276.40774	]	,
["Skucinski",	276.41122	]	,
["Sauer",	276.4147	]	,
["Rama",	276.41818	]	,
["Radaszewski",	276.42166	]	,
["Pyrtek",	276.42514	]	,
["Pruchniewicz",	276.42862	]	,
["Pojawa",	276.4321	]	,
["Pilot",	276.43558	]	,
["Ozimkowski",	276.43906	]	,
["Ogrodzinski",	276.44254	]	,
["Nowosinski",	276.44602	]	,
["Noch",	276.4495	]	,
["Nagi",	276.45298	]	,
["Lapkiewicz",	276.45646	]	,
["Kutylowski",	276.45994	]	,
["Kuszczak",	276.46342	]	,
["Kusaj",	276.4669	]	,
["Kulaczkowski",	276.47038	]	,
["Kamzol",	276.47386	]	,
["Jesionkowski",	276.47734	]	,
["Herbus",	276.48082	]	,
["Gorajek",	276.4843	]	,
["Gonta",	276.48778	]	,
["Gielec",	276.49126	]	,
["Garnys",	276.49474	]	,
["Dasiewicz",	276.49822	]	,
["Ciota",	276.5017	]	,
["Cioroch",	276.50518	]	,
["Chalas",	276.50866	]	,
["Bodurka",	276.51214	]	,
["Bacela",	276.51562	]	,
["Banasinski",	276.5191	]	,
["Baglaj",	276.52258	]	,
["Bachman",	276.52606	]	,
["Zymla",	276.52953	]	,
["Zabik",	276.533	]	,
["Zawiski",	276.53647	]	,
["Wypasek",	276.53994	]	,
["Wroczynski",	276.54341	]	,
["Woroszylo",	276.54688	]	,
["Wieronski",	276.55035	]	,
["Utnicki",	276.55382	]	,
["Tylutki",	276.55729	]	,
["Tyda",	276.56076	]	,
["Turniak",	276.56423	]	,
["Sadokierski",	276.5677	]	,
["Ryzewski",	276.57117	]	,
["Rypina",	276.57464	]	,
["Redzikowski",	276.57811	]	,
["Podsiadlik",	276.58158	]	,
["Plesiak",	276.58505	]	,
["Pionk",	276.58852	]	,
["Piestrak",	276.59199	]	,
["Pajkert",	276.59546	]	,
["Paciejewski",	276.59893	]	,
["Nieuzyla",	276.6024	]	,
["Mulak",	276.60587	]	,
["Mandziak",	276.60934	]	,
["Krystosik",	276.61281	]	,
["Koczor",	276.61628	]	,
["Koclega",	276.61975	]	,
["Kijko",	276.62322	]	,
["Hutek",	276.62669	]	,
["Glodzik",	276.63016	]	,
["Galeczka",	276.63363	]	,
["Flisowski",	276.6371	]	,
["Figzal",	276.64057	]	,
["Drozak",	276.64404	]	,
["Derlukiewicz",	276.64751	]	,
["Cholewski",	276.65098	]	,
["Bichta",	276.65445	]	,
["Abratkiewicz",	276.65792	]	,
["Zelik",	276.66138	]	,
["Wludarczyk",	276.66484	]	,
["Wlosowicz",	276.6683	]	,
["Wiazowski",	276.67176	]	,
["Welter",	276.67522	]	,
["Uramowski",	276.67868	]	,
["Trzepacz",	276.68214	]	,
["Taczkowski",	276.6856	]	,
["Szczyrbowski",	276.68906	]	,
["Szamborski",	276.69252	]	,
["Szaflarski",	276.69598	]	,
["Sochal",	276.69944	]	,
["Rauhut",	276.7029	]	,
["Próba",	276.70636	]	,
["Pfeiffer",	276.70982	]	,
["Pardyka",	276.71328	]	,
["Olownia",	276.71674	]	,
["Obrochta",	276.7202	]	,
["Noskiewicz",	276.72366	]	,
["Nierychlo",	276.72712	]	,
["Nakoneczny",	276.73058	]	,
["Liput",	276.73404	]	,
["Lelonkiewicz",	276.7375	]	,
["Kuczborski",	276.74096	]	,
["Kralka",	276.74442	]	,
["Kostuj",	276.74788	]	,
["Konderak",	276.75134	]	,
["Klaptocz",	276.7548	]	,
["Jedro",	276.75826	]	,
["Jacyno",	276.76172	]	,
["Halat",	276.76518	]	,
["Gulbicki",	276.76864	]	,
["Gronowicz",	276.7721	]	,
["Glaszczka",	276.77556	]	,
["Galczak",	276.77902	]	,
["Dujka",	276.78248	]	,
["Czaus",	276.78594	]	,
["Bruder",	276.7894	]	,
["Bosiak",	276.79286	]	,
["Bajszczak",	276.79632	]	,
["Alicki",	276.79978	]	,
["Zwirek",	276.80323	]	,
["Watorek",	276.80668	]	,
["Wasilek",	276.81013	]	,
["Tyra",	276.81358	]	,
["Tulin",	276.81703	]	,
["Trukawka",	276.82048	]	,
["Torka",	276.82393	]	,
["Swietoniowski",	276.82738	]	,
["Szromek",	276.83083	]	,
["Szczerbal",	276.83428	]	,
["Szablicki",	276.83773	]	,
["Skaba",	276.84118	]	,
["Sielewicz",	276.84463	]	,
["Sabuda",	276.84808	]	,
["Regiec",	276.85153	]	,
["Pawicki",	276.85498	]	,
["Olszowiec",	276.85843	]	,
["Obrocki",	276.86188	]	,
["Meksula",	276.86533	]	,
["Malkus",	276.86878	]	,
["Lajewski",	276.87223	]	,
["Kurczaba",	276.87568	]	,
["Kugler",	276.87913	]	,
["Komendera",	276.88258	]	,
["Koldej",	276.88603	]	,
["Karnia",	276.88948	]	,
["Kaniuka",	276.89293	]	,
["Józefacki",	276.89638	]	,
["Jodelka",	276.89983	]	,
["Irlik",	276.90328	]	,
["Gudel",	276.90673	]	,
["Gmaj",	276.91018	]	,
["Giedyk",	276.91363	]	,
["Gesek",	276.91708	]	,
["Figlarz",	276.92053	]	,
["Dolewski",	276.92398	]	,
["Czepiec",	276.92743	]	,
["Czader",	276.93088	]	,
["Ciostek",	276.93433	]	,
["Cichaczewski",	276.93778	]	,
["Chlon",	276.94123	]	,
["Bindas",	276.94468	]	,
["Bielenda",	276.94813	]	,
["Bialoszewski",	276.95158	]	,
["Antoszczak",	276.95503	]	,
["Zambrowski",	276.95847	]	,
["Wrotecki",	276.96191	]	,
["Wardynski",	276.96535	]	,
["Waczynski",	276.96879	]	,
["Uryszek",	276.97223	]	,
["Tambor",	276.97567	]	,
["Strycharczyk",	276.97911	]	,
["Stechly",	276.98255	]	,
["Sobieszczuk",	276.98599	]	,
["Samonek",	276.98943	]	,
["Ruczkowski",	276.99287	]	,
["Psuj",	276.99631	]	,
["Pryga",	276.99975	]	,
["Podkowski",	277.00319	]	,
["Plaszczynski",	277.00663	]	,
["Pertkiewicz",	277.01007	]	,
["Pelkowski",	277.01351	]	,
["Parczynski",	277.01695	]	,
["Muszak",	277.02039	]	,
["Milner",	277.02383	]	,
["Milczynski",	277.02727	]	,
["Mazewski",	277.03071	]	,
["Matan",	277.03415	]	,
["Mamzer",	277.03759	]	,
["Leczynski",	277.04103	]	,
["Laniecki",	277.04447	]	,
["Liwoch",	277.04791	]	,
["Lesz",	277.05135	]	,
["Kruzycki",	277.05479	]	,
["Kropisz",	277.05823	]	,
["Koltunowicz",	277.06167	]	,
["Klab",	277.06511	]	,
["Kedracki",	277.06855	]	,
["Juzwiak",	277.07199	]	,
["Jochemczyk",	277.07543	]	,
["Jedruszak",	277.07887	]	,
["Jarocha",	277.08231	]	,
["Góras",	277.08575	]	,
["Gebalski",	277.08919	]	,
["Flegel",	277.09263	]	,
["Dziwis",	277.09607	]	,
["Dzielski",	277.09951	]	,
["Duczkowski",	277.10295	]	,
["Drobina",	277.10639	]	,
["Dopke",	277.10983	]	,
["Debiak",	277.11327	]	,
["Bugajczyk",	277.11671	]	,
["Brenk",	277.12015	]	,
["Bracki",	277.12359	]	,
["Blus",	277.12703	]	,
["Barbarski",	277.13047	]	,
["Baranczuk",	277.13391	]	,
["Zamiar",	277.13734	]	,
["Zakowicz",	277.14077	]	,
["Wojcieszynski",	277.1442	]	,
["Wantulok",	277.14763	]	,
["Uczciwek",	277.15106	]	,
["Tolloczko",	277.15449	]	,
["Szczotkowski",	277.15792	]	,
["Surowiak",	277.16135	]	,
["Pukacki",	277.16478	]	,
["Porucznik",	277.16821	]	,
["Pochwatka",	277.17164	]	,
["Peron",	277.17507	]	,
["Pec",	277.1785	]	,
["Obrzud",	277.18193	]	,
["Nienartowicz",	277.18536	]	,
["Niedzinski",	277.18879	]	,
["Nawracala",	277.19222	]	,
["Michas",	277.19565	]	,
["Laczek",	277.19908	]	,
["Lanoszka",	277.20251	]	,
["Kuduk",	277.20594	]	,
["Krystian",	277.20937	]	,
["Kolos",	277.2128	]	,
["Kazimierowicz",	277.21623	]	,
["Haladaj",	277.21966	]	,
["Grablewski",	277.22309	]	,
["Gladych",	277.22652	]	,
["Drabent",	277.22995	]	,
["Dowejko",	277.23338	]	,
["Domalik",	277.23681	]	,
["Czempik",	277.24024	]	,
["Czado",	277.24367	]	,
["Cieplicki",	277.2471	]	,
["Buchert",	277.25053	]	,
["Biega",	277.25396	]	,
["Ziemianek",	277.25738	]	,
["Zabczynski",	277.2608	]	,
["Wyzgol",	277.26422	]	,
["Wojciuch",	277.26764	]	,
["Wlochowicz",	277.27106	]	,
["Wawrzynowski",	277.27448	]	,
["Sniecikowski",	277.2779	]	,
["Smierciak",	277.28132	]	,
["Sztobryn",	277.28474	]	,
["Szmajdzinski",	277.28816	]	,
["Szalecki",	277.29158	]	,
["Szalaty",	277.295	]	,
["Styka",	277.29842	]	,
["Steinborn",	277.30184	]	,
["Stabrawa",	277.30526	]	,
["Sosulski",	277.30868	]	,
["Soinski",	277.3121	]	,
["Reimus",	277.31552	]	,
["Pyplacz",	277.31894	]	,
["Polichnowski",	277.32236	]	,
["Okrojek",	277.32578	]	,
["Nabialczyk",	277.3292	]	,
["Marynczak",	277.33262	]	,
["Mann",	277.33604	]	,
["Macyszyn",	277.33946	]	,
["Lokiec",	277.34288	]	,
["Lagód",	277.3463	]	,
["Kucala",	277.34972	]	,
["Klaczak",	277.35314	]	,
["Kirsz",	277.35656	]	,
["Haratym",	277.35998	]	,
["Damrat",	277.3634	]	,
["Dajewski",	277.36682	]	,
["Czupala",	277.37024	]	,
["Ciesielczuk",	277.37366	]	,
["Chibowski",	277.37708	]	,
["Chabiniak",	277.3805	]	,
["Cabak",	277.38392	]	,
["Brogowski",	277.38734	]	,
["Blasinski",	277.39076	]	,
["Batory",	277.39418	]	,
["Abel",	277.3976	]	,
["Zysko",	277.40101	]	,
["Zóltak",	277.40442	]	,
["Zyra",	277.40783	]	,
["Zglenicki",	277.41124	]	,
["Zabaglo",	277.41465	]	,
["Werminski",	277.41806	]	,
["Tusznio",	277.42147	]	,
["Turko",	277.42488	]	,
["Sliwak",	277.42829	]	,
["Szustkiewicz",	277.4317	]	,
["Szpakowicz",	277.43511	]	,
["Stryjski",	277.43852	]	,
["Stacherczak",	277.44193	]	,
["Smurzynski",	277.44534	]	,
["Sendrowski",	277.44875	]	,
["Romaszewski",	277.45216	]	,
["Roguszka",	277.45557	]	,
["Rafalik",	277.45898	]	,
["Przyzycki",	277.46239	]	,
["Popielec",	277.4658	]	,
["Pietrasiewicz",	277.46921	]	,
["Muzyczuk",	277.47262	]	,
["Muziol",	277.47603	]	,
["Matuk",	277.47944	]	,
["Markulak",	277.48285	]	,
["Machalek",	277.48626	]	,
["Kuzaj",	277.48967	]	,
["Kosierb",	277.49308	]	,
["Konaszewski",	277.49649	]	,
["Klasinski",	277.4999	]	,
["Kazienko",	277.50331	]	,
["Karaczun",	277.50672	]	,
["Kaczocha",	277.51013	]	,
["Janeta",	277.51354	]	,
["Jamro",	277.51695	]	,
["Jachimiak",	277.52036	]	,
["Hacia",	277.52377	]	,
["Grzelaczyk",	277.52718	]	,
["Glagla",	277.53059	]	,
["Getler",	277.534	]	,
["Gajowy",	277.53741	]	,
["Frasz",	277.54082	]	,
["Domaniecki",	277.54423	]	,
["Czapkowski",	277.54764	]	,
["Cielas",	277.55105	]	,
["Bielewski",	277.55446	]	,
["Beling",	277.55787	]	,
["Zaganczyk",	277.56127	]	,
["Wloka",	277.56467	]	,
["Wisniak",	277.56807	]	,
["Warkocz",	277.57147	]	,
["Waksmundzki",	277.57487	]	,
["Tekien",	277.57827	]	,
["Tama",	277.58167	]	,
["Sysiak",	277.58507	]	,
["Suchorowski",	277.58847	]	,
["Smolucha",	277.59187	]	,
["Smogór",	277.59527	]	,
["Skrzat",	277.59867	]	,
["Sarad",	277.60207	]	,
["Puzia",	277.60547	]	,
["Nalaskowski",	277.60887	]	,
["Molczan",	277.61227	]	,
["Misztak",	277.61567	]	,
["Milecki",	277.61907	]	,
["Medon",	277.62247	]	,
["Matejak",	277.62587	]	,
["Lopuch",	277.62927	]	,
["Ludwa",	277.63267	]	,
["Lorkiewicz",	277.63607	]	,
["Lechocki",	277.63947	]	,
["Laszczka",	277.64287	]	,
["Kozlicki",	277.64627	]	,
["Konieczynski",	277.64967	]	,
["Kolberg",	277.65307	]	,
["Kocel",	277.65647	]	,
["Kikowski",	277.65987	]	,
["Kanas",	277.66327	]	,
["Kafara",	277.66667	]	,
["Kaczmarz",	277.67007	]	,
["Jarzecki",	277.67347	]	,
["Hareza",	277.67687	]	,
["Grelowski",	277.68027	]	,
["Gainski",	277.68367	]	,
["Forszpaniak",	277.68707	]	,
["Erazmus",	277.69047	]	,
["Dymski",	277.69387	]	,
["Darczuk",	277.69727	]	,
["Czujko",	277.70067	]	,
["Ciemniak",	277.70407	]	,
["Brzyszcz",	277.70747	]	,
["Brakoniecki",	277.71087	]	,
["Blum",	277.71427	]	,
["Andraka",	277.71767	]	,
["Aleszczyk",	277.72107	]	,
["Zwiewka",	277.72446	]	,
["Znój",	277.72785	]	,
["Zarzeka",	277.73124	]	,
["Zamkowski",	277.73463	]	,
["Wajszczuk",	277.73802	]	,
["Teresiak",	277.74141	]	,
["Tatarczuch",	277.7448	]	,
["Stefanko",	277.74819	]	,
["Starosz",	277.75158	]	,
["Smigielski",	277.75497	]	,
["Samburski",	277.75836	]	,
["Salanski",	277.76175	]	,
["Salewski",	277.76514	]	,
["Russ",	277.76853	]	,
["Rdest",	277.77192	]	,
["Pluskwa",	277.77531	]	,
["Patyra",	277.7787	]	,
["Orda",	277.78209	]	,
["Nierychlewski",	277.78548	]	,
["Nerka",	277.78887	]	,
["Lubek",	277.79226	]	,
["Leszkowski",	277.79565	]	,
["Legawiec",	277.79904	]	,
["Kuzimski",	277.80243	]	,
["Kurlej",	277.80582	]	,
["Kotnis",	277.80921	]	,
["Kochaniak",	277.8126	]	,
["Kieczka",	277.81599	]	,
["Halaburda",	277.81938	]	,
["Hain",	277.82277	]	,
["Freus",	277.82616	]	,
["Fliszkiewicz",	277.82955	]	,
["Dutkowiak",	277.83294	]	,
["Cyranowicz",	277.83633	]	,
["Bierski",	277.83972	]	,
["Wolkowycki",	277.8431	]	,
["Wojsz",	277.84648	]	,
["Waszczynski",	277.84986	]	,
["Walachowski",	277.85324	]	,
["Tyton",	277.85662	]	,
["Tesmer",	277.86	]	,
["Tatol",	277.86338	]	,
["Strutynski",	277.86676	]	,
["Smoderek",	277.87014	]	,
["Semla",	277.87352	]	,
["Sekular",	277.8769	]	,
["Reut",	277.88028	]	,
["Poneta",	277.88366	]	,
["Plota",	277.88704	]	,
["Pindelski",	277.89042	]	,
["Pilorz",	277.8938	]	,
["Parciak",	277.89718	]	,
["Pajecki",	277.90056	]	,
["Paduszynski",	277.90394	]	,
["Oczkos",	277.90732	]	,
["Nowikowski",	277.9107	]	,
["Najberg",	277.91408	]	,
["Moczkowski",	277.91746	]	,
["Massalski",	277.92084	]	,
["Laszczych",	277.92422	]	,
["Kosztyla",	277.9276	]	,
["Kica",	277.93098	]	,
["Izak",	277.93436	]	,
["Hurko",	277.93774	]	,
["Hliwa",	277.94112	]	,
["Grzejda",	277.9445	]	,
["Fiodorow",	277.94788	]	,
["Dziurowicz",	277.95126	]	,
["Drosik",	277.95464	]	,
["Dranka",	277.95802	]	,
["Danelski",	277.9614	]	,
["Czosnowski",	277.96478	]	,
["Czarniewski",	277.96816	]	,
["Cygler",	277.97154	]	,
["Chudas",	277.97492	]	,
["Burba",	277.9783	]	,
["Bulkowski",	277.98168	]	,
["Bralski",	277.98506	]	,
["Zarlok",	277.98843	]	,
["Ziemianin",	277.9918	]	,
["Zemlo",	277.99517	]	,
["Wykretowicz",	277.99854	]	,
["Wera",	278.00191	]	,
["Waruszewski",	278.00528	]	,
["Trzaskawka",	278.00865	]	,
["Tekielak",	278.01202	]	,
["Swietoslawski",	278.01539	]	,
["Sliwicki",	278.01876	]	,
["Strzesniewski",	278.02213	]	,
["Starkowski",	278.0255	]	,
["Skrzyp",	278.02887	]	,
["Sinica",	278.03224	]	,
["Przymus",	278.03561	]	,
["Piwecki",	278.03898	]	,
["Pietrzela",	278.04235	]	,
["Pieslak",	278.04572	]	,
["Otawa",	278.04909	]	,
["Niedopytalski",	278.05246	]	,
["Nalborski",	278.05583	]	,
["Mieszczynski",	278.0592	]	,
["Mielko",	278.06257	]	,
["Matejkowski",	278.06594	]	,
["Lyduch",	278.06931	]	,
["Luszkiewicz",	278.07268	]	,
["Lipert",	278.07605	]	,
["Kudlek",	278.07942	]	,
["Koleczek",	278.08279	]	,
["Kobierzynski",	278.08616	]	,
["Justa",	278.08953	]	,
["Janczarski",	278.0929	]	,
["Hryckiewicz",	278.09627	]	,
["Grych",	278.09964	]	,
["Gibka",	278.10301	]	,
["Ferster",	278.10638	]	,
["Deskiewicz",	278.10975	]	,
["Delewski",	278.11312	]	,
["Czucha",	278.11649	]	,
["Cyrzan",	278.11986	]	,
["Cyc",	278.12323	]	,
["Chetkowski",	278.1266	]	,
["Babis",	278.12997	]	,
["Zenka",	278.13333	]	,
["Zagrobelny",	278.13669	]	,
["Zagaja",	278.14005	]	,
["Wychowalek",	278.14341	]	,
["Wegorowski",	278.14677	]	,
["Twardziak",	278.15013	]	,
["Sztucki",	278.15349	]	,
["Szproch",	278.15685	]	,
["Sygit",	278.16021	]	,
["Sell",	278.16357	]	,
["Rudko",	278.16693	]	,
["Rogon",	278.17029	]	,
["Radziemski",	278.17365	]	,
["Presz",	278.17701	]	,
["Myczkowski",	278.18037	]	,
["Moritz",	278.18373	]	,
["Macznik",	278.18709	]	,
["Lechniak",	278.19045	]	,
["Laskiewicz",	278.19381	]	,
["Kulasinski",	278.19717	]	,
["Ksiezarczyk",	278.20053	]	,
["Krzeslak",	278.20389	]	,
["Krezymon",	278.20725	]	,
["Koslacz",	278.21061	]	,
["Kaszczyszyn",	278.21397	]	,
["Janos",	278.21733	]	,
["Henkel",	278.22069	]	,
["Gliwka",	278.22405	]	,
["Farys",	278.22741	]	,
["Dwojacki",	278.23077	]	,
["Ciapka",	278.23413	]	,
["Chechlacz",	278.23749	]	,
["Babkiewicz",	278.24085	]	,
["Ziobron",	278.2442	]	,
["Zielinski",	278.24755	]	,
["Zagórny",	278.2509	]	,
["Wijatkowski",	278.25425	]	,
["Watrak",	278.2576	]	,
["Tomon",	278.26095	]	,
["Szegda",	278.2643	]	,
["Sycha",	278.26765	]	,
["Sieroslawski",	278.271	]	,
["Rzepczyk",	278.27435	]	,
["Regucki",	278.2777	]	,
["Ramiaczek",	278.28105	]	,
["Posadzy",	278.2844	]	,
["Podosek",	278.28775	]	,
["Podkalicki",	278.2911	]	,
["Pipowski",	278.29445	]	,
["Pintera",	278.2978	]	,
["Pilas",	278.30115	]	,
["Orzlowski",	278.3045	]	,
["Nurczyk",	278.30785	]	,
["Niebrzegowski",	278.3112	]	,
["Lowinski",	278.31455	]	,
["Laczka",	278.3179	]	,
["Lesnianski",	278.32125	]	,
["Koperkiewicz",	278.3246	]	,
["Kompala",	278.32795	]	,
["Kolucki",	278.3313	]	,
["Kanak",	278.33465	]	,
["Hanslik",	278.338	]	,
["Góreczny",	278.34135	]	,
["Gomola",	278.3447	]	,
["Gburczyk",	278.34805	]	,
["Galazkiewicz",	278.3514	]	,
["Estkowski",	278.35475	]	,
["Drzezdzon",	278.3581	]	,
["Dorocki",	278.36145	]	,
["Deron",	278.3648	]	,
["Dembkowski",	278.36815	]	,
["Chmielnik",	278.3715	]	,
["Calkowski",	278.37485	]	,
["Bibik",	278.3782	]	,
["Berezanski",	278.38155	]	,
["Berczynski",	278.3849	]	,
["Wilgucki",	278.38824	]	,
["Wachol",	278.39158	]	,
["Turlejski",	278.39492	]	,
["Slefarski",	278.39826	]	,
["Szepielak",	278.4016	]	,
["Stefanczuk",	278.40494	]	,
["Siwonia",	278.40828	]	,
["Siekaniec",	278.41162	]	,
["Ryciak",	278.41496	]	,
["Pedryc",	278.4183	]	,
["Paldyna",	278.42164	]	,
["Ozimina",	278.42498	]	,
["Murgrabia",	278.42832	]	,
["Moczygemba",	278.43166	]	,
["Medrzak",	278.435	]	,
["Maniszewski",	278.43834	]	,
["Luberadzki",	278.44168	]	,
["Lieske",	278.44502	]	,
["Lichacz",	278.44836	]	,
["Lechwar",	278.4517	]	,
["Lamik",	278.45504	]	,
["Kulawiec",	278.45838	]	,
["Kühn",	278.46172	]	,
["Kuchniak",	278.46506	]	,
["Krzaczynski",	278.4684	]	,
["Kozdrowski",	278.47174	]	,
["Karman",	278.47508	]	,
["Jednoróg",	278.47842	]	,
["Grzedzinski",	278.48176	]	,
["Grandys",	278.4851	]	,
["Gnojek",	278.48844	]	,
["Gilka",	278.49178	]	,
["Foltyn",	278.49512	]	,
["Filipiec",	278.49846	]	,
["Dytlow",	278.5018	]	,
["Dubert",	278.50514	]	,
["Ditrich",	278.50848	]	,
["Derlecki",	278.51182	]	,
["Dajer",	278.51516	]	,
["Berkowicz",	278.5185	]	,
["Bacal",	278.52184	]	,
["Alaszewski",	278.52518	]	,
["Zynek",	278.52851	]	,
["Zietak",	278.53184	]	,
["Zdziennicki",	278.53517	]	,
["Wyrzykiewicz",	278.5385	]	,
["Wybierala",	278.54183	]	,
["Wuwer",	278.54516	]	,
["Wtyklo",	278.54849	]	,
["Wólkiewicz",	278.55182	]	,
["Wiora",	278.55515	]	,
["Wielowski",	278.55848	]	,
["Walerianczyk",	278.56181	]	,
["Ulejczyk",	278.56514	]	,
["Tor",	278.56847	]	,
["Tarnas",	278.5718	]	,
["Seniuk",	278.57513	]	,
["Rupa",	278.57846	]	,
["Rot",	278.58179	]	,
["Radczuk",	278.58512	]	,
["Pukowiec",	278.58845	]	,
["Ploch",	278.59178	]	,
["Piorunowski",	278.59511	]	,
["Paczesniak",	278.59844	]	,
["Okarma",	278.60177	]	,
["Nowodworski",	278.6051	]	,
["Nachman",	278.60843	]	,
["Mleko",	278.61176	]	,
["Miszke",	278.61509	]	,
["Marszalkiewicz",	278.61842	]	,
["Lysikowski",	278.62175	]	,
["Lijewski",	278.62508	]	,
["Leszczyna",	278.62841	]	,
["Lechman",	278.63174	]	,
["Kotek",	278.63507	]	,
["Klupczynski",	278.6384	]	,
["Karolik",	278.64173	]	,
["Kalecki",	278.64506	]	,
["Jedroszkowiak",	278.64839	]	,
["Gulan",	278.65172	]	,
["Gralewicz",	278.65505	]	,
["Golombek",	278.65838	]	,
["Giszczak",	278.66171	]	,
["Gidaszewski",	278.66504	]	,
["Gandecki",	278.66837	]	,
["Figaszewski",	278.6717	]	,
["Demków",	278.67503	]	,
["Chwalik",	278.67836	]	,
["Chalubinski",	278.68169	]	,
["Cedrowski",	278.68502	]	,
["Boberek",	278.68835	]	,
["Babut",	278.69168	]	,
["Zyluk",	278.695	]	,
["Zeleznik",	278.69832	]	,
["Ziebura",	278.70164	]	,
["Zenker",	278.70496	]	,
["Zagórna",	278.70828	]	,
["Wywrot",	278.7116	]	,
["Wiwatowski",	278.71492	]	,
["Wandachowicz",	278.71824	]	,
["Walko",	278.72156	]	,
["Smielak",	278.72488	]	,
["Smolis",	278.7282	]	,
["Sieradz",	278.73152	]	,
["Rysiak",	278.73484	]	,
["Pulinski",	278.73816	]	,
["Prill",	278.74148	]	,
["Polowiec",	278.7448	]	,
["Pokropek",	278.74812	]	,
["Pocztowski",	278.75144	]	,
["Plazinski",	278.75476	]	,
["Patej",	278.75808	]	,
["Niciak",	278.7614	]	,
["Nastalek",	278.76472	]	,
["Mulawka",	278.76804	]	,
["Milosek",	278.77136	]	,
["Leszka",	278.77468	]	,
["Kozyczkowski",	278.778	]	,
["Kocieda",	278.78132	]	,
["Kochowski",	278.78464	]	,
["Kirejczyk",	278.78796	]	,
["Kiljanek",	278.79128	]	,
["Kicior",	278.7946	]	,
["Halski",	278.79792	]	,
["Gerula",	278.80124	]	,
["Gacon",	278.80456	]	,
["Dziarkowski",	278.80788	]	,
["Dolik",	278.8112	]	,
["Dobruchowski",	278.81452	]	,
["Dlugon",	278.81784	]	,
["Danis",	278.82116	]	,
["Bychawski",	278.82448	]	,
["Bacinski",	278.8278	]	,
["Zakolski",	278.83111	]	,
["Wasiel",	278.83442	]	,
["Wazydrag",	278.83773	]	,
["Wasek",	278.84104	]	,
["Walczyk",	278.84435	]	,
["Urawski",	278.84766	]	,
["Tyliszczak",	278.85097	]	,
["Tomeczek",	278.85428	]	,
["Szalankiewicz",	278.85759	]	,
["Stacherski",	278.8609	]	,
["Rudny",	278.86421	]	,
["Pukacz",	278.86752	]	,
["Protas",	278.87083	]	,
["Pejas",	278.87414	]	,
["Pawlewski",	278.87745	]	,
["Pacynski",	278.88076	]	,
["Oszajca",	278.88407	]	,
["Opasinski",	278.88738	]	,
["Oliszewski",	278.89069	]	,
["Niewulis",	278.894	]	,
["Namiotko",	278.89731	]	,
["Muszkiet",	278.90062	]	,
["Mistewicz",	278.90393	]	,
["Mazuruk",	278.90724	]	,
["Lupicki",	278.91055	]	,
["Lichwala",	278.91386	]	,
["Leniec",	278.91717	]	,
["Kowalonek",	278.92048	]	,
["Kosim",	278.92379	]	,
["Kosel",	278.9271	]	,
["Klarkowski",	278.93041	]	,
["Klamrowski",	278.93372	]	,
["Kapinski",	278.93703	]	,
["Kapturkiewicz",	278.94034	]	,
["Hensel",	278.94365	]	,
["Fiutka",	278.94696	]	,
["Dziubala",	278.95027	]	,
["Dzikowicz",	278.95358	]	,
["Dydek",	278.95689	]	,
["Dejk",	278.9602	]	,
["Daszewski",	278.96351	]	,
["Darasz",	278.96682	]	,
["Cynk",	278.97013	]	,
["Charlinski",	278.97344	]	,
["Burzykowski",	278.97675	]	,
["Bluma",	278.98006	]	,
["Belowski",	278.98337	]	,
["Antoszkiewicz",	278.98668	]	,
["Zwierzyk",	278.98998	]	,
["Zagozdon",	278.99328	]	,
["Wolfram",	278.99658	]	,
["Werbinski",	278.99988	]	,
["Welnic",	279.00318	]	,
["Szubart",	279.00648	]	,
["Szpytma",	279.00978	]	,
["Szamburski",	279.01308	]	,
["Szala",	279.01638	]	,
["Soltyszewski",	279.01968	]	,
["Sioma",	279.02298	]	,
["Sekula",	279.02628	]	,
["Salaga",	279.02958	]	,
["Rysinski",	279.03288	]	,
["Pytlakowski",	279.03618	]	,
["Purchala",	279.03948	]	,
["Pupiec",	279.04278	]	,
["Psuja",	279.04608	]	,
["Popow",	279.04938	]	,
["Poliwka",	279.05268	]	,
["Polewaczyk",	279.05598	]	,
["Plotnicki",	279.05928	]	,
["Piliszek",	279.06258	]	,
["Paczek",	279.06588	]	,
["Oleniacz",	279.06918	]	,
["Nelke",	279.07248	]	,
["Mokwinski",	279.07578	]	,
["Miodowski",	279.07908	]	,
["Maczko",	279.08238	]	,
["Masiuk",	279.08568	]	,
["Lawnicki",	279.08898	]	,
["Krystecki",	279.09228	]	,
["Krezlewicz",	279.09558	]	,
["Kreczmanski",	279.09888	]	,
["Kocia",	279.10218	]	,
["Jahns",	279.10548	]	,
["Grzebula",	279.10878	]	,
["Figielski",	279.11208	]	,
["Dzierzek",	279.11538	]	,
["Drygas",	279.11868	]	,
["Drwecki",	279.12198	]	,
["Drogos",	279.12528	]	,
["Drejer",	279.12858	]	,
["Dadak",	279.13188	]	,
["Czempinski",	279.13518	]	,
["Cichorski",	279.13848	]	,
["Chamski",	279.14178	]	,
["Bozecki",	279.14508	]	,
["Andula",	279.14838	]	,
["Zebrak",	279.15167	]	,
["Ziemecki",	279.15496	]	,
["Zdral",	279.15825	]	,
["Zawiszewski",	279.16154	]	,
["Walisiak",	279.16483	]	,
["Tyrcha",	279.16812	]	,
["Tylczynski",	279.17141	]	,
["Trofimiuk",	279.1747	]	,
["Spica",	279.17799	]	,
["Szoltys",	279.18128	]	,
["Starmach",	279.18457	]	,
["Sofinski",	279.18786	]	,
["Siniecki",	279.19115	]	,
["Rzazewski",	279.19444	]	,
["Rymuza",	279.19773	]	,
["Rasek",	279.20102	]	,
["Rabczewski",	279.20431	]	,
["Pospieszynski",	279.2076	]	,
["Pinkiewicz",	279.21089	]	,
["Pilatowski",	279.21418	]	,
["Pieton",	279.21747	]	,
["Pietruk",	279.22076	]	,
["Patrzyk",	279.22405	]	,
["Nielipinski",	279.22734	]	,
["Kuzyk",	279.23063	]	,
["Kozaczek",	279.23392	]	,
["Kiryk",	279.23721	]	,
["Kierczak",	279.2405	]	,
["Joskowski",	279.24379	]	,
["Harendarz",	279.24708	]	,
["Grajko",	279.25037	]	,
["Góreczna",	279.25366	]	,
["Gornowicz",	279.25695	]	,
["Fryza",	279.26024	]	,
["Dziemianko",	279.26353	]	,
["Drewing",	279.26682	]	,
["Demidowicz",	279.27011	]	,
["Chwilkowski",	279.2734	]	,
["Chwal",	279.27669	]	,
["Brzeszcz",	279.27998	]	,
["Bodzon",	279.28327	]	,
["Blitek",	279.28656	]	,
["Andruchów",	279.28985	]	,
["Zarnowiecki",	279.29313	]	,
["Wower",	279.29641	]	,
["Wituszynski",	279.29969	]	,
["Wilde",	279.30297	]	,
["Wanecki",	279.30625	]	,
["Walerych",	279.30953	]	,
["Tlolka",	279.31281	]	,
["Szymanczuk",	279.31609	]	,
["Sobura",	279.31937	]	,
["Skrzyszewski",	279.32265	]	,
["Setla",	279.32593	]	,
["Sajko",	279.32921	]	,
["Rusznica",	279.33249	]	,
["Ropel",	279.33577	]	,
["Pyrak",	279.33905	]	,
["Prochot",	279.34233	]	,
["Potrawiak",	279.34561	]	,
["Piszczalka",	279.34889	]	,
["Panufnik",	279.35217	]	,
["Noczynski",	279.35545	]	,
["Mazela",	279.35873	]	,
["Maslo",	279.36201	]	,
["Marzewski",	279.36529	]	,
["Margiel",	279.36857	]	,
["Mamcarczyk",	279.37185	]	,
["Luczywek",	279.37513	]	,
["Liczmanski",	279.37841	]	,
["Kostrzebski",	279.38169	]	,
["Korfanty",	279.38497	]	,
["Jedryczko",	279.38825	]	,
["Jafra",	279.39153	]	,
["Gruber",	279.39481	]	,
["Gotkowski",	279.39809	]	,
["Goronski",	279.40137	]	,
["Golimowski",	279.40465	]	,
["Godziek",	279.40793	]	,
["Gluba",	279.41121	]	,
["Gardias",	279.41449	]	,
["Galanty",	279.41777	]	,
["Galanek",	279.42105	]	,
["Filochowski",	279.42433	]	,
["Filocha",	279.42761	]	,
["Falecki",	279.43089	]	,
["Duchna",	279.43417	]	,
["Doroszewski",	279.43745	]	,
["Ciezarek",	279.44073	]	,
["Chlastawa",	279.44401	]	,
["Calek",	279.44729	]	,
["Bialo",	279.45057	]	,
["Zlocki",	279.45384	]	,
["Wyrwicki",	279.45711	]	,
["Wochniak",	279.46038	]	,
["Skrzydlak",	279.46365	]	,
["Sieniek",	279.46692	]	,
["Saba",	279.47019	]	,
["Rytczak",	279.47346	]	,
["Rominski",	279.47673	]	,
["Rochowicz",	279.48	]	,
["Raczyk",	279.48327	]	,
["Rabczynski",	279.48654	]	,
["Potrzasaj",	279.48981	]	,
["Patecki",	279.49308	]	,
["Naborczyk",	279.49635	]	,
["Mysków",	279.49962	]	,
["Miazek",	279.50289	]	,
["Luzny",	279.50616	]	,
["Lopatecki",	279.50943	]	,
["Lubonski",	279.5127	]	,
["Kulejewski",	279.51597	]	,
["Klarecki",	279.51924	]	,
["Jaster",	279.52251	]	,
["Jarocinski",	279.52578	]	,
["Jakielaszek",	279.52905	]	,
["Hala",	279.53232	]	,
["Gwardecki",	279.53559	]	,
["Gnys",	279.53886	]	,
["Gniedziejko",	279.54213	]	,
["Gnatek",	279.5454	]	,
["Glombik",	279.54867	]	,
["Franiel",	279.55194	]	,
["Fisiak",	279.55521	]	,
["Dukowski",	279.55848	]	,
["Chudala",	279.56175	]	,
["Cerek",	279.56502	]	,
["Caruk",	279.56829	]	,
["Caba",	279.57156	]	,
["Burlinski",	279.57483	]	,
["Bialko",	279.5781	]	,
["Balazinski",	279.58137	]	,
["Badecki",	279.58464	]	,
["Augusciak",	279.58791	]	,
["Winkiel",	279.59117	]	,
["Trzosek",	279.59443	]	,
["Trzepalka",	279.59769	]	,
["Tondel",	279.60095	]	,
["Thiede",	279.60421	]	,
["Swidnicki",	279.60747	]	,
["Slepecki",	279.61073	]	,
["Szymonski",	279.61399	]	,
["Strzelewicz",	279.61725	]	,
["Skirzynski",	279.62051	]	,
["Sarnik",	279.62377	]	,
["Salacki",	279.62703	]	,
["Rywacki",	279.63029	]	,
["Rura",	279.63355	]	,
["Rostecki",	279.63681	]	,
["Redel",	279.64007	]	,
["Ratuszna",	279.64333	]	,
["Pupka",	279.64659	]	,
["Podrzycki",	279.64985	]	,
["Paczka",	279.65311	]	,
["Ludew",	279.65637	]	,
["Lessnau",	279.65963	]	,
["Laudanski",	279.66289	]	,
["Lamorski",	279.66615	]	,
["Kutarba",	279.66941	]	,
["Korczewski",	279.67267	]	,
["Korbela",	279.67593	]	,
["Kolba",	279.67919	]	,
["Koluch",	279.68245	]	,
["Kinalski",	279.68571	]	,
["Kaczuba",	279.68897	]	,
["Hatala",	279.69223	]	,
["Gembala",	279.69549	]	,
["Duchniak",	279.69875	]	,
["Czochra",	279.70201	]	,
["Czajczyk",	279.70527	]	,
["Chlodnicki",	279.70853	]	,
["Bujwid",	279.71179	]	,
["Brudlo",	279.71505	]	,
["Bojara",	279.71831	]	,
["Barkowski",	279.72157	]	,
["Badyna",	279.72483	]	,
["Aleksandrowski",	279.72809	]	,
["Zyta",	279.73134	]	,
["Zelisko",	279.73459	]	,
["Wiedlocha",	279.73784	]	,
["Wabinski",	279.74109	]	,
["Teodorowski",	279.74434	]	,
["Talma",	279.74759	]	,
["Swida",	279.75084	]	,
["Szajnowski",	279.75409	]	,
["Strzelbicki",	279.75734	]	,
["Stebnicki",	279.76059	]	,
["Solipiwko",	279.76384	]	,
["Sienkowski",	279.76709	]	,
["Rzysko",	279.77034	]	,
["Rymar",	279.77359	]	,
["Rózyc",	279.77684	]	,
["Rozwód",	279.78009	]	,
["Popanda",	279.78334	]	,
["Nazarczuk",	279.78659	]	,
["Mikielewicz",	279.78984	]	,
["Lyzinski",	279.79309	]	,
["Kwestarz",	279.79634	]	,
["Kutrzeba",	279.79959	]	,
["Krocz",	279.80284	]	,
["Krala",	279.80609	]	,
["Krakus",	279.80934	]	,
["Korzeniec",	279.81259	]	,
["Korn",	279.81584	]	,
["Korenkiewicz",	279.81909	]	,
["Koniszewski",	279.82234	]	,
["Komarek",	279.82559	]	,
["Kiszko",	279.82884	]	,
["Kasperkowiak",	279.83209	]	,
["Kantorek",	279.83534	]	,
["Kalisciak",	279.83859	]	,
["Hyla",	279.84184	]	,
["Huba",	279.84509	]	,
["Gwiazdowicz",	279.84834	]	,
["Grubich",	279.85159	]	,
["Golczewski",	279.85484	]	,
["Gocalek",	279.85809	]	,
["Gmurek",	279.86134	]	,
["Gladysiak",	279.86459	]	,
["Ciurysek",	279.86784	]	,
["Chamot",	279.87109	]	,
["Breczko",	279.87434	]	,
["Balcar",	279.87759	]	,
["Ankudowicz",	279.88084	]	,
["Zinczuk",	279.88408	]	,
["Zagalski",	279.88732	]	,
["Wrebiak",	279.89056	]	,
["Wojtylo",	279.8938	]	,
["Wojtusik",	279.89704	]	,
["Wojewski",	279.90028	]	,
["Wojcieszko",	279.90352	]	,
["Trzcinka",	279.90676	]	,
["Trochonowicz",	279.91	]	,
["Trepczynski",	279.91324	]	,
["Taube",	279.91648	]	,
["Szychulski",	279.91972	]	,
["Szumera",	279.92296	]	,
["Szneider",	279.9262	]	,
["Syguda",	279.92944	]	,
["Suchara",	279.93268	]	,
["Smus",	279.93592	]	,
["Rózanowski",	279.93916	]	,
["Raczy",	279.9424	]	,
["Pindur",	279.94564	]	,
["Opielka",	279.94888	]	,
["Olejko",	279.95212	]	,
["Olejarski",	279.95536	]	,
["Olbromski",	279.9586	]	,
["Molasy",	279.96184	]	,
["Minga",	279.96508	]	,
["Marunowski",	279.96832	]	,
["Mamczur",	279.97156	]	,
["Ludynia",	279.9748	]	,
["Liebner",	279.97804	]	,
["Latowski",	279.98128	]	,
["Kurman",	279.98452	]	,
["Krzynowek",	279.98776	]	,
["Koteras",	279.991	]	,
["Korwek",	279.99424	]	,
["Kordzinski",	279.99748	]	,
["Kolodko",	280.00072	]	,
["Kokoszynski",	280.00396	]	,
["Kasek",	280.0072	]	,
["Jechorek",	280.01044	]	,
["Jakóbiec",	280.01368	]	,
["Jakimczuk",	280.01692	]	,
["Holz",	280.02016	]	,
["Heldt",	280.0234	]	,
["Grochowiak",	280.02664	]	,
["Gredka",	280.02988	]	,
["Glódz",	280.03312	]	,
["Glowik",	280.03636	]	,
["Gawek",	280.0396	]	,
["Gatnar",	280.04284	]	,
["Furmaniuk",	280.04608	]	,
["Furczyk",	280.04932	]	,
["Freier",	280.05256	]	,
["Foksowicz",	280.0558	]	,
["Dziadczyk",	280.05904	]	,
["Duszka",	280.06228	]	,
["Domoradzki",	280.06552	]	,
["Czabanski",	280.06876	]	,
["Cwiklinski",	280.072	]	,
["Buzalski",	280.07524	]	,
["Branecki",	280.07848	]	,
["Bieniarz",	280.08172	]	,
["Barwacz",	280.08496	]	,
["Zmaczynski",	280.08819	]	,
["Zgnilec",	280.09142	]	,
["Zdzieszynski",	280.09465	]	,
["Zaliwski",	280.09788	]	,
["Zajecki",	280.10111	]	,
["Zacharz",	280.10434	]	,
["Wraga",	280.10757	]	,
["Wall",	280.1108	]	,
["Walczykowski",	280.11403	]	,
["Uchwat",	280.11726	]	,
["Truty",	280.12049	]	,
["Szychowiak",	280.12372	]	,
["Steczkowski",	280.12695	]	,
["Sobieszuk",	280.13018	]	,
["Skorulski",	280.13341	]	,
["Siemionek",	280.13664	]	,
["Salik",	280.13987	]	,
["Rymarkiewicz",	280.1431	]	,
["Ryduchowski",	280.14633	]	,
["Rozynski",	280.14956	]	,
["Rekiel",	280.15279	]	,
["Radawiec",	280.15602	]	,
["Pycz",	280.15925	]	,
["Poniewierka",	280.16248	]	,
["Pasierowski",	280.16571	]	,
["Nowosadko",	280.16894	]	,
["Noszczynski",	280.17217	]	,
["Nasalski",	280.1754	]	,
["Morag",	280.17863	]	,
["Micinski",	280.18186	]	,
["Maszczak",	280.18509	]	,
["Maronski",	280.18832	]	,
["Lewczak",	280.19155	]	,
["Labisz",	280.19478	]	,
["Kuzniarz",	280.19801	]	,
["Kurpierz",	280.20124	]	,
["Kosciecha",	280.20447	]	,
["Kisiela",	280.2077	]	,
["Kierasinski",	280.21093	]	,
["Kabut",	280.21416	]	,
["Jurys",	280.21739	]	,
["Grzegórzko",	280.22062	]	,
["Gretkowski",	280.22385	]	,
["Fleischer",	280.22708	]	,
["Flakus",	280.23031	]	,
["Dobkiewicz",	280.23354	]	,
["Derdzinski",	280.23677	]	,
["Cymbor",	280.24	]	,
["Broja",	280.24323	]	,
["Brek",	280.24646	]	,
["Brambor",	280.24969	]	,
["Bobruk",	280.25292	]	,
["Ber",	280.25615	]	,
["Barcewicz",	280.25938	]	,
["Ziemlinski",	280.2626	]	,
["Zaroda",	280.26582	]	,
["Wyzinski",	280.26904	]	,
["Wolosowicz",	280.27226	]	,
["Wielkopolan",	280.27548	]	,
["Uram",	280.2787	]	,
["Typiak",	280.28192	]	,
["Tunkiewicz",	280.28514	]	,
["Szuchnik",	280.28836	]	,
["Szczapa",	280.29158	]	,
["Suchorab",	280.2948	]	,
["Stasikowski",	280.29802	]	,
["Spiewak",	280.30124	]	,
["Smaruj",	280.30446	]	,
["Slawuta",	280.30768	]	,
["Siemiaszko",	280.3109	]	,
["Sermak",	280.31412	]	,
["Schröder",	280.31734	]	,
["Rycerski",	280.32056	]	,
["Ruszewski",	280.32378	]	,
["Rejak",	280.327	]	,
["Pultorak",	280.33022	]	,
["Pukownik",	280.33344	]	,
["Prugar",	280.33666	]	,
["Porwolik",	280.33988	]	,
["Plader",	280.3431	]	,
["Pieszczek",	280.34632	]	,
["Parkita",	280.34954	]	,
["Ozdarski",	280.35276	]	,
["Omilian",	280.35598	]	,
["Mogilnicki",	280.3592	]	,
["Milke",	280.36242	]	,
["Masior",	280.36564	]	,
["Mataczynski",	280.36886	]	,
["Mallek",	280.37208	]	,
["Malczynski",	280.3753	]	,
["Ladowski",	280.37852	]	,
["Kusnierczyk",	280.38174	]	,
["Krzysztoforski",	280.38496	]	,
["Kruszec",	280.38818	]	,
["Koziura",	280.3914	]	,
["Korszla",	280.39462	]	,
["Komasinski",	280.39784	]	,
["Klecki",	280.40106	]	,
["Jodkowski",	280.40428	]	,
["Idzinski",	280.4075	]	,
["Hnatów",	280.41072	]	,
["Hejnar",	280.41394	]	,
["Gwizdon",	280.41716	]	,
["Grzybczyk",	280.42038	]	,
["Gradkowski",	280.4236	]	,
["Godos",	280.42682	]	,
["Garula",	280.43004	]	,
["Fiegler",	280.43326	]	,
["Dróbka",	280.43648	]	,
["Draczkowski",	280.4397	]	,
["Derdowski",	280.44292	]	,
["Dalach",	280.44614	]	,
["Dajek",	280.44936	]	,
["Czul",	280.45258	]	,
["Chomko",	280.4558	]	,
["Buciak",	280.45902	]	,
["Bubula",	280.46224	]	,
["Blas",	280.46546	]	,
["Basara",	280.46868	]	,
["Bajera",	280.4719	]	,
["Andruczyk",	280.47512	]	,
["Zmudka",	280.47833	]	,
["Zarek",	280.48154	]	,
["Wrotkowski",	280.48475	]	,
["Witman",	280.48796	]	,
["Witkowiak",	280.49117	]	,
["Weselak",	280.49438	]	,
["Uchyla",	280.49759	]	,
["Szultka",	280.5008	]	,
["Stawiski",	280.50401	]	,
["Rozlach",	280.50722	]	,
["Psota",	280.51043	]	,
["Poradzisz",	280.51364	]	,
["Pochwala",	280.51685	]	,
["Plawinski",	280.52006	]	,
["Pilak",	280.52327	]	,
["Pajer",	280.52648	]	,
["Orzeszyna",	280.52969	]	,
["Orzeszko",	280.5329	]	,
["Oberc",	280.53611	]	,
["Makiewicz",	280.53932	]	,
["Macios",	280.54253	]	,
["Maciaga",	280.54574	]	,
["Linde",	280.54895	]	,
["Kurylek",	280.55216	]	,
["Kraskiewicz",	280.55537	]	,
["Jakób",	280.55858	]	,
["Hawrot",	280.56179	]	,
["Halupczok",	280.565	]	,
["Gurbala",	280.56821	]	,
["Grzebinoga",	280.57142	]	,
["Gaczorek",	280.57463	]	,
["Furlepa",	280.57784	]	,
["Fedyna",	280.58105	]	,
["Dziecielewski",	280.58426	]	,
["Dziadyk",	280.58747	]	,
["Drzewoszewski",	280.59068	]	,
["Doba",	280.59389	]	,
["Czudek",	280.5971	]	,
["Chwiej",	280.60031	]	,
["Chlopicki",	280.60352	]	,
["Chechlowski",	280.60673	]	,
["Bujarski",	280.60994	]	,
["Boszczyk",	280.61315	]	,
["Andrusiak",	280.61636	]	,
["Zygnerski",	280.61956	]	,
["Zlotos",	280.62276	]	,
["Zaluga",	280.62596	]	,
["Zagraba",	280.62916	]	,
["Wysmulek",	280.63236	]	,
["Wisnios",	280.63556	]	,
["Wielgórski",	280.63876	]	,
["Wawrzen",	280.64196	]	,
["Tyburek",	280.64516	]	,
["Treter",	280.64836	]	,
["Sekrecki",	280.65156	]	,
["Samuel",	280.65476	]	,
["Pyzara",	280.65796	]	,
["Putko",	280.66116	]	,
["Prosniak",	280.66436	]	,
["Piosek",	280.66756	]	,
["Niemyt",	280.67076	]	,
["Morciniec",	280.67396	]	,
["Marianowski",	280.67716	]	,
["Majder",	280.68036	]	,
["Kurzyp",	280.68356	]	,
["Kucharczuk",	280.68676	]	,
["Kielinski",	280.68996	]	,
["Karpisz",	280.69316	]	,
["Kabata",	280.69636	]	,
["Glomski",	280.69956	]	,
["Fröhlich",	280.70276	]	,
["Fresko",	280.70596	]	,
["Dzugaj",	280.70916	]	,
["Drapacz",	280.71236	]	,
["Zasadni",	280.71555	]	,
["Warzych",	280.71874	]	,
["Szypryt",	280.72193	]	,
["Szwarczynski",	280.72512	]	,
["Stobierski",	280.72831	]	,
["Spendel",	280.7315	]	,
["Skrobanski",	280.73469	]	,
["Sielczak",	280.73788	]	,
["Rekos",	280.74107	]	,
["Przeslawski",	280.74426	]	,
["Potasinski",	280.74745	]	,
["Poninski",	280.75064	]	,
["Pietraszak",	280.75383	]	,
["Pieluzek",	280.75702	]	,
["Osyra",	280.76021	]	,
["Nalej",	280.7634	]	,
["Myszko",	280.76659	]	,
["Mrzyk",	280.76978	]	,
["Menzel",	280.77297	]	,
["Mandat",	280.77616	]	,
["Maduzia",	280.77935	]	,
["Latecki",	280.78254	]	,
["Krzowski",	280.78573	]	,
["Krupczynski",	280.78892	]	,
["Kowynia",	280.79211	]	,
["Knobloch",	280.7953	]	,
["Klobuch",	280.79849	]	,
["Klekotko",	280.80168	]	,
["Kasjan",	280.80487	]	,
["Karpacz",	280.80806	]	,
["Karbowy",	280.81125	]	,
["Hubicki",	280.81444	]	,
["Haracz",	280.81763	]	,
["Halek",	280.82082	]	,
["Gujski",	280.82401	]	,
["Foterek",	280.8272	]	,
["Forgiel",	280.83039	]	,
["Chalinski",	280.83358	]	,
["Brysz",	280.83677	]	,
["Broniewicz",	280.83996	]	,
["Boryna",	280.84315	]	,
["Bachórz",	280.84634	]	,
["Zieziula",	280.84952	]	,
["Zbronski",	280.8527	]	,
["Zabczyk",	280.85588	]	,
["Zapisek",	280.85906	]	,
["Wdowicz",	280.86224	]	,
["Warczynski",	280.86542	]	,
["Wandycz",	280.8686	]	,
["Utko",	280.87178	]	,
["Torbicz",	280.87496	]	,
["Tetzlaff",	280.87814	]	,
["Tajer",	280.88132	]	,
["Szalega",	280.8845	]	,
["Sudolski",	280.88768	]	,
["Staszko",	280.89086	]	,
["Starak",	280.89404	]	,
["Rzepiela",	280.89722	]	,
["Rogoza",	280.9004	]	,
["Rochminski",	280.90358	]	,
["Rewaj",	280.90676	]	,
["Oczachowski",	280.90994	]	,
["Niemotko",	280.91312	]	,
["Mamla",	280.9163	]	,
["Lenar",	280.91948	]	,
["Latus",	280.92266	]	,
["Kuzel",	280.92584	]	,
["Kurnyta",	280.92902	]	,
["Kanikowski",	280.9322	]	,
["Grzadkowski",	280.93538	]	,
["Gozdalski",	280.93856	]	,
["Geppert",	280.94174	]	,
["Gardynski",	280.94492	]	,
["Gajecki",	280.9481	]	,
["Flajszer",	280.95128	]	,
["Fica",	280.95446	]	,
["Dziurdz",	280.95764	]	,
["Dorywalski",	280.96082	]	,
["Cielemecki",	280.964	]	,
["Cheda",	280.96718	]	,
["Capar",	280.97036	]	,
["Butynski",	280.97354	]	,
["Bulzacki",	280.97672	]	,
["Buca",	280.9799	]	,
["Bota",	280.98308	]	,
["Aleksanderek",	280.98626	]	,
["Zmuda-Trzebiatowski",	280.98943	]	,
["Wyderski",	280.9926	]	,
["Wikarski",	280.99577	]	,
["Wesiora",	280.99894	]	,
["Wegorek",	281.00211	]	,
["Uscilowski",	281.00528	]	,
["Tyburcy",	281.00845	]	,
["Toporski",	281.01162	]	,
["Toporkiewicz",	281.01479	]	,
["Tarchala",	281.01796	]	,
["Szynwelski",	281.02113	]	,
["Sztylka",	281.0243	]	,
["Szafruga",	281.02747	]	,
["Starz",	281.03064	]	,
["Staros",	281.03381	]	,
["Smajdor",	281.03698	]	,
["Serweta",	281.04015	]	,
["Schabek",	281.04332	]	,
["Samp",	281.04649	]	,
["Rybczak",	281.04966	]	,
["Robaszynski",	281.05283	]	,
["Redzinski",	281.056	]	,
["Ptaszkiewicz",	281.05917	]	,
["Prusakiewicz",	281.06234	]	,
["Prochal",	281.06551	]	,
["Prajsnar",	281.06868	]	,
["Polcik",	281.07185	]	,
["Poliszuk",	281.07502	]	,
["Piesyk",	281.07819	]	,
["Pasniewski",	281.08136	]	,
["Pasiuk",	281.08453	]	,
["Paczes",	281.0877	]	,
["Nosol",	281.09087	]	,
["Nadobnik",	281.09404	]	,
["Marszolek",	281.09721	]	,
["Lala",	281.10038	]	,
["Kyziol",	281.10355	]	,
["Kuzanski",	281.10672	]	,
["Kusion",	281.10989	]	,
["Krzywiecki",	281.11306	]	,
["Krymski",	281.11623	]	,
["Krieger",	281.1194	]	,
["Kowalów",	281.12257	]	,
["Kotyza",	281.12574	]	,
["Korczowski",	281.12891	]	,
["Jadczuk",	281.13208	]	,
["Harbuz",	281.13525	]	,
["Guss",	281.13842	]	,
["Gondzik",	281.14159	]	,
["Furdal",	281.14476	]	,
["Foik",	281.14793	]	,
["Domaracki",	281.1511	]	,
["Derendarz",	281.15427	]	,
["Czerech",	281.15744	]	,
["Cyrwus",	281.16061	]	,
["Cybulka",	281.16378	]	,
["Byczyk",	281.16695	]	,
["Bolibok",	281.17012	]	,
["Bajno",	281.17329	]	,
["Ankowski",	281.17646	]	,
["Wyporski",	281.17962	]	,
["Wodara",	281.18278	]	,
["Walotka",	281.18594	]	,
["Tulowiecki",	281.1891	]	,
["Trzpiot",	281.19226	]	,
["Swientek",	281.19542	]	,
["Sniadala",	281.19858	]	,
["Sztyler",	281.20174	]	,
["Starzomski",	281.2049	]	,
["Smug",	281.20806	]	,
["Skutela",	281.21122	]	,
["Siwczynski",	281.21438	]	,
["Ruszniak",	281.21754	]	,
["Radom",	281.2207	]	,
["Pykalo",	281.22386	]	,
["Poborski",	281.22702	]	,
["Piska",	281.23018	]	,
["Piecek",	281.23334	]	,
["Peters",	281.2365	]	,
["Palmaka",	281.23966	]	,
["Otta",	281.24282	]	,
["Orkiszewski",	281.24598	]	,
["Ochowiak",	281.24914	]	,
["Nietubyc",	281.2523	]	,
["Mikielski",	281.25546	]	,
["Matlok",	281.25862	]	,
["Krupicki",	281.26178	]	,
["Koryczan",	281.26494	]	,
["Kocerba",	281.2681	]	,
["Kaluziak",	281.27126	]	,
["Juras",	281.27442	]	,
["Janowczyk",	281.27758	]	,
["Goraus",	281.28074	]	,
["Glabinski",	281.2839	]	,
["Gladys",	281.28706	]	,
["Garczarczyk",	281.29022	]	,
["Frysz",	281.29338	]	,
["Dziwota",	281.29654	]	,
["Dulowski",	281.2997	]	,
["Derejczyk",	281.30286	]	,
["Cybulak",	281.30602	]	,
["Chuderski",	281.30918	]	,
["Chlebny",	281.31234	]	,
["Chanko",	281.3155	]	,
["Chalupniczak",	281.31866	]	,
["Blazkiewicz",	281.32182	]	,
["Bizior",	281.32498	]	,
["Bierut",	281.32814	]	,
["Ambrozkiewicz",	281.3313	]	,
["Zielona",	281.33445	]	,
["Wozniakiewicz",	281.3376	]	,
["Wowk",	281.34075	]	,
["Wojtylko",	281.3439	]	,
["Typek",	281.34705	]	,
["Turczak",	281.3502	]	,
["Tarczon",	281.35335	]	,
["Sciesinski",	281.3565	]	,
["Sznober",	281.35965	]	,
["Szarko",	281.3628	]	,
["Strzykala",	281.36595	]	,
["Strzemieczny",	281.3691	]	,
["Repetowski",	281.37225	]	,
["Polny",	281.3754	]	,
["Pejski",	281.37855	]	,
["Okaj",	281.3817	]	,
["Obrusnik",	281.38485	]	,
["Nowrotek",	281.388	]	,
["Mazerant",	281.39115	]	,
["Markut",	281.3943	]	,
["Malitka",	281.39745	]	,
["Loszewski",	281.4006	]	,
["Lolo",	281.40375	]	,
["Kuzera",	281.4069	]	,
["Ksiazka",	281.41005	]	,
["Krzastek",	281.4132	]	,
["Kopaniarz",	281.41635	]	,
["Konofalski",	281.4195	]	,
["Klockowski",	281.42265	]	,
["Karwatka",	281.4258	]	,
["Kaftan",	281.42895	]	,
["Joppek",	281.4321	]	,
["Iwanejko",	281.43525	]	,
["Gniewosz",	281.4384	]	,
["Gibki",	281.44155	]	,
["Gabrus",	281.4447	]	,
["Fabicki",	281.44785	]	,
["Dzierbicki",	281.451	]	,
["Dziabas",	281.45415	]	,
["Cymanowski",	281.4573	]	,
["Cesarski",	281.46045	]	,
["Bisek",	281.4636	]	,
["Zytko",	281.46674	]	,
["Zdolski",	281.46988	]	,
["Zawicki",	281.47302	]	,
["Wyszogrodzka",	281.47616	]	,
["Wojdal",	281.4793	]	,
["Wistuba",	281.48244	]	,
["Westphal",	281.48558	]	,
["Walaszkowski",	281.48872	]	,
["Uljasz",	281.49186	]	,
["Turcza",	281.495	]	,
["Towarnicki",	281.49814	]	,
["Szucki",	281.50128	]	,
["Szafarski",	281.50442	]	,
["Surala",	281.50756	]	,
["Sulski",	281.5107	]	,
["Struginski",	281.51384	]	,
["Strag",	281.51698	]	,
["Specht",	281.52012	]	,
["Soltyk",	281.52326	]	,
["Skora",	281.5264	]	,
["Skaza",	281.52954	]	,
["Siegien",	281.53268	]	,
["Rabeda",	281.53582	]	,
["Predko",	281.53896	]	,
["Pondo",	281.5421	]	,
["Plotkowski",	281.54524	]	,
["Piszko",	281.54838	]	,
["Piekoszewski",	281.55152	]	,
["Peta",	281.55466	]	,
["Pergol",	281.5578	]	,
["Pazurek",	281.56094	]	,
["Pawluczyk",	281.56408	]	,
["Olszta",	281.56722	]	,
["Ochalek",	281.57036	]	,
["Nierodzik",	281.5735	]	,
["Nielacny",	281.57664	]	,
["Nawojczyk",	281.57978	]	,
["Militowski",	281.58292	]	,
["Margalski",	281.58606	]	,
["Lyczba",	281.5892	]	,
["Lejczak",	281.59234	]	,
["Lau",	281.59548	]	,
["Ksepka",	281.59862	]	,
["Krupecki",	281.60176	]	,
["Kremski",	281.6049	]	,
["Koryciak",	281.60804	]	,
["Koniarczyk",	281.61118	]	,
["Karnafel",	281.61432	]	,
["Jamroz",	281.61746	]	,
["Hrycak",	281.6206	]	,
["Halczuk",	281.62374	]	,
["Habura",	281.62688	]	,
["Gromotka",	281.63002	]	,
["Gorzen",	281.63316	]	,
["Gorgosz",	281.6363	]	,
["Gadocha",	281.63944	]	,
["Fuhrmann",	281.64258	]	,
["Fidrych",	281.64572	]	,
["Datko",	281.64886	]	,
["Cienkowski",	281.652	]	,
["Chrost",	281.65514	]	,
["Chabierski",	281.65828	]	,
["Cepil",	281.66142	]	,
["Celer",	281.66456	]	,
["Broma",	281.6677	]	,
["Bagniuk",	281.67084	]	,
["Ziemichód",	281.67397	]	,
["Zegarski",	281.6771	]	,
["Widel",	281.68023	]	,
["Tomasz",	281.68336	]	,
["Smiertka",	281.68649	]	,
["Szymus",	281.68962	]	,
["Rzymek",	281.69275	]	,
["Ryszawa",	281.69588	]	,
["Puchalka",	281.69901	]	,
["Pospieszna",	281.70214	]	,
["Pesko",	281.70527	]	,
["Patela",	281.7084	]	,
["Miaskiewicz",	281.71153	]	,
["Majnert",	281.71466	]	,
["Macko",	281.71779	]	,
["Lusiewicz",	281.72092	]	,
["Lapucha",	281.72405	]	,
["Kolota",	281.72718	]	,
["Kedron",	281.73031	]	,
["Kawalerski",	281.73344	]	,
["Kajmowicz",	281.73657	]	,
["Janisiewicz",	281.7397	]	,
["Gol",	281.74283	]	,
["Globisz",	281.74596	]	,
["Dziurski",	281.74909	]	,
["Dybciak",	281.75222	]	,
["Doros",	281.75535	]	,
["Donajski",	281.75848	]	,
["Czulinski",	281.76161	]	,
["Cybulko",	281.76474	]	,
["Chwalczuk",	281.76787	]	,
["Chodubski",	281.771	]	,
["Chelmicki",	281.77413	]	,
["Chara",	281.77726	]	,
["Bonder",	281.78039	]	,
["Bodura",	281.78352	]	,
["Bilnik",	281.78665	]	,
["Bedyk",	281.78978	]	,
["Bakowicz",	281.79291	]	,
["Zmich",	281.79603	]	,
["Zimonczyk",	281.79915	]	,
["Zgrzebnicki",	281.80227	]	,
["Zalóg",	281.80539	]	,
["Wygladala",	281.80851	]	,
["Wojniusz",	281.81163	]	,
["Wasielak",	281.81475	]	,
["Walencki",	281.81787	]	,
["Uruski",	281.82099	]	,
["Tryniecki",	281.82411	]	,
["Topolinski",	281.82723	]	,
["Telesz",	281.83035	]	,
["Tarabula",	281.83347	]	,
["Sleziona",	281.83659	]	,
["Szafirski",	281.83971	]	,
["Swadzba",	281.84283	]	,
["Sudul",	281.84595	]	,
["Skowerski",	281.84907	]	,
["Siepietowski",	281.85219	]	,
["Pukala",	281.85531	]	,
["Pios",	281.85843	]	,
["Paton",	281.86155	]	,
["Patelka",	281.86467	]	,
["Pakura",	281.86779	]	,
["Oszywa",	281.87091	]	,
["Orzepowski",	281.87403	]	,
["Ogrodniczuk",	281.87715	]	,
["Nems",	281.88027	]	,
["Naglak",	281.88339	]	,
["Mrozewski",	281.88651	]	,
["Meisner",	281.88963	]	,
["Mastela",	281.89275	]	,
["Madecki",	281.89587	]	,
["Maczynski",	281.89899	]	,
["Lutrzykowski",	281.90211	]	,
["Lutkiewicz",	281.90523	]	,
["Kurnatowski",	281.90835	]	,
["Kubic",	281.91147	]	,
["Kobalczyk",	281.91459	]	,
["Klita",	281.91771	]	,
["Klauzinski",	281.92083	]	,
["Kierznikiewicz",	281.92395	]	,
["Jemielita",	281.92707	]	,
["Jakrzewski",	281.93019	]	,
["Imiolczyk",	281.93331	]	,
["Gmitruk",	281.93643	]	,
["Gdanski",	281.93955	]	,
["Furdyna",	281.94267	]	,
["Elas",	281.94579	]	,
["Darmetko",	281.94891	]	,
["Cudowski",	281.95203	]	,
["Ciecholewski",	281.95515	]	,
["Byrczek",	281.95827	]	,
["Bugdol",	281.96139	]	,
["Bodziak",	281.96451	]	,
["Bill",	281.96763	]	,
["Beczala",	281.97075	]	,
["Bachta",	281.97387	]	,
["Babulewicz",	281.97699	]	,
["Aniskiewicz",	281.98011	]	,
["Zielkowski",	281.98322	]	,
["Wedrychowicz",	281.98633	]	,
["Topolnicki",	281.98944	]	,
["Teszner",	281.99255	]	,
["Tatarowicz",	281.99566	]	,
["Szyszlo",	281.99877	]	,
["Szuleta",	282.00188	]	,
["Szulakowski",	282.00499	]	,
["Szpigiel",	282.0081	]	,
["Sulka",	282.01121	]	,
["Sonik",	282.01432	]	,
["Smarzewski",	282.01743	]	,
["Siodlowski",	282.02054	]	,
["Ronkowski",	282.02365	]	,
["Raczkowiak",	282.02676	]	,
["Pyryt",	282.02987	]	,
["Pycinski",	282.03298	]	,
["Puslecki",	282.03609	]	,
["Proskura",	282.0392	]	,
["Podedworny",	282.04231	]	,
["Pajdo",	282.04542	]	,
["Ostrysz",	282.04853	]	,
["Oksiuta",	282.05164	]	,
["Nosalski",	282.05475	]	,
["Narel",	282.05786	]	,
["Kuspiel",	282.06097	]	,
["Kretkiewicz",	282.06408	]	,
["Kislowski",	282.06719	]	,
["Kaszta",	282.0703	]	,
["Jureczka",	282.07341	]	,
["Jewiarz",	282.07652	]	,
["Janukowicz",	282.07963	]	,
["Ilinski",	282.08274	]	,
["Hyzyk",	282.08585	]	,
["Habrajski",	282.08896	]	,
["Grynczel",	282.09207	]	,
["Gallus",	282.09518	]	,
["Fafrowicz",	282.09829	]	,
["Dudak",	282.1014	]	,
["Dlugowski",	282.10451	]	,
["Ciechalski",	282.10762	]	,
["Chciuk",	282.11073	]	,
["Bierylo",	282.11384	]	,
["Bedla",	282.11695	]	,
["Badurek",	282.12006	]	,
["Arasim",	282.12317	]	,
["Andrychowski",	282.12628	]	,
["Zerebiec",	282.12938	]	,
["Wypich",	282.13248	]	,
["Wlosik",	282.13558	]	,
["Wetoszka",	282.13868	]	,
["Wabich",	282.14178	]	,
["Trzesicki",	282.14488	]	,
["Trendak",	282.14798	]	,
["Tobiczyk",	282.15108	]	,
["Sniadach",	282.15418	]	,
["Szwajcer",	282.15728	]	,
["Szmulik",	282.16038	]	,
["Suchenia",	282.16348	]	,
["Stygar",	282.16658	]	,
["Strzemieczna",	282.16968	]	,
["Skubel",	282.17278	]	,
["Skrabacz",	282.17588	]	,
["Sienczak",	282.17898	]	,
["Polaski",	282.18208	]	,
["Pierzchlewicz",	282.18518	]	,
["Pieczko",	282.18828	]	,
["Ostrowicz",	282.19138	]	,
["Osoba",	282.19448	]	,
["Myca",	282.19758	]	,
["Migowski",	282.20068	]	,
["Maslana",	282.20378	]	,
["Machut",	282.20688	]	,
["Luczyszyn",	282.20998	]	,
["Lozicki",	282.21308	]	,
["Loboz",	282.21618	]	,
["Lizurek",	282.21928	]	,
["Lenort",	282.22238	]	,
["Kuzmin",	282.22548	]	,
["Kulbaka",	282.22858	]	,
["Krzyz",	282.23168	]	,
["Kryza",	282.23478	]	,
["Kondrak",	282.23788	]	,
["Kerlin",	282.24098	]	,
["Kaszczuk",	282.24408	]	,
["Jeczmyk",	282.24718	]	,
["Jaz",	282.25028	]	,
["Jandura",	282.25338	]	,
["Jakuszewski",	282.25648	]	,
["Ignac",	282.25958	]	,
["Horn",	282.26268	]	,
["Hodurek",	282.26578	]	,
["Guzera",	282.26888	]	,
["Glac",	282.27198	]	,
["Gieszczyk",	282.27508	]	,
["Gielda",	282.27818	]	,
["Dziminski",	282.28128	]	,
["Dekiert",	282.28438	]	,
["Darmochwal",	282.28748	]	,
["Damaszk",	282.29058	]	,
["Czesnik",	282.29368	]	,
["Czajewski",	282.29678	]	,
["Cyngot",	282.29988	]	,
["Chorab",	282.30298	]	,
["Charkot",	282.30608	]	,
["Cajdler",	282.30918	]	,
["Buniowski",	282.31228	]	,
["Breczewski",	282.31538	]	,
["Bonarek",	282.31848	]	,
["Blada",	282.32158	]	,
["Baziuk",	282.32468	]	,
["Bancewicz",	282.32778	]	,
["Wojarski",	282.33087	]	,
["Til",	282.33396	]	,
["Szkudlarz",	282.33705	]	,
["Szarowski",	282.34014	]	,
["Szakiel",	282.34323	]	,
["Stepski",	282.34632	]	,
["Rzepnicki",	282.34941	]	,
["Rychly",	282.3525	]	,
["Rose",	282.35559	]	,
["Pyrzyk",	282.35868	]	,
["Przystanski",	282.36177	]	,
["Przeor",	282.36486	]	,
["Pronczuk",	282.36795	]	,
["Ogorzelski",	282.37104	]	,
["Nieswiec",	282.37413	]	,
["Mamos",	282.37722	]	,
["Lamasz",	282.38031	]	,
["Ladynski",	282.3834	]	,
["Korowaj",	282.38649	]	,
["Konczalski",	282.38958	]	,
["Hlebowicz",	282.39267	]	,
["Heliosz",	282.39576	]	,
["Hejmanowski",	282.39885	]	,
["Gradzik",	282.40194	]	,
["Fereniec",	282.40503	]	,
["Fedorczak",	282.40812	]	,
["Dzierzawa",	282.41121	]	,
["Dragosz",	282.4143	]	,
["Czesnowski",	282.41739	]	,
["Czernikiewicz",	282.42048	]	,
["Chwastowski",	282.42357	]	,
["Charchut",	282.42666	]	,
["Celeban",	282.42975	]	,
["Buganik",	282.43284	]	,
["Brzuchalski",	282.43593	]	,
["Barecki",	282.43902	]	,
["Balawejder",	282.44211	]	,
["Zacharko",	282.44519	]	,
["Wojtanek",	282.44827	]	,
["Wietrak",	282.45135	]	,
["Wartak",	282.45443	]	,
["Tyrpa",	282.45751	]	,
["Troski",	282.46059	]	,
["Traczuk",	282.46367	]	,
["Torbicki",	282.46675	]	,
["Szmaja",	282.46983	]	,
["Szczyrek",	282.47291	]	,
["Szczyglewski",	282.47599	]	,
["Stawiak",	282.47907	]	,
["Stanny",	282.48215	]	,
["Sarba",	282.48523	]	,
["Renkiewicz",	282.48831	]	,
["Przebieracz",	282.49139	]	,
["Przanowski",	282.49447	]	,
["Profic",	282.49755	]	,
["Monastyrski",	282.50063	]	,
["Lapawa",	282.50371	]	,
["Listewnik",	282.50679	]	,
["Kucypera",	282.50987	]	,
["Kucyk",	282.51295	]	,
["Klinicki",	282.51603	]	,
["Kamer",	282.51911	]	,
["Jasiuk",	282.52219	]	,
["Janiszyn",	282.52527	]	,
["Hasik",	282.52835	]	,
["Gurbisz",	282.53143	]	,
["Gradalski",	282.53451	]	,
["Gorzelany",	282.53759	]	,
["Gawlica",	282.54067	]	,
["Faltynowski",	282.54375	]	,
["Chrustek",	282.54683	]	,
["Chrapusta",	282.54991	]	,
["Chochowski",	282.55299	]	,
["Ches",	282.55607	]	,
["Brynda",	282.55915	]	,
["Böhm",	282.56223	]	,
["Bogdanik",	282.56531	]	,
["Bielejewski",	282.56839	]	,
["Becela",	282.57147	]	,
["Baluch",	282.57455	]	,
["Baluch",	282.57763	]	,
["Andrysiewicz",	282.58071	]	,
["Wosz",	282.58378	]	,
["Wojtus",	282.58685	]	,
["Wiackiewicz",	282.58992	]	,
["Wegrzecki",	282.59299	]	,
["Walkosz",	282.59606	]	,
["Tobjasz",	282.59913	]	,
["Sniegula",	282.6022	]	,
["Surawski",	282.60527	]	,
["Sobocik",	282.60834	]	,
["Sionkowski",	282.61141	]	,
["Sarbak",	282.61448	]	,
["Rejdak",	282.61755	]	,
["Reizer",	282.62062	]	,
["Raban",	282.62369	]	,
["Przybek",	282.62676	]	,
["Pieprzowski",	282.62983	]	,
["Pecio",	282.6329	]	,
["Patrzek",	282.63597	]	,
["Patok",	282.63904	]	,
["Pasierbski",	282.64211	]	,
["Okuniewicz",	282.64518	]	,
["Niezgodzki",	282.64825	]	,
["Mlodecki",	282.65132	]	,
["Michura",	282.65439	]	,
["Majdanik",	282.65746	]	,
["Magnuski",	282.66053	]	,
["Machnacki",	282.6636	]	,
["Lasecki",	282.66667	]	,
["Lacna",	282.66974	]	,
["Limanski",	282.67281	]	,
["Korolewicz",	282.67588	]	,
["Klyk",	282.67895	]	,
["Klessa",	282.68202	]	,
["Jankun",	282.68509	]	,
["Ilowski",	282.68816	]	,
["Holod",	282.69123	]	,
["Hausman",	282.6943	]	,
["Haladyn",	282.69737	]	,
["Czaj",	282.70044	]	,
["Czaczyk",	282.70351	]	,
["Ciolak",	282.70658	]	,
["Cielica",	282.70965	]	,
["Cichopek",	282.71272	]	,
["Chwirot",	282.71579	]	,
["Chodun",	282.71886	]	,
["Buski",	282.72193	]	,
["Blaszczyszyn",	282.725	]	,
["Bielarz",	282.72807	]	,
["Biegus",	282.73114	]	,
["Biegajlo",	282.73421	]	,
["Bargielski",	282.73728	]	,
["Baniewicz",	282.74035	]	,
["Balawajder",	282.74342	]	,
["Aderek",	282.74649	]	,
["Zuchowski",	282.74955	]	,
["Wawerek",	282.75261	]	,
["Warpechowski",	282.75567	]	,
["Tatka",	282.75873	]	,
["Szulga",	282.76179	]	,
["Szreiber",	282.76485	]	,
["Szczyglak",	282.76791	]	,
["Strzyga",	282.77097	]	,
["Stolz",	282.77403	]	,
["Starzecki",	282.77709	]	,
["Slawkowski",	282.78015	]	,
["Siudowski",	282.78321	]	,
["Rzesa",	282.78627	]	,
["Rybitwa",	282.78933	]	,
["Rajs",	282.79239	]	,
["Pudelski",	282.79545	]	,
["Postrach",	282.79851	]	,
["Pospieszny",	282.80157	]	,
["Podziewski",	282.80463	]	,
["Pietrakowski",	282.80769	]	,
["Pidek",	282.81075	]	,
["Pelszyk",	282.81381	]	,
["Osmolak",	282.81687	]	,
["Ojczyk",	282.81993	]	,
["Obajtek",	282.82299	]	,
["Nowopolski",	282.82605	]	,
["Nowicz",	282.82911	]	,
["Murzynski",	282.83217	]	,
["Muchewicz",	282.83523	]	,
["Mordas",	282.83829	]	,
["Matz",	282.84135	]	,
["Lotysz",	282.84441	]	,
["Lopato",	282.84747	]	,
["Kundys",	282.85053	]	,
["Kosciuch",	282.85359	]	,
["Klaskala",	282.85665	]	,
["Kanownik",	282.85971	]	,
["Jasak",	282.86277	]	,
["Grudowski",	282.86583	]	,
["Gniatkowski",	282.86889	]	,
["Gmiter",	282.87195	]	,
["Giecewicz",	282.87501	]	,
["Ganczar",	282.87807	]	,
["Gajlewicz",	282.88113	]	,
["Gajko",	282.88419	]	,
["Frasunkiewicz",	282.88725	]	,
["Fizia",	282.89031	]	,
["Fidut",	282.89337	]	,
["Elwertowski",	282.89643	]	,
["Dydynski",	282.89949	]	,
["Daniewski",	282.90255	]	,
["Cypel",	282.90561	]	,
["Ciepka",	282.90867	]	,
["Ciborek",	282.91173	]	,
["Chwierut",	282.91479	]	,
["Chuchra",	282.91785	]	,
["Cach",	282.92091	]	,
["Brudnowski",	282.92397	]	,
["Berdys",	282.92703	]	,
["Adamcewicz",	282.93009	]	,
["Wiewiórowski",	282.93314	]	,
["Wazynski",	282.93619	]	,
["Torzecki",	282.93924	]	,
["Tereba",	282.94229	]	,
["Tartanus",	282.94534	]	,
["Tararuj",	282.94839	]	,
["Swinarski",	282.95144	]	,
["Szwemin",	282.95449	]	,
["Szutowicz",	282.95754	]	,
["Strupczewski",	282.96059	]	,
["Stotko",	282.96364	]	,
["Skotarski",	282.96669	]	,
["Saczko",	282.96974	]	,
["Pyszynski",	282.97279	]	,
["Piprek",	282.97584	]	,
["Pachowski",	282.97889	]	,
["Osip",	282.98194	]	,
["Orminski",	282.98499	]	,
["Mizgier",	282.98804	]	,
["Mamaj",	282.99109	]	,
["Machalinski",	282.99414	]	,
["Lubianka",	282.99719	]	,
["Legosz",	283.00024	]	,
["Kwiczala",	283.00329	]	,
["Kozimor",	283.00634	]	,
["Kopyciok",	283.00939	]	,
["Konon",	283.01244	]	,
["Komadowski",	283.01549	]	,
["Kocierz",	283.01854	]	,
["Klos",	283.02159	]	,
["Kepowicz",	283.02464	]	,
["Horyn",	283.02769	]	,
["Gajec",	283.03074	]	,
["Fordon",	283.03379	]	,
["Ellert",	283.03684	]	,
["Ciesek",	283.03989	]	,
["Buzuk",	283.04294	]	,
["Bulakowski",	283.04599	]	,
["Bularz",	283.04904	]	,
["Bogulak",	283.05209	]	,
["Badkowski",	283.05514	]	,
["Agata",	283.05819	]	,
["Adasiewicz",	283.06124	]	,
["Zylski",	283.06428	]	,
["Walecko",	283.06732	]	,
["Urlik",	283.07036	]	,
["Udala",	283.0734	]	,
["Szutenberg",	283.07644	]	,
["Szaroleta",	283.07948	]	,
["Szachowicz",	283.08252	]	,
["Sysak",	283.08556	]	,
["Slabuszewski",	283.0886	]	,
["Salapa",	283.09164	]	,
["Ryn",	283.09468	]	,
["Romatowski",	283.09772	]	,
["Pyszkowski",	283.10076	]	,
["Pydyn",	283.1038	]	,
["Pruszko",	283.10684	]	,
["Priebe",	283.10988	]	,
["Pokorny",	283.11292	]	,
["Plachetka",	283.11596	]	,
["Piejak",	283.119	]	,
["Piechulski",	283.12204	]	,
["Peszek",	283.12508	]	,
["Pajdowski",	283.12812	]	,
["Paczoski",	283.13116	]	,
["Ott",	283.1342	]	,
["Orkwiszewski",	283.13724	]	,
["Nega",	283.14028	]	,
["Moren",	283.14332	]	,
["Mokijewski",	283.14636	]	,
["Merkel",	283.1494	]	,
["Lizik",	283.15244	]	,
["Ledwoch",	283.15548	]	,
["Kwintal",	283.15852	]	,
["Kuszel",	283.16156	]	,
["Kurdej",	283.1646	]	,
["Kuchar",	283.16764	]	,
["Kozniewski",	283.17068	]	,
["Kolpa",	283.17372	]	,
["Kluczna",	283.17676	]	,
["Klepadlo",	283.1798	]	,
["Klamczynski",	283.18284	]	,
["Kierul",	283.18588	]	,
["Kielt",	283.18892	]	,
["Kapla",	283.19196	]	,
["Kajzar",	283.195	]	,
["Juniewicz",	283.19804	]	,
["Jegier",	283.20108	]	,
["Jamry",	283.20412	]	,
["Ilkiewicz",	283.20716	]	,
["Ickiewicz",	283.2102	]	,
["Hildebrand",	283.21324	]	,
["Henel",	283.21628	]	,
["Grzesiowski",	283.21932	]	,
["Grzebyta",	283.22236	]	,
["Grzadzielewski",	283.2254	]	,
["Górawski",	283.22844	]	,
["Gaber",	283.23148	]	,
["Flaczynski",	283.23452	]	,
["Fabianowski",	283.23756	]	,
["Draheim",	283.2406	]	,
["Dobak",	283.24364	]	,
["Cynarski",	283.24668	]	,
["Chudzikiewicz",	283.24972	]	,
["Chaja",	283.25276	]	,
["Bogalecki",	283.2558	]	,
["Bobrzyk",	283.25884	]	,
["Blat",	283.26188	]	,
["Banicki",	283.26492	]	,
["Zurkiewicz",	283.26795	]	,
["Wycichowski",	283.27098	]	,
["Wódkiewicz",	283.27401	]	,
["Wilczopolski",	283.27704	]	,
["Wartecki",	283.28007	]	,
["Turbiarz",	283.2831	]	,
["Tobys",	283.28613	]	,
["Szarota",	283.28916	]	,
["Szajer",	283.29219	]	,
["Szadura",	283.29522	]	,
["Strózak",	283.29825	]	,
["Stodolak",	283.30128	]	,
["Starzewski",	283.30431	]	,
["Sperling",	283.30734	]	,
["Skomial",	283.31037	]	,
["Skinder",	283.3134	]	,
["Siwierski",	283.31643	]	,
["Sedrowski",	283.31946	]	,
["Sekita",	283.32249	]	,
["Ryniewicz",	283.32552	]	,
["Rostalski",	283.32855	]	,
["Pokój",	283.33158	]	,
["Piernikowski",	283.33461	]	,
["Paclawski",	283.33764	]	,
["Owsianny",	283.34067	]	,
["Osowicki",	283.3437	]	,
["Osiniak",	283.34673	]	,
["Odolczyk",	283.34976	]	,
["Nych",	283.35279	]	,
["Mocna",	283.35582	]	,
["Marzejon",	283.35885	]	,
["Markocki",	283.36188	]	,
["Markiewka",	283.36491	]	,
["Lulinski",	283.36794	]	,
["Lubarski",	283.37097	]	,
["Lass",	283.374	]	,
["Kusibab",	283.37703	]	,
["Kminikowski",	283.38006	]	,
["Jecek",	283.38309	]	,
["Ibek",	283.38612	]	,
["Henicz",	283.38915	]	,
["Hejda",	283.39218	]	,
["Greniuk",	283.39521	]	,
["Golojuch",	283.39824	]	,
["Fligiel",	283.40127	]	,
["Falfus",	283.4043	]	,
["Dworok",	283.40733	]	,
["Dragula",	283.41036	]	,
["Domoslawski",	283.41339	]	,
["Czelej",	283.41642	]	,
["Cienkusz",	283.41945	]	,
["Cieniewski",	283.42248	]	,
["Chyrzynski",	283.42551	]	,
["Checko",	283.42854	]	,
["Buskiewicz",	283.43157	]	,
["Burdon",	283.4346	]	,
["Burdek",	283.43763	]	,
["Bloszyk",	283.44066	]	,
["Blachura",	283.44369	]	,
["Bialoblocki",	283.44672	]	,
["Andrasz",	283.44975	]	,
["Zasko",	283.45277	]	,
["Zajas",	283.45579	]	,
["Wybieralski",	283.45881	]	,
["Wittke",	283.46183	]	,
["Tajak",	283.46485	]	,
["Swislocki",	283.46787	]	,
["Swinoga",	283.47089	]	,
["Sidwa",	283.47391	]	,
["Sajkiewicz",	283.47693	]	,
["Rudol",	283.47995	]	,
["Rudniak",	283.48297	]	,
["Rozner",	283.48599	]	,
["Rabiasz",	283.48901	]	,
["Puczko",	283.49203	]	,
["Przybycin",	283.49505	]	,
["Proscinski",	283.49807	]	,
["Procajlo",	283.50109	]	,
["Podskarbi",	283.50411	]	,
["Pieronczyk",	283.50713	]	,
["Piechal",	283.51015	]	,
["Palowski",	283.51317	]	,
["Ozdzynski",	283.51619	]	,
["Owsianna",	283.51921	]	,
["Mirzejewski",	283.52223	]	,
["Melnik",	283.52525	]	,
["Melerowicz",	283.52827	]	,
["Luter",	283.53129	]	,
["Litkowiec",	283.53431	]	,
["Kutrowski",	283.53733	]	,
["Krowinski",	283.54035	]	,
["Kromka",	283.54337	]	,
["Konop",	283.54639	]	,
["Koltys",	283.54941	]	,
["Kistela",	283.55243	]	,
["Katna",	283.55545	]	,
["Kaca",	283.55847	]	,
["Judkowiak",	283.56149	]	,
["Jonasz",	283.56451	]	,
["Jasnikowski",	283.56753	]	,
["Jarzabski",	283.57055	]	,
["Jalowski",	283.57357	]	,
["Hopa",	283.57659	]	,
["Hawrylo",	283.57961	]	,
["Gorwa",	283.58263	]	,
["Golebowski",	283.58565	]	,
["Gembiak",	283.58867	]	,
["Gagol",	283.59169	]	,
["Gadalinski",	283.59471	]	,
["Dziemianczyk",	283.59773	]	,
["Dziembala",	283.60075	]	,
["Dymarkowski",	283.60377	]	,
["Dyktynski",	283.60679	]	,
["Cielniak",	283.60981	]	,
["Cender",	283.61283	]	,
["Bulenda",	283.61585	]	,
["Blizniuk",	283.61887	]	,
["Zywot",	283.62188	]	,
["Zarosa",	283.62489	]	,
["Tyran",	283.6279	]	,
["Tenderenda",	283.63091	]	,
["Srutwa",	283.63392	]	,
["Szymczewski",	283.63693	]	,
["Szkopiak",	283.63994	]	,
["Szews",	283.64295	]	,
["Szczyrbak",	283.64596	]	,
["Salaciak",	283.64897	]	,
["Rygol",	283.65198	]	,
["Reczkowski",	283.65499	]	,
["Putyra",	283.658	]	,
["Poddebniak",	283.66101	]	,
["Piwosz",	283.66402	]	,
["Pinczewski",	283.66703	]	,
["Pietrulewicz",	283.67004	]	,
["Pielichowski",	283.67305	]	,
["Piekarzewski",	283.67606	]	,
["Padykula",	283.67907	]	,
["Ostafinski",	283.68208	]	,
["Olcha",	283.68509	]	,
["Noworol",	283.6881	]	,
["Nockowski",	283.69111	]	,
["Niezgódka",	283.69412	]	,
["Nandzik",	283.69713	]	,
["Molon",	283.70014	]	,
["Miaskiewicz",	283.70315	]	,
["Maziarek",	283.70616	]	,
["Maleski",	283.70917	]	,
["Kuzara",	283.71218	]	,
["Kuik",	283.71519	]	,
["Krymarys",	283.7182	]	,
["Koziar",	283.72121	]	,
["Kos",	283.72422	]	,
["Koruba",	283.72723	]	,
["Konieczniak",	283.73024	]	,
["Kiec",	283.73325	]	,
["Jaszczerski",	283.73626	]	,
["Gucik",	283.73927	]	,
["Grochecki",	283.74228	]	,
["Gasztych",	283.74529	]	,
["Gajdzis",	283.7483	]	,
["Filozof",	283.75131	]	,
["Dorozala",	283.75432	]	,
["Burian",	283.75733	]	,
["Burdelak",	283.76034	]	,
["Berezinski",	283.76335	]	,
["Bacik",	283.76636	]	,
["Balanda",	283.76937	]	,
["Badocha",	283.77238	]	,
["Akacki",	283.77539	]	,
["Zug",	283.77839	]	,
["Zgud",	283.78139	]	,
["Zabuski",	283.78439	]	,
["Wilkus",	283.78739	]	,
["Walawski",	283.79039	]	,
["Trzuskowski",	283.79339	]	,
["Szkolnicki",	283.79639	]	,
["Szelagiewicz",	283.79939	]	,
["Surga",	283.80239	]	,
["Suplicki",	283.80539	]	,
["Stusinski",	283.80839	]	,
["Siuzdak",	283.81139	]	,
["Ryniak",	283.81439	]	,
["Rycak",	283.81739	]	,
["Radominski",	283.82039	]	,
["Plebanski",	283.82339	]	,
["Pedowski",	283.82639	]	,
["Nitychoruk",	283.82939	]	,
["Nagler",	283.83239	]	,
["Mysliwski",	283.83539	]	,
["Malinka",	283.83839	]	,
["Maciukiewicz",	283.84139	]	,
["Lubczyk",	283.84439	]	,
["Krzekotowski",	283.84739	]	,
["Kiezik",	283.85039	]	,
["Janucik",	283.85339	]	,
["Iwon",	283.85639	]	,
["Hadrych",	283.85939	]	,
["Gozdan",	283.86239	]	,
["Gagol",	283.86539	]	,
["Folcik",	283.86839	]	,
["Dajnowicz",	283.87139	]	,
["Chrust",	283.87439	]	,
["Bystrowski",	283.87739	]	,
["Butrymowicz",	283.88039	]	,
["Budak",	283.88339	]	,
["Boryslawski",	283.88639	]	,
["Blahut",	283.88939	]	,
["Andruszków",	283.89239	]	,
["Adaszak",	283.89539	]	,
["Zyznowski",	283.89838	]	,
["Ziec",	283.90137	]	,
["Zarazinski",	283.90436	]	,
["Wrodarczyk",	283.90735	]	,
["Welke",	283.91034	]	,
["Walica",	283.91333	]	,
["Tretowski",	283.91632	]	,
["Szlacheta",	283.91931	]	,
["Synos",	283.9223	]	,
["Suberlak",	283.92529	]	,
["Stefanów",	283.92828	]	,
["Sputo",	283.93127	]	,
["Spirydowicz",	283.93426	]	,
["Slodka",	283.93725	]	,
["Skuta",	283.94024	]	,
["Samula",	283.94323	]	,
["Rewinski",	283.94622	]	,
["Pondel",	283.94921	]	,
["Polachowski",	283.9522	]	,
["Piwoni",	283.95519	]	,
["Patalong",	283.95818	]	,
["Pankanin",	283.96117	]	,
["Paliszewski",	283.96416	]	,
["Nosko",	283.96715	]	,
["Mojski",	283.97014	]	,
["Mitrowski",	283.97313	]	,
["Misiukiewicz",	283.97612	]	,
["Matoszko",	283.97911	]	,
["Maciulewicz",	283.9821	]	,
["Leczkowski",	283.98509	]	,
["Kostrzycki",	283.98808	]	,
["Koston",	283.99107	]	,
["Kosakiewicz",	283.99406	]	,
["Koczewski",	283.99705	]	,
["Knosala",	284.00004	]	,
["Kniola",	284.00303	]	,
["Klejdysz",	284.00602	]	,
["Klapczynski",	284.00901	]	,
["Kaluzniak",	284.012	]	,
["Jaszczura",	284.01499	]	,
["Janta-Lipinski",	284.01798	]	,
["Holówko",	284.02097	]	,
["Holdynski",	284.02396	]	,
["Grzenda",	284.02695	]	,
["Grylak",	284.02994	]	,
["Fryszkowski",	284.03293	]	,
["Fiertek",	284.03592	]	,
["Dygon",	284.03891	]	,
["Dulny",	284.0419	]	,
["Druciarek",	284.04489	]	,
["Demkowski",	284.04788	]	,
["Dec",	284.05087	]	,
["Danak",	284.05386	]	,
["Cipa",	284.05685	]	,
["Brola",	284.05984	]	,
["Bozko",	284.06283	]	,
["Borusiewicz",	284.06582	]	,
["Bonik",	284.06881	]	,
["Binienda",	284.0718	]	,
["Adolf",	284.07479	]	,
["Zjawiony",	284.07777	]	,
["Wyszecki",	284.08075	]	,
["Wysinski",	284.08373	]	,
["Wygas",	284.08671	]	,
["Wroniewicz",	284.08969	]	,
["Wincek",	284.09267	]	,
["Swidrak",	284.09565	]	,
["Szynszecki",	284.09863	]	,
["Szablowski",	284.10161	]	,
["Sumorek",	284.10459	]	,
["Subczynski",	284.10757	]	,
["Sobierski",	284.11055	]	,
["Skalmierski",	284.11353	]	,
["Serwotka",	284.11651	]	,
["Samulewski",	284.11949	]	,
["Rzap",	284.12247	]	,
["Rekawiecki",	284.12545	]	,
["Pleszczynski",	284.12843	]	,
["Pleskacz",	284.13141	]	,
["Petela",	284.13439	]	,
["Misiun",	284.13737	]	,
["Mamica",	284.14035	]	,
["Laszczynski",	284.14333	]	,
["Krawczyszyn",	284.14631	]	,
["Kolda",	284.14929	]	,
["Kil",	284.15227	]	,
["Karnas",	284.15525	]	,
["Kalfas",	284.15823	]	,
["Kakowski",	284.16121	]	,
["Harkot",	284.16419	]	,
["Gryzio",	284.16717	]	,
["Grajdek",	284.17015	]	,
["Gorzynski",	284.17313	]	,
["Gardynik",	284.17611	]	,
["Galaszewski",	284.17909	]	,
["Filanowski",	284.18207	]	,
["Faliszek",	284.18505	]	,
["Dziobak",	284.18803	]	,
["Dyrszka",	284.19101	]	,
["Domarus",	284.19399	]	,
["Dereniowski",	284.19697	]	,
["Ciezak",	284.19995	]	,
["Chamielec",	284.20293	]	,
["Cencora",	284.20591	]	,
["Bylinka",	284.20889	]	,
["Brunka",	284.21187	]	,
["Biedrowski",	284.21485	]	,
["Bagienski",	284.21783	]	,
["Zakieta",	284.2208	]	,
["Wolczek",	284.22377	]	,
["Winciorek",	284.22674	]	,
["Wawrzeniuk",	284.22971	]	,
["Uberman",	284.23268	]	,
["Teofilak",	284.23565	]	,
["Szyszkiewicz",	284.23862	]	,
["Strachowski",	284.24159	]	,
["Stemplowski",	284.24456	]	,
["Siejko",	284.24753	]	,
["Siegmund",	284.2505	]	,
["Sidel",	284.25347	]	,
["Sekalski",	284.25644	]	,
["Sacharuk",	284.25941	]	,
["Poswiatowski",	284.26238	]	,
["Poraj",	284.26535	]	,
["Pirowski",	284.26832	]	,
["Oskiera",	284.27129	]	,
["Ojrzanowski",	284.27426	]	,
["Odoj",	284.27723	]	,
["Noetzel",	284.2802	]	,
["Nabaglo",	284.28317	]	,
["Musialowicz",	284.28614	]	,
["Murdzek",	284.28911	]	,
["Mglosiek",	284.29208	]	,
["Maszka",	284.29505	]	,
["Malko",	284.29802	]	,
["Malczuk",	284.30099	]	,
["Majszczyk",	284.30396	]	,
["Kutzner",	284.30693	]	,
["Kuczka",	284.3099	]	,
["Kubel",	284.31287	]	,
["Koscianek",	284.31584	]	,
["Koscian",	284.31881	]	,
["Konczewski",	284.32178	]	,
["Klincewicz",	284.32475	]	,
["Klauze",	284.32772	]	,
["Hupka",	284.33069	]	,
["Hudyka",	284.33366	]	,
["Godzic",	284.33663	]	,
["Gerus",	284.3396	]	,
["Gasidlo",	284.34257	]	,
["Dlutowski",	284.34554	]	,
["Derus",	284.34851	]	,
["Czelen",	284.35148	]	,
["Ciez",	284.35445	]	,
["Chudak",	284.35742	]	,
["Blazinski",	284.36039	]	,
["Bednarowski",	284.36336	]	,
["Zgadzaj",	284.36632	]	,
["Zembrowski",	284.36928	]	,
["Wysowski",	284.37224	]	,
["Wolarz",	284.3752	]	,
["Tratkiewicz",	284.37816	]	,
["Todorowski",	284.38112	]	,
["Swiecki",	284.38408	]	,
["Szendera",	284.38704	]	,
["Stogowski",	284.39	]	,
["Smolag",	284.39296	]	,
["Sitnicki",	284.39592	]	,
["Sekscinski",	284.39888	]	,
["Samulewicz",	284.40184	]	,
["Saletnik",	284.4048	]	,
["Robaszewski",	284.40776	]	,
["Ramczyk",	284.41072	]	,
["Piszczyk",	284.41368	]	,
["Ozóg",	284.41664	]	,
["Oldziejewski",	284.4196	]	,
["Ogon",	284.42256	]	,
["Nieruchalski",	284.42552	]	,
["Molda",	284.42848	]	,
["Miklis",	284.43144	]	,
["Maslona",	284.4344	]	,
["Malyjurek",	284.43736	]	,
["Lubik",	284.44032	]	,
["Kwasniok",	284.44328	]	,
["Kropornicki",	284.44624	]	,
["Krezelewski",	284.4492	]	,
["Kostowski",	284.45216	]	,
["Korpala",	284.45512	]	,
["Klimuk",	284.45808	]	,
["Kawala",	284.46104	]	,
["Janczara",	284.464	]	,
["Janasiak",	284.46696	]	,
["Jacyszyn",	284.46992	]	,
["Hanczewski",	284.47288	]	,
["Gryczkowski",	284.47584	]	,
["Grycman",	284.4788	]	,
["Gromnicki",	284.48176	]	,
["Grochocinski",	284.48472	]	,
["Gres",	284.48768	]	,
["Gibalski",	284.49064	]	,
["Ganczarski",	284.4936	]	,
["Fras",	284.49656	]	,
["Erlich",	284.49952	]	,
["Dzierba",	284.50248	]	,
["Dyszewski",	284.50544	]	,
["Domzala",	284.5084	]	,
["Dobros",	284.51136	]	,
["Datta",	284.51432	]	,
["Czakanski",	284.51728	]	,
["Cieniewicz",	284.52024	]	,
["Chudyba",	284.5232	]	,
["Bysiek",	284.52616	]	,
["Buchala",	284.52912	]	,
["Boleslawski",	284.53208	]	,
["Betanski",	284.53504	]	,
["Balcerowiak",	284.538	]	,
["Zgliczynski",	284.54095	]	,
["Zembik",	284.5439	]	,
["Wyrozebski",	284.54685	]	,
["Wszelaka",	284.5498	]	,
["Tunski",	284.55275	]	,
["Tul",	284.5557	]	,
["Trafna",	284.55865	]	,
["Sztandarski",	284.5616	]	,
["Szkatulski",	284.56455	]	,
["Szczuraszek",	284.5675	]	,
["Sychta",	284.57045	]	,
["Stoppa",	284.5734	]	,
["Spruch",	284.57635	]	,
["Spandel",	284.5793	]	,
["Slabinski",	284.58225	]	,
["Siadak",	284.5852	]	,
["Sadlos",	284.58815	]	,
["Redo",	284.5911	]	,
["Radziejowski",	284.59405	]	,
["Prosniewski",	284.597	]	,
["Prostko",	284.59995	]	,
["Pirek",	284.6029	]	,
["Pigon",	284.60585	]	,
["Ostrzyzek",	284.6088	]	,
["Oryszczak",	284.61175	]	,
["Niszczak",	284.6147	]	,
["Mycka",	284.61765	]	,
["Miszka",	284.6206	]	,
["Medza",	284.62355	]	,
["Mazowiecki",	284.6265	]	,
["Matlega",	284.62945	]	,
["Manuszak",	284.6324	]	,
["Malatynski",	284.63535	]	,
["Luda",	284.6383	]	,
["Limanówka",	284.64125	]	,
["Kutyba",	284.6442	]	,
["Kuszpit",	284.64715	]	,
["Kuszak",	284.6501	]	,
["Kulisa",	284.65305	]	,
["Ksiazyk",	284.656	]	,
["Krzysztofczyk",	284.65895	]	,
["Krzemieniecki",	284.6619	]	,
["Komsa",	284.66485	]	,
["Kirstein",	284.6678	]	,
["Kaizer",	284.67075	]	,
["Jerominek",	284.6737	]	,
["Hnatiuk",	284.67665	]	,
["Gomoluch",	284.6796	]	,
["German",	284.68255	]	,
["Fruzynski",	284.6855	]	,
["Fluks",	284.68845	]	,
["Fandrejewski",	284.6914	]	,
["Dzienkowski",	284.69435	]	,
["Cepowski",	284.6973	]	,
["Brokowski",	284.70025	]	,
["Borzynski",	284.7032	]	,
["Bortel",	284.70615	]	,
["Bokwa",	284.7091	]	,
["Bilkiewicz",	284.71205	]	,
["Barys",	284.715	]	,
["Aleksy",	284.71795	]	,
["Wrosz",	284.72089	]	,
["Worytko",	284.72383	]	,
["Witanowski",	284.72677	]	,
["Wielec",	284.72971	]	,
["Waluszko",	284.73265	]	,
["Ulamek",	284.73559	]	,
["Tabat",	284.73853	]	,
["Szuberski",	284.74147	]	,
["Szponarski",	284.74441	]	,
["Szoma",	284.74735	]	,
["Stafinski",	284.75029	]	,
["Sokolski",	284.75323	]	,
["Salamucha",	284.75617	]	,
["Ryzak",	284.75911	]	,
["Rozwalka",	284.76205	]	,
["Rostankowski",	284.76499	]	,
["Rodewald",	284.76793	]	,
["Repczynski",	284.77087	]	,
["Ratajszczak",	284.77381	]	,
["Popieluch",	284.77675	]	,
["Ponczek",	284.77969	]	,
["Pomierski",	284.78263	]	,
["Podstawek",	284.78557	]	,
["Pietrala",	284.78851	]	,
["Nankiewicz",	284.79145	]	,
["Mocydlarz",	284.79439	]	,
["Manterys",	284.79733	]	,
["Lanski",	284.80027	]	,
["Kuliczkowski",	284.80321	]	,
["Kudyk",	284.80615	]	,
["Kruza",	284.80909	]	,
["Kozaczynski",	284.81203	]	,
["Komin",	284.81497	]	,
["Gomulak",	284.81791	]	,
["Gdak",	284.82085	]	,
["Fertala",	284.82379	]	,
["Fedyszyn",	284.82673	]	,
["Dachtera",	284.82967	]	,
["Czerniej",	284.83261	]	,
["Cierpich",	284.83555	]	,
["Cichacz",	284.83849	]	,
["Chrzestek",	284.84143	]	,
["Chabiera",	284.84437	]	,
["Bystra",	284.84731	]	,
["Bojarowski",	284.85025	]	,
["Blachuta",	284.85319	]	,
["Blacharski",	284.85613	]	,
["Bakowicz",	284.85907	]	,
["Bartlewski",	284.86201	]	,
["Bardzik",	284.86495	]	,
["Bamberski",	284.86789	]	,
["Bacior",	284.87083	]	,
["Zydziak",	284.87376	]	,
["Zawitkowski",	284.87669	]	,
["Zakrzewicz",	284.87962	]	,
["Zagdan",	284.88255	]	,
["Wiera",	284.88548	]	,
["Wawrzuta",	284.88841	]	,
["Wawerski",	284.89134	]	,
["Turyk",	284.89427	]	,
["Tempczyk",	284.8972	]	,
["Szwabowicz",	284.90013	]	,
["Szekalski",	284.90306	]	,
["Sygulski",	284.90599	]	,
["Suszycki",	284.90892	]	,
["Sumislawski",	284.91185	]	,
["Slusarczyk",	284.91478	]	,
["Skwiot",	284.91771	]	,
["Skotniczna",	284.92064	]	,
["Skiera",	284.92357	]	,
["Siewiorek",	284.9265	]	,
["Rozpondek",	284.92943	]	,
["Rombel",	284.93236	]	,
["Przydryga",	284.93529	]	,
["Proniewicz",	284.93822	]	,
["Pretkowski",	284.94115	]	,
["Praszczyk",	284.94408	]	,
["Porembski",	284.94701	]	,
["Polomka",	284.94994	]	,
["Pietraszuk",	284.95287	]	,
["Pawlewicz",	284.9558	]	,
["Paweski",	284.95873	]	,
["Najduk",	284.96166	]	,
["Michrowski",	284.96459	]	,
["Majrowski",	284.96752	]	,
["Lassak",	284.97045	]	,
["Kuglarz",	284.97338	]	,
["Kuciej",	284.97631	]	,
["Krulikowski",	284.97924	]	,
["Koterwa",	284.98217	]	,
["Kiluk",	284.9851	]	,
["Haski",	284.98803	]	,
["Grott",	284.99096	]	,
["Gehrke",	284.99389	]	,
["Frajer",	284.99682	]	,
["Erber",	284.99975	]	,
["Dzieciuchowicz",	285.00268	]	,
["Dynda",	285.00561	]	,
["Dwórznik",	285.00854	]	,
["Dominas",	285.01147	]	,
["Darkowski",	285.0144	]	,
["Dalak",	285.01733	]	,
["Cierpinski",	285.02026	]	,
["Chodur",	285.02319	]	,
["Burawski",	285.02612	]	,
["Buchman",	285.02905	]	,
["Biderman",	285.03198	]	,
["Balakier",	285.03491	]	,
["Arabasz",	285.03784	]	,
["Anasiewicz",	285.04077	]	,
["Zegota",	285.04369	]	,
["Zubkowicz",	285.04661	]	,
["Wlasiuk",	285.04953	]	,
["Wilgos",	285.05245	]	,
["Wilczok",	285.05537	]	,
["Waleka",	285.05829	]	,
["Trojnacki",	285.06121	]	,
["Swiatkowski",	285.06413	]	,
["Szremski",	285.06705	]	,
["Szczelina",	285.06997	]	,
["Stachacz",	285.07289	]	,
["Smura",	285.07581	]	,
["Smereczynski",	285.07873	]	,
["Skolasinski",	285.08165	]	,
["Sajdok",	285.08457	]	,
["Resler",	285.08749	]	,
["Psiuch",	285.09041	]	,
["Prokocki",	285.09333	]	,
["Plywaczewski",	285.09625	]	,
["Pietrusewicz",	285.09917	]	,
["Pegiel",	285.10209	]	,
["Persona",	285.10501	]	,
["Pardej",	285.10793	]	,
["Oleksiejuk",	285.11085	]	,
["Niewrzol",	285.11377	]	,
["Niedzwiedzinski",	285.11669	]	,
["Niedzwiedz",	285.11961	]	,
["Nawacki",	285.12253	]	,
["Muracki",	285.12545	]	,
["Mulica",	285.12837	]	,
["Marekwica",	285.13129	]	,
["Madejak",	285.13421	]	,
["Lobodziec",	285.13713	]	,
["Labunski",	285.14005	]	,
["Lichtarski",	285.14297	]	,
["Kukuc",	285.14589	]	,
["Kubka",	285.14881	]	,
["Krzebietke",	285.15173	]	,
["Kozlinski",	285.15465	]	,
["Kozarski",	285.15757	]	,
["Kazimierowski",	285.16049	]	,
["Kandyba",	285.16341	]	,
["Grzebieniak",	285.16633	]	,
["Grelewski",	285.16925	]	,
["Greiner",	285.17217	]	,
["Golygowski",	285.17509	]	,
["Gappa",	285.17801	]	,
["Gajdemski",	285.18093	]	,
["Durbas",	285.18385	]	,
["Dadok",	285.18677	]	,
["Czeszewski",	285.18969	]	,
["Chorobik",	285.19261	]	,
["Cacek",	285.19553	]	,
["Buslowski",	285.19845	]	,
["Brynski",	285.20137	]	,
["Bosko",	285.20429	]	,
["Bastrzyk",	285.20721	]	,
["Barbachowski",	285.21013	]	,
["Antkowicz",	285.21305	]	,
["Zelman",	285.21596	]	,
["Zakoscielna",	285.21887	]	,
["Zakierski",	285.22178	]	,
["Wygladacz",	285.22469	]	,
["Wujcik",	285.2276	]	,
["Walawender",	285.23051	]	,
["Tylki",	285.23342	]	,
["Smiglewski",	285.23633	]	,
["Smigasiewicz",	285.23924	]	,
["Stempka",	285.24215	]	,
["Stacha",	285.24506	]	,
["Samojlik",	285.24797	]	,
["Roczon",	285.25088	]	,
["Prusicki",	285.25379	]	,
["Organa",	285.2567	]	,
["Niedoba",	285.25961	]	,
["Nieciag",	285.26252	]	,
["Mytkowski",	285.26543	]	,
["Mulewski",	285.26834	]	,
["Moszkowicz",	285.27125	]	,
["Majoch",	285.27416	]	,
["Lukiewski",	285.27707	]	,
["Loks",	285.27998	]	,
["Krankowski",	285.28289	]	,
["Konwa",	285.2858	]	,
["Kadzinski",	285.28871	]	,
["Jóskowiak",	285.29162	]	,
["Harasimczuk",	285.29453	]	,
["Granieczny",	285.29744	]	,
["Graff",	285.30035	]	,
["Gracki",	285.30326	]	,
["Franielczyk",	285.30617	]	,
["Farat",	285.30908	]	,
["Dobaj",	285.31199	]	,
["Chodowski",	285.3149	]	,
["Borychowski",	285.31781	]	,
["Blatkiewicz",	285.32072	]	,
["Betiuk",	285.32363	]	,
["Badurski",	285.32654	]	,
["Adas",	285.32945	]	,
["Abucewicz",	285.33236	]	,
["Zyszkiewicz",	285.33526	]	,
["Wasilowski",	285.33816	]	,
["Uzdrowski",	285.34106	]	,
["Troc",	285.34396	]	,
["Tomilo",	285.34686	]	,
["Straub",	285.34976	]	,
["Stepa",	285.35266	]	,
["Smedzik",	285.35556	]	,
["Sawiak",	285.35846	]	,
["Salapatek",	285.36136	]	,
["Rozwora",	285.36426	]	,
["Pyszczak",	285.36716	]	,
["Poroszewski",	285.37006	]	,
["Pierzynowski",	285.37296	]	,
["Paszak",	285.37586	]	,
["Pakoca",	285.37876	]	,
["Oseka",	285.38166	]	,
["Omiecinski",	285.38456	]	,
["Niewiedzial",	285.38746	]	,
["Niesciur",	285.39036	]	,
["Namieta",	285.39326	]	,
["Nalezinski",	285.39616	]	,
["Matych",	285.39906	]	,
["Lont",	285.40196	]	,
["Kuzka",	285.40486	]	,
["Kuciapski",	285.40776	]	,
["Kuchcik",	285.41066	]	,
["Kubisa",	285.41356	]	,
["Krug",	285.41646	]	,
["Kreczko",	285.41936	]	,
["Klusak",	285.42226	]	,
["Jarmuszkiewicz",	285.42516	]	,
["Habryka",	285.42806	]	,
["Gust",	285.43096	]	,
["Grodziski",	285.43386	]	,
["Grewling",	285.43676	]	,
["Goyke",	285.43966	]	,
["Gogolok",	285.44256	]	,
["Frysztacki",	285.44546	]	,
["Flisnik",	285.44836	]	,
["Felusiak",	285.45126	]	,
["Erdmanski",	285.45416	]	,
["Dyrlaga",	285.45706	]	,
["Durbajlo",	285.45996	]	,
["Drzewosz",	285.46286	]	,
["Drozdzak",	285.46576	]	,
["Dereszewski",	285.46866	]	,
["Demarczyk",	285.47156	]	,
["Cyprys",	285.47446	]	,
["Cincio",	285.47736	]	,
["Chróst",	285.48026	]	,
["Bastian",	285.48316	]	,
["Basior",	285.48606	]	,
["Baracz",	285.48896	]	,
["Zuczkowski",	285.49185	]	,
["Wittstock",	285.49474	]	,
["Weimann",	285.49763	]	,
["Trzecinski",	285.50052	]	,
["Tobis",	285.50341	]	,
["Szwaczka",	285.5063	]	,
["Sufin",	285.50919	]	,
["Skwirowski",	285.51208	]	,
["Sabiniarz",	285.51497	]	,
["Ryncarz",	285.51786	]	,
["Rubas",	285.52075	]	,
["Roksela",	285.52364	]	,
["Robaszek",	285.52653	]	,
["Przezdziek",	285.52942	]	,
["Panak",	285.53231	]	,
["Okólski",	285.5352	]	,
["Ocieczek",	285.53809	]	,
["Nowinowski",	285.54098	]	,
["Nadziejko",	285.54387	]	,
["Muda",	285.54676	]	,
["Miturski",	285.54965	]	,
["Lubowski",	285.55254	]	,
["Lachendro",	285.55543	]	,
["Kutrzuba",	285.55832	]	,
["Korzun",	285.56121	]	,
["Kelner",	285.5641	]	,
["Guszpit",	285.56699	]	,
["Grzybicki",	285.56988	]	,
["Gorlikowski",	285.57277	]	,
["Gorczewski",	285.57566	]	,
["Glinicki",	285.57855	]	,
["Gasiorkiewicz",	285.58144	]	,
["Garbal",	285.58433	]	,
["Furmanczuk",	285.58722	]	,
["Firlag",	285.59011	]	,
["Farbaniec",	285.593	]	,
["Falasa",	285.59589	]	,
["Dziewinski",	285.59878	]	,
["Dzialoszynski",	285.60167	]	,
["Dobrzyn",	285.60456	]	,
["Dlutek",	285.60745	]	,
["Czuchaj",	285.61034	]	,
["Czarzasty",	285.61323	]	,
["Chochol",	285.61612	]	,
["Cemka",	285.61901	]	,
["Blajet",	285.6219	]	,
["Belicki",	285.62479	]	,
["Balchanowski",	285.62768	]	,
["Amerek",	285.63057	]	,
["Adasiak",	285.63346	]	,
["Wolodkowicz",	285.63634	]	,
["Werens",	285.63922	]	,
["Tyll",	285.6421	]	,
["Torczynski",	285.64498	]	,
["Tesiorowski",	285.64786	]	,
["Szalbierz",	285.65074	]	,
["Skowron",	285.65362	]	,
["Rochowski",	285.6565	]	,
["Rakus",	285.65938	]	,
["Rados",	285.66226	]	,
["Polikowski",	285.66514	]	,
["Piechuta",	285.66802	]	,
["Netter",	285.6709	]	,
["Myszczynski",	285.67378	]	,
["Molisak",	285.67666	]	,
["Marszycki",	285.67954	]	,
["Lyszczek",	285.68242	]	,
["Losik",	285.6853	]	,
["Linowiecki",	285.68818	]	,
["Lebuda",	285.69106	]	,
["Kwidzynski",	285.69394	]	,
["Kukuryk",	285.69682	]	,
["Krzysteczko",	285.6997	]	,
["Kolomyjski",	285.70258	]	,
["Kludka",	285.70546	]	,
["Kempiak",	285.70834	]	,
["Kazmierczuk",	285.71122	]	,
["Januszyk",	285.7141	]	,
["Hejmej",	285.71698	]	,
["Gadziala",	285.71986	]	,
["Ferek",	285.72274	]	,
["Dziergowski",	285.72562	]	,
["Dzialkowski",	285.7285	]	,
["Duleba",	285.73138	]	,
["Czarnynoga",	285.73426	]	,
["Chodzynski",	285.73714	]	,
["Biniszewski",	285.74002	]	,
["Batog",	285.7429	]	,
["Zawitowski",	285.74577	]	,
["Wiloch",	285.74864	]	,
["Werecki",	285.75151	]	,
["Wdówka",	285.75438	]	,
["Wawrzyniuk",	285.75725	]	,
["Turulski",	285.76012	]	,
["Trelka",	285.76299	]	,
["Tepper",	285.76586	]	,
["Szwaracki",	285.76873	]	,
["Szmul",	285.7716	]	,
["Stepaniak",	285.77447	]	,
["Starobrat",	285.77734	]	,
["Sloniowski",	285.78021	]	,
["Slodowski",	285.78308	]	,
["Rumianek",	285.78595	]	,
["Ropela",	285.78882	]	,
["Radzieta",	285.79169	]	,
["Potas",	285.79456	]	,
["Polerowicz",	285.79743	]	,
["Pasterczyk",	285.8003	]	,
["Olecki",	285.80317	]	,
["Nurski",	285.80604	]	,
["Nocula",	285.80891	]	,
["Michewicz",	285.81178	]	,
["Marszolik",	285.81465	]	,
["Luzna",	285.81752	]	,
["Labuzek",	285.82039	]	,
["Liro",	285.82326	]	,
["Liczkowski",	285.82613	]	,
["Lewy",	285.829	]	,
["Kutela",	285.83187	]	,
["Kupny",	285.83474	]	,
["Kubot",	285.83761	]	,
["Krajcer",	285.84048	]	,
["Kotlenga",	285.84335	]	,
["Korzynski",	285.84622	]	,
["Kopertowski",	285.84909	]	,
["Koncki",	285.85196	]	,
["Kois",	285.85483	]	,
["Kardel",	285.8577	]	,
["Kapcinski",	285.86057	]	,
["Jopkiewicz",	285.86344	]	,
["Ilkowski",	285.86631	]	,
["Hubisz",	285.86918	]	,
["Holewik",	285.87205	]	,
["Halinski",	285.87492	]	,
["Guzior",	285.87779	]	,
["Gramatyka",	285.88066	]	,
["Goleniowski",	285.88353	]	,
["Godzieba",	285.8864	]	,
["Ertman",	285.88927	]	,
["Dzedzej",	285.89214	]	,
["Denkowski",	285.89501	]	,
["Czarniawski",	285.89788	]	,
["Cieslawski",	285.90075	]	,
["Budzikowski",	285.90362	]	,
["Bryniak",	285.90649	]	,
["Breitkopf",	285.90936	]	,
["Bownik",	285.91223	]	,
["Anczewski",	285.9151	]	,
["Wyzuj",	285.91796	]	,
["Wronecki",	285.92082	]	,
["Warmowski",	285.92368	]	,
["Towpik",	285.92654	]	,
["Szymczyszyn",	285.9294	]	,
["Szewczynski",	285.93226	]	,
["Szczechla",	285.93512	]	,
["Sypka",	285.93798	]	,
["Sasiak",	285.94084	]	,
["Sarga",	285.9437	]	,
["Roszkowiak",	285.94656	]	,
["Ratke",	285.94942	]	,
["Puza",	285.95228	]	,
["Purchla",	285.95514	]	,
["Przymusinski",	285.958	]	,
["Pollak",	285.96086	]	,
["Plywaczyk",	285.96372	]	,
["Otrzasek",	285.96658	]	,
["Ofman",	285.96944	]	,
["Niechwiej",	285.9723	]	,
["Mozgala",	285.97516	]	,
["Mokros",	285.97802	]	,
["Mokosa",	285.98088	]	,
["Mojzesz",	285.98374	]	,
["Milaniuk",	285.9866	]	,
["Meredyk",	285.98946	]	,
["Mendelski",	285.99232	]	,
["Martenka",	285.99518	]	,
["Kudaj",	285.99804	]	,
["Krzemkowski",	286.0009	]	,
["Krakówka",	286.00376	]	,
["Kolibski",	286.00662	]	,
["Klosok",	286.00948	]	,
["Kiermaszek",	286.01234	]	,
["Karwinski",	286.0152	]	,
["Jur",	286.01806	]	,
["Jedryszek",	286.02092	]	,
["Jeromin",	286.02378	]	,
["Jadwiszczak",	286.02664	]	,
["Jachyra",	286.0295	]	,
["Herian",	286.03236	]	,
["Hanula",	286.03522	]	,
["Grynia",	286.03808	]	,
["Golata",	286.04094	]	,
["Goliat",	286.0438	]	,
["Fryska",	286.04666	]	,
["Fober",	286.04952	]	,
["Flejszman",	286.05238	]	,
["Filosek",	286.05524	]	,
["Dziubany",	286.0581	]	,
["Dziech",	286.06096	]	,
["Drozdalski",	286.06382	]	,
["Delag",	286.06668	]	,
["Czernikowski",	286.06954	]	,
["Czabaj",	286.0724	]	,
["Bulczynski",	286.07526	]	,
["Blaszko",	286.07812	]	,
["Bilewski",	286.08098	]	,
["Bec",	286.08384	]	,
["Banduch",	286.0867	]	,
["Banas",	286.08956	]	,
["Balka",	286.09242	]	,
["Amborski",	286.09528	]	,
["Akonom",	286.09814	]	,
["Zlobecki",	286.10099	]	,
["Zymek",	286.10384	]	,
["Zmarzla",	286.10669	]	,
["Zloty",	286.10954	]	,
["Zarzynski",	286.11239	]	,
["Wsól",	286.11524	]	,
["Wrazen",	286.11809	]	,
["Wojenka",	286.12094	]	,
["Wloka",	286.12379	]	,
["Wichniewicz",	286.12664	]	,
["Szor",	286.12949	]	,
["Szalewicz",	286.13234	]	,
["Syper",	286.13519	]	,
["Stal",	286.13804	]	,
["Siegert",	286.14089	]	,
["Sawczyn",	286.14374	]	,
["Rokoszewski",	286.14659	]	,
["Parylak",	286.14944	]	,
["Opic",	286.15229	]	,
["Odziemek",	286.15514	]	,
["Noskowiak",	286.15799	]	,
["Mieszkalski",	286.16084	]	,
["Mendala",	286.16369	]	,
["Lopuszanski",	286.16654	]	,
["Lezak",	286.16939	]	,
["Lubka",	286.17224	]	,
["Leonik",	286.17509	]	,
["Krecioch",	286.17794	]	,
["Kozacki",	286.18079	]	,
["Kowaleczko",	286.18364	]	,
["Kotela",	286.18649	]	,
["Koselski",	286.18934	]	,
["Korusiewicz",	286.19219	]	,
["Kondys",	286.19504	]	,
["Kolec",	286.19789	]	,
["Knot",	286.20074	]	,
["Kluga",	286.20359	]	,
["Kaduk",	286.20644	]	,
["Jarnicki",	286.20929	]	,
["Hubka",	286.21214	]	,
["Homoncik",	286.21499	]	,
["Hernes",	286.21784	]	,
["Helmecki",	286.22069	]	,
["Haluszka",	286.22354	]	,
["Gluszkiewicz",	286.22639	]	,
["Gamdzyk",	286.22924	]	,
["Drzystek",	286.23209	]	,
["Dams",	286.23494	]	,
["Czwórnóg",	286.23779	]	,
["Ciwinski",	286.24064	]	,
["Cichorz",	286.24349	]	,
["Chudzio",	286.24634	]	,
["Buszewski",	286.24919	]	,
["Boryca",	286.25204	]	,
["Bondarenko",	286.25489	]	,
["Bienczyk",	286.25774	]	,
["Belczowski",	286.26059	]	,
["Zgodzinski",	286.26343	]	,
["Zahorski",	286.26627	]	,
["Wlecial",	286.26911	]	,
["Warszawa",	286.27195	]	,
["Trzeciakiewicz",	286.27479	]	,
["Smigowski",	286.27763	]	,
["Szeloch",	286.28047	]	,
["Spocinski",	286.28331	]	,
["Skucha",	286.28615	]	,
["Selke",	286.28899	]	,
["Sarlej",	286.29183	]	,
["Rutana",	286.29467	]	,
["Redzia",	286.29751	]	,
["Rec",	286.30035	]	,
["Rafacz",	286.30319	]	,
["Pyk",	286.30603	]	,
["Pojasek",	286.30887	]	,
["Plewczynski",	286.31171	]	,
["Pietruch",	286.31455	]	,
["Pieronkiewicz",	286.31739	]	,
["Paczka",	286.32023	]	,
["Patocki",	286.32307	]	,
["Paron",	286.32591	]	,
["Orszewski",	286.32875	]	,
["Misiag",	286.33159	]	,
["Micielski",	286.33443	]	,
["Majta",	286.33727	]	,
["Machaczka",	286.34011	]	,
["Klimasz",	286.34295	]	,
["Kiecka",	286.34579	]	,
["Kantorowicz",	286.34863	]	,
["Kaciuba",	286.35147	]	,
["Jalkiewicz",	286.35431	]	,
["Hes",	286.35715	]	,
["Heda",	286.35999	]	,
["Hanuszkiewicz",	286.36283	]	,
["Grzebinski",	286.36567	]	,
["Grolewski",	286.36851	]	,
["Golubiewski",	286.37135	]	,
["Glapka",	286.37419	]	,
["Gietkowski",	286.37703	]	,
["Gierlinski",	286.37987	]	,
["Garbolinski",	286.38271	]	,
["Dyguda",	286.38555	]	,
["Chwastyk",	286.38839	]	,
["Chwast",	286.39123	]	,
["Cecotka",	286.39407	]	,
["Bryza",	286.39691	]	,
["Brembor",	286.39975	]	,
["Borzucki",	286.40259	]	,
["Belzowski",	286.40543	]	,
["Ball",	286.40827	]	,
["Zolek",	286.4111	]	,
["Zielaskowski",	286.41393	]	,
["Zembala",	286.41676	]	,
["Zaprawa",	286.41959	]	,
["Uchronski",	286.42242	]	,
["Trykowski",	286.42525	]	,
["Tereszczuk",	286.42808	]	,
["Teichert",	286.43091	]	,
["Smigaj",	286.43374	]	,
["Sliwecki",	286.43657	]	,
["Szwugier",	286.4394	]	,
["Szemiot",	286.44223	]	,
["Sysa",	286.44506	]	,
["Surowaniec",	286.44789	]	,
["Stebelski",	286.45072	]	,
["Silka",	286.45355	]	,
["Serdynski",	286.45638	]	,
["Salwerowicz",	286.45921	]	,
["Ratusznik",	286.46204	]	,
["Ranachowski",	286.46487	]	,
["Radziun",	286.4677	]	,
["Podlasin",	286.47053	]	,
["Pigla",	286.47336	]	,
["Pieczywek",	286.47619	]	,
["Ozorowski",	286.47902	]	,
["Osewski",	286.48185	]	,
["Olichwier",	286.48468	]	,
["Nielacna",	286.48751	]	,
["Musialkiewicz",	286.49034	]	,
["Mielczarczyk",	286.49317	]	,
["Micherda",	286.496	]	,
["Matej",	286.49883	]	,
["Mariak",	286.50166	]	,
["Macutkiewicz",	286.50449	]	,
["Leciej",	286.50732	]	,
["Kulasek",	286.51015	]	,
["Krzyszycha",	286.51298	]	,
["Krym",	286.51581	]	,
["Koprucki",	286.51864	]	,
["Knapkiewicz",	286.52147	]	,
["Klesta",	286.5243	]	,
["Katnik",	286.52713	]	,
["Kabus",	286.52996	]	,
["Jutrzenka-Trzebiatowski",	286.53279	]	,
["Juchno",	286.53562	]	,
["Jaksender",	286.53845	]	,
["Hadamik",	286.54128	]	,
["Habowski",	286.54411	]	,
["Grygowski",	286.54694	]	,
["Gralik",	286.54977	]	,
["Gellert",	286.5526	]	,
["Dziamara",	286.55543	]	,
["Dubisz",	286.55826	]	,
["Dedek",	286.56109	]	,
["Cwieczek",	286.56392	]	,
["Czernichowski",	286.56675	]	,
["Clapka",	286.56958	]	,
["Cholerzynski",	286.57241	]	,
["Bujanowicz",	286.57524	]	,
["Behnke",	286.57807	]	,
["Bartusik",	286.5809	]	,
["Artyszuk",	286.58373	]	,
["Zardecki",	286.58655	]	,
["Wojtacki",	286.58937	]	,
["Wasiek",	286.59219	]	,
["Trzesowski",	286.59501	]	,
["Sniadowski",	286.59783	]	,
["Szpil",	286.60065	]	,
["Szelenbaum",	286.60347	]	,
["Subda",	286.60629	]	,
["Strojewski",	286.60911	]	,
["Snopkiewicz",	286.61193	]	,
["Slodki",	286.61475	]	,
["Sipinski",	286.61757	]	,
["Silkowski",	286.62039	]	,
["Siebielec",	286.62321	]	,
["Ryglewicz",	286.62603	]	,
["Rodkiewicz",	286.62885	]	,
["Proc",	286.63167	]	,
["Palucha",	286.63449	]	,
["Pachota",	286.63731	]	,
["Ocipka",	286.64013	]	,
["Ochrymiuk",	286.64295	]	,
["Norberciak",	286.64577	]	,
["Naskrecki",	286.64859	]	,
["Mojescik",	286.65141	]	,
["Miechowski",	286.65423	]	,
["Marusiak",	286.65705	]	,
["Luszczki",	286.65987	]	,
["Lazuka",	286.66269	]	,
["Lietz",	286.66551	]	,
["Kurasik",	286.66833	]	,
["Kulbat",	286.67115	]	,
["Kubas",	286.67397	]	,
["Kryn",	286.67679	]	,
["Kruszczak",	286.67961	]	,
["Kornicki",	286.68243	]	,
["Korkus",	286.68525	]	,
["Kluczka",	286.68807	]	,
["Klaczek",	286.69089	]	,
["Kiczynski",	286.69371	]	,
["Karlicki",	286.69653	]	,
["Kaplinski",	286.69935	]	,
["Kaminiarz",	286.70217	]	,
["Hendrysiak",	286.70499	]	,
["Hamerlik",	286.70781	]	,
["Grzeca",	286.71063	]	,
["Grusiecki",	286.71345	]	,
["Gralla",	286.71627	]	,
["Górko",	286.71909	]	,
["Gawluk",	286.72191	]	,
["Gaszczyk",	286.72473	]	,
["Furmanowicz",	286.72755	]	,
["Frydryszak",	286.73037	]	,
["Ekstowicz",	286.73319	]	,
["Dydymski",	286.73601	]	,
["Durkowski",	286.73883	]	,
["Duklas",	286.74165	]	,
["Danych",	286.74447	]	,
["Chromiak",	286.74729	]	,
["Chlapek",	286.75011	]	,
["Chilczuk",	286.75293	]	,
["Celebucki",	286.75575	]	,
["Brzyk",	286.75857	]	,
["Brzezicha",	286.76139	]	,
["Blajda",	286.76421	]	,
["Barchan",	286.76703	]	,
["Andrych",	286.76985	]	,
["Wolczuk",	286.77266	]	,
["Wiczling",	286.77547	]	,
["Wegielewski",	286.77828	]	,
["Walenta",	286.78109	]	,
["Turewicz",	286.7839	]	,
["Tkaczewski",	286.78671	]	,
["Szymak",	286.78952	]	,
["Sztark",	286.79233	]	,
["Szacilowski",	286.79514	]	,
["Stanilewicz",	286.79795	]	,
["Smarz",	286.80076	]	,
["Skrzypecki",	286.80357	]	,
["Singer",	286.80638	]	,
["Siedliski",	286.80919	]	,
["Serwata",	286.812	]	,
["Rynio",	286.81481	]	,
["Rurek",	286.81762	]	,
["Rozniatowski",	286.82043	]	,
["Rosek",	286.82324	]	,
["Pyrda",	286.82605	]	,
["Powidel",	286.82886	]	,
["Popardowski",	286.83167	]	,
["Pazdzierz",	286.83448	]	,
["Parecki",	286.83729	]	,
["Osiadly",	286.8401	]	,
["Ojdowski",	286.84291	]	,
["Ogarek",	286.84572	]	,
["Nowosiad",	286.84853	]	,
["Niemiro",	286.85134	]	,
["Mojsiewicz",	286.85415	]	,
["Mizura",	286.85696	]	,
["Masiukiewicz",	286.85977	]	,
["Mamon",	286.86258	]	,
["Lipowiecki",	286.86539	]	,
["Lembas",	286.8682	]	,
["Kurlowicz",	286.87101	]	,
["Kukowka",	286.87382	]	,
["Krosny",	286.87663	]	,
["Kraskowski",	286.87944	]	,
["Kopczak",	286.88225	]	,
["Koniusz",	286.88506	]	,
["Kocieba",	286.88787	]	,
["Kobiec",	286.89068	]	,
["Kmiolek",	286.89349	]	,
["Kekus",	286.8963	]	,
["Kartasinski",	286.89911	]	,
["Kapek",	286.90192	]	,
["Kaldowski",	286.90473	]	,
["Jerzy",	286.90754	]	,
["Jeleniewicz",	286.91035	]	,
["Jarmulowicz",	286.91316	]	,
["Horodenski",	286.91597	]	,
["Harazin",	286.91878	]	,
["Ferszt",	286.92159	]	,
["Dziubecki",	286.9244	]	,
["Dymacz",	286.92721	]	,
["Dulkiewicz",	286.93002	]	,
["Dudaczyk",	286.93283	]	,
["Daca",	286.93564	]	,
["Czarczynski",	286.93845	]	,
["Cytacki",	286.94126	]	,
["Cipkowski",	286.94407	]	,
["Bolik",	286.94688	]	,
["Beksa",	286.94969	]	,
["Badek",	286.9525	]	,
["Anisiewicz",	286.95531	]	,
["Zabowski",	286.95811	]	,
["Zieleznik",	286.96091	]	,
["Wojdowski",	286.96371	]	,
["Wladzinski",	286.96651	]	,
["Walkowski",	286.96931	]	,
["Walichnowski",	286.97211	]	,
["Usowicz",	286.97491	]	,
["Tys",	286.97771	]	,
["Swircz",	286.98051	]	,
["Sniecinski",	286.98331	]	,
["Szwejda",	286.98611	]	,
["Szum",	286.98891	]	,
["Szulczynski",	286.99171	]	,
["Szer",	286.99451	]	,
["Suslik",	286.99731	]	,
["Stempniewski",	287.00011	]	,
["Steliga",	287.00291	]	,
["Stefanicki",	287.00571	]	,
["Smuk",	287.00851	]	,
["Sarkowicz",	287.01131	]	,
["Rycabel",	287.01411	]	,
["Rojowski",	287.01691	]	,
["Renk",	287.01971	]	,
["Rawiak",	287.02251	]	,
["Pyta",	287.02531	]	,
["Prylinski",	287.02811	]	,
["Predkiewicz",	287.03091	]	,
["Poks",	287.03371	]	,
["Pilitowski",	287.03651	]	,
["Pietrusa",	287.03931	]	,
["Pezik",	287.04211	]	,
["Pawlikiewicz",	287.04491	]	,
["Parchimowicz",	287.04771	]	,
["Pachacz",	287.05051	]	,
["Normantowicz",	287.05331	]	,
["Lyszkowicz",	287.05611	]	,
["Lezniak",	287.05891	]	,
["Lipien",	287.06171	]	,
["Ligman",	287.06451	]	,
["Kurpias",	287.06731	]	,
["Kodzis",	287.07011	]	,
["Klósko",	287.07291	]	,
["Kinas",	287.07571	]	,
["Kauf",	287.07851	]	,
["Jugo",	287.08131	]	,
["Jarmusz",	287.08411	]	,
["Gudyka",	287.08691	]	,
["Glura",	287.08971	]	,
["Fiet",	287.09251	]	,
["Falda",	287.09531	]	,
["Blaszczykiewicz",	287.09811	]	,
["Beski",	287.10091	]	,
["Barszczowski",	287.10371	]	,
["Zurawek",	287.1065	]	,
["Zasina",	287.10929	]	,
["Wierczynski",	287.11208	]	,
["Weyer",	287.11487	]	,
["Werno",	287.11766	]	,
["Wawryszczuk",	287.12045	]	,
["Wais",	287.12324	]	,
["Tobolewski",	287.12603	]	,
["Tatko",	287.12882	]	,
["Targaszewski",	287.13161	]	,
["Swis",	287.1344	]	,
["Supel",	287.13719	]	,
["Sopniewski",	287.13998	]	,
["Sobas",	287.14277	]	,
["Skurzewski",	287.14556	]	,
["Rumniak",	287.14835	]	,
["Pyl",	287.15114	]	,
["Plaskowski",	287.15393	]	,
["Pinas",	287.15672	]	,
["Pielucha",	287.15951	]	,
["Parafinski",	287.1623	]	,
["Oracki",	287.16509	]	,
["Ojdana",	287.16788	]	,
["Obluski",	287.17067	]	,
["Nawrocik",	287.17346	]	,
["Nasternak",	287.17625	]	,
["Montowski",	287.17904	]	,
["Lajszczak",	287.18183	]	,
["Leonczyk",	287.18462	]	,
["Krysztof",	287.18741	]	,
["Komaniecki",	287.1902	]	,
["Jodlowiec",	287.19299	]	,
["Jazwiecki",	287.19578	]	,
["Idziorek",	287.19857	]	,
["Gryzik",	287.20136	]	,
["Gruszeczka",	287.20415	]	,
["Grubiak",	287.20694	]	,
["Golba",	287.20973	]	,
["Glombik",	287.21252	]	,
["Futoma",	287.21531	]	,
["Figler",	287.2181	]	,
["Fieden",	287.22089	]	,
["Dyrbus",	287.22368	]	,
["Durko",	287.22647	]	,
["Drózka",	287.22926	]	,
["Drewnicki",	287.23205	]	,
["Butkowski",	287.23484	]	,
["Buszek",	287.23763	]	,
["Burnicki",	287.24042	]	,
["Broniec",	287.24321	]	,
["Brenda",	287.246	]	,
["Boros",	287.24879	]	,
["Boc",	287.25158	]	,
["Bargiela",	287.25437	]	,
["Barelkowski",	287.25716	]	,
["Zerek",	287.25994	]	,
["Wilim",	287.26272	]	,
["Wargula",	287.2655	]	,
["Waberski",	287.26828	]	,
["Tybor",	287.27106	]	,
["Tworus",	287.27384	]	,
["Tomas",	287.27662	]	,
["Szpalerski",	287.2794	]	,
["Szombara",	287.28218	]	,
["Szarecki",	287.28496	]	,
["Sulicki",	287.28774	]	,
["Smejda",	287.29052	]	,
["Skwarzynski",	287.2933	]	,
["Skula",	287.29608	]	,
["Serej",	287.29886	]	,
["Samon",	287.30164	]	,
["Remlein",	287.30442	]	,
["Redestowicz",	287.3072	]	,
["Pulik",	287.30998	]	,
["Pietrowiak",	287.31276	]	,
["Otczyk",	287.31554	]	,
["Osek",	287.31832	]	,
["Okos",	287.3211	]	,
["Odrowaz",	287.32388	]	,
["Nienajadlo",	287.32666	]	,
["Mysliwczyk",	287.32944	]	,
["Muzyczka",	287.33222	]	,
["Moskwik",	287.335	]	,
["Medrecki",	287.33778	]	,
["Malanowicz",	287.34056	]	,
["Liniewicz",	287.34334	]	,
["Krycia",	287.34612	]	,
["Kowaliszyn",	287.3489	]	,
["Kotylak",	287.35168	]	,
["Kostewicz",	287.35446	]	,
["Kosmaczewski",	287.35724	]	,
["Koronka",	287.36002	]	,
["Kocieniewski",	287.3628	]	,
["Kanty",	287.36558	]	,
["Kaldunek",	287.36836	]	,
["Kaczykowski",	287.37114	]	,
["Kacprzykowski",	287.37392	]	,
["Jeczen",	287.3767	]	,
["Has",	287.37948	]	,
["Gurda",	287.38226	]	,
["Gorczowski",	287.38504	]	,
["Gargul",	287.38782	]	,
["Frackowski",	287.3906	]	,
["Franckiewicz",	287.39338	]	,
["Fabisiewicz",	287.39616	]	,
["Dubiak",	287.39894	]	,
["Domina",	287.40172	]	,
["Czora",	287.4045	]	,
["Curzytek",	287.40728	]	,
["Chwaliszewski",	287.41006	]	,
["Chlebna",	287.41284	]	,
["Bucon",	287.41562	]	,
["Berdowski",	287.4184	]	,
["Babisz",	287.42118	]	,
["Zemczak",	287.42395	]	,
["Zdziechowicz",	287.42672	]	,
["Wirkowski",	287.42949	]	,
["Weyna",	287.43226	]	,
["Ujejski",	287.43503	]	,
["Tybus",	287.4378	]	,
["Turchan",	287.44057	]	,
["Terefenko",	287.44334	]	,
["Tandek",	287.44611	]	,
["Sorys",	287.44888	]	,
["Skopowski",	287.45165	]	,
["Safaryn",	287.45442	]	,
["Pypno",	287.45719	]	,
["Potakowski",	287.45996	]	,
["Pikulinski",	287.46273	]	,
["Olasek",	287.4655	]	,
["Ochlik",	287.46827	]	,
["Nagla",	287.47104	]	,
["Monica",	287.47381	]	,
["Madrak",	287.47658	]	,
["Lodowski",	287.47935	]	,
["Lenckowski",	287.48212	]	,
["Legat",	287.48489	]	,
["Krasna",	287.48766	]	,
["Kozajda",	287.49043	]	,
["Kompa",	287.4932	]	,
["Kolpacki",	287.49597	]	,
["Klosa",	287.49874	]	,
["Kiljanczyk",	287.50151	]	,
["Horba",	287.50428	]	,
["Hola",	287.50705	]	,
["Harasymowicz",	287.50982	]	,
["Gurtatowski",	287.51259	]	,
["Gulbinowicz",	287.51536	]	,
["Grychtol",	287.51813	]	,
["Grula",	287.5209	]	,
["Gielata",	287.52367	]	,
["Filipecki",	287.52644	]	,
["Fesser",	287.52921	]	,
["Echaust",	287.53198	]	,
["Dziemianczuk",	287.53475	]	,
["Dybinski",	287.53752	]	,
["Dwornikowski",	287.54029	]	,
["Cwiertniak",	287.54306	]	,
["Cizio",	287.54583	]	,
["Chodyniecki",	287.5486	]	,
["Bronowicz",	287.55137	]	,
["Bernys",	287.55414	]	,
["Barwinek",	287.55691	]	,
["Badowicz",	287.55968	]	,
["Zydowicz",	287.56244	]	,
["Zoch",	287.5652	]	,
["Ziolek",	287.56796	]	,
["Zenderowski",	287.57072	]	,
["Zembron",	287.57348	]	,
["Zamlynski",	287.57624	]	,
["Zachaj",	287.579	]	,
["Wydrowski",	287.58176	]	,
["Wawreniuk",	287.58452	]	,
["Urbankiewicz",	287.58728	]	,
["Ucher",	287.59004	]	,
["Swietlikowski",	287.5928	]	,
["Szwejk",	287.59556	]	,
["Szalak",	287.59832	]	,
["Swed",	287.60108	]	,
["Strumillo",	287.60384	]	,
["Stekla",	287.6066	]	,
["Sokolowicz",	287.60936	]	,
["Silakowski",	287.61212	]	,
["Siemieniecki",	287.61488	]	,
["Siama",	287.61764	]	,
["Samel",	287.6204	]	,
["Rzemieniecki",	287.62316	]	,
["Rylo",	287.62592	]	,
["Rekosz",	287.62868	]	,
["Rauk",	287.63144	]	,
["Przetacznik",	287.6342	]	,
["Pozarlik",	287.63696	]	,
["Polesiak",	287.63972	]	,
["Podebski",	287.64248	]	,
["Pasternacki",	287.64524	]	,
["Palik",	287.648	]	,
["Paczesny",	287.65076	]	,
["Ogorzelec",	287.65352	]	,
["Nadlonek",	287.65628	]	,
["Mizerek",	287.65904	]	,
["Migus",	287.6618	]	,
["Machniewski",	287.66456	]	,
["Lazuga",	287.66732	]	,
["Luber",	287.67008	]	,
["Lenz",	287.67284	]	,
["Kuczyk",	287.6756	]	,
["Krzeczowski",	287.67836	]	,
["Kosciuczuk",	287.68112	]	,
["Knapp",	287.68388	]	,
["Klementowicz",	287.68664	]	,
["Kleist",	287.6894	]	,
["Klarzynski",	287.69216	]	,
["Kilijanek",	287.69492	]	,
["Kielek",	287.69768	]	,
["Jesion",	287.70044	]	,
["Jackowicz",	287.7032	]	,
["Hryniewski",	287.70596	]	,
["Hadzik",	287.70872	]	,
["Gorynia",	287.71148	]	,
["Gondro",	287.71424	]	,
["Gluszczyk",	287.717	]	,
["Glowaczewski",	287.71976	]	,
["Glugla",	287.72252	]	,
["Gembal",	287.72528	]	,
["Gawarkiewicz",	287.72804	]	,
["Gabala",	287.7308	]	,
["Filewicz",	287.73356	]	,
["Drys",	287.73632	]	,
["Dobinski",	287.73908	]	,
["Dereszkiewicz",	287.74184	]	,
["Burdzik",	287.7446	]	,
["Bodera",	287.74736	]	,
["Bereszynski",	287.75012	]	,
["Baskiewicz",	287.75288	]	,
["Afeltowicz",	287.75564	]	,
["Woznik",	287.75839	]	,
["Wielgocki",	287.76114	]	,
["Wawrzusiszyn",	287.76389	]	,
["Ulas",	287.76664	]	,
["Tusien",	287.76939	]	,
["Szulewski",	287.77214	]	,
["Sutryk",	287.77489	]	,
["Surmacki",	287.77764	]	,
["Sucholas",	287.78039	]	,
["Strycharczuk",	287.78314	]	,
["Stasiek",	287.78589	]	,
["Solan",	287.78864	]	,
["Sobko",	287.79139	]	,
["Schabowicz",	287.79414	]	,
["Saltarski",	287.79689	]	,
["Rypien",	287.79964	]	,
["Przegalinski",	287.80239	]	,
["Pop",	287.80514	]	,
["Pietrolaj",	287.80789	]	,
["Pech",	287.81064	]	,
["Paszkiet",	287.81339	]	,
["Orylski",	287.81614	]	,
["Olechowicz",	287.81889	]	,
["Malich",	287.82164	]	,
["Latanowicz",	287.82439	]	,
["Kurasiak",	287.82714	]	,
["Kumorowski",	287.82989	]	,
["Kremser",	287.83264	]	,
["Korpysa",	287.83539	]	,
["Knera",	287.83814	]	,
["Knafel",	287.84089	]	,
["Klonek",	287.84364	]	,
["Kilianczyk",	287.84639	]	,
["Juzyszyn",	287.84914	]	,
["Jemielniak",	287.85189	]	,
["Jasnosz",	287.85464	]	,
["Harabasz",	287.85739	]	,
["Hanysz",	287.86014	]	,
["Gurazda",	287.86289	]	,
["Ganc",	287.86564	]	,
["Galeziewski",	287.86839	]	,
["Gadacz",	287.87114	]	,
["Furgalski",	287.87389	]	,
["Frychel",	287.87664	]	,
["Feja",	287.87939	]	,
["Eksterowicz",	287.88214	]	,
["Dras",	287.88489	]	,
["Cyrta",	287.88764	]	,
["Chabraszewski",	287.89039	]	,
["Buller",	287.89314	]	,
["Brudniak",	287.89589	]	,
["Bochat",	287.89864	]	,
["Bocek",	287.90139	]	,
["Birycki",	287.90414	]	,
["Andryszewski",	287.90689	]	,
["Wyczawski",	287.90963	]	,
["Wolniakowski",	287.91237	]	,
["Wasak",	287.91511	]	,
["Wachocki",	287.91785	]	,
["Usarz",	287.92059	]	,
["Trendel",	287.92333	]	,
["Termin",	287.92607	]	,
["Szylak",	287.92881	]	,
["Szut",	287.93155	]	,
["Szczepanowicz",	287.93429	]	,
["Suchowierski",	287.93703	]	,
["Skimina",	287.93977	]	,
["Skalec",	287.94251	]	,
["Siebor",	287.94525	]	,
["Sierny",	287.94799	]	,
["Pykacz",	287.95073	]	,
["Puziak",	287.95347	]	,
["Prochon",	287.95621	]	,
["Pikosz",	287.95895	]	,
["Pachel",	287.96169	]	,
["Nogas",	287.96443	]	,
["Nepelski",	287.96717	]	,
["Nakoneczna",	287.96991	]	,
["Molas",	287.97265	]	,
["Misinski",	287.97539	]	,
["Mioduski",	287.97813	]	,
["Macik",	287.98087	]	,
["Matyniak",	287.98361	]	,
["Maleta",	287.98635	]	,
["Makola",	287.98909	]	,
["Madajewski",	287.99183	]	,
["Lawreszuk",	287.99457	]	,
["Licbarski",	287.99731	]	,
["Krychowski",	288.00005	]	,
["Krus",	288.00279	]	,
["Kosterna",	288.00553	]	,
["Kollataj",	288.00827	]	,
["Kmin",	288.01101	]	,
["Klewek",	288.01375	]	,
["Klawitter",	288.01649	]	,
["Karpiej",	288.01923	]	,
["Karauda",	288.02197	]	,
["Ignarski",	288.02471	]	,
["Haponik",	288.02745	]	,
["Granatowski",	288.03019	]	,
["Gazdzicki",	288.03293	]	,
["Galas",	288.03567	]	,
["Forczek",	288.03841	]	,
["Dybikowski",	288.04115	]	,
["Drozdzyk",	288.04389	]	,
["Dorszewski",	288.04663	]	,
["Cwieka",	288.04937	]	,
["Czynsz",	288.05211	]	,
["Cybart",	288.05485	]	,
["Cislak",	288.05759	]	,
["Cipior",	288.06033	]	,
["Ciesliczka",	288.06307	]	,
["Chruscielewski",	288.06581	]	,
["Chlebus",	288.06855	]	,
["Chanas",	288.07129	]	,
["Borysowski",	288.07403	]	,
["Balwas",	288.07677	]	,
["Archutowski",	288.07951	]	,
["Zorychta",	288.08224	]	,
["Wyczalkowski",	288.08497	]	,
["Wodka",	288.0877	]	,
["Wochowski",	288.09043	]	,
["Witosz",	288.09316	]	,
["Wierus",	288.09589	]	,
["Wegiera",	288.09862	]	,
["Wawrocki",	288.10135	]	,
["Wardas",	288.10408	]	,
["Tchorek",	288.10681	]	,
["Snitko",	288.10954	]	,
["Strehlau",	288.11227	]	,
["Stompor",	288.115	]	,
["Stachel",	288.11773	]	,
["Sloniec",	288.12046	]	,
["Siadkowski",	288.12319	]	,
["Rostowski",	288.12592	]	,
["Raszeja",	288.12865	]	,
["Ramczykowski",	288.13138	]	,
["Puszynski",	288.13411	]	,
["Prysak",	288.13684	]	,
["Poniecki",	288.13957	]	,
["Plinski",	288.1423	]	,
["Pilny",	288.14503	]	,
["Paskiewicz",	288.14776	]	,
["Olewski",	288.15049	]	,
["Narolewski",	288.15322	]	,
["Matwij",	288.15595	]	,
["Materzok",	288.15868	]	,
["Macala",	288.16141	]	,
["Lukanowski",	288.16414	]	,
["Laszczuk",	288.16687	]	,
["Koziróg",	288.1696	]	,
["Kopowski",	288.17233	]	,
["Knysz",	288.17506	]	,
["Jedrachowicz",	288.17779	]	,
["Jasny",	288.18052	]	,
["Jasiolek",	288.18325	]	,
["Iwachów",	288.18598	]	,
["Hipsz",	288.18871	]	,
["Haczkiewicz",	288.19144	]	,
["Gorzela",	288.19417	]	,
["Gladecki",	288.1969	]	,
["Gieniec",	288.19963	]	,
["Fiedorczyk",	288.20236	]	,
["Fasciszewski",	288.20509	]	,
["Ehlert",	288.20782	]	,
["Dziwosz",	288.21055	]	,
["Dworczynski",	288.21328	]	,
["Drapa",	288.21601	]	,
["Damaszke",	288.21874	]	,
["Cwajda",	288.22147	]	,
["Chyba",	288.2242	]	,
["Chwiecko",	288.22693	]	,
["Burgiel",	288.22966	]	,
["Budziarek",	288.23239	]	,
["Boniewicz",	288.23512	]	,
["Blazkowski",	288.23785	]	,
["Blazewski",	288.24058	]	,
["Bilek",	288.24331	]	,
["Baziak",	288.24604	]	,
["Bayer",	288.24877	]	,
["Arentowicz",	288.2515	]	,
["Antolik",	288.25423	]	,
["Zwierzchlewski",	288.25695	]	,
["Zielecki",	288.25967	]	,
["Zagacki",	288.26239	]	,
["Wyrwol",	288.26511	]	,
["Wyjadlowski",	288.26783	]	,
["Wegrzycki",	288.27055	]	,
["Wedzinski",	288.27327	]	,
["Unger",	288.27599	]	,
["Tetlak",	288.27871	]	,
["Tacik",	288.28143	]	,
["Swieconek",	288.28415	]	,
["Szymbor",	288.28687	]	,
["Szulzycki",	288.28959	]	,
["Stancel",	288.29231	]	,
["Splitt",	288.29503	]	,
["Sobeczko",	288.29775	]	,
["Sep",	288.30047	]	,
["Rytka",	288.30319	]	,
["Ryt",	288.30591	]	,
["Rybialek",	288.30863	]	,
["Rebecki",	288.31135	]	,
["Probierz",	288.31407	]	,
["Poteralski",	288.31679	]	,
["Pietrow",	288.31951	]	,
["Piechówka",	288.32223	]	,
["Pianowski",	288.32495	]	,
["Pawlucki",	288.32767	]	,
["Pantol",	288.33039	]	,
["Ostrouch",	288.33311	]	,
["Olkuski",	288.33583	]	,
["Noweta",	288.33855	]	,
["Nalikowski",	288.34127	]	,
["Mospinek",	288.34399	]	,
["Mintus",	288.34671	]	,
["Lyszczak",	288.34943	]	,
["Leppert",	288.35215	]	,
["Kulasa",	288.35487	]	,
["Kuczmanski",	288.35759	]	,
["Krzykwa",	288.36031	]	,
["Krepski",	288.36303	]	,
["Krebs",	288.36575	]	,
["Komodzinski",	288.36847	]	,
["Kolowski",	288.37119	]	,
["Kleba",	288.37391	]	,
["Kilianek",	288.37663	]	,
["Kander",	288.37935	]	,
["Jedrysek",	288.38207	]	,
["Jeger",	288.38479	]	,
["Jarzmik",	288.38751	]	,
["Jarszak",	288.39023	]	,
["Jank",	288.39295	]	,
["Hryncewicz",	288.39567	]	,
["Hrycyk",	288.39839	]	,
["Hejnowicz",	288.40111	]	,
["Hatlas",	288.40383	]	,
["Guzal",	288.40655	]	,
["Gulba",	288.40927	]	,
["Górczyk",	288.41199	]	,
["Golko",	288.41471	]	,
["Fitowski",	288.41743	]	,
["Doleglo",	288.42015	]	,
["Czyszek",	288.42287	]	,
["Czmok",	288.42559	]	,
["Chomont",	288.42831	]	,
["Cholewczynski",	288.43103	]	,
["Chocholek",	288.43375	]	,
["Brzana",	288.43647	]	,
["Bondos",	288.43919	]	,
["Blaszczykowski",	288.44191	]	,
["Blonkowski",	288.44463	]	,
["Bereski",	288.44735	]	,
["Bagnowski",	288.45007	]	,
["Artymiak",	288.45279	]	,
["Adaszek",	288.45551	]	,
["Zólty",	288.45822	]	,
["Zolnierczuk",	288.46093	]	,
["Zgarda",	288.46364	]	,
["Zarówny",	288.46635	]	,
["Wilkanowski",	288.46906	]	,
["Warta",	288.47177	]	,
["Wanicki",	288.47448	]	,
["Ulczok",	288.47719	]	,
["Ulaszewski",	288.4799	]	,
["Tymoczko",	288.48261	]	,
["Tycner",	288.48532	]	,
["Tuchalski",	288.48803	]	,
["Sciborowski",	288.49074	]	,
["Szymandera",	288.49345	]	,
["Skocki",	288.49616	]	,
["Siedlak",	288.49887	]	,
["Seemann",	288.50158	]	,
["Santorek",	288.50429	]	,
["Salicki",	288.507	]	,
["Sajecki",	288.50971	]	,
["Roguszewski",	288.51242	]	,
["Rega",	288.51513	]	,
["Ral",	288.51784	]	,
["Pulut",	288.52055	]	,
["Pranczk",	288.52326	]	,
["Polarczyk",	288.52597	]	,
["Owedyk",	288.52868	]	,
["Ostromecki",	288.53139	]	,
["Ochodek",	288.5341	]	,
["Nester",	288.53681	]	,
["Najmola",	288.53952	]	,
["Mazuchowski",	288.54223	]	,
["Manaj",	288.54494	]	,
["Machera",	288.54765	]	,
["Ladon",	288.55036	]	,
["Lutkowski",	288.55307	]	,
["Lewsza",	288.55578	]	,
["Lesków",	288.55849	]	,
["Larek",	288.5612	]	,
["Kustosik",	288.56391	]	,
["Kucko",	288.56662	]	,
["Królewski",	288.56933	]	,
["Kopp",	288.57204	]	,
["Koperniak",	288.57475	]	,
["Kirchner",	288.57746	]	,
["Kijanski",	288.58017	]	,
["Kajfasz",	288.58288	]	,
["Jarmakowski",	288.58559	]	,
["Janowiec",	288.5883	]	,
["Imielinski",	288.59101	]	,
["Hoeft",	288.59372	]	,
["Haas",	288.59643	]	,
["Gugulski",	288.59914	]	,
["Gintowt",	288.60185	]	,
["Gazdowicz",	288.60456	]	,
["Dziubdziela",	288.60727	]	,
["Dworek",	288.60998	]	,
["Durawa",	288.61269	]	,
["Doczekalski",	288.6154	]	,
["Dajcz",	288.61811	]	,
["Czastkiewicz",	288.62082	]	,
["Cwanek",	288.62353	]	,
["Chojniak",	288.62624	]	,
["Broll",	288.62895	]	,
["Bethke",	288.63166	]	,
["Barnowski",	288.63437	]	,
["Badelek",	288.63708	]	,
["Zurawicki",	288.63978	]	,
["Zielonko",	288.64248	]	,
["Wruk",	288.64518	]	,
["Wojtaczka",	288.64788	]	,
["Witczyk",	288.65058	]	,
["Wilkoszewski",	288.65328	]	,
["Weryszko",	288.65598	]	,
["Waskowicz",	288.65868	]	,
["Tworzewski",	288.66138	]	,
["Tomankiewicz",	288.66408	]	,
["Tasior",	288.66678	]	,
["Spiewakowski",	288.66948	]	,
["Szlawski",	288.67218	]	,
["Szczutowski",	288.67488	]	,
["Synal",	288.67758	]	,
["Surdykowski",	288.68028	]	,
["Strózek",	288.68298	]	,
["Stephan",	288.68568	]	,
["Stajer",	288.68838	]	,
["Slabowski",	288.69108	]	,
["Skubiszynski",	288.69378	]	,
["Redyk",	288.69648	]	,
["Praga",	288.69918	]	,
["Orzylowski",	288.70188	]	,
["Netkowski",	288.70458	]	,
["Moldrzyk",	288.70728	]	,
["Merk",	288.70998	]	,
["Mederski",	288.71268	]	,
["Marcjanik",	288.71538	]	,
["Lagodzki",	288.71808	]	,
["Lecznar",	288.72078	]	
		
		];

	return {
		first: first,
		female: female,
		last: last
	};
});

